import { Overlay } from 'react-bootstrap'
import Popover from 'react-bootstrap/Popover'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

const NotificationModal = ({
  handleOverlayClose,
  showPopover,
  handleButtonClick,
  targetRef,
  onClickRead,
  notificationArr,
  readNotificationsArr,
  onClickNotification
}) => {
  return (
    <>
      <button
        className="button button--round"
        style={{ color: '#006dc6' }}
        onClick={handleButtonClick}
        ref={targetRef}
      >
        <span className="notification-badge">
          {notificationArr?.length > 0 && (
            <span className="badge">{notificationArr?.length}</span>
          )}
          <FontAwesome className="fa-bell-o has-badge" />
        </span>
      </button>
      <Overlay
        show={showPopover}
        target={targetRef.current}
        placement="bottom-end"
        container={document.body}
        onHide={handleOverlayClose}
        rootClose
      >
        <Popover
          style={{
            marginTop: '8px',
            minWidth: '370px',
            padding: '0',
            // boxShadow: '2px 3px 2px -5px rgba(0,0,0,0.5)',
            borderRadius: '15px'
          }}
        >
          <div className="notification-tab">
            <div className="notification-tab__header-container">
              <div className="notification-tab__header-wrapper">
                <h4>Notifications</h4>
                <p className="notification-tab__subheader">
                  You have{' '}
                  {notificationArr?.length ? notificationArr?.length : 0} unread
                  messages
                </p>
              </div>
              <div className="notification-tab__button-container">
                {notificationArr?.length > 0 ? (
                  <button
                    className={`notification-tab__button`}
                    onClick={onClickRead}
                  >
                    <FontAwesome className="fa-check-circle" /> Mark all as read
                  </button>
                ) : null}
              </div>
            </div>
            {(notificationArr?.length > 0 ||
              readNotificationsArr?.length > 0) && (
              <div
                className="notification-tab__body"
                style={{
                  height: '380px'
                }}
              >
                {notificationArr?.length > 0 && (
                  <>
                    <div>
                      <div className="notification-tab__header--orange">
                        NEW
                      </div>
                      {notificationArr.map(noti => (
                        <div
                          className="notification-wrapper"
                          onClick={() => {
                            onClickNotification(noti?.route, noti?.id, noti)
                          }}
                        >
                          <div className="notification">
                            <h4>{noti.title}</h4>
                            <p className="notification__message">
                              {noti.message}
                            </p>
                            <p className="notification__date">
                              {moment(noti.created_at).format(
                                'MMM Do [at] h:mma'
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {/* {readNotificationsArr?.length > 0 && (
                <>
                  <div className="notification-tab__header--green">SEEN</div>
                  {readNotificationsArr?.map(noti => (
                    <div
                      className="notification-wrapper"
                      // onClick={()=>{}}
                    >
                      <div className="notification">
                        <h4>{noti?.title}</h4>
                        <p className="notification__message">{noti?.message}</p>
                        <p className="notification__date">
                          {moment(noti?.created_at).format('MMM Do [at] h:mma')}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )} */}
              </div>
            )}
          </div>
        </Popover>
      </Overlay>
    </>
  )
}
export default NotificationModal
