import {
  SAVE_CLASS_SECTION_TEST_AND_SUBJECT,
  SAVE_GROUP_AND_TEST_FOR_DASHBOARD,
  SAVE_SELECTOR_DATA,
  SAVE_GROUPSELECTOR_DATA_FOR_MARKS_ENTRY
} from '../../helpers/actions'

export const saveClassSectionTestAndSubject = ({
  umbrellaGroup,
  groupId,
  testId,
  subjectId
}) => ({
  type: SAVE_CLASS_SECTION_TEST_AND_SUBJECT,
  umbrellaGroup,
  groupId,
  testId,
  subjectId
})
// Each change of group selector object is provided
// eg: {groupId: 'value'}

export const saveGroupSelectorData = data => ({
  type: SAVE_SELECTOR_DATA,
  data
})

export const saveGroupAndTest = ({ groupId, testId }) => ({
  type: SAVE_GROUP_AND_TEST_FOR_DASHBOARD,
  groupId,
  testId
})

export const saveDataForMarksEntry = ({
  academicYear,
  umbrellaGroup,
  groupId,
  testId,
  subjectId,
  electiveId
}) => ({
  type: SAVE_GROUPSELECTOR_DATA_FOR_MARKS_ENTRY,
  academicYear,
  umbrellaGroup,
  groupId,
  testId,
  subjectId,
  electiveId
})
