import {
  APP_LOGOUT,
  ADD_DRIVER,
  ADD_DRIVER_START,
  ADD_DRIVER_FAIL,
  GET_DRIVER,
  GET_DRIVER_BY_ID,
  GET_DRIVER_START,
  GET_DRIVER_FAIL,
  PATCH_DRIVER_START,
  PATCH_DRIVER,
  PATCH_DRIVER_FAIL
} from '../../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isLoadingPatch: false,
  isErr: false,
  driverData: [],
  driverById: {}
}

export const transportDriver = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ADD_DRIVER_START:
      return {
        ...state,
        isLoadingPost: true,
        isErr: false
      }
    case ADD_DRIVER:
      return {
        ...state,
        isLoadingPost: false,
        isErr: false
      }
    case ADD_DRIVER_FAIL:
      return {
        ...state,
        isLoadingPost: false,
        isErr: true
      }
    case GET_DRIVER_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case GET_DRIVER:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        driverData: action.data
      }
    case GET_DRIVER_BY_ID:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        driverById: action.data
      }
    case GET_DRIVER_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    case PATCH_DRIVER_START:
      return {
        ...state,
        isLoadingPatch: true
      }
    case PATCH_DRIVER:
      return {
        ...state,
        isLoadingPatch: false
      }
    case PATCH_DRIVER_FAIL:
      return {
        ...state,
        isLoadingPatch: false
      }
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.transportDriver.isLoading
export const getIsLoadingPost = state => state.transportDriver.isLoadingPost
export const getIsLoadingPatch = state => state.transportDriver.isLoadingPatch
export const getIsError = state => state.transportDriver.isErr
export const getDriverData = state => state.transportDriver.driverData
export const getDriverById = state => state.transportDriver.driverById
