import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ className, text, onClick, id }) => {
  return (
    <button onClick={onClick} className={className} id={id}>
      {text}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string
}

export default Button
