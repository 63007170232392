import { createSelector } from 'reselect'
import { merge, union } from 'lodash'

import {
  APP_LOGOUT,
  SET_DOCUMENTS_DATA,
  DOCUMENTS_FETCH_START,
  DOCUMENTS_FETCH_FAIL,
  UPDATE_DOCUMENTS_DATA,
  ADD_PROFILE_PICTURE_START,
  ADD_PROFILE_PICTURE_SUCCESS,
  ADD_PROFILE_PICTURE_FAILURE,
  CLEAR_STUDENT_PICTURE
} from '../helpers/actions'

// selectors
import { getActiveId } from './ui/documentUpload'

const initialState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isSuccess: '',
  profilePicture: ''
}

export const documents = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENTS_DATA:
      return Object.assign({}, state, {
        byId: merge({}, state.byId, action.byId),
        allIds: union([], state.allIds, action.allIds),
        isSuccess: 'success',
        isLoading: false
      })
    case DOCUMENTS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: ''
      })
    case DOCUMENTS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: 'error'
      })
    case UPDATE_DOCUMENTS_DATA: {
      console.log('dataaaaaa', action.data)
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.data['_id']]: action.data
        }),
        allIds: union([], state.allIds, [action.data['_id']])
      })
    }

    case ADD_PROFILE_PICTURE_START:
      return Object.assign({}, state, {
        isLoading: false,
        profilePicture: '',
        isSuccess: ''
      })
    case ADD_PROFILE_PICTURE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        profilePicture: action.data.publicUrl,
        isSuccess: 'success'
      })
    case ADD_PROFILE_PICTURE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        profilePicture: '',
        isSuccess: 'failed'
      })
    case CLEAR_STUDENT_PICTURE:
      return Object.assign({}, state, {
        isLoading: false,
        profilePicture: '',
        isSuccess: ''
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors

export const getById = state => state.documents.byId
export const getAllIds = state => state.documents.allIds
export const getIsLoading = state => state.documents.isLoading
export const getIsSuccess = state => state.documents.isSuccess
export const getProfilePicture = state => state.documents.profilePicture

export const getDocumentsByOwnerId = createSelector(
  getById,
  getAllIds,
  getActiveId,
  (byId, allIds, activeId) => {
    return allIds
      .filter(id => byId[id]['owner'] === activeId)
      .map(id => byId[id])
  }
)
