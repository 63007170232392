import React from 'react'
import ReactDOM from 'react-dom'

import configureStore from './helpers/configureStore.js'
// import { Root } from './components/root.jsx'
import { Root } from './componentsNew/router/root'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './index.scss'
import './assets/styles/index.scss'
import * as serviceWorker from './serviceWorker'

require('dotenv').config()

let { store, persistor } = configureStore()

ReactDOM.render(
  <Root store={store} persistor={persistor} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
