import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput, SelectInputWithID } from '../inputs.jsx'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'

import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../helpers/enums'
import Spinner from '../spinner'

export const GroupSelector = ({
  groupsLoading,
  selectedAcademicYear,
  allAcademicYears,
  umbrellaGroup,
  groupId,
  testId,
  parentNames,
  sections,
  tests,
  setSection,
  handleAcademicYearChange,
  handleParentChange,
  handleTestChange,
  handleGo,
  isDirty
}) => {
  return (
    <form className="group-selector" onSubmit={handleGo}>
      <SelectInput
        name="academic_year"
        options={allAcademicYears}
        umbrellaGroup={selectedAcademicYear}
        handleChange={handleAcademicYearChange}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <FontAwesome size="2x" name="caret-right" />
      {groupsLoading ? (
        <Spinner />
      ) : (
        <SelectInput
          name="parent_group"
          options={parentNames}
          umbrellaGroup={umbrellaGroup}
          handleChange={handleParentChange}
          {...STYLE_PROPS_FOR_GROUP_SELECTOR}
        />
      )}
      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="groupId"
        options={sections}
        id={groupId}
        handleChange={setSection}
        required
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="testId"
        id={testId}
        options={tests}
        handleChange={handleTestChange}
        required
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />

      <FontAwesome size="2x" name="caret-right" />
      <button
        type="submit"
        className={classNames({
          'button button--small button--blue': true,
          disabled: !isDirty
        })}
        disabled={!isDirty}
      >
        Go
      </button>
    </form>
  )
}

GroupSelector.propTypes = {
  umbrellaGroup: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  parentNames: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
  tests: PropTypes.array.isRequired,
  setSection: PropTypes.func.isRequired,
  handleParentChange: PropTypes.func.isRequired,
  handleTestChange: PropTypes.func.isRequired,
  handleGo: PropTypes.func.isRequired
}
