import {
  POST_SURVEY_START,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FAIL,
  PATCH_SURVEY_START,
  PATCH_SURVEY_SUCCESS,
  PATCH_SURVEY_FAIL,
  DELETE_SURVEY_START,
  DELETE_SURVEY_SUCCESS,
  DELETE_SURVEY_FAIL,
  START_GET_QUESTION,
  QUESTION_GET_FAILURE,
  ANSWER_POST_SUCCESS,
  GET_QUESTION,
  APP_LOGOUT,
  START_GET_SURVEY,
  SURVEY_GET_FAILURE,
  SURVEY_FETCHED,
  GET_FEEDBACK_TEACHER,
  START_GET_FEEDBACK_TEACHER,
  GET_FEEDBACK_TEACHER_REPORT,
  START_GET_FEEDBACK_TEACHER_REPORT,
  GET_FEEDBACK_TEACHER_REPORT_COMMENTS,
  START_GET_FEEDBACK_TEACHER_REPORT_COMMENTS,
  SURVEY_RESULTS_FETCHED,
  START_GET_SURVEY_RESULTS,
  SURVEY_RESULTS_GET_FAILURE,
  DELETE_SURVEY_QUESTION_START,
  DELETE_SURVEY_QUESTION_SUCCESS,
  DELETE_SURVEY_QUESTION_FAIL,
  POST_SURVEY_QUESTIONS_START,
  POST_SURVEY_QUESTIONS_SUCCESS,
  POST_SURVEY_QUESTIONS_FAIL,
  GET_SURVEY_ANSWER_START,
  GET_SURVEY_ANSWER_SUCCESS,
  GET_SURVEY_ANSWER_FAIL
} from '../helpers/actions'

// import { getById } from '../helpers/utils'

const initialState = {
  error: false,
  isLoading: false,
  isLoadingTeacher: false,
  isLoadingQuestions: false,
  questions: [],
  isSuccess: false,
  surveys: [],
  feedBackOfTeacher: {},
  isLoadingReport: false,
  isLoadingComments: false,
  report: [],
  comments: [],
  surveyResults: [],
  isLoadingSurveyResults: false,
  isPatchLoading: false,
  isDeleteQuestionLoading: false,
  isAddQuestionLoading: false,
  surveyAnswers: [],
  surveyAnswerLoading: false
}

export const studentFeedback = (state = initialState, action) => {
  switch (action.type) {
    case POST_SURVEY_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false
      })
    case POST_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: false
      })
    case POST_SURVEY_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        error: true
      })
    case GET_SURVEY_ANSWER_START:
      return Object.assign({}, state, {
        surveyAnswerLoading: true,
        error: false
      })
    case GET_SURVEY_ANSWER_SUCCESS:
      return Object.assign({}, state, {
        surveyAnswerLoading: false,
        error: false,
        surveyAnswers: action.data
      })
    case GET_SURVEY_ANSWER_FAIL:
      return Object.assign({}, state, {
        surveyAnswerLoading: false,
        error: true
      })
    case PATCH_SURVEY_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        error: false
      })
    case PATCH_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: false
      })
    case PATCH_SURVEY_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: true
      })
    case DELETE_SURVEY_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        error: false
      })
    case DELETE_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: false
      })
    case DELETE_SURVEY_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: true
      })
    case START_GET_QUESTION:
      return Object.assign({}, state, {
        isLoadingQuestions: true
      })

    case QUESTION_GET_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoadingQuestions: false
      })
    case GET_QUESTION:
      return Object.assign({}, state, {
        isLoadingQuestions: false,
        questions: action.data
      })
    case START_GET_SURVEY:
      return Object.assign({}, state, {
        isLoading: true
      })
    case SURVEY_FETCHED:
      return Object.assign({}, state, {
        isLoading: false,
        surveys: action.data?.filter(item => item?.visibility)
      })

    case SURVEY_GET_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
        isLoadingTeacher: false,
        isLoadingQuestions: false
      })
    case START_GET_SURVEY_RESULTS:
      return Object.assign({}, state, {
        isLoadingSurveyResults: true,
        error: false
      })
    case SURVEY_RESULTS_FETCHED:
      return Object.assign({}, state, {
        isLoadingSurveyResults: false,
        surveyResults: action.data,
        error: false
      })
    case SURVEY_RESULTS_GET_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoadingSurveyResults: false
      })
    case ANSWER_POST_SUCCESS:
      return Object.assign({}, state, {
        isSuccess: true,
        isLoading: false
      })
    case GET_FEEDBACK_TEACHER:
      return Object.assign({}, state, {
        feedBackOfTeacher: action.data,
        isLoadingTeacher: false
      })
    case START_GET_FEEDBACK_TEACHER:
      return Object.assign({}, state, {
        isLoadingTeacher: true
      })
    case GET_FEEDBACK_TEACHER_REPORT:
      return Object.assign({}, state, {
        isLoadingReport: false,
        report: action.data
      })
    case START_GET_FEEDBACK_TEACHER_REPORT:
      return Object.assign({}, state, {
        isLoadingReport: true
      })
    case GET_FEEDBACK_TEACHER_REPORT_COMMENTS:
      return Object.assign({}, state, {
        isLoadingComments: false,
        comments: action.data
      })
    case START_GET_FEEDBACK_TEACHER_REPORT_COMMENTS:
      return Object.assign({}, state, {
        isLoadingComments: true
      })
    case DELETE_SURVEY_QUESTION_START:
      return Object.assign({}, state, {
        isDeleteQuestionLoading: true,
        error: false
      })
    case DELETE_SURVEY_QUESTION_SUCCESS:
      return Object.assign({}, state, {
        isDeleteQuestionLoading: false,
        error: false
      })
    case DELETE_SURVEY_QUESTION_FAIL:
      return Object.assign({}, state, {
        isDeleteQuestionLoading: false,
        error: true
      })
    case POST_SURVEY_QUESTIONS_START:
      return Object.assign({}, state, {
        isAddQuestionLoading: true,
        error: false
      })
    case POST_SURVEY_QUESTIONS_SUCCESS:
      return Object.assign({}, state, {
        isAddQuestionLoading: false,
        error: false
      })
    case POST_SURVEY_QUESTIONS_FAIL:
      return Object.assign({}, state, {
        isAddQuestionLoading: false,
        error: true
      })
    // case CLEAR_FEEDBACK_TEACHER:
    //     return Object.assign({}, state, {
    //         error: true,
    //         isLoading: false
    //     })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors

export const getSurveys = state => state.studentFeedback.surveys
export const questions = state =>
  state.studentFeedback.questions
    ? state.studentFeedback.questions
        ?.filter(item => item?.visibility)
        .sort((a, b) => a.index - b.index)
    : []
export const feedBackOfTeacher = state =>
  state.studentFeedback.feedBackOfTeacher
export const isLoading = state => state.studentFeedback.isLoading
export const isLoadingTeacher = state => state.studentFeedback.isLoadingTeacher
export const isLoadingReport = state => state.studentFeedback.isLoadingReport
export const questionIsLoading = state =>
  state.studentFeedback.isLoadingQuestions
export const getReport = state => state.studentFeedback.report
export const getComments = state => state.studentFeedback.comments
export const isLoadingComments = state =>
  state.studentFeedback.isLoadingComments
export const getIsLoadingSurveyResults = state =>
  state.studentFeedback.isLoadingSurveyResults
export const getSurveyResults = state => state.studentFeedback.surveyResults
export const getError = state => state.studentFeedback.error
export const getPatchLoading = state => state.studentFeedback.isPatchLoading
export const getDeleteQuestionLoading = state =>
  state.studentFeedback.isDeleteQuestionLoading
export const getAddQuestionLoading = state =>
  state.studentFeedback.isAddQuestionLoading
export const getSurveyAnswers = state => state.studentFeedback.surveyAnswers
export const getSurveyAnswerLaoding = state =>
  state.studentFeedback.surveyAnswerLoading
