import {
  APP_LOGOUT,
  GET_LESSON_PLAN_BY_TEACHER_START,
  GET_LESSON_PLAN_BY_TEACHER,
  GET_LESSON_PLAN_BY_TEACHER_FAIL,
  POST_LESSON_PLAN_START,
  POST_LESSON_PLAN,
  POST_LESSON_PLAN_FAIL,
  GET_LESSON_PLAN_BY_ID_START,
  GET_LESSON_PLAN_BY_ID,
  GET_LESSON_PLAN_BY_ID_FAIL,
  PATCH_LESSON_PLAN_START,
  PATCH_LESSON_PLAN,
  PATCH_LESSON_PLAN_FAIL,
  DELETE_LESSON_PLAN_START,
  DELETE_LESSON_PLAN,
  DELETE_LESSON_PLAN_FAIL,
  PATCH_LESSON_CHAPTER_START,
  PATCH_LESSON_CHAPTER_SUCCESS,
  PATCH_LESSON_CHAPTER_FAILED,
  GET_LESSON_CHAPTER_START,
  GET_LESSON_CHAPTER_SUCCESS,
  GET_LESSON_CHAPTER_FAILED,
  POST_LESSON_CHAPTER_START,
  POST_LESSON_CHAPTER_SUCCESS,
  POST_LESSON_CHAPTER_FAILED,
  GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_START,
  GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_SUCCESS,
  GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_FAIL,
  GET_LESSON_PLAN_FOR_HOD_START,
  GET_LESSON_PLAN_FOR_HOD_SUCCESS,
  GET_LESSON_PLAN_FOR_HOD_FAIL,
  LESSON_PLAN_VERIFY_START,
  LESSON_PLAN_VERIFY_SUCCESS,
  LESSON_PLAN_VERIFY_FAIL,
  LESSON_PLAN_APPROVAL_START,
  LESSON_PLAN_APPROVAL_SUCCESS,
  LESSON_PLAN_APPROVAL_FAIL,
  GET_LESSON_PLAN_BY_DATES_START,
  GET_LESSON_PLAN_BY_DATES_SUCCESS,
  GET_LESSON_PLAN_BY_DATES_FAIL
} from '../helpers/actions'

const initialState = {
  isLoadingGetLessonPlanByTeacher: false,
  lessonPlans: [],
  isLoadingPostLessonPlan: false,
  isLoadingPatchLessonPlan: false,
  lessonPlanDetails: {},
  isLoadingGetLessonPlanById: false,
  isLoadingDeleteLessonPlanById: false,
  lessonPlanObj: {},
  isErr: false,
  isLoadingPostLessonChapter: false,
  isLoadingPatchLessonChapter: false,
  isLoadingGetLessonChapter: false,
  isLessonChapterErr: false,
  lessonChapters: [],
  lessonChapterMsg: '',
  lessonPlanChaptersPerSubject: [],
  isLoadingLessonPlanChapters: false,
  lessonPlanForHod: [],
  isLoadingLessonPlanForHod: false,
  isLessonPlanVerifyLoading: false,
  isLessonPlanByDatesLoading: false
}

export const lessonPlan = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_LESSON_PLAN_BY_TEACHER_START:
      return {
        ...state,
        isLoadingGetLessonPlanByTeacher: true,
        isErr: false
      }
    case GET_LESSON_PLAN_BY_TEACHER:
      return {
        ...state,
        isLoadingGetLessonPlanByTeacher: false,
        lessonPlans: action.data,
        isErr: false
      }
    case GET_LESSON_PLAN_BY_TEACHER_FAIL:
      return {
        ...state,
        isLoadingGetLessonPlanByTeacher: false,
        isErr: true
      }
    case POST_LESSON_PLAN_START:
      return {
        ...state,
        isLoadingPostLessonPlan: true,
        isErr: false
      }
    case POST_LESSON_PLAN:
      return {
        ...state,
        isLoadingPostLessonPlan: false,
        lessonPlanDetails: action.data,
        isErr: false
      }
    case POST_LESSON_PLAN_FAIL:
      return {
        ...state,
        isLoadingPostLessonPlan: false,
        isErr: true
      }
    case PATCH_LESSON_PLAN_START:
      return {
        ...state,
        isLoadingPatchLessonPlan: true,
        isErr: false
      }
    case PATCH_LESSON_PLAN:
      return {
        ...state,
        isLoadingPatchLessonPlan: false,
        lessonPlanDetails: action.data,
        isErr: false
      }
    case PATCH_LESSON_PLAN_FAIL:
      return {
        ...state,
        isLoadingPatchLessonPlan: false,
        isErr: true
      }
    case GET_LESSON_PLAN_BY_ID_START:
      return {
        ...state,
        isLoadingGetLessonPlanById: true,
        isErr: false
      }
    case GET_LESSON_PLAN_BY_ID:
      return {
        ...state,
        isLoadingGetLessonPlanById: false,
        lessonPlanObj: action.data,
        isErr: false
      }
    case GET_LESSON_PLAN_BY_ID_FAIL:
      return {
        ...state,
        isLoadingGetLessonPlanById: false,
        isErr: true
      }
    case DELETE_LESSON_PLAN_START:
      return {
        ...state,
        isLoadingDeleteLessonPlanById: true,
        isErr: false
      }
    case DELETE_LESSON_PLAN:
      return {
        ...state,
        isLoadingDeleteLessonPlanById: false,
        lessonPlanObj: action.data,
        isErr: false
      }
    case DELETE_LESSON_PLAN_FAIL:
      return {
        ...state,
        isLoadingDeleteLessonPlanById: false,
        isErr: true
      }
    case POST_LESSON_CHAPTER_START:
      return {
        ...state,
        isLoadingPostLessonChapter: true,
        isLessonChapterErr: false
      }
    case POST_LESSON_CHAPTER_SUCCESS:
      return {
        ...state,
        isLoadingPostLessonChapter: false,
        isLessonChapterErr: false
      }
    case POST_LESSON_CHAPTER_FAILED:
      return {
        ...state,
        isLoadingPostLessonChapter: false,
        isLessonChapterErr: true
      }
    case GET_LESSON_CHAPTER_START:
      return {
        ...state,
        isLoadingGetLessonChapter: true,
        isLessonChapterErr: false
      }
    case GET_LESSON_CHAPTER_SUCCESS:
      return {
        ...state,
        isLoadingGetLessonChapter: false,
        isLessonChapterErr: false,
        lessonChapters: action.data
      }
    case GET_LESSON_CHAPTER_FAILED:
      return {
        ...state,
        isLoadingGetLessonChapter: false,
        isLessonChapterErr: true
      }
    case PATCH_LESSON_CHAPTER_START:
      return {
        ...state,
        isLoadingPatchLessonChapter: true,
        isLessonChapterErr: false
      }
    case PATCH_LESSON_CHAPTER_SUCCESS:
      return {
        ...state,
        isLoadingPatchLessonChapter: false,
        isLessonChapterErr: false
      }
    case PATCH_LESSON_CHAPTER_FAILED:
      return {
        ...state,
        isLoadingPatchLessonChapter: false,
        isLessonChapterErr: true
      }
    case GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_START:
      return {
        ...state,
        isLoadingLessonPlanChapters: true,
        isErr: false
      }
    case GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoadingLessonPlanChapters: false,
        lessonPlanChaptersPerSubject: action.data,
        isErr: false
      }
    case GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_FAIL:
      return {
        ...state,
        isLoadingLessonPlanChapters: false,
        isErr: true
      }
    case GET_LESSON_PLAN_FOR_HOD_START:
      return {
        ...state,
        isLoadingLessonPlanForHod: true,
        isErr: false
      }
    case GET_LESSON_PLAN_FOR_HOD_SUCCESS:
      return {
        ...state,
        isLoadingLessonPlanForHod: false,
        lessonPlanForHod: action.data,
        isErr: false
      }
    case GET_LESSON_PLAN_FOR_HOD_FAIL:
      return {
        ...state,
        isLoadingLessonPlanForHod: false,
        isErr: true
      }
    case LESSON_PLAN_VERIFY_START:
      return {
        ...state,
        isLessonPlanVerifyLoading: true,
        isErr: false
      }
    case LESSON_PLAN_VERIFY_SUCCESS:
      return {
        ...state,
        isLessonPlanVerifyLoading: false,
        isErr: false
      }
    case LESSON_PLAN_VERIFY_FAIL:
      return {
        ...state,
        isLessonPlanVerifyLoading: false,
        isErr: true
      }
    case LESSON_PLAN_APPROVAL_START:
      return {
        ...state,
        isLessonPlanVerifyLoading: true,
        isErr: false
      }
    case LESSON_PLAN_APPROVAL_SUCCESS:
      return {
        ...state,
        isLessonPlanVerifyLoading: false,
        isErr: false
      }
    case LESSON_PLAN_APPROVAL_FAIL:
      return {
        ...state,
        isLessonPlanVerifyLoading: false,
        isErr: true
      }
    case GET_LESSON_PLAN_BY_DATES_START:
      return {
        ...state,
        isLessonPlanByDatesLoading: true,
        isErr: false
      }
    case GET_LESSON_PLAN_BY_DATES_SUCCESS:
      return {
        ...state,
        isLessonPlanByDatesLoading: false,
        lessonPlans: action.data,
        isErr: false
      }
    case GET_LESSON_PLAN_BY_DATES_FAIL:
      return {
        ...state,
        isLessonPlanByDatesLoading: false,
        isErr: true
      }
    default:
      return state
  }
}

export const getIsLoadingGetLessonPlanByTeacher = state =>
  state.lessonPlan.isLoadingGetLessonPlanByTeacher
export const getLessonPlans = state => state.lessonPlan.lessonPlans
export const getIsLoadingPostLessonPlan = state =>
  state.lessonPlan.isLoadingPostLessonPlan
export const getIsLoadingPatchLessonPlan = state =>
  state.lessonPlan.isLoadingPatchLessonPlan
export const getLessonPlanDetails = state => state.lessonPlan.lessonPlanDetails
export const getError = state => state.lessonPlan.isErr
export const getIsLoadingGetLessonPlanById = state =>
  state.lessonPlan.isLoadingGetLessonPlanById
export const getIsLoadingDeleteLessonPlanById = state =>
  state.lessonPlan.isLoadingDeleteLessonPlanById
export const getLessonPlanObj = state => state.lessonPlan.lessonPlanObj
export const getLessonChapterPostLoading = state =>
  state.lessonPlan.isLoadingPostLessonChapter
export const getLessonChapterPatchLoading = state =>
  state.lessonPlan.isLoadingPatchLessonChapter
export const getLessonChapters = state => state.lessonPlan.lessonChapters
export const getLessonChapterErr = state => state.lessonPlan.isLessonChapterErr
export const getLessonChapterLoading = state =>
  state.lessonPlan.isLoadingGetLessonChapter
export const getLessonPlanChaptersPerSubject = state =>
  state.lessonPlan.lessonPlanChaptersPerSubject
export const getIsLaodingLessonPlanChapter = state =>
  state.lessonPlan.isLoadingLessonPlanChapters
export const getLessonPlanForHod = state => state.lessonPlan.lessonPlanForHod
export const getIsLoadingLessonPlanForHod = state =>
  state.lessonPlan.isLoadingLessonPlanForHod
export const getIsLessonPlanVerifyLoading = state =>
  state.lessonPlan.isLessonPlanVerifyLoading
export const getIsLessonPlanByDatesLoading = state =>
  state.lessonPlan.isLessonPlanByDatesLoading
