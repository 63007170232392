import React from 'react'
import Select from 'react-select'

const SearchableDropdown = props => {
  return (
    <Select
      isSearchable={true}
      autoFocus={!props.autoFocus ? false : true}
      className={props.className}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      closeMenuOnSelect={true}
      openMenuOnFocus={false}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  )
}

export default SearchableDropdown
