// import packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
// import actions
import { startPatchTeacher, startDeleteTeacher } from '../../../actions/groups'
// import selectors
import { getJwt } from '../../../reducers/accounts'
// import enums
import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../../helpers/enums'
import Button from '../../common/Button'

class AddOrEditSubjectTeachersComponent extends Component {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    subjectTeachers: PropTypes.object.isRequired,
    teacherArr: PropTypes.array.isRequired,
    subjectsById: PropTypes.object.isRequired,
    teachersById: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      existingTeachers: {},
      newlyAddedTeachers: {},
      deletedTeachers: {}
    }
    this.addTeacher = this.addTeacher.bind(this)
    this.saveAddedTeachers = this.saveAddedTeachers.bind(this)
    this.handleExistingTeacherSelect = this.handleExistingTeacherSelect.bind(
      this
    )
    this.handleAddedTeacherSelect = this.handleAddedTeacherSelect.bind(this)
    this.handleDeletedTeacherSelect = this.handleDeletedTeacherSelect.bind(this)
    this.deleteTeachers = this.deleteTeachers.bind(this)
  }

  componentDidMount() {
    const { subjectTeachers } = this.props
    const existingTeachers = this.getExistingTeachers(subjectTeachers)
    const newlyAddedTeachers = this.getSubjects(subjectTeachers)
    const deletedTeachers = this.getSubjects(subjectTeachers)
    this.setState({
      existingTeachers,
      newlyAddedTeachers,
      deletedTeachers
    })
  }

  getExistingTeachers(subjectTeachers) {
    const newState = Object.assign({}, subjectTeachers)
    return newState
  }

  getSubjects(subjectTeachers) {
    const obj = {}
    Object.keys(subjectTeachers).forEach(subjectId => {
      obj[subjectId] = []
    })
    return obj
  }

  componentWillReceiveProps(nextProps) {
    const { subjectTeachers } = nextProps
    const existingTeachers = this.getExistingTeachers(subjectTeachers)
    const newlyAddedTeachers = this.getSubjects(subjectTeachers)
    const deletedTeachers = this.getSubjects(subjectTeachers)
    this.setState({
      existingTeachers,
      newlyAddedTeachers,
      deletedTeachers
    })
  }

  isTeacherAssigned(subjectId, teacherId) {
    return this.state.existingTeachers[subjectId].includes(teacherId)
  }

  isTeacherDeleted(subjectId, teacherId) {
    return this.state.deletedTeachers[subjectId].includes(teacherId)
  }

  isTeacherNewlyAdded(subjectId, teacherId) {
    return this.state.newlyAddedTeachers[subjectId].includes(teacherId)
  }

  handleExistingTeacherSelect(e) {
    const { name: subjectId, value: teacherId } = e.target
    const { existingTeachers, deletedTeachers } = this.state
    let existingTeacherArrPerSubject = existingTeachers[subjectId]
    let deletedTeacherArrPerSubject = deletedTeachers[subjectId]
    if (existingTeacherArrPerSubject.includes(teacherId)) {
      /* if teacherId is present, find the index and remove it from the teacher
      Arr */
      const index = existingTeacherArrPerSubject.indexOf(teacherId)
      existingTeacherArrPerSubject.splice(index, 1)
      deletedTeacherArrPerSubject.push(teacherId)
    }
    this.setState({
      existingTeachers: {
        ...existingTeachers,
        [subjectId]: existingTeacherArrPerSubject
      },
      deletedTeachers: {
        ...deletedTeachers,
        [subjectId]: deletedTeacherArrPerSubject
      }
    })
  }

  handleDeletedTeacherSelect(e) {
    const { name: subjectId, value: teacherId } = e.target
    const { existingTeachers, deletedTeachers } = this.state
    let existingTeacherArrPerSubject = existingTeachers[subjectId]
    let deletedTeacherArrPerSubject = deletedTeachers[subjectId]
    if (deletedTeacherArrPerSubject.includes(teacherId)) {
      /* if teacherId is present, find the index and remove it from the teacher
      Arr */
      const index = deletedTeacherArrPerSubject.indexOf(teacherId)
      deletedTeacherArrPerSubject.splice(index, 1)
      existingTeacherArrPerSubject.push(teacherId)
    }
    this.setState({
      existingTeachers: {
        ...existingTeachers,
        [subjectId]: existingTeacherArrPerSubject
      },
      deletedTeachers: {
        ...deletedTeachers,
        [subjectId]: deletedTeacherArrPerSubject
      }
    })
  }

  handleAddedTeacherSelect(e) {
    e.preventDefault()
    const { name: subjectId, value: teacherId } = e.target
    const { newlyAddedTeachers } = this.state
    let teacherArrPerSubject = newlyAddedTeachers[subjectId]
    if (teacherArrPerSubject.includes(teacherId)) {
      /* if teacherId is present, find the index and remove it from the teacher
      Arr */
      const index = teacherArrPerSubject.indexOf(teacherId)
      teacherArrPerSubject.splice(index, 1)
    }
    this.setState({
      newlyAddedTeachers: {
        ...newlyAddedTeachers,
        [subjectId]: teacherArrPerSubject
      }
    })
  }

  addTeacher(e) {
    const { name: subjectId, value: teacherId } = e.target
    const { newlyAddedTeachers } = this.state
    let teacherArrPerSubject = newlyAddedTeachers[subjectId]
    teacherArrPerSubject.push(teacherId)
    this.setState({
      newlyAddedTeachers: {
        ...newlyAddedTeachers,
        [subjectId]: teacherArrPerSubject
      }
    })
  }

  renderTeacherDropdown(subjectId) {
    const {
      fieldClass,
      rightIconClass,
      iconSize,
      rightIconName,
      labelClass,
      leftIconClass,
      leftIconName
    } = STYLE_PROPS_FOR_GROUP_SELECTOR
    const { teacherArr } = this.props
    const { existingTeachers, newlyAddedTeachers } = this.state
    return (
      <label className={labelClass}>
        <span className={`selector__field__icon ${leftIconClass}`}>
          <FontAwesome className={`icon ${iconSize}`} name={leftIconName} />
        </span>
        <select
          className={fieldClass}
          value=""
          name={subjectId}
          onChange={this.addTeacher}
        >
          <option value="" disabled>
            Select Teacher
          </option>
          {teacherArr.map((teacherObj, index) => (
            <option
              key={`${teacherObj.id}:${index}`}
              value={teacherObj.id}
              disabled={
                existingTeachers[subjectId].includes(teacherObj.id) ||
                newlyAddedTeachers[subjectId].includes(teacherObj.id)
                  ? true
                  : false
              }
            >
              {teacherObj.name}
            </option>
          ))}
        </select>
        <span className={`selector__field__icon ${rightIconClass}`}>
          <FontAwesome className={`icon ${iconSize}`} name={rightIconName} />
        </span>
      </label>
    )
  }

  renderAssignedTeachers(subjectId) {
    const { teachersById } = this.props
    const { existingTeachers, deletedTeachers } = this.state
    return (
      <div className="info-cell">
        {existingTeachers[subjectId].map((teacherId, index) => (
          <span className="check-tab-ctr" key={`${teacherId}-${index}`}>
            <label className="check-tag check-tag--green">
              <input
                name={subjectId}
                type="checkbox"
                value={teacherId}
                checked={this.isTeacherAssigned(subjectId, teacherId)}
                onChange={this.handleExistingTeacherSelect}
                className="check-tag__input"
              />
              <span className="check-tag__text">
                {teachersById[teacherId].name}
              </span>
            </label>
          </span>
        ))}
        {deletedTeachers[subjectId].map((teacherId, index) => (
          <span className="check-tab-ctr" key={`${teacherId}:${index}`}>
            <label className="check-tag check-tag--red">
              <input
                name={subjectId}
                type="checkbox"
                value={teacherId}
                checked={this.isTeacherDeleted(subjectId, teacherId)}
                onChange={this.handleDeletedTeacherSelect}
                className="check-tag__input"
              />
              <span className="check-tag__text">
                {teachersById[teacherId].name}
              </span>
            </label>
          </span>
        ))}
        <Button
          onClick={this.deleteTeachers}
          id={subjectId}
          className="button button--red"
          text="Delete Teachers"
        />
      </div>
    )
  }

  renderNewlyAddedTeachers(subjectId) {
    const { newlyAddedTeachers } = this.state
    const { teachersById } = this.props
    return newlyAddedTeachers[subjectId].map((teacherId, index) => (
      <span
        className="check-tab-ctr check-tab-ctr--red"
        key={`${teacherId}.${index}`}
      >
        <label className="check-tag check-tag--green">
          <input
            name={subjectId}
            type="checkbox"
            value={teacherId}
            checked={this.isTeacherNewlyAdded(subjectId, teacherId)}
            onChange={this.handleAddedTeacherSelect}
            className="check-tag__input"
          />
          <span className="check-tag__text">
            {teachersById[teacherId].name}
          </span>
        </label>
      </span>
    ))
  }

  saveAddedTeachers(e) {
    const { id: subjectId } = e.target
    const { newlyAddedTeachers } = this.state
    const { groupId, addTeacher, jwt } = this.props
    const subjectWithTeachers = {
      subject: subjectId,
      teacher: newlyAddedTeachers[subjectId]
    }
    addTeacher({
      jwt,
      subjectWithTeachers,
      groupId
    })
  }

  deleteTeachers(e) {
    e.preventDefault()
    const { id: subjectId } = e.target
    const { deletedTeachers } = this.state

    const { deleteTeacher, jwt, groupId } = this.props
    const subjectWithTeachers = {
      subject: subjectId,
      teacher: deletedTeachers[subjectId]
    }
    deleteTeacher({
      jwt,
      subjectWithTeachers,
      groupId
    })
  }

  render() {
    const { subjectTeachers, subjectsById } = this.props
    const { existingTeachers, deletedTeachers } = this.state
    return (
      <div id="subjects">
        <h3 className="page__subHead">Assign Teachers</h3>
        <div>
          {Object.keys(subjectsById).length === 0 && (
            <p>No Subjects Available</p>
          )}
          {Object.keys(existingTeachers).length !== 0 &&
            Object.keys(subjectTeachers).map((subjectId, index) => (
              <div key={`${subjectId}-${index}`} className="assignTeacher row">
                <div className="assignTeacher__subject col-sm-5">
                  {subjectsById[subjectId].name}
                  <div className="clear"></div>
                </div>
                <div className="assignTeacher__body col-sm-7">
                  <div className="assignTeacher__select">
                    {this.renderTeacherDropdown(subjectId)}
                    <Button
                      onClick={this.saveAddedTeachers}
                      id={subjectId}
                      className="button button--blue"
                      text="+"
                    />
                    <div className="clear"></div>
                  </div>
                  <div className="clear"></div>
                  {this.renderNewlyAddedTeachers(subjectId)}
                  {subjectTeachers[subjectId].length !== 0 ||
                  deletedTeachers[subjectId].length !== 0 ? (
                    this.renderAssignedTeachers(subjectId)
                  ) : (
                    <p className="noTeachersAssigned">No Teacher assigned</p>
                  )}
                  <div className="clear"></div>
                </div>
                <div className="clear"></div>
              </div>
              //   <div key={`${subjectId}-${index}`}>
              //     <div className="info-row">
              //       <p
              //         className={classNames({
              //           'info-cell info-cell--left': true
              //         })}
              //       >
              //         {subjectsById[subjectId].name}
              //       </p>
              //       <div className="dash" />
              //       <div>
              //         {subjectTeachers[subjectId].length !== 0 ||
              //         deletedTeachers[subjectId].length !== 0
              //           ? this.renderAssignedTeachers(subjectId)
              //           : 'No Teacher assigned'}
              //         <div className="info-cell">
              //           {this.renderNewlyAddedTeachers(subjectId)}
              //           {this.renderTeacherDropdown(subjectId)}
              //           <Button
              //             onClick={this.saveAddedTeachers}
              //             id={subjectId}
              //             className="button button--blue"
              //             text="Add Teachers"
              //           />
              //         </div>
              //       </div>
              //     </div>
              //   </div>
            ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state)
})

const mapDispatchToProps = dispatch => ({
  addTeacher(data) {
    dispatch(startPatchTeacher(data))
  },
  deleteTeacher(data) {
    dispatch(startDeleteTeacher(data))
  }
})

const AddOrEditSubjectTeachers = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrEditSubjectTeachersComponent)

export default AddOrEditSubjectTeachers
