import React, { Component } from 'react'

class TableBody extends Component {
  render() {
    const { data, tableHeaders } = this.props
    return (
      <tbody className="table-body">
        {data.map((studentObj, index) => {
          return (
            <tr key={index} className="table-row">
              {tableHeaders.map((key, index) => {
                if (key.subHeader && key.subHeader.length) {
                  return key.subHeader.map((test, index) => {
                    if (test.label === 'Agg %') {
                      return (
                        <td className="table-row__cell" key={index}>
                          {studentObj[test.value]}
                        </td>
                      )
                    } else {
                      return (
                        <td className="table-row__cell" key={index}>
                          {studentObj[key.subjectId + test.value]}
                        </td>
                      )
                    }
                  })
                } else {
                  return (
                    <td className="table-row__cell" key={index}>
                      {studentObj[key.groupBy]}
                    </td>
                  )
                }
              })}
            </tr>
          )
        })}
      </tbody>
    )
  }
}

export default TableBody
