import {
  CREATE_TICKET,
  CREATE_TICKET_FAILED,
  CREATE_TICKET_START,
  GET_TICKET,
  GET_TICKET_FAILED,
  GET_TICKET_START,
  PATCH_TICKET,
  PATCH_TICKET_FAILED,
  PATCH_TICKET_START,
  REMOVE_TICKET,
  REMOVE_TICKET_START,
  REMOVE_TICKET_FAILED,
  GET_CHATS_START,
  GET_CHATS,
  GET_CHALLAN_FAIL,
  POST_CHAT,
  POST_CHAT_START,
  POST_CHAT_FAILED,
  PATCH_CHAT,
  PATCH_CHAT_START,
  PATCH_CHAT_FAILED
} from '../helpers/actions'
import {
  AuthError,
  checkStatus,
  composeAuth,
  errorToast,
  parseJSON,
  successToast
} from '../helpers/utils'
import { emitAuthFailed } from './login'
import querystring from 'query-string'

//post ticket
const postTicketStart = () => ({
  type: CREATE_TICKET_START
})
const postTicketSuccess = data => ({
  type: CREATE_TICKET,
  data
})
const postTicketFailed = () => ({
  type: CREATE_TICKET_FAILED
})

const postTicket = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/ticketing_system/ticket'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPostTicket = data => dispatch => {
  dispatch(postTicketStart())
  return postTicket(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(postTicketSuccess(json.data))
      successToast('Ticket Raised successfully')
    })
    .catch(err => {
      dispatch(postTicketFailed())
      errorToast('Ticket Creation Failed')
    })
}

//get ticket
const getTicketStart = () => ({
  type: GET_TICKET_START
})

const getTicketSuccess = data => ({
  type: GET_TICKET,
  data
})

const getTicketFail = errMsg => ({
  type: GET_TICKET_FAILED,
  errMsg
})
//get ticket by ticket id
const getTicketByTicketId = ({ jwt, ticketId }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/ticketing_system/ticket/${ticketId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}
export const startGetTicketByTicketId = data => {
  return function(dispatch) {
    dispatch(getTicketStart())
    return getTicketByTicketId(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(getTicketSuccess(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(getTicketFail(err.message))
      })
  }
}
// get tickets for level id
const getTicket = ({ jwt, statusTypes }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/ticketing_system/ticket/levelId/status?` +
    querystring.stringify({ status: statusTypes })

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}
export const startGetTicketByLevelIdAndStatus = data => {
  return function(dispatch) {
    dispatch(getTicketStart())
    return getTicket(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(getTicketSuccess(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(getTicketFail(err.message))
      })
  }
}

//get ticket for admin
const getAllTicket = ({ jwt, statusTypes }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/ticketing_system/ticket/status?` +
    querystring.stringify({ status: statusTypes })

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}
export const startGetTicketByStatus = data => {
  return function(dispatch) {
    dispatch(getTicketStart())
    return getAllTicket(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(getTicketSuccess(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(getTicketFail(err.message))
      })
  }
}

// patch ticket
const patchTicketStart = () => ({
  type: PATCH_TICKET_START
})

const patchTicketSuccess = data => ({
  type: PATCH_TICKET,
  data
})

const patchTicketFail = () => ({
  type: PATCH_TICKET_FAILED
})

const patchTicket = ({ jwt, id, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/ticketing_system/ticket/${id}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchTicket = data => {
  return function(dispatch) {
    dispatch(patchTicketStart())
    return patchTicket(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(patchTicketSuccess(json.data))
        successToast('Successfully changed status')
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(patchTicketFail(err.message))
        errorToast('Unable to change status')
      })
  }
}

//get chats by ticket id
const getChatStart = () => ({
  type: GET_CHATS_START
})

const getChatSuccess = data => ({
  type: GET_CHATS,
  data
})

const getChatsFailed = errMsg => ({
  type: GET_CHALLAN_FAIL,
  errMsg
})

const getChatsByTicketId = ({ jwt, ticketId }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/ticketing_system/chats/ticket_id/${ticketId}`

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}
export const startGetChatsByTicketId = data => {
  return function(dispatch) {
    dispatch(getChatStart())
    return getChatsByTicketId(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(getChatSuccess(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(getChatsFailed(err.message))
      })
  }
}

//post chat
const postChatStart = () => ({
  type: POST_CHAT_START
})
const postChatSuccess = data => ({
  type: POST_CHAT,
  data
})
const postChatFailed = err => ({
  type: POST_CHAT_FAILED,
  err
})

const postChat = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/ticketing_system/chats'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPostChat = data => dispatch => {
  dispatch(postChatStart())
  return postChat(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(postChatSuccess(json.data))
    })
    .catch(err => {
      dispatch(postChatFailed(err))
    })
}

//patch chat
const patchChatStart = () => ({
  type: PATCH_CHAT_START
})

const patchChatSuccess = data => ({
  type: PATCH_CHAT,
  data
})

const patchChatFail = () => ({
  type: PATCH_CHAT_FAILED
})

const patchChat = ({ jwt, chatId, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/ticketing_system/chats/${chatId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchChat = data => {
  return function(dispatch) {
    dispatch(patchChatStart())
    return patchChat(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(patchChatSuccess(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(patchChatFail(err.message))
      })
  }
}
