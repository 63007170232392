import {
  APP_LOGOUT,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS
} from '../helpers/actions'

const initialState = {
  notificationIsLoading: false,
  notificationErr: false,
  notificationErrMsg: '',
  successMsg: '',
  notificationsArr: [],
  readNotificationsArr: [],
  status: ''
}
export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_NOTIFICATIONS_START:
      return Object.assign({}, state, {
        notificationIsLoading: true,
        notificationErr: false,
        notificationErrMsg: '',
        status: ''
      })
    case GET_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        notificationIsLoading: false,
        notificationErr: false,
        notificationErrMsg: '',
        status: 'fetched',
        notificationsArr: action?.data
          ?.filter(noti => noti?.visibility)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        readNotificationsArr: action?.data
          ?.filter(noti => !noti?.visibility)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      })
    case GET_NOTIFICATIONS_FAIL:
      return Object.assign({}, state, {
        notificationIsLoading: false,
        notificationErr: true,
        notificationErrMsg: action?.error,
        status: ''
      })
    default:
      return state
  }
}

export const getNotificationLoading = state =>
  state.notifications.notificationIsLoading
export const getNotificationError = state => state.notifications.notificationErr
export const getNotificationErrorMsg = state =>
  state.notifications.notificationErrMsg
export const getNotificationStatus = state => state.notifications.status
export const getNotificationArr = state => state.notifications.notificationsArr
export const getReadNotificationsArr = state =>
  state.notifications.readNotificationsArr
