// import packages
import React from 'react'

import WrapperComponetCertificates from './registercetificate'
import WrapperComponetCertificatesStatus from './reportCertificateStatus'

const RegisterComp = () => (
  <WrapperComponetCertificates
    PageHelmetText="STUDENT REGISTETR CERTIFICATE"
    nameOfComponent="register-cerificate"
  />
)
const ConductCertCom = () => (
  <WrapperComponetCertificates
    PageHelmetText="STUDENT CONDUCT CERTIFICATE"
    nameOfComponent="conduct-certificate"
  />
)
const RegisterStatusComp = () => (
  <WrapperComponetCertificatesStatus
    PageHelmetText="STUDENT REGISTETR CERTIFICATE"
    nameOfComponent="register-cerificate"
  />
)
const ConductCertStatusCom = () => (
  <WrapperComponetCertificatesStatus
    PageHelmetText="STUDENT CONDUCT CERTIFICATE"
    nameOfComponent="conduct-certificate"
  />
)
export {
  RegisterComp,
  ConductCertCom,
  RegisterStatusComp,
  ConductCertStatusCom
}
