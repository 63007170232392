import React from 'react'
import PropTypes from 'prop-types'

import allFunctions from '../marksReport/marksReportFns'

const funObj = allFunctions
const MarksReportItem = ({ downloadReportData, report }) => {
  return (
    <div className="info-row">
      <label className="info-cell info-cell--left" htmlFor="name">
        {report.name}
      </label>
      <span className="dash" />
      <div className="info-cell info-row-certificate">
        <button
          className="button button--blue"
          onClick={() =>
            funObj[report.func]({
              ...downloadReportData,
              templateType: report.value
            })
          }
        >
          download pdf
        </button>
      </div>
    </div>
  )
}
MarksReportItem.propTypes = {
  downloadReportData: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired
}
export default MarksReportItem
