import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import { Field } from 'redux-form'
import { uniqueParentGroups, getSections } from '../../helpers/utils'

class SelectSectionsForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    meta: PropTypes.shape({
      error: PropTypes.bool
    }).isRequired,
    groups: PropTypes.array.isRequired,
    selectedGroups: PropTypes.array.isRequired
  }

  render() {
    const {
      fields,
      meta: { error },
      groups,
      selectedGroups,
      clearTests
    } = this.props
    return (
      <div className="class-details section-details">
        <ul>
          {fields.map((field, index) => {
            const selectedParent = selectedGroups[index]
            return (
              <li className="section-details-feilds" key={index}>
                <div className="section-field class-field--70">
                  <Field
                    name={`${field}.class`}
                    component="select"
                    onChange={
                      clearTests
                        ? () => {
                            fields.remove(index)
                            clearTests()
                          }
                        : () => true
                    }
                    required
                  >
                    <option value="" disabled>
                      Select Class
                    </option>
                    {uniqueParentGroups(groups).map((parent, index) => (
                      <option key={index} value={parent}>
                        {parent}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="section-field">
                  {getSections(selectedParent, groups).map((section, index) => (
                    <span className="check-tab-ctr" key={index}>
                      <label className="check-tag">
                        <Field
                          name={`${field}.section.${section._id}`}
                          component="input"
                          type="checkbox"
                          className="check-tag__input"
                        />
                        <span className="check-tag__text">
                          {section.group_name}
                        </span>
                      </label>
                    </span>
                  ))}
                </div>
                <button
                  className="button button--round button--red button--absolute"
                  type="button"
                  title="Remove Class"
                  onClick={() => fields.remove(index)}
                >
                  <FontAwesome name="trash" />
                </button>
              </li>
            )
          })}
          {error && <li className="error">{error}</li>}
          <li>
            <button
              className="button button--bordered"
              type="button"
              onClick={() => fields.push()}
            >
              + Add Class
            </button>
          </li>
        </ul>
      </div>
    )
  }
}

export default SelectSectionsForm
