import { createSelector } from 'reselect'
import underscore from 'underscore'
import { GROUPS_GROUP_TYPE } from '../helpers/enums'
import { getById } from '../helpers/utils'
import {
  APP_LOGOUT,
  PROMOTION_GROUPS_START,
  PROMOTION_GROUPS_FAILURE,
  PROMOTION_GROUP_DATA,
  PROMOTION_ACADEMIC_YEAR_DATA,
  START_DUPLICATE_STUDENT_GROUP_DATA,
  DUPLICATE_STUDENT_GROUP_DATA,
  FAILED_START_DUPLICATE_STUDENT_GROUP_DATA,
  DUPLICATE_TEST_START,
  DUPLICATE_TEST_SUCCESS,
  DUPLICATE_TEST_FAIL,
  ADMISSION_YEAR_DATA_START,
  ADMISSION_YEAR_DATA,
  ADMISSION_YEAR_DATA_FAIL,
  START_PROMOTION_ACADEMIC_YEAR_DATA
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isWorking: false, // post / patch actions in progress
  byId: {},
  allIds: [],
  nextAcademicYear: [],
  promotionAcademicYear: [],
  isSuccess: '',
  postInProgress: false,
  duplicationLoading: false,
  isAdmissionLoading: false,
  admissionYear: []
}

export const promotionalgroups = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case PROMOTION_GROUPS_START:
      return Object.assign({}, state, {
        isLoading: true,
        byId: {},
        allIds: []
      })
    case PROMOTION_GROUP_DATA:
      if (!action.groups || !action.result) return state
      const { groups, result } = action
      return Object.assign({}, state, {
        isLoading: false,
        byId: groups,
        allIds: result,
        isSuccess: ''
      })

    case PROMOTION_GROUPS_FAILURE:
      return Object.assign({}, state, {
        isSuccess: 'fail',
        isLoading: false
      })
    case START_PROMOTION_ACADEMIC_YEAR_DATA:
      return Object.assign({}, state, {
        isLoading: true,
        nextAcademicYear: []
      })
    case PROMOTION_ACADEMIC_YEAR_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        nextAcademicYear: action.data
      })
    case START_DUPLICATE_STUDENT_GROUP_DATA:
      return Object.assign({}, state, {
        duplicationLoading: true,
        isSuccess: ''
      })
    case DUPLICATE_STUDENT_GROUP_DATA:
      return Object.assign({}, state, {
        isSuccess: 'success',
        duplicationLoading: false
      })
    case FAILED_START_DUPLICATE_STUDENT_GROUP_DATA:
      return Object.assign({}, state, {
        duplicationLoading: false,
        isSuccess: 'fail'
      })
    case DUPLICATE_TEST_START:
      return Object.assign({}, state, {
        duplicationLoading: true,
        isSuccess: ''
      })
    case DUPLICATE_TEST_SUCCESS:
      return Object.assign({}, state, {
        isSuccess: 'success',
        duplicationLoading: false
      })
    case DUPLICATE_TEST_FAIL:
      return Object.assign({}, state, {
        duplicationLoading: false,
        isSuccess: 'fail'
      })
    case ADMISSION_YEAR_DATA_START:
      return Object.assign({}, state, {
        isAdmissionLoading: true
      })
    case ADMISSION_YEAR_DATA:
      return Object.assign({}, state, {
        isAdmissionLoading: false,
        admissionYear: action.data
      })
    case ADMISSION_YEAR_DATA_FAIL:
      return Object.assign({}, state, {
        isAdmissionLoading: false
      })
    default:
      return Object.assign({}, state, {})
  }
}

export const groupsById = state => state.promotionalgroups.byId

export const isLoading = state => state.promotionalgroups.isLoading

export const isWorking = state => state.promotionalgroups.isWorking

export const getAllIds = state => state.promotionalgroups.allIds

export const getIsSuccess = state => state.promotionalgroups.isSuccess
export const getPromotionalAcademicYear = state =>
  state.promotionalgroups.nextAcademicYear.sort((a, b) => b - a)
export const getAdmissionYears = state =>
  state.promotionalgroups.admissionYear.sort((a, b) => b - a)
export const getDuplicationLoading = state =>
  state.promotionalgroups.duplicationLoading

export const getAcademicPromotionalGroups = createSelector(
  groupsById,
  getAllIds,
  (byId, promotionalgroups) => {
    return promotionalgroups
      .filter(group => {
        const { group_type, archive } = byId[group]
        if (GROUPS_GROUP_TYPE[0] === group_type && false === archive)
          return true
        return false
      })
      .sort((a, b) => {
        const {
          orderUmbrella_group: orderUmbrella_groupOfA,
          orderGroup: orderGroupOfA
        } = Object.assign({ orderUmbrella_group: 0, orderGroup: 0 }, byId[a])
        const {
          orderUmbrella_group: orderUmbrella_groupOfB,
          orderGroup: orderGroupOfB
        } = Object.assign({ orderUmbrella_group: 0, orderGroup: 0 }, byId[b])
        const aValue = orderUmbrella_groupOfA * 10 + orderGroupOfA
        const bValue = orderUmbrella_groupOfB * 10 + orderGroupOfB
        return aValue - bValue
      })
      .map(groupId => {
        const {
          group_name,
          umbrella_group,
          group_type,
          subjects,
          students,
          tests,
          class_teacher,
          orderUmbrella_group,
          orderGroup
        } = Object.assign(
          {
            group_name: '',
            umbrella_group: '',
            group_type: '',
            subjects: [],
            students: [],
            tests: [],
            class_teacher: '',
            orderUmbrella_group: '',
            orderGroup: ''
          },
          byId[groupId]
        )
        return {
          id: groupId,
          _id: groupId,
          group_name,
          parent_group: umbrella_group,
          group_type,
          subjects,
          students,
          tests,
          class_teacher,
          orderUmbrella_group,
          orderGroup
        }
      })
  }
)

export const getPromotionalAcademicGroupsById = createSelector(
  getAcademicPromotionalGroups,
  sections => {
    return getById(sections, '_id')
  }
)

export const getPromotionalAcademicGrades = createSelector(
  getAcademicPromotionalGroups,
  sections => {
    return underscore.uniq(sections.map(section => section.parent_group))
  }
)
