// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, Field } from 'redux-form'
import classNames from 'classnames'
import moment from 'moment'
// import actions
import { startFetchFlattenedData } from '../../actions/students'
// import selectors
import { getJwt } from '../../reducers/accounts'
import { groupsById } from '../../reducers/groups'
import StudentStore from '../../reducers/flattenedStudents'
// import enums
import { CONDUCT_CERTIFICATE_NAME } from '../../helpers/enums'
// import components
import Spinner from '../spinner'

class StudentSelectorContainer extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    groupsById: PropTypes.object.isRequired,
    studentsLoading: PropTypes.bool.isRequired,
    studentsArr: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    fetchStudents: PropTypes.func.isRequired
  }
  componentDidMount() {
    const { jwt, groupId, groupsById, fetchStudents } = this.props
    if (groupId !== '' || groupsById[groupId]) {
      fetchStudents({
        jwt,
        students: groupsById[groupId].students
      })
    }
  }

  getTableHeaders() {
    return (
      <thead className="st-table__head">
        <tr className="st-table__head--lightblue">
          <th className="st-table__row--headcell st-table__text--head">
            Roll No.
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            Admission No.
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            Student Name
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            Joining Class
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            Joining Date
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            Select
          </th>
        </tr>
      </thead>
    )
  }

  getTableBody() {
    const { studentsArr } = this.props
    return (
      <tbody className="st-table__body">
        <tr className="st-table__body--row">
          <td className="st-table__body--cell st-table__text--body" />
          <td className="st-table__body--cell st-table__text--body" />
          <td className="st-table__body--cell st-table__text--body" />
          <td className="st-table__body--cell st-table__text--body" />
          <td className="st-table__body--cell st-table__text--body" />
          <td className="st-table__body--cell st-table__text--body">
            <label className="student-select">
              <Field
                name="selectAllValues"
                id="selectAllValues"
                component="input"
                type="checkbox"
              />

              <span className="square">
                <span className="tick" />
              </span>
            </label>
            all
          </td>
        </tr>
        {studentsArr
          .sort((a, b) => Number(a.roll_number) - Number(b.roll_number))
          .map(studentObj => {
            const {
              admission_number,
              roll_number,
              name,
              joining_class,
              joining_date
            } = Object.assign(
              {
                admission_number: '',
                roll_number: '',
                name: '',
                joining_class: ''
              },
              studentObj
            )
            return (
              <tr key={studentObj._id} className="st-table__body--row">
                <td className="st-table__body--cell st-table__text--body">
                  {roll_number}
                </td>
                <td className="st-table__body--cell st-table__text--body">
                  {admission_number}
                </td>
                <td className="st-table__body--cell st-table__text--body">
                  {name}
                </td>
                <td className="st-table__body--cell st-table__text--body">
                  {joining_class}
                </td>
                <td className="st-table__body--cell st-table__text--body">
                  {joining_date
                    ? moment(joining_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    : ''}
                </td>
                <td className="st-table__body--cell st-table__text--body">
                  <label className="group-select group-select--all">
                    <Field
                      name={`students.${studentObj._id}`}
                      component="input"
                      type="checkbox"
                    />
                    <span className="square">
                      <span className="tick" />
                    </span>
                  </label>
                </td>
              </tr>
            )
          })}
      </tbody>
    )
  }

  isReady() {
    const { studentState } = this.props
    if (Object.keys(studentState).length === 0) return false
    const selectedStudents = Object.keys(studentState).filter(
      studentId => studentState[studentId]
    )
    if (selectedStudents.length === 0) return false
    return true
  }

  render() {
    const { handleSubmit, studentsLoading } = this.props
    return (
      <div className="student-select-ctr">
        <form onSubmit={handleSubmit}>
          <div className="space-creator" />
          <table className="st-table st-table--default u-position-relative">
            {this.getTableHeaders()}
            {!studentsLoading ? this.getTableBody() : <Spinner />}
          </table>
          <div className="field submit-field submit-field__studentconfirm">
            >
            <button
              className={classNames({
                'button button--blue': true,
                disabled: !this.isReady()
              })}
              disabled={!this.isReady()}
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const selector = formValueSelector(CONDUCT_CERTIFICATE_NAME)

const mapStateToProps = state => {
  const selectAllValues = selector(state, 'selectAllValues')

  const getInitialValues = () => {
    const studentsLoading = StudentStore.isLoading(state)
    const studentsArr = StudentStore.getStudentsSortedByName(state)
    if (studentsLoading) return {}
    const obj = { students: {} }
    if (selectAllValues) {
      studentsArr.forEach(studentObj => {
        obj.students[studentObj._id] = true
      })
      return obj
    } else {
      studentsArr.forEach(studentObj => {
        obj.students[studentObj._id] = false
      })
      return obj
    }
  }
  const section = selector(state, 'groupId')
  let studentState = selector(state, 'students')

  return {
    jwt: getJwt(state),
    groupId: section ? section : '',
    selectAllValues: selectAllValues ? true : false,
    groupsById: groupsById(state),
    studentsLoading: StudentStore.isLoading(state),
    studentsArr: StudentStore.getStudentsSortedByName(state),
    studentState: studentState ? studentState : {},
    initialValues: getInitialValues()
  }
}

const mapDispatchToProps = dispatch => ({
  fetchStudents(data) {
    dispatch(startFetchFlattenedData(data))
  }
})

const StudentSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: CONDUCT_CERTIFICATE_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(StudentSelectorContainer)
)

export default StudentSelector
