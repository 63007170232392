import {
  APP_LOGOUT,
  ADD_VEHICLE,
  ADD_VEHICLE_START,
  ADD_VEHICLE_FAIL,
  GET_VEHICLE,
  GET_VEHICLE_START,
  GET_VEHICLE_FAIL,
  PATCH_VEHICLE_START,
  PATCH_VEHICLE_SUCCESS,
  PATCH_VEHICLE_FAIL
} from '../../helpers/actions'

import { getById } from '../../helpers/utils'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isLoadingPatch: false,
  isErr: false,
  transportVehicleData: [],
  transportVehicleById: {}
}

export const transportVehicle = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ADD_VEHICLE_START:
      return {
        ...state,
        isLoadingPost: true,
        isErr: false
      }
    case ADD_VEHICLE:
      return {
        ...state,
        isLoadingPost: false,
        isErr: false
      }
    case ADD_VEHICLE_FAIL:
      return {
        ...state,
        isLoadingPost: false,
        isErr: true
      }
    case GET_VEHICLE_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case GET_VEHICLE:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        transportVehicleData: action.data,
        transportVehicleById: getById(action.data, '_id')
      }
    case GET_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    case PATCH_VEHICLE_START:
      return {
        ...state,
        isLoadingPatch: true,
        isErr: false
      }
    case PATCH_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoadingPatch: false,
        isErr: false
      }
    case PATCH_VEHICLE_FAIL:
      return {
        ...state,
        isLoadingPatch: false,
        isErr: true
      }
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.transportVehicle.isLoading
export const getIsLoadingPost = state => state.transportVehicle.isLoadingPost
export const getIsLoadingPatch = state => state.transportVehicle.isLoadingPatch
export const getIsError = state => state.transportVehicle.isErr
export const getTransportVehicle = state =>
  state.transportVehicle.transportVehicleData
export const getTransportVehicleById = state =>
  state.transportVehicle.transportVehicleById
