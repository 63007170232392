import {
  checkStatus,
  parseJSON,
  AuthError,
  successToast,
  errorToast,
  composeAuth
} from '../helpers/utils.js'
import {
  startFetchInstitution,
  startFetchSupportInstitution
} from './institution'

import {
  APP_LOGIN_START,
  APP_LOGIN,
  APP_LOGIN_PARENT,
  APP_LOGIN_SUPPORT,
  APP_LOGIN_FAIL,
  APP_LOGOUT,
  APP_AUTH_FAILED,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  APP_PARENT_LEVEL,
  APP_SUPPORT_LEVEL
} from '../helpers/actions'

import { startFetchNavigation } from './navigation'
import { startFetchAllInstitution } from './institution'

export const emitLoginStart = () => ({
  type: APP_LOGIN_START
})

export const emitParentLevel = () => ({
  type: APP_PARENT_LEVEL
})

export const emitSupportLevel = () => ({
  type: APP_SUPPORT_LEVEL
})

export const emitLoggedIn = data => {
  const { jwt, name, level, level_id, user, institutionId, roles } = data
  return {
    type: APP_LOGIN,
    jwt,
    name,
    level,
    roles,
    level_id,
    username: user,
    institutionId
  }
}

export const emitParentLoggedIn = data => {
  const {
    jwt,
    name,
    level,
    level_id,
    user,
    _id,
    institutionId,
    students
  } = data
  return {
    type: APP_LOGIN_PARENT,
    jwt,
    name,
    level,
    level_id,
    _id,
    username: user,
    institutionId,
    students
  }
}

export const emitSupportLoggedIn = data => {
  const { jwt, name, level, level_id, user, _id } = data
  return {
    type: APP_LOGIN_SUPPORT,
    jwt,
    name,
    level,
    level_id,
    username: user,
    _id
  }
}

export const emitLoginFail = () => ({
  type: APP_LOGIN_FAIL
})

export const emitLogout = () => ({
  type: APP_LOGOUT
})

export const emitAuthFailed = () => ({
  type: APP_AUTH_FAILED
})

const sealTabsLogin = data => {
  let url = process.env.REACT_APP_SERVER_NEW + '/api/user/login'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

export const emitLogin = data => {
  return function(dispatch) {
    dispatch(emitLoginStart())
    return sealTabsLogin(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.level === 'parent') {
          if (json.students && json.students.length > 0) {
            dispatch(emitParentLoggedIn(json))
            // dispatch(startFetchNavigation(json.jwt))
          } else {
            dispatch(
              emitLoggedIn(Object.assign({}, json, { level: 'student' }))
            )
            dispatch(startFetchInstitution({ jwt: json.jwt }))
            dispatch(startFetchNavigation(json.jwt))
          }
        } else if (json.level === 'support' || json.level === 'superAdmin') {
          dispatch(emitSupportLoggedIn(json))
          dispatch(startFetchSupportInstitution({ jwt: json.jwt }))
        } else if (json.level === 'master') {
          dispatch(emitLoggedIn(json))
          dispatch(startFetchAllInstitution({ jwt: json.jwt }))
        } else {
          dispatch(emitLoggedIn(json))
          dispatch(startFetchInstitution({ jwt: json.jwt }))
          dispatch(startFetchNavigation(json.jwt))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitLoginFail())
      })
  }
}

//login by parent
const studentLoginByParent = ({ jwt, students }) => {
  let url = process.env.REACT_APP_SERVER_NEW + '/api/user/login/student'
  let Authorization = composeAuth(jwt)
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(students)
  })
}

export const emitStudentLoginByParent = data => {
  return dispatch => {
    // dispatch(emitLoginStart())
    return studentLoginByParent(data)
      .then(parseJSON)
      .then(json => {
        dispatch(emitLoggedIn(json))
        dispatch(startFetchInstitution({ jwt: json.jwt }))
        dispatch(startFetchNavigation(json.jwt))
        // if (json.statusCode === 201) {
        //   dispatch(emitLoggedIn(json))
        //   dispatch(startFetchInstitution({ jwt: json.jwt }))
        // } else {
        //   dispatch(emitLoginFail())
        // }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitLoginFail())
      })
  }
}

// change password

export const startChangePassword = data => {
  return function(dispatch) {
    dispatch(emitChangePasswordStart())
    return changePassword(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('password change successful')
        dispatch(changePasswordSuccess())
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else {
          errorToast('password change unsuccessful')
          dispatch(changePasswordFailure())
        }
      })
  }
}

const emitChangePasswordStart = () => ({
  type: CHANGE_PASSWORD_START
})

const changePassword = data => {
  const Authorization = composeAuth(data.jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/user/changepassword'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      oldpassword: data.oldPassword,
      newpassword: data.newPassword
    })
  })
}

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS
})

const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_FAILURE
})

// reset password

export const startResetPassword = data => {
  return function(dispatch) {
    dispatch(emitResetPasswordStart())
    return resetPassword(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast("New Password sent to student's phone")
        dispatch(resetPasswordSuccess())
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else {
          errorToast('password reset unsuccessful')
          dispatch(resetPasswordFailure())
        }
      })
  }
}

const emitResetPasswordStart = () => ({
  type: RESET_PASSWORD_START
})

const resetPassword = data => {
  const Authorization = composeAuth(data.jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/user/' +
    encodeURIComponent(data.levelId) +
    '/resetpassword'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      level: data.level
    })
  })
}

const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
})

const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAILURE
})

//login by support
const supportLogin = ({ jwt, institutionId }) => {
  let url = process.env.REACT_APP_SERVER_NEW + '/api/user/login/support'
  let Authorization = composeAuth(jwt)
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(institutionId)
  })
}

export const emitSupportLogin = data => {
  return dispatch => {
    dispatch(emitLoginStart())
    return supportLogin(data)
      .then(parseJSON)
      .then(json => {
        dispatch(emitLoggedIn(json))
        dispatch(startFetchInstitution({ jwt: json.jwt }))
        dispatch(startFetchNavigation(json.jwt))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitLoginFail())
      })
  }
}
