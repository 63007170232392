import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

class ModalComponent extends Component {
  render() {
    const {
      showModal,
      closeModal,
      modalHeaderText,
      modalBodyText,
      handleSubmit,
      buttonText,
      isReady
    } = this.props
    return (
      <Modal show={showModal}>
        <ModalHeader>
          <p className="modal-title">{modalHeaderText}</p>
        </ModalHeader>
        <ModalBody>
          <div className="info-cell">{modalBodyText}</div>
        </ModalBody>
        <ModalFooter>
          <div className="comment-button-container">
            <button className="button button--white" onClick={closeModal}>
              CLOSE
            </button>
            <button
              className="button button--blue"
              onClick={handleSubmit}
              disabled={isReady ? !isReady() : false}
            >
              {buttonText}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalComponent
