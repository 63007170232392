import {
  GROUPS_ADD_GROUP,
  GROUPS_GROUP_DATA,
  UMBRELLA_GROUPS_NAME,
  START_FETCH_GROUPS,
  ADD_GROUP_SUCCESS,
  GROUPS_PATCH_START,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_GROUP_DATA,
  PATCH_TEACHER_PER_SUBJECT,
  START_ADD_OR_REMOVE_STUDENTS,
  ADD_OR_REMOVE_STUDENTS_FAIL,
  ADD_STUDENTS_TO_ELECTIVES_SUCCESS,
  REMOVE_STUDENTS_FROM_ELECTIVES_SUCCESS,
  STUDENTS_PATCH_START,
  STUDENTS_PATCH_SUCCESS,
  UPDATE_ARCHIVED_STUDENT,
  UPDATE_UNARCHIVED_STUDENT,
  PROMOTION_GROUPS_START,
  PROMOTION_GROUPS_FAILURE,
  PROMOTION_GROUP_DATA,
  PROMOTION_ACADEMIC_YEAR_DATA,
  START_PROMOTION_ACADEMIC_YEAR_DATA,
  DELETE_CLASS_SUCCESS,
  DELETE_SECTION_SUCCESS,
  ADMISSION_YEAR_DATA_START,
  ADMISSION_YEAR_DATA,
  ADMISSION_YEAR_DATA_FAIL
} from '../helpers/actions.js'
import { NEWGROUP_FORM_NAME } from '../helpers/enums'
import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'

import { normalize } from 'normalizr'
import { reset } from 'redux-form'

import { groupListSchema } from '../schemas/group.js'
import { startFetchData } from './students'
import { emitAuthFailed } from './login.js'
import { saveData } from './ui/studentProfile'

import querystring from 'query-string'

const Groups = {}

/* TODO see if redundant */
export const emitGroupAdd = () => ({
  type: GROUPS_ADD_GROUP
})

export const emitGroupData = (groups, result, groupType) => ({
  type: GROUPS_GROUP_DATA,
  groups,
  result,
  groupType
})

export const emitUmbrellaClassNames = result => ({
  type: UMBRELLA_GROUPS_NAME,
  result
})

export const addGroupSuccess = () => ({
  type: ADD_GROUP_SUCCESS
})

export const emitSuccess = () => ({
  type: UPDATE_SUCCESS
})

export const emitFailure = () => ({
  type: UPDATE_FAILURE
})

export const emitStart = () => ({
  type: GROUPS_PATCH_START
})

export const emitPatchGroupData = group => ({
  type: UPDATE_GROUP_DATA,
  group
})

export const emitPromotionGroupStart = () => ({
  type: PROMOTION_GROUPS_START
})
export const emitPromotionGroupFailure = () => ({
  type: PROMOTION_GROUPS_FAILURE
})

export const emitPromotionalGroupData = (groups, result) => ({
  type: PROMOTION_GROUP_DATA,
  groups,
  result
})
export const emitStartFetchAcademicYear = data => ({
  type: START_PROMOTION_ACADEMIC_YEAR_DATA
})
export const emitPromotionalAcademicYear = data => ({
  type: PROMOTION_ACADEMIC_YEAR_DATA,
  data
})
const postNewGroup = (jwt, data) => {
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/studentgroup'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    /*
    Note: explicitly enumerate the fields so that
    extra parameters aren't being passed
    */
    body: JSON.stringify(data)
  })
}

export const startNewGroup = (jwt, data) => dispatch => {
  const arrPromises = data.map(group =>
    postNewGroup(jwt, group)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => json.name)
      .catch(err => {
        console.error('startNewGroup: ', err)
      })
  )

  dispatch(emitStart())
  return Promise.all(arrPromises)
    .then(arrGroupNames => {
      const length = arrGroupNames.length
      successToast(length + ' Class / Classes Created')
      dispatch(emitSuccess())
      dispatch(reset(NEWGROUP_FORM_NAME))
    })
    .catch(err => {
      errorToast(err)
      dispatch(emitFailure())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

export const emitStartFetch = () => ({
  type: START_FETCH_GROUPS
})

const fetchGroups = ({ jwt, type }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW + '/api/studentgroup/type/' + type
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchGroups = data => {
  return function(dispatch) {
    dispatch(emitStartFetch())
    return fetchGroups(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.data.length === 0) {
          dispatch(emitGroupData({}, []))
        } else {
          const normalizedGroups = normalize(json.data, groupListSchema)
          const {
            entities: { groups },
            result
          } = normalizedGroups
          dispatch(emitGroupData(groups, result, data.type))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

// fetch studentGroups for teachers
const fetchGroupsForTeacher = ({ jwt, type }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW + '/api/studentgroup/teacher/type/' + type
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchGroupsForTeacher = data => {
  return function(dispatch) {
    dispatch(emitStartFetch())
    return fetchGroupsForTeacher(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.data.length === 0) {
          dispatch(emitGroupData({}, []))
        } else {
          const normalizedGroups = normalize(json.data, groupListSchema)
          const {
            entities: { groups },
            result
          } = normalizedGroups
          dispatch(emitGroupData(groups, result))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

// fetch studentGroups for teachers by academic year
const fetchGroupsForTeacherByAcademicYear = ({ jwt, type, academic_year }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/teacher/type/${type}/academicyear/${academic_year}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchGroupsForTeacherByAcademicYear = data => {
  return function(dispatch) {
    dispatch(emitStartFetch())
    return fetchGroupsForTeacherByAcademicYear(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.data.length === 0) {
          dispatch(emitGroupData({}, []))
        } else {
          const normalizedGroups = normalize(json.data, groupListSchema)
          const {
            entities: { groups },
            result
          } = normalizedGroups
          dispatch(emitGroupData(groups, result, data?.type))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPromotionGroupFailure())
      })
  }
}

const addTestToGroup = ({ jwt, grpId, arrTests }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/' +
    encodeURIComponent(grpId) +
    '/addtests'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      tests: arrTests
    })
  })
}

Groups.startPatchTests = (jwt, arrSections, arrTests) => dispatch => {
  dispatch(emitStart())
  const patchGroupThunks = arrSections.map(grpId =>
    addTestToGroup({
      jwt,
      grpId,
      arrTests
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => json.data)
  )

  return Promise.all(patchGroupThunks)
    .then(json => {
      dispatch(emitSuccess())
    })
    .catch(err => {
      console.error(err)
      dispatch(emitFailure())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

const fetchGroupsById = ({ jwt, _id }) => {
  const Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/ids?' +
    querystring.stringify({ ids: _id })
  // const url =
  //   process.env.REACT_APP_SERVER_NEW +
  //   '/api/studentgroup/' +
  //   encodeURIComponent(JSON.stringify(_id))
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchGroupsById = data => {
  return function(dispatch) {
    return fetchGroupsById(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(
          startFetchData({
            jwt: data.jwt,
            students: json.data[0].students
          })
        )
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}
export const startFetchGroupsByIdSmsInfo = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchGroupsById(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.data.length === 0) {
          dispatch(emitGroupData({}, []))
        } else {
          const normalizedGroups = normalize(json.data, groupListSchema)
          const {
            entities: { groups },
            result
          } = normalizedGroups
          dispatch(emitGroupData(groups, result))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}
export const patchAddSubject = ({ jwt, subId, arrGroups }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/' +
    encodeURIComponent(JSON.stringify(arrGroups)) +
    '/addsubject'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      subjectId: subId
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      return json.data
    })
    .catch(err => {
      console.error(err)
      /*TODO: emit authFailed*/
    })
}

export const addSubjectsToMultipleGroups = ({ jwt, arrGroups, subjects }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/multi/addsubjects?' +
    querystring.stringify({ _id: arrGroups })
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      subjects
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      return json.data
    })
    .catch(err => {
      console.error(err)
      /*TODO: emit authFailed*/
    })
}

const fetchUmbrellaGroups = ({ jwt }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW + '/api/studentgroup/umbrella_groups'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchUmbrellaGroups = data => {
  return dispatch => {
    return fetchUmbrellaGroups(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitUmbrellaClassNames(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const editUmbrellaGroupName = ({
  jwt,
  data: { existingUmbrellaGroupName, newUmbrellaGroupName }
}) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/edit/class/' +
    encodeURIComponent(existingUmbrellaGroupName)
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      newUmbrellaGroupName
    })
  })
}

export const startPatchUmbrellaGroupName = data => {
  return dispatch => {
    dispatch(emitStart())
    return editUmbrellaGroupName(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitSuccess())
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const editUmbrellaGroupNameAndOrder = ({
  jwt,
  academic_year,
  data: {
    existingUmbrellaGroupName,
    new_umbrella_group_name,
    orderUmbrella_group
  }
}) => {
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/studentgroup/umbrella_group/academic_year/${academic_year}/${existingUmbrellaGroupName}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      new_umbrella_group_name,
      orderUmbrella_group
    })
  })
}

export const startPatchUmbrellaGroupNameAndOrder = data => {
  return dispatch => {
    dispatch(emitStart())
    return editUmbrellaGroupNameAndOrder(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitSuccess())
        successToast('Class information updated successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        errorToast('Failed to update class information.')
      })
  }
}

const patchGroup = (jwt, id, data) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(id)

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startGroupPatch = ({ jwt, groupId, data }) => dispatch => {
  dispatch(emitStart())
  return patchGroup(jwt, groupId, data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Class information updated successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Class information could not be updated')
    })
}

const deleteGroupSubjects = (jwt, groupId, subjects) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/removesubject'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ subjects })
  })
}

export const startDeleteGroupSubjects = ({ jwt, data }) => dispatch => {
  dispatch(emitStart())
  return deleteGroupSubjects(jwt, data.groupId, data.subjects)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data.UpdatedStudentGroup))
      dispatch(emitSuccess())
      successToast('Subject(s) deleted successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Subject(s) could not be deleted')
    })
}

// delete tests

const deleteTests = ({ jwt, groupId, tests }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/removetest'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ tests })
  })
}

export const startDeleteGroupTests = data => dispatch => {
  dispatch(emitStart())
  return deleteTests(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Test(s) deleted successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Test(s) could not be deleted')
    })
}

const deleteSubTests = ({ jwt, groupId, tests }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/removetest'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ tests })
  })
}

export const startDeleteGroupSubTests = data => dispatch => {
  dispatch(emitStart())
  return deleteSubTests(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Test(s) deleted successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Test(s) could not be deleted')
    })
}

// patch/add teachers for subject
const addTeacherToSubject = ({ jwt, groupId, subjectWithTeachers }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/addteacher'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(subjectWithTeachers)
  })
}

const addMarksTeacherToSubject = ({ jwt, groupId, subjectWithTeachers }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/addmarksEntryTeacher'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(subjectWithTeachers)
  })
}

export const startPatchTeacher = data => dispatch => {
  dispatch(emitStart())
  return addTeacherToSubject(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitTeacherData(json.data))
      successToast('Teacher Assigned successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Unable to assign teacher')
    })
}

export const startPatchMarksTeacher = data => dispatch => {
  dispatch(emitStart())
  return addMarksTeacherToSubject(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitTeacherData(json.data))
      successToast('Teacher Assigned successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Unable to assign teacher')
    })
}

const emitTeacherData = data => ({
  type: PATCH_TEACHER_PER_SUBJECT,
  data
})

// remove teacher from a subject
const removeTeacherFromSubject = ({ jwt, groupId, subjectWithTeachers }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/removeteacher'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(subjectWithTeachers)
  })
}

const removeMarkTeacherFromSubject = ({
  jwt,
  groupId,
  subjectWithTeachers
}) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/removemarksEntryTeacher'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(subjectWithTeachers)
  })
}

export const startDeleteTeacher = data => dispatch => {
  dispatch(emitStart())
  return removeTeacherFromSubject(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitTeacherData(json.data))
      successToast('Teacher removed successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Unable to remove teacher')
    })
}

export const startDeleteMarkTeacher = data => dispatch => {
  dispatch(emitStart())
  return removeMarkTeacherFromSubject(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitTeacherData(json.data))
      successToast('Teacher removed successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Unable to remove teacher')
    })
}

// add students to electives
const addStudentsToElectives = (jwt, data) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(data.groupId) +
    `/addstudentstoelectives`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    /*
    Note: explicitly enumerate the fields so that
    extra parameters aren't being passed
    */
    body: JSON.stringify({
      subjectId: data.subjectId,
      studentIds: data.studentIds
    })
  })
}

export const startAddStudentsToElectives = ({ jwt, data }) => {
  return function(dispatch) {
    dispatch(emiStartAddOrRemoveStudents())
    return addStudentsToElectives(jwt, data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitAddStudentsToElectivesSuccess(json.data))
        successToast('students Added Successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitAddOrRemoveStudentsFail())
        errorToast('Students Not Added')
      })
  }
}

const emiStartAddOrRemoveStudents = () => ({
  type: START_ADD_OR_REMOVE_STUDENTS
})

const emitAddStudentsToElectivesSuccess = data => ({
  type: ADD_STUDENTS_TO_ELECTIVES_SUCCESS,
  data
})

const emitAddOrRemoveStudentsFail = () => ({
  type: ADD_OR_REMOVE_STUDENTS_FAIL
})

// remove students from electives
const removeStudentsFromElectives = (jwt, data) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(data.groupId) +
    `/removestudentsfromelectives`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    /*
    Note: explicitly enumerate the fields so that
    extra parameters aren't being passed
    */
    body: JSON.stringify({
      subjectId: data.subjectId,
      studentIds: data.studentIds
    })
  })
}

export const startRemoveStudentsFromElectives = ({ jwt, data }) => {
  return function(dispatch) {
    dispatch(emiStartAddOrRemoveStudents())
    return removeStudentsFromElectives(jwt, data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitRemoveStudentsFromElectivesSuccess(json.data))
        successToast('students Removed Successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitAddOrRemoveStudentsFail())
        errorToast('Students Not Removed')
      })
  }
}

const emitRemoveStudentsFromElectivesSuccess = data => ({
  type: REMOVE_STUDENTS_FROM_ELECTIVES_SUCCESS,
  data
})

const changeStudentToGroup = ({ jwt, sectionId, studentId, newSectionId }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/${sectionId}/changestudent/studentgroupnew/${newSectionId}`
  const body = {
    students: [studentId]
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}

export const startChangeStudentSection = body => dispatch => {
  return changeStudentToGroup(body)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('successfully Changed Class')
      dispatch(
        saveData({
          groupId: json.data[1]['_id'],
          umbrellaGroup: json.data[1]['umbrella_group'],
          groupType: json.data[1]['group_type']
        })
      )
      dispatch(emitPatchGroupData(json.data[0]))
      dispatch(emitPatchGroupData(json.data[1]))
    })
    .catch(err => {
      errorToast('Failed to Add Student To New Group', err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

// archive students
export const archiveStudent = data => {
  const { jwt, groupId, studentId, reason } = data
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/studentgroup/${groupId}/archivestudent`
  return function(dispatch) {
    dispatch(emitPatchStart())
    return fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization
      },
      body: JSON.stringify({
        students: [studentId],
        reason
      })
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Student archived successfully')
        dispatch(emitPatchSuccess(json.data))
        dispatch(updateArchivedStudent(studentId))
      })
      .catch(err => {
        errorToast('Student archive unsuccessful')
      })
  }
}

const emitPatchStart = () => ({
  type: STUDENTS_PATCH_START
})

const emitPatchSuccess = data => ({
  type: STUDENTS_PATCH_SUCCESS,
  data
})

// const emitPatchFail = () => ({
//   type: STUDENTS_PATCH_FAIL
// })

const updateArchivedStudent = studentId => ({
  type: UPDATE_ARCHIVED_STUDENT,
  studentId
})

//unarcheive students
export const unArchiveStudent = data => {
  const { jwt, groupId, studentId } = data
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/studentgroup/${groupId}/unarchivestudent`
  return function(dispatch) {
    dispatch(emitPatchStart())
    return fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization
      },
      body: JSON.stringify({
        students: [studentId]
      })
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPatchSuccess(json.data))
        dispatch(updateUnarchivedStudent(studentId))
        successToast('Student unarchived successfully')
      })
      .catch(err => {
        errorToast('Student unarchive unsuccessful')
      })
  }
}
const updateUnarchivedStudent = studentId => ({
  type: UPDATE_UNARCHIVED_STUDENT,
  studentId
})

const fetchPromotionGroups = ({ jwt, type, academic_year }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/type/${type}/academic_year/${academic_year}`

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchGroupsPerYear = data => {
  return function(dispatch) {
    dispatch(emitStartFetch())
    return fetchPromotionGroups(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.data.length === 0) {
          dispatch(emitGroupData({}, []))
        } else {
          const normalizedGroups = normalize(json.data, groupListSchema)
          const {
            entities: { groups },
            result
          } = normalizedGroups
          dispatch(emitGroupData(groups, result, data.type))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitPromotionGroupFailure())
      })
  }
}

const fetchAcademicYear = ({ jwt, type }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/academicyear/type/${type}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchAcademicYearPromotionalGroups = data => {
  return function(dispatch) {
    dispatch(emitStartFetchAcademicYear())
    return fetchAcademicYear(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPromotionalAcademicYear(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

//fetch admission year
export const emitAdmissionYearStart = data => ({
  type: ADMISSION_YEAR_DATA_START,
  data
})
export const emitAdmissionYear = data => ({
  type: ADMISSION_YEAR_DATA,
  data
})
export const emitAdmissionYearFail = data => ({
  type: ADMISSION_YEAR_DATA_FAIL,
  data
})
export const startFetchAdmissionYearPromotionalGroups = data => {
  return function(dispatch) {
    dispatch(emitAdmissionYearStart())
    return fetchAcademicYear(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitAdmissionYear(json.data))
      })
      .catch(err => {
        console.error(err)
        dispatch(emitAdmissionYearFail())
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}
export const startFetchPromotionalGroups = data => {
  return function(dispatch) {
    dispatch(emitPromotionGroupStart())
    return fetchPromotionGroups(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (json.data.length === 0) {
          dispatch(emitPromotionalGroupData({}, []))
        } else {
          const normalizedGroups = normalize(json.data, groupListSchema)
          const {
            entities: { groups },
            result
          } = normalizedGroups
          dispatch(emitPromotionalGroupData(groups, result))
        }
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitPromotionGroupFailure())
      })
  }
}

export default Groups

//deleting classes

const emitDeleteClassSuccess = data => ({
  type: DELETE_CLASS_SUCCESS,
  data
})

const deleteClass = ({ jwt, data }) => {
  const Authorization = composeAuth(jwt)
  // const url =
  //   process.env.REACT_APP_SERVER +
  //   `/api/studentgroup/` +
  //   encodeURIComponent(groupId) +
  //   '/removeteacher'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startDeleteClass = data => dispatch => {
  dispatch(emitStart())
  return deleteClass(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitDeleteClassSuccess(json.data))
      successToast('Class Deleted successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Unable to delete Class')
    })
}

//deleting studentgroup

const emitDeleteSectionSuccess = data => ({
  type: DELETE_SECTION_SUCCESS,
  data
})

const deleteSection = ({ jwt, data }) => {
  const Authorization = composeAuth(jwt)
  // const url =
  //   process.env.REACT_APP_SERVER +
  //   `/api/studentgroup/` +
  //   encodeURIComponent(groupId) +
  //   '/removeteacher'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startDeleteSection = data => dispatch => {
  dispatch(emitStart())
  return deleteSection(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitDeleteSectionSuccess(json.data))
      successToast('Section deleted successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Unable to delete Section')
    })
}

//add subjects to a studentGroup
const addSubjectToGroup = ({ jwt, groupId, subjects }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/addsubjects'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ subjects })
  })
}

export const startAddSubjectsToGroup = data => dispatch => {
  dispatch(emitStart())
  return addSubjectToGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Subject(s) added successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Subject(s) could not be added')
    })
}

//add tests to a studentGroup
const addTestsToGroup = ({ jwt, groupId, tests }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/` +
    encodeURIComponent(groupId) +
    '/addtests'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ tests })
  })
}

export const startAddTestsToGroup = data => dispatch => {
  dispatch(emitStart())
  return addTestsToGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Test(s) added successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure())
      errorToast('Test(s) could not be added')
    })
}

//patch studentgroup tc template
const patchStudentGroupTcTemplate = ({ jwt, studentGroup, payload }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/tctemplate?' +
    querystring.stringify({ studentgroup: studentGroup })
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchStudentGroupTcTemplate = data => {
  return dispatch => {
    dispatch(emitStart())
    return patchStudentGroupTcTemplate(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitSuccess())
        successToast('TC Template updated successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitFailure())
        errorToast('Failed to update TC Template')
      })
  }
}

// patch studentgroup rc template startPatchStudentGroupRcTemplate
const patchStudentGroupRcTemplate = ({ jwt, studentGroup, payload }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/rctemplate?' +
    querystring.stringify({ studentgroup: studentGroup })
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchStudentGroupRcTemplate = data => {
  return dispatch => {
    dispatch(emitStart())
    return patchStudentGroupRcTemplate(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitSuccess())
        successToast('RC Template updated successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitFailure())
        errorToast('Failed to update RC Template')
      })
  }
}

const patchStudentGroupPcTemplate = ({ jwt, studentGroup, payload }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/pctemplate?' +
    querystring.stringify({ studentgroup: studentGroup })
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchStudentGroupPcTemplate = data => {
  return dispatch => {
    dispatch(emitStart())
    return patchStudentGroupPcTemplate(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitSuccess())
        successToast('PC Template updated successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitFailure())
        errorToast('Failed to update PC Template')
      })
  }
}

export const actionGetGroupsPerYear = {
  superAdmin: startFetchGroupsPerYear,
  support: startFetchGroupsPerYear,
  admin: startFetchGroupsPerYear,
  principal: startFetchGroupsPerYear,
  teacher: startFetchGroupsForTeacherByAcademicYear
}

export const actionGetGroupsCurrentAcademicYear = {
  superAdmin: startFetchGroups,
  support: startFetchGroups,
  admin: startFetchGroups,
  principal: startFetchGroups,
  teacher: startFetchGroupsForTeacher
}
