// import packages
// import action types
import {
  APP_LOGOUT,
  START_REPORT_CARD_REQUEST,
  REPORT_CARD_REQUEST_SUCCESS,
  REPORT_CARD_REQUEST_FAIL,
  START_FETCH_REPORT_CARD_STATUS,
  REPORT_CARD_STATUS_FETCH_FAIL,
  REPORT_CARD_STATUS_DATA,
  MERGED_PDF_DATA,
  START_FETCH_REPORT_CARD_STATUS_PER_STUDENT,
  REPORT_CARD_STATUS_FETCH_FAIL_PER_STUDENT,
  REPORT_CARD_STATUS_DATA_PER_STUDENT,
  CLEAR_MERGED_PDF_DATA,
  PUBLISH_REPORT_CARD_START,
  PUBLISH_REPORT_CARD_FAIL,
  PUBLISH_REPORT_CARD_SUCCESS,
  GET_PUBLISHED_REPORT_CARD_STUDENT_START,
  GET_PUBLISHED_REPORT_CARD_STUDENT_SUCCESS,
  GET_PUBLISHED_REPORT_CARD_STUDENT_FAIL,
  PUBLISH_MARKS_START,
  PUBLISH_MARKS_SUCCESS,
  PUBLISH_MARKS_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isWorking: false,
  byId: {},
  allIds: [],
  mergedPDFData: {},
  isPublishReportCardLoading: false,
  isError: false,
  studentPublishedReportCard: [],
  isPublishMarksLoading: false
}

export const reportCard = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_REPORT_CARD_REQUEST:
      return Object.assign({}, state, {
        isWorking: true
      })
    case REPORT_CARD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isWorking: false
      })
    case REPORT_CARD_REQUEST_FAIL:
      return Object.assign({}, state, {
        isWorking: false
      })
    case START_FETCH_REPORT_CARD_STATUS:
      return Object.assign({}, state, {
        isLoading: true
      })
    case REPORT_CARD_STATUS_DATA:
      return Object.assign({}, state, {
        byId: action.entities,
        allIds: action.result,
        isLoading: false
      })
    case REPORT_CARD_STATUS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case START_FETCH_REPORT_CARD_STATUS_PER_STUDENT:
      return Object.assign({}, state, {
        isLoading: true,
        isPublishReportCardLoading: true
      })
    case REPORT_CARD_STATUS_DATA_PER_STUDENT:
      return Object.assign({}, state, {
        byId: action.entities,
        allIds: action.result,
        isLoading: false,
        isPublishReportCardLoading: false
      })
    case REPORT_CARD_STATUS_FETCH_FAIL_PER_STUDENT:
      return Object.assign({}, state, {
        isLoading: false,
        isPublishReportCardLoading: false
      })
    case MERGED_PDF_DATA:
      return Object.assign({}, state, {
        mergedPDFData: action.data.result,
        isWorking: false
      })
    case CLEAR_MERGED_PDF_DATA:
      return Object.assign({}, state, {
        mergedPDFData: {},
        isWorking: false
      })
    case PUBLISH_REPORT_CARD_START:
      return Object.assign({}, state, {
        isPublishReportCardLoading: true,
        isError: false
      })
    case PUBLISH_REPORT_CARD_SUCCESS:
      return Object.assign({}, state, {
        isPublishReportCardLoading: false,
        isError: false
      })
    case PUBLISH_REPORT_CARD_FAIL:
      return Object.assign({}, state, {
        isPublishReportCardLoading: false,
        isError: true
      })
    case GET_PUBLISHED_REPORT_CARD_STUDENT_START:
      return Object.assign({}, state, {
        isPublishReportCardLoading: true,
        isError: false
      })
    case GET_PUBLISHED_REPORT_CARD_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        isPublishReportCardLoading: false,
        studentPublishedReportCard: action.data,
        isError: false
      })
    case GET_PUBLISHED_REPORT_CARD_STUDENT_FAIL:
      return Object.assign({}, state, {
        isPublishReportCardLoading: false,
        isError: true
      })
    case PUBLISH_MARKS_START:
      return Object.assign({}, state, {
        isPublishMarksLoading: true,
        isError: false
      })
    case PUBLISH_MARKS_SUCCESS:
      return Object.assign({}, state, {
        isPublishMarksLoading: false,
        isError: false
      })
    case PUBLISH_MARKS_FAIL:
      return Object.assign({}, state, {
        isPublishMarksLoading: false,
        isError: true
      })
    default:
      return state
  }
}

export const byId = state => state.reportCard.byId
export const isLoading = state => state.reportCard.isLoading
export const isWorking = state => state.reportCard.isWorking
export const mergedPDFData = state => state.reportCard.mergedPDFData
export const getPublishReportCardLoading = state =>
  state.reportCard.isPublishReportCardLoading
export const getError = state => state.reportCard.isError
export const getStudentPublishedReportCard = state =>
  state.reportCard.studentPublishedReportCard
export const getPublishMarksLoading = state =>
  state.reportCard.isPublishMarksLoading
