// import packages
import classNames from 'classnames'
import React from 'react'

export const renderField = ({
  input,
  type,
  disableField,
  existingValue,
  meta: { error, touched, visited }
}) => (
  <label
    className={classNames({
      'marks-field': true,
      'marks-field--smallwidth': true,
      'marks-field--error': error
    })}
  >
    <input disabled={disableField} {...input} type={type} />
    {visited && touched && error && (
      <span className="marks-field__error">{error}</span>
    )}
  </label>
)
