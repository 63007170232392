// import packages
// import action types
import {
  APP_LOGOUT,
  START_CONDUCT_CERTIFICATE_FETCH,
  FETCH_CONDUCT_CERTIFICATE_SUCCESS,
  FETCH_CONDUCT_CERTIFICATE_FAIL,
  START_MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  FAILED_MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  START_REGISTER_CERTIFICATE_FETCH,
  FETCH_REGISTER_CERTIFICATE_FAIL,
  FETCH_REGISTER_CERTIFICATE_SUCCESS,
  START_MERGED_REGISTER_CERTIFICATE_PDF_DATA,
  MERGED_REGISTER_CERTIFICATE_PDF_DATA,
  FAILED_MERGED_REGISTER_CERTIFICATE_PDF_DATA
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  isLoadingRegisterCertificate: false,
  registerCertificateById: {},
  registerCertificateAllIds: [],
  mergedPDFData: '',
  mergedPDFDataRegisterCertificate: ''
}

export const conductCertificate = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_CONDUCT_CERTIFICATE_FETCH:
      return Object.assign({}, state, {
        isLoading: true
      })
    case FETCH_CONDUCT_CERTIFICATE_SUCCESS:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false
      })
    case FETCH_CONDUCT_CERTIFICATE_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case START_MERGED_CONDUCT_CERTIFICATE_PDF_DATA:
      return Object.assign({}, state, {
        isLoading: true,
        mergedPDFData: ''
      })
    case MERGED_CONDUCT_CERTIFICATE_PDF_DATA:
      return Object.assign({}, state, {
        mergedPDFData: action.data,
        isLoading: false
      })
    case FAILED_MERGED_CONDUCT_CERTIFICATE_PDF_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        mergedPDFData: ''
      })

    case START_REGISTER_CERTIFICATE_FETCH:
      return Object.assign({}, state, {
        isLoadingRegisterCertificate: true
      })
    case FETCH_REGISTER_CERTIFICATE_SUCCESS:
      return Object.assign({}, state, {
        registerCertificateById: action.byId,
        registerCertificateAllIds: action.allIds,
        isLoadingRegisterCertificate: false
      })
    case FETCH_REGISTER_CERTIFICATE_FAIL:
      return Object.assign({}, state, {
        isLoadingRegisterCertificate: false
        // registerCertificateById:{},
        // registerCertificateAllIds:[],
      })

    case START_MERGED_REGISTER_CERTIFICATE_PDF_DATA:
      return Object.assign({}, state, {
        isLoading: true,
        mergedPDFmergedPDFDataRegisterCertificateData: ''
      })
    case MERGED_REGISTER_CERTIFICATE_PDF_DATA:
      return Object.assign({}, state, {
        mergedPDFDataRegisterCertificate: action.data,
        isLoading: false
      })
    case FAILED_MERGED_REGISTER_CERTIFICATE_PDF_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        mergedPDFDataRegisterCertificate: ''
      })
    default:
      return state
  }
}

export const byId = state => state.conductCertificate.byId
export const isLoading = state => state.conductCertificate.isLoading
export const isWorking = state => state.conductCertificate.isWorking
export const mergedPDFData = state => state.conductCertificate.mergedPDFData
export const isLoadingRegisterCertificate = state =>
  state.conductCertificate.isLoadingRegisterCertificate
export const registerCertificateById = state =>
  state.conductCertificate.registerCertificateById
export const registerCertificateAllIds = state =>
  state.conductCertificate.registerCertificateAllIds
export const mergedPDFDataRegisterCertificate = state =>
  state.conductCertificate.mergedPDFDataRegisterCertificate
