import {
  APP_LOGOUT,
  SET_ACTIVE_DOCUMNET_UPLOAD_DATA,
  CLEAR_ACTIVE_DOCUMENT_UPLOAD_DATA
} from '../../helpers/actions'

const initialState = {
  activeId: '',
  type: ''
}

export const documentUpload = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SET_ACTIVE_DOCUMNET_UPLOAD_DATA:
      return Object.assign({}, state, {
        activeId: action.id,
        type: action.level
      })
    case CLEAR_ACTIVE_DOCUMENT_UPLOAD_DATA:
      return Object.assign({}, state, {
        activeId: '',
        type: ''
      })
    default:
      return state
  }
}

export const getActiveId = state => state.ui.documentUpload.activeId
export const getType = state => state.ui.documentUpload.type
