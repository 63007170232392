import {
  ADD_NEW_DEVICE,
  ADD_NEW_DEVICE_FAILED,
  ADD_NEW_DEVICE_START,
  GET_ALL_DEVICES,
  GET_ALL_DEVICES_START,
  GET_ALL_DEVICES_FAILED,
  GET_FINGERPRINT,
  GET_FINGERPRINT_START,
  GET_FINGERPRINT_FAILED,
  ASSIGN_TEACHER_FINGERPRINT,
  ASSIGN_TEACHER_FINGERPRINT_START,
  ASSIGN_TEACHER_FINGERPRINT_FAILED,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isErr: false,
  errMsg: '',
  devices: [],
  isFingerPrintLoading: false,
  isFingerPrintErr: false,
  fingerPrintArr: [],
  message: '',
  assignLoading: false,
  assignError: false,
  assignedMessage: ''
}
export const biometric = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ADD_NEW_DEVICE_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErr: false,
        errMsg: ''
      })
    case ADD_NEW_DEVICE:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: false,
        message: 'devicePosted'
      })
    case ADD_NEW_DEVICE_FAILED:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case GET_ALL_DEVICES_START:
      return {
        ...state,
        isLoading: true,
        isErr: false,
        devices: []
      }
    case GET_ALL_DEVICES:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        devices: action.data
      }
    case GET_ALL_DEVICES_FAILED:
      return {
        ...state,
        isLoading: false,
        isErr: true,
        devices: []
      }
    case GET_FINGERPRINT_START:
      return {
        ...state,
        isFingerPrintLoading: true,
        isFingerPrintErr: false
      }
    case GET_FINGERPRINT:
      return {
        ...state,
        isFingerPrintLoading: false,
        fingerPrintArr: action.data,
        isFingerPrintErr: false
      }
    case GET_FINGERPRINT_FAILED:
      return {
        ...state,
        isFingerPrintLoading: false,
        isFingerPrintErr: true
      }
    case ASSIGN_TEACHER_FINGERPRINT_START:
      return {
        ...state,
        assignLoading: true,
        assignError: false
      }
    case ASSIGN_TEACHER_FINGERPRINT:
      return {
        ...state,
        assignLoading: false,
        assignError: false,
        assignedMessage: 'assigned'
      }
    case ASSIGN_TEACHER_FINGERPRINT_FAILED:
      return {
        ...state,
        assignLoading: false,
        assignError: true,
        assignedMessage: action.errMsg
      }
    default:
      return state
  }
}

export const getDevicePostIsLoading = state => state.biometric.isLoadingPost
export const getBiometricError = state => state.biometric.isErr
export const getBiometricErrMsg = state => state.biometric.errMsg
export const getDevices = state => state.biometric.devices
export const getDevicesLoading = state => state.biometric.isLoading
export const getFingerPrintLoading = state =>
  state.biometric.isFingerPrintLoading
export const getFingerPrintArr = state => state.biometric.fingerPrintArr
export const getDeviceMessage = state => state.biometric.message
export const getAssignedMessage = state => state.biometric.assignedMessage
export const getAssignLoading = state => state.biometric.assignLoading
export const getAssignError = state => state.biometric.assignError
