// import packages
import React from 'react'

import { ErrorLayoutComponent } from './layoutComponent'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // console.log(error, info,"error");
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorLayoutComponent />
    }

    return this.props.children
  }
}
export default ErrorBoundary
