// import packages
import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
// import helper functions
import { validateMaxMarks } from './validate'
import { renderField } from './fields'
import { getSubjectIdOfAStudent } from '../../helpers/utils'
// import selectors
import { groupsById } from '../../reducers/groups'
import { byId as subjectsById } from '../../reducers/subjects'

const renderTdMaxMarks = (testsId, handleChange) => {
  let tdArray = []
  testsId.map((test, index) => {
    const tdMaxMarksNameField = (
      <td className="max-marks-cell" key={2 * index}>
        <span>Max Marks</span>
        <Field
          component={renderField}
          type="number"
          name={`${test}.MaxMarks`}
          validate={[validateMaxMarks]}
          onBlur={handleChange}
        />
      </td>
    )
    const tdEmpty = <td key={2 * index + 1} />
    tdArray = tdArray.concat([tdMaxMarksNameField, tdEmpty])
    return test
  })
  return tdArray
}

const MaxMarksComponentArrayContainer = ({
  groupId,
  testsId,
  marksform,
  subjectId,
  resetMarks,
  showMaxMarksModalError,
  mainSubjectId,
  groupsById,
  subjectsById
}) => {
  const handleChange = e => {
    const [testId] = e.target.name.split('.')
    const failure = {}
    if (
      marksform.initial[testId] !== undefined &&
      marksform.initial[testId].MaxMarks !== undefined
    ) {
      Object.keys(marksform.initial[testId])
        .filter(studentId => studentId !== 'MaxMarks')
        .map(studentId => {
          const subject = getSubjectIdOfAStudent({
            studentId,
            subjectId,
            subjectsById,
            groupsById,
            mainSubjectId,
            groupId
          })
          failure[`${testId}.${subject}.${studentId}`] = 'failure'
          return studentId
        })
    }
    if (
      marksform.values[testId] !== undefined &&
      marksform.values[testId].MaxMarks !== undefined
    ) {
      Object.keys(marksform.values[testId])
        .filter(studentId => studentId !== 'MaxMarks')
        .map(studentId => {
          const subject = getSubjectIdOfAStudent({
            studentId,
            subjectId,
            subjectsById,
            groupsById,
            mainSubjectId,
            groupId
          })
          Object.assign(failure, {
            [`${testId}.${subject}.${studentId}`]: 'failure'
          })
          return studentId
        })
    }
    showMaxMarksModalError()
    resetMarks(failure)
  }

  return (
    <tr>
      {/* <td /> */}
      <td />
      <td />
      <td />
      <td />
      {renderTdMaxMarks(testsId, handleChange)}
      {/* <td /> */}
    </tr>
  )
}

MaxMarksComponentArrayContainer.propTypes = {
  testsId: PropTypes.array.isRequired,
  marksform: PropTypes.shape({
    values: PropTypes.object,
    syncErrors: PropTypes.object,
    initial: PropTypes.object
  }),
  subjectId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  resetMarks: PropTypes.func.isRequired,
  showMaxMarksModalError: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  groupsById: groupsById(state),
  subjectsById: subjectsById(state)
})

export const MaxMarksComponentArray = connect(mapStateToProps)(
  MaxMarksComponentArrayContainer
)
