import { ZERO_REASON } from '../../helpers/enums'

export const validate = values => {
  const errors = {}
  const keys = Object.keys(values)
  if (keys.length !== 0) {
    keys.map(key => {
      errors[key] = {}
      if (values[key].MaxMarks !== undefined && !values[key].MaxMarks) {
        errors[key].MaxMarks = 'Max Marks required'
      } else if (values[key].MaxMarks < 0) {
        errors[key].MaxMarks = 'Max Marks must be greater than zero'
      }
      const studentIds = Object.keys(values[key])
      studentIds.map(id => {
        if (id !== 'MaxMarks') {
          if (
            isNaN(Number(values[key][id])) &&
            values[key][id].toLowerCase() !== 'ab' &&
            values[key][id].toLowerCase() !== 'ch' &&
            values[key][id].toLowerCase() !== 'ne' &&
            values[key][id].toLowerCase() !== 'ns' &&
            values[key][id].toLowerCase() !== 'ex'
          ) {
            errors[key][id] = 'Unsupported zero reasons'
          } else if (values[key][id] && !values[key].MaxMarks) {
            errors[key][id] = 'Max Marks is empty'
          } else if (Number(values[key][id]) > values[key].MaxMarks) {
            errors[key][id] = 'value greater than max'
          }
        }
        return id
      })
      return key
    })
  }
  return errors
}

export const validateMaxMarks = (value, allValues) => {
  if (!value) return 'Max Marks is required'
  if (value < 0) return 'Max Marks must be greater than zero'
  return undefined
}

export const validateMark = (value, allValues, props, name) => {
  if (value && isNaN(Number(value))) {
    if (!Object.keys(ZERO_REASON).includes(value.toLowerCase())) {
      return 'Unsupported zero reasons'
    }
  }
  if (value && Object.keys(allValues).length) {
    const stringArr = name.split('.')
    let maxMarks = 0
    if (stringArr.length > 0) maxMarks = allValues[stringArr[0]].MaxMarks
    if (value > Number(maxMarks)) return 'Mark cannot be greater than Max Marks'
  }
  return undefined
}

export const validateGrade = (value, allValues, props) => {
  const { validGrades } = props
  if (value) {
    const reducedValue = getValue(value)
    if (
      !Object.keys(ZERO_REASON).includes(reducedValue) &&
      !validGrades.includes(reducedValue)
    )
      return 'Not a valid grade'
  }
  return undefined
}

export const getValue = value => {
  let str = value.replace(/\s+/g, '').toUpperCase()
  return str
}
