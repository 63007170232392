import {
  APP_LOGOUT,
  FETCH_MAIL_BY_STATUS,
  POST_MAIL_DATA,
  MAIL_POST_FAILURE,
  SET_POST_MAIL_STATUS,
  SET_POST_MAIL,
  GET_EMAIL_START,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAIL,
  GET_EMAIL_LOGS_BY_EMAILID_START,
  GET_EMAIL_LOGS_BY_EMAILID,
  GET_EMAIL_LOGS_BY_EMAILID_FAIL
} from '../helpers/actions.js'

import { createSelector } from 'reselect'

const initialState = {
  mailById: {},
  mailAllIds: [],
  postSuccess: false,
  isLoading: false,
  message: '',
  isLoadingEmailLogsByEmailId: false,
  emailLogs: []
}

export const mailById = state => state.mail.mailById
export const mailAllIds = state => state.mail.mailAllIds
export const postStatus = state => state.mail.postSuccess
export const getIsLoading = state => state.mail.isLoading
export const getPostMessage = state => state.mail.message
export const getIsLoadingEmailLogsByEmailId = state =>
  state.mail.isLoadingEmailLogsByEmailId
export const getEmailLogs = state => state.mail.emailLogs

export const getmailArray = createSelector(
  mailById,
  mailAllIds,
  (byId, allIds) => {
    if (!allIds || !byId) return []
    return allIds
      .map(id => byId[id])
      .sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate)
      })
  }
)

export const mail = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case FETCH_MAIL_BY_STATUS:
      return Object.assign({}, state, {
        isLoading: true
      })
    case POST_MAIL_DATA:
      return Object.assign({}, state, {
        postSuccess: true,
        isLoading: false,
        message: action.message
      })
    case MAIL_POST_FAILURE:
      return Object.assign({}, state, {
        postSuccess: false,
        isLoading: false,
        message: 'error'
      })
    case SET_POST_MAIL_STATUS:
      return Object.assign({}, state, {
        postSuccess: action.data,
        isLoading: true,
        message: ''
      })
    case SET_POST_MAIL:
      return Object.assign({}, state, {
        message: ''
      })
    case GET_EMAIL_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case GET_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        mailById: action.payload.entities,
        mailAllIds: action.payload.result
      })
    case GET_EMAIL_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case GET_EMAIL_LOGS_BY_EMAILID_START:
      return Object.assign({}, state, {
        isLoadingEmailLogsByEmailId: true
      })
    case GET_EMAIL_LOGS_BY_EMAILID:
      return Object.assign({}, state, {
        isLoadingEmailLogsByEmailId: false,
        emailLogs: action.data
      })
    case GET_EMAIL_LOGS_BY_EMAILID_FAIL:
      return Object.assign({}, state, {
        isLoadingEmailLogsByEmailId: false
      })
    default:
      return state
  }
}
