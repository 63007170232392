import {
  SAVE_DATA_FOR_STUDENT_PROFILE,
  SET_ACTIVE_STUDENT,
  CLEAR_ACTIVE_STUDENT
} from '../../helpers/actions'

const UI_StudentProfile = {}

export const saveData = data => ({
  type: SAVE_DATA_FOR_STUDENT_PROFILE,
  data
})

UI_StudentProfile.setActiveStudent = studentId => ({
  type: SET_ACTIVE_STUDENT,
  studentId
})

UI_StudentProfile.clearActiveStudent = data => ({
  type: CLEAR_ACTIVE_STUDENT
})

export default UI_StudentProfile
