import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// actions
import {
  startFetchUmbrellaGroups,
  startPatchUmbrellaGroupName
} from '../../../actions/groups'

// reducers
import { getJwt } from '../../../reducers/accounts'
import { getUmbrellaGroupNames } from '../../../reducers/groups'

// components
import Snackbar from './snackbar'

class EditClassComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    fetchUmbrellaGroups: PropTypes.func.isRequired,
    umbrella_groups_name: PropTypes.array.isRequired,
    editUmbrellaGroupName: PropTypes.func.isRequired,
    setSnackbarType: PropTypes.func.isRequired,
    showSnackbar: PropTypes.bool.isRequired,
    snackbarType: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      existingUmbrellaGroupName: '',
      newUmbrellaGroupName: ''
    }
    this.inputFieldRef = null
    this.handleClick = this.handleClick.bind(this)
    this.handleNewUmbrellaName = this.handleNewUmbrellaName.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.props.fetchUmbrellaGroups({ jwt: this.props.jwt })
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      existingUmbrellaGroupName: e.target.value
    })
  }

  handleNewUmbrellaName(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      newUmbrellaGroupName: e.target.value
    })
  }

  handleClick(e) {
    e.preventDefault()
    const data = {
      ...this.state
    }
    if (this.inputFieldRef.value !== '')
      this.props.editUmbrellaGroupName({
        jwt: this.props.jwt,
        data
      })
    this.props.setSnackbarType('class')
    this.inputFieldRef.value = ''
  }

  render() {
    const { newUmbrellaGroupName, existingUmbrellaGroupName } = this.state
    const {
      umbrella_groups_name,
      snackbarType,
      showSnackbar,
      hideSnackbar
    } = this.props
    return (
      <div>
        <div className="side-info">
          <p className="side-info__title">Select Class</p>
          {umbrella_groups_name.length === 0 && (
            <select>
              <option disabled>No Class available</option>
            </select>
          )}
          {umbrella_groups_name.length !== 0 && (
            <select
              className="info-cell info-cell--no-left-margin"
              onChange={this.handleChange}
            >
              <option selected="selected" disabled>
                Select a class
              </option>
              {umbrella_groups_name.map((um, index) => (
                <option key={index} value={um}>
                  {um}
                </option>
              ))}
            </select>
          )}
          <div className="dash" />
          <p className="side-info__title">New Class name</p>
          <input
            type="text"
            className="info-cell info-cell--no-left-margin"
            onChange={this.handleNewUmbrellaName}
            ref={field => (this.inputFieldRef = field)}
          />
          <div className="buttonalign__center buttonalign__center--margins">
            <input
              className={classNames({
                'button button--blue': true,
                disabled:
                  newUmbrellaGroupName.length === 0 ||
                  existingUmbrellaGroupName.length === 0
              })}
              disabled={
                newUmbrellaGroupName.length === 0 ||
                existingUmbrellaGroupName.length === 0
              }
              type="button"
              value="Save"
              onClick={this.handleClick}
            />
          </div>
        </div>
        <Snackbar
          text="Class Name changed Sucessfully"
          showSnackbar={snackbarType === 'class' && showSnackbar}
          hideSnackbar={hideSnackbar}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state),
  umbrella_groups_name: getUmbrellaGroupNames(state)
})

const mapDispatchToProps = dispatch => ({
  fetchUmbrellaGroups(data) {
    dispatch(startFetchUmbrellaGroups(data))
  },
  editUmbrellaGroupName(data) {
    dispatch(startPatchUmbrellaGroupName(data))
  }
})

export const EditClass = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClassComponent)
