import { createSelector } from 'reselect'
import {
  GET_EVENTS_START,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  CREATE_EVENT_START,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  EDIT_EVENT_START,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAIL,
  DELETE_EVENT_START,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL
} from './../helpers/actions'

const initialState = {
  isLoading: false,
  error: '',
  byId: {},
  allIds: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_START:
      return {
        ...state,
        isLoading: true,
        error: ''
      }

    case GET_EVENTS_SUCCESS:
      const { byId, allIds } = action.data
      return {
        ...state,
        isLoading: false,
        error: '',
        byId,
        allIds
      }

    case GET_EVENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case CREATE_EVENT_START:
      return {
        ...state,
        isLoading: true,
        error: ''
      }

    case CREATE_EVENT_SUCCESS:
      const { _id, ...rest } = action.data
      return {
        ...state,
        isLoading: false,
        error: '',
        byId: { ...state.byId, [_id]: rest },
        allIds: [...state.allIds, _id]
      }

    case CREATE_EVENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case EDIT_EVENT_START:
      return {
        ...state,
        isLoading: true,
        error: ''
      }

    case EDIT_EVENT_SUCCESS:
      // const { _id, ...rest } = action.data
      return {
        ...state,
        isLoading: false,
        error: ''
        // byId: { ...state.byId, [_id]: rest },
        // allIds: [...state.allIds, _id]
      }

    case EDIT_EVENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case DELETE_EVENT_START:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case DELETE_EVENT_FAIL:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export const eventsById = state => state.events.byId
export const eventsAllIds = state => state.events.allIds
export const getIsLoading = state => state.events.isLoading
export const getAllEventsArray = createSelector(
  eventsById,
  eventsAllIds,
  (eventsById, eventsAllIds) => {
    if (eventsAllIds.length > 0 && Object.keys(eventsById).length > 0)
      return eventsAllIds
        .filter(eventId => !eventsById[eventId]?.archived)
        .map(eventId => eventsById[eventId])

    // return [
    //   {
    //     title: '',
    //     start: new Date(),
    //     end: new Date()
    //   }
    // ]
    return []
  }
)
