// import actions
import {
  APP_LOGOUT,
  START_FETCH_PORTION,
  PORTION_DATA,
  PORTION_FETCH_FAIL,
  START_POST_PORTION,
  POST_PORTION_SUCCESS,
  POST_PORTION_FAILED,
  PATCH_PORTION_START,
  PATCH_PORTION_SUCCESS,
  PATCH_PORTION_FAIL,
  DELETE_PORTION_START,
  DELETE_PORTION_SUCCESS,
  DELETE_PORTION_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  portion: {},
  errMsg: '',
  patchLoading: false,
  error: false,
  deleteLoading: false
}

export const portion = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_FETCH_PORTION:
      return Object.assign({}, state, {
        isLoading: true,
        errMsg: ''
      })
    case START_POST_PORTION:
      return Object.assign({}, state, {
        isLoading: true,
        errMsg: ''
      })
    case POST_PORTION_SUCCESS:
      return Object.assign({}, state, {
        // byId: {
        //   [action.id]: action.data
        // },
        // allIds: [action.id],
        isLoading: false,
        errMsg: 'success'
      })
    case POST_PORTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errMsg: 'fail'
      })
    case PORTION_DATA:
      return Object.assign({}, state, {
        portion: action.data,
        isLoading: false
      })
    case PORTION_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        errMsg: action.err
      })
    case PATCH_PORTION_START:
      return Object.assign({}, state, {
        patchLoading: true,
        error: false
      })
    case PATCH_PORTION_SUCCESS:
      return Object.assign({}, state, {
        patchLoading: false,
        error: false
      })
    case PATCH_PORTION_FAIL:
      return Object.assign({}, state, {
        patchLoading: false,
        error: true
      })
    case DELETE_PORTION_START:
      return Object.assign({}, state, {
        deleteLoading: true,
        error: false
      })
    case DELETE_PORTION_SUCCESS:
      return Object.assign({}, state, {
        deleteLoading: false,
        error: false
      })
    case DELETE_PORTION_FAIL:
      return Object.assign({}, state, {
        deleteLoading: false,
        error: true
      })
    default:
      return state
  }
}

export const isLoading = state => state.portion.isLoading
export const portionObj = state => state.portion.portion
export const getPatchLoading = state => state.portion.patchLoading
export const getDeleteLoading = state => state.portion.deleteLoading
export const getError = state => state.portion.error
const Portion = {
  isLoading,
  portionObj
}

export default Portion
