import {
  APP_LOGOUT,
  POST_LIBRARY_LENDING_START,
  POST_LIBRARY_LENDING,
  POST_LIBRARY_LENDING_FAIL,
  RETURN_LIBRARY_LENDING_START,
  RETURN_LIBRARY_LENDING,
  RETURN_LIBRARY_LENDING_FAIL,
  GET_LIBRARY_LENDING_STATS_START,
  GET_LIBRARY_LENDING_STATS,
  GET_LIBRARY_LENDING_STATS_FAIL,
  GET_LIBRARY_USER_LENDING_START,
  GET_LIBRARY_USER_LENDING,
  GET_LIBRARY_USER_LENDING_FAIL,
  GET_LIBRARY_USER_LENDING_BY_STATUS_START,
  GET_LIBRARY_USER_LENDING_BY_STATUS_SUCCESS,
  GET_LIBRARY_USER_LENDING_BY_STATUS_FAIL,
  LIBRARY_LENDING_CHANGE_STATUS_START,
  LIBRARY_LENDING_CHANGE_STATUS_SUCCESS,
  LIBRARY_LENDING_CHANGE_STATUS_FAIL
} from '../../helpers/actions'

const initialState = {
  lending: '',
  isLoadingPostLibraryLending: false,
  isLoadingReturnLibraryLending: false,
  lendingById: {},
  error: false,
  isLoadingGetLibraryLendingStats: false,
  lendingStats: [],
  isLoadingGetLibraryUserLending: false, // Added for tracking loading state of user lending
  userLending: {}, // Added for storing the user lending data
  isLoadingGetLibraryUserLendingByStatus: false,
  userLendingByStatus: [],
  isLoadingGetLibraryUserLendingChangeByStatus: false,
  userLendingChangeByStatus: {}
}

export const libraryLendings = (state = initialState, action) => {
  switch (action.type) {
    case POST_LIBRARY_LENDING_START:
      return Object.assign({}, state, {
        isLoadingPostLibraryLending: true,
        error: false
      })
    case POST_LIBRARY_LENDING:
      return Object.assign({}, state, {
        isLoadingPostLibraryLending: false,
        lending: action.data,
        lendingById: action.byId,
        error: false
      })
    case POST_LIBRARY_LENDING_FAIL:
      return Object.assign({}, state, {
        isLoadingPostLibraryLending: false,
        error: true
      })
    case RETURN_LIBRARY_LENDING_START:
      return Object.assign({}, state, {
        isLoadingReturnLibraryLending: true,
        error: false
      })
    case RETURN_LIBRARY_LENDING:
      return Object.assign({}, state, {
        isLoadingReturnLibraryLending: false,
        error: false
      })
    case RETURN_LIBRARY_LENDING_FAIL:
      return Object.assign({}, state, {
        isLoadingReturnLibraryLending: false,
        error: true
      })
    case GET_LIBRARY_LENDING_STATS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryLendingStats: true,
        error: false
      })
    case GET_LIBRARY_LENDING_STATS:
      return Object.assign({}, state, {
        isLoadingGetLibraryLendingStats: false,
        lendingStats: action.data,
        error: false
      })
    case GET_LIBRARY_LENDING_STATS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryLendingStats: false,
        error: true
      })
    case GET_LIBRARY_USER_LENDING_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLending: true,
        error: false
      })
    case GET_LIBRARY_USER_LENDING:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLending: false,
        userLending: action.data,
        error: false
      })
    case GET_LIBRARY_USER_LENDING_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLending: false,
        error: true
      })

    case GET_LIBRARY_USER_LENDING_BY_STATUS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLendingByStatus: true,
        error: false
      })
    case GET_LIBRARY_USER_LENDING_BY_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLendingByStatus: false,
        userLendingByStatus: action.data,
        error: false
      })
    case GET_LIBRARY_USER_LENDING_BY_STATUS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLendingByStatus: false,
        error: true
      })
    case LIBRARY_LENDING_CHANGE_STATUS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLendingChangeByStatus: true,
        error: false
      })
    case LIBRARY_LENDING_CHANGE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLendingChangeByStatus: false,
        userLendingChangeByStatus: action.data,
        error: false
      })
    case LIBRARY_LENDING_CHANGE_STATUS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserLendingChangeByStatus: false,
        error: true
      })

    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getLibraryLendings = state =>
  state.libraryLendings.lending ? state.libraryLendings.lending : {}
export const getisLoadingPostLibraryLending = state =>
  state.libraryLendings.isLoadingPostLibraryLending
    ? state.libraryLendings.isLoadingPostLibraryLending
    : false
export const getisLoadingReturnLibraryLending = state =>
  state.libraryLendings.isLoadingReturnLibraryLending
    ? state.libraryLendings.isLoadingReturnLibraryLending
    : false
export const getLendingById = state =>
  state.libraryLendings.lendingById ? state.libraryLendings.lendingById : {}
export const getError = state =>
  state.libraryLendings.error ? state.libraryLendings.error : false

export const getisLoadingGetLibraryLendingStats = state =>
  state.libraryLendings.isLoadingGetLibraryLendingStats
    ? state.libraryLendings.isLoadingGetLibraryLendingStats
    : false
export const getLendingStats = state =>
  state.libraryLendings.lendingStats ? state.libraryLendings.lendingStats : []

export const getisLoadingGetLibraryUserLending = state =>
  state.libraryLendings.isLoadingGetLibraryUserLending
    ? state.libraryLendings.isLoadingGetLibraryUserLending
    : false
export const getUserLending = state => state.libraryLendings.userLending

export const getisLoadingLibraryLendingByStatus = state =>
  state.libraryLendings.isLoadingGetLibraryUserLendingByStatus
    ? state.libraryLendings.isLoadingGetLibraryUserLendingByStatus
    : false

export const getUserLendingByStatus = state =>
  state.libraryLendings.userLendingByStatus

export const getisLoadingGetLibraryUserLendingChangeByStatus = state =>
  state.libraryLendings.isLoadingGetLibraryUserLendingChangeByStatus
    ? state.libraryLendings.isLoadingGetLibraryUserLendingChangeByStatus
    : false

export const getUserLendingChangeByStatus = state =>
  state.libraryLendings.userLendingChangeByStatus
