import {
  APP_LOGOUT,
  ADD_ROUTE,
  ADD_ROUTE_START,
  ADD_ROUTE_FAIL,
  GET_ROUTE,
  GET_ROUTE_START,
  GET_ROUTE_FAIL,
  GET_ROUTE_BY_ID,
  PATCH_ROUTE,
  PATCH_ROUTE_START,
  PATCH_ROUTE_FAIL
} from '../../helpers/actions'

import { getById } from '../../helpers/utils'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isLoadingPatch: false,
  isErr: false,
  routeData: [],
  routeById: {},
  singleRouteData: {},
  stopData: [],
  stopDataById: {}
}

export const transportRoute = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ADD_ROUTE_START:
      return {
        ...state,
        isLoadingPost: true,
        isErr: false
      }
    case ADD_ROUTE:
      return {
        ...state,
        isLoadingPost: false,
        isErr: false
      }
    case ADD_ROUTE_FAIL:
      return {
        ...state,
        isLoadingPost: false,
        isErr: true
      }
    case GET_ROUTE_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case GET_ROUTE:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        routeData: action.data
      }
    case GET_ROUTE_BY_ID:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        singleRouteData: action.data?.route,
        stopData: action?.data?.stops,
        stopDataById: getById(action?.data?.stops, '_id')
      }
    case GET_ROUTE_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    case PATCH_ROUTE_START:
      return {
        ...state,
        isLoadingPatch: true,
        isErr: false
      }
    case PATCH_ROUTE:
      return {
        ...state,
        isLoadingPatch: false,
        isErr: false
      }
    case PATCH_ROUTE_FAIL:
      return {
        ...state,
        isLoadingPatch: false,
        isErr: true
      }
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.transportRoute.isLoading
export const getIsLoadingPost = state => state.transportRoute.isLoadingPost
export const getIsLoadingPatch = state => state.transportRoute.isLoadingPatch
export const getIsError = state => state.transportRoute.isErr
export const getRouteData = state => state.transportRoute.routeData
export const getRouteById = state => state.transportRoute.routeById
export const getSingleRouteData = state => state.transportRoute.singleRouteData
export const getStopData = state => state.transportRoute.stopData
export const getStopDataById = state => state.transportRoute.stopDataById
