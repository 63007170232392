import {
  APP_LOGOUT,
  GET_LIBRARY_CHALLAN_BY_USERID_START,
  GET_LIBRARY_CHALLAN_BY_USERID,
  GET_LIBRARY_CHALLAN_BY_USERID_FAIL,
  GET_LIBRARY_CHALLAN_STATS_START,
  GET_LIBRARY_CHALLAN_STATS,
  GET_LIBRARY_CHALLAN_STATS_FAIL,
  LIBRARY_CHALLAN_CHANGE_STATUS_START,
  LIBRARY_CHALLAN_CHANGE_STATUS,
  LIBRARY_CHALLAN_CHANGE_STATUS_FAIL,
  GET_LIBRARY_USER_CHALLAN_START,
  GET_LIBRARY_USER_CHALLAN,
  GET_LIBRARY_USER_CHALLAN_FAIL,
  GET_LIBRARY_CHALLAN_BY_STATUS_START,
  GET_LIBRARY_CHALLAN_BY_STATUS_SUCCESS,
  GET_LIBRARY_CHALLAN_BY_STATUS_FAIL
} from '../../helpers/actions'

const initialState = {
  challans: {},
  isLoadingGetLibraryChallan: false,
  isLoadingLibraryChallanChnageStatus: false,
  challansById: {},
  error: false,
  isLoadingGetLibraryChallanStats: false,
  challanStats: [],
  challan: []
}

export const libraryChallans = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIBRARY_CHALLAN_BY_USERID_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallan: true,
        error: false
      })
    case GET_LIBRARY_CHALLAN_BY_USERID:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallan: false,
        challans: action.data,
        challansById: action.byId,
        error: false
      })
    case GET_LIBRARY_CHALLAN_BY_USERID_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallan: false,
        error: true
      })
    case LIBRARY_CHALLAN_CHANGE_STATUS_START:
      return Object.assign({}, state, {
        isLoadingLibraryChallanChnageStatus: true,
        error: false
      })
    case LIBRARY_CHALLAN_CHANGE_STATUS:
      return Object.assign({}, state, {
        isLoadingLibraryChallanChnageStatus: false,
        error: false
      })
    case LIBRARY_CHALLAN_CHANGE_STATUS_FAIL:
      return Object.assign({}, state, {
        isLoadingLibraryChallanChnageStatus: false,
        error: true
      })
    case GET_LIBRARY_CHALLAN_STATS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallanStats: true,
        error: false
      })
    case GET_LIBRARY_CHALLAN_STATS:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallanStats: false,
        challanStats: action.data,
        error: false
      })
    case GET_LIBRARY_CHALLAN_STATS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallanStats: false,
        error: true
      })

    case GET_LIBRARY_USER_CHALLAN_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserChallan: true,
        error: false
      })
    case GET_LIBRARY_USER_CHALLAN:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserChallan: false,
        userChallan: action.data,
        error: false
      })
    case GET_LIBRARY_USER_CHALLAN_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryUserChallan: false,
        error: true
      })
    case GET_LIBRARY_CHALLAN_BY_STATUS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallan: true,
        error: false
      })
    case GET_LIBRARY_CHALLAN_BY_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallan: false,
        challan: action.data.data,
        error: false
      })
    case GET_LIBRARY_CHALLAN_BY_STATUS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryChallan: false,
        error: true
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getLibrarychallans = state =>
  state.libraryChallans.challans ? state.libraryChallans.challans : {}
export const getisLoadingGetLibraryChallan = state =>
  state.libraryChallans.isLoadingGetLibraryChallan
    ? state.libraryChallans.isLoadingGetLibraryChallan
    : false
export const getisLoadingLibraryChallanChnageStatus = state =>
  state.libraryChallans.isLoadingLibraryChallanChnageStatus
    ? state.libraryChallans.isLoadingLibraryChallanChnageStatus
    : false
export const getLibraryChallansById = state =>
  state.libraryChallans.challansById ? state.libraryChallans.challansById : {}
export const getError = state =>
  state.libraryChallans.error ? state.libraryChallans.error : false

export const getisLoadingGetLibraryChallanStats = state =>
  state.libraryChallans.isLoadingGetLibraryChallanStats
    ? state.libraryChallans.isLoadingGetLibraryChallanStats
    : false
export const getChallanStats = state =>
  state.libraryChallans.challanStats ? state.libraryChallans.challanStats : []

export const getisLoadingGetLibraryUserChallan = state =>
  state.libraryChallans.isLoadingGetLibraryUserChallan
    ? state.libraryChallans.isLoadingGetLibraryUserChallan
    : false
export const getLibraryUserChallan = state => state.libraryChallans.userChallan
export const getChallan = state => state.libraryChallans.challan
