import { createSelector } from 'reselect'
import lodash from 'lodash'

import {
  APP_LOGOUT,
  DAILY_STUDENT_LAST_ATTENDANCE,
  HOURLY_STUDENT_LAST_ATTENDANCE,
  DAILY_STUDENT_MONTHLY_ATTENDANCE,
  HOURLY_STUDENT_MONTHLY_ATTENDANCE,
  START_LAST_ATTENDANCE_FETCH,
  START_MONTHLY_ATTENDANCE_FETCH,
  LAST_ATTENDANCE_FETCH_ERROR,
  MONTHLY_ATTENDANCE_FETCH_ERROR,
  // actions for attendance of the entire academic year
  ATTENDANCE_ACADEMIC_YEAR_FETCH_START,
  ATTENDANCE_ACADEMIC_YEAR_DATA,
  ATTENDANCE_ACADEMIC_YEAR_FETCH_FAIL
} from '../../helpers/actions'

const initialState = {
  isLoading: false,
  dailyLastAttendance: {},
  hourlyLastAttendanceBySubjectId: {},
  hourlyLastAttendanceAllIds: {},
  monthlyAttendanceByMonthId: {},
  monthlyAttendanceBySubjectId: {},
  monthlyAttendanceAllSubjectIds: [],
  isLoadingLast: false,
  lastAttendanceMsg: '',
  isLoadingMonthly: false,
  monthlyAttendanceMsg: '',
  attendance: []
}

export const attendance = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_LAST_ATTENDANCE_FETCH:
      return Object.assign({}, state, {
        isLoadingLast: true,
        lastAttendanceMsg: ''
      })
    case START_MONTHLY_ATTENDANCE_FETCH:
      return Object.assign({}, state, {
        isLoadingMonthly: true,
        monthlyAttendanceMsg: ''
      })
    case DAILY_STUDENT_LAST_ATTENDANCE: {
      return Object.assign({}, state, {
        dailyLastAttendance: action.data ? action.data : {},
        isLoadingLast: false,
        lastAttendanceMsg: 'success'
      })
    }
    case HOURLY_STUDENT_LAST_ATTENDANCE:
      return Object.assign({}, state, {
        hourlyLastAttendanceBySubjectId: action.byId,
        hourlyLastAttendanceAllIds: action.allIds,
        isLoadingLast: false,
        lastAttendanceMsg: 'success'
      })
    case DAILY_STUDENT_MONTHLY_ATTENDANCE:
      return Object.assign({}, state, {
        monthlyAttendanceByMonthId: action.byId,
        isLoadingMonthly: false,
        monthlyAttendanceMsg: 'success'
      })
    case HOURLY_STUDENT_MONTHLY_ATTENDANCE:
      return Object.assign({}, state, {
        monthlyAttendanceBySubjectId: action.byId,
        monthlyAttendanceAllSubjectIds: action.allIds,
        isLoadingMonthly: false,
        monthlyAttendanceMsg: 'success'
      })
    case LAST_ATTENDANCE_FETCH_ERROR:
      return Object.assign({}, state, {
        isLoadingLast: true,
        lastAttendanceMsg: 'fail'
      })
    case MONTHLY_ATTENDANCE_FETCH_ERROR:
      return Object.assign({}, state, {
        isLoadingMonthly: true,
        monthlyAttendanceMsg: 'fail'
      })
    case ATTENDANCE_ACADEMIC_YEAR_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ATTENDANCE_ACADEMIC_YEAR_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        attendance: action.data
      })
    case ATTENDANCE_ACADEMIC_YEAR_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    default:
      return state
  }
}

// selectors
export const getDailyLastAttendance = state =>
  state.studentLogin.attendance.dailyLastAttendance

export const getHourlyLastAttendanceBySubjectId = state =>
  state.studentLogin.attendance.hourlyLastAttendanceBySubjectId

export const getHourlyLastAttendanceAllIds = state =>
  state.studentLogin.attendance.hourlyLastAttendanceAllIds

export const getMonthlyAttendanceByMonthId = state =>
  state.studentLogin.attendance.monthlyAttendanceByMonthId

export const getMonthlyAttendanceBySubjectId = state =>
  state.studentLogin.attendance.monthlyAttendanceBySubjectId

export const getMonthlyAttendanceAllSubjectIds = state =>
  state.studentLogin.attendance.monthlyAttendanceAllSubjectIds

export const getLastIsLoading = state =>
  state.studentLogin.attendance.isLoadingLast
export const getLastMsg = state =>
  state.studentLogin.attendance.lastAttendanceMsg
export const getMonthlyIsLoading = state =>
  state.studentLogin.attendance.isLoadingMonthly
export const getMonthlyMsg = state =>
  state.studentLogin.attendance.monthlyAttendanceMsg

export const getAttendanceForAcademicYear = state =>
  state.studentLogin.attendance.attendance

export const isLoading = state => state.studentLogin.attendance.isLoading

export const getUniqueSubjectIds = createSelector(
  getHourlyLastAttendanceAllIds,
  getMonthlyAttendanceAllSubjectIds,
  (hourlySubjectIds, monthlySubjectIds) =>
    lodash.uniq(hourlySubjectIds, monthlySubjectIds)
)
