import {
  APP_LOGOUT,
  START_POST_POLICY,
  POST_POLICY_SUCCESS,
  POST_POLICY_FAILURE,
  START_FETCH_POLICY,
  START_FETCH_POLICY_SUCCESS,
  START_FETCH_POLICY_FAILURE,
  START_UPLOAD_POLICY_DOCUMENT,
  UPLOAD_POLICY_DOCUMENT_SUCCESS,
  UPLOAD_POLICY_DOCUMENT_FAILURE,
  REMOVE_POLICY_DOCUMENT_LINK
} from '../helpers/actions.js'
import { createSelector } from 'reselect'

const initialState = {
  policyById: {},
  policyAllIds: [],
  isLoading: false,
  message: '',
  policyDocumentLink: [],
  isLoadingPolicy: false
}

export const policyById = state => state.policy.policyById
export const policyAllIds = state => state.policy.policyAllIds
export const getIsLoading = state => state.policy.isLoading
export const getPostMessage = state => state.policy.message
export const getDocumentLInk = state => state.policy.policyDocumentLink
export const getIsPolicyLoading = state => state.policy.isLoadingPolicy

export const getPolicyArray = createSelector(
  policyById,
  policyAllIds,
  (byId, allIds) => {
    if (!allIds || !byId || allIds.length === 0) return []
    return allIds
      .map(id => byId[id])
      .sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate)
      })[0]
  }
)

export const policy = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState

    case START_POST_POLICY:
      return Object.assign(
        {},
        {
          isLoading: true,

          message: ''
        }
      )
    case POST_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'success',
        policyDocumentLink: []
      })
    case POST_POLICY_FAILURE:
      return Object.assign({}, state, {
        message: 'failed',
        isLoading: false,
        policyDocumentLink: []
      })
    case START_UPLOAD_POLICY_DOCUMENT:
      return Object.assign({}, state, {
        isLoadingPolicy: true,
        policyDocumentLink: []
      })
    case UPLOAD_POLICY_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPolicy: false,
        policyDocumentLink: [...action.data]
      })
    case UPLOAD_POLICY_DOCUMENT_FAILURE:
      return Object.assign({}, state, {
        isLoadingPolicy: false,
        policyDocumentLink: []
      })
    case REMOVE_POLICY_DOCUMENT_LINK:
      return Object.assign({}, state, {
        policyDocumentLink: [...action.data]
      })
    case START_FETCH_POLICY:
      return Object.assign(state, {
        isLoading: true,

        message: ''
      })
    case START_FETCH_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'success',
        policyById: action.byId,
        policyAllIds: action.allIds
      })
    case START_FETCH_POLICY_FAILURE:
      return Object.assign({}, state, {
        message: 'failed',
        isLoading: false,
        policyById: {},
        policyAllIds: []
      })
    default:
      return state
  }
}
