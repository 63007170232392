import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput, SelectInputWithID } from '../inputs.jsx'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'

import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../helpers/enums'

export const GroupSelector = ({
  clickedGo,
  showElectives,
  umbrellaGroup,
  groupId,
  testId,
  subjectId,
  electiveId,
  parentNames,
  sections,
  tests,
  subjects,
  handleUmbrellaChange,
  handleSectionChange,
  handleTestChange,
  handleElectiveChange,
  handleSubjectChange,
  handleGo,
  electives,
  isDirty
  // handleSubjectChange
}) => {
  return (
    <form
      className="group-selector group-selector__marks-entry"
      onSubmit={handleGo}
    >
      <SelectInput
        name="parent_group"
        umbrellaGroup={umbrellaGroup}
        options={parentNames}
        handleChange={handleUmbrellaChange}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />

      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="groupId"
        id={groupId}
        options={sections}
        handleChange={handleSectionChange}
        required
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />

      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="testId"
        id={testId}
        options={tests}
        handleChange={handleTestChange}
        required
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />

      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="subject"
        id={subjectId}
        options={subjects}
        handleChange={handleSubjectChange}
        required
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />

      {electives.length !== 0 && showElectives && (
        <span className="group-selector__II-lang-arrow">
          <FontAwesome
            size="2x"
            name="caret-right"
            className="group-selector__II-lang-caret"
          />
          <SelectInputWithID
            name="electiveId"
            id={electiveId}
            options={electives}
            handleChange={handleElectiveChange}
            required
            {...STYLE_PROPS_FOR_GROUP_SELECTOR}
          />
        </span>
      )}

      <FontAwesome size="2x" name="caret-right" />
      <button
        type="submit"
        className={classNames({
          'button button--small button--blue': true,
          disabled: !isDirty
        })}
        disabled={!isDirty}
      >
        Go
      </button>
    </form>
  )
}

GroupSelector.propTypes = {
  parentNames: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
  subjects: PropTypes.array.isRequired,
  tests: PropTypes.array.isRequired,
  electives: PropTypes.array.isRequired,
  subjectId: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  electiveId: PropTypes.string.isRequired,
  isDirty: PropTypes.bool.isRequired,
  handleUmbrellaChange: PropTypes.func.isRequired,
  handleSectionChange: PropTypes.func.isRequired,
  handleTestChange: PropTypes.func.isRequired,
  handleElectiveChange: PropTypes.func.isRequired,
  handleSubjectChange: PropTypes.func.isRequired,
  handleGo: PropTypes.func.isRequired
}
