// import packages
import { createSelector } from 'reselect'
// import actions
import {
  APP_LOGOUT,
  START_FETCH_MARKS_FOR_PERFORMANCE,
  MARKS_DATA_FOR_PERFORMANCE,
  FETCH_MARKS_FOR_PERFORMANCE_FAIL,
  START_FETCH_REPORT_FOR_PERFORMANCE,
  REPORT_DATA_FOR_PERFORMANCE,
  FETCH_REPORT_FOR_PERFORMANCE_FAIL
} from '../helpers/actions'

const initialState = {
  isMarksLoading: false, // marks per testId, subjectId, studentId
  isReportLoading: false, // class and section percentage per subject
  marks: [], // marks per testId, subjectId, studentId
  report: [], // class and section percentage per subject
  errMsg: ''
}

export const performance = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_FETCH_MARKS_FOR_PERFORMANCE:
      return Object.assign({}, state, {
        isMarksLoading: true,
        errMsg: ''
      })
    case MARKS_DATA_FOR_PERFORMANCE:
      return Object.assign({}, state, {
        isMarksLoading: false,
        marks: action.marksData
      })
    case FETCH_MARKS_FOR_PERFORMANCE_FAIL:
      return Object.assign({}, state, {
        isMarksLoading: false,
        errMsg: action.err
      })
    case START_FETCH_REPORT_FOR_PERFORMANCE:
      return Object.assign({}, state, {
        isReportLoading: true,
        errMsg: ''
      })
    case REPORT_DATA_FOR_PERFORMANCE:
      return Object.assign({}, state, {
        isReportLoading: false,
        report: action.reportData
      })
    case FETCH_REPORT_FOR_PERFORMANCE_FAIL:
      return Object.assign({}, state, {
        isReportLoading: false,
        errMsg: action.err
      })
    default:
      return state
  }
}

const marksLoading = state => state.performance.isMarksLoading
const reportLoading = state => state.performance.isReportLoading
const marks = state => state.performance.marks
const report = state => state.performance.report

const getMarksByTestId = createSelector(marks, marksArr => {
  if (marksArr.length === 0) return {}
  const obj = {}
  marksArr.forEach(markObj => {
    const { subjectId, testId } = markObj
    if (!Object.keys(obj).includes(testId)) {
      obj[testId] = {
        [subjectId]: markObj
      }
    } else {
      obj[testId] = {
        ...obj[testId],
        [subjectId]: markObj
      }
    }
  })
  return obj
})

const getReportByTestId = createSelector(report, reportArr => {
  if (reportArr.length === 0) return {}
  const obj = {}
  reportArr.forEach(reportObj => {
    const { subjectId, testId } = reportObj
    if (!Object.keys(obj).includes(testId)) {
      obj[testId] = {
        [subjectId]: reportObj
      }
    } else {
      obj[testId] = {
        ...obj[testId],
        [subjectId]: reportObj
      }
    }
  })
  return obj
})

const Performance = {
  marksLoading,
  reportLoading,
  marks,
  report,
  getMarksByTestId,
  getReportByTestId
}

export default Performance
