import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//reducers
import {
  getLevel,
  getJwt,
  isLoggedIn,
  isSupportLoggedIn
} from '../../../reducers/accounts'

//actions
import { startGetAllFeeTypes } from '../../../actions/feeTypes'

//components
import AdminRoute from './admin'
import AdmissionOfficeRoute from './admissionOffice'
import CashierRoute from './cashier'
import DirectorRoute from './director'
import FeeAdminRoute from './feeAdmin'
import LibraryRoute from './library'
import ParentRoute from './parent'
import PrincipalRoute from './principal'
import StudentRoute from './student'
import SuperAdminRoute from './superAdmin'
import SupportRoute from './support'
import TeacherRoute from './teacher'
import MasterRoute from './master'
import EmptyState from '../../common/atom/emptyState'

const PrivateRouter = () => {
  const dispatch = useDispatch()
  const level = useSelector(getLevel)
  const jwt = useSelector(getJwt)
  // const loggedIn = useSelector(isLoggedIn)
  // const supportLoggedIn = useSelector(isSupportLoggedIn)

  // useEffect(() => {
  //   if (loggedIn || supportLoggedIn) {
  //     console.log('here startGetAllFeeTypes')
  //     dispatch(startGetAllFeeTypes({ jwt }))
  //   }
  // }, [loggedIn])

  switch (level) {
    case 'admin':
      return <AdminRoute />
    case 'adminCoordinator':
      return <AdminRoute />
    case 'admissionOfficer':
      return <AdmissionOfficeRoute />
    case 'cashier':
      return <CashierRoute />
    case 'director':
      return <DirectorRoute />
    case 'feeAdmin':
      return <FeeAdminRoute />
    case 'library':
      return <LibraryRoute />
    case 'master':
      return <MasterRoute />
    case 'support':
      return <SupportRoute />
    case 'parent':
      return <ParentRoute />
    case 'master':
      return <ParentRoute />
    case 'principal':
      return <PrincipalRoute />
    case 'student':
      return <StudentRoute />
    case 'superAdmin':
      return <SuperAdminRoute />
    case 'teacher':
      return <TeacherRoute />
    default:
      return <EmptyState text="Your Login is under construction"></EmptyState>
  }
}

export default PrivateRouter
