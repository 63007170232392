// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
// import selectors
import { getGradeNames } from '../../reducers/tests'
import { byId as subjectsById } from '../../reducers/subjects'
import { groupsById } from '../../reducers/groups'
// import components
import { MaxMarksComponentArray } from './maxMarksComponent'
import { MarksAndStatusField } from './marksAndStatusField'
import Spinner from '../spinner'
import StudentLink from '../common/studentLink'

const MarksFormComponent = ({
  groupId,
  ready,
  studentDetails,
  selectedTests,
  selectedTestsGrade,
  marksform,
  dispatchPostRequest,
  postBulkMarks,
  marksStatus,
  subjectId,
  mainSubjectId,
  marksById,
  marksallIds,
  showMaxMarksModalError,
  resetMarks,
  type,
  validateGrades,
  initialValues,
  subjectsById,
  groupsById
}) => {
  if (!ready) return <Spinner /> // change to spinner
  if (ready && studentDetails !== undefined && studentDetails.length === 0) {
    return <p> Data not available, pls try again later </p>
  }

  return (
    <tbody className="table-body">
      {type === 'marks' && (
        <MaxMarksComponentArray
          testsId={selectedTests}
          marksform={marksform}
          subjectId={subjectId}
          mainSubjectId={mainSubjectId}
          groupId={groupId}
          resetMarks={resetMarks}
          showMaxMarksModalError={showMaxMarksModalError}
        />
      )}
      {studentDetails !== undefined &&
        studentDetails.map(student => {
          const {
            roll_number: rollNo,
            admission_number: admissionNum,
            registration_number,
            name,
            id
          } = student
          return (
            <tr className="table-row" key={id}>
              {/* <td className="table-row__cell table-row__cell--empty-left" /> */}
              <td className="table-row__cell"> {rollNo} </td>
              <td className="table-row__cell"> {admissionNum} </td>
              <td className="table-row__cell">
                {registration_number ? registration_number : ''}
              </td>
              <td className="table-row__cell">
                <StudentLink studentId={id}>{name}</StudentLink>
              </td>
              {MarksAndStatusField({
                selectedTests,
                studentId: id,
                subjectId,
                marksStatus,
                marksform,
                dispatchPostRequest,
                type,
                validateGrades,
                mainSubjectId,
                groupId,
                subjectsById,
                groupsById
              })}
              {/* <td className="table-row__cell table-row__cell--empty-right" /> */}
            </tr>
          )
        })}
    </tbody>
  )
}

MarksFormComponent.propTypes = {
  ready: PropTypes.bool.isRequired,
  studentDetails: PropTypes.array.isRequired,
  selectedTests: PropTypes.array.isRequired,
  marksform: PropTypes.shape({
    syncErrors: PropTypes.object,
    values: PropTypes.object,
    initial: PropTypes.object
  }),
  dispatchPostRequest: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  marksStatus: PropTypes.object.isRequired,
  subjectId: PropTypes.string.isRequired,
  showMaxMarksModalError: PropTypes.func.isRequired,
  resetMarks: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  validGrades: getGradeNames(state),
  subjectsById: subjectsById(state),
  groupsById: groupsById(state)
})

export const MarksForm = connect(mapStateToProps)(
  reduxForm({
    form: 'MarksForm',
    enableReinitialize: true
  })(MarksFormComponent)
)
