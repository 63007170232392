import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Snackbar from './snackbar'

export const DeleteTests = ({
  tests,
  existingTestIds,
  deleteTest,
  deletedTests,
  handleDeleteTests,
  showSnackbar,
  hideSnackbar,
  snackbarType
}) => {
  return (
    <div id="tests">
      <h3 className="page__subHead">Delete Tests</h3>
      <div class="table-responsive">
        <table class="table deleteSubjectTable">
          <tr>
            <th>Test Name</th>
            <th>Select</th>
          </tr>
          {existingTestIds.length === 0 && (
            <td colSpan="2">No Tests Available</td>
          )}
          {existingTestIds.length !== 0 &&
            Object.keys(tests).length !== 0 &&
            existingTestIds.map((test, index) => {
              return (
                <tr key={tests[test]?._id}>
                  <td>{tests[test]?.name}</td>
                  <td>
                    <button
                      className={classNames({
                        button: true,
                        'button--bordered': !deletedTests[tests[test]?._id],
                        'button--blue': deletedTests[tests[test]?._id]
                      })}
                      aria-hidden="true"
                      id={tests[test]?._id}
                      onClick={deleteTest}
                    >
                      {!deletedTests[tests[test]?._id] ? 'SELECT' : 'UNSELECT'}
                    </button>
                  </td>
                </tr>
              )
            })}
        </table>
      </div>
      <div className="buttonalign__center buttonalign__center--margins">
        <button
          className={classNames({
            'button button--blue': true,
            disabled: existingTestIds.length === 0
          })}
          onClick={handleDeleteTests}
          disabled={existingTestIds.length === 0}
        >
          Delete Tests
        </button>
      </div>
      <div className="clear"></div>
      <Snackbar
        text="Tests deleted successfully"
        showSnackbar={snackbarType === 'tests' && showSnackbar}
        hideSnackbar={hideSnackbar}
      />
      <div className="clear"></div>
    </div>
  )
}

DeleteTests.propTypes = {
  tests: PropTypes.object.isRequired,
  existingTestIds: PropTypes.array.isRequired,
  deleteTest: PropTypes.func.isRequired,
  deletedTests: PropTypes.object.isRequired,
  handleDeleteTests: PropTypes.func.isRequired,
  showSnackbar: PropTypes.bool.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  snackbarType: PropTypes.string.isRequired
}
