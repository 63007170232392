import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '../spinner'

const PolicesDescription = ({
  description,
  subject,
  nameOfComponent,
  handleInputChange,
  handleFileChange,
  nextPage,
  documents = [],
  removePolicyLink,
  PageHelmetText = '',
  policyLoading,
  maximumFileSizeexeeds
}) => {
  return (
    <div className="sms-form-ctr">
      <form className="sms-form" onSubmit={nextPage}>
        <div className="sms-inner-ctr">
          <div className="inner-ctr">
            <div className="dashed-line-top" />
            <div className="dashed-line-bottom" />
            <div className="dashed-line-left" />
            <div className="dashed-line-right" />
            <p className="form__heading">{PageHelmetText}</p>
            {nameOfComponent === 'sendEmailComponent' ? (
              <React.Fragment>
                <p className="form__heading">Subject</p>
                <div className="info-row-no-dashes col-sm-7">
                  <input
                    value={subject}
                    name="subject"
                    placeholder="Subject Here"
                    className="info-cell "
                    onChange={handleInputChange}
                  />
                </div>{' '}
                <p className="form__heading">Content</p>
              </React.Fragment>
            ) : null}

            <div className="text-and-char-ctr">
              <div className="text-area">
                <textarea
                  value={description}
                  name="description"
                  placeholder="type here"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="space-creator" />
            <p className="form__heading">Upload Document</p>
            <div>
              {policyLoading ? (
                <Spinner />
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  // accept="application/pdf"
                />
              )}
              {maximumFileSizeexeeds && (
                <p style={{ color: 'red' }}>
                  Document size is more than 20 mb. Please upload a smaller
                  document.
                </p>
              )}
              {documents.map((link, index) => (
                <div className="info-row" key={index}>
                  <a className="info-cell" href={link.url}>
                    {link.name}
                  </a>
                  <button
                    name={link.name}
                    className="button button--short button--squared button--red"
                    onClick={removePolicyLink}
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="field submit-field-no-absolute">
            <button
              className={classNames({
                'button button--blue': true
              })}
              type="submit"
            >
              Proceed
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

PolicesDescription.propTypes = {
  description: PropTypes.string.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired
}

export default PolicesDescription
