import React from 'react'
import PropTypes from 'prop-types'

const RenderDepartments = ({
  departments,
  selectedDepartments,
  handleDepartmentChange,
  handleAllDepartmentChange
}) => {
  return (
    <div className="group">
      <label className="group-select group-select--all">
        <input
          type="checkbox"
          name="department"
          onChange={handleAllDepartmentChange}
          checked={selectedDepartments.checked}
        />
        <span className="square">
          <span className="tick" />
        </span>
        <span className="text">Teachers</span>
      </label>
      <div className="group-select-ctr">
        {departments.map((department, index) => {
          return (
            <label key={index} className="group-select">
              <input
                type="checkbox"
                name={department}
                onChange={handleDepartmentChange}
                checked={selectedDepartments[department]}
              />
              <span className="square">
                <span className="tick" />
              </span>
              <span className="text">{department}</span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

RenderDepartments.propTypes = {
  departments: PropTypes.array.isRequired,
  selectedDepartments: PropTypes.object.isRequired,
  handleDepartmentChange: PropTypes.func.isRequired,
  handleAllDepartmentChange: PropTypes.func.isRequired
}

export default RenderDepartments
