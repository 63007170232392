import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Logo from '../../../assets/images/logo.svg'

//actions
// import { startFetchNavlistForHeader } from '../../../actions/navlist'
import { startFetchAcademicYearPromotionalGroups } from '../../../actions/groups'
import { saveAcademicYearForSession } from '../../../actions/ui/institution'
// import { startFetchNavigation } from '../../../actions/navigation'
import { startPostTicket } from '../../../actions/queryTicketing'
import {
  startGetNotifications,
  startRemoveNotifications
} from '../../../actions/notifications'

import {
  clearFileUpload,
  deleteDocumentLinks,
  startFileUpload
} from '../../../actions/fileUpload'
import { saveConcessionData } from '../../../actions/ui/notification'

//selectors
import {
  getLevel,
  getJwt,
  getParentJwt,
  getLevelId
} from '../../../reducers/accounts'
import {
  isLoading as getIsNavListLoading,
  getNavlistData
} from '../../../reducers/navlist'
import {
  getInstitutionData,
  getSessionAcademicYear
} from '../../../reducers/institution'
import { getPromotionalAcademicYear } from '../../../reducers/promotionGroups'
import {
  getIsLoading,
  getNavigation,
  getIsError
} from '../../../reducers/navigation'
import { getStatusTicket } from '../../../reducers/queryTicketing'
import {
  getNotificationArr,
  getReadNotificationsArr
} from '../../../reducers/notifications'
import { getFileDocumentLink } from '../../../reducers/fileUpload'

//helpers
import { getFormData, usePrevious } from '../../../helpers/utils'
import { listOfQueries } from '../../../helpers/enums'

//component
import Spinner from '../atom/spinner'
import ModalComponent from '../molecules/modal'
import Navigation from './navigation'
import LoginButtons from './loginButtons'
import SearchableDropdown from '../atom/searchableDropdown'
import FontAwesome from 'react-fontawesome'
import NotificationModal from './notificationModal'
import { Button, Card } from 'react-bootstrap'
import { DeleteButton } from '../atom/IconButtons'

const Header = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const jwt = useSelector(getJwt)
  const parentJwt = useSelector(getParentJwt)
  const level = useSelector(getLevel)
  // const isNavListLoading = useSelector(getIsNavListLoading)
  const institutionData = useSelector(getInstitutionData)
  const navlist = useSelector(getNavlistData)
  const sessionAcademicYear = useSelector(getSessionAcademicYear)
  const allAcademicYear = useSelector(getPromotionalAcademicYear)
  const levelId = useSelector(getLevelId)
  const isLoading = useSelector(getIsLoading)
  const isLoadingPrev = usePrevious(isLoading)
  const navigation = useSelector(getNavigation)
  const error = useSelector(getIsError)
  const ticketStatus = useSelector(getStatusTicket)
  const notificationArr = useSelector(getNotificationArr)
  const readNotificationsArr = useSelector(getReadNotificationsArr)
  const files = useSelector(getFileDocumentLink)

  const [academicYear, setAcademicYear] = useState(sessionAcademicYear)
  const [showModal, setShowModal] = useState(false)
  const [targetId, setTargetId] = useState('')
  const urlarray = location.pathname.split('/')
  const [mainNavigation, setMainNavigation] = useState([])
  const [subNavigation, setSubNavigation] = useState({})
  const [showTicketModal, setShowTicketModal] = useState(false)
  const [desc, setDesc] = useState('')
  const [title, setTitle] = useState('')
  const [showAddNew, setShowAddNew] = useState(false)
  const [newIssue, setNewIssue] = useState('')
  // const [showRead, setShowRead] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const targetRef = useRef(null)

  const useInterval = (callback, delay) => {
    const savedCallback = useRef() // Store the latest callback
    // Remember the latest callback if it changes.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval on mount and clear on unmount.
    useEffect(() => {
      let id

      function tick() {
        savedCallback.current()
      }

      if (delay !== null) {
        id = setInterval(tick, delay)
      }

      return () => clearInterval(id)
    }, [delay]) // Only update the interval on delay change
  }
  const [ticketDocs, setTicketDocs] = useState([])
  const [deleteFile, setDeleteFile] = useState(false)

  const fileInput = useRef('')

  useEffect(() => {
    dispatch(
      startFetchAcademicYearPromotionalGroups({ jwt, type: 'academics' })
    )
    // dispatch(startGetNotifications({}))
  }, [])

  useInterval(() => {
    if (
      level !== 'student' &&
      level !== 'master' &&
      location.pathname === '/dashboard'
    ) {
      dispatch(startGetNotifications({ jwt }))
    }
  }, 10000)

  useEffect(() => {
    // dispatch(startFetchNavigation(jwt))
    window.addEventListener('keydown', handleKeyPress)
    window.addEventListener('click', hideSubmenuOnOutsideClick)
  }, [])

  // useEffect(() => {
  //   level === 'parent'
  //     ? dispatch(startFetchNavlistForHeader(parentJwt))
  //     : dispatch(startFetchNavlistForHeader(jwt))
  //   window.addEventListener('keydown', handleKeyPress)
  //   window.addEventListener('click', hideSubmenuOnOutsideClick)
  // }, [level])

  useEffect(() => {
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
      window.addEventListener('click', hideSubmenuOnOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (!isLoading && isLoadingPrev !== undefined && !error) {
      let parent =
        navigation && navigation?.length > 0
          ? navigation
              ?.filter(nav => nav?.isParent)
              ?.sort((a, b) => a?.order - b?.order)
          : []
      let child =
        navigation && navigation?.length > 0
          ? navigation?.filter(nav => !nav?.isParent)
          : []
      let childGroupByParentId = _.groupBy(child, 'parentId')
      let childGroupByParentIdGroupByName = {}
      childGroupByParentId &&
        Object.keys(childGroupByParentId)?.length > 0 &&
        Object.keys(childGroupByParentId)?.forEach(child => {
          let childGroupByName = _.groupBy(
            childGroupByParentId[child],
            'groupByName'
          )
          childGroupByParentIdGroupByName[child] = childGroupByName
        })
      setMainNavigation(parent)
      setSubNavigation(childGroupByParentIdGroupByName)
    }
  }, [isLoading, isLoadingPrev, error])

  useEffect(() => {
    if (ticketStatus === 'posted') {
      setTicketDocs([])
      dispatch(clearFileUpload())
      closeTicketModal()
    }
  }, [ticketStatus])

  useEffect(() => {
    if (deleteFile) {
      setTicketDocs(files)
      setDeleteFile(false)
      fileInput.current.value = ''
    } else {
      let totalFiles = [...ticketDocs, ...files]
      let newFilesArray = []
      let lookupObject = {}
      for (let i in totalFiles) {
        lookupObject[totalFiles[i]['publicUrl']] = totalFiles[i]
      }
      for (let i in lookupObject) {
        newFilesArray.push(lookupObject[i])
      }
      setTicketDocs(newFilesArray)
    }
  }, [files])

  const truncateString = str => {
    return str.length > 20 ? `${str.substring(0, 20)}...` : str
  }

  const hideSubmenuOnOutsideClick = e => {
    // detect if clicked Outside of either the button and the submenu
    const navItemAncestor = e.target.closest('.nav-item-ctr')
    const submenuAncestor = e.target.closest('.submenu')
    if (navItemAncestor === null && submenuAncestor === null) hideSubmenu()
  }
  const handleKeyPress = e => {
    // dismiss on pressing Escape
    if (e.key === 'Escape') hideSubmenu()
  }
  const handleClick = e => {
    setTargetId(e.target.getAttribute('data-id'))
  }
  const hideSubmenu = () => {
    setTargetId('')
  }

  const onAcademicYearChange = e => {
    setAcademicYear(e.target.value)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setAcademicYear(institutionData?.current_academic_year)
  }

  const openTicketModal = () => {
    setShowTicketModal(true)
  }

  const closeTicketModal = () => {
    setTicketDocs([])
    dispatch(clearFileUpload())
    setDesc('')
    setTitle('')
    setNewIssue('')
    setShowTicketModal(false)
  }

  const handleAcademicYearChange = () => {
    dispatch(saveAcademicYearForSession(academicYear))
    setShowModal(false)
  }

  const handleTitleChange = obj => {
    const value = obj?.value
    if (value === 'Not Listed Above (Create new)') {
      setShowAddNew(true)
      setTitle(value)
    } else {
      setTitle(value)
      setShowAddNew(false)
    }
  }

  const handleAddNewIssue = e => {
    setNewIssue(e.target.value)
  }

  const handleDescChange = e => {
    setDesc(e.target.value)
  }

  const handleTicketSubmit = () => {
    const documentsArr = ticketDocs?.map(docObj => docObj?.publicUrl)
    const data = {
      title:
        title === 'Not Listed Above (Create new) (Create new)'
          ? newIssue
          : title,
      description: desc,
      documents: documentsArr
    }
    if (data?.documents?.length === 0) {
      delete data.documents
    }
    dispatch(startPostTicket({ jwt, data }))
  }

  const isTicketValid = () => {
    if (title === 'Not Listed Above (Create new)') {
      return !/^\s*$/.test(newIssue) && !/^\s*$/.test(desc)
    } else {
      return !/^\s*$/.test(title) && !/^\s*$/.test(desc)
    }
  }

  const onClickRead = () => {
    let notiIds = notificationArr?.map(noti => noti?.id)
    dispatch(startRemoveNotifications({ jwt, notiIds }))
    handleOverlayClose()
  }
  // if (
  //   // isNavListLoading ||
  //   isLoading
  // ) {
  //   return <Spinner />
  // }

  const onFileChange = e => {
    const files = getFormData(e.target.files)
    if (e.target.files.length === 1) files.append('name', e.target.files)
    dispatch(
      startFileUpload({
        body: files,
        jwt
      })
    )
  }

  const onDeleteFile = link => {
    let newDockLinks = ticketDocs?.filter(el => el.publicUrl !== link.publicUrl)
    setDeleteFile(true)
    dispatch(
      deleteDocumentLinks({
        data: newDockLinks
      })
    )
  }

  const onClickNotification = (path, idToRemove, notification) => {
    history.push(path)
    if (notification?.title === 'Fee concession') {
      let data = {
        studentGroup: notification?.student_group_id,
        umbrellaGroup: notification?.umbrella_group
      }
      dispatch(saveConcessionData(data))
    }
    dispatch(startRemoveNotifications({ jwt, notiIds: [idToRemove] }))
  }

  const handleButtonClick = () => {
    setShowPopover(!showPopover)
  }

  const handleOverlayClose = () => {
    setShowPopover(false)
  }
  return (
    <>
      <div className="header">
        <div className="header-left">
          <div className="header-left-top">
            <Link to="/">
              <img
                className="header-left-top__img img img-responsive"
                src={Logo}
                alt="sealtabs logo"
              />
            </Link>
          </div>
          <div className="header-left-bottom ">
            {truncateString(institutionData?.name)} |{' '}
            {moment().format('DD MMM, YYYY')} | {moment().format('hh:mm A')}
          </div>
        </div>

        <div className="header-right">
          <div className="header-right-top">
            <ul className="header-utilities">
              {/* <li className="header-utilities__item silverleaf">
                Sealtabs Learning
              </li> */}
              {/* <li className="header-utilities__item searchbar">
                <input type="text" placeholder="Search" />
              </li> */}
              {/* {level !== 'student' ? (
                <li className="header-utilities__item user-info">
                  <button className="button button--white" onClick={openModal}>
                    Change Academic Year
                  </button>
                </li>
              ) : (
                <li className="header-utilities__item user-info">
                  <button
                    className="button button--white"
                    onClick={openTicketModal}
                  >
                    Raise Ticket
                  </button>
                </li>
              )} */}
              {level === 'student' && (
                <li className="header-utilities__item user-info">
                  <button
                    className="button button--white"
                    onClick={openTicketModal}
                  >
                    Raise Ticket
                  </button>
                </li>
              )}
              {level !== 'student' &&
                level !== 'teacher' &&
                level !== 'master' && (
                  <li className="header-utilities__item user-info">
                    <NotificationModal
                      handleButtonClick={handleButtonClick}
                      handleOverlayClose={handleOverlayClose}
                      targetRef={targetRef}
                      showPopover={showPopover}
                      // showRead={showRead}
                      onClickRead={onClickRead}
                      notificationArr={notificationArr}
                      readNotificationsArr={readNotificationsArr}
                      onClickNotification={onClickNotification}
                    />
                  </li>
                )}

              <li className="header-utilities__item user-info">
                <span className="user-info__img" />
                <span className="user-info__name">
                  <LoginButtons />
                </span>
              </li>
            </ul>
          </div>
          <Navigation
            mainNavigation={mainNavigation}
            subNavigation={subNavigation}
            targetId={targetId}
            urlarray={urlarray}
            hideSubmenu={hideSubmenu}
            handleClick={handleClick}
          />
        </div>
        <ModalComponent
          showModal={showModal}
          closeModal={closeModal}
          modalHeaderText="CHANGE ACADEMIC YEAR"
          handleSubmit={handleAcademicYearChange}
          isReady={academicYear ? true : false}
          buttonText="Change"
        >
          <>
            <div className="info-row">
              <label className="info-cell info-cell--left">
                Current Academic Year
              </label>
              <span className="dash" />
              <p className="info-cell">
                {institutionData?.current_academic_year}
              </p>
            </div>
            <div className="info-row">
              <label className="info-cell info-cell--left">
                Change Academic Year
              </label>
              <span className="dash" />
              <select
                name="academicYear"
                className="info-cell"
                value={academicYear}
                onChange={onAcademicYearChange}
              >
                <option value="">Select Academic Year</option>
                {allAcademicYear &&
                  allAcademicYear?.length > 0 &&
                  allAcademicYear?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div className="space-creator" />
            <p className="warning--blue text-center">
              {
                'Selected Academic Year will be considered as the current academic year throughout SEALTBAS for this session.'
              }
            </p>
          </>
        </ModalComponent>
        <ModalComponent
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          modalHeaderText="Raise a Ticket"
          handleSubmit={handleTicketSubmit}
          isReady={isTicketValid()}
          buttonText="Submit"
        >
          <div className="info-row">
            <label className="info-cell info-cell--left">
              Please select the issue *
            </label>
            <span className="dash" />
            <SearchableDropdown
              className="info-cell"
              name="title"
              options={listOfQueries.map(type => {
                return { value: type, label: type }
              })}
              value={
                title
                  ? {
                      value: title,
                      label: title
                    }
                  : ''
              }
              onChange={handleTitleChange}
              placeholder="Select an issue"
            />
          </div>
          {showAddNew && (
            <div className="info-row">
              <label className="info-cell info-cell--left">
                Please Add new issue *
              </label>
              <span className="dash" />
              <input
                className="info-cell"
                value={newIssue}
                onChange={handleAddNewIssue}
                placeholder="Add New issue"
              />
            </div>
          )}
          <div className="info-row">
            <label className="info-cell info-cell--left">
              Please Explain the issue in Detail *
            </label>
            <span className="dash" />
            <textarea
              placeholder="Write here"
              name="description"
              value={desc}
              onChange={handleDescChange}
              className="info-cell"
              style={{ minHeight: '100px' }}
            />
          </div>
          <div className="info-row">
            <label className="info-cell info-cell--left">
              Upload files for reference
            </label>
            <span className="dash" />
            <div className="info-cell">
              <input type="file" onChange={onFileChange} ref={fileInput} />
            </div>
          </div>
          {ticketDocs &&
            ticketDocs?.length > 0 &&
            ticketDocs?.map((link, index) => {
              return (
                <div className="info-row" key={index}>
                  <span className="dash" />
                  <a
                    className="info-cell"
                    href={link?.publicUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.filename.startsWith('http')
                      ? `File ${index + 1}`
                      : link?.filename}
                  </a>
                  <DeleteButton onClick={() => onDeleteFile(link)} />
                  <span className="dash" />
                </div>
              )
            })}
          <div className="space-creator"></div>
        </ModalComponent>
      </div>
    </>
  )
}

export default Header
