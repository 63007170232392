import {
  APP_LOGOUT,
  START_FETCH_REGISTRATION_CERTY,
  START_FETCH_REGISTRATION_CERTY_SUCCESS,
  START_FETCH_REGISTRATION_CERTY_FAILURE
} from '../helpers/actions'

const initialState = {
  registerCertificate: [],
  message: '',
  isLoadingRegisterCertificate: false
}

export const getMessage = state => state.registrationCertificate.message
export const getIsLoadingRegisterCertificate = state =>
  state.registrationCertificate.isLoadingRegisterCertificate
export const getRegisterCertificate = state =>
  state.registrationCertificate.registerCertificate

export const registrationCertificate = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_FETCH_REGISTRATION_CERTY:
      return Object.assign(state, {
        isLoadingRegisterCertificate: true,
        message: ''
      })
    case START_FETCH_REGISTRATION_CERTY_SUCCESS:
      return Object.assign({}, state, {
        isLoadingRegisterCertificate: false,
        message: 'success',
        registerCertificate: action.data
      })
    case START_FETCH_REGISTRATION_CERTY_FAILURE:
      return Object.assign({}, state, {
        message: 'failed',
        isLoadingRegisterCertificate: false
      })
    default:
      return state
  }
}
