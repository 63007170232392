import {
  APP_LOGOUT,
  GET_STUDENT_IDCARD_START,
  GET_STUDENT_IDCARD_SUCCESS,
  GET_STUDENT_IDCARD_FAILURE,
  POST_STUDENT_IDCARD_START,
  POST_STUDENT_IDCARD_SUCCESS,
  POST_STUDENT_IDCARD_FAILURE,
  POST_TEACHER_IDCARD_START,
  POST_TEACHER_IDCARD_SUCCESS,
  POST_TEACHER_IDCARD_FAILURE,
  GET_TEACHER_IDCARD_START,
  GET_TEACHER_IDCARD_SUCCESS,
  GET_TEACHER_IDCARD_FAILURE
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: []
}

export const idCards = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_STUDENT_IDCARD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case GET_STUDENT_IDCARD_SUCCESS:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false
      })
    case GET_STUDENT_IDCARD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case POST_STUDENT_IDCARD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case POST_STUDENT_IDCARD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case POST_STUDENT_IDCARD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case GET_TEACHER_IDCARD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case GET_TEACHER_IDCARD_SUCCESS:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false
      })
    case GET_TEACHER_IDCARD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case POST_TEACHER_IDCARD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case POST_TEACHER_IDCARD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case POST_TEACHER_IDCARD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    default:
      return state
  }
}

export const byId = state => state.idCards.byId
export const getAllIds = state => state.idCards.allIds
export const isLoading = state => state.idCards.isLoading
