import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { NEWSUBJECT_FORM_NAME, SUBJECT_TYPE } from '../../helpers/enums'
import { errorToast } from '../../helpers/utils'

export const renderSubject = ({
  input: { name },
  meta: { touched, error }
}) => (
  <div>
    <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
      <div className="field">
        <label className="field__label" htmlFor="name">
          Subject Name *
        </label>
        <Field
          name={`${name}.name`}
          placeholder="Subject Name"
          type="text"
          component="input"
          required
        />
      </div>
      <div className="field">
        <label className="field__label" htmlFor="code">
          Subject Code *
        </label>
        <Field
          name={`${name}.code`}
          placeholder="Subject Code"
          type="text"
          component="input"
          required
        />
      </div>
    </div>
    <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
      <div className="field">
        <label className="field__label" htmlFor="order">
          Subject Order *
        </label>
        <Field
          name={`${name}.order`}
          placeholder="Subject Order"
          type="number"
          component="input"
          required
        />
      </div>
    </div>
  </div>
)

const selector = formValueSelector(NEWSUBJECT_FORM_NAME)

const mapStateToProps = state => {
  const subject = selector(state, 'subject')
  const is_main = selector(state, 'is_main')
  return {
    subject,
    is_main
  }
}

class AddSubjectFormComponent extends Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    //mapStateToProps
    subject: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      order: PropTypes.string
    }),
    is_main: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.isFormValid = this.isFormValid.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  isFormValid() {
    const { subject, is_main } = this.props
    return subject
      ? subject.name !== '' &&
          subject.code !== '' &&
          Number(subject.order) > 0 &&
          is_main !== undefined
      : false
  }

  handleBack(e) {
    e.preventDefault()
    this.props.onBack()
  }

  handleNext(e) {
    e.preventDefault()
    if (this.isFormValid()) this.props.onNext()
    else errorToast('Error: Please check fields and try again')
  }

  render() {
    return (
      <form className="st-group-form">
        <h3 class="page__subHead">Create Subject</h3>
        <div className="field">
          {/*TODO: Replace this with SubjectDetails from 'forms'*/}
          <Field name="subject" component={renderSubject} />
        </div>
        <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
          <div className="field">
            <label className="field__label" htmlFor="order">
              Select Type *
            </label>
            <Field
              name="subject.marksGrade"
              component="select"
              value="marks"
              required
            >
              <option value="" disabled>
                Select Type
              </option>
              {SUBJECT_TYPE.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="fields-ctr">
          <div className="field">
            <label className="field__label" htmlFor="is_main">
              Does this subject have Electives under it? *
            </label>
            <div>
              <span className="check-tab-ctr">
                <label className="check-tag">
                  <Field
                    name="is_main"
                    component="input"
                    type="radio"
                    value="true"
                    className="check-tag__input"
                  />
                  <span className="check-tag__text">Yes</span>
                </label>
              </span>
              <span className="check-tab-ctr">
                <label className="check-tag">
                  <Field
                    name="is_main"
                    component="input"
                    type="radio"
                    value="false"
                    className="check-tag__input"
                  />
                  <span className="check-tag__text">No</span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="button-group mar-t25px">
          <button
            type="button"
            className="button button--blue"
            onClick={this.handleBack}
          >
            Back
          </button>
          <button
            className="button button--blue"
            type="button"
            onClick={this.handleNext}
          >
            Next
          </button>
        </div>
        <div className="clear"></div>
      </form>
    )
  }
}

const AddSubjectForm = connect(mapStateToProps)(
  reduxForm({
    form: NEWSUBJECT_FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(AddSubjectFormComponent)
)

export default AddSubjectForm
