import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//selectors
import { getAcademicGrades, isLoading } from '../../reducers/groups'
import Spinner from '../spinner'
import ReduxSelect from './inputs'

const mapStateToProps = state => ({
  grades: getAcademicGrades(state),
  ready: !isLoading(state)
})

class SelectClassFormComponent extends Component {
  static propTypes = {
    ready: PropTypes.bool.isRequired,
    grades: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  render() {
    const { ready, grades } = this.props
    if (ready)
      return (
        <ReduxSelect name="grade" options={grades} placeholder="Select Grade" />
      )
    return <Spinner />
  }
}

const SelectClassForm = connect(mapStateToProps)(SelectClassFormComponent)

export default SelectClassForm
