import {
  APP_LOGOUT,
  GET_SMS_TEMPLATE_START,
  GET_SMS_TEMPLATE_SUCCESS,
  GET_SMS_TEMPLATE_FAIL,
  GET_SMS_TEMPLATE_GROUP_NAMES_START,
  GET_SMS_TEMPLATE_GROUP_NAMES_SUCCESS,
  GET_SMS_TEMPLATE_GROUP_NAMES_FAIL,
  POST_SMS_TEMPLATE_START,
  POST_SMS_TEMPLATE_SUCCESS,
  POST_SMS_TEMPLATE_FAIL,
  DELETE_SMS_TEMPLATE_START,
  DELETE_SMS_TEMPLATE_SUCCESS,
  DELETE_SMS_TEMPLATE_FAIL,
  PATCH_SMS_TEMPLATE_START,
  PATCH_SMS_TEMPLATE_SUCCESS,
  PATCH_SMS_TEMPLATE_FAIL,
  GET_SMS_TEMPLATE_BY_ID_START,
  GET_SMS_TEMPLATE_BY_ID_SUCCESS,
  GET_SMS_TEMPLATE_BY_ID_FAIL,
  POST_SMS_TEMPLATE_CSV_START,
  POST_SMS_TEMPLATE_CSV_SUCCESS,
  POST_SMS_TEMPLATE_CSV_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isGroupNameLoading: false,
  isPatchLoading: false,
  isSmsByIdLoading: false,
  isPostCsv: false,
  isErr: false,
  errMsg: '',
  status: '',
  successMsg: '',
  templates: [],
  templateGroupNames: [],
  smsById: []
}

export const smsTemplate = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_SMS_TEMPLATE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_SMS_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        templates: action.data,
        status: 'fetched'
      })
    case GET_SMS_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_SMS_TEMPLATE_GROUP_NAMES_START:
      return Object.assign({}, state, {
        isGroupNameLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_SMS_TEMPLATE_GROUP_NAMES_SUCCESS:
      return Object.assign({}, state, {
        isGroupNameLoading: false,
        isErr: false,
        templateGroupNames: action.data,
        status: 'fetched'
      })
    case GET_SMS_TEMPLATE_GROUP_NAMES_FAIL:
      return Object.assign({}, state, {
        isGroupNameLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case POST_SMS_TEMPLATE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case POST_SMS_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'posted'
      })
    case POST_SMS_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case DELETE_SMS_TEMPLATE_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        status: '',
        isErr: false
      })
    case DELETE_SMS_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        status: 'delete',
        isErr: false
      })
    case DELETE_SMS_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        status: '',
        isErr: true
      })
    case PATCH_SMS_TEMPLATE_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        status: '',
        isErr: false
      })
    case PATCH_SMS_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        status: 'patch',
        isErr: false
      })
    case PATCH_SMS_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        status: '',
        isErr: true
      })
    case GET_SMS_TEMPLATE_BY_ID_START:
      return Object.assign({}, state, {
        isSmsByIdLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_SMS_TEMPLATE_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isSmsByIdLoading: false,
        isErr: false,
        smsById: action.data,
        status: 'fetched'
      })
    case GET_SMS_TEMPLATE_BY_ID_FAIL:
      return Object.assign({}, state, {
        isSmsByIdLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case POST_SMS_TEMPLATE_CSV_START:
      return Object.assign({}, state, {
        isPostCsv: true,
        isErr: false,
        status: ''
      })
    case POST_SMS_TEMPLATE_CSV_SUCCESS:
      return Object.assign({}, state, {
        isPostCsv: false,
        isErr: false,
        status: 'csvPosted'
      })
    case POST_SMS_TEMPLATE_CSV_FAIL:
      return Object.assign({}, state, {
        isPostCsv: false,
        isErr: true,
        status: ''
      })
    default:
      return state
  }
}

export const getIsLoading = state => state.smsTemplate.isLoading
export const getIsGroupNameLoading = state =>
  state.smsTemplate.isGroupNameLoading
export const getIsError = state => state.smsTemplate.isErr
export const getErrorMessage = state => state.smsTemplate.errMsg
export const getStatus = state => state.smsTemplate.status
export const getTemplates = state => state.smsTemplate.templates
export const getTemplateGroupNames = state =>
  state.smsTemplate.templateGroupNames
export const getPatchLoading = state => state.smsTemplate.isPatchLoading
export const getSmsByIdLoading = state => state.smsTemplate.isSmsByIdLoading
export const getSmsById = state => state.smsTemplate.smsById
export const getCsvPostLoading = state => state.smsTemplate.isPostCsv
