import React from 'react'
import PropTypes from 'prop-types'
import StudentLink from '../common/studentLink'
import { getMark, checkForElective } from '../../helpers/utils'

export const StudentRow = ({ student, subjectData }) => {
  return (
    subjectData.length !== 0 && (
      <tr className="table-row">
        <td className="table-row__cell">{student.roll_number}</td>
        <td className="table-row__cell">
          <StudentLink studentId={student._id}>{student.name}</StudentLink>
        </td>
        {subjectData.map(subjectObj => {
          return student.marks[subjectObj._id] === undefined ? (
            <td className="table-row__cell__center" key={subjectObj._id} />
          ) : (
            <td key={subjectObj._id} className="table-row__cell__center">
              {getMark(student.marks[subjectObj._id], subjectObj)}
              {checkForElective(student.marks[subjectObj._id])}
            </td>
          )
        })}
        {student.total === undefined || isNaN(student.total) ? (
          <td className="table-row__cell__center" />
        ) : (
          <td className="table-row__cell__center">{student.total}</td>
        )}
        {student.percentage === undefined || isNaN(student.percentage) ? (
          <td className="table-row__cell__center table-row__cell--empty-right" />
        ) : (
          <td className="table-row__cell__center table-row__cell--empty-right">
            {student.percentage}
          </td>
        )}
        {student.sectionPosition === undefined ||
        isNaN(student.sectionPosition) ? (
          <td className="table-row__cell__center table-row__cell--empty-right" />
        ) : (
          <td className="table-row__cell__center table-row__cell--empty-right">
            {student.sectionPosition}
          </td>
        )}
        {student.classPosition === undefined || isNaN(student.classPosition) ? (
          <td className="table-row__cell__center table-row__cell--empty-right" />
        ) : (
          <td className="table-row__cell__center table-row__cell--empty-right">
            {student.classPosition}
          </td>
        )}
      </tr>
    )
  )
}

StudentRow.propTypes = {
  student: PropTypes.object.isRequired,
  subjectData: PropTypes.array.isRequired
}
