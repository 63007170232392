import React from 'react'
import { Field } from 'redux-form'

import { MONTHS_TYPES } from '../../helpers/enums'

const TestDetailForm = () => (
  <div>
    <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
      <div className="field">
        <label className="field__label" htmlFor="name">
          Name of Test
        </label>
        <Field
          name="name"
          id="name"
          placeholder="Test Name"
          type="text"
          component="input"
          required
        />
      </div>
      <div className="field">
        <label className="field__label" htmlFor="percentage">
          Percentage
        </label>
        <Field
          name="percentage"
          id="percentage"
          placeholder="Percentage"
          type="number"
          component="input"
          required
        />
      </div>
    </div>
    <div className="fields-ctr fields-ctr--has-3-fields fields-ctr--no-border">
      <div className="field">
        <label className="field__label" htmlFor="month">
          Month
        </label>
        <Field
          name="month"
          id="month"
          placeholder="Month"
          type="string"
          component="select"
          required
        >
          <option value="" disabled>
            Select Month
          </option>
          {MONTHS_TYPES.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </Field>
      </div>
      <div className="field">
        <label className="field__label" htmlFor="order">
          Test Order
        </label>
        <Field
          name="order"
          id="order"
          placeholder="Test Order"
          type="number"
          component="input"
          required
        />
      </div>
      <div className="field">
        <label className="field__label" htmlFor="term">
          Term
        </label>
        <Field
          name="term"
          id="term"
          placeholder="Term"
          type="number"
          component="input"
          required
        />
      </div>
    </div>
  </div>
)

export default TestDetailForm
