import React from 'react'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import PropTypes from 'prop-types'

import { SelectInput, SelectInputWithID } from '../inputs.jsx'
import { uniqueParentGroups, filterSectionsByParent } from '../../helpers/utils'

import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../helpers/enums'

const GroupSelector = ({
  isDirty,
  academicGroups,
  umbrellaGroup,
  groupId,
  handleChange,
  handleSubmit
}) => {
  return (
    <form className="group-selector" onSubmit={handleSubmit}>
      <SelectInput
        name="parent_group"
        options={uniqueParentGroups(academicGroups)}
        umbrellaGroup={umbrellaGroup}
        handleChange={handleChange}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="group_name"
        options={filterSectionsByParent(umbrellaGroup, academicGroups)}
        id={groupId}
        handleChange={handleChange}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <FontAwesome size="2x" name="caret-right" />
      <button
        type="submit"
        className={classNames({
          'button button--small button--blue': true,
          disabled: !isDirty
        })}
        disabled={!isDirty}
      >
        Go
      </button>
    </form>
  )
}

GroupSelector.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  academicGroups: PropTypes.array.isRequired,
  umbrellaGroup: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default GroupSelector
