import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { version } from '../../../package.alias.json'
import moment from 'moment'

//actions
import {
  emitLogout,
  emitParentLevel,
  emitSupportLevel
} from '../../../actions/login.js'
import { saveData } from '../../../actions/ui/studentProfile'
import { saveAttendanceData } from '../../../actions/ui/attendance'

//reducers
import {
  isLoggedIn as getIsUserLoggedIn,
  isSupportLoggedIn,
  getName,
  getSiblings,
  getLevel
} from '../../../reducers/accounts'
import { clearTeacherData } from '../../../actions/teacher.js'

const LoginButtons = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const supportLoggedIn = useSelector(isSupportLoggedIn)
  const siblings = useSelector(getSiblings)
  const name = useSelector(getName)
  const level = useSelector(getLevel)

  const handleChangePassword = () => {
    history.push({
      pathname: '/change-password'
    })
  }
  const handleSwitchAccount = () => {
    if (level === 'support' || level === 'superAdmin') {
      dispatch(emitSupportLevel())
      history.push({
        pathname: '/dashboard'
      })
      dispatch(
        saveData({
          studentlistUmbrellaGroup: '',
          studentlistGroupId: '',
          academicYear: ''
        })
      )
      dispatch(
        saveAttendanceData({
          umbrellaGroup: '',
          groupId: '',
          date: moment()
        })
      )
      // dispatch(clearTeacherData())
    } else {
      dispatch(emitParentLevel())
      history.push({
        pathname: '/'
      })
    }
  }

  const handleLogout = () => {
    dispatch(emitLogout())
    history.push({
      pathname: '/login'
    })
  }

  if (isLoggedIn && !supportLoggedIn) {
    return (
      <div>
        <DropdownButton
          pullright={'true'}
          bsstyle={'default'}
          className="primarybutton"
          title={name}
          id={'login-buttons'}
          size="bg"
        >
          <Dropdown.Item
            style={{ margin: 10 }}
            eventKey="1"
            onSelect={handleChangePassword}
          >
            Change Password
          </Dropdown.Item>
          <Dropdown.Divider />
          {(siblings && siblings.length > 0) ||
          level === 'support' ||
          level === 'superAdmin' ? (
            <>
              <Dropdown.Item
                style={{ margin: 10 }}
                eventKey="3"
                onSelect={handleSwitchAccount}
              >
                Switch Account
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          ) : null}
          <Dropdown.Item
            style={{ margin: 10 }}
            eventKey="2"
            onSelect={handleLogout}
          >
            Logout
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item style={{ margin: 10 }}>
            Version: {version}
          </Dropdown.Item>
        </DropdownButton>
      </div>
    )
  }
  if (supportLoggedIn) {
    return (
      <div>
        <DropdownButton
          pullright={'true'}
          bsstyle={'default'}
          className="primarybutton"
          title={name}
          id={'login-buttons'}
        >
          <Dropdown.Divider />
          <Dropdown.Item
            style={{ margin: 10 }}
            eventKey="2"
            onSelect={handleLogout}
          >
            Logout
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item style={{ margin: 10 }}>
            Version: {version}
          </Dropdown.Item>
        </DropdownButton>
      </div>
    )
  } else {
    return (
      <Link className="login" to="/login">
        Login
      </Link>
    )
  }
}

export default LoginButtons
