// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'redux-form'
import FontAwesome from 'react-fontawesome'
import { renderSubject } from '../groups/newSubjectForm.jsx'

export default class ElectiveDetails extends Component {
  render() {
    return (
      <div className="form-ctr">
        <h3 className="page__subHead">Elective Details</h3>
        <FieldArray name="electives" component={renderElectives} />
      </div>
    )
  }
}

export const renderElectives = ({ fields, meta: { error } }) => (
  <div className="section-details elective-fields">
    <ul>
      {fields.map((field, index) => (
        <li className="section-details-feilds" key={index}>
          {/* <label htmlFor={`${field}`} className="section-num">
            {index}
          </label> */}
          {/* <label className="field__label" htmlFor={`${field}`}>
              Elective #{index + 1}
            </label> */}
          <div className="row">
            <div className="col-md-8">
              <h3 className="subHead">Elective #{index + 1}</h3>
            </div>
            <div className="col-md-4">
              <button
                className="button button--round button--red button__delete"
                type="button"
                title="Remove Section"
                onClick={() => fields.remove(index)}
              >
                <FontAwesome name="trash" />
              </button>
            </div>
          </div>
          <div className="clear"></div>
          <Field name={`${field}`} component={renderSubject} />
          <div className="clear"></div>
        </li>
      ))}
      {error && <li className="error">{error}</li>}
      <li>
        <button
          className="button button--bordered"
          type="button"
          onClick={() => fields.push()}
        >
          + Add Elective
        </button>
      </li>
    </ul>
    <div className="clear"></div>
  </div>
)

renderElectives.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.bool
  }).isRequired
}
