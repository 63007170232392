import {
  INSTITUTION_DATA,
  INSTITUTION_DATA_START,
  INSTITUTION_DATA_FAIL,
  SET_ACADEMIC_YEAR_DATA,
  POST_INSTITUTION_START,
  POST_INSTITUTION_FAIL,
  POST_INSTITUTION_DATA,
  FETCH_ALL_INSTITUTIONS_DATA,
  FETCH_ALL_INSTITUTIONS_START,
  FETCH_ALL_INSTITUTIONS_FAIL,
  FETCH_ALL_INSTITUTIONS_USERS_DATA,
  FETCH_ALL_INSTITUTIONS_USERS_START,
  FETCH_ALL_INSTITUTIONS_USERS_FAIL,
  POST_INSTITUTION_FINANCIAL_DATA,
  POST_INSTITUTION_FINANCIAL_START,
  POST_INSTITUTION_FINANCIAL_FAIL,
  ADD_INSTITUTIONS_USER_DATA,
  ADD_INSTITUTIONS_USER_START,
  ADD_INSTITUTIONS_USER_FAIL,
  POST_BILLING_TRANSACTIONS_DATA,
  POST_BILLING_TRANSACTIONS_START,
  POST_BILLING_TRANSACTIONS_FAIL,
  APP_LOGOUT,
  CHANGE_ACADEMIC_YEAR_FOR_SESSION,
  GET_SUPPORT_INSTITUTION_START,
  GET_SUPPORT_INSTITUTION_SUCCESS,
  GET_SUPPORT_INSTITUTION_FAIL,
  FETCH_ALL_SUPPORT,
  FETCH_ALL_SUPPORT_START,
  FETCH_ALL_SUPPORT_FAIL,
  DELETE_SUPPORT_USER_START,
  DELETE_SUPPORT_USER_SUCCESS,
  DELETE_SUPPORT_USER_FAIL,
  RESET_SUPPORT_START,
  RESET_SUPPORT_SUCCESS,
  RESET_SUPPORT_FAIL,
  GET_LOG_REPORT_START,
  GET_LOG_REPORT_SUCCESS,
  GET_LOG_REPORT_FAIL
} from '../helpers/actions.js'
import { createSelector } from 'reselect'

const initialState = {
  institutionData: [],
  financial: {},
  isLoadingFinancial: false,
  isErrFinancial: false,
  sessionAcademicYear: '',
  set_academic_year: '',
  isLoadingPost: false,
  isLoadingGetInstitution: false,
  postedInstitutionData: {},
  isErr: false,
  errMsg: '',
  successMsg: '',
  isLoadingGetAllInstitution: false,
  allInstitutions: [],
  institutionById: {},
  isLoadingGetAllInstitutionUsers: false,
  allInstitutionsUsers: [],
  isLoadingAddInstitutionUser: false,
  institutionsUser: {},
  isLoadingBillingTransactions: false,
  transactions: [],
  supportInstitutionLoading: false,
  supportData: {},
  allSupportUser: [],
  supportLoading: false,
  deleteSupportLoading: false,
  resetLoading: false,
  logReportLoading: false,
  logReport: []
}

export const institution = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_INSTITUTION_FINANCIAL_DATA:
      return Object.assign({}, state, {
        isLoadingFinancial: true,
        isErrFinancial: false
      })
    case POST_INSTITUTION_FINANCIAL_START:
      return Object.assign({}, state, {
        isLoadingFinancial: false,
        isErrFinancial: false,
        financial: action.data
      })
    case POST_INSTITUTION_FINANCIAL_FAIL:
      return Object.assign({}, state, {
        isLoadingFinancial: false,
        isErrFinancial: true
      })
    case INSTITUTION_DATA_START:
      return Object.assign({}, state, {
        isLoadingGetInstitution: true,
        isErr: false
      })
    case INSTITUTION_DATA:
      return Object.assign({}, state, {
        isLoadingGetInstitution: false,
        isErr: false,
        institutionData: Array.isArray(action.data)
          ? action.data
          : [action.data]
      })
    case INSTITUTION_DATA_FAIL:
      return Object.assign({}, state, {
        isLoadingGetInstitution: false,
        isErr: true
      })
    case SET_ACADEMIC_YEAR_DATA:
      return Object.assign({}, state, {
        set_academic_year: action.data
      })
    case POST_INSTITUTION_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case POST_INSTITUTION_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: ''
      })
    case POST_INSTITUTION_DATA:
      return Object.assign({}, state, {
        isLoadingPost: false,
        postedInstitutionData: action.data,
        isErr: false,
        successMsg: ''
      })
    case FETCH_ALL_INSTITUTIONS_START:
      return Object.assign({}, state, {
        isLoadingGetAllInstitution: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case FETCH_ALL_INSTITUTIONS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetAllInstitution: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: ''
      })
    case FETCH_ALL_INSTITUTIONS_DATA:
      return Object.assign({}, state, {
        isLoadingGetAllInstitution: false,
        allInstitutions: action.data,
        institutionById: action?.byId,
        isErr: false,
        successMsg: ''
      })
    case FETCH_ALL_INSTITUTIONS_USERS_START:
      return Object.assign({}, state, {
        isLoadingGetAllInstitutionUsers: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case FETCH_ALL_INSTITUTIONS_USERS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetAllInstitutionUsers: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: ''
      })
    case FETCH_ALL_INSTITUTIONS_USERS_DATA:
      return Object.assign({}, state, {
        isLoadingGetAllInstitutionUsers: false,
        allInstitutionsUsers: action.data,
        isErr: false,
        successMsg: ''
      })
    case ADD_INSTITUTIONS_USER_START:
      return Object.assign({}, state, {
        isLoadingAddInstitutionUser: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case ADD_INSTITUTIONS_USER_FAIL:
      return Object.assign({}, state, {
        isLoadingAddInstitutionUser: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: ''
      })
    case ADD_INSTITUTIONS_USER_DATA:
      return Object.assign({}, state, {
        isLoadingAddInstitutionUser: false,
        institutionsUser: action.data,
        isErr: false,
        successMsg: ''
      })
    case POST_BILLING_TRANSACTIONS_START:
      return Object.assign({}, state, {
        isLoadingBillingTransactions: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case POST_BILLING_TRANSACTIONS_FAIL:
      return Object.assign({}, state, {
        isLoadingBillingTransactions: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: ''
      })
    case POST_BILLING_TRANSACTIONS_DATA:
      return Object.assign({}, state, {
        isLoadingBillingTransactions: false,
        transactions: action.data,
        isErr: false,
        successMsg: ''
      })
    case CHANGE_ACADEMIC_YEAR_FOR_SESSION:
      return Object.assign({}, state, {
        sessionAcademicYear: action.data
      })
    case GET_SUPPORT_INSTITUTION_START:
      return Object.assign({}, state, {
        supportInstitutionLoading: true,
        isErr: false
      })
    case GET_SUPPORT_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {
        supportInstitutionLoading: false,
        supportData: action.data,
        isErr: false
      })
    case GET_SUPPORT_INSTITUTION_FAIL:
      return Object.assign({}, state, {
        supportInstitutionLoading: false,
        isErr: true
      })
    case FETCH_ALL_SUPPORT_START:
      return Object.assign({}, state, {
        supportLoading: true,
        isErr: false
      })
    case FETCH_ALL_SUPPORT:
      return Object.assign({}, state, {
        supportLoading: false,
        allSupportUser: action.data,
        isErr: false
      })
    case FETCH_ALL_SUPPORT_FAIL:
      return Object.assign({}, state, {
        supportLoading: false,
        isErr: true
      })
    case DELETE_SUPPORT_USER_START:
      return Object.assign({}, state, {
        deleteSupportLoading: true,
        isErr: false
      })
    case DELETE_SUPPORT_USER_SUCCESS:
      return Object.assign({}, state, {
        deleteSupportLoading: false,
        isErr: false
      })
    case DELETE_SUPPORT_USER_FAIL:
      return Object.assign({}, state, {
        deleteSupportLoading: false,
        isErr: true
      })
    case RESET_SUPPORT_START:
      return Object.assign({}, state, {
        resetLoading: true,
        isErr: false
      })
    case RESET_SUPPORT_SUCCESS:
      return Object.assign({}, state, {
        resetLoading: false,
        isErr: false
      })
    case RESET_SUPPORT_FAIL:
      return Object.assign({}, state, {
        resetLoading: false,
        isErr: true
      })
    case GET_LOG_REPORT_START:
      return Object.assign({}, state, {
        logReportLoading: true,
        isErr: false
      })
    case GET_LOG_REPORT_SUCCESS:
      return Object.assign({}, state, {
        logReportLoading: false,
        logReport: action.data.user_sessions_data,
        isErr: false
      })
    case GET_LOG_REPORT_FAIL:
      return Object.assign({}, state, {
        logReportLoading: false,
        isErr: true
      })
    default:
      return state
  }
}

export const getInstitutionData = state =>
  Object.assign(
    {},
    {
      name: '',
      current_academic_year: '',
      attendance_type: '',
      logo: '',
      principalData: {
        name: ''
      },
      address: ''
    },
    state?.institution?.institutionData?.[0]
  )

export const getInstitutionDataMain = state =>
  Object.assign(
    {},
    {
      name: '',
      current_academic_year: '',
      attendance_type: '',
      logo: '',
      principalData: {
        name: ''
      },
      address: ''
    },
    state?.institution?.institutionData
  )

// export const getAcademicYear = createSelector(
//   getInstitutionData,
//   institutionArr => {
//     return institutionArr.current_academic_year
//   }
// )
export const getSessionAcademicYear = state =>
  state.institution.sessionAcademicYear

export const getAcademicYear = createSelector(
  getInstitutionData,
  getSessionAcademicYear,
  (institutionArr, sessionAcademicYear) => {
    return sessionAcademicYear
      ? sessionAcademicYear
      : institutionArr.current_academic_year
  }
)

export const getSetAcademicYear = state => state.institution.set_academic_year
export const getIsLoadingGetInstitution = state =>
  state.institution.isLoadingGetInstitution
export const getIsLoadingPost = state => state.institution.isLoadingPost
export const getPostedInstitutionData = state =>
  state.institution.postedInstitutionData
export const getIsErr = state => state.institution.isErr
export const getIsLoadingGetAllInstitution = state =>
  state.institution.isLoadingGetAllInstitution
export const getAllInstitutions = state => state.institution.allInstitutions
export const getInstitutionById = state => state.institution.institutionById
export const getIsLoadingGetAllInstitutionUsers = state =>
  state.institution.isLoadingGetAllInstitutionUsers
export const getAllInstitutionsUsers = state =>
  state.institution.allInstitutionsUsers
export const getIsLoadingAddInstitutionUser = state =>
  state.institution.isLoadingAddInstitutionUser

export const getIsLoadingFinancial = state =>
  state.institution.isLoadingFinancial
export const getIsErrFinancial = state => state.institution.isErrFinancial
export const getIsLoadingBillingTransactions = state =>
  state.institution.isLoadingBillingTransactions
export const getTransactions = state => state.institution.transactions
export const getSupportData = state => state.institution.supportData
export const getSupportInstitutionLoading = state =>
  state.institution.supportInstitutionLoading
export const getSupportLoading = state => state.institution.supportLoading
export const getAllSupoortUsers = state => state.institution.allSupportUser
export const getDeleteSupportLoading = state =>
  state.institution.deleteSupportLoading
export const getResetLoading = state => state.institution.resetLoading
export const getLogReportLoading = state => state.institution.logReportLoading
export const getLogReport = state => state.institution.logReport

export const getAttendanceType = createSelector(
  getInstitutionData,
  institutionArr => {
    return institutionArr.attendance_type
  }
)

export const getDisplayAttendanceType = createSelector(
  getInstitutionData,
  institutionArr => {
    return institutionArr.display_attendance_type
      ? institutionArr.display_attendance_type
      : institutionArr.attendance_type
  }
)
export const getPrincipalData = createSelector(
  getInstitutionData,
  institutionArr => {
    return institutionArr.principalData
  }
)
