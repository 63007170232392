import { merge, union } from 'lodash'
import { createSelector } from 'reselect'
import {
  APP_LOGOUT,
  STUDENTS_DATA,
  STUDENTS_GET_STARTED,
  ADD_STUDENT_START,
  ADD_STUDENT_SUCCESS,
  ADD_NEW_STUDENT,
  EDIT_STUDENT,
  ADD_STUDENT_FAIL,
  CHECK_ADMISSION_NUMBER_UNIQUENESS,
  STUDENTS_SEARCH_DATA,
  UPDATE_PATCH_PICTURE_DATA,
  FETCH_ALL_STUDENT_GROUPS_SUCCESS,
  ADD_STUDENT_CSV_FAIL,
  ADD_STUDENT_USERS_START,
  ADD_STUDENT_USERS,
  ADD_STUDENT_USERS_FAIL,
  REMOVE_STUDENT_START,
  REMOVE_STUDENT_SUCCESS,
  REMOVE_STUDENT_FAIL,
  GET_STUDENT_EMAIL_LOGS_START,
  GET_STUDENT_EMAIL_LOGS,
  GET_STUDENT_EMAIL_LOGS_FAIL,
  STUDENT_UPLOAD_PROFILE_PIC_START,
  STUDENT_UPLOAD_PROFILE_PIC,
  STUDENT_UPLOAD_PROFILE_PIC_FAIL,
  GET_STUDENT_HOUSE_COUNT_FAIL,
  GET_STUDENT_HOUSE_COUNT_START,
  GET_STUDENT_HOUSE_COUNT_SUCCESS
} from '../helpers/actions.js'

import { groupId } from './ui/attendance'
import { groupsById } from './groups'
import { groupIdForMarks } from './ui/marks'
import { getActiveStudent } from './ui/studentProfile'

const underscore = require('underscore')

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  err: '',
  addStudentSuccess: false,
  admissionNumberIsUnique: true,
  searchById: {},
  searchAllIds: [],
  allStudentGroups: [],
  addMessage: '',
  isLoadingAddStudentUsers: false,
  isErr: false,
  isRemoveLoading: false,
  houseCount: []
}

export const students = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case STUDENTS_DATA:
      if (action.entities && action.result) {
        const byId = merge({}, state.byId, action.entities.students)
        const allIds = merge([], state.allIds, action.result)
        return Object.assign({}, state, {
          isLoading: false,
          byId,
          allIds,
          addMessage: ''
        })
      }
      break
    case STUDENTS_GET_STARTED:
      return Object.assign({}, state, {
        isLoading: true,
        addMessage: ''
      })
    case ADD_STUDENT_START:
      return Object.assign({}, state, {
        isLoading: true,
        err: '',
        addStudentSuccess: false,
        addMessage: ''
      })
    case ADD_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        err: '',
        addStudentSuccess: true,
        addMessage: 'success'
      })
    case ADD_NEW_STUDENT:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        allIds: [...state.allIds, action.data._id],
        isLoading: false,
        err: '',
        addStudentSuccess: true,
        addMessage: 'success'
      })
    case EDIT_STUDENT:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        isLoading: false,
        err: '',
        addMessage: 'success'
      })
    case UPDATE_PATCH_PICTURE_DATA:
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.data['_id']]: action.data
        }),
        allIds: union([], state.allIds, [action.data['_id']]),
        addMessage: ''
      })
    case ADD_STUDENT_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        err: action.err,
        addStudentSuccess: false,
        addMessage: ''
      })
    case CHECK_ADMISSION_NUMBER_UNIQUENESS:
      return Object.assign({}, state, {
        admissionNumberIsUnique: action.data,
        addMessage: ''
      })

    case ADD_STUDENT_CSV_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        byId: {},
        allIds: [],
        addStudentSuccess: false,
        addMessage: ''
      })
    case STUDENTS_SEARCH_DATA:
      if (action.entities && action.result) {
        return Object.assign({}, state, {
          isLoading: false,
          searchById: action.entities.students,
          searchAllIds: action.result,
          addMessage: ''
        })
      }
    case FETCH_ALL_STUDENT_GROUPS_SUCCESS:
      if (action.data) {
        return Object.assign({}, state, {
          allStudentGroups: action.data?.[0],
          addMessage: ''
        })
      }
    case ADD_STUDENT_USERS_START:
      return Object.assign({}, state, {
        isLoadingAddStudentUsers: true,
        isErr: false
      })
    case ADD_STUDENT_USERS:
      return Object.assign({}, state, {
        isLoadingAddStudentUsers: false,
        isErr: false
      })
    case ADD_STUDENT_USERS_FAIL:
      return Object.assign({}, state, {
        isLoadingAddStudentUsers: false,
        isErr: true
      })
    case REMOVE_STUDENT_START:
      return Object.assign({}, state, {
        isRemoveLoading: true,
        isErr: false
      })
    case REMOVE_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        isRemoveLoading: false,
        isErr: false
      })
    case REMOVE_STUDENT_FAIL:
      return Object.assign({}, state, {
        isRemoveLoading: false,
        isErr: true
      })
    case GET_STUDENT_EMAIL_LOGS_START:
      return Object.assign({}, state, {
        isLoadingGetStudentEmailLogs: true,
        isErr: false
      })
    case GET_STUDENT_EMAIL_LOGS:
      return Object.assign({}, state, {
        isLoadingGetStudentEmailLogs: false,
        studentEmailLogs: action.data,
        isErr: false
      })
    case GET_STUDENT_EMAIL_LOGS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetStudentEmailLogs: false,
        isErr: true
      })
      break
    case STUDENT_UPLOAD_PROFILE_PIC_START:
      return Object.assign({}, state, {
        isUploadProfilePicLoading: true,
        isErr: false
      })
    case STUDENT_UPLOAD_PROFILE_PIC:
      return Object.assign({}, state, {
        isUploadProfilePicLoading: false,
        isErr: false
      })
    case STUDENT_UPLOAD_PROFILE_PIC_FAIL:
      return Object.assign({}, state, {
        isUploadProfilePicLoading: false,
        isErr: true
      })
    case GET_STUDENT_HOUSE_COUNT_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false
      })
    case GET_STUDENT_HOUSE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        houseCount: action.houseCount
      })
    case GET_STUDENT_HOUSE_COUNT_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        err: action.errMsg
      })
    default:
      return state
  }
}

export const getLoading = state => state.students.isLoading

export const getById = state => state.students.byId

export const getAllIds = state => state.students.allIds

export const getAdmissionNumberIsUnique = state =>
  state.students.admissionNumberIsUnique

export const getSearchById = state => state.students.searchById

export const getSearchAllIds = state => state.students.searchAllIds

export const getAllStudentGroups = state => state.students.allStudentGroups

export const getAddStudentSuccess = state => state.students.addStudentSuccess

export const getAddMessage = state => state.students.addMessage
export const getIsLoadingAddStudentUsers = state =>
  state.students.isLoadingAddStudentUsers
export const getError = state => state.students.isErr
export const getRemoveStudentLoading = state => state.students.isRemoveLoading
export const getIsLoadingGetStudentEmailLogs = state =>
  state.students.isLoadingGetStudentEmailLogs
export const getStudentEmailLogs = state => state.students.studentEmailLogs
export const getUploadProfilePicLoading = state =>
  state.students.isUploadProfilePicLoading
export const getStudentHouseCount = state => state.students.houseCount

export const getStudentData = createSelector(
  getById,
  getAllIds,
  (data, students) => {
    if (students.length === 0) return []
    return students
      .map(student => {
        const {
          _id,
          admission_number,
          roll_number,
          name,
          gender,
          parents,
          addresses,
          phone
        } = data[student]
        return {
          _id,
          admission_number,
          roll_number,
          name,
          gender,
          parents,
          addresses,
          phone
        }
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 1
      })
  }
)

export const getStudentDataPerGroup = createSelector(
  getById,
  groupsById,
  groupId,
  (studentsById, groupsById, groupId) => {
    if (
      Object.keys(studentsById).length === 0 ||
      Object.keys(groupsById).length === 0 ||
      groupId === ''
    )
      return []
    const studentIds = groupsById[groupId].students
    if (studentIds.length === 0) return []
    if (
      underscore.intersection(studentIds, Object.keys(studentsById)).length !==
      studentIds.length
    )
      return []
    return studentIds
      .map(id => {
        const { _id, admission_number, roll_number, name } = studentsById[id]
        return {
          _id,
          admission_number,
          roll_number,
          name
        }
      })
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 1
      })
  }
)

export const getStudentDataPerGroupById = createSelector(
  getById,
  groupsById,
  groupIdForMarks,
  (studentsById, groupsById, groupId) => {
    if (
      Object.keys(studentsById).length === 0 ||
      Object.keys(groupsById).length === 0 ||
      groupId === ''
    )
      return {}
    const studentIds = groupsById[groupId].students
    if (studentIds.length === 0) return {}
    if (
      underscore.intersection(studentIds, Object.keys(studentsById)).length !==
      studentIds.length
    )
      return {}

    const obj = {}
    studentIds.forEach(id => {
      obj[id] = studentsById[id]
    })
    return obj
  }
)

export const getAllStudentIdsPerGroup = createSelector(
  getById,
  groupsById,
  groupIdForMarks,
  (studentsById, groupsById, groupId) => {
    if (
      Object.keys(studentsById).length === 0 ||
      Object.keys(groupsById).length === 0 ||
      groupId === ''
    )
      return []
    const studentIds = groupsById[groupId].students
    if (studentIds.length === 0) return []
    if (
      underscore.intersection(studentIds, Object.keys(studentsById)).length !==
      studentIds.length
    )
      return []
    return groupsById[groupId].students
  }
)

export const getActiveStudentData = createSelector(
  getById,
  getActiveStudent,
  (studentById, id) => {
    return Object.assign(
      {},
      {
        _id: '',
        joining: {
          date: ''
        },
        current_class: {
          name: '',
          section: ''
        },
        phone: [
          {
            number: 0,
            belongs: 'father',
            sms: false
          },
          {
            number: 0,
            belongs: 'mother',
            sms: false
          }
        ],
        parents: [],
        addresses: []
      },
      studentById[id]
    )
  }
)
