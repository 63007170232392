// import packages
import { createSelector } from 'reselect'
import moment from 'moment'
import underscore from 'underscore'
// import selectors
import StudentStore from './flattenedStudents'

// import action types
import {
  ST_DAILY_ATTENDANCE_START,
  ST_DAILY_ATTENDANCE_DATA,
  ST_DAILY_ATTENDANCE_FAIL,
  START_ATTENDANCE_POST,
  POST_ATTENDANCE_SUCCESS,
  POST_ATTENDANCE_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  postInProgress: false,
  attendancePostStatus: ''
}

export const st_attendance = (state = initialState, action) => {
  switch (action.type) {
    case ST_DAILY_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true,
        postInProgress: false,
        attendancePostStatus: ''
      })
    case ST_DAILY_ATTENDANCE_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.entities,
        allIds: action.result
      })
    case ST_DAILY_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case START_ATTENDANCE_POST:
      return Object.assign({}, state, {
        postInProgress: true,
        attendancePostStatus: ''
      })
    case POST_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        postInProgress: false,
        attendancePostStatus: 'success'
      })
    case POST_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        postInProgress: false,
        attendancePostStatus: 'fail'
      })
    default:
      return state
  }
}

export const isLoading = state => state.st_attendance.isLoading
export const byId = state => state.st_attendance.byId
export const allIds = state => state.st_attendance.allIds
export const postInProgress = state => state.st_attendance.postInProgress
export const attendancePostStatus = state =>
  state.st_attendance.attendancePostStatus

const getByStudent = createSelector(
  byId,
  allIds,
  (attendanceByStudentId, allAttendance) => {
    const byStudent = {}
    allAttendance &&
      allAttendance?.length > 0 &&
      allAttendance?.forEach(attendanceId => {
        const { student, attendance, date } = attendanceByStudentId[
          attendanceId?.student
        ]
        byStudent[student] = {
          student,
          attendance,
          date,
          late: attendanceByStudentId[attendanceId?.student]?.late,
          session: attendanceByStudentId[attendanceId?.student]?.session,
          niwd: attendanceByStudentId[attendanceId?.student]?.niwd
        }
      })
    return byStudent
  }
)

export const getByStudentWithState = (state, studentIds, selectedDate) => {
  const studentsLoading = StudentStore.isLoading(state)
  const attendanceLoading = isLoading(state)
  if (studentsLoading || attendanceLoading) return {}
  const byStudent = getByStudent(state)
  const studentsById = StudentStore.getById(state)
  const byStudentClone = Object.assign({}, byStudent)
  const unmarkedStudents = underscore.difference(
    studentIds,
    Object.keys(byStudentClone)
  )
  unmarkedStudents.forEach(studentId => {
    const { joining_date: joiningDate } = Object.assign(
      { joining_date: '' },
      studentsById[studentId]
    )
    if (joiningDate && moment(joiningDate).isAfter(selectedDate)) {
      byStudentClone[studentId] = {
        student: studentId,
        date: selectedDate,
        attendance: 2
      }
    } else {
      byStudentClone[studentId] = {
        student: studentId,
        date: selectedDate,
        attendance: 1
      }
    }
  })
  console.log('byStudentClone: ', byStudentClone)
  return byStudentClone
}
