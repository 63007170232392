import {
  APP_LOGOUT,
  GET_PARENT_USERS_FAIL,
  GET_PARENT_USERS_START,
  GET_PARENT_USERS_SUCCESS,
  PATCH_PARENT_USERS_FAIL,
  PATCH_PARENT_USERS_START,
  PATCH_PARENT_USERS_SUCCESS
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  message: '',
  parentUsers: [],
  patchLoading: false,
  patchErr: false
}

export const parent = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_PARENT_USERS_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        message: ''
      })
    case GET_PARENT_USERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        parentUsers: action.data,
        message: 'fetched'
      })
    case GET_PARENT_USERS_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        message: action.errorMsg
      })
    case PATCH_PARENT_USERS_START:
      return Object.assign({}, state, {
        patchLoading: true,
        patchErr: false,
        message: ''
      })
    case PATCH_PARENT_USERS_SUCCESS:
      return Object.assign({}, state, {
        patchLoading: false,
        patchErr: false,
        message: 'patch'
      })
    case PATCH_PARENT_USERS_FAIL:
      return Object.assign({}, state, {
        patchLoading: false,
        patchErr: true,
        message: action.errMsg
      })
    default:
      return state
  }
}

export const getParentUsersLoading = state => state.parent?.isLoading
export const getParentUsersErr = state => state.parent?.isErr
export const getParentUsersMessage = state => state.parent?.message
export const getParentUsersArr = state => state.parent?.parentUsers
export const getParentUsersPatchLoading = state => state.parent?.patchLoading
export const getParentUsersPatchErr = state => state.parent?.patchErr
