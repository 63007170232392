import {
  GET_GALLERY_START,
  GALLERY_DATA,
  GET_GALLERY_FAIL,
  APP_LOGOUT,
  START_POST_IMAGES_TO_GALLERY,
  SET_IMAGES_TO_GALLERY,
  POST_IMAGE_TO_GALLERY_FAILED,
  PATCH_GALLERY_START,
  PATCH_GALLERY_SUCCESS,
  PATCH_GALLERY_FAIL,
  DELETE_GALLERY_START,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAIL
} from '../helpers/actions.js'
import { getActiveGallery } from './ui/gallery'
import { createSelector } from 'reselect'

const initialState = {
  isLoading: false,
  message: '',
  byId: {},
  allIds: [],
  isPatchLoading: false,
  isError: false
}

export const gallery = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_GALLERY_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case GALLERY_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.byId,
        allIds: action.allIds
      })
    case GET_GALLERY_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case START_POST_IMAGES_TO_GALLERY:
      return Object.assign({}, state, {
        isLoading: true,
        message: ''
      })
    case SET_IMAGES_TO_GALLERY:
      return Object.assign({}, state, {
        byId: {
          [action.id]: action.data
        },
        allIds: [action.id],
        isLoading: false,
        message: 'success'
      })
    case POST_IMAGE_TO_GALLERY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'fail'
      })
    case PATCH_GALLERY_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        isError: false
      })
    case PATCH_GALLERY_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        isError: false
      })
    case PATCH_GALLERY_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        isError: true
      })
    case DELETE_GALLERY_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        isError: false
      })
    case DELETE_GALLERY_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        isError: false
      })
    case DELETE_GALLERY_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        isError: true
      })
    default:
      return state
  }
}
export const isGalleryLoading = state => state.gallery.isLoading
export const getById = state => state.gallery.byId
export const getAllIds = state => state.gallery.allIds
export const getSuccessMsg = state => state.gallery.message
export const getPatchLoading = state => state.gallery.isPatchLoading
export const getError = state => state.gallery.isError
export const getAllGallery = createSelector(getById, byId => {
  return Object.keys(byId)
    ?.filter(album =>
      byId[album]?.visibility ? byId[album]?.visibility : true
    )
    .map(album => byId[album])
})
export const getActiveGalleryObject = createSelector(
  getById,
  getActiveGallery,
  (byId, activeGallery) => {
    return Object.assign(
      {},
      {
        name: '',
        images: [],
        text: '',
        dateOfEvent: ''
      },
      byId[activeGallery]
    )
  }
)
