import { normalize } from 'normalizr'
import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'
/*actions*/
import {
  SUBJECTS_NEW_START,
  SUBJECTS_NEW_FAIL,
  SUBJECTS_NEW_SUCCESS,
  SUBJECTS_FETCH_START,
  SUBJECTS_SUBJECT_DATA,
  SUBJECTS_FETCH_FAIL,
  ELECTIVES_SUBJECT_DATA,
  ELECTIVES_FETCH_START,
  ELECTIVES_FETCH_FAIL,
  ADD_ELECTIVE_SUCCESS,
  ADD_SUBJECT_SUCCESS,
  ADD_ELECTIVE_FAIL,
  EDIT_SUBJECT_SUCCESS,
  EDIT_SUBJECT_FAIL,
  EDIT_SUBJECT_START,
  GET_HOD_SUBJECTS_START,
  GET_HOD_SUBJECTS_SUCCESS,
  GET_HOD_SUBJECTS_FAILED
} from '../helpers/actions.js'
import { ADDELECTIVES_FORM_NAME } from '../helpers/enums'
/*schemas*/
import { subjectListSchema } from '../schemas/subjects'
/*actions*/
import { emitAuthFailed } from './login.js'
import * as Groups from './groups.js'
import * as Tests from './tests.js'
import { NEWSUBJECT_FORM_NAME } from '../helpers/enums'
import { reset } from 'redux-form'
import querystring from 'query-string'

export const emitNewStart = () => ({
  type: SUBJECTS_NEW_START
})

export const emitNewFail = () => ({
  type: SUBJECTS_NEW_FAIL
})

export const emitNewSuccess = () => ({
  type: SUBJECTS_NEW_SUCCESS
})

export const startPatchElectives = ({ jwt, subId, arrElectives }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW + '/api/subject/' + subId + '/addelective'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      electives: arrElectives
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('json after patching electives', json)
      return json.data
    })
    .catch(err => {
      console.error(err)
      /*TODO: dispatch authError*/
    })
}

const postNewSub = ({
  jwt,
  name,
  code,
  order,
  is_main,
  marksGrade,
  umbrellaName,
  umbrellaOrder,
  umbrellaCode,
  groupBy,
  totalCalculation,
  compulsoryForTotal,
  percentageFifty,
  electiveNotUnderUmbrella
}) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/subject'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      name,
      code,
      order,
      is_main,
      marksGrade,
      umbrellaName,
      umbrellaOrder,
      umbrellaCode,
      groupBy,
      totalCalculation,
      compulsoryForTotal,
      percentageFifty,
      electiveNotUnderUmbrella
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('json after posting a new subject', json)
      return json.data
    })
    .catch(err => {
      console.error(err)
      /*TODO: emit authError*/
    })
}

// create electives and add it to existing subject
export const createElectivesAndAddToSubject = ({ jwt, values }) => {
  return function(dispatch) {
    dispatch(emitNewStart())
    const electives = values.electives.map(elective =>
      postNewSub({
        jwt,
        ...elective,
        is_main: false
      })
    )

    return Promise.all(electives)
      .then(electiveArr => {
        if (electiveArr.some(electiveObj => !electiveObj || !electiveObj._id))
          throw new Error('All electives not created successfully')
        electiveArr.forEach(electiveObj => {
          dispatch(emitAddSubjectData(electiveObj))
        })
        const electiveIds = electiveArr.map(electiveObj => electiveObj._id)
        const electivePostActions = []
        electivePostActions.push(
          startPatchElectives({
            jwt,
            subId: values.subjectId,
            arrElectives: electiveIds
          })
        )
        const electiveSubjects = electiveIds.map(elective => {
          return {
            subject_id: elective
          }
        })

        electivePostActions.push(
          Groups.addSubjectsToMultipleGroups({
            jwt,
            subjects: electiveSubjects,
            arrGroups: values.arrGroups
          })
        )

        electiveIds?.forEach(sub => {
          electivePostActions.push(
            Tests.patchAddSubjectToTests({
              jwt,
              subId: sub,
              arrTests: values.testIds
            })
          )
        })

        Promise.all(electivePostActions).then(data => {
          console.log('data inside createElectivesAndAddToSubject', data)
          dispatch(addElectiveSuccess())
          dispatch(reset(ADDELECTIVES_FORM_NAME))
          successToast('Electives added successfully')
        })
      })
      .catch(err => {
        console.log('err', err)
        dispatch(addElectiveFail())
        errorToast('error adding electives')
      })
  }
}

const addElectiveSuccess = () => ({
  type: ADD_ELECTIVE_SUCCESS
})

const addElectiveFail = () => ({
  type: ADD_ELECTIVE_FAIL
})

const emitAddSubjectData = subjectObj => ({
  type: ADD_SUBJECT_SUCCESS,
  data: subjectObj
})

export const startNewSub = ({ jwt, values }) => {
  return function(dispatch) {
    dispatch(emitNewStart())
    /*create electives*/
    console.log('values: ', values)
    const electives = values.electives.map(elective =>
      postNewSub({
        jwt,
        ...elective,
        is_main: false
      })
    )

    return Promise.all(electives)
      .then(electiveArr => {
        if (electiveArr.some(electiveObj => !electiveObj || !electiveObj._id))
          throw new Error('All electives not created successfully')
        electiveArr.forEach(electiveObj => {
          dispatch(emitAddSubjectData(electiveObj))
        })
        const electiveIds = electiveArr?.map(electiveObj => electiveObj._id)
        /*create subject*/
        postNewSub({
          jwt,
          ...values.subject,
          is_main: true
        })
          .then(subObj => {
            console.log(subObj, 'subObj')
            if (!subObj || !subObj._id)
              throw new Error('Subject not created successfully')
            dispatch(emitAddSubjectData(subObj))
            const subId = subObj._id
            const addSubPostActions = []
            addSubPostActions.push(
              startPatchElectives({
                jwt,
                subId,
                arrElectives: electiveIds
              })
            )

            const allSubjectIds = electiveIds.slice()
            allSubjectIds.push(subId)
            const electiveSubjects = allSubjectIds.map(elective => {
              return {
                subject_id: elective
              }
            })
            const arrGroups = values.groups
              .map(group => {
                return Object.keys(group.section).filter(
                  sectionId => group.section[sectionId]
                )
              })
              .reduce((flat, toFlatten) => flat.concat(toFlatten), [])
            addSubPostActions.push(
              Groups.addSubjectsToMultipleGroups({
                jwt,
                subjects: electiveSubjects,
                arrGroups
              })
            )

            // add subject to tests
            if (
              Object.keys(values.test) &&
              Object.keys(values.test)?.length > 0
            ) {
              const arrTests =
                Object.keys(values.test) &&
                Object.keys(values.test)?.length > 0 &&
                Object.keys(values.test).filter(testId => values.test[testId])
              if (arrTests && arrTests?.length > 0) {
                allSubjectIds?.forEach(sub => {
                  addSubPostActions.push(
                    Tests.patchAddSubjectToTests({
                      jwt,
                      subId: sub,
                      arrTests
                    })
                  )
                })
              }
              Promise.all(addSubPostActions).then(data => {
                dispatch(emitNewSuccess())
                successToast('Subject added successfully')
              })
            } else {
              dispatch(emitNewSuccess())
              successToast('Subject added successfully')
            }
          })
          .catch(err => {
            errorToast('Subject post unsuccessful')
            if (err instanceof AuthError) dispatch(emitAuthFailed())
            else dispatch(emitNewFail())
          })
      })
      .catch(err => {
        errorToast('Subject not created')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitNewFail())
      })
  }
}
export const startAssignSubjectToTests = ({ jwt, subId, arrTests }) => {
  return function(dispatch) {
    dispatch(emitNewStart())
    /*create electives*/
    Tests.patchAddSubjectToTests({
      jwt,
      subId,
      arrTests
    })
      .then(data => {
        dispatch(emitNewSuccess())
        successToast('Subject added to test successfully')
      })
      .catch(err => {
        errorToast('Failed to Assign Subjects')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitNewFail())
      })
  }
}

export const emitStart = () => ({
  type: SUBJECTS_FETCH_START
})

export const emitSubjectData = data => {
  if (data && data.length !== 0) {
    const {
      entities: { subjects },
      result
    } = normalize(data, subjectListSchema)
    return {
      type: SUBJECTS_SUBJECT_DATA,
      entities: subjects,
      result
    }
  }
  return {
    type: SUBJECTS_SUBJECT_DATA,
    entities: {},
    result: []
  }
}

export const emitFail = errMsg => ({
  type: SUBJECTS_FETCH_FAIL,
  errMsg
})

const fetchSubjects = ({ jwt, subjects }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/subject/ids?' +
    querystring.stringify({ ids: subjects })

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchSubjects = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchSubjects(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('json inside startFetchSubjects', json)
        dispatch(emitSubjectData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err.message))
      })
  }
}

export const emitElectivesStart = () => ({
  type: ELECTIVES_FETCH_START
})

export const emitElectivesData = data => {
  const {
    entities: { subjects },
    result
  } = normalize(data, subjectListSchema)
  return {
    type: ELECTIVES_SUBJECT_DATA,
    entities: subjects,
    result
  }
}

export const emitElectivesFail = errMsg => ({
  type: ELECTIVES_FETCH_FAIL,
  errMsg
})

export const startFetchElectives = data => {
  return function(dispatch) {
    dispatch(emitElectivesStart())
    return fetchSubjects(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startFetchElectives: ', json)
        dispatch(emitElectivesData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitElectivesFail(err.message))
      })
  }
}

// patch subjects
const patchSubject = (jwt, subjectId, data) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/subject/` +
    encodeURIComponent(subjectId)

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startSubjectPatch = ({ jwt, subjectId, data }) => dispatch => {
  dispatch(emitPatchStart())
  return patchSubject(jwt, subjectId, data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startSubjectPatch', json)
      dispatch(emitPatchSubjectData(json.data))
      successToast('Subject updated successully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitFailure(err))
      errorToast('Subject update unsuccessful')
    })
}

const emitPatchStart = () => ({
  type: EDIT_SUBJECT_START
})

const emitPatchSubjectData = data => ({
  type: EDIT_SUBJECT_SUCCESS,
  data
})

const emitFailure = err => ({
  type: EDIT_SUBJECT_FAIL,
  message: err
})

const patchSubjectandElective = ({ jwt, subjectIds, data, electiveData }) => {
  return Promise.all(
    subjectIds &&
      subjectIds.length > 0 &&
      subjectIds.map(subjectId => {
        let Authorization = composeAuth(jwt)
        let url =
          process.env.REACT_APP_SERVER_NEW +
          `/api/subject/` +
          encodeURIComponent(subjectId.id)
        let patchData = subjectId.is_main
          ? JSON.stringify(data)
          : JSON.stringify(electiveData)
        return fetch(url, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization
          },
          body: patchData
        })
          .then(checkStatus)
          .then(parseJSON)
          .then(json => {
            return json.data
          })
          .catch(err => {
            console.error(err)
            if (err instanceof AuthError) dispatch(emitAuthFailed())
            dispatch(emitFailure(err))
            errorToast('Subject update unsuccessful')
          })
      })
  )
}

export const startSubjectandElectivePatch = payload => {
  console.log('startSubjectandElectivePatch payload: ', payload)
  return function(dispatch) {
    dispatch(emitPatchStart())
    return patchSubjectandElective(payload).then(dataArr => {
      let finalData = []
      dataArr.forEach(leaves => {
        finalData = finalData.concat(leaves)
      })
      dispatch(emitPatchSubjectData(finalData))
      successToast('Subject updated successully')
    })
  }
}

//get hod subjects
const getHodSubjectStart = () => ({
  type: GET_HOD_SUBJECTS_START
})

const getHodSubjectSuccess = data => ({
  type: GET_HOD_SUBJECTS_SUCCESS,
  data
})

const getHodSubjectFail = () => ({
  type: GET_HOD_SUBJECTS_FAILED
})

const gethodSubject = ({ jwt, levelId }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/subject/hod/${levelId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGethodSubject = data => dispatch => {
  dispatch(getHodSubjectStart())
  return gethodSubject(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(getHodSubjectSuccess(json.data))
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      else dispatch(getHodSubjectFail(err.message))
    })
}
