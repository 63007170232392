import {
  APP_LOGOUT,
  TESTS_TEST_DATA,
  FLATTENED_TESTS_TEST_DATA,
  TESTS_FETCH_START,
  TESTS_FAIL,
  ADD_TEST_START,
  ADD_TEST_SUCCESS,
  EDIT_TEST_SUCCESS,
  EDIT_TEST_FAIL,
  START_FETCH_GRADES,
  EMIT_GRADES_DATA,
  EMIT_GRADES_FAIL,
  ADD_GRADES_TO_TEST_START,
  ADD_GRADES_TO_TEST,
  ADD_GRADES_TO_TEST_FAIL,
  FETCH_SUB_TEST_START,
  SUB_TEST_DATA,
  FLATTENED_SUB_TEST_DATA,
  EDIT_SUB_TEST_START,
  EDIT_SUB_TEST_SUCCESS,
  ADD_MIN_MARKS_GRADES_FAIL,
  ADD_MIN_MARKS_GRADES_START,
  ADD_MIN_MARKS_GRADES_SUCCESS
} from '../helpers/actions'
import { merge, union } from 'lodash'
import underscore from 'underscore'
import { createSelector } from 'reselect'
import { groupIdForMarks } from './ui/marks'
import { groupsById } from './groups'

const initialState = {
  //state
  isLoading: false, //to track GET requests
  isWorking: false, //to track POST requests
  isErr: false, //to track success/failure of ANY request
  errMsg: '',
  //data
  byId: {}, //map of ID to data
  allIds: [], //array of all ID's
  gradesLoading: false,
  gradesById: {},
  allGradeIds: [],
  unmergedTestbyId: {},
  unmergedAllIds: [],
  subTestById: {},
  allSubTestIds: [],
  isSubTestLoading: false,
  isSubTestEditLoading: false,
  isSubTestErr: false,
  addedMinMarksGrades: [],
  addMinMarksGradesIsLoading: false,
  unMergedsubTestById: {},
  testsData: [],
  subTestData: [],
  gradesError: false
}

export const tests = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case TESTS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case TESTS_TEST_DATA:
      const byId = merge({}, state.byId, action.entities)
      const allIds = union(state.allIds, action.result)
      return Object.assign({}, state, {
        isLoading: false,
        byId,
        allIds,
        testsData: action.data
      })
    case FLATTENED_TESTS_TEST_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        unmergedTestbyId: action.entities,
        unmergedAllIds: action.result
      })
    case TESTS_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isSubTestLoading: false,
        isWorking: false,
        isErr: true,
        errMsg: action.err
      })
    case ADD_TEST_START:
      return Object.assign({}, state, {
        isErr: false,
        errMsg: '',
        isWorking: true
      })
    case ADD_TEST_SUCCESS:
      return Object.assign({}, state, {
        isWorking: false
      })
    case EDIT_TEST_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        isLoading: false,
        isErr: false,
        errMsg: ''
      })
    case EDIT_TEST_FAIL:
      return Object.assign({}, state, {
        isErr: true,
        errMsg: action.message,
        isLoading: false,
        isSubTestErr: true,
        isSubTestEditLoading: false
      })
    case START_FETCH_GRADES:
      return Object.assign({}, state, {
        gradesLoading: true,
        gradesError: false
      })
    case EMIT_GRADES_DATA:
      return Object.assign({}, state, {
        gradesById: action.entities,
        allGradeIds: action.result,
        gradesLoading: false,
        gradesError: false
      })
    case EMIT_GRADES_FAIL:
      return Object.assign({}, state, {
        gradesLoading: false,
        gradesError: true
      })
    case ADD_GRADES_TO_TEST_START:
      return Object.assign({}, state, {
        addGradesToTestLoading: true,
        isErr: false
      })
    case ADD_MIN_MARKS_GRADES_SUCCESS:
      return Object.assign({}, state, {
        addedMinMarksGrades: action.data,
        addMinMarksGradesIsLoading: false,
        isErr: false
      })
    case ADD_MIN_MARKS_GRADES_FAIL:
      return Object.assign({}, state, {
        addMinMarksGradesIsLoading: false,
        isErr: true
      })
    case ADD_MIN_MARKS_GRADES_START:
      return Object.assign({}, state, {
        addMinMarksGradesIsLoading: true,
        isErr: false,
        errMsg: ''
      })
    case ADD_GRADES_TO_TEST:
      return Object.assign({}, state, {
        addedGrades: action.data,
        addGradesToTestLoading: false,
        isErr: false
      })
    case ADD_GRADES_TO_TEST_FAIL:
      return Object.assign({}, state, {
        addGradesToTestLoading: false,
        isErr: true
      })
    case FETCH_SUB_TEST_START:
      return Object.assign({}, state, {
        isSubTestLoading: true,
        isErr: false,
        errMsg: ''
      })
    case SUB_TEST_DATA:
      const subTestById = merge({}, state.subTestById, action.entities)
      const unMergedsubTestById = action.entities
      const allSubTestIds = union(state.allSubTestIds, action.result)
      return Object.assign({}, state, {
        isSubTestLoading: false,
        subTestById,
        allSubTestIds,
        unMergedsubTestById,
        subTestData: action.subTestData
      })
    case FLATTENED_SUB_TEST_DATA:
      return Object.assign({}, state, {
        isSubTestLoading: false,
        unmergedTestbyId: action.entities,
        unmergedAllIds: action.result
      })
    case EDIT_SUB_TEST_START:
      return Object.assign({}, state, {
        isSubTestEditLoading: true,
        isSubTestErr: false,
        errMsg: ''
      })
    case EDIT_SUB_TEST_SUCCESS:
      return Object.assign({}, state, {
        subTestById: {
          ...state.subTestById,
          [action.data._id]: action.data
        },
        isSubTestEditLoading: false,
        isSubTestErr: false,
        errMsg: ''
      })
    default:
      return state
  }
}

/*selectors*/

// STATE SELECTORS

export const isLoading = state => state.tests.isLoading
export const isWorking = state => state.tests.isWorking
export const isError = state => state.tests.isErr
export const gradesLoading = state => state.tests.gradesLoading
export const getAddGradesToTestLoading = state =>
  state.tests.addGradesToTestLoading
export const getAddedGrades = state => state.tests.addedGrades
export const getIsSubTestLoading = state => state.tests.isSubTestLoading
export const getSubTestEditLoading = state => state.tests.isSubTestEditLoading
export const getIsSubTestErr = state => state.tests.isSubTestErr

export const getAddedMinMarksGrades = state => state.tests.addedMinMarksGrades
export const getAddMinMarksGradesIsLoading = state =>
  state.tests.addMinMarksGradesIsLoading
export const getUnMergedsubTestById = state => state.tests.unMergedsubTestById
export const getTestsData = state => state.tests.testsData
export const getSubTestsData = state => state.tests.subTestData
export const gradesError = state => state.tests.gradesError

// DATA SELECTORS

export const testsById = state => {
  const byId = state.tests.byId
  const testsAllIds = state.tests.allIds

  testsAllIds.sort((a, b) => byId[a]['order'] - byId[b]['order'])
  let testByIdSort = {}

  testsAllIds.forEach(test => {
    testByIdSort[test] = byId[test]
  })
  return testByIdSort
}

export const subTestsById = state => {
  const byId = state.tests.subTestById
  const testsAllIds = state.tests.allSubTestIds

  testsAllIds &&
    testsAllIds.length > 0 &&
    testsAllIds.sort((a, b) => byId[a]['order'] - byId[b]['order'])
  let testByIdSort = {}

  testsAllIds &&
    testsAllIds.length > 0 &&
    testsAllIds.forEach(test => {
      testByIdSort[test] = byId[test]
    })
  return testByIdSort
}

export const unMergedTestsById = state => {
  const byId = state.tests.unmergedTestbyId
  const testsAllIds = state.tests.unmergedAllIds

  testsAllIds.sort((a, b) => byId[a]['order'] - byId[b]['order'])
  let testByIdSort = {}

  testsAllIds.forEach(test => {
    testByIdSort[test] = byId[test]
  })
  return testByIdSort
}
export const gradesById = state => state.tests.gradesById
export const allGradeIds = state => state.tests.allGradeIds

export const testPercentages = state => {
  const allIds = state.tests.allIds
  const testsById = state.tests.byId
  const percentages = {}

  allIds.map(test => {
    if (testsById[test]) {
      percentages[test] = testsById[test].percentage
    }
    return test
  })
  return percentages
}

const getAllIds = state => {
  const testById = state.tests.byId
  const testsAllIds = state.tests.allIds

  testsAllIds.sort((a, b) => testById[a]['order'] - testById[b]['order'])

  return testsAllIds
}

export const getTests = createSelector(testsById, getAllIds, (byId, tests) => {
  if (!tests || tests.length === 0) return []
  let test = tests
    .filter(test => {
      if (byId[test]) {
        return !byId[test].archive
      } else {
        return false
      }
    })
    .map(group => byId[group])
    .sort((a, b) => a.order - b.order)
  return test
})

export const testDataForClassProfile = createSelector(
  testsById,
  getAllIds,
  state => state.ui.classProfile.groupId,
  state => state.groups.byId,
  (testsById, allTestIds, groupId, groupsById) => {
    if (groupId === '' || Object.keys(groupsById).length === 0) return []
    if (allTestIds.length === 0) return []
    return groupsById[groupId].tests
      .filter(testId => !testsById[testId].archive)
      .map(testId => testsById[testId])
  }
)

export const getTestsPerGroupById = createSelector(
  testsById,
  groupsById,
  groupIdForMarks,
  (testsById, groupsById, groupId) => {
    if (
      Object.keys(testsById).length === 0 ||
      Object.keys(groupsById).length === 0 ||
      groupId === ''
    )
      return {}
    const testIds = groupsById[groupId].tests
    if (testIds.length === 0) return {}
    if (
      underscore.intersection(testIds, Object.keys(testsById)).length !==
      testIds.length
    )
      return {}

    const obj = {}
    testIds.forEach(id => {
      obj[id] = testsById[id]
    })
    return obj
  }
)

// grades selectors
export const getGradesArr = createSelector(
  gradesById,
  allGradeIds,
  (gradesById, allGradeIds) => {
    return allGradeIds.map(gradeId => gradesById[gradeId])
  }
)

export const getGradeNames = createSelector(
  gradesById,
  allGradeIds,
  (gradesById, allGradeIds) => {
    return allGradeIds.map(gradeId => {
      const { name } = gradesById[gradeId]
      return name
    })
  }
)

// get test name for a testId
export const getTestNameByTestId = (state, testId) => {
  const testsById = state.tests.byId
  if (!testId || Object.keys(testsById).length === 0) return ''
  if (!testsById[testId]) return ''
  return testsById[testId].name
}
