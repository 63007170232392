import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

class ReduxSelect extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string
  }

  render() {
    const { options, name, placeholder = 'Select Option' } = this.props
    return (
      <Field
        name={name}
        component="select"
        required
        onChange={this.props.onChange ? this.props.onChange : null}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Field>
    )
  }
}

export default ReduxSelect
