import React from 'react'
import FontAwesome from 'react-fontawesome'
import { version } from '../../../package.alias.json'

const PrivateFooter = () => {
  return (
    <div className="privateFooter">
      <div className="footer-left">
        <span className="footer-left__info-item">v{version}</span>
        <span className="footer-left__divider">|</span>
        <span className="footer-left__info-item">
          Copyright © 2014. All Rights Reserved
        </span>
        <span className="footer-left__divider">|</span>
        <span className="footer-left__info-item">
          Contact us at : info@sealtabs.com
        </span>
        <span className="footer-left__divider">|</span>
        <span className="footer-left__info-item">
          Call us at : +91-80 88 100 900
        </span>
      </div>

      <div className="footer-right">
        A product by
        <div className="img-box">
          <img
            className="img-box__nissi-logo"
            src="/nissi_logo.png"
            alt="Nissi agency logo"
          />
        </div>
        {/* <span className="divider">|</span>
        <div className="icons">
          <FontAwesome name="twitter" className="st-social" size="lg" />
          <FontAwesome name="facebook" className="st-social" size="lg" />
          <FontAwesome name="youtube-play" className="st-social" size="lg" />
        </div> */}
      </div>
    </div>
  )
}

export default PrivateFooter
