// import packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import ReactTooltip from 'react-tooltip'
// import actions
import {
  startPostReqMarks,
  startFetchMarksForTestSubj,
  updateMarksStore,
  startPostReqGrade
} from '../../actions/marks'
import Tests from '../../actions/tests'
// selectors
import {
  getAcademicGroups,
  getAcademicTeacherGroups,
  groupsById
} from '../../reducers/groups'
import { getMarksArr, getIsLoading as marksLoading } from '../../reducers/marks'
import { byId as subjectsById } from '../../reducers/subjects'
import { getById as studentsById } from '../../reducers/students'
import { getLevelId, getLevel } from '../../reducers/accounts'
import {
  testsById,
  isLoading as testsLoading,
  getGradeNames
} from '../../reducers/tests'
// import components
import { MarksForm } from './marksForm'
import GroupInfo from './groupInfo'
import { getValue } from './validate'
import Spinner from '../spinner'
// import helpers
import {
  zeroReasonString,
  getZeroReason,
  getSubjectIdOfAStudent
} from '../../helpers/utils.js'
// import enums
import { ZERO_REASON } from '../../helpers/enums'

export class MarksEntryComponent extends Component {
  static propTypes = {
    selectedTests: PropTypes.array.isRequired,
    selectedSubj: PropTypes.array.isRequired,
    studentsOfTests: PropTypes.array.isRequired,
    showMaxMarksModalError: PropTypes.func.isRequired,
    tests: PropTypes.object.isRequired,
    groupSelected: PropTypes.string.isRequired,
    mainTest: PropTypes.string.isRequired,
    resetMarks: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      order: { roll_number: false },
      header: 'roll_number'
    }
    this.filterStudents = this.filterStudents.bind(this)
    this.dispatchPostRequest = this.dispatchPostRequest.bind(this)
    this.renderTestsName = this.renderTestsName.bind(this)
    this.getInitialValues = this.getInitialValues.bind(this)
    this.getClassAndGroup = this.getClassAndGroup.bind(this)
    this.getSubjectName = this.getSubjectName.bind(this)
    this.handleGroupBy = this.handleGroupBy.bind(this)
    this.getSortIcon = this.getSortIcon.bind(this)
    this.getToolTip = this.getToolTip.bind(this)
    this.getToolTipInfo = this.getToolTipInfo.bind(this)
  }

  componentDidMount() {
    const {
      jwt,
      selectedTestsGrade,
      studentsOfTests,
      testsById,
      fetchExistingMarks,
      fetchGrades
    } = this.props

    console.log(this.props, 'this.props')

    console.log(selectedTestsGrade, 'selectedTestsGrade')

    const testId = selectedTestsGrade
    const subjectArr = this.getSubjectArr()
    const studentArr = studentsOfTests
    const data = { jwt, testId, subjectArr, studentArr }
    let gradeIds = []
    testId.forEach(id => {
      const { grades } = Object.assign({ grades: [] }, testsById[id])
      gradeIds = gradeIds.concat(grades)
    })
    fetchExistingMarks(data)
    if (gradeIds.length !== 0) {
      fetchGrades({
        jwt,
        gradeIds
      })
    }
  }

  getSubjectArr = () => {
    const { selectedSubj, mainSubjectId, subjectsById } = this.props
    if (selectedSubj[0] === 'all') {
      const { electives } = Object.assign(
        { electives: [] },
        subjectsById[mainSubjectId]
      )
      return electives
    }
    return selectedSubj
  }
  // get students registered from the subject and filter them accordingly
  filterStudents(studentsbyId, studentsOfTests) {
    let students = []

    if (Object.keys(studentsbyId).length !== 0)
      students = studentsOfTests.map(id => {
        const {
          name,
          admission_number,
          registration_number,
          roll_number
        } = studentsbyId[id]
          ? studentsbyId[id]
          : {
              name: 'ARCHIVED',
              admission_number: 'ARCHIVED',
              roll_number: 'ARCHIVED'
            }
        return {
          name: name,
          admission_number: admission_number,
          registration_number,
          roll_number: roll_number,
          id
        }
      })
    const { order, header } = this.state

    if (header) {
      if (header === 'roll_number' || header === 'registration_number') {
        students = students.sort((a, b) => {
          if (order[header]) return Number(b[header]) - Number(a[header])
          return Number(a[header]) - Number(b[header])
        })
      } else {
        students = students.sort((a, b) => {
          if (order[header]) {
            if (a[header] < b[header]) return 1
            return -1
          } else {
            if (a[header] < b[header]) return -1
            return 1
          }
        })
      }
    }
    return students
  }

  handleGroupBy(e) {
    const { order } = this.state
    this.setState({
      ...this.state,
      order: {
        ...order,
        [e.target.id]: !order[e.target.id]
      },
      header: e.target.id
    })
  }

  getSortIcon(header) {
    const { order } = this.state
    if (order[header]) return 'sort-amount-desc'
    return 'sort-amount-asc'
  }

  getToolTip(header) {
    const { order } = this.state
    if (order[header]) return 'sort ascending'
    return 'sort descending'
  }

  // dispatch post request for the test subj student
  dispatchPostRequest(testId, selectedStudent, maxMarks, mark) {
    const {
      level,
      selectedSubj,
      groupSelected,
      type,
      postEnteredMarks,
      postEnteredGrade
    } = this.props

    console.log(
      testId,
      selectedStudent,
      maxMarks,
      mark,
      'testId, selectedStudent, maxMarks, mark'
    )

    const [subject] = selectedSubj
    let subjectId = subject
    if (subject === 'all') {
      const { mainSubjectId, subjectsById, groupsById, groupId } = this.props
      subjectId = getSubjectIdOfAStudent({
        studentId: selectedStudent,
        subjectId: subject,
        subjectsById,
        groupsById,
        mainSubjectId,
        groupId
      })
    }
    const groupId = groupSelected
    const formData = {
      groupId,
      testId,
      subjectId,
      selectedStudent,
      maxMarks,
      mark
    }

    // set non zero Reason as an object to map shor-cut to string
    const payload = this.getPayload(formData)
    const data = { jwt: this.props.jwt, payload, level }

    if (mark !== '' && type === 'marks') postEnteredMarks(data)
    if (mark !== '' && type === 'grade') postEnteredGrade(data)
  }

  getPayload(formData) {
    const { type } = this.props
    if (type === 'marks') return this.getPayloadForMarks(formData)
    return this.getPayloadForGrades(formData)
  }

  getPayloadForMarks(formData) {
    const {
      mark,
      groupId,
      testId,
      subjectId,
      maxMarks,
      selectedStudent
    } = formData
    let zeroReason = 'notZero'
    let enteredMark = mark
    if (mark === 0) {
      zeroReason = 'zero'
    } else if (isNaN(mark)) {
      zeroReason = zeroReasonString(mark)
      enteredMark = 0
    } // makeit func

    const payload = {
      subjectId,
      testId,
      maxMarks,
      groupId,
      marks: [
        {
          studentId: selectedStudent,
          mark: enteredMark,
          zeroReason
        }
      ]
    }
    return payload
  }

  getPayloadForGrades(formData) {
    const {
      mark: grade,
      groupId,
      testId,
      subjectId,
      selectedStudent
    } = formData
    let enteredGrade = getValue(grade)
    const zeroReason = this.getZeroReason(enteredGrade)
    const payload = {
      subjectId,
      testId,
      groupId,
      marks: [
        {
          studentId: selectedStudent,
          grade: enteredGrade,
          zeroReason
        }
      ]
    }
    return payload
  }

  getZeroReason(grade) {
    const { validGrades } = this.props
    if (validGrades.includes(grade)) return 'notZero'
    let zeroReason = ZERO_REASON[grade]
    if (!zeroReason) return 'ERR'
    return zeroReason
  }

  // return test name and status
  renderTestsName(selectedTests, tests) {
    let arrayOfHeadData = []
    selectedTests.map((test, index) => {
      const thTestName = (
        <th
          key={2 * index}
          className="table-head-row__cell table-head-row__cell--marker-cell"
        >
          {tests[test] && tests[test].name}{' '}
        </th>
      )
      const thStatus = (
        <th
          key={2 * index + 1}
          className="table-head-row__cell table-head-row__cell--status-cell"
        >
          {' '}
          Status
        </th>
      )
      arrayOfHeadData = arrayOfHeadData.concat([thTestName, thStatus])
      return test
    })
    return arrayOfHeadData
  }
  //

  getInitialValues(marks, tests) {
    const { type } = this.props
    if (marks.length === 0 || tests.length === 0) return {}
    if (!tests.includes(marks[0].testId)) return {}
    switch (type) {
      case 'marks':
        return this.getInitialValuesForMarks(marks, tests)
      case 'grade':
        return this.getInitialValuesForGrades(marks, tests)
      default:
        return {}
    }
  }

  getInitialValuesForMarks(marks, tests) {
    const initialMarksObj = {}
    //initialize empty test objects
    tests.forEach(id => {
      initialMarksObj[id] = {}
    })
    // check if max marks for all students is same
    marks.forEach(markObj => {
      const { testId, studentId, maxMarks, zeroReason, mark } = markObj
      if (Object.keys(initialMarksObj).includes(testId)) {
        initialMarksObj[testId]['MaxMarks'] = maxMarks
        initialMarksObj[testId][studentId] =
          mark === 0 ? getZeroReason(zeroReason) : mark
      }
    })
    return initialMarksObj
  }

  getInitialValuesForGrades(marks, tests) {
    const initialMarksObj = {}
    //initialize empty test objects
    tests.forEach(id => {
      initialMarksObj[id] = {}
    })
    // check if max marks for all students is same
    marks.forEach(markObj => {
      const { testId, studentId, zeroReason, grade } = markObj
      if (Object.keys(initialMarksObj).includes(testId)) {
        initialMarksObj[testId][studentId] =
          grade === 0 ? getZeroReason(zeroReason) : grade
      }
    })
    return initialMarksObj
  }

  getClassAndGroup(groupId, groups) {
    const [group] = groups.filter(group => group._id === groupId)
    return {
      group: group.group_name,
      parent_group: group.parent_group
    }
  }

  getSubjectName(subjectId, subjects) {
    const [subj] = subjects.filter(subject => subject.id === subjectId)
    return subj && subj.name !== undefined ? subj.name : ''
  }

  getToolTipInfo(header) {
    return (
      <ReactTooltip
        className="st-tooltip"
        place="bottom"
        type="light"
        id={header}
      >
        <div className="tooltip-text">{this.getToolTip(header)}</div>
      </ReactTooltip>
    )
  }

  isReady() {
    const { marksLoading, testsLoading } = this.props
    if (marksLoading || testsLoading) return false
    return true
  }

  getValueForDropdown() {
    const { showMainTest } = this.props
    if (showMainTest) return 'yes'
    return 'no'
  }

  render() {
    const {
      subjects,
      groups,
      groupSelected,
      selectedSubj,
      mainTest,
      tests,
      selectedTests,
      clickedGo,
      type,
      handleShowMainTestChange,
      mainSubjectId
    } = this.props
    const { group, parent_group } = this.getClassAndGroup(groupSelected, groups)
    const readyState = this.isReady()
    return (
      <form>
        <div className="attd-search">
          <div className="container">
            <div className="row">
              <div className="col-xs-8">
                {/* TODO- Make this component name generic */}
                <GroupInfo
                  subject={this.getSubjectName(
                    this.props.selectedSubj[0],
                    subjects
                  )}
                  test={tests[mainTest].name}
                  clickedGo={clickedGo}
                  groupName={group}
                  umbrellaGroup={parent_group}
                />
              </div>
              <div className="col-xs-2">
                <div className="date-and-class">
                  <p>Show main test</p>
                  <select
                    defaultValue={this.getValueForDropdown()}
                    onClick={handleShowMainTestChange}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-2">
                <div className="date-and-class">
                  <p>
                    Subject Type: <span>{type}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <table className="table">
            <thead className="table-head table-head--dark">
              <tr className="table-head-row">
                {/* <th className="table-head-row__cell table-head-row__cell--empty-left" /> */}
                <th className="table-head-row__cell">
                  Roll No.
                  <FontAwesome
                    id="roll_number"
                    onClick={this.handleGroupBy}
                    name={this.getSortIcon('roll_number')}
                    data-for="roll_number"
                    data-tip
                  />
                  {this.getToolTipInfo('roll_number')}
                </th>
                <th className="table-head-row__cell">
                  Admission No.
                  <FontAwesome
                    id="admission_number"
                    onClick={this.handleGroupBy}
                    name={this.getSortIcon('admission_number')}
                    data-for="admission_number"
                    data-tip
                  />
                  {this.getToolTipInfo('admission_number')}
                </th>
                <th className="table-head-row__cell">
                  Registration No.
                  <FontAwesome
                    id="registration_number"
                    onClick={this.handleGroupBy}
                    name={this.getSortIcon('registration_number')}
                    data-for="registration_number"
                    data-tip
                  />
                  {this.getToolTipInfo('registration_number')}
                </th>
                <th className="table-head-row__cell">
                  Name
                  <FontAwesome
                    id="name"
                    onClick={this.handleGroupBy}
                    name={this.getSortIcon('name')}
                    data-for="name"
                    data-tip
                  />
                  {this.getToolTipInfo('name')}
                </th>
                {readyState && this.renderTestsName(selectedTests, tests)}
                {/* <th className="table-head-row__cell table-head-row__cell--empty-right" /> */}
              </tr>
            </thead>

            {readyState ? (
              <MarksForm
                ready={readyState}
                showMaxMarksModalError={this.props.showMaxMarksModalError}
                studentDetails={this.filterStudents(
                  this.props.studentsbyId,
                  this.props.studentsOfTests
                )}
                selectedTests={this.props.selectedTests}
                groupId={groupSelected}
                marksform={this.props.marksform}
                subjectId={selectedSubj[0]}
                mainSubjectId={mainSubjectId}
                dispatchPostRequest={this.dispatchPostRequest}
                marksStatus={this.props.marksStatus}
                resetMarks={this.props.resetMarks}
                initialValues={this.getInitialValues(
                  this.props.marksArr,
                  this.props.selectedTests
                )}
                type={type}
              />
            ) : (
              <Spinner />
            )}
          </table>
        </div>
      </form>
    )
  }
}

const mapStatetoProps = (state, ownProps) => {
  const getGroupsArr = () => {
    if (state.accounts.level === 'teacher') {
      return getAcademicTeacherGroups(state)
    }
    return getAcademicGroups(state)
  }
  return {
    jwt: state.accounts.jwt,
    groups: getGroupsArr(),
    studentsbyId: studentsById(state),
    marksById: state.marks.byId,
    marksallIds: state.marks.allIds,
    marksform: state.form.MarksForm,
    marksStatus: state.marks.marksStatus,
    marksArr: getMarksArr(state),
    levelId: getLevelId(state),
    level: getLevel(state),
    testsById: testsById(state),
    testsLoading: testsLoading(state),
    marksLoading: marksLoading(state),
    validGrades: getGradeNames(state),
    subjectsById: subjectsById(state),
    groupsById: groupsById(state)
  }
}

const mapDispatchToProps = dispatch => ({
  postEnteredMarks(data) {
    dispatch(startPostReqMarks(data))
  },
  postEnteredGrade(data) {
    dispatch(startPostReqGrade(data))
  },
  fetchExistingMarks(data) {
    dispatch(startFetchMarksForTestSubj(data))
  },
  updateExistingMarksForForm(data) {
    dispatch(updateMarksStore(data))
  },
  fetchGrades(data) {
    dispatch(Tests.startFetchGrades(data))
  }
})

export const MarksEntry = connect(
  mapStatetoProps,
  mapDispatchToProps
)(MarksEntryComponent)
