/*
https://simonsmith.io/reusing-layouts-in-react-router-4/
*/

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Route, Link, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Logo from '../../../assets/images/logo.svg'

//components
import Footer from './footer'
import Header from './header'
import PrivateFooter from './privateFooter'
import LoginButtons from './loginButtons'
import ScrollToTop from './scrollTop'
import { usePrevious } from '../../../helpers/utils'

/*selectors*/
import { isLoggedIn, isSupportLoggedIn } from '../../../reducers/accounts'

export const AppLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className="st-form-layout">
          <Header />
          <div className="container">
            <div className="row">
              <div className="col-lg-offset-2 col-lg-8">
                <Component {...matchProps} />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export const CoverLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className="st-login-layout">
          <div className="bg-absolute bg-absolute--light-inverse" />
          <div className="bg-absolute bg-absolute--light zIndex--1" />
          <Component {...matchProps} />
          <footer className="zIndex--1">
            <Footer />
          </footer>
        </div>
      )}
    />
  )
}

export const ErrorLayoutComponent = ({ location, history, ...rest }) => {
  return (
    <div>
      <Route
        {...rest}
        render={props => (
          <div>
            <Header {...props} />
          </div>
        )}
      />
    </div>
  )
}

export const PrivateLayout = ({ component: Component, ...rest }) => {
  const history = useHistory()
  const loggedIn = useSelector(isLoggedIn)

  if (loggedIn) {
    return (
      <div>
        <ScrollToTop />
        <Route
          {...rest}
          render={props => (
            <div className="page__container">
              <Header {...props} />
              <div>
                <Component {...props} />
              </div>
              <div className="clear"></div>
              <PrivateFooter />
            </div>
          )}
        />
      </div>
    )
  } else history.push('/login')
}

export const BlankLayout = ({ component: Component, ...rest }) => {
  const history = useHistory()
  const loggedIn = useSelector(isLoggedIn)
  const supportLoggedIn = useSelector(isSupportLoggedIn)
  if (loggedIn || supportLoggedIn) {
    return (
      <div>
        <Route
          {...rest}
          render={props => (
            <div className="page__container">
              <div className="header">
                <div className="header-left">
                  <div className="header-left-top">
                    <Link to="/">
                      <img
                        className="header-left-top__img img img-responsive"
                        src={Logo}
                        alt="sealtabs logo"
                      />
                    </Link>
                  </div>
                  <div className="header-left-bottom ">
                    {moment().format('DD MMM, YYYY')} |{' '}
                    {moment().format('hh:mm A')}
                  </div>
                </div>
                <div className="header-right">
                  <div className="header-right-top">
                    <ul className="header-utilities">
                      {/* <li className="header-utilities__item silverleaf">
                        Sealtabs Learning
                      </li> */}
                      <li className="header-utilities__item user-info">
                        <span className="user-info__img" />
                        <span className="user-info__name">
                          <LoginButtons />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <Component {...props} />
              </div>
              <div className="clear"></div>
              <PrivateFooter />
            </div>
          )}
        />
      </div>
    )
  } else history.push('/login')
}
