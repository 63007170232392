import {
  APP_LOGOUT,
  START_POST_CMS,
  POST_CMS_SUCCESS,
  POST_CMS_FAILURE,
  PATCH_CMS_SUCCESS,
  START_FETCH_CMS,
  START_FETCH_CMS_SUCCESS,
  START_FETCH_CMS_FAILURE,
  START_UPLOAD_CMS_DOCUMENT,
  UPLOAD_CMS_DOCUMENT_SUCCESS,
  UPLOAD_CMS_DOCUMENT_FAILURE,
  REMOVE_CMS_DOCUMENT_LINK
} from '../helpers/actions.js'
import { createSelector } from 'reselect'

const initialState = {
  cmsById: {},
  cmsAllIds: [],
  isLoading: false,
  message: '',
  cmsDocumentLink: [],
  isLoadingcms: false,
  postInProgress: false
}

export const cmsById = state => state.cms.cmsById
export const cmsAllIds = state => state.cms.cmsAllIds
export const getIsLoading = state => state.cms.isLoading
export const getPostMessage = state => state.cms.message
export const getDocumentLInk = state => state.cms.cmsDocumentLink
export const getIscmsLoading = state => state.cms.isLoadingcms
export const getPostProgress = state => state.cms.postInProgress

export const getcmsArray = createSelector(
  cmsById,
  cmsAllIds,
  (byId, allIds) => {
    if (!allIds || !byId || allIds.length === 0) return []
    return allIds
      .map(id => byId[id])
      .sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate)
      })
  }
)

export const cms = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState

    case START_POST_CMS:
      return Object.assign(
        {},
        {
          isLoading: true,

          message: ''
        }
      )
    case POST_CMS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'success',
        cmsDocumentLink: []
      })
    case POST_CMS_FAILURE:
      return Object.assign({}, state, {
        message: 'failed',
        isLoading: false,
        cmsDocumentLink: []
      })
    case PATCH_CMS_SUCCESS:
      return Object.assign({}, state, {
        postInProgress: false,
        isLoading: false,
        cmsById: {
          ...state.cmsById,
          [action.data._id]: action.data
        }
      })
    case START_UPLOAD_CMS_DOCUMENT:
      return Object.assign({}, state, {
        isLoadingcms: true,
        cmsDocumentLink: []
      })
    case UPLOAD_CMS_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoadingcms: false,
        cmsDocumentLink: [...action.data]
      })
    case UPLOAD_CMS_DOCUMENT_FAILURE:
      return Object.assign({}, state, {
        isLoadingcms: false,
        cmsDocumentLink: []
      })
    case REMOVE_CMS_DOCUMENT_LINK:
      return Object.assign({}, state, {
        cmsDocumentLink: [...action.data]
      })
    case START_FETCH_CMS:
      return Object.assign(state, {
        isLoading: true,

        message: ''
      })
    case START_FETCH_CMS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'success',
        cmsById: action.byId,
        cmsAllIds: action.allIds
      })
    case START_FETCH_CMS_FAILURE:
      return Object.assign({}, state, {
        message: 'failed',
        isLoading: false,
        cmsById: {},
        cmsAllIds: []
      })
    default:
      return state
  }
}
