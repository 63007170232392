import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const SnackBar = ({ text, hideSnackbar, showSnackbar }) => {
  return (
    <div
      className={classNames({
        snackbar: true,
        'snackbar--show': showSnackbar
      })}
    >
      <p className="snackbar__text">{text}</p>
      <button className="button button--red" onClick={hideSnackbar}>
        Dismiss
      </button>
    </div>
  )
}

SnackBar.propTypes = {
  text: PropTypes.string.isRequired,
  showSnackbar: PropTypes.bool.isRequired,
  hideSnackbar: PropTypes.func
}

export default SnackBar
