import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { PageHelmet } from '../../common'

//reducers
import { getJwt } from '../../../reducers/accounts'
import { getAcademicGroups } from '../../../reducers/groups'
import { byId, getIsLoading } from '../../../reducers/subjects'
import { umbrellaGroup, groupId } from '../../../reducers/ui/editGroup'
import { teachersById, teachersAllIds } from '../../../reducers/teacher'
import { testsById } from '../../../reducers/tests'
import { getIsSuccess } from '../../../reducers/groups'

// actions
import { startFetchGroups } from '../../../actions/groups'
import { saveClassAndSection } from '../../../actions/ui/editGroups'
import {
  startFetchAllTeachers,
  startTeacherPasswordReset
} from '../../../actions/teacher'
import { startFetchSubjects } from '../../../actions/subjects'
import { startFetchTests } from '../../../actions/tests'

// import components
import { GroupSelector } from './classDivSelector'
import { StudentGroup } from './editClassSection'
import { EditClass } from './editClass'
import Spinner from '../../spinner'

class EditStudentGroupsComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired,
    groupsObj: PropTypes.object.isRequired,
    fetchStudentGroups: PropTypes.func.isRequired,
    storeClassAndSection: PropTypes.func.isRequired,
    umbrellaGroup: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    fetchTeachers: PropTypes.func.isRequired,
    teachersAllIds: PropTypes.array.isRequired,
    teachersById: PropTypes.object.isRequired,
    subjects: PropTypes.object.isRequired,
    isLoadingSubject: PropTypes.bool.isRequired,
    fetchSubjects: PropTypes.func.isRequired,
    tests: PropTypes.object.isRequired,
    fetchTests: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      umbrellaGroup: this.props.umbrellaGroup,
      groupId: this.props.groupId,
      isDirty: false,
      showGroup: false,
      selectedGroup: {
        group_name: '',
        academic_year: '',
        class_teacher: '',
        description: '',
        orderGroup: 0
      },
      tests: [],
      groupSubjects: [],
      fetchData: true,
      showSnackbar: false,
      snackbarType: ''
    }
    this.handleParentChange = this.handleParentChange.bind(this)
    this.getSections = this.getSections.bind(this)
    this.handleSectionChange = this.handleSectionChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.filterSubjectIds = this.filterSubjectIds.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getTeachers = this.getTeachers.bind(this)
    this.setSnackbarType = this.setSnackbarType.bind(this)
    this.hideSnackbar = this.hideSnackbar.bind(this)
  }

  componentDidMount() {
    const { jwt, fetchStudentGroups, fetchTeachers } = this.props
    fetchStudentGroups({
      jwt,
      type: 'academics'
    })
    fetchTeachers(jwt)
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.isLoadingSubject === false) {
    //   console.log("setting is dirty to true");
    //   this.setState({ isDirty: true })
    // }
    const { groupId, fetchData } = this.state
    const { groupsObj } = nextProps

    if (groupId !== '' && Object.keys(groupsObj).length !== 0 && fetchData) {
      const selectedGroup = groupsObj[groupId]
      const tests =
        selectedGroup.tests.length > 0
          ? selectedGroup.tests.filter(test => test !== null)
          : []
      this.setState(
        {
          selectedGroup: {
            group_name: selectedGroup?.group_name,
            academic_year: selectedGroup?.academic_year,
            class_teacher: selectedGroup?.class_teacher,
            description: selectedGroup?.description,
            orderGroup: selectedGroup?.orderGroup
          },
          groupSubjects: selectedGroup.subjects,
          groupTests: tests,
          showGroup: true,
          isDirty: false,
          fetchData: false
        },
        this.fetchData({
          subjects: selectedGroup.subjects,
          tests: selectedGroup.tests
        })
      )
    }

    if (groupId !== '' && Object.keys(groupsObj).length !== 0) {
      const selectedGroup = groupsObj[groupId]
      const tests =
        selectedGroup.tests.length > 0
          ? selectedGroup.tests.filter(test => test !== null)
          : []
      this.setState({
        selectedGroup: {
          group_name: selectedGroup?.group_name,
          academic_year: selectedGroup?.academic_year,
          class_teacher: selectedGroup?.class_teacher,
          description: selectedGroup?.description,
          orderGroup: selectedGroup?.orderGroup
        },
        groupSubjects: selectedGroup.subjects,
        groupTests: tests,
        showGroup: true,
        isDirty: false,
        fetchData: false
      })
    }
  }

  // handling umbrella group dropdown
  handleParentChange(e) {
    this.setState({
      umbrellaGroup: e.target.value,
      isDirty: false,
      showGroup: false,
      groupId: ''
    })
  }

  getSections() {
    const { umbrellaGroup } = this.state
    const { groups } = this.props
    if (umbrellaGroup === '') return []
    return groups
      .filter(groupObj => groupObj.parent_group === umbrellaGroup)
      .map(groupObj => {
        return {
          name: groupObj.group_name,
          id: groupObj._id
        }
      })
  }

  // handling section drop down
  handleSectionChange(e) {
    this.setState({
      groupId: e.target.value,
      isDirty: true,
      showGroup: false
    })
  }

  // handle submit for selecting a group and showing it
  handleSubmit(e) {
    e.preventDefault()
    const { groupsObj } = this.props
    const { groupId } = this.state
    const selectedGroup = groupsObj[groupId]
    const tests =
      selectedGroup.tests.length > 0
        ? selectedGroup.tests.filter(test => test !== null)
        : []
    this.setState(
      {
        showGroup: true,
        isDirty: false,
        fetchData: false,
        selectedGroup: {
          group_name: selectedGroup?.group_name,
          academic_year: selectedGroup?.academic_year,
          class_teacher: selectedGroup?.class_teacher,
          description: selectedGroup?.description,
          orderGroup: selectedGroup?.orderGroup
        },
        groupSubjects: selectedGroup.subjects,
        groupTests: tests
      },
      this.fetchData({
        subjects: selectedGroup.subjects,
        tests: selectedGroup.tests
      })
    )
  }

  fetchData(data) {
    const { jwt, storeClassAndSection, fetchSubjects, fetchTests } = this.props
    const { umbrellaGroup, groupId } = this.state

    storeClassAndSection({
      umbrellaGroup,
      groupId
    })
    const subjects = this.filterSubjectIds(data.subjects)
    const testIds =
      data.tests.length > 0 ? data.tests.filter(id => id !== null) : []
    fetchSubjects({ jwt, subjects })
    fetchTests({ jwt, tests: testIds })
  }

  filterSubjectIds(data) {
    return data.map(sub => sub.subject_id)
  }

  handleChange(e) {
    let { selectedGroup } = this.state
    e.preventDefault()
    if (e.target.id === 'orderGroup')
      selectedGroup[e.target.id] = Number(e.target.value)
    else selectedGroup[e.target.id] = e.target.value
    this.setState({ selectedGroup })
  }

  getTeachers() {
    const { teachersAllIds, teachersById } = this.props
    if (teachersAllIds === '') return []
    return teachersAllIds
      .filter(teach => teachersById[teach].archive === false)
      .map(teach => ({
        name: teachersById[teach].name,
        id: teachersById[teach]._id
      }))
  }

  hideSnackbar() {
    this.setState({ showSnackbar: false })
  }

  setSnackbarType(snackbarType) {
    this.setState({ snackbarType })
  }

  getSubjectTeachers(groupSubjects) {
    const { subjects } = this.props
    if (groupSubjects.length === 0) return {}

    const obj = {}
    groupSubjects
      .filter(subjectObj => {
        const { electives = [] } = subjects[subjectObj.subject_id]
        return electives.length === 0
      })
      .forEach(subjectObj => {
        obj[subjectObj.subject_id] = subjectObj.teacher_id
      })

    return obj
  }

  render() {
    const {
      groups,
      subjects,
      tests,
      isLoadingSubject,
      testsLoading,
      teachersById
    } = this.props
    const {
      groupId,
      umbrellaGroup,
      isDirty,
      showGroup,
      selectedGroup,
      groupSubjects,
      groupTests,
      showSnackbar,
      snackbarType
    } = this.state
    return (
      <div>
        <PageHelmet>Edit Class</PageHelmet>
        <div className="drop-down-menu">
          <div className="space-creator" />
          <div className="container">
            <div className="row">
              <div className="col-xs-3">
                <div className="tab">
                  <p className="tab__text">Edit Class</p>
                </div>
              </div>
              <div className="col-xs-9">
                <GroupSelector
                  groups={groups}
                  handleParentChange={this.handleParentChange}
                  umbrellaGroup={umbrellaGroup}
                  sections={this.getSections()}
                  handleSectionChange={this.handleSectionChange}
                  groupId={groupId}
                  handleSubmit={this.handleSubmit}
                  isDirty={isDirty}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="add-student-form-inner innerHeightFull">
            <div className="space-creator" />

            {/*styles for these  are in `_groupselector.scss`*/}
            {showGroup && !isLoadingSubject && !testsLoading && (
              <div>
                <StudentGroup
                  umbrellaGroup={umbrellaGroup}
                  selectedgroupId={groupId}
                  selectedGroup={selectedGroup}
                  subjects={subjects}
                  validSubjects={this.filterSubjectIds(groupSubjects)}
                  subjectTeachers={this.getSubjectTeachers(groupSubjects)}
                  teachersById={teachersById}
                  handleChange={this.handleChange}
                  teachers={this.getTeachers()}
                  groupId={groupId}
                  testIds={groupTests}
                  tests={tests}
                  snackbarType={snackbarType}
                  showSnackbar={showSnackbar}
                  setSnackbarType={this.setSnackbarType}
                  hideSnackbar={this.hideSnackbar}
                />
                {/* <EditClass
                      snackbarType={snackbarType}
                      showSnackbar={showSnackbar}
                      setSnackbarType={this.setSnackbarType}
                      hideSnackbar={this.hideSnackbar}
                    /> */}
                <div className="clear"></div>
              </div>
            )}
            {showGroup && (isLoadingSubject || testsLoading) && <Spinner />}
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state),
  groups: getAcademicGroups(state),
  groupsObj: state.groups.byId,
  teachersAllIds: teachersAllIds(state),
  teachersById: teachersById(state),
  umbrellaGroup: umbrellaGroup(state),
  groupId: groupId(state),
  subjects: byId(state),
  isLoadingSubject: getIsLoading(state),
  testsLoading: state.tests.isLoading,
  tests: testsById(state),
  isSuccess: getIsSuccess(state)
})

const mapDispatchToProps = dispatch => ({
  fetchStudentGroups(data) {
    dispatch(startFetchGroups(data))
  },
  storeClassAndSection(data) {
    dispatch(saveClassAndSection(data))
  },
  fetchTeachers(data) {
    dispatch(startFetchAllTeachers(data))
  },
  fetchSubjects(data) {
    dispatch(startFetchSubjects(data))
  },
  fetchTests(data) {
    dispatch(startFetchTests(data))
  }
})

export const EditStudentGroups = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStudentGroupsComponent)
