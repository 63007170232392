import downloadMarksReport from './downloadreport'
import downloadReportBestOfPer from './downloadReportBestOfPer'
import downloadReportPer from './downloadReportPer'
import checkListExam from './checkListExam'
import downloadReportcummulative from './downloadReportcummulative'

const allFunctions = {
  downloadReport: downloadMarksReport,
  downloadReportBestOfPer,
  downloadReportPer,
  downloadReportcummulative,
  checkListExam
}

export default allFunctions
