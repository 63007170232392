import {
  APP_LOGOUT,
  GET_HALLTICKET_START,
  GET_HALLTICKET_FAIL,
  GET_HALLTICKET_SUCCESS,
  POST_HALLTICKET_START,
  POST_HALLTICKET_FAIL,
  POST_HALLTICKET_SUCCESS,
  POST_TIMETABLE_START,
  POST_TIMETABLE_FAIL,
  POST_TIMETABLE_SUCCESS,
  GET_EXAMTIMETABLE_DETAILS_START,
  GET_EXAMTIMETABLE_DETAILS_FAIL,
  GET_EXAMTIMETABLE_DETAILS_SUCCESS,
  GET_STUDENT_EXAM_TIMETABLE_START,
  GET_STUDENT_EXAM_TIMETABLE_SUCCESS,
  GET_STUDENT_EXAM_TIMETABLE_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingGetExamTimetable: false,
  isErr: false,
  errMsg: '',
  details: [],
  examtimetable: [],
  studentExamTimetable: []
}

export const hallticket = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_HALLTICKET_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case GET_HALLTICKET_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        details: action.data
      })
    case GET_HALLTICKET_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case GET_EXAMTIMETABLE_DETAILS_START:
      return Object.assign({}, state, {
        isLoadingGetExamTimetable: true,
        isErr: false,
        errMsg: ''
      })
    case GET_EXAMTIMETABLE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetExamTimetable: false,
        isErr: false,
        examtimetable: action.data
      })
    case GET_EXAMTIMETABLE_DETAILS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetExamTimetable: false,
        isErr: true,
        errMsg: action.error
      })
    case POST_TIMETABLE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case POST_TIMETABLE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false
      })
    case POST_TIMETABLE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case POST_HALLTICKET_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case POST_HALLTICKET_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false
      })
    case POST_HALLTICKET_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case GET_STUDENT_EXAM_TIMETABLE_START:
      return Object.assign({}, state, {
        isLoadingGetExamTimetable: true,
        isErr: false,
        errMsg: ''
      })
    case GET_STUDENT_EXAM_TIMETABLE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetExamTimetable: false,
        isErr: false,
        studentExamTimetable: action.data?.[0]?.timeTable
      })
    case GET_STUDENT_EXAM_TIMETABLE_FAIL:
      return Object.assign({}, state, {
        isLoadingGetExamTimetable: false,
        isErr: true,
        errMsg: action.error
      })

    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.hallticket.isLoading
export const getIsError = state => state.hallticket.isErr
export const getErrMsg = state => state.hallticket.errMsg
export const getHallticket = state => state.hallticket.details
export const getIsLoadingGetExamTimetable = state =>
  state.hallticket.isLoadingGetExamTimetable
export const getExamtimetable = state => state.hallticket.examtimetable
export const getStudentExamTimetable = state =>
  state.hallticket.studentExamTimetable
