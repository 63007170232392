import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import { Field } from 'redux-form'
import { MONTHS_TYPES } from '../../../helpers/enums'

class TestDetailSection extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    //instantiate with one form
    props.fields.push()
  }

  render() {
    const { fields } = this.props
    return (
      <ul>
        {fields.map((field, index) => (
          <li key={index}>
            <div className="field">
              <label className="field__label" htmlFor={`${field}`}>
                Test #{index + 1}
              </label>
            </div>
            <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
              <div className="field">
                <label className="field__label" htmlFor="name">
                  Name of Test *
                </label>
                <Field
                  name={`${field}.name`}
                  id="name"
                  placeholder="Test Name"
                  type="text"
                  component="input"
                  required
                />
              </div>
              <div className="field">
                <label className="field__label" htmlFor="percentage">
                  Percentage *
                </label>
                <Field
                  name={`${field}.percentage`}
                  id="percentage"
                  placeholder="Percentage"
                  type="number"
                  component="input"
                  required
                />
              </div>
            </div>
            <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
              <div className="field">
                <label className="field__label" htmlFor="month">
                  Month *
                </label>
                <Field
                  name={`${field}.month`}
                  id="month"
                  placeholder="Month"
                  type="string"
                  component="select"
                  required
                >
                  <option value="" disabled>
                    Select Month
                  </option>
                  {MONTHS_TYPES.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="field">
                <label className="field__label" htmlFor="order">
                  Test Order *
                </label>
                <Field
                  name={`${field}.order`}
                  id="order"
                  placeholder="Test Order"
                  type="number"
                  component="input"
                  required
                />
              </div>
            </div>
            <div className="fields-ctr fields-ctr--has-2-fields">
              <div className="field">
                <label className="field__label" htmlFor="term">
                  Term *
                </label>
                <Field
                  name={`${field}.term`}
                  id="term"
                  placeholder="Term"
                  type="number"
                  component="input"
                  required
                />
              </div>
              <div className="field">
                <button
                  className="button button--round button--red"
                  type="button"
                  title="Remove Test"
                  onClick={() => fields.remove(index)}
                >
                  <FontAwesome name="trash" />
                </button>
              </div>
            </div>
          </li>
        ))}
        <li>
          <button
            className="button button--white"
            type="button"
            onClick={() => fields.push()}
          >
            + Add
          </button>
        </li>
      </ul>
    )
  }
}

export default TestDetailSection
