// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import moment from 'moment'
import { PageHelmet } from '../common'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
// import actions
import {
  startAddRegisterCertificate,
  startAddConductCertificate
} from '../../actions/conductCertificate'
// import selectors
import { getJwt } from '../../reducers/accounts'
import { groupsById } from '../../reducers/groups'
import { getAcademicYear } from '../../reducers/institution'
// import enums
import { CONDUCT_CERTIFICATE_NAME } from '../../helpers/enums'
// import components
import GroupSelector from './groupSelector'
import StudentSelector from './studentSelector'
import ConformationPage from './conformationPage'

class ConductCertificateContainer extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    groupsById: PropTypes.object.isRequired,
    academicYear: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    students: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
    this.nextPage = this.nextPage.bind(this)
  }

  nextPage() {
    this.setState({
      page: this.state.page + 1
    })
  }
  handleSubmit = data => {
    const { students, groupId } = data
    const {
      jwt,
      generatateRegisterCertificate,
      academicYear,
      generateConductCertificate,
      nameOfComponent,
      template
    } = this.props
    const stdArr = []
    Object.keys(students)
      .filter(stdId => students[stdId])
      .forEach(stdId => stdArr.push(stdId))
    const formatteddate = moment().format('YYYY-MM-DD')
    if (nameOfComponent === 'register-cerificate') {
      const body = {
        jwt,
        students: stdArr,
        studentGroup: groupId,
        date: formatteddate,
        template: template,
        academic_year: academicYear
      }
      generatateRegisterCertificate(body)
    } else {
      const body = {
        jwt,
        students: stdArr,
        studentGroup: groupId,
        date: formatteddate,
        template: 'sjhsConductCertificate',
        academic_year: academicYear
      }
      generateConductCertificate(body)
    }
  }
  renderComponent() {
    const { page } = this.state
    const { groupId, groupsById, nameOfComponent } = this.props
    return (
      <div className="new-group-form-ctr">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              {page === 1 && (
                <GroupSelector
                  onSubmit={this.nextPage}
                  nameOfComponent={nameOfComponent}
                />
              )}
              {page === 2 && <StudentSelector onSubmit={this.nextPage} />}
              {page === 3 && (
                <ConformationPage
                  onSubmit={this.handleSubmit}
                  groupData={groupsById[groupId]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { page } = this.state
    const { PageHelmetText, template } = this.props
    return (
      <div>
        <PageHelmet>{PageHelmetText}</PageHelmet>
        <div className="tab-nav-ctr tab-nav-ctr--sms">
          <div className="tab-nav">
            <div className="tab">
              <p className="tab__text">{PageHelmetText}</p>
            </div>
            <div
              className={classNames({
                navigation: true,
                'navigation--active': page === 1,
                'navigation--success': page > 1
              })}
            >
              <p className="navigation__text">
                <span className="navigation__num">1</span>
                Class/Sec
              </p>
            </div>
            <FontAwesome name="chevron-right" />
            <div
              className={classNames({
                navigation: true,
                'navigation--active': page === 2,
                'navigation--success': page > 2
              })}
            >
              <p className="navigation__text">
                <span className="navigation__num">2</span>
                Students
              </p>
            </div>
            <FontAwesome name="chevron-right" />
            <div
              className={classNames({
                navigation: true,
                'navigation--active': page === 3,
                'navigation--success': page > 3
              })}
            >
              <p className="navigation__text">
                <span className="navigation__num">3</span>
                Confirm
              </p>
            </div>
          </div>
        </div>
        {this.renderComponent()}
      </div>
    )
  }
}

const selector = formValueSelector(CONDUCT_CERTIFICATE_NAME)

const mapStateToProps = state => {
  let groupId = selector(state, 'groupId')
  let students = selector(state, 'students')
  let date = selector(state, 'date')
  let template = selector(state, 'templete')
  return {
    jwt: getJwt(state),
    groupsById: groupsById(state),
    academicYear: getAcademicYear(state),
    groupId: groupId ? groupId : '',
    students: students ? students : {},
    date: date ? date : '',
    template: template ? template : ''
  }
}

const mapDispatchToProps = dispatch => ({
  generatateRegisterCertificate(data) {
    dispatch(startAddRegisterCertificate(data))
  },
  generateConductCertificate(data) {
    dispatch(startAddConductCertificate(data))
  }
})

const ConductCertificate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConductCertificateContainer)

export default ConductCertificate
