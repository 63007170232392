import {
  APP_LOGOUT,
  START_POST_WORKSHEET,
  WORKSHEET_POST_FAILURE,
  WORKSHEET_POST_SUCCESS,
  START_GET_WORKSHEET,
  WORKSHEET_GET_FAILURE,
  WORKSHEET_GET_SUCCESS,
  DELETE_WORKSHEET_START,
  DELETE_WORKSHEET_SUCCESS,
  DELETE_WORKSHEET_FAIL,
  PATCH_WORKSHEET_START,
  PATCH_WORKSHEET_SUCCESS,
  PATCH_WORKSHEET_FAIL
} from '../helpers/actions'

const initialState = {
  worksheet: [],
  postInProgress: false,
  postStatus: '',
  error: false,
  isLoading: false,
  isDeleteLoading: false,
  isPatchLoading: false
}

export const worksheet = (state = initialState, action) => {
  switch (action.type) {
    case START_GET_WORKSHEET:
      return Object.assign({}, state, {
        isLoading: true,
        error: false
      })
    case WORKSHEET_GET_FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          isLoading: false
        }
      )
    case WORKSHEET_GET_SUCCESS:
      return Object.assign({}, state, {
        worksheet: action.data,
        isLoading: false,
        error: false
      })

    case START_POST_WORKSHEET:
      return Object.assign({}, state, {
        isLoading: true,
        error: false
      })
    case WORKSHEET_POST_FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          isLoading: false
        }
      )
    case WORKSHEET_POST_SUCCESS:
      return Object.assign({}, state, {
        worksheet: action.data,
        isLoading: false,
        error: false
      })
    case DELETE_WORKSHEET_START:
      return Object.assign({}, state, {
        isDeleteLoading: true,
        error: false
      })
    case DELETE_WORKSHEET_SUCCESS:
      return Object.assign(
        {},
        {
          error: false,
          isDeleteLoading: false
        }
      )
    case DELETE_WORKSHEET_FAIL:
      return Object.assign({}, state, {
        isDeleteLoading: false,
        error: true
      })
    case PATCH_WORKSHEET_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        error: false
      })
    case PATCH_WORKSHEET_SUCCESS:
      return Object.assign(
        {},
        {
          error: false,
          isPatchLoading: false
        }
      )
    case PATCH_WORKSHEET_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: true
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getWorksheet = state =>
  state.worksheet.worksheet ? state.worksheet.worksheet : []
export const getisLoading = state => state.worksheet.isLoading
export const getError = state => state.worksheet.error
export const getPatchLoading = state => state.worksheet.isPatchLoading
export const getDeleteLoading = state => state.worksheet.isDeleteLoading
