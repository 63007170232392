import {
  SAVE_CLASS_AND_SECTION_FOR_ATTENDANCE,
  SAVE_START_AND_END_DATE,
  SAVE_DATA,
  SET_IN_PROGRESS,
  SAVE_GROUPSELECTOR_DATA_FOR_ATTENDANCE,
  SAVE_DATA_FOR_UI_ATTENDANCE,
  SAVE_STUDENTS_FOR_ATTENDANCE,
  SAVE_YEAR_MONTH_FOR_ATTENDANCE,
  SAVE_DATA_FOR_WEEKLY_ATTENDANCE
} from '../../helpers/actions'

export const saveClassAndSection = ({ umbrellaGroup, groupId }) => ({
  type: SAVE_CLASS_AND_SECTION_FOR_ATTENDANCE,
  umbrellaGroup,
  groupId
})

export const saveStartAndEndDate = ({ startDate, endDate }) => ({
  type: SAVE_START_AND_END_DATE,
  startDate,
  endDate
})

export const saveData = data => ({
  type: SAVE_DATA,
  data
})

export const setProgressStatus = ({ inProgress }) => ({
  type: SET_IN_PROGRESS,
  inProgress
})

export const saveGroupSelectorData = data => ({
  type: SAVE_GROUPSELECTOR_DATA_FOR_ATTENDANCE,
  data
})

export const saveAttendanceData = data => ({
  type: SAVE_DATA_FOR_UI_ATTENDANCE,
  data
})

export const saveStudentIdsForAttendance = data => ({
  type: SAVE_STUDENTS_FOR_ATTENDANCE,
  data
})

export const saveYearAndMonthForAttendance = ({ year, month }) => ({
  type: SAVE_YEAR_MONTH_FOR_ATTENDANCE,
  year,
  month
})

export const saveDataForWeeklyAttendance = ({
  date,
  umbrellaGroup,
  groupId
}) => ({
  type: SAVE_DATA_FOR_WEEKLY_ATTENDANCE,
  date,
  umbrellaGroup,
  groupId
})
