import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Snackbar from './snackbar'

export const DeleteSubjects = ({
  subjects,
  existingSubjects,
  deleteSubject,
  deletedSubjects,
  handleDeleteSubjects,
  showSnackbar,
  hideSnackbar,
  snackbarType
}) => {
  const getSubjectName = subjectObj => {
    if (subjectObj.is_main === true) return subjectObj.name
    let name
    Object.keys(subjects).some(subjectId => {
      if (subjects[subjectId].electives.includes(subjectObj.id)) {
        name = `${subjects[subjectId].name} - ${subjectObj.name}`
        return true
      }
      return false
    })
    return name
  }
  return (
    <div id="subjects">
      <h3 className="page__subHead">Delete Subjects</h3>
      <div class="table-responsive">
        <table class="table deleteSubjectTable">
          <tr>
            <th>Subject Name</th>
            <th>Select</th>
          </tr>
          {existingSubjects.length === 0 && (
            <td colSpan="2">No Subjects Available</td>
          )}
          {existingSubjects.length !== 0 &&
            existingSubjects.map((subjectId, index) => {
              //TODO: just shape this data via a selector
              const subject = Object.assign(
                {},
                { id: subjectId, name: 'MISSING' },
                subjects[subjectId]
              )
              return (
                <tr key={subjectId}>
                  <td>{getSubjectName(subject)}</td>
                  <td>
                    <button
                      className={classNames({
                        button: true,
                        'button--bordered': !deletedSubjects[subjectId],
                        'button--blue': deletedSubjects[subjectId]
                      })}
                      aria-hidden="true"
                      id={subjectId}
                      onClick={deleteSubject}
                    >
                      {!deletedSubjects[subjectId] ? 'SELECT' : 'UNSELECT'}
                    </button>
                  </td>
                </tr>
              )
            })}
        </table>
      </div>
      <div className="buttonalign__center buttonalign__center--margins">
        <button
          className={classNames({
            'button button--blue': true,
            disabled: existingSubjects.length === 0
          })}
          onClick={handleDeleteSubjects}
          disabled={existingSubjects.length === 0}
        >
          Delete Subjects
        </button>
      </div>
      <div className="clear"></div>
      <Snackbar
        text="Subjects Deleted Sucessfully"
        showSnackbar={snackbarType === 'subjects' && showSnackbar}
        hideSnackbar={hideSnackbar}
      />
      <div className="clear"></div>
    </div>
  )
}

DeleteSubjects.propTypes = {
  subjects: PropTypes.object.isRequired,
  existingSubjects: PropTypes.array.isRequired,
  deleteSubject: PropTypes.func.isRequired,
  deletedSubjects: PropTypes.object.isRequired,
  handleDeleteSubjects: PropTypes.func.isRequired,
  showSnackbar: PropTypes.bool.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  snackbarType: PropTypes.string.isRequired
}

export const AddSubjects = () => {}
