import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//selectors
import {
  isLoading,
  getAcademicGrades,
  getAcademicGroups
} from '../../reducers/groups'
//components
import Spinner from '../spinner'
import ReduxSelect, { ReduxCheckbox } from './inputs'

const mapStateToProps = (state, ownProps) => {
  const { selectedClass = '' } = ownProps
  return {
    classes: getAcademicGrades(state),
    sections: getAcademicGroups(state).filter(
      section => section.parent_group === selectedClass
    ),
    ready: !isLoading(state)
  }
}

class SelectSectionsOfClassComponent extends Component {
  static propTypes = {
    //ownProps
    selectedClass: PropTypes.string.isRequired,
    //mapStateToProps
    classes: PropTypes.arrayOf(PropTypes.string).isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        group_name: PropTypes.string.isRequired,
        parent_group: PropTypes.string.isRequired
      })
    ).isRequired,
    ready: PropTypes.bool.isRequired
  }

  render() {
    const { ready, classes, sections } = this.props
    if (ready)
      return (
        <div className="class-details section-details">
          <div className="section-field class-field--70">
            <ReduxSelect
              name="grade"
              options={classes}
              placeholder="Select Class"
            />
          </div>
          <div className="section-field">
            <ReduxCheckbox
              name="sections"
              options={sections.map(section => ({
                ...section,
                name: section.group_name
              }))}
            />
          </div>
        </div>
      )
    else return <Spinner />
  }
}

const SelectSectionsOfClass = connect(mapStateToProps)(
  SelectSectionsOfClassComponent
)

export default SelectSectionsOfClass
