import {
  LEAVE_POLICY_POST_START,
  LEAVE_POLICY_POST_SUCCESS,
  LEAVE_POLICY_POST_FAILURE,
  LEAVE_POLICY_PATCH_START,
  LEAVE_POLICY_PATCH_SUCCESS,
  LEAVE_POLICY_PATCH_FAILURE,
  LEAVE_POLICY_FETCH_START,
  LEAVE_POLICY_FETCH_SUCCESS,
  LEAVE_POLICY_FETCH_FAILURE,
  LEAVE_POLICY_DELETE_START,
  LEAVE_POLICY_DELETE_SUCCESS,
  LEAVE_POLICY_DELETE_FAILURE,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isSuccess: false,
  isErr: false,
  hasErr: false,
  isLoadingDelete: false,
  isSuccessDelete: false,
  isSuccessEdit: false,
  policyData: [],
  msg: ''
}

export const leavePolicy = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState

    case LEAVE_POLICY_DELETE_START:
      return Object.assign({}, state, {
        isLoadingDelete: true,
        isSuccessDelete: false,
        hasErr: false
      })
    case LEAVE_POLICY_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isSuccessDelete: true,
        hasErr: false
      })
    case LEAVE_POLICY_DELETE_FAILURE:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isSuccessDelete: false,
        hasErr: true
      })
    case LEAVE_POLICY_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true
      })

    case LEAVE_POLICY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
        policyData: action.data.employee_leave_policies
      })

    case LEAVE_POLICY_FETCH_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        isSuccess: false
      })
    case LEAVE_POLICY_PATCH_SUCCESS:
      return Object.assign({}, state, {
        isSuccessEdit: true,
        isLoading: false,
        isErr: false,
        msg: 'success'
      })

    case LEAVE_POLICY_PATCH_FAILURE:
      return Object.assign({}, state, {
        isSuccess: false,
        isLoading: false,
        isErr: true,
        msg: 'failure'
      })

    case LEAVE_POLICY_PATCH_START:
      return Object.assign({}, state, {
        isSuccess: action.data,
        isLoading: true,
        isErr: false,
        msg: ''
      })

    case LEAVE_POLICY_POST_SUCCESS:
      return Object.assign({}, state, {
        isSuccess: true,
        isLoading: false,
        isErr: false,
        msg: 'success'
      })

    case LEAVE_POLICY_POST_FAILURE:
      return Object.assign({}, state, {
        isSuccess: false,
        isLoading: false,
        isErr: true,
        msg: 'failure'
      })

    case LEAVE_POLICY_POST_START:
      return Object.assign({}, state, {
        isSuccess: action.data,
        isLoading: true,
        isErr: false,
        msg: ''
      })

    default:
      return state
  }
}

export const isLoadingLeavePolicy = state => state.leavePolicy.isLoading
export const isLoadingDeletePolicy = state => state.leavePolicy.isLoadingDelete
export const getLeavePolicyIsSuccess = state => state.leavePolicy.isSuccess
export const getLeavePolicyIsSuccessEdit = state =>
  state.leavePolicy.isSuccessEdit
export const getLeavePolicyData = state => state.leavePolicy.policyData
export const getLeavePolicyHasError = state => state.leavePolicy.isErr
export const getPolicyDeleteHasError = state => state.leavePolicy.hasErr
export const getPostStatusLeavePolicy = state => state.leavePolicy.msg
