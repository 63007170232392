import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from './snackbar'

const EditGroupData = ({
  selectedGroup,
  handleChange,
  teachers,
  handleClick,
  showSnackbar,
  hideSnackbar,
  snackbarType
}) => (
  <div id="subjects">
    <h3 className="page__subHead">Edit Section</h3>
    <div className="form-row">
      <div className="form-group col-md-6">
        <label for="group_name">Section Name</label>

        <input
          type="text"
          id="group_name"
          placeholder="Section Name"
          onChange={handleChange}
          value={selectedGroup.group_name}
          className="form-control"
        />
      </div>
      <div className="form-group col-md-6">
        <label for="academic_year">Academic Year</label>

        <input
          type="text"
          id="academic_year"
          placeholder="Academic Year"
          onChange={handleChange}
          value={selectedGroup.academic_year}
          className="form-control"
        />
      </div>
    </div>

    <div className="form-row">
      <div className="form-group col-md-6">
        <label for="class_teacher">Class Teacher</label>
        <select
          className="form-control"
          name="class_teacher"
          id="class_teacher"
          value={selectedGroup.class_teacher ? selectedGroup.class_teacher : ''}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select Class Teacher
          </option>
          {teachers.map((option, index) => {
            return (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            )
          })}
        </select>
      </div>
      <div className="form-group col-md-6">
        <label for="description">Description</label>
        <input
          type="text"
          id="description"
          placeholder="Description"
          onChange={handleChange}
          value={selectedGroup.description}
          className="form-control"
        />
      </div>
    </div>

    <div className="form-row">
      <div className="form-group col-md-6">
        <label for="orderGroup">Section Order</label>

        <input
          type="text"
          id="orderGroup"
          placeholder="Section order"
          onChange={handleChange}
          value={selectedGroup.orderGroup}
          className="form-control"
        />
      </div>
    </div>

    <div className="clear"></div>
    <div className="buttonalign__center buttonalign__center--margins">
      <button
        className="button button--blue"
        type="button"
        onClick={handleClick}
      >
        Save
      </button>
    </div>
    <div className="clear"></div>
    <Snackbar
      text="Group Data has been updated sucessfully"
      showSnackbar={snackbarType === 'groupData' && showSnackbar}
      hideSnackbar={hideSnackbar}
    />
    <div className="clear"></div>
  </div>
)

EditGroupData.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  showSnackbar: PropTypes.bool.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  snackbarType: PropTypes.string.isRequired
}

export default EditGroupData
