import { createSelector } from 'reselect'
import {
  ATTENDANCE_DATA_FOR_CLASS_PROFILE,
  HOURLY_ATTENDANCE_DATA_FOR_CLASS_PROFILE,
  ATTENDANCE_GET_STARTED,
  APP_LOGOUT
} from '../helpers/actions'
import _ from 'underscore'

const initialState = {
  isLoading: false,
  studentsById: {},
  studentAllIds: [],
  hourlyStudentsById: {},
  hourlyStudentAllIds: [],
  allHourlyAttendance: []
}

export const classProfile = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ATTENDANCE_GET_STARTED:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ATTENDANCE_DATA_FOR_CLASS_PROFILE:
      return Object.assign({}, state, {
        isLoading: false,
        studentsById: action.entities,
        studentAllIds: action.result
      })
    case HOURLY_ATTENDANCE_DATA_FOR_CLASS_PROFILE:
      return Object.assign({}, state, {
        isLoading: false,
        hourlyStudentsById: action.entities,
        hourlyStudentAllIds: action.result,
        allHourlyAttendance: action.data
      })
    default:
      return state
  }
}

export const getAttendanceIsLoading = state => state.classProfile.isLoading
export const studentsById = state => state.classProfile.studentsById
export const studentAllIds = state => state.classProfile.studentAllIds
export const hourlyStudentsById = state => state.classProfile.hourlyStudentsById
export const hourlyStudentAllIds = state =>
  state.classProfile.hourlyStudentAllIds
export const getAllHourlyAttendance = state =>
  state.classProfile.allHourlyAttendance

export const attendanceDataForClassProfile = createSelector(
  studentsById,
  state => state.ui.classProfile.groupId,
  state => state.groups.byId,
  (studentsById, groupId, groupsById) => {
    const obj = {}

    if (groupId === '' || Object.keys(groupsById)?.length === 0) return obj

    const activeGroup = groupsById[groupId]
    obj.studentCount = activeGroup?.students?.length
    activeGroup?.students?.forEach(studentId => {
      const student = studentsById[studentId]
      student &&
        Object.keys(student)?.forEach(date => {
          if (student[date]?.attendance) {
            if (!obj[date]) {
              obj[date] = {
                present: 1,
                takenBy: student[date]?.taken_by,
                taken_by_id: student[date]?.taken_by_id,
                taken_by_name: student[date]?.taken_by_name
              }
            } else obj[date].present++
          } else {
            if (!obj[date]) {
              obj[date] = {
                present: 0,
                takenBy: student[date]?.taken_by,
                taken_by_id: student[date]?.taken_by_id,
                taken_by_name: student[date]?.taken_by_name
              }
            }
          }
        })
    })
    return obj
  }
)

export const hourlyAttendanceDataForClassProfile = createSelector(
  hourlyStudentsById,
  state => state.ui.classProfile.groupId,
  state => state.groups.byId,
  (studentsById, groupId, groupsById) => {
    const obj = {}

    if (groupId === '' || Object.keys(groupsById).length === 0) return obj

    const activeGroup = groupsById[groupId]
    obj.studentCount = activeGroup.students.length
    activeGroup.students.forEach(studentId => {
      const student = studentsById[studentId]
      student &&
        Object.keys(student).forEach(hour => {
          if (student[hour].attendance) {
            if (!obj[hour]) {
              obj[hour] = {
                present: 1,
                takenBy: [student[hour]?.taken_by],
                taken_by_id: [student[hour]?.taken_by_id],
                taken_by_name: [student[hour]?.taken_by_name],
                assigned_to: [student[hour]?.assigned_to],
                class_taken_by: [student[hour]?.class_taken_by]
              }
            } else {
              obj[hour].present++
              obj[hour].takenBy = _.uniq(
                obj[hour].takenBy.concat([student[hour]?.taken_by])
              )
              obj[hour].taken_by_id = _.uniq(
                obj[hour].taken_by_id.concat([student[hour]?.taken_by_id])
              )
              obj[hour].taken_by_name = _.uniq(
                obj[hour].taken_by_name.concat([student[hour]?.taken_by_name])
              )
              obj[hour].assigned_to = _.uniq(
                obj[hour].assigned_to.concat([student[hour]?.assigned_to])
              )
              obj[hour].class_taken_by = _.uniq(
                obj[hour].class_taken_by.concat([student[hour]?.class_taken_by])
              )
            }
          } else {
            if (!obj[hour]) {
              obj[hour] = {
                present: 0,
                takenBy: [student[hour]?.taken_by],
                taken_by_id: [student[hour]?.taken_by_id],
                taken_by_name: [student[hour]?.taken_by_name],
                assigned_to: [student[hour]?.assigned_to],
                class_taken_by: [student[hour]?.class_taken_by]
              }
            } else {
              obj[hour].takenBy = _.uniq(
                obj[hour].takenBy.concat([student[hour]?.taken_by])
              )
              obj[hour].taken_by_id = _.uniq(
                obj[hour].taken_by_id.concat([student[hour]?.taken_by_id])
              )
              obj[hour].taken_by_name = _.uniq(
                obj[hour].taken_by_name.concat([student[hour]?.taken_by_name])
              )
              obj[hour].assigned_to = _.uniq(
                obj[hour].assigned_to.concat([student[hour]?.assigned_to])
              )
              obj[hour].class_taken_by = _.uniq(
                obj[hour].class_taken_by.concat([student[hour]?.class_taken_by])
              )
            }
          }
        })
    })
    return obj
  }
)
