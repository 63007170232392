// import packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import actions
import { startChangePassword } from '../../actions/login'
// import selectors
import {
  getJwt,
  isLoading as isChangePasswordLoading,
  getParentJwt,
  getSiblings
} from '../../reducers/accounts'
// import components
import { PageHelmet } from '../common'
import ChangePasswordForm from './changePasswordForm'

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(values) {
    const { jwt, changePassword, siblings, parentJwt } = this.props
    if (siblings && siblings.length > 0) {
      changePassword({
        jwt: parentJwt,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      })
    } else {
      changePassword({
        jwt,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      })
    }
  }

  render() {
    return (
      <div>
        <PageHelmet>Change Password</PageHelmet>
        <div>
          <div className="drop-down-menu">
            <div className="space-creator" />
            <div className="container">
              <div className="row row-eq-height">
                <div className="col-xs-offset-1 col-lg-3 col-xs-4">
                  <div className="tab">
                    <p className="tab__text">CHANGE PASSWORD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="add-student-form-outer">
            <div className="container">
              <div className="add-student-form-inner">
                <div className="row">
                  <div className="col-md-offset-2 col-md-8 add-student-form">
                    <p className="info-title">Change Password</p>
                    <ChangePasswordForm onSubmit={this.handleSubmit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state),
  changePasswordInProgress: isChangePasswordLoading(state),
  parentJwt: getParentJwt(state),
  siblings: getSiblings(state)
})

const mapDispatchToProps = dispatch => ({
  changePassword(data) {
    dispatch(startChangePassword(data))
  }
})

const ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordComponent)

export default ChangePassword
