import map from 'lodash/map'
import moment from 'moment'

import {
  APP_LOGOUT,
  FETCH_MONTHLY_ATTENDANCE_START,
  FETCH_MONTHLY_ATTENDANCE_SUCCESS,
  FETCH_MONTHLY_ATTENDANCE_FAIL
} from './../helpers/actions'

const initialState = {
  isLoading: false,
  byStudent: {},
  allStudents: [],
  errorMsg: '',
  isError: false
}

export const monthOverall = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case FETCH_MONTHLY_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        errorMsg: ''
      })
    case FETCH_MONTHLY_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        byStudent: action.byStudent,
        allStudents: action.allStudents
      })
    case FETCH_MONTHLY_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        errorMsg: 'Failed to fetch monthly attendance'
      })
    default:
      return state
  }
}

/* Selectors */
export const isLoading = state => state.reports.monthOverall.isLoading
export const getMonthlyAttdVals = (state, studentIds, year, month) => {
  const studentsById = state.students.byId
  const monthOverall = state.reports.monthOverall.byStudent
  if (
    Object.keys(studentsById).length === 0 ||
    !studentIds ||
    studentIds.length === 0
  )
    return {}
  let days_in_month = null

  const monthWith31Days = [1, 3, 5, 7, 8, 10, 12]
  const monthWith30Days = [4, 6, 9, 11]

  const newStudentsById = {}
  studentIds.forEach(id => {
    newStudentsById[id] = studentsById[id]
  })
  let studentInfo = map(newStudentsById, (student, key) => {
    if (monthWith31Days.includes(month)) {
      days_in_month = 31
    } else if (monthWith30Days.includes(month)) {
      days_in_month = 30
    } else {
      /*February handling*/
      days_in_month =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28
    }

    const attendanceByDays = []
    attendanceByDays.length = days_in_month
    attendanceByDays.fill('')

    if (monthOverall[student._id]) {
      monthOverall[student._id].forEach(val => {
        const { date, attendance, holiday } = val
        const day = parseInt(moment(date).format('D'), 10)
        if (holiday) attendanceByDays[day - 1] = 'H'
        else attendanceByDays[day - 1] = attendance
      })
    }

    return {
      _id: student?._id,
      studentName: student?.name,
      roll_number: student.roll_number ? student.roll_number : '',
      total_attendance: monthOverall[student?._id]
        ? monthOverall[student?._id].length
        : '',
      attendanceByDays
    }
  })

  studentInfo = studentInfo.sort((a, b) => {
    if (a?.studentName?.toLowerCase() < b?.studentName?.toLowerCase()) return -1
    return 1
  })

  return {
    days_in_month,
    month,
    studentInfo
  }
}

export const getMonthlyAttendance = state => {
  const studentsById = state.students.byId
  const monthOverall = state.reports.monthOverall.byStudent
  const studentIds = state.ui.attendance.studentIds
  const year = state.ui.attendance.year
  const month = state.ui.attendance.month
  if (
    Object.keys(studentsById).length === 0 ||
    !studentIds ||
    studentIds.length === 0
  )
    return {}
  let days_in_month = null

  const monthWith31Days = [1, 3, 5, 7, 8, 10, 12]
  const monthWith30Days = [4, 6, 9, 11]

  const newStudentsById = {}
  studentIds.forEach(id => {
    newStudentsById[id] = studentsById[id]
  })
  let studentInfo = map(newStudentsById, (student, key) => {
    if (monthWith31Days.includes(month)) {
      days_in_month = 31
    } else if (monthWith30Days.includes(month)) {
      days_in_month = 30
    } else {
      /*February handling*/
      days_in_month =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28
    }

    const attendanceByDays = []
    attendanceByDays.length = days_in_month
    attendanceByDays.fill('')

    if (monthOverall?.[student?._id]) {
      monthOverall?.[student?._id].forEach(val => {
        const { date, attendance, holiday, late } = val
        const day = parseInt(moment(date).format('D'), 10)
        if (holiday) attendanceByDays[day - 1] = 'H'
        else if (late) attendanceByDays[day - 1] = 'L'
        else attendanceByDays[day - 1] = attendance
      })
    }

    return {
      _id: student?._id,
      studentName: student?.name,
      roll_number: student?.roll_number ? student.roll_number : '',
      registration_number: student?.registration_number,
      total_attendance: monthOverall?.[student?._id]
        ? monthOverall?.[student?._id].length
        : '',
      attendanceByDays
    }
  })
  studentInfo = studentInfo?.sort((a, b) => {
    if (a?.studentName?.toLowerCase() < b?.studentName?.toLowerCase()) return -1
    return 1
  })

  return {
    days_in_month,
    month,
    studentInfo
  }
}

export const getHalfDayMonthlyAttendance = state => {
  const studentsById = state.students.byId
  const monthOverall = state.reports.monthOverall.byStudent
  const studentIds = state.ui.attendance.studentIds
  const year = state.ui.attendance.year
  const month = state.ui.attendance.month
  if (
    Object.keys(studentsById).length === 0 ||
    !studentIds ||
    studentIds.length === 0
  )
    return {}
  let days_in_month = null

  const monthWith31Days = [1, 3, 5, 7, 8, 10, 12]
  const monthWith30Days = [4, 6, 9, 11]

  const newStudentsById = {}
  studentIds.forEach(id => {
    newStudentsById[id] = studentsById[id]
  })
  let studentInfo = map(newStudentsById, (student, key) => {
    if (monthWith31Days.includes(month)) {
      days_in_month = 31
    } else if (monthWith30Days.includes(month)) {
      days_in_month = 30
    } else {
      /*February handling*/
      days_in_month =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28
    }

    const attendanceByDays = []
    attendanceByDays.length = days_in_month
    attendanceByDays.fill('')

    if (monthOverall?.[student?._id]) {
      monthOverall?.[student?._id].forEach(val => {
        const { date, attendance, holiday } = val
        const day = parseInt(moment(date).format('D'), 10)
        if (holiday) attendanceByDays[day - 1] = 'H'
        else
          attendanceByDays[day - 1] = {
            attendance,
            session: val?.session,
            late: val?.late,
            niwd: val?.niwd
          }
      })
    }

    return {
      _id: student?._id,
      studentName: student?.name,
      roll_number: student?.roll_number ? student.roll_number : '',
      registration_number: student?.registration_number,
      total_attendance: monthOverall?.[student?._id]
        ? monthOverall?.[student?._id].length
        : '',
      attendanceByDays
    }
  })
  studentInfo = studentInfo?.sort((a, b) => {
    if (a?.studentName?.toLowerCase() < b?.studentName?.toLowerCase()) return -1
    return 1
  })

  return {
    days_in_month,
    month,
    studentInfo
  }
}
