// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { ComposedChart, Bar, XAxis, Cell } from 'recharts'

const SingleTestPerformanceGrades = ({ getBarColor, data }) => {
  return (
    <ComposedChart
      width={650}
      height={200}
      data={data}
      // margin={{top: 20, right: 20, bottom: 20, left: 20}}
    >
      <XAxis dataKey="code" />

      <Bar dataKey="percentage" barSize={40}>
        {' '}
        {/*fill="#413ea0"*/}
        {data.map((dataObj, index) => {
          const color = getBarColor(Number(dataObj.percentage))
          return <Cell key={index} fill={color} />
        })}
      </Bar>
    </ComposedChart>
  )
}

SingleTestPerformanceGrades.propTypes = {
  getBarColor: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}
export default SingleTestPerformanceGrades
