// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts'

const SingleTestPerformanceMarks = ({ getBarColor, data }) => {
  return (
    <div>
      <ComposedChart
        width={650}
        height={200}
        data={data}
        // margin={{top: 20, right: 20, bottom: 20, left: 20}}
      >
        <XAxis dataKey="code" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="percentage" barSize={40}>
          {' '}
          {/*fill="#413ea0"*/}
          {data.map((dataObj, index) => {
            const color = getBarColor(Number(dataObj.percentage))
            return <Cell key={index} fill={color} />
          })}
        </Bar>
        <Line type="monotone" dataKey="sectionAverage" stroke="#ff7300" />
        <Line type="monotone" dataKey="classAverage" stroke="#8884d8" />
      </ComposedChart>
      <div className="legend">
        <span className="legend__item">
          <span className="box box--r" />{' '}
          <span>
            0 - 30
            {/* background: #ff3838; */}
            {/* 'rgba(254, 56, 36, 1)' */}
          </span>
        </span>
        <span className="legend__item">
          <span className="box box--o" />{' '}
          <span>
            31 - 60
            {/* 'rgba(255, 150, 0, 1)' */}
          </span>
        </span>
        <span className="legend__item">
          <span className="box box--p" />{' '}
          <span>
            61 - 74
            {/* 'rgba(102, 51, 153, 1)' */}
          </span>
        </span>
        <span className="legend__item">
          <span className="box box--b" />{' '}
          <span>
            75 - 90
            {/* 'rgba(84, 199, 252, 1)' */}
          </span>
        </span>
        <span className="legend__item">
          <span className="box box--g" />{' '}
          <span>
            91 - 100
            {/* 'rgba(68, 219, 94, 1)' */}
          </span>
        </span>
        <span className="legend__item">
          {' '}
          <span className="box--stroke--o">-o-</span>
          <span>
            Section Average
            {/* 'rgba(68, 219, 94, 1)' */}
          </span>
        </span>
        <span className="legend__item">
          {' '}
          <span className="box--stroke--b">-o-</span>
          <span>
            Class Average
            {/* 'rgba(68, 219, 94, 1)' */}
          </span>
        </span>
      </div>
    </div>
  )
}

SingleTestPerformanceMarks.propTypes = {
  getBarColor: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}
export default SingleTestPerformanceMarks
