import {
  APP_LOGOUT,
  GET_OTP_START,
  GET_OTP_FAIL,
  GET_OTP,
  VERIFY_OTP_START,
  VERIFY_OTP_FAIL,
  VERIFY_OTP,
  RESET_PASSWORD_STARTS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD
} from '../helpers/actions.js'

const initialState = {
  isLoadingOtp: false,
  isLoadingVerifyOtp: false,
  isLoadingResetPassword: false,
  userDetails: [],
  isErr: false
}

export const forgotPassword = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_OTP_START:
      return Object.assign({}, state, {
        isLoadingOtp: true,
        isErr: false
      })
    case GET_OTP_FAIL:
      return Object.assign({}, state, {
        isLoadingOtp: false,
        isErr: true
      })
    case GET_OTP:
      return Object.assign({}, state, {
        isLoadingOtp: false,
        isErr: false
      })
    case VERIFY_OTP_START:
      return Object.assign({}, state, {
        isLoadingVerifyOtp: true,
        isErr: false
      })
    case VERIFY_OTP_FAIL:
      return Object.assign({}, state, {
        isLoadingVerifyOtp: false,
        isErr: true
      })
    case VERIFY_OTP:
      return Object.assign({}, state, {
        isLoadingVerifyOtp: false,
        userDetails: action.data,
        isErr: false
      })
    case RESET_PASSWORD_STARTS:
      return Object.assign({}, state, {
        isLoadingResetPassword: true,
        isErr: false
      })
    case RESET_PASSWORD_FAIL:
      return Object.assign({}, state, {
        isLoadingResetPassword: false,
        isErr: true
      })
    case RESET_PASSWORD:
      return Object.assign({}, state, {
        isLoadingResetPassword: false,
        isErr: false
      })
    default:
      return state
  }
}

export const getIsLoadingOtp = state => state.forgotPassword.isLoadingOtp
export const getIsLoadingVerifyOtp = state =>
  state.forgotPassword.isLoadingVerifyOtp
export const getIsLoadingResetPassword = state =>
  state.forgotPassword.isLoadingResetPassword
export const getUserDetails = state => state.forgotPassword.userDetails
export const getIsErr = state => state.forgotPassword.isErr
