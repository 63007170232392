import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'

const GroupInfo = ({ umbrellaGroup, groupName, subject, test, clickedGo }) => {
  if (clickedGo) {
    return (
      <div className="date-and-class">
        <FontAwesome name="pencil" />
        <span>{` ${umbrellaGroup} - ${groupName} / ${subject} / ${test} `}</span>
      </div>
    )
  }
  return <div className="date-and-class">Waiting for Class and Section</div>
}

GroupInfo.propTypes = {
  umbrellaGroup: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  test: PropTypes.string.isRequired,
  clickedGo: PropTypes.bool.isRequired
}

export default GroupInfo
