import {
  APP_LOGOUT,
  POST_TEACHERS_ATTENDANCE_START,
  POST_TEACHERS_ATTENDANCE_FAIL,
  POST_TEACHERS_ATTENDANCE_SUCCESS,
  GET_TEACHERS_ATTENDANCE_START,
  GET_TEACHERS_ATTENDANCE_FAIL,
  GET_TEACHERS_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE_FOR_TEACHER_START,
  GET_ATTENDANCE_FOR_TEACHER_SUCCESS,
  GET_ATTENDANCE_FOR_TEACHER_FAIL,
  GET_MONTHLY_TEACHERS_ATTENDANCE_START,
  GET_MONTHLY_TEACHERS_ATTENDANCE_SUCCESS,
  GET_MONTHLY_TEACHERS_ATTENDANCE_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  errMsg: '',
  attendances: [],
  status: ''
}

export const teacherAttendance = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_TEACHERS_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case POST_TEACHERS_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'posted'
      })
    case POST_TEACHERS_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case GET_TEACHERS_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_TEACHERS_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        attendances: action.data.employee_attendances,
        status: 'fetched'
      })
    case GET_TEACHERS_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_ATTENDANCE_FOR_TEACHER_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_ATTENDANCE_FOR_TEACHER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        attendances: action.data,
        status: 'fetched'
      })
    case GET_ATTENDANCE_FOR_TEACHER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_MONTHLY_TEACHERS_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_MONTHLY_TEACHERS_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        attendances: action.data,
        status: 'fetched'
      })
    case GET_MONTHLY_TEACHERS_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.teacherAttendance.isLoading
export const getIsError = state => state.teacherAttendance.isErr
export const getErrMsg = state => state.teacherAttendance.errMsg
export const getTeacherAttendances = state =>
  state.teacherAttendance.attendances
export const getStatus = state => state.teacherAttendance.status
