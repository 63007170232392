import { getAcademicGroups } from '../groups'
import { testsById } from '../tests'
import { byId as subjectsById } from '../subjects'
import { formValueSelector } from 'redux-form'
import { NEWSUBTEST_FORM_NAME } from '../../helpers/enums'
import { createSelector } from 'reselect'
import underscore from 'underscore'
import { isLoading as isGroupsLoading } from '../groups'
import { isLoading as isTestsLoading } from '../tests'
import { getIsLoading as isSubjectsLoading } from '../subjects'

const newSubTestSelector = formValueSelector(NEWSUBTEST_FORM_NAME)

const getSelectedClass = state => newSubTestSelector(state, 'grade')

export const getTestIdsPerClass = createSelector(
  getAcademicGroups,
  getSelectedClass,
  (sections, grade) => {
    return underscore.uniq(
      sections
        .filter(section => section.parent_group === grade)
        .map(section => section.tests)
        .reduce((acc, val) => acc.concat(val), []) //flatten
    )
  }
)

export const getSubjectIdsPerClass = createSelector(
  getAcademicGroups,
  getSelectedClass,
  (sections, grade) => {
    if (!grade || sections.length === 0) return []
    const subjectIds = []
    sections
      .filter(section => section.parent_group === grade)
      .forEach(section => {
        section.subjects.forEach(subjectObj => {
          subjectIds.push(subjectObj.subject_id)
        })
      })
    return underscore.uniq(subjectIds)
  }
)

export const getTests = createSelector(
  getTestIdsPerClass,
  testsById,
  isGroupsLoading,
  isTestsLoading,
  (testIds, allTests, groupsLoading, testsLoading) => {
    if (groupsLoading || testsLoading) return []
    return testIds
      .map(test =>
        Object.assign({}, { id: test, name: 'empty', order: 0 }, allTests[test])
      )
      .sort((a, b) => a.order - b.order)
  }
)

const getSelectedTest = state => newSubTestSelector(state, 'test')

export const getSubjects = createSelector(
  testsById,
  getSelectedTest,
  subjectsById,
  isSubjectsLoading,
  isTestsLoading,
  (tests, selectedTest, allSubjects, subjectsLoading, testsLoading) => {
    if (subjectsLoading || testsLoading) return []
    return tests[selectedTest].subjects
      .map(item => item.subject) //extract only subject IDs into an array
      .map(subject =>
        Object.assign(
          {},
          { id: subject, name: 'empty', order: 0 },
          allSubjects[subject]
        )
      )
      .filter(subject => subject.is_main)
  }
)

const getSelectedSubject = state => newSubTestSelector(state, 'subject')

export const getElectives = createSelector(
  subjectsById,
  getSelectedSubject,
  (allSubjects, subject) => {
    if (!subject) return []
    const subObj = allSubjects[subject]
    return subObj.electives
      .map(elective =>
        Object.assign(
          {},
          { id: elective, name: 'empty', order: 0 },
          allSubjects[elective]
        )
      )
      .sort((a, b) => a.order - b.order)
  }
)
