import React, { Component } from 'react'

class TableBody extends Component {
  render() {
    const { data, tableHeaders } = this.props
    return (
      <tbody className="table-body">
        {data.map((studentObj, index) => {
          return (
            <tr key={index} className="table-row">
              {tableHeaders.map((key, index) => {
                return (
                  <td className="table-row__cell" key={index}>
                    {studentObj[key]}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    )
  }
}

export default TableBody
