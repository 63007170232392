import { combineReducers } from 'redux'
import { attendance } from './attendance'
import { marks } from './marks'
import { classProfile } from './classProfile'
import { timetable } from './timetable'
import { studentProfile } from './studentProfile'
import { editGroup } from './editGroup'
import { marksReport } from './marksReport'
import { application } from './application'
import { studentFees } from './studentFees'
import { gallery } from './gallery'
import { documentUpload } from './documentUpload'
import { questionpaper } from './questionPaper'
import { smsTemplateData } from './smsTemplateData'
import { worksheet } from './worksheet'
import { notification } from './notification'

export const ui = combineReducers({
  attendance,
  marks,
  classProfile,
  timetable,
  studentProfile,
  editGroup,
  marksReport,
  application,
  studentFees,
  gallery,
  documentUpload,
  questionpaper,
  smsTemplateData,
  worksheet,
  notification
})
