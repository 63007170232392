import {
  GET_FEE_TYPES_START,
  GET_FEE_TYPES,
  GET_FEE_TYPES_FAIL,
  POST_FEE_TYPES_START,
  POST_FEE_TYPES,
  POST_FEE_TYPES_FAIL,
  PATCH_FEE_TYPES_START,
  PATCH_FEE_TYPES,
  PATCH_FEE_TYPES_FAIL,
  DELETE_FEE_TYPES_START,
  DELETE_FEE_TYPES,
  DELETE_FEE_TYPES_FAIL
} from '../helpers/actions'
import { emitAuthFailed } from './login.js'

import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  getById,
  getAllIds,
  errorToast,
  successToast
} from '../helpers/utils.js'

//get all fees types
const emitGetFeeTypesStart = () => ({
  type: GET_FEE_TYPES_START
})

const emitGetFeeTypes = data => ({
  type: GET_FEE_TYPES,
  data
})

const emitGetFeeTypesFail = () => ({
  type: GET_FEE_TYPES_FAIL
})

const getAllFeeTypes = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/fee-types'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGetAllFeeTypes = data => {
  return function(dispatch) {
    dispatch(emitGetFeeTypesStart())
    return getAllFeeTypes(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitGetFeeTypes(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitGetFeeTypesFail())
      })
  }
}

//post all fees types
const emitPostFeeTypesStart = () => ({
  type: POST_FEE_TYPES_START
})

const emitPostFeeTypes = data => ({
  type: POST_FEE_TYPES,
  data
})

const emitPostFeeTypesFail = () => ({
  type: POST_FEE_TYPES_FAIL
})

const postFeeTypes = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/fee-types'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPostFeeTypes = data => {
  return function(dispatch) {
    dispatch(emitPostFeeTypesStart())
    return postFeeTypes(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostFeeTypes(json.data))
        successToast('Fee type added.')
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFeeTypesFail())
        errorToast('Unable to add. Try Again!')
      })
  }
}

//patch all fees types
const emitPatchFeeTypesStart = () => ({
  type: PATCH_FEE_TYPES_START
})

const emitPatchFeeTypes = data => ({
  type: PATCH_FEE_TYPES,
  data
})

const emitPatchFeeTypesFail = () => ({
  type: PATCH_FEE_TYPES_FAIL
})

const patchFeeTypes = ({ jwt, data, id }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/fee-types/${id}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPatchFeeTypes = data => {
  return function(dispatch) {
    dispatch(emitPatchFeeTypesStart())
    return patchFeeTypes(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPatchFeeTypes(json.data))
        successToast('Updated successfully.')
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPatchFeeTypesFail())
        errorToast('Unable to update. Try Again!')
      })
  }
}

//delete all fees types
const emitDeleteFeeTypesStart = () => ({
  type: DELETE_FEE_TYPES_START
})

const emitDeleteFeeTypes = data => ({
  type: DELETE_FEE_TYPES,
  data
})

const emitDeleteFeeTypesFail = () => ({
  type: DELETE_FEE_TYPES_FAIL
})

const deleteFeeTypes = ({ jwt, data, id }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/fee-types/${id}`
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startDeleteFeeTypes = data => {
  return function(dispatch) {
    dispatch(emitDeleteFeeTypesStart())
    return deleteFeeTypes(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitDeleteFeeTypes(json.data))
        successToast('Fee type removed.')
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitDeleteFeeTypesFail())
        errorToast('Unable to remove. Try Again!')
      })
  }
}
