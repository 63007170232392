// import packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import selectors
import { byId as subjectsById } from '../../reducers/subjects'
import { getTimeTableByHour } from '../../reducers/timetable'
import { teachersById } from '../../reducers/teacher'

class TimetableContainer extends Component {
  static propTypes = {
    timetableByHour: PropTypes.object.isRequired,
    subjectsById: PropTypes.object.isRequired,
    teachersById: PropTypes.object.isRequired
  }
  getTableHead(hourArr) {
    return (
      <thead className="time-table-head">
        <tr className="time-table-row">
          {hourArr.map(hourObj => (
            <th
              key={hourObj._id}
              className="time-table__cell time-table__cell--th time-table__cell--blue"
            >
              {hourObj.start_time}
            </th>
          ))}
        </tr>
      </thead>
    )
  }

  getTableBody(hourArr) {
    const { timetableByHour, subjectsById, teachersById } = this.props
    return (
      <tbody>
        <tr className="time-table-row">
          {hourArr.map(hourObj => {
            const { _id: hourId } = hourObj
            const timetableHourObj = timetableByHour[hourId]
            const { details } = Object.assign({ details: [] }, timetableHourObj)
            return (
              <td key={hourId} className="time-table__cell">
                {details.length !== 0
                  ? details.map((detailObj, index) => {
                      const { subject, teacher } = Object.assign(
                        { subject: '', teacher: '' },
                        detailObj
                      )
                      const { name: subjectName } = Object.assign(
                        { name: '' },
                        subjectsById[subject]
                      )
                      const { name: teacherName } = Object.assign(
                        { name: '' },
                        teachersById[teacher]
                      )
                      return (
                        <div key={index}>
                          <p>{subjectName}</p>
                          <p>{teacherName}</p>
                          <hr />
                        </div>
                      )
                    })
                  : ''}
              </td>
            )
          })}
        </tr>
      </tbody>
    )
  }
  render() {
    const { hourArr } = this.props
    return (
      <div>
        <table className="table time-table">
          {this.getTableHead(hourArr)}
          {this.getTableBody(hourArr)}
        </table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  timetableByHour: getTimeTableByHour(state),
  subjectsById: subjectsById(state),
  teachersById: teachersById(state)
})

const Timetable = connect(mapStateToProps)(TimetableContainer)

export default Timetable
