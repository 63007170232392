import { createSelector } from 'reselect'

import {
  APP_LOGOUT,
  ADMISSION_GROUPS_DATA,
  UPDATE_ADMISSION_GROUP,
  START_FETCH_ADMISSION_GROUPS,
  FETCH_ADMISSION_GROUPS_FAIL
} from '../helpers/actions'
import { GROUPS_GROUP_TYPE } from '../helpers/enums'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  err: ''
}

export const admissionGroups = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_ADMISSION_GROUPS:
      return Object.assign({}, state, {
        isLoading: true,
        err: ''
      })
    case ADMISSION_GROUPS_DATA:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false
      })
    case FETCH_ADMISSION_GROUPS_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        err: action.err
      })
    case UPDATE_ADMISSION_GROUP: {
      const byId = state.byId
      const group = action.group
      return Object.assign({}, state, {
        byId: {
          ...byId,
          [group._id]: group
        }
      })
    }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const isLoading = state => state.admissionGroups.isLoading
export const getById = state => state.admissionGroups.byId
export const getAllIds = state => state.admissionGroups.allIds

export const getAdmissionGroups = createSelector(
  getById,
  getAllIds,
  (byId, groups) => {
    return groups
      .filter(group => {
        const { group_type, archive } = byId[group]
        if (GROUPS_GROUP_TYPE[4] === group_type && archive === false)
          return true
        return false
      })
      .map(group => {
        const { _id, group_name, umbrella_group, group_type, students } = byId[
          group
        ]
        return {
          _id,
          group_name,
          parent_group: umbrella_group,
          group_type,
          students
        }
      })
  }
)

export const getAdmissionUmbrellaGroups = createSelector(
  getAdmissionGroups,
  admissionGroupArr => {
    if (admissionGroupArr.length === 0) return []
    return admissionGroupArr.map(groupObj => groupObj.parent_group)
  }
)
