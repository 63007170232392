import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StudentLink from '../common/studentLink'
import moment from 'moment'
import classnames from 'classnames'
//import enums
import { STUDENT_LIST_CAPITALIZED_COLUMNS } from '../../helpers/enums'

class TableBody extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    tableHeaders: PropTypes.array.isRequired
  }

  renderCell(studentObj, header, serialNumber) {
    const value = studentObj[header]
    switch (header) {
      case 'date_of_birth':
      case 'joining_date':
        const resDate = moment(value)
        return resDate.isValid() ? resDate.format('DD/MMM/YYYY') : ''
      case 'serial_number':
        return serialNumber
      case 'reasonOfArchive':
        return value.map(item => {
          return <p>{item}</p>
        })
      case 'name':
        return (
          <StudentLink studentId={studentObj._id}>
            {studentObj.name}
          </StudentLink>
        )
      default:
        return value
    }
  }

  render() {
    const { data, tableHeaders } = this.props
    console.log('data inside tableBody', data)
    return (
      <tbody className="table-body">
        {data.map((studentObj, index) => (
          <tr key={studentObj._id} className="table-row">
            {tableHeaders.map(header => (
              <td
                className={classnames({
                  'table-row__cell': true,
                  'text-capitalize': STUDENT_LIST_CAPITALIZED_COLUMNS.includes(
                    header
                  )
                })}
                key={header}
              >
                {this.renderCell(studentObj, header, index + 1)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    )
  }
}

export default TableBody
