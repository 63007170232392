import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//selectors
import { isLoading as groupsLoading } from '../../reducers/groups'
import { isLoading as testsLoading } from '../../reducers/tests'
import { getTests } from '../../reducers/ui/newSubTest'
import Spinner from '../spinner'
import { ReduxSelectWithId } from './inputs'

const mapStateToProps = state => ({
  tests: getTests(state),
  ready: !groupsLoading(state) && !testsLoading(state)
})

class SelectSingleTestFormComponent extends Component {
  static propTypes = {
    ready: PropTypes.bool.isRequired,
    tests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired
      })
    ).isRequired
  }

  render() {
    const { ready, tests } = this.props
    console.log('SelectSingleTestForm - tests: ', tests)
    if (ready)
      return (
        <ReduxSelectWithId
          name="test"
          options={tests}
          placeholder="Select Test"
        />
      )
    return <Spinner />
  }
}

const SelectSingleTestForm = connect(mapStateToProps)(
  SelectSingleTestFormComponent
)

export default SelectSingleTestForm
