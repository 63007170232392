import React from 'react'
import PropTypes from 'prop-types'

import { TextInput } from '../inputs.jsx'

const SearchBar = ({
  handleChange,
  /*TODO see if this state can be moved into the input field*/
  active,
  text = 'search: waiting for date and class',
  placeholderText = 'Search By Name, Admission Or Roll Number',
  value
}) => (
  <div>
    <TextInput
      name="search"
      placeholder={active ? placeholderText : text}
      handleChange={handleChange}
      disabled={!active}
      value={value}
    />
  </div>
)

SearchBar.propTypes = {
  handleChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
}

export default SearchBar
