// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'

//import {Modal} from 'react-bootstrap'
import { connect } from 'react-redux'
import { PageHelmet } from '../common'
import underscore from 'underscore'
// import actions
import Spinner from '../spinner'

import {
  startFetchGroups,
  startFetchGroupsForTeacher
} from '../../actions/groups'
import { startFetchTests } from '../../actions/tests.js'
import {
  startFetchMarksForTestSubj,
  startFetchReports
} from '../../actions/marks'
import { startFetchSubjects } from '../../actions/subjects.js'
import { startFetchData } from '../../actions/students.js'
import { saveData } from '../../actions/ui/marksReport'
// import selectors
import { getLevel, getLevelId } from '../../reducers/accounts'
import { testsById, isLoading as isTestsLoading } from '../../reducers/tests.js'
import * as Subjects from '../../reducers/subjects.js'
import * as Marks from '../../reducers/marks.js'
import {
  getAcademicGroups,
  isLoading as isGroupsLoading,
  getTeacherGroupsById,
  getAcademicTeacherGroups
} from '../../reducers/groups.js'
import { getUiMarksReportData } from '../../reducers/ui/marksReport'
import { getLoading as isStudentsLoading } from '../../reducers/students'
import StudentStore from '../../reducers/flattenedStudents'
import { getInstitutionData, getAcademicYear } from '../../reducers/institution'
// import components
import MarksReportList from './marksReportList'
import {
  uniqueParentGroups,
  filterSectionsByParent
} from '../../helpers/utils.js'
//import {TestReportRender} from './testReportRender'
import { GroupSelector } from './groupSelector.jsx'
import GroupInfo from './groupInfo'
import {
  getParentSubjectOfAnElective,
  downloadReport
} from '../../helpers/utils'
import { Object } from 'es6-shim'

class MarksReportComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    marksReady: PropTypes.bool.isRequired,
    groups: PropTypes.object.isRequired,
    academicGroups: PropTypes.array.isRequired,
    tests: PropTypes.object.isRequired,
    subjects: PropTypes.array.isRequired,
    studentDataWithMarks: PropTypes.array.isRequired,
    //functions
    fetchGroups: PropTypes.func.isRequired,
    fetchTests: PropTypes.func.isRequired,
    fetchSubjectsMarksStudentsAndReport: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      parent_group: '',
      groupId: '',
      testId: '',
      goClicked: false,
      search: '',
      isDirty: false,
      order: { roll_number: false },
      subjectId: '',
      headerId: 'roll_number',
      fetchData: false
    }
    this.setSection = this.setSection.bind(this)
    this.handleParentChange = this.handleParentChange.bind(this)
    this.handleTestChange = this.handleTestChange.bind(this)
    this.handleGo = this.handleGo.bind(this)
    this.filterTestsByGroup = this.filterTestsByGroup.bind(this)
    this.filterStudents = this.filterStudents.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.active = this.active.bind(this)
    this.handleGroupBy = this.handleGroupBy.bind(this)
    this.getSortIcon = this.getSortIcon.bind(this)
    this.getToolTip = this.getToolTip.bind(this)
  }

  componentDidMount() {
    // fetch groups
    const { jwt, fetchGroups, fetchTeacherGroups, level } = this.props
    let data = {
      jwt,
      type: 'academics'
    }
    if (level !== 'teacher') {
      fetchGroups(data)
    } else {
      fetchTeacherGroups(data)
    }
    this.setState({
      fetchData: true
    })
  }

  componentDidUpdate() {
    const {
      jwt,
      groupsLoading,
      groups,
      tests,
      groupSelectorData,
      fetchTests,
      fetchSubjectsMarksStudentsAndReport
    } = this.props
    const { fetchData } = this.state
    const { umbrellaGroup, groupId, testId } = groupSelectorData
    if (!groupsLoading && umbrellaGroup && groupId && testId && fetchData) {
      const { subjects, students } = Object.assign(
        { subjects: [], students: [] },
        groups[groupId]
      )
      const subjectIds = subjects.map(subObj => subObj.subject_id)
      const order = {}
      subjectIds.forEach(subjectId => (order[subjectId] = false))
      order.roll_number = false
      order.name = false
      order.total = false
      order.classPosition = false
      fetchTests({ jwt, tests: groups[groupId].tests })
      if (testId && Boolean(tests[testId])) {
        //  if (!(Boolean(tests[testId]) && tests[testId].order === 4)) {
        fetchSubjectsMarksStudentsAndReport(
          jwt,
          students,
          subjectIds,
          testId,
          groupId
        )
        // }
        // else {
        //   const testIds = Object.keys(this.props.tests)
        //     .filter(test => this.props.tests[test].percentage === 50)
        //     .map(testId => this.props.tests[testId]._id)
        //   fetchSubjectsMarksStudentsAndReport(
        //     jwt,
        //     groups[groupId].students,
        //     subjectIds,
        //     testIds,
        //     groupId
        //   )
        // }
      }

      this.setState({
        order,
        parent_group: umbrellaGroup,
        groupId,
        testId,
        goClicked: true,
        isDirty: false,
        fetchData: false
      })
    }
  }

  getSubjectData() {
    const { subjectsById, groups, tests } = this.props
    const { groupId, testId } = this.state
    const testSubject = tests[testId].subjects.map(sub => sub.subject)
    const groupSubject = groups[groupId].subjects.map(
      subjectObj => subjectObj.subject_id
    )
    const intersectionList = underscore.intersection(testSubject, groupSubject)
    return intersectionList
      .map(subjectId => {
        let obj = subjectsById[subjectId]
        if (obj.umbrellaName) {
          obj['mainOrder'] = obj.umbrellaOrder
          obj['subjectOrder'] = obj.order
        } else {
          obj['mainOrder'] = obj.order
        }
        return obj
      })
      .filter(subObj => subObj.is_main === true)
      .sort((a, b) => {
        const returnValue = a.mainOrder - b.mainOrder
        if (returnValue === 0) return a.subjectOrder - b.subjectOrder
        return returnValue
      })
  }

  handleGo(e) {
    e.preventDefault()
    const {
      jwt,
      groups,
      fetchSubjectsMarksStudentsAndReport,
      storeGroupSelectorData,
      tests
    } = this.props
    const { parent_group, groupId, testId } = this.state
    const subjectIds = groups[groupId].subjects.map(subObj => subObj.subject_id)
    const order = {}
    subjectIds.forEach(subjectId => (order[subjectId] = false))
    order.roll_number = false
    order.name = false
    order.total = false
    order.classPosition = false
    storeGroupSelectorData({
      umbrellaGroup: parent_group,
      groupId,
      testId
    })
    if (testId && Boolean(tests[testId])) {
      //if (!(Boolean(tests[testId]) && tests[testId].order === 4)) {
      fetchSubjectsMarksStudentsAndReport(
        jwt,
        groups[groupId].students,
        subjectIds,
        testId,
        groupId
      )
      //}
      //  else {
      //   const testIds = groups[groupId].tests
      //     .filter(test => this.props.tests[test].percentage === 50)
      //     .map(testId => this.props.tests[testId]._id)

      //   fetchSubjectsMarksStudentsAndReport(
      //     jwt,
      //     groups[groupId].students,
      //     subjectIds,
      //     testIds,
      //     groupId
      //   )
      // }
    }

    this.setState({
      order,
      goClicked: true,
      isDirty: false,
      fetchData: false,
      search: ''
    })
  }

  active() {
    return this.state.goClicked
  }

  handleParentChange(e) {
    this.setState({
      parent_group: e.target.value,
      groupId: '',
      testId: '',
      goClicked: false,
      isDirty: false
    })
  }

  handleTestChange(e) {
    this.setState({
      testId: e.target.value,
      goClicked: false,
      isDirty: true
    })
  }

  setSection(e) {
    const { name, value } = e.target
    const { jwt, groups, fetchTests } = this.props
    this.setState(
      {
        [name]: value,
        testId: '',
        goClicked: false,
        isDirty: false
      },
      fetchTests({
        jwt,
        tests: groups[value].tests
      })
    )
  }

  filterTestsByGroup() {
    const { tests, groups, testsLoading } = this.props
    const { groupId } = this.state
    if (testsLoading || Object.keys(tests).length === 0) return []
    let arrTests = []

    if (groupId && tests !== undefined) {
      if (Object.keys(tests).length) arrTests = groups[groupId].tests
    }

    const notAllGroupTestsPresent = arrTests.some(
      testId => Object.keys(tests).indexOf(testId) === -1
    )
    if (notAllGroupTestsPresent) return []

    return arrTests
      .map(testId => ({
        id: tests[testId]._id,
        name: tests[testId].name,
        order: tests[testId].order
      }))
      .sort((a, b) => a.order - b.order)
  }

  filterStudents() {
    const { studentArr, groups } = this.props
    const { search, headerId, subjectId, order, groupId } = this.state
    if (studentArr.length === 0 || Object.keys(groups).length === 0) return []
    const studentIds = groups[groupId].students
    let studentDataWithMarksPerGroup = studentArr.filter(
      obj => studentIds.indexOf(obj._id) !== -1
    )
    let studentData = studentDataWithMarksPerGroup
    if (subjectId !== '') {
      if (order[subjectId]) {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj.marks[subjectId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => b.marks[subjectId].mark - a.marks[subjectId].mark
        )
        studentData = sorted.concat(empty)
      } else {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj.marks[subjectId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => a.marks[subjectId].mark - b.marks[subjectId].mark
        )
        studentData = sorted.concat(empty)
      }
    }

    if (headerId === 'name') {
      if (order[headerId]) {
        studentData = studentDataWithMarksPerGroup.sort((a, b) => {
          if (a.name < b.name) return 1
          return -1
        })
      } else {
        studentData = studentDataWithMarksPerGroup.sort((a, b) => {
          if (a.name < b.name) return -1
          return 1
        })
      }
    }

    if (headerId !== '' && headerId !== 'name') {
      if (order[headerId]) {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj[headerId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => Number(b[headerId]) - Number(a[headerId])
        )
        studentData = sorted.concat(empty)
      } else {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj[headerId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => Number(a[headerId]) - Number(b[headerId])
        )
        studentData = sorted.concat(empty)
      }
    }

    if (search.length === 0) return studentData

    const filterAcross = (fields = []) => {
      return student => {
        return fields.some(field => {
          return -1 !== student[field].toLowerCase().indexOf(search)
        })
      }
    }

    return studentData.filter(filterAcross(['roll_number', 'name']))
  }

  setSearch(e) {
    e.preventDefault()
    let obj = {}
    obj[e.target.name] = e.target.value.toLowerCase()
    this.setState(obj)
  }

  handleGroupBy(e) {
    const { subjects } = this.props
    const { order } = this.state
    const len = subjects.filter(subjectObj => subjectObj._id === e.target.id)
      .length
    if (len !== 0) {
      this.setState({
        ...this.state,
        order: {
          ...order,
          [e.target.id]: !order[e.target.id]
        },
        subjectId: e.target.id,
        headerId: ''
      })
    } else {
      this.setState({
        ...this.state,
        order: {
          ...order,
          [e.target.id]: !order[e.target.id]
        },
        headerId: e.target.id,
        subjectId: ''
      })
    }
  }

  getSortIcon(header) {
    const { order } = this.state
    if (order[header]) return 'sort-amount-desc'
    return 'sort-amount-asc'
  }

  getToolTip(header) {
    const { order } = this.state
    if (order[header]) return 'sort ascending'
    return 'sort descending'
  }

  isReady() {
    const {
      groupsLoading,
      studentsLoading,
      subjectsLoading,
      marksLoading,
      testsLoading
    } = this.props
    if (
      groupsLoading ||
      studentsLoading ||
      subjectsLoading ||
      marksLoading ||
      testsLoading
    ) {
      return false
    }
    return true
  }

  getTableBody = () => {
    const {
      marksPerStudentByTestId,
      subjectsById,
      subjects: mainSubjectArr,
      groups,
      reportbyStudentId
    } = this.props
    const { testId, groupId } = this.state
    let studentData = this.filterStudents()
    return studentData.map(studentObj => {
      const studentMarkWithTest = Object.assign(
        { [testId]: {} },
        marksPerStudentByTestId[studentObj._id]
      )
      const studentMarkObjBySubjectId = studentMarkWithTest[testId]
      // assign a marks attribute for each student
      studentObj['marks'] = {}
      let totalMarks = 0
      let totalMaxMarks = 0
      Object.keys(studentMarkObjBySubjectId).forEach(subjectId => {
        const { is_main, code: subjectCode } = Object.assign(
          { is_main: true },
          subjectsById[subjectId]
        )
        const { subjects } = Object.assign({ subjects: [] }, groups[groupId])
        let subjectObj = subjects.find(
          subObj => subObj.subject_id === subjectId
        )
        // Assign marks only if the student has been assigned to the elective
        if (subjectObj.student_id.includes(studentObj._id)) {
          let subject =
            is_main === true
              ? subjectId
              : getParentSubjectOfAnElective(mainSubjectArr, subjectId)
          const studentMarkObj = studentMarkObjBySubjectId[subjectId]
          studentObj['marks'][subject] = studentMarkObj
          if (is_main === false) {
            studentObj['marks'][subject]['elective'] = subjectCode
          }
          const { totalCalculation } = Object.assign(
            { totalCalculation: true },
            subjectsById[subject]
          )
          // add total marks and max marks only id totalCalculation is true
          if (studentMarkObj.mark && totalCalculation) {
            totalMarks += studentMarkObj.mark
          }
          if (studentMarkObj.maxMarks && totalCalculation) {
            totalMaxMarks += studentMarkObj.maxMarks
          }
        }
      })

      // derive total marks and percentage and add it as attributes to student data
      studentObj['total'] = totalMarks
      studentObj['totalMaxMarks'] = totalMaxMarks
      studentObj['percentage'] = this.getPercentage(totalMarks, totalMaxMarks)
      // assign class and section position
      const reportObj = reportbyStudentId[studentObj._id]
      const { classPosition, sectionPosition } = Object.assign(
        { classPosition: '', sectionPosition: '' },
        reportObj
      )
      studentObj['classPosition'] = classPosition
      studentObj['sectionPosition'] = sectionPosition
      return studentObj
    })
  }

  getPercentage = (totalMarks, totalMaxMarks) => {
    return Number(Math.round((totalMarks / totalMaxMarks) * 100))
  }
  shapeFinalExamData = () => {
    const {
      marksPerStudentByTestId,
      subjectsById,
      subjects: mainSubjectArr,
      institutionData,
      academicYear,
      tests,
      groups
    } = this.props
    const { parent_group, groupId, testId } = this.state
    const { academic_year, group_name, umbrella_group } = groups[groupId]
    let studentData = this.filterStudents()
    const testIds = groups[groupId].tests
      .filter(test => tests[test].percentage === 50)
      .map(testId => tests[testId]._id)

    const arr = []
    studentData.forEach(studentObj => {
      const studentMarkWithTest = Object.assign(
        { [testId]: {} },
        marksPerStudentByTestId[studentObj._id]
      )
      const obj = {}
      testIds.forEach((testId, index) => {
        const { percentage } = Object.assign(
          {},
          {
            percentage: 0
          },
          tests[testId]
        )
        let totalMarks = 0
        let totalMaxMarks = 0
        const studentMarkObjBySubjectId = studentMarkWithTest[testId]
        // assign a marks attribute for each student
        studentObj['marks'] = {}
        if (Boolean(studentMarkObjBySubjectId)) {
          Object.keys(studentMarkObjBySubjectId).forEach(subj => {
            if (subjectsById[subj].compulsoryForTotal) {
              const { mark, maxMarks } = studentMarkObjBySubjectId[subj]

              totalMarks = totalMarks + mark
              totalMaxMarks = totalMaxMarks + maxMarks
            }
          })
        }
        obj[`totalMarks${index}`] = totalMarks
        obj[`totalMaxMarks${index}`] = totalMaxMarks
        obj[`percent${index}`] = (
          (totalMarks / totalMaxMarks) *
          percentage
        ).toFixed(2)
      })
      const yearlyTotalMarks =
        Number(obj['totalMarks0']) + Number(obj['totalMarks1'])
      const yearlyTotalMaxMarks =
        Number(obj['totalMaxMarks0']) + Number(obj['totalMaxMarks1'])

      obj['totalMarks'] = yearlyTotalMarks
      obj['yearlyTotalMaxMarks'] = yearlyTotalMaxMarks

      // const percent1 = Boolean(obj['percent0']) ? Number(obj['percent0']) : 0
      // const percent2 = Boolean(obj['percent1']) ? Number(obj['percent1']) : 0
      // console.log(yearlyTotalMarks, 'yearlyTotalMarks')
      // console.log(yearlyTotalMaxMarks, 'yearlyTotalMaxMarks')
      // console.log(studentObj.name, 'name')

      obj['totalPercent'] = (
        (yearlyTotalMarks * 100) /
        yearlyTotalMaxMarks
      ).toFixed(2)
      const newObj = Object.assign({}, studentObj, obj)
      arr.push(newObj)
    })
    const sortByPercent = arr.sort((a, b) => b.totalPercent - a.totalPercent)
    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    const data = {
      title: `${parent_group}-${groups[groupId].group_name}`,
      students: sortByPercent,
      subjects: mainSubjectArr.map(sub => sub.name),
      classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-2019`,
      year: `${academic_year}-2019`,
      class: umbrella_group,
      section: group_name
    }
    data['institutionName'] = institutionName
    data['institutionAddress'] = institutionAddress
    data['class'] = umbrella_group
    data['section'] = group_name
    data['testName'] = tests[testId].name
    const academicYearPlushOne = Number(academicYear) + 1
    data['academicYear'] = `${academicYear} - ${academicYearPlushOne}`
    console.log('data', data)
    downloadReport({
      nameOfTemplate: 'bcgsCumulativeReportClassIToIV',
      dataForTemplate: data,
      downloadedFileName: `performance-report-${umbrella_group}-${group_name}`
    })
  }

  render() {
    const {
      academicGroups,
      tests,
      groups,
      subjectsById,
      institutionData,
      academicYear
    } = this.props
    const { parent_group, groupId, testId, goClicked, isDirty } = this.state
    return (
      <div>
        <div className="attd-container">
          <PageHelmet>Marks Report</PageHelmet>
          <div className="container">
            <div className="row">
              <div className="col-xs-3">
                <div className="tab">
                  <p className="tab__text">Marks Report</p>
                </div>
              </div>
              <div className="col-xs-9">
                <GroupSelector
                  // data
                  umbrellaGroup={parent_group}
                  groupId={groupId}
                  testId={testId}
                  parentNames={uniqueParentGroups(academicGroups)}
                  sections={filterSectionsByParent(
                    parent_group,
                    academicGroups
                  )}
                  tests={this.filterTestsByGroup()}
                  // callbacks
                  handleParentChange={this.handleParentChange}
                  handleTestChange={this.handleTestChange}
                  setSection={this.setSection}
                  handleGo={this.handleGo}
                  isDirty={isDirty}
                />
              </div>
            </div>
          </div>
          <div className="attd-search">
            <div className="container">
              <div className="row">
                <div className="col-xs-6">
                  {goClicked && (
                    <GroupInfo
                      umbrellaGroup={parent_group}
                      groupName={groups[groupId].group_name}
                      test={tests[testId].name}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {goClicked && this.isReady() && (
          <MarksReportList
            finalExamData={this.shapeFinalExamData}
            downloadReportData={{
              studentData: this.getTableBody(),
              subjectData: this.getSubjectData(),
              state: { parent_group, groupId, testId },
              props: {
                groups,
                subjectsById,
                tests,
                institutionData,
                academicYear
              }
            }}
          />
        )}
        {goClicked && !this.isReady() && <Spinner />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const getGroupsObj = () => {
    if (state.accounts.level === 'teacher') {
      return getTeacherGroupsById(state)
    }
    return state.groups.byId
  }
  const getGroupsArr = () => {
    if (state.accounts.level === 'teacher') {
      return getAcademicTeacherGroups(state)
    }
    return getAcademicGroups(state)
  }
  return {
    marksReady: !Subjects.getIsLoading(state) && !Marks.getIsLoading(state),
    isLoading: state.groups.isLoading,
    groups: getGroupsObj(),
    jwt: state.accounts.jwt,
    academicGroups: getGroupsArr(),
    tests: testsById(state),
    subjects: Subjects.getMainSubjects(state),
    subjectsById: Subjects.byId(state),
    groupSelectorData: getUiMarksReportData(state),
    groupsLoading: isGroupsLoading(state),
    testsLoading: isTestsLoading(state),
    marksLoading: Marks.getIsLoading(state),
    subjectsLoading: Subjects.getIsLoading(state),
    studentsLoading: isStudentsLoading(state),
    institutionData: getInstitutionData(state),
    academicYear: getAcademicYear(state),
    level: getLevel(state),
    levelId: getLevelId(state),
    marksPerStudentByTestId: Marks.getMarksPerStudentByTestId(state),
    studentArr: StudentStore.getStudentsSortedByRollNumber(state),
    reportbyStudentId: Marks.getReportByStudentId(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups(data) {
    dispatch(startFetchGroups(data))
  },
  fetchTeacherGroups(data) {
    dispatch(startFetchGroupsForTeacher(data))
  },
  fetchTests(data) {
    dispatch(startFetchTests(data))
  },
  fetchSubjectsMarksStudentsAndReport(
    jwt,
    studentArr,
    subjectArr,
    testId,
    groupId
  ) {
    dispatch(
      startFetchSubjects({
        jwt,
        subjects: subjectArr
      })
    )
    dispatch(
      startFetchMarksForTestSubj({
        jwt,
        testId: Array.isArray(testId) ? testId : [testId],
        subjectArr,
        studentArr
      })
    )
    dispatch(startFetchData({ jwt, students: studentArr }))
    dispatch(
      startFetchReports({
        jwt,
        groupId,
        testIds: Array.isArray(testId) ? testId : [testId],
        type: ['overall']
      })
    )
  },
  storeGroupSelectorData(data) {
    dispatch(saveData(data))
  }
})

export const MarksReportPDF = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarksReportComponent)
