import querystring from 'query-string'
import {
  TESTS_TEST_DATA,
  TESTS_FETCH_START,
  TESTS_FAIL,
  ADD_TEST_START,
  ADD_TEST_SUCCESS,
  EDIT_TEST_SUCCESS,
  EDIT_TEST_FAIL,
  START_FETCH_GRADES,
  EMIT_GRADES_DATA,
  EMIT_GRADES_FAIL,
  FLATTENED_TESTS_TEST_DATA,
  ADD_GRADES_TO_TEST_START,
  ADD_GRADES_TO_TEST,
  ADD_GRADES_TO_TEST_FAIL,
  FETCH_SUB_TEST_START,
  SUB_TEST_DATA,
  FLATTENED_SUB_TEST_DATA,
  EDIT_SUB_TEST_START,
  EDIT_SUB_TEST_SUCCESS,
  ADD_MIN_MARKS_GRADES_FAIL,
  ADD_MIN_MARKS_GRADES_START,
  ADD_MIN_MARKS_GRADES_SUCCESS
} from '../helpers/actions.js'

import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'

import { NEWTEST_FORM_NAME, NEWSUBTEST_FORM_NAME } from '../helpers/enums'
import Groups from './groups'
import { normalize } from 'normalizr'
import { reset } from 'redux-form'

import { testListSchema } from '../schemas/tests'
import { emitAuthFailed } from './login.js'

const Tests = {}

export const emitStart = () => ({
  type: TESTS_FETCH_START
})

export const emitTestData = data => {
  data.sort((a, b) => a.order - b.order)
  const {
    entities: { tests },
    result
  } = normalize(data, testListSchema)

  return {
    type: TESTS_TEST_DATA,
    entities: tests,
    result,
    data: data.sort((a, b) => a.order - b.order)
  }
}

export const emitFlatenedTestData = data => {
  data.sort((a, b) => a.order - b.order)
  const {
    entities: { tests },
    result
  } = normalize(data, testListSchema)

  return {
    type: FLATTENED_TESTS_TEST_DATA,
    entities: tests,
    result
  }
}

export const emitFail = err => ({
  type: TESTS_FAIL,
  err
})

export const addTestStart = () => ({
  type: ADD_TEST_START
})

export const addTestSuccess = () => ({
  type: ADD_TEST_SUCCESS
})

const emitPatchTestData = data => ({
  type: EDIT_TEST_SUCCESS,
  data
})

const emitFailure = err => ({
  type: EDIT_TEST_FAIL,
  message: err
})

const postNewTest = ({ jwt, body }) => {
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/test'
  const { is_main = true } = body
  delete body.is_main
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      ...body,
      is_main
    })
  })
}

const fetchTests = ({ jwt, tests }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/test?' +
    querystring.stringify({ ids: tests })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchTests = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchTests(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitTestData(json.data))
        dispatch(emitFlatenedTestData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err.message))
      })
  }
}
// edit test
// /test/{id}

const patchTest = ({ jwt, test, testId }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/test/${encodeURIComponent(
    testId
  )}`

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(
      test
      //   {
      //   name: test.name,
      //   percentage: test.percentage,
      //   month: test.month,
      //   order: test.order,
      //   term: test.term,
      //   archive: test.archive,
      //   is_main: false,
      //   groupBy: test.groupBy,
      //   template: test.template,
      //   viewTemplate: test.viewTemplate,
      //   bestOf: test.bestOf,
      //   teacher_edit: test.teacher_edit,
      //   finalExam: test.finalExam,
      //   hundredPercent: test.hundredPercent,
      //   separateCalculation: test.separateCalculation,
      //   description: test.description
      // }
    )
  })
}

export const startPatchTests = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return patchTest(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPatchTestData(json.data))
        successToast('Test/SubTest updated successully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitFailure(err))
        errorToast('Test/SubTest update unsuccessful')
      })
  }
}

// Patch remove subjects from test

const removeTestSubjects = ({ jwt, subjects, _id }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/test/${encodeURIComponent(
    _id
  )}/removesubject`

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ subjects })
  })
}

export const startRemoveTestSubjects = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return removeTestSubjects(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPatchTestData(json.data))
        successToast('Test subjects updated successully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitFailure(err))
        errorToast('Test subjects update unsuccessful')
      })
  }
}

/* patch /test/{_id}/addsubject */
export const patchAddSubjectToTests = ({ jwt, subId, arrTests }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/test/addsubject?' +
    querystring.stringify({ _id: arrTests })
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      subject: subId,
      minMarks: 0,
      tests: []
    })
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log(json.data)
      return json.data
    })
    .catch(err => {
      console.error(err)
    })
}

/* patch /test/{_id}/addsubjects */
const patchAddTestsToSubject = (jwt, subId, testId, arrSubTests) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW + '/api/test/' + testId + '/addsubjects'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      subjects: [
        {
          subject: subId,
          minMarks: 0,
          tests: arrSubTests
        }
      ]
    })
  })
}

// new Sub test
Tests.createSubTests = (jwt, body) => {
  const { subtests, test, subject, excistingSubtests } = body
  console.log('test inside createSubTest', test)
  console.log('subject inside createSubTest', subject)
  const arrSubTests = subtests.map(test =>
    postNewTest({ jwt, body: { ...test, is_main: false } })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('json inside createSubTests', json)
        return json.data
      })
  )
  return function(dispatch) {
    dispatch(addTestStart())
    return Promise.all(arrSubTests).then(testData => {
      dispatch(emitTestData(testData))
      const testIds = testData.map(test => test._id).concat(excistingSubtests)
      //console.log('Tests.createSubTests: testIds - ', testIds)
      patchAddTestsToSubject(jwt, subject, test, testIds)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          console.log('Tests.createSubTests: data - ', json.data)
          dispatch(addTestSuccess())
          dispatch(emitPatchTestData(json.data))
          dispatch(reset(NEWSUBTEST_FORM_NAME))
          successToast('Sub-Tests Created')
        })
        .catch(err => {
          errorToast(err)
          console.error(err)
          dispatch(emitFail(err))
          if (err instanceof AuthError) dispatch(emitAuthFailed())
        })
    })
  }
}

Tests.startNewTests = (jwt, arrTests, arrSections) => dispatch => {
  const newTestThunks = arrTests.map(test =>
    postNewTest({ jwt, body: { ...test, is_main: true } })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => json.data)
  )

  dispatch(addTestStart())
  return Promise.all(newTestThunks)
    .then(arrTests => {
      dispatch(emitTestData(arrTests))
      const arrTestIds = arrTests.map(test => test._id)
      return dispatch(
        Groups.startPatchTests(jwt, arrSections, arrTestIds)
      ).then(data => {
        dispatch(addTestSuccess())
        dispatch(reset(NEWTEST_FORM_NAME))
        successToast('Tests Created Successfully')
      })
    })
    .catch(err => {
      dispatch(emitFail(err))
      errorToast('Test not created.')
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

// fetch all grades
const fetchAllGrades = ({ jwt, gradeIds }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/grades'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

Tests.startFetchAllGrades = data => {
  return function(dispatch) {
    dispatch(startFetchGradeData())
    return fetchAllGrades(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('json inside startFetchGrades', json)
        dispatch(emitGradeData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitGradeFail())
      })
  }
}

// fetch grades
const fetchGrades = ({ jwt, gradeIds }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/grades/ids?' +
    querystring.stringify({ ids: gradeIds })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

Tests.startFetchGrades = data => {
  return function(dispatch) {
    dispatch(startFetchGradeData())
    return fetchGrades(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('json inside startFetchGrades', json)
        dispatch(emitGradeData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitGradeFail())
      })
  }
}

const startFetchGradeData = () => ({
  type: START_FETCH_GRADES
})

const emitGradeData = data => {
  const entities = {}
  const result = []
  data.forEach(gradeObj => {
    result.push(gradeObj._id)
    entities[gradeObj._id] = gradeObj
  })
  return {
    type: EMIT_GRADES_DATA,
    entities,
    result
  }
}

const emitGradeFail = () => ({
  type: EMIT_GRADES_FAIL
})

//add grades to tests
export const addGradesToTestFail = err => ({
  type: ADD_GRADES_TO_TEST_FAIL,
  err
})

export const addGradesToTestStart = () => ({
  type: ADD_GRADES_TO_TEST_START
})

export const addGradesToTestSuccess = data => ({
  type: ADD_GRADES_TO_TEST,
  data
})

const postGradesToTest = ({ jwt, tests, payload }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/grades/tests?' +
    querystring.stringify({ tests: tests })
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPostGradesToTest = data => {
  return function(dispatch) {
    dispatch(addGradesToTestStart())
    return postGradesToTest(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startPostGradesToTest', json)
        dispatch(addGradesToTestSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(addGradesToTestFail(err.message))
      })
  }
}

//edit grades
const editGrades = ({ jwt, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/grades'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startEditGrades = data => {
  return function(dispatch) {
    dispatch(addGradesToTestStart())
    return editGrades(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startPostGradesToTest', json)
        dispatch(addGradesToTestSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(addGradesToTestFail(err.message))
      })
  }
}

export default Tests

//fetch sub-tests
const emitSubTest = () => ({
  type: FETCH_SUB_TEST_START
})

const emitSubTestData = data => {
  data.sort((a, b) => a.order - b.order)
  const {
    entities: { tests },
    result
  } = normalize(data, testListSchema)

  return {
    type: SUB_TEST_DATA,
    entities: tests,
    result,
    subTestData: data.sort((a, b) => a.order - b.order)
  }
}

const emitFlatenedSubTestData = data => {
  data.sort((a, b) => a.order - b.order)
  const {
    entities: { tests },
    result
  } = normalize(data, testListSchema)

  return {
    type: FLATTENED_SUB_TEST_DATA,
    entities: tests,
    result
  }
}

const fetchSubTests = ({ jwt, tests }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/test?' +
    querystring.stringify({ ids: tests })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchSubTests = data => {
  return function(dispatch) {
    dispatch(emitSubTest())
    return fetchSubTests(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startFetchSubTests', json)
        dispatch(emitSubTestData(json.data))
        dispatch(emitFlatenedSubTestData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err.message))
      })
  }
}

//patch sub tests

const emitPatchSubTestStart = () => ({
  type: EDIT_SUB_TEST_START
})

const emitPatchSubTestData = data => ({
  type: EDIT_SUB_TEST_SUCCESS,
  data
})

const patchSubTest = ({ jwt, testIds, payload }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/test/multiple?' +
    querystring.stringify({ ids: testIds })

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchSubTests = data => {
  return function(dispatch) {
    dispatch(emitPatchSubTestStart())
    return patchSubTest(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startPatchSubTests: ', json.data)
        dispatch(emitPatchSubTestData(json.data))
        successToast('Test updated successully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(emitFailure(err))
        errorToast('Test update unsuccessful')
      })
  }
}

//add minmarks min grades
export const emitAddMinMarksGradesFail = err => ({
  type: ADD_MIN_MARKS_GRADES_FAIL,
  err
})

export const emitAddMinMarksGradesStart = () => ({
  type: ADD_MIN_MARKS_GRADES_START
})

export const emitAddMinMarksGradesSuccess = () => ({
  type: ADD_MIN_MARKS_GRADES_SUCCESS
})

const addMinMarksGrades = ({ jwt, testId, subjectId, data }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/test/${testId}/${subjectId}/minMarks`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startAddMinMarksGrades = data => {
  return function(dispatch) {
    dispatch(emitAddMinMarksGradesStart())
    return addMinMarksGrades(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startAddMinMarksGrades', json)
        dispatch(emitAddMinMarksGradesSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitAddMinMarksGradesFail(err.message))
      })
  }
}

const addMaxMarksGrades = ({ jwt, testId, subjectId, data }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/test/${testId}/${subjectId}/maxMarks`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startAddMaxMarksGrades = data => {
  return function(dispatch) {
    dispatch(emitAddMinMarksGradesStart())
    return addMaxMarksGrades(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('inside startAddMaxMarksGrades', json)
        dispatch(emitAddMinMarksGradesSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitAddMinMarksGradesFail(err.message))
      })
  }
}
