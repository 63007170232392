// import helpers
import {
  checkStatus,
  parseJSON,
  composeAuth,
  successToast,
  errorToast
} from '../helpers/utils.js'
import querystring from 'query-string'
// import action types
import {
  START_CONDUCT_CERTIFICATE_FETCH,
  FETCH_CONDUCT_CERTIFICATE_SUCCESS,
  FETCH_CONDUCT_CERTIFICATE_FAIL,
  START_MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  FAILED_MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  START_REGISTER_CERTIFICATE_FETCH,
  FETCH_REGISTER_CERTIFICATE_FAIL,
  FETCH_REGISTER_CERTIFICATE_SUCCESS,
  START_MERGED_REGISTER_CERTIFICATE_PDF_DATA,
  MERGED_REGISTER_CERTIFICATE_PDF_DATA,
  FAILED_MERGED_REGISTER_CERTIFICATE_PDF_DATA
} from '../helpers/actions'

const getById = data => {
  let byId = {}
  data.forEach(s => {
    byId[s['studentId']] = s
  })
  return byId
}

const getAllIds = data => {
  let allIds = []
  data.forEach(s => {
    allIds.push(s['studentId'])
  })
  return allIds
}
// start get requests
const emitStartRegisterCertificate = () => ({
  type: START_REGISTER_CERTIFICATE_FETCH
})
// get requests failure
const emitFailureRegisterCertificate = () => ({
  type: FETCH_REGISTER_CERTIFICATE_FAIL
})
// get request success
const emitSuccessRegisterCertificate = data => {
  const byId = getById(data)
  const allIds = getAllIds(data)
  return {
    type: FETCH_REGISTER_CERTIFICATE_SUCCESS,
    byId,
    allIds
  }
}
// start get requests
const emitStart = () => ({
  type: START_CONDUCT_CERTIFICATE_FETCH
})
// get requests failure
const emitFailure = () => ({
  type: FETCH_CONDUCT_CERTIFICATE_FAIL
})
// get request success
const emitSuccess = data => {
  const byId = getById(data)
  const allIds = getAllIds(data)
  return {
    type: FETCH_CONDUCT_CERTIFICATE_SUCCESS,
    byId,
    allIds
  }
}

//merge pdf data
const emitStartMerge = () => ({
  type: START_MERGED_CONDUCT_CERTIFICATE_PDF_DATA
})
const mergeSuccessful = data => ({
  type: MERGED_CONDUCT_CERTIFICATE_PDF_DATA,
  data
})
const mergeFailed = () => ({
  type: FAILED_MERGED_CONDUCT_CERTIFICATE_PDF_DATA
})
//merge pdf data
const emitStartMergeRegisterCertificate = () => ({
  type: START_MERGED_REGISTER_CERTIFICATE_PDF_DATA
})
const mergeSuccessfulRegisterCertificate = data => ({
  type: MERGED_REGISTER_CERTIFICATE_PDF_DATA,
  data
})
const mergeFailedRegisterCertificate = () => ({
  type: FAILED_MERGED_REGISTER_CERTIFICATE_PDF_DATA
})
const addConductCertificate = data => {
  const { jwt, ...payload } = data
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/conductcertificate'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}
//generate student tc report
export const startAddConductCertificate = data => dispatch => {
  return addConductCertificate(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside addConductCertificate', json)
      successToast('Conduct Certificate successfully generated')
    })
    .catch(err => {
      errorToast('Failed to generate Conduct Certificate ', err)
    })
}
//add register certificate
const addRegisterCertificate = data => {
  const { jwt, ...payload } = data
  console.log('payload', payload)
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/registercertificate'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}
//generate student tc report
export const startAddRegisterCertificate = data => dispatch => {
  return addRegisterCertificate(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside addRegisterCertificate', json)
      successToast('Register Certificate successfully generated')
    })
    .catch(err => {
      errorToast('Failed to generate Register Certificate ', err)
    })
}
// fetch tc status
export const startFetchConductCertificateStatus = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchConductCertificateStatus(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('data inside startFetchConductCertificates', json)
        dispatch(emitSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        dispatch(emitFailure)
      })
  }
}

const fetchConductCertificateStatus = data => {
  const { jwt, academic_year, payload } = data
  const Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/conductcertificate/academic_year/' +
    academic_year +
    '/students?' +
    querystring.stringify({ students: payload?.studentId })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

// export const startFetchRegisterCertificateStatus = data => {
//   return function(dispatch) {
//     dispatch(emitStartRegisterCertificate())
//     return fetchRegisterCertificateStatus(data)
//       .then(checkStatus)
//       .then(parseJSON)
//       .then(json => {
//         console.log('data inside startFetchRegisterCertificates', json)
//         dispatch(emitSuccessRegisterCertificate(json.data))
//       })
//       .catch(err => {
//         console.error(err)
//         dispatch(emitFailureRegisterCertificate)
//       })
//   }
// }

// const fetchRegisterCertificateStatus = data => {
//   const { jwt, studentId, academic_year } = data
//   let temporal = []
//   let index = 0
//   let chunkSize = 50
//   for (index = 0; index < studentId?.length; index += chunkSize) {
//     let arrChunk = studentId.slice(index, index + chunkSize)
//     temporal.push(arrChunk)
//   }
//   const Authorization = composeAuth(jwt)
//   let url =
//     process.env.REACT_APP_SERVER_NEW +
//     `/api/registercertificate/academic_year/${academic_year}/students?` +
//     querystring.stringify({ students: studentId })
//   return fetch(url, {
//     method: 'GET',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       Authorization
//     }
//   })
// }

export const startFetchRegisterCertificateStatus = data => {
  return function(dispatch) {
    dispatch(emitStartRegisterCertificate())
    return fetchRegisterCertificateStatus(data).then(dataArr => {
      let finalData = []
      dataArr.forEach(data => {
        finalData = finalData.concat(data)
      })
      console.log('finalData: ', finalData)
      dispatch(emitSuccessRegisterCertificate(finalData))
    })
  }
}

const fetchRegisterCertificateStatus = data => {
  const { jwt, studentId, academic_year } = data
  let temporal = []
  let index = 0
  let chunkSize = 50
  for (index = 0; index < studentId?.length; index += chunkSize) {
    let arrChunk = studentId.slice(index, index + chunkSize)
    temporal.push(arrChunk)
  }
  return Promise.all(
    temporal.map(st => {
      let url =
        process.env.REACT_APP_SERVER_NEW +
        `/api/registercertificate/academic_year/${academic_year}/students?` +
        querystring.stringify({ students: st })
      let Authorization = composeAuth(jwt)
      return fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization
        }
      })
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          console.log('json.data: ', json.data)
          return json.data
        })
        .catch(err => {
          dispatch(emitFailureRegisterCertificate(err))
          errorToast('Error Fetching Register Certificate')
        })
    })
  )
}

const mergePdf = data => {
  const { students, studentgroup, academic_year, jwt } = data
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + `/api/conductcertificate/merge`
  const body = {
    students,
    studentgroup,
    academic_year
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}
const mergePdfRegisterCertificate = data => {
  const { students, studentgroup, academic_year, jwt } = data
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW + `/api/registercertificate/merge`
  const body = {
    students,
    studentgroup,
    academic_year
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}
//start merge pdf
export const startMergePDFRegisterCertificate = data => dispatch => {
  dispatch(emitStartMergeRegisterCertificate())
  return mergePdfRegisterCertificate(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startMergePDFRegisterCertificate', json)
      dispatch(mergeSuccessfulRegisterCertificate(json.data))
      successToast('PDF Merge Complete')
    })
    .catch(err => {
      dispatch(mergeFailedRegisterCertificate())
      errorToast('PDF Merge Fail')

      console.error(err)
    })
}
export const startMergePDF = data => dispatch => {
  dispatch(emitStartMerge())
  return mergePdf(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startMergePDF', json)
      dispatch(mergeSuccessful(json.data))
      successToast('PDF Merge Complete')
    })
    .catch(err => {
      dispatch(mergeFailed())
      errorToast('PDF Merge Fail')

      console.error(err)
    })
}
