// import packages
import React from 'react'
import classNames from 'classnames'
// import components
import TableHeader from './tableHeader'
import TableBody from './tableBody'

const StudentListContainer = ({
  studentDataAndTc,
  selectedStudents,
  handleInputChange,
  handleMerge,
  mergeInProgress,
  mergedPDFData,
  handleSelectAllChange,
  selectAll
}) => {
  return (
    <div>
      <div className="container student-select-ctr">
        <table className="st-table st-table--default u-position-relative">
          <TableHeader
            handleSelectAllChange={handleSelectAllChange}
            selectAll={selectAll}
          />
          <TableBody
            selectedStudents={selectedStudents}
            handleInputChange={handleInputChange}
            studentDataAndTc={studentDataAndTc}
          />
          <div className="field submit-field submit-field__studentconfirm">
            <button
              className={classNames({
                'button button--small button--blue': true
              })}
              type="button"
              onClick={handleMerge}
            >
              Merge PDF
            </button>
            {Object.keys(mergedPDFData).length != 0 && !mergeInProgress && (
              <button
                className={classNames({
                  'button button--small button--blue': true
                })}
                onClick={() =>
                  window.open(mergedPDFData.merged_file_url, '_blank')
                }
              >
                View Merged PDF
              </button>
            )}
            {mergeInProgress && <p>Merging...</p>}
          </div>
        </table>
      </div>
    </div>
  )
}

export default StudentListContainer
