import {
  APP_LOGOUT,
  TEACHERS_LEAVE_POLICY_START,
  TEACHERS_LEAVE_POLICY_FAIL,
  TEACHERS_LEAVE_POLICY_SUCCESS,
  LEAVE_POLICY_BY_POLICY_ID_START,
  LEAVE_POLICY_BY_POLICY_ID_SUCCESS,
  LEAVE_POLICY_BY_POLICY_ID_FAIL,
  LEAVE_POLICY_FOR_TEACHER_START,
  LEAVE_POLICY_FOR_TEACHER_FAIL,
  LEAVE_POLICY_FOR_TEACHER_SUCCESS,
  ASSIGN_LEAVE_POLICY_FOR_TEACHER_START,
  ASSIGN_LEAVE_POLICY_FOR_TEACHER_FAIL,
  ASSIGN_LEAVE_POLICY_FOR_TEACHER_SUCCESS
} from '../helpers/actions'

const initialState = {
  leavePolicies: [],
  isLoading: false,
  isErr: false,
  errMsg: '',
  teacherLeavePolicies: {},
  isLoadingAssignLeavePolcy: false
}

export const teacherLeavePolicy = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case TEACHERS_LEAVE_POLICY_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case TEACHERS_LEAVE_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        leavePolicies: action.data,
        isErr: false
      })
    case TEACHERS_LEAVE_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case LEAVE_POLICY_BY_POLICY_ID_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case LEAVE_POLICY_BY_POLICY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        leavePolicies: action.data,
        isErr: false
      })
    case LEAVE_POLICY_BY_POLICY_ID_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case LEAVE_POLICY_FOR_TEACHER_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case LEAVE_POLICY_FOR_TEACHER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        teacherLeavePolicies: action.data,
        isErr: false
      })
    case LEAVE_POLICY_FOR_TEACHER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case ASSIGN_LEAVE_POLICY_FOR_TEACHER_START:
      return Object.assign({}, state, {
        isLoadingAssignLeavePolcy: true,
        isErr: false,
        errMsg: ''
      })
    case ASSIGN_LEAVE_POLICY_FOR_TEACHER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingAssignLeavePolcy: false,
        isErr: false
      })
    case ASSIGN_LEAVE_POLICY_FOR_TEACHER_FAIL:
      return Object.assign({}, state, {
        isLoadingAssignLeavePolcy: false,
        isErr: true,
        errMsg: action.errMsg
      })
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.teacherLeavePolicy.isLoading
export const getIsLoadingAssignLeavePolcy = state =>
  state.teacherLeavePolicy.isLoadingAssignLeavePolcy
export const getLeavePolicies = state => state.teacherLeavePolicy.leavePolicies
export const getIsError = state => state.teacherLeavePolicy.isErr
export const getErrMsg = state => state.teacherLeavePolicy.errMsg
export const getTeacherLeavePolicies = state =>
  state.teacherLeavePolicy.teacherLeavePolicies
