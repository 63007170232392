// import packages
import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import { PageHelmet } from '../common'
// import actions
import { startFetchGroups } from '../../actions/groups'
import { startFetchDepartmentData } from '../../actions/teacher'
import {
  startPostPolicy,
  startSingleFileUpload,
  removePolicyDocumentLink
} from '../../actions/policy'
import { startNewMail } from '../../actions/email'

//import selectors
import { getJwt } from '../../reducers/accounts'
import {
  getGroupsForSms,
  isLoading as groupIsLoading
} from '../../reducers/groups'
import { getDepartments, getTeacherLoading } from '../../reducers/teacher'
import { getInstitutionData } from '../../reducers/institution'
import {
  getDocumentLInk,
  getPostMessage,
  getIsPolicyLoading as policyIsLoading
} from '../../reducers/policy'
// import components
import PoliciesDescription from './descriptionComponent'
import GroupSectionComponent from './groupSelection'
import ConfirmGroupSelectionComponent from './confirmGroupSelection'
//helpers
import { getFormData } from '../../helpers/utils'

class PolicyComponent extends Component {
  state = {
    description: '',
    subject: '',
    fileName: '',
    files: [],
    page: 1,
    confirmObj: {},
    message: '',
    selectAll: true,
    groups: {},
    selectedDepartments: {},
    setStateData: true,
    showModal: false,
    maximumFileSizeexeeds: false
  }

  componentDidMount() {
    const {
      jwt,
      fetchAllGroups,
      fetchDepartments,
      description,
      fileName
    } = this.props
    fetchAllGroups({ jwt, type: 'academics' })
    fetchDepartments({ jwt })
    this.setState({
      message: description,
      fileName: fileName
    })
  }
  componentDidUpdate(prevProps, prevState) {
    const { setStateData } = this.state
    const {
      allGroups,
      departments,
      teacherIsLoading,
      groupIsLoading
    } = this.props

    if (setStateData && !groupIsLoading && !teacherIsLoading) {
      const newState = {}
      const newDepartmentState = {}
      Object.keys(allGroups).forEach(umbrellaGroup => {
        newState[umbrellaGroup] = {}
        newState[umbrellaGroup].checked = true
        allGroups[umbrellaGroup].forEach(groupObj => {
          newState[umbrellaGroup][groupObj._id] = {}
          newState[umbrellaGroup][groupObj._id].name = groupObj.group_name
          newState[umbrellaGroup][groupObj._id].checked = true
        })
      })

      newDepartmentState.checked = true
      departments.forEach(department => {
        newDepartmentState[department] = true
      })
      this.setState({
        ...this.state,
        groups: newState,
        selectedDepartments: newDepartmentState,
        setStateData: false
      })
    }

    if (prevProps.postMsg !== this.props.postMsg) {
      this.setState({
        showModal: true
      })
    }
  }

  handleInputChange = e => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleFileChange = e => {
    const { jwt, uploadPolicyDocument } = this.props
    const files = getFormData(e.target.files)
    const fileName = e.target.files.length === 1 ? e.target.files[0].name : ''
    const filesize =
      e.target.files.length === 1
        ? (e.target.files[0].size / 1024 / 1024).toFixed(4)
        : ''
    if (e.target.files.length === 1) files.append('name', e.target.files)
    if (filesize > 200) {
      this.setState({
        maximumFileSizeexeeds: true
      })
    } else {
      this.setState({
        files: files,
        fileName: fileName || '',
        maximumFileSizeexeeds: false
      })
      uploadPolicyDocument({
        jwt,
        body: files
      })
    }
  }
  nextPage = e => {
    e.preventDefault()
    this.setState({
      page: this.state.page + 1
    })
  }
  navigateBack = () => {
    this.setState({
      page: this.state.page - 1
    })
  }
  handleSubmitGroupSelect = obj => {
    this.setState({
      page: this.state.page + 1,
      confirmObj: obj
    })
  }
  handleUmbrellaGroupChange = e => {
    const { groups, selectedDepartments } = this.state
    const umbrellaName = e.target.name
    const umbrellaObj = groups[umbrellaName]
    const newUmbrellaChange = Object.assign({}, umbrellaObj)
    Object.keys(newUmbrellaChange).forEach(groupId => {
      if (groupId === 'checked')
        newUmbrellaChange.checked = !umbrellaObj.checked
      else {
        newUmbrellaChange[groupId] = Object.assign(
          {},
          newUmbrellaChange[groupId],
          {
            checked: !umbrellaObj.checked
          }
        )
      }
    })

    const newState = {
      ...groups,
      [umbrellaName]: newUmbrellaChange
    }

    const isSelectAll = this.setSelectAll(newState, selectedDepartments)
    this.setState({
      ...this.state,
      selectAll: isSelectAll,
      groups: newState
    })
  }

  handleGroupChange = e => {
    const { groups, selectedDepartments } = this.state
    const { name } = e.target
    const groupId = name
    let umbrellaGroup
    Object.keys(groups).forEach(parent_group => {
      if (Object.keys(groups[parent_group]).indexOf(groupId) !== -1) {
        umbrellaGroup = parent_group
        return false
      }
    })
    const newGroupState = {
      ...groups,
      [umbrellaGroup]: {
        ...groups[umbrellaGroup],
        [groupId]: {
          ...groups[umbrellaGroup][groupId],
          checked: !groups[umbrellaGroup][groupId].checked
        }
      }
    }

    const newState = this.setSelectUmbrellaGroup(newGroupState, umbrellaGroup)
    const isSelectAll = this.setSelectAll(newState, selectedDepartments)
    this.setState({
      ...this.state,
      selectAll: isSelectAll,
      groups: newState
    })
  }

  setSelectUmbrellaGroup = (groups, umbrellaGroup) => {
    Object.keys(groups[umbrellaGroup]).forEach(groupId => {
      if (groupId !== 'checked' && !groups[umbrellaGroup][groupId].checked) {
        groups[umbrellaGroup].checked = false
        return false
      }
    })
    const groupsOfUmbrellaGroup = Object.keys(groups[umbrellaGroup]).length - 1
    const checkedGroupsOfUmbrellaGroup = Object.keys(
      groups[umbrellaGroup]
    ).filter(groupId => groups[umbrellaGroup][groupId].checked).length

    if (groupsOfUmbrellaGroup === checkedGroupsOfUmbrellaGroup) {
      groups[umbrellaGroup].checked = true
    }
    return groups
  }

  handleSelectAllChange = e => {
    const { groups, selectAll, selectedDepartments } = this.state
    const newDepartmentState = Object.assign({}, selectedDepartments)
    newDepartmentState.checked = !selectAll
    Object.keys(newDepartmentState).forEach(department => {
      if (department !== 'checked') {
        newDepartmentState[department] = !selectAll
      }
    })
    const newState = Object.assign({}, groups)
    Object.keys(groups).forEach(umbrellaGroup => {
      newState[umbrellaGroup].checked = !selectAll
      Object.keys(newState[umbrellaGroup]).forEach(groupId => {
        if (groupId !== 'checked') {
          newState[umbrellaGroup][groupId].checked = !selectAll
        }
      })
    })
    this.setState({
      ...this.state,
      selectAll: !selectAll,
      groups: newState,
      selectedDepartments: newDepartmentState
    })
  }

  setSelectAll = (groups, selectedDepartments) => {
    let newSelectAll = true
    if (selectedDepartments.checked === false) {
      newSelectAll = false
    }
    Object.keys(groups).forEach(umbrellaGroup => {
      if (!groups[umbrellaGroup].checked) {
        newSelectAll = false
        return false
      }
      Object.keys(groups[umbrellaGroup]).forEach(groupId => {
        if (groupId !== 'checked' && !groups[umbrellaGroup][groupId].checked) {
          newSelectAll = false
          return false
        }
      })
    })

    return newSelectAll
  }

  handleAllDepartmentChange = e => {
    const { selectedDepartments, groups } = this.state
    const newState = Object.assign({}, selectedDepartments)
    newState.checked = !selectedDepartments.checked
    Object.keys(newState).forEach(department => {
      if (department !== 'checked') {
        newState[department] = !selectedDepartments.checked
      }
    })
    const newSelectAll = this.setSelectAll(groups, newState)
    this.setState({
      ...this.state,
      selectedDepartments: newState,
      selectAll: newSelectAll
    })
  }

  handleDepartmentChange = e => {
    const { selectedDepartments, groups } = this.state
    const newState = Object.assign({}, selectedDepartments)
    newState[e.target.name] = !selectedDepartments[e.target.name]
    let newDepartmentState = this.checkToSelectAllDepartments(newState)
    const newSelectAll = this.setSelectAll(groups, newDepartmentState)
    this.setState({
      ...this.state,
      selectedDepartments: newDepartmentState,
      selectAll: newSelectAll
    })
  }

  checkToSelectAllDepartments = newState => {
    newState.checked = true
    Object.keys(newState).forEach(department => {
      if (department !== 'checked' && !newState[department]) {
        newState.checked = false
        return false
      }
    })
    return newState
  }

  getSelectedDepartments = selectedDepartments => {
    return Object.keys(selectedDepartments)
      .filter(department => department !== 'checked')
      .filter(department => selectedDepartments[department])
  }

  calculateClasses = () => {
    const { selectAll, groups } = this.state
    if (!groups || Object.keys(groups).length === 0) return 0
    if (selectAll) return 'ALL'
    return Object.keys(groups).filter(
      umbrellaGroup => groups[umbrellaGroup].checked
    ).length
  }

  calculateSections = () => {
    const { selectAll, groups } = this.state
    if (!groups || Object.keys(groups).length === 0) return 0
    if (selectAll) return 'ALL'
    let total = 0
    Object.keys(groups).forEach(umbrellaGroup => {
      Object.keys(groups[umbrellaGroup]).forEach(groupId => {
        if (groupId !== 'checked' && groups[umbrellaGroup][groupId].checked) {
          total++
        }
      })
    })
    return total
  }

  calculateDepartments = () => {
    const { selectedDepartments } = this.state
    if (selectedDepartments.checked === true) return 'ALL'
    let total = 0
    Object.keys(selectedDepartments).forEach(department => {
      if (department !== 'checked' && selectedDepartments[department]) total++
    })
    return total
  }
  handleSubmit = () => {
    const {
      addPolicy,
      jwt,
      documents = [],
      nameOfComponent,
      addmail
    } = this.props
    const { confirmObj, description, subject } = this.state
    const { groups, departments } = confirmObj
    const groupIds = []
    Object.keys(groups).forEach(umbrellaGroup => {
      Object.keys(groups[umbrellaGroup]).forEach(groupId => {
        if (groupId !== 'checked' && groups[umbrellaGroup][groupId].checked) {
          groupIds.push(groupId)
        }
      })
    })
    let data
    if (nameOfComponent === 'addPolicyComponent') {
      if (documents.length) {
        data = {
          jwt,
          document: documents[0].url,
          receipents: [
            {
              type: 'studentGroup',
              receipent: groupIds
            },
            {
              type: 'teacherDepartment',
              receipent: departments
            }
          ]
        }
      } else {
        data = {
          jwt,
          description,
          receipents: [
            {
              type: 'studentGroup',
              receipent: groupIds
            },
            {
              type: 'teacherDepartment',
              receipent: departments
            }
          ]
        }
      }

      console.log(data)
      addPolicy(data)
    }
    if (nameOfComponent === 'sendEmailComponent') {
      console.log(documents, 'documents')
      const domumentsArray = documents.map(doc => doc.url)

      data = {
        jwt,
        body: description,
        subject,
        attachments: domumentsArray.length ? domumentsArray : [],
        receipents: [
          {
            type: 'studentGroup',
            receipent: groupIds
          },
          {
            type: 'teacherDepartment',
            receipent: departments
          }
        ]
      }
      if (data.attachments === '') {
        delete data.attachments
      }
      addmail(data)
    }
  }
  removePolicyLink = e => {
    e.preventDefault()
    const { removeLink, documents } = this.props
    let newDocs = documents.filter(el => el.name !== e.target.name)
    removeLink({ data: newDocs })
  }
  getPostDetails = () => {
    const { postMsg } = this.props
    console.log('postMsg', postMsg)
    if (postMsg === 'success') {
      return (
        <div className="success">
          <div className="check-container">
            <FontAwesome name="check" size="3x" />
          </div>
          {/* <p>{getMessageText(level)}</p> */}
          <p>Click close to go back to dashboard</p>
        </div>
      )
    }
    return (
      <div className="failure">
        <div className="times-container">
          <FontAwesome name="times" size="3x" />
        </div>
        <p>Error In updating Policy </p>
        <p>Click close to try again</p>
      </div>
    )
  }
  close = () => {
    this.setState({
      showModal: false
    })
  }
  render() {
    const {
      description,
      page,
      confirmObj,
      groups,
      selectAll,
      selectedDepartments,
      showModal,
      maximumFileSizeexeeds
    } = this.state
    const {
      teacherIsLoading,
      groupIsLoading,
      allGroups,
      departments,
      documents,
      postMsg,
      PageHelmetText,
      policyLoading,
      nameOfComponent
    } = this.props

    return (
      <div>
        <PageHelmet>{PageHelmetText}</PageHelmet>
        <div className="tab-nav-ctr tab-nav-ctr--sms">
          <div className="tab-nav">
            <div className="tab">
              <p className="tab__text">{PageHelmetText} </p>
            </div>
            <div
              className={classNames({
                navigation: true,
                'navigation--active': page === 1,
                'navigation--success': page > 1
              })}
            >
              <p className="navigation__text">
                <span className="navigation__num">1</span>
                {PageHelmetText}
              </p>
            </div>
            <FontAwesome name="chevron-right" />
            <div
              className={classNames({
                navigation: true,
                'navigation--active': page === 2,
                'navigation--success': page > 2
              })}
            >
              <p className="navigation__text">
                <span className="navigation__num">2</span>
                Select Classes/Sections/Departments
              </p>
            </div>
            <FontAwesome name="chevron-right" />
            <div
              className={classNames({
                navigation: true,
                'navigation--active': page === 3,
                'navigation--success': page > 3
              })}
            >
              <p className="navigation__text">
                <span className="navigation__num">2</span>
                Confirm
              </p>
            </div>
          </div>
        </div>

        {page === 1 && (
          <PoliciesDescription
            description={description}
            nameOfComponent={nameOfComponent}
            handleInputChange={this.handleInputChange}
            handleFileChange={this.handleFileChange}
            nextPage={this.nextPage}
            documents={documents}
            removePolicyLink={this.removePolicyLink}
            PageHelmetText={PageHelmetText}
            policyLoading={policyLoading}
            maximumFileSizeexeeds={maximumFileSizeexeeds}
          />
        )}
        {page === 2 && (
          <GroupSectionComponent
            description={description}
            navigateBack={this.navigateBack}
            nextPage={this.nextPage}
            page={page}
            handleSubmitGroupSelect={this.handleSubmitGroupSelect}
            handleUmbrellaGroupChange={this.handleUmbrellaGroupChange}
            handleGroupChange={this.handleGroupChange}
            setSelectUmbrellaGroup={this.setSelectUmbrellaGroup}
            handleSelectAllChange={this.handleSelectAllChange}
            setSelectAll={this.setSelectAll}
            handleAllDepartmentChange={this.handleAllDepartmentChange}
            handleDepartmentChange={this.handleDepartmentChange}
            checkToSelectAllDepartments={this.checkToSelectAllDepartments}
            getSelectedDepartments={this.getSelectedDepartments}
            calculateClasses={this.calculateClasses}
            calculateSections={this.calculateSections}
            calculateDepartments={this.calculateDepartments}
            groups={groups}
            selectAll={selectAll}
            message={description}
            selectedDepartments={selectedDepartments}
            teacherIsLoading={teacherIsLoading}
            groupIsLoading={groupIsLoading}
            allGroups={allGroups}
            departments={departments}
          />
        )}
        {page === 3 && (
          <ConfirmGroupSelectionComponent
            confirmObj={confirmObj}
            description={description}
            navigateBack={this.navigateBack}
            handleSubmit={this.handleSubmit}
            getPostDetails={this.getPostDetails}
            showModal={showModal}
            close={this.close}
            postMsg={postMsg}
            documents={documents}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  institutionData: getInstitutionData(state),
  jwt: getJwt(state),
  teacherIsLoading: getTeacherLoading(state),
  groupIsLoading: groupIsLoading(state),
  allGroups: getGroupsForSms(state),
  departments: getDepartments(state),
  documents: getDocumentLInk(state),
  postMsg: getPostMessage(state),
  policyLoading: policyIsLoading(state)
})

const mapDispatchToProps = dispatch => ({
  fetchAllGroups(data) {
    dispatch(startFetchGroups(data))
  },
  fetchDepartments(data) {
    dispatch(startFetchDepartmentData(data))
  },
  uploadPolicyDocument(data) {
    dispatch(startSingleFileUpload(data))
  },
  addPolicy(data) {
    dispatch(startPostPolicy(data))
  },
  addmail(data) {
    dispatch(startNewMail(data))
  },
  removeLink(data) {
    dispatch(removePolicyDocumentLink(data))
  }
})

const Policy = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PolicyComponent)
)

export default Policy
