import React, { Suspense, lazy } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

// import { PrivateLayout, CoverLayout } from './layout.jsx'
import { PrivateLayout } from '../componentsNew/common/layout/layoutComponent'

import { Login } from './login/login.js'

import { StudentListWithoutCsv } from './studentList'

import { ChangePassword } from './password'
import {
  RegisterComp,
  ConductCertCom,
  RegisterStatusComp,
  ConductCertStatusCom
} from './stu-register-certificate'
import { PolicyComponent, EmailComponent } from './policyAndEmails'
import { Marks } from './marks/marksModal.jsx'
import { MarksReport } from './marksReport/marksReport.jsx'
import { SubjectWiseMarksReport } from './marksReport/subjectWise'
import { SubjectWiseConsolidatedMarksReport } from './marksReport/subjectWiseConsolidated'
import { MarksReportPDF } from './marksReportPDF/marksReport'

// edit class
import { EditStudentGroups } from './groups/editClass/editStudentGroups'
const EditStudentGroup = lazy(() =>
  import('../componentsNew/studentGroup/editStudentGroup')
)

// dashboards
const Dashboard = lazy(() => import('./dashboard'))

const AttendanceModule = lazy(() => import('./attendance'))

const MarksReport3Level = lazy(() => import('./marksReport/3level'))

const AttendanceReport = lazy(() => import('./attendanceReport'))
const AddStudent = lazy(() =>
  import('./groups/addStudent/addStudent.index.jsx')
)

// edit student
const EditStudent = lazy(() =>
  import('./groups/editStudent/editStudent.index.jsx')
)
// edit subject
// const EditSubject = lazy(() => import('./groups/editSubject'))
const EditSubject = lazy(() => import('../componentsNew/subjects/editSubject'))

// SMS for Group
const Sms = lazy(() => import('./SMS/SMSForGroup'))
const GroupSelection = lazy(() => import('./SMS/SMSForGroup/groupSelection'))
const ConfirmGroupSelection = lazy(() =>
  import('./SMS/SMSForGroup/confirmGroupSelection')
)
const ApproveSms = lazy(() => import('./SMS/SMSApprove/approveSms'))
const SmsInfo = lazy(() => import('./SMS/SMSApprove/smsInfo'))

// SMS for student
const SmsStudents = lazy(() => import('./SMS/SMSForStudents'))
const StudentSelection = lazy(() =>
  import('./SMS/SMSForStudents/studentSelection')
)

// SMS for teacher
const SmsTeachers = lazy(() => import('./SMS/SMSForTeachers'))
const TeacherSelection = lazy(() =>
  import('./SMS/SMSForTeachers/teacherSelect')
)
//const TeacherViewSMS = lazy(() => import('./SMS/SMSViewAll/teacherSms'))
const TeacherViewSMS = lazy(() => import('./SMS/SMSTeacherView/smsViewAll'))

// time table
const TimeTable = lazy(() => import('./timeTable'))
const TimeTableV2 = lazy(() => import('./timetable_v2'))

// Student List and Profile
const StudentProfile = lazy(() => import('./studentProfile'))

// class profile
// const ClassProfile = lazy(() => import('./classProfile'))
const ClassProfile = lazy(() =>
  import('../componentsNew/studentGroup/classProfile')
)

// student list
const StudentList = lazy(() => import('./studentList'))

// Add Hour
const AddHour = lazy(() => import('./groups/addHour'))
// Edit Hour
const EditHour = lazy(() => import('./groups/editHour'))
const AddEditHour = lazy(() => import('../componentsNew/hour/addEditHour'))
// csv upload
const StudentUploadPerSection = lazy(() => import('./csv/studentUpload'))
const TeacherUploadCsv = lazy(() => import('./csv/teacherUpload'))

// add students to electives
const AddStudentsToElectives = lazy(() =>
  import('./subjects/students/addStudents')
)
const AddStudentsToSubject = lazy(() =>
  import('../componentsNew/subjects/assignSubject/addStudent')
)

// remove students = lazy(() => import( electives))
const RemoveStudentsFromElectives = lazy(() =>
  import('./subjects/students/removeStudents')
)
const RemoveStudentsFromSubject = lazy(() =>
  import('../componentsNew/subjects/assignSubject/removeStudent')
)

// teacher profile
const TeacherProfile = lazy(() => import('./teacherProfile'))

// teacher list
const TeacherList = lazy(() => import('./teacher/teacherList'))

// teacher edit
const TeacherEdit = lazy(() => import('./teacherEdit'))
// const TeacherEdit = lazy(() => import('../componentsNew/teacher/editTeacher'))
const EditTest = lazy(() => import('./groups/editTests'))

//generatereport
const generateRankBcbs = lazy(() => import('./bcbs_generateRank'))

// Admissions
const NewEnquiry = lazy(() => import('./admission/newEnquiry'))
const EnquiryList = lazy(() => import('./admission/enquiryList'))
const ApplicantProfile = lazy(() => import('./admission/applicantProfile'))
const Chalan = lazy(() => import('./admission/chalan/chalan.index.jsx'))
const EditApplication = lazy(() =>
  import('./admission/editApplication/index.jsx')
)
const AddAdmissionStudent = lazy(() => import('./admission/student/addStudent'))
const AdmissionStudentList = lazy(() => import('./admission/studentList'))

// Inverntory
const newProposal = lazy(() => import('./inventory/newProposal'))
const ProposalList = lazy(() => import('./inventory/proposalList'))
const ProposalProfile = lazy(() => import('./inventory/proposalProfile'))
const Items = lazy(() => import('./inventory/items'))
const ItemsDetails = lazy(() => import('./inventory/items/itemDetails'))
// const AddAdmissionStudent = lazy(() => import( './admission/student/addStudent'))
// const AdmissionStudentList = lazy(() => import( './admission/studentList'))

// Fees
const StudentFees = lazy(() => import('./admission/fee'))
const GenerateFeeReceipt = lazy(() => import('./feeReceipt'))
const FeeReceipt = lazy(() => import('./feeReceipt/feeReceipt/index.jsx'))
const StudentFeeReceipt = lazy(() => import('./feeReceipt/student'))
const StudentFeeReceiptList = lazy(() =>
  import('./feeReceipt/student/feeReport')
)
const FeeReceiptReport = lazy(() => import('./feeReceipt/report'))
const ClassStudentFee = lazy(() => import('./feeStudentList'))
const ClassDateStudentFee = lazy(() => import('./feeStudentList/indexWithDate'))
const ClassWiseFeeStatusReport = lazy(() =>
  import('./feeReports/classWiseStatus')
)
const ClassWiseDetailFeeStatusReport = lazy(() =>
  import('./feeReports/classWiseStatus/detailedReport')
)

// change password

// Gallery
const gallery = lazy(() => import('./gallery/albumList'))
const album = lazy(() => import('./gallery/album'))
const GalleryBulkUpload = lazy(() => import('./gallery/upload'))

// Student Login
const StudentMyProfile = lazy(() => import('./studentLogin/myProfile.jsx'))
const StudentTimetable = lazy(() => import('./studentLogin/timeTable'))
const StudentAttendance = lazy(() => import('./studentLogin/attendance'))
// question Bank
const QuestionBank = lazy(() => import('./studentLogin/questionBank'))
const QuestionPaper = lazy(() =>
  import('./studentLogin/questionBank/showQuestionPaper.jsx')
)
const UploadQuestionbank = lazy(() =>
  import('./questionBank/uploadQuestionbank')
)

// helper
const StudentGroupCSV = lazy(() => import('./studentGroupCSV'))

const FileUpload = lazy(() => import('./fileUpload'))
const DocumentUpload = lazy(() => import('./documentUpload'))
const Performance = lazy(() => import('./studentProfile/performancePage'))
const PerformanceGraph = lazy(() =>
  import('./studentProfile/performancePageAll')
)
const Portion = lazy(() => import('./studentLogin/portion'))
const AddPortion = lazy(() => import('./portion/addPortion/addPortion'))
const Search = lazy(() => import('./search'))

// empty test report for bcgs
const BCGSStudentList = lazy(() => import('./bcgs/studentList'))

// bulk attendance daily
const BulkAttendance = lazy(() => import('./bulkAttendance'))

// report card
const ReportCardGeneration = lazy(() =>
  import('./reportCard/reportCardGeneration')
)
const ReportCardStatus = lazy(() => import('./reportCard/reportCardStatus'))

//attenace completion rate
const attendanceCompletionReport = lazy(() =>
  import('./attendanceCompletionReport')
)

// attendance correction
const AttendanceCorrection = lazy(() => import('./attendanceCorrection'))

// weekly attendance report for hourly attendance
const WeeklyAttendanceReport = lazy(() =>
  import('./attendanceReport/weeklyAttendance')
)

// Student Calendar
const StudentCalendar = lazy(() => import('./Calendar/StudentCalendar/index'))

// cce report
const CCEReport = lazy(() => import('./cceReport'))
//student certificates
const StudentCertificates = lazy(() =>
  import('./studentProfile/studentCertificates')
)
//student tc
const StudentTc = lazy(() => import('./studentTc'))
const StudentTcStatus = lazy(() => import('./studentTc/tcStatus'))
//cumulative report
const CumulativeReport = lazy(() => import('./cumulativeReport'))
const SjhsCumulativeReport = lazy(() => import('./sjhsCumulativeReport'))
const BcgsCumulativeReport = lazy(() => import('./bcgsCumulativeReport'))
const BcgsCumulativeReportAllClass = lazy(() =>
  import('./bcgsCumulativeReportAllClass')
)
const CceReport2 = lazy(() => import('./cceReport2'))

//promotion Next year
const AddStudentPromotionNextYear = lazy(() =>
  import('./promotionNextYearAddStudent')
)

//promotion
const PromotionNextYear = lazy(() => import('./promotionNextYear'))

//homework and assignment
const HomeworkAndAssignment = lazy(() => import('./homework-and-assignment'))
const HomeworkTable = lazy(() => import('./student-homework'))

//register certificate

const TcAndReportCardComponent = lazy(() =>
  import('./studentProfile/student-tc-and-report')
)

//reports
const SubjectsStudentList = lazy(() => import('./reports/subject-studentlist'))
const ElectiveStudentlist = lazy(() =>
  import('./reports/students-list-per-elective')
)
//policies

//const Policies = lazy(() => import( './policies'))
const ShowPolicies = lazy(() => import('./policies/showPolicy'))

//student remarks report
const StudentRemarksReport = lazy(() =>
  import('./student-remarks-report/index.jsx')
)
const StudentRemarksReportComment = lazy(() =>
  import('./student-remarks-report-comment')
)
const StudentRemarksReportSUbject = lazy(() =>
  import('./student-remarks-report-subject')
)

//cms
const NewsLetter = lazy(() => import('./cms'))
const ShowNewsLetter = lazy(() => import('./cms/showCms'))

//student login
const StudentReports = lazy(() => import('./student-report'))

//graphical and Tabular Analysis
const GraphiicalAndTabuarAnalysis = lazy(() =>
  import('./tabular-graphical-Analysis')
)
const PerformanceReportFailCount = lazy(() =>
  import('./performanceReportFailcounts/marksReport')
)
const ClasswiseAverageGraph = lazy(() => import('./classwiseAverageGraph'))

//student FeedBack
const studentFeedback = lazy(() => import('./studentFeedback'))
const studentFeedbackReport = lazy(() => import('./studentFeedbackReport'))

//Requisition
const requisition = lazy(() => import('./requisition/createRequisition'))

//Worksheet
const worksheet = lazy(() => import('./studentLogin/worksheet'))
const worksheetList = lazy(() => import('./worksheet'))
const worksheetListSingle = lazy(() =>
  import('./worksheet/renderWorksheetSingle')
)
const AddWorksheet = lazy(() => import('./worksheet/addWorksheet/addWorksheet'))

//library management system
const libraryAddCatalog = lazy(() => import('./library/catalog/add/addCatalog'))
const librarySearchCatalog = lazy(() => import('./library/catalog/search'))
const libraryAddCatalogPolicy = lazy(() =>
  import('./library/catalog/policy/addCatalogPolicy')
)
const libraryCatalogProfile = lazy(() =>
  import('./library/catalog/catalogInfo/catalogProfile')
)
const libraryUserSearch = lazy(() => import('./library/users/userSearch'))
const libraryAddUserPolicy = lazy(() =>
  import('./library/users/policy/addUserPolicy')
)
const libraryUserInfo = lazy(() =>
  import('./library/users/userInfo/userProfile')
)
const libraryUserLend = lazy(() => import('./library/lending'))
const libraryUserLendHistory = lazy(() => import('./library/users/history'))
const libraryUserChallanHistory = lazy(() =>
  import('./library/users/challanHistory')
)

const libraryChallanHistory = lazy(() =>
  import('./library/history/challanHistory')
)

//BCBS reports
const StudentListBoarders = lazy(() =>
  import('./bcbsReports/studentListBoarders')
)
const StudentListSubjects = lazy(() =>
  import('./bcbsReports/studentListSubjects')
)

//refund fees
const FeeReceiptRefund = lazy(() => import('./feeReceipt/refundReceipt'))
const FeeRefund = lazy(() => import('./feeReceipt/refundReceipt/receiptFees'))
const StudentRefundedFeeReceipt = lazy(() =>
  import('./feeReceipt/student/refundedReceipts')
)

//student id card
const StudentIdCard = lazy(() => import('./studentIdCard'))
const StudentIdStatus = lazy(() => import('./studentIdCard/idCardStatus'))

//teacher id card
const TeachetIdCard = lazy(() => import('./teacherIdCard'))
const TeacherIdStatus = lazy(() => import('./teacherIdCard/idCardStatus'))

const WIP = () => <h3>Work In progress</h3>

/*frontend V4 routes*/
// groups
// subjects
const AddStudentGroup = lazy(() =>
  import('../componentsNew/studentGroup/addStudentGroup')
)
const AddSubjectsToGroup = lazy(() =>
  import('../componentsNew/subjects/addSubject/main')
)
const AddElectivesToExistingSubject = lazy(() =>
  import('../componentsNew/subjects/addSubject/elective')
)
// tests
const AddTestsToGroup = lazy(() => import('../componentsNew/test/addTest/main'))
const NewSubTest = lazy(() => import('../componentsNew/test/addTest/subTest'))

// institution details
const InstitutionDetails = lazy(() =>
  import('../componentsNew/studentGroup/classOverview')
)
// const InstitutionDetailsPerYear = lazy(() =>
//   import(
//     '../componentsNew/studentGroup/classOverviewByYear/groupsPerAcademicYear'
//   )
// )
const InstitutionDetailsPerYear = lazy(() =>
  import('../componentsNew/studentGroup/classOverviewByYear')
)

// Add teacher
const AddTeacher = lazy(() => import('../componentsNew/teacher/addTeacher'))

//Leave policies
const CreatePolicy = lazy(() =>
  import('../componentsNew/leaveManagement/policy/createPolicy')
)

const EditPolicy = lazy(() =>
  import('../componentsNew/leaveManagement/policy/editPolicy')
)

const AssignLeavePolicy = lazy(() =>
  import('../componentsNew/leaveManagement/policy/assignPolicy')
)

const LeavePolicy = lazy(() =>
  import('../componentsNew/leaveManagement/policy')
)
const HolidayPolicy = lazy(() =>
  import('../componentsNew/leaveManagement/holidayPolicy')
)
const LeaveApproval = lazy(() =>
  import('../componentsNew/leaveManagement/leaveApproval')
)

//teacher Attendance
const TeacherAttendance = lazy(() =>
  import('../componentsNew/attendance/teacher/main')
)

//teacher leave application
const TeacherLeaveApplication = lazy(() =>
  import('../componentsNew/leaveManagement/leaveApplication/teacher')
)
const TeacherLeaveApplicationList = lazy(() =>
  import(
    '../componentsNew/leaveManagement/leaveApplication/teacher/leaveApplications'
  )
)
const TeacherLeaveBalance = lazy(() =>
  import(
    '../componentsNew/leaveManagement/leaveApplication/teacher/leaveBalance'
  )
)
const LeaveApprovalTeacher = lazy(() =>
  import(
    '../componentsNew/leaveManagement/leaveApproval/approvalTeacherProfile'
  )
)
const LeavePolicyTeacher = lazy(() =>
  import('../componentsNew/leaveManagement/policy/policyTeacherProfile')
)

//Payslip
const GeneratePayslip = lazy(() =>
  import('../componentsNew/payrollManagement/generatePayslip')
)
const DownloadPayslip = lazy(() =>
  import('../componentsNew/payrollManagement/downloadPayslip')
)
const GeneratePayslipTeacher = lazy(() =>
  import(
    '../componentsNew/payrollManagement/generatePayslip/generateInTeacherProfile'
  )
)
const DownloadPayslipTeacher = lazy(() =>
  import(
    '../componentsNew/payrollManagement/downloadPayslip/downloadPayslipTeacherProfile'
  )
)

//payroll policy
const PayrollPolicy = lazy(() =>
  import('../componentsNew/payrollManagement/policy')
)
const AddEditPayrollPolicy = lazy(() =>
  import('../componentsNew/payrollManagement/policy/addEditPolicy')
)
const AllocatePolicy = lazy(() =>
  import('../componentsNew/payrollManagement/policy/assignPolicy')
)

//payslips
const PayslipDownload = lazy(() =>
  import('../componentsNew/payrollManagement/downloadPayslip/teacher')
)
const FinancialDetailsTeacher = lazy(() =>
  import('../componentsNew/payrollManagement/financialDetails')
)
const FinancialDetailsBulk = lazy(() =>
  import(
    '../componentsNew/payrollManagement/financialDetails/financialDetailsBulk'
  )
)
const PayslipReport = lazy(() =>
  import('../componentsNew/payrollManagement/payslipReport')
)

//fees
const FeeDueReminder = lazy(() =>
  import('../componentsNew/fees/reports/feeDueReminder')
)

//marks
const MarksBulk = lazy(() => import('../componentsNew/marks/bulk'))

//sms templates
const AddSmsTemplate = lazy(() =>
  import('../componentsNew/smsTemplate/addTemplate')
)

//hallticket
const ExamTimetable = lazy(() => import('../componentsNew/exam/timetable'))
const GenerateHallticket = lazy(() =>
  import('../componentsNew/exam/hallticket/generateHallticket')
)
const DownloadHallticket = lazy(() =>
  import('../componentsNew/exam/hallticket/downloadHallticket')
)

//feedback
//survey reports
const SurveyReports = lazy(() =>
  import('../componentsNew/feedbackAndSurveys/surveyReports')
)

//student attendance report
const AttendanceReportByGender = lazy(() =>
  import('../componentsNew/attendance/student/attendanceReport/genderBased')
)

//forgot password
const ForgotPassword = lazy(() =>
  import('../componentsNew/account/forgotPassword')
)

export const SealtabsRouter = () => {
  return (
    <>
      {/* <Route
            exact
            path="/"
            name="home"
            render={() => <Redirect to="/dashboard" />}
          /> */}
      {/* <CoverLayout exact path="/login" name="login" component={Login} /> */}
      {/* <CoverLayout
            exact
            path="/reset-password"
            name="reset-password"
            component={ForgotPassword}
          /> */}
      {/* <PrivateLayout
        exact
        path="/change-password"
        name="changePassword"
        component={ChangePassword}
      /> */}
      {/* <PrivateLayout
            exact
            path="/dashboard"
            name="dashboard"
            component={Dashboard}
          /> */}
      {/* <PrivateLayout
        exact
        path="/student/show"
        name="student-list"
        component={StudentListWithoutCsv}
      /> */}
      <PrivateLayout
        exact
        path="/student/show/csv"
        name="student-list"
        component={StudentList}
      />
      {/* <PrivateLayout
        exact
        path="/student/show/:id"
        name="student-profile"
        component={StudentProfile}
      /> */}
      {/* <PrivateLayout
        exact
        path="/student/edit/:id"
        name="edit-student"
        component={EditStudent}
      /> */}
      <PrivateLayout
        exact
        path="/student/tc-reports/:id"
        name="edit-student"
        component={TcAndReportCardComponent}
      />
      {/* Adds an elective to an existing subject */}
      <PrivateLayout
        exact
        path="/groups/new/add-hour"
        name="group-add-hour"
        component={AddEditHour}
      />
      <PrivateLayout
        exact
        path="/groups/edit/hour"
        name="group-edit-hour"
        component={AddEditHour}
      />
      <PrivateLayout
        exact
        path="/student/groups/new/add-student"
        name="group-add-student"
        component={AddStudent}
      />
      <PrivateLayout
        exact
        path="/groups/edit/class"
        name="group-edit-class"
        component={EditStudentGroup}
      />
      <PrivateLayout
        exact
        path="/groups/edit/subject"
        name="group-edit-subject"
        component={EditSubject}
      />

      <PrivateLayout
        exact
        path="/groups/edit/test"
        name="group-edit-test"
        component={EditTest}
      />

      <PrivateLayout
        exact
        path="/attendance"
        name="attendance"
        component={AttendanceModule}
      />
      <PrivateLayout
        exact
        path="/attendance/bulk"
        name="bulkAttendance"
        component={BulkAttendance}
      />
      <PrivateLayout
        exact
        path="/attendance/report"
        name="attendance-report"
        component={AttendanceReport}
      />
      <PrivateLayout exact path="/marks" name="marks" component={Marks} />
      <PrivateLayout
        exact
        path="/marks/report"
        name="marks-report"
        component={MarksReport}
      />
      {/* <PrivateLayout
        exact
        path="/marks/report/subject-wise"
        name="marks-report"
        component={SubjectWiseMarksReport}
      /> */}
      <PrivateLayout
        exact
        path="/marks/report/subject-wise/consolidated"
        name="marks-report"
        component={SubjectWiseConsolidatedMarksReport}
      />
      <PrivateLayout
        exact
        path="/marks/exam-report"
        name="exam-report"
        component={MarksReport3Level}
      />
      <PrivateLayout exact path="/sms" name="sms" component={Sms} />
      <PrivateLayout
        exact
        path="/sms/groupselect"
        name="groupselect"
        component={GroupSelection}
      />
      <PrivateLayout
        exact
        path="/sms/groupselect/confirm"
        name="confirmGroupSelection"
        component={ConfirmGroupSelection}
      />
      {/* <PrivateLayout
        exact
        path="/sms/approve"
        name="approveSms"
        component={ApproveSms}
      /> */}
      <PrivateLayout
        exact
        path="/sms/info"
        name="smsInfo"
        component={SmsInfo}
      />
      <PrivateLayout
        exact
        path="/sms/students"
        name="smsstudents"
        component={SmsStudents}
      />
      <PrivateLayout
        exact
        path="/sms/students/studentselect"
        name="studentSelect"
        component={StudentSelection}
      />
      <PrivateLayout
        exact
        path="/sms/teachers"
        name="smsTeachers"
        component={SmsTeachers}
      />
      <PrivateLayout
        exact
        path="/sms/teachers/teacherselect"
        name="teacherSelect"
        component={TeacherSelection}
      />
      <PrivateLayout
        exact
        path="/sms/teachers/viewAll"
        name="teacherSMS"
        component={TeacherViewSMS}
      />
      <PrivateLayout
        exact
        path="/timetable"
        name="timeTable"
        component={TimeTable}
      />
      <PrivateLayout
        exact
        path="/timetablev2"
        name="timeTablev2"
        component={TimeTableV2}
      />
      <PrivateLayout
        exact
        path="/groups/classprofile"
        name="classProfile"
        component={ClassProfile}
      />
      {/* <PrivateLayout
        exact
        path="/teacher/show"
        name="teacherList"
        component={TeacherList}
      /> */}
      <PrivateLayout
        exact
        path="/csv/students"
        name="csvUpload"
        component={StudentUploadPerSection}
      />
      <PrivateLayout
        exact
        path="/csv/teacher"
        name="csvUploadTeacher"
        component={TeacherUploadCsv}
      />
      {/* Add students to electives */}
      <PrivateLayout
        exact
        path="/subjects/add/students"
        name="addStudentsToElectives"
        component={AddStudentsToElectives}
      />
      {/* Remove students from electives */}
      <PrivateLayout
        exact
        path="/subjects/remove/students"
        name="removeStudentsFromElectives"
        component={RemoveStudentsFromElectives}
      />
      {/* <PrivateLayout
        exact
        path="/teacher/show/:id"
        name="teacherProfile"
        component={TeacherProfile}
      /> */}
      {/* <PrivateLayout
        exact
        path="/teacher/edit/:id"
        name="editTeacher"
        component={TeacherEdit}
      /> */}
      {/* <PrivateLayout
        exact
        path="/admissions/new"
        name="addTeacher"
        component={NewEnquiry}
      /> */}
      <PrivateLayout
        exact
        path="/proposal/new"
        name="addTeacher"
        component={newProposal}
      />
      <PrivateLayout
        exact
        path="/admissions/enquirylist"
        name="enquirylist"
        component={EnquiryList}
      />
      <PrivateLayout
        exact
        path="/proposal/proposallist"
        name="enquirylist"
        component={ProposalList}
      />
      {/* <PrivateLayout
        exact
        path="/admissions/application/:id"
        name="NewApplicantProfile"
        component={ApplicantProfile}
      /> */}
      <PrivateLayout
        exact
        path="/proposal/:id"
        name="NewApplicantProfile"
        component={ProposalProfile}
      />
      <PrivateLayout
        exact
        path="/proposal/items/list"
        name="NewApplicantProfile"
        component={Items}
      />
      <PrivateLayout
        exact
        path="/proposal/items/details/:id"
        name="NewApplicantProfile"
        component={ItemsDetails}
      />
      <PrivateLayout
        exact
        path="/admissions/chalan"
        name="Chalan"
        component={Chalan}
      />
      <PrivateLayout
        exact
        path="/admissions/application/edit/:id"
        name="edit-application"
        component={EditApplication}
      />
      <PrivateLayout
        exact
        path="/admissions/student/addStudent"
        name="admission-addStudent"
        component={AddAdmissionStudent}
      />
      <PrivateLayout
        exact
        path="/admissions/studentlist"
        name="admissionStudentList"
        component={AdmissionStudentList}
      />
      <PrivateLayout
        exact
        path="/student/fee/show/:id"
        name="student-fee"
        component={StudentFees}
      />
      {/* Gallery routes */}
      {/* <PrivateLayout
        exact
        path="/gallery"
        name="gallery-list"
        component={gallery}
      />
      <PrivateLayout
        exact
        path="/events/gallery"
        name="gallery-list"
        component={gallery}
      /> */}
      {/* <PrivateLayout
        exact
        path="/gallery/upload"
        name="gallery-list"
        component={GalleryBulkUpload}
      />
      <PrivateLayout
        exact
        path="/gallery/album/:id"
        name="album"
        component={album}
      /> */}
      <PrivateLayout
        exact
        path="/student/feereceipt/:id"
        name="student-fee-receipt"
        component={GenerateFeeReceipt}
      />
      <PrivateLayout
        exact
        path="/student/refund/feereceipt/:id"
        name="student-refund-fee-receipt"
        component={FeeReceiptRefund}
      />
      <PrivateLayout
        exact
        path="/student/refund/fees/:id"
        name="student-refund-fee"
        component={FeeRefund}
      />
      <PrivateLayout
        exact
        path="/student/feereceipt"
        name="student-fee-receipt"
        component={StudentFeeReceipt}
      />
      <PrivateLayout
        exact
        path="/student/receipt/refunded"
        name="student-fee-receipt"
        component={StudentRefundedFeeReceipt}
      />
      <PrivateLayout
        exact
        path="/student/list/feereceipt"
        name="student-fee-receipt-list"
        component={StudentFeeReceiptList}
      />
      <PrivateLayout
        exact
        path="/students/feereceipt/report"
        name="fee-receipt-report"
        component={FeeReceiptReport}
      />
      <PrivateLayout
        exact
        path="/fees/students/feereceipt/report"
        name="fee-receipt-report"
        component={FeeReceiptReport}
      />
      <PrivateLayout
        exact
        path="/student/fee/showreceipt"
        name="fee-receipt"
        component={FeeReceipt}
      />
      <PrivateLayout
        exact
        path="/fees/class/students"
        name="fee-receipt"
        component={ClassStudentFee}
      />
      <PrivateLayout
        exact
        path="/fees/class/date/students"
        name="fee-receipt"
        component={ClassDateStudentFee}
      />
      <PrivateLayout
        exact
        path="/fees/class/status-report"
        name="fee-class-wise-status"
        component={ClassWiseFeeStatusReport}
      />
      <PrivateLayout
        exact
        path="/fees/class/detail/status-report"
        name="fee-class-wise-status"
        component={ClassWiseDetailFeeStatusReport}
      />
      {/* student login */}
      <PrivateLayout
        exact
        path="/student/profile"
        name="my-profile"
        component={StudentMyProfile}
      />
      {/* <PrivateLayout
        exact
        path="/profile/student/profile"
        name="my-profile"
        component={StudentMyProfile}
      /> */}
      <PrivateLayout
        exact
        path="/student/timetable"
        name="student-timetable"
        component={StudentTimetable}
      />
      <PrivateLayout
        exact
        path="/timetable/student/timetable"
        name="student-timetable"
        component={StudentTimetable}
      />
      {/* Month wise student attendance in student login */}
      <PrivateLayout
        exact
        path="/student/attendance"
        name="student-attendance"
        component={StudentAttendance}
      />
      <PrivateLayout
        exact
        path="/attendance/student/attendance"
        name="student-attendance"
        component={StudentAttendance}
      />
      <PrivateLayout exact path="/helper" component={StudentGroupCSV} />
      <PrivateLayout
        exact
        path="/questionbank"
        name="student-questionbank"
        component={QuestionBank}
      />
      <PrivateLayout
        exact
        path="/resources/questionbank"
        name="student-questionbank"
        component={QuestionBank}
      />
      <PrivateLayout
        exact
        path="/questionbank/show/:id"
        name="questionpaper"
        component={QuestionPaper}
      />
      <PrivateLayout
        exact
        path="/upload/questionbank"
        name="upload-questionbank"
        component={UploadQuestionbank}
      />
      <PrivateLayout
        exact
        path="/fileupload"
        name="file-upload"
        component={FileUpload}
      />
      <PrivateLayout
        exact
        path="/documentupload/:id"
        name="document-upload"
        component={DocumentUpload}
      />
      {/* <PrivateLayout
        exact
        path="/student/performance"
        name="performance"
        component={Performance}
      /> */}
      <PrivateLayout
        exact
        path="/performance/student/performance"
        name="performance"
        component={Performance}
      />
      <PrivateLayout
        exact
        path="/student/performance/graph/performance/:id"
        name="performance"
        component={PerformanceGraph}
      />
      <PrivateLayout
        exact
        path="/student/portion"
        name="portion"
        component={Portion}
      />
      <PrivateLayout
        exact
        path="/resources/portion"
        name="portion"
        component={Portion}
      />
      <PrivateLayout
        exact
        path="/add/portion"
        name="portion upload"
        component={AddPortion}
      />
      {/* <PrivateLayout
        exact
        path="/student/search"
        name="student-search"
        component={Search}
      /> */}
      <PrivateLayout
        exact
        path="/bcgs/student-list/test-report"
        name="bcgsStudentList"
        component={BCGSStudentList}
      />
      <PrivateLayout
        exact
        path="/attendance/hourly/completion"
        name="attendanceCompletionReport"
        component={attendanceCompletionReport}
      />
      <PrivateLayout
        exact
        path="/report-card/generate"
        name="reportCardGeneration"
        component={ReportCardGeneration}
      />
      <PrivateLayout
        exact
        path="/report-card/status"
        name="reportCardStatus"
        component={ReportCardStatus}
      />
      <PrivateLayout
        exact
        path="/attendance/hourly/correction"
        name="attendanceCorrection"
        component={AttendanceCorrection}
      />
      {/* Weekly Report For Hourly institution type */}
      <PrivateLayout
        exact
        path="/attendance/report/hourly/weekly"
        name="weeklyAttendanceReport"
        component={WeeklyAttendanceReport}
      />
      <PrivateLayout
        exact
        path="/student/calendar"
        name="StudentCalendar"
        component={StudentCalendar}
      />
      <PrivateLayout
        exact
        path="/events/student/calendar"
        name="StudentCalendar"
        component={StudentCalendar}
      />
      {/* CCE report */}
      <PrivateLayout
        exact
        path="/marks/cce-report"
        name="cceReport-new"
        component={CCEReport}
      />
      <PrivateLayout
        exact
        path="/marks/generaterank"
        name="cceReport"
        component={generateRankBcbs}
      />
      <PrivateLayout
        exact
        path="/student/certificates/:id"
        name="cceReport"
        component={StudentCertificates}
      />
      {/* <PrivateLayout
        exact
        path="/marks/marks-report"
        name="marks-report cumulative"
        component={MarksReportPDF}
      /> */}
      <PrivateLayout
        path="/student/tc/generate"
        name="tc generate"
        component={StudentTc}
      />
      <PrivateLayout
        exact
        path="/student/tc/status"
        name="tc status"
        component={StudentTcStatus}
      />
      {/* <PrivateLayout
        exact
        path="/marks/report/cumulative"
        name="cumulativereport"
        component={CumulativeReport}
      /> */}
      <PrivateLayout
        exact
        path="/marks/cce-report-final"
        name="cceReport"
        component={CceReport2}
      />
      <PrivateLayout
        exact
        path="/marks/report/sjhs-cumulative"
        name="cumulativereport"
        component={SjhsCumulativeReport}
      />
      {/* <PrivateLayout
        exact
        path="/marks/report/avg-cumulative"
        name="cumulativereport"
        component={BcgsCumulativeReport}
      /> */}
      {/* <PrivateLayout
        exact
        path="/marks/report/avg-cumulative/all"
        name="cumulativereport"
        component={BcgsCumulativeReportAllClass}
      /> */}
      <PrivateLayout
        exact
        path="/student/conduct-certificate/generate"
        name="conduct-certificate"
        component={ConductCertCom}
      />
      <PrivateLayout
        exact
        path="/student/conduct-certificate/status"
        name="conduct-certificate"
        component={ConductCertStatusCom}
      />
      <PrivateLayout
        exact
        path="/groups/add-students"
        name="student promotion"
        component={AddStudentPromotionNextYear}
      />
      <PrivateLayout
        exact
        path="/groups/dupllicate/academic-year"
        name="duplicate classes"
        component={PromotionNextYear}
      />
      <PrivateLayout
        exact
        path="/student/homework-assignment"
        name="homework-assignment"
        component={HomeworkAndAssignment}
      />

      <PrivateLayout
        exact
        path="/homework-assignment"
        name="show homework-assignment"
        component={HomeworkTable}
      />
      <PrivateLayout
        exact
        path="/resources/homework-assignment"
        name="show homework-assignment"
        component={HomeworkTable}
      />
      <PrivateLayout
        exact
        path="/student/register-certificate/generate"
        name="conduct-certificate generate"
        component={RegisterComp}
      />
      <PrivateLayout
        exact
        path="/student/register-certificate/status"
        name="conduct-certificate status"
        component={RegisterStatusComp}
      />
      <PrivateLayout
        exact
        path="/report/class-section/studentlist"
        name="conduct-certificate status"
        component={SubjectsStudentList}
      />
      <PrivateLayout
        exact
        path="/report/elective/studentlist"
        name="report elective studentlist"
        component={ElectiveStudentlist}
      />
      <PrivateLayout
        exact
        path="/policy"
        name="policy"
        component={PolicyComponent}
      />
      <PrivateLayout
        exact
        path="/policy/show"
        name="policy show"
        component={ShowPolicies}
      />
      <PrivateLayout
        exact
        path="/send-emails"
        name="send-emails"
        component={EmailComponent}
      />
      <PrivateLayout
        exact
        path="/marks/remarks/type/:type"
        name="student remarks"
        component={StudentRemarksReport}
      />
      <PrivateLayout
        exact
        path="/marks/remarks"
        name="student remarks"
        component={StudentRemarksReport}
      />
      <PrivateLayout
        exact
        path="/marks/remarks/subject"
        name="student remarks"
        component={StudentRemarksReportSUbject}
      />
      <PrivateLayout
        exact
        path="/marks/remarks/list"
        name="student remarks"
        component={StudentRemarksReportComment}
      />
      <PrivateLayout exact path="/cms" name="cms" component={NewsLetter} />
      <PrivateLayout
        exact
        path="/cms/show"
        name="cms show"
        component={ShowNewsLetter}
      />
      <PrivateLayout
        exact
        path="/class/reports"
        name="class reports"
        component={StudentReports}
      />
      <PrivateLayout
        exact
        path="/marks/graphical-tabular-analysis"
        name="graphical tabular analysis"
        component={GraphiicalAndTabuarAnalysis}
      />
      <PrivateLayout
        exact
        path="/marks/tabular-analysis"
        name="graphical tabular analysis failed-count"
        component={PerformanceReportFailCount}
      />
      <PrivateLayout
        exact
        path="/marks/classwise-average-graph"
        name="classwise average graph"
        component={ClasswiseAverageGraph}
      />
      <PrivateLayout
        exact
        path="/student-feedback"
        name="Student Feedback"
        component={studentFeedback}
      />
      <PrivateLayout
        exact
        path="/groups/student-feedback/reports"
        name="Student Feedback"
        component={studentFeedbackReport}
      />
      {/* Requsition  */}

      <PrivateLayout
        exact
        path="/groups/requisition"
        name="requisition"
        component={requisition}
      />

      {/* Worksheet  */}

      {/* <PrivateLayout
            exact
            path="/student/worksheet"
            name="worksheet student"
            component={worksheet}
          />

          <PrivateLayout
            exact
            path="/worksheet"
            name="worksheet teacher"
            component={worksheetList}
          />
          <PrivateLayout
            exact
            path="/worksheet/:id"
            name="worksheet teacher"
            component={worksheetListSingle}
          />
          <PrivateLayout
            exact
            path="/add/worksheet"
            name="worksheet add"
            component={AddWorksheet}
          /> */}

      {/* Library  */}

      {/* <PrivateLayout
            exact
            path="/library/catalog"
            name="library"
            component={libraryAddCatalog}
          />
          <PrivateLayout
            exact
            path="/library/catalog/search"
            name="library"
            component={librarySearchCatalog}
          />
          <PrivateLayout
            exact
            path="/library/catalog/policy"
            name="library"
            component={libraryAddCatalogPolicy}
          />
          <PrivateLayout
            exact
            path="/library/catalog/info"
            name="library"
            component={libraryCatalogProfile}
          />
          <PrivateLayout
            exact
            path="/library/users/search"
            name="library"
            component={libraryUserSearch}
          />
          <PrivateLayout
            exact
            path="/library/users/policy"
            name="library"
            component={libraryAddUserPolicy}
          />
          <PrivateLayout
            exact
            path="/library/user/profile"
            name="library"
            component={libraryUserInfo}
          />
          <PrivateLayout
            exact
            path="/library/user/lend"
            name="library"
            component={libraryUserLend}
          />
          <PrivateLayout
            exact
            path="/library/user/history"
            name="library"
            component={libraryUserLendHistory}
          />
          <PrivateLayout
            exact
            path="/library/user/history/challans"
            name="library"
            component={libraryUserChallanHistory}
          />
          <PrivateLayout
            exact
            path="/library/history/challans"
            name="library"
            component={libraryChallanHistory}
          /> */}
      <PrivateLayout
        exact
        path="/studentList/boarders"
        name="bcbsReports"
        component={StudentListBoarders}
      />
      <PrivateLayout
        exact
        path="/studentList/subjects"
        name="bcbsReports"
        component={StudentListSubjects}
      />
      <PrivateLayout
        path="/student/idCard/generate"
        name="student id generate"
        component={StudentIdCard}
      />
      <PrivateLayout
        path="/student/idCard/status"
        name="student id generate"
        component={StudentIdStatus}
      />
      <PrivateLayout
        path="/teacher/idCard/generate"
        name="teacher id generate"
        component={TeachetIdCard}
      />
      <PrivateLayout
        path="/teacher/idCard/status"
        name="teacher id generate"
        component={TeacherIdStatus}
      />
      {/* frontend v4 routes */}
      {/* <PrivateLayout
            exact
            path="/dashboard"
            name="dashboard"
            component={Dashboard}
          /> */}
      <PrivateLayout
        exact
        path="/groups/new/add-groups"
        name="group-new"
        component={AddStudentGroup}
      />
      <PrivateLayout
        exact
        path="/groups/new/add-subjects"
        name="group-add-subjects"
        component={AddSubjectsToGroup}
      />
      <PrivateLayout
        exact
        path="/groups/new/add-electives"
        name="group-add-subjects"
        component={AddElectivesToExistingSubject}
      />
      <PrivateLayout
        exact
        path="/groups/new/add-tests"
        name="group-add-tests"
        component={AddTestsToGroup}
      />
      <PrivateLayout
        exact
        path="/tests/new/sub-test"
        name="group-add-tests"
        component={NewSubTest}
      />
      <PrivateLayout
        exact
        path="/groups/"
        name="groups"
        component={InstitutionDetails}
      />
      <PrivateLayout
        exact
        path="/groups/year"
        name="groups"
        component={InstitutionDetailsPerYear}
      />
      <PrivateLayout
        exact
        path="/teacher/new"
        name="addTeacher"
        component={AddTeacher}
      />
      {/* <PrivateLayout
            path="/generate-payslip"
            name="Generate Payslip"
            component={GeneratePayslip}
          />
          <PrivateLayout
            path="/download-payslip"
            name="Download payslip"
            component={DownloadPayslip}
          />
          <PrivateLayout
            path="/teacher/generate-payslip/:id"
            name="Generate Payslip"
            component={GeneratePayslipTeacher}
          />
          <PrivateLayout
            path="/teacher/download-payslip/:id"
            name="Download Payslip"
            component={DownloadPayslipTeacher}
          />
          <PrivateLayout
            path="/teacher/leave-policy/:id"
            name="Assign policy"
            component={LeavePolicyTeacher}
          />
          <PrivateLayout
            path="/teacher/leave-details/:id"
            name="Leave Details"
            component={LeaveApprovalTeacher}
          />
          <PrivateLayout
            path="/teacher/financial-details/:id"
            name="Financial Details"
            component={FinancialDetailsTeacher}
          />
          <PrivateLayout
            path="/financial-details"
            name="Financial Details"
            component={FinancialDetailsBulk}
          />
          <PrivateLayout
            exact
            path="/leaveManagement/createPolicy"
            name="createPolicy"
            component={CreatePolicy}
          />
          <PrivateLayout
            exact
            path="/leaveManagement/holidayPolicy"
            name="holidayPolicy"
            component={HolidayPolicy}
          />
          <PrivateLayout
            exact
            path="/leaveManagement/leavePolicy"
            name="leavePolicy"
            component={LeavePolicy}
          />
          <PrivateLayout
            exact
            path="/leaveManagement/leavePolicy/edit/:policy"
            name="editPolicy"
            component={EditPolicy}
          />
          <PrivateLayout
            exact
            path="/leaveManagement/leavePolicy/assign"
            name="editPolicy"
            component={AssignLeavePolicy}
          />
          <PrivateLayout
            exact
            path="/leaveManagement/leaveApproval"
            name="leaveApproval"
            component={LeaveApproval}
          />
          <PrivateLayout
            path="/attendance/teacher"
            name="teacher attendance"
            component={TeacherAttendance}
          />
          <PrivateLayout
            exact
            path="/teacher/attendance/report"
            name="teacher attendance report"
            component={TeacherAttendanceReport}
          />
          <PrivateLayout
            path="/me/apply-leave/:type"
            name="teacher apply-leave"
            component={TeacherLeaveApplication}
          />
          <PrivateLayout
            path="/me/leaves"
            name="teacher pending leaves"
            component={TeacherLeaveApplicationList}
          />
          <PrivateLayout
            path="/me/leave-balance"
            name="teacher leave balance"
            component={TeacherLeaveBalance}
          />
          <PrivateLayout
            path="/payroll-policy"
            name="payroll policy"
            component={PayrollPolicy}
          />
          <PrivateLayout
            path="/payroll/policy/:type"
            name="create/edit"
            component={AddEditPayrollPolicy}
          />
          <PrivateLayout
            path="/teacher/assign/payroll/:id"
            name="assign payroll policy"
            component={AllocatePolicy}
          />
          <PrivateLayout
            path="/payslip/download"
            name="download payslip"
            component={PayslipDownload}
          />
          <PrivateLayout
            path="/payslip/report"
            name="payslip report"
            component={PayslipReport}
          />
          <PrivateLayout
            path="/fee-due/reminder"
            name="fee due reminder"
            component={FeeDueReminder}
          />
          <PrivateLayout
            path="/marks/bulk/upload"
            name="marks bulk upload csv"
            component={MarksBulk}
          />
          <PrivateLayout
            path="/sms-template/add"
            name="add sms templates"
            component={AddSmsTemplate}
          />
          <PrivateLayout
            path="/exam/timetable"
            name="Exam Timetable"
            component={ExamTimetable}
          />
          <PrivateLayout
            path="/exam/hallticket/generate"
            name="Generate Hallticket"
            component={GenerateHallticket}
          />
          <PrivateLayout
            path="/exam/hallticket/download"
            name="Download Hallticket"
            component={DownloadHallticket}
          />
          <PrivateLayout
            path="/feedback/survey/report"
            name="Survey Report"
            component={SurveyReports}
          />
          <PrivateLayout
            path="/attendance/all-students/report"
            name="Students Attendance Report by gender"
            component={AttendanceReportByGender}
          />
          <PrivateLayout
            exact
            path="/studentgroup/student/add"
            name="group-add-student"
            component={AddEditStudent}
          />
          <PrivateLayout
            exact
            path="/studentgroup/student/edit/:id"
            name="edit-student"
            component={AddEditStudent}
          />
          <PrivateLayout
            exact
            path="/studentgroup/subject/edit"
            name="group-edit-subject"
            component={EditSubject}
          />
          <PrivateLayout
            exact
            path="/studentgroup/subjects/add-students"
            name="addStudentsToElectives"
            component={AddStudentsToSubject}
          />
          <PrivateLayout
            exact
            path="/studentgroup/subjects/remove-students"
            name="removeStudentsFromElectives"
            component={RemoveStudentsFromSubject}
          />
          <PrivateLayout
            exact
            path="/studentgroup/hour/:type"
            name="group-add-hour"
            component={AddEditHour}
          />
          <PrivateLayout
            exact
            path="/studentgroup/classes"
            name="groups"
            component={InstitutionDetails}
          />
          <PrivateLayout
            exact
            path="/studentgroup/year"
            name="groups by year"
            component={InstitutionDetailsPerYear}
          />
          <PrivateLayout
            exact
            path="/studentgroup/class/profile"
            name="class profile"
            component={ClassProfile}
          />
          <PrivateLayout
            exact
            path="/studentgroup/class/add"
            name="group-new"
            component={AddStudentGroup}
          />
          <PrivateLayout
            exact
            path="/studentgroup/subject/add"
            name="group-add-subjects"
            component={AddSubjectsToGroup}
          />
          <PrivateLayout
            exact
            path="/studentgroup/elective/add"
            name="group-add-subjects"
            component={AddElectivesToExistingSubject}
          />
          <PrivateLayout
            exact
            path="/studentgroup/test/add"
            name="group-add-tests"
            component={AddTestsToGroup}
          />
          <PrivateLayout
            exact
            path="/studentgroup/sub-test/add"
            name="group-add-tests"
            component={NewSubTest}
          />
          <PrivateLayout
            exact
            path="/studentgroup/class/edit"
            name="group-edit-class"
            component={EditStudentGroup}
          /> */}
    </>
  )
}
