import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
import { PrivateLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

//add navigation
const AddNavigation = lazy(() => import('../../../institution/addNavigation'))
const smsCount = lazy(() => import('../../../institution/smsCount'))
const NavigationList = lazy(() => import('../../../institution/navigationList'))
const SubNavigationTable = lazy(() =>
  import('../../../institution/navigationList/subNavigationTable')
)

//support users
// const SupportList = lazy(() => import('../../../institution/users/supportList'))
const SupportList = lazy(() => import('../../../institution/users/supportList'))
const AddSupportUser = lazy(() =>
  import('../../../institution/users/addSupportUser')
)

//create user
const CreateUser = lazy(() => import('../../../institution/users/createUser'))

const MasterRoute = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Frontend v3 routes */}

            {/* Frontend v4 routes */}
            <PrivateLayout
              exact
              path="/navigation/add"
              name="add navigation"
              component={AddNavigation}
            />
            <PrivateLayout
              exact
              path="/navigation/list"
              name="list of navigation"
              component={NavigationList}
            />
            <PrivateLayout
              exact
              path="/navigation/sub-navigation/list"
              name="list of navigation"
              component={SubNavigationTable}
            />
            <PrivateLayout
              exact
              path="/support-user"
              name="add support user"
              component={SupportList}
            />
            <PrivateLayout
              exact
              path="/support-user/add"
              name="add support user"
              component={AddSupportUser}
            />
            <PrivateLayout
              exact
              path="/institution/smscount"
              name="smscount"
              component={smsCount}
            />
            <PrivateLayout
              exact
              path="/user/add"
              name="add support user"
              component={CreateUser}
            />
            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default MasterRoute
