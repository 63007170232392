import _ from 'underscore'
import {
  APP_LOGOUT,
  GET_FEE_TYPES_START,
  GET_FEE_TYPES,
  GET_FEE_TYPES_FAIL,
  POST_FEE_TYPES_START,
  POST_FEE_TYPES,
  POST_FEE_TYPES_FAIL,
  PATCH_FEE_TYPES_START,
  PATCH_FEE_TYPES,
  PATCH_FEE_TYPES_FAIL,
  DELETE_FEE_TYPES_START,
  DELETE_FEE_TYPES,
  DELETE_FEE_TYPES_FAIL
} from '../helpers/actions'
import { createSelector } from 'reselect'

const initialState = {
  isLoadingGetAllFeeTypes: false,
  isLoadingPostFeeTypes: false,
  isLoadingPatchFeeTypes: false,
  allFeeTypes: [],
  isError: false,
  allFeeTypesObj: null
}

export const feeTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEE_TYPES_START:
      return Object.assign({}, state, {
        isLoadingGetAllFeeTypes: true,
        isError: false
      })
    case GET_FEE_TYPES:
      let finalData = {}
      action?.data
        ?.sort((a, b) => {
          if (a?.['value']?.toLowerCase() > b?.['value']?.toLowerCase())
            return 1
          return -1
        })
        ?.forEach(item => {
          finalData = Object.assign({}, finalData, {
            [item?.key]: item?.value
          })
        })
      return Object.assign({}, state, {
        allFeeTypes: action.data,
        allFeeTypesObj: finalData,
        isLoadingGetAllFeeTypes: false,
        isError: false
      })
    case GET_FEE_TYPES_FAIL:
      return Object.assign({}, state, {
        isLoadingGetAllFeeTypes: false,
        isError: true
      })
    case POST_FEE_TYPES_START:
      return Object.assign({}, state, {
        isLoadingPostFeeTypes: true,
        isError: false
      })
    case POST_FEE_TYPES:
      return Object.assign({}, state, {
        isLoadingPostFeeTypes: false,
        isError: false
      })
    case POST_FEE_TYPES_FAIL:
      return Object.assign({}, state, {
        isLoadingPostFeeTypes: false,
        isError: true
      })
    case PATCH_FEE_TYPES_START:
      return Object.assign({}, state, {
        isLoadingPatchFeeTypes: true,
        isError: false
      })
    case PATCH_FEE_TYPES:
      return Object.assign({}, state, {
        isLoadingPatchFeeTypes: false,
        isError: false
      })
    case PATCH_FEE_TYPES_FAIL:
      return Object.assign({}, state, {
        isLoadingPatchFeeTypes: false,
        isError: true
      })
    case DELETE_FEE_TYPES_START:
      return Object.assign({}, state, {
        isLoadingDeleteFeeTypes: true,
        isError: false
      })
    case DELETE_FEE_TYPES:
      return Object.assign({}, state, {
        isLoadingDeleteFeeTypes: false,
        isError: false
      })
    case DELETE_FEE_TYPES_FAIL:
      return Object.assign({}, state, {
        isLoadingDeleteFeeTypes: false,
        isError: true
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getIsLoadingGetAllFeeTypes = state =>
  state.feeTypes.isLoadingGetAllFeeTypes
export const getIsLoadingPostFeeTypes = state =>
  state.feeTypes.isLoadingPostFeeTypes
export const getIsLoadingPatchFeeTypes = state =>
  state.feeTypes.isLoadingPatchFeeTypes
export const getIsLoadingDeleteFeeTypes = state =>
  state.feeTypes.isLoadingDeleteFeeTypes
export const getAllFeeTypes = state => state.feeTypes.allFeeTypes
export const getIsError = state => state.feeTypes.isError
export const getAllFeeTypesObj = state => state.feeTypes.allFeeTypesObj
