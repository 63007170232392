// import packages
import React from 'react'
import { Field } from 'redux-form'
import FontAwesome from 'react-fontawesome'
// import helper functions
import { validateMark, validateGrade } from './validate'
import { renderField } from './fields'
import { getSubjectIdOfAStudent } from '../../helpers/utils'

const loading = (
  <FontAwesome name="circle-o-notch spin 3x fw" style={{ color: 'orange' }} />
)
const successStatus = <FontAwesome name="check" />
const failureStatus = <FontAwesome name="times" />

export const animatedStatus = status => {
  switch (status) {
    case 'success':
      return successStatus
    case 'in_progress':
      return loading
    case 'failure':
      return failureStatus
    default:
      return ''
  }
}

export const MarksAndStatusField = ({
  selectedTests,
  studentId,
  subjectId,
  marksStatus,
  marksform,
  dispatchPostRequest,
  type,
  groupId,
  mainSubjectId,
  groupsById,
  subjectsById
}) => {
  let marksAndStatus = []

  const postMarks = ({ student, testId, mark, maxMarks, syncErrorsExist }) => {
    if (
      marksform.values[testId] !== undefined &&
      marksform.values[testId].MaxMarks !== undefined &&
      !syncErrorsExist
    ) {
      dispatchPostRequest(testId, student, maxMarks, mark, type)
    }
  }

  const postGrade = ({ student, testId, mark, syncErrorsExist }) => {
    console.log(
      student,
      testId,
      mark,
      syncErrorsExist,
      'student, testId, mark, syncErrorsExist'
    )

    if (marksform.values[testId] !== undefined && !syncErrorsExist) {
      dispatchPostRequest(testId, student, 'none', mark, type)
    }
  }

  const handleBlur = e => {
    const [testId, student] = e.target.name.split('.')
    const mark = e.target.value
    const syncErrors = marksform.syncErrors
    const syncErrorsExist =
      syncErrors !== undefined &&
      syncErrors[testId] !== undefined &&
      syncErrors[testId][student] !== undefined

    const maxMarks = type === 'marks' ? marksform.values[testId].MaxMarks : 0

    // If sync errors exist don't dispatchPostRequest
    console.log(mark, 'mark')
    if (type === 'marks') {
      postMarks({ student, testId, mark, maxMarks, syncErrorsExist })
    } else {
      postGrade({ student, testId, mark, syncErrorsExist })
    }

    if (syncErrorsExist) {
      const studentsWithSyncError = Object.keys(syncErrors[testId])
      studentsWithSyncError.map(student => {
        const subject = getSubjectIdOfAStudent({
          studentId: student,
          subjectId,
          subjectsById,
          groupsById,
          mainSubjectId,
          groupId
        })
        marksStatus[`${testId}.${subject}.${student}`] = 'failure'
        return student
      })
    }
  }

  const disableMarksInput =
    marksform === undefined
      ? true
      : marksform.values === undefined ||
        Object.keys(marksform.values).length === 0
  selectedTests.map(testId => {
    const tdMarksField = (
      <td className="table-row__cell table-row__cell--marker-cell" key={testId}>
        {type === 'marks' && (
          <Field
            component={renderField}
            type="text"
            name={`${testId}.${studentId}`}
            disableField={disableMarksInput}
            validate={[validateMark]}
            onBlur={handleBlur}
          />
        )}
        {type === 'grade' && (
          <Field
            component={renderField}
            type="text"
            name={`${testId}.${studentId}`}
            disableField={false}
            validate={[validateGrade]}
            onBlur={handleBlur}
          />
        )}
      </td>
    )

    const subject = getSubjectIdOfAStudent({
      studentId,
      subjectId,
      subjectsById,
      groupsById,
      mainSubjectId,
      groupId
    })
    const status = marksStatus[`${testId}.${subject}.${studentId}`]
    const tdStatusField = (
      <td
        className="table-row__cell table-row__cell--status-cell"
        key={'status' + testId}
      >
        {' '}
        {animatedStatus(status)}{' '}
      </td>
    )
    marksAndStatus = marksAndStatus.concat([tdMarksField, tdStatusField])
    return testId
  })
  return marksAndStatus
}
