import {
  APP_LOGOUT,
  DEPARTMENTS_DATA,
  DEPARTMENTS_FETCH_START,
  TEACHERS_DATA,
  UPDATE_TEACHER_STORE,
  TEACHERS_FETCH_START,
  TEACHERS_FETCH_FAIL,
  ARCHIVE_TEACHER_START,
  ARCHIVE_TEACHER_SUCCESS,
  ARCHIVE_TEACHER_FAIL,
  UNARCHIVE_TEACHER_START,
  UNARCHIVE_TEACHER_SUCCESS,
  UNARCHIVE_TEACHER_FAIL,
  TEACHER_RESET_PASSWORD_START,
  TEACHER_RESET_PASSWORD_SUCCESS,
  TEACHER_RESET_PASSWORD_FAILURE,
  ADD_TEACHER_USERS_START,
  ADD_TEACHER_USERS,
  ADD_TEACHER_USERS_FAIL,
  ADD_NEW_TEACHER_START,
  ADD_NEW_TEACHER_FAIL,
  ADD_NEW_TEACHER_SUCCESS,
  TEACHER_SEARCH_DATA,
  CLEAR_TEACHER_DATA
} from '../helpers/actions.js'

import { createSelector } from 'reselect'

const initialState = {
  departments: [],
  teachersById: {},
  teachersAllIds: [],
  isLoading: false,
  isErr: false,
  errMsg: '',
  isWorking: false,
  isLoadingAddTeacherUsers: false,
  addTeacherLoading: false,
  searchTeacher: [],
  searchTeacherById: {}
}

export const teacher = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case DEPARTMENTS_DATA:
      return Object.assign({}, state, {
        isDepartmentsLoading: false,
        departments: action.data
      })
    case TEACHERS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case DEPARTMENTS_FETCH_START:
      return Object.assign({}, state, {
        isDepartmentsLoading: true,
        isErr: false,
        errMsg: ''
      })
    case TEACHERS_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        teachersById: action.entities,
        teachersAllIds: action.result
      })
    case UPDATE_TEACHER_STORE:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        }
      })
    case TEACHERS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case ARCHIVE_TEACHER_START:
      return Object.assign({}, state, {
        isWorking: true,
        isErr: false,
        errMsg: ''
      })
    case ARCHIVE_TEACHER_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isWorking: false,
          isErr: false,
          errMsg: ''
        },
        {
          teachersById: {
            ...state.teachersById,
            [action.teacherId]: {
              ...state.teachersById[action.teacherId],
              archive: true
            }
          }
        }
      )
    case ARCHIVE_TEACHER_FAIL:
      return Object.assign({}, state, {
        isWorking: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case UNARCHIVE_TEACHER_START:
      return Object.assign({}, state, {
        isWorking: true,
        isErr: false,
        errMsg: ''
      })
    case UNARCHIVE_TEACHER_SUCCESS:
      return Object.assign({}, state, {
        isWorking: false,
        isErr: false,
        errMsg: ''
      })
    case UNARCHIVE_TEACHER_FAIL:
      return Object.assign({}, state, {
        isWorking: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case TEACHER_RESET_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case TEACHER_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case TEACHER_RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case ADD_TEACHER_USERS_START:
      return Object.assign({}, state, {
        isLoadingAddTeacherUsers: true,
        isErr: false
      })
    case ADD_TEACHER_USERS:
      return Object.assign({}, state, {
        isLoadingAddTeacherUsers: false,
        isErr: false
      })
    case ADD_TEACHER_USERS_FAIL:
      return Object.assign({}, state, {
        isLoadingAddTeacherUsers: false,
        isErr: true
      })
    case ADD_NEW_TEACHER_START:
      return Object.assign({}, state, {
        addTeacherLoading: true,
        isErr: false
      })
    case ADD_NEW_TEACHER_SUCCESS:
      return Object.assign({}, state, {
        addTeacherLoading: false,
        isErr: false
      })
    case ADD_NEW_TEACHER_FAIL:
      return Object.assign({}, state, {
        addTeacherLoading: false,
        isErr: true
      })
    case TEACHER_SEARCH_DATA:
      return Object.assign({}, state, {
        addTeacherLoading: false,
        isErr: false,
        searchTeacher: action?.entities,
        searchTeacherById: action?.result
      })
    case CLEAR_TEACHER_DATA:
      return Object.assign({}, state, {
        teachersById: {},
        teachersAllIds: [],
        departments: [],
        isDepartmentsLoading: false
      })
    default:
      return state
  }
}

// selectors
export const isWorking = state => state.teacher.isWorking
export const getDepartments = state => state.teacher.departments
export const teachersById = state => state.teacher.teachersById
export const teachersAllIds = state => state.teacher.teachersAllIds
export const teachersDetails = state => {
  return state.teacher.teachersAllIds.map(
    teacher => state.teacher.teachersById[teacher]
  )
}
export const getTeacherLoading = state => state.teacher.isLoading
export const getIsLoadingAddTeacherUsers = state =>
  state.teacher.isLoadingAddTeacherUsers
export const isDepartmentsLoading = state => state.teacher.isDepartmentsLoading
export const getAddTeacherLoading = state => state.teacher.addTeacherLoading
export const getTeacherSearchData = state => state.teacher.searchTeacher
export const getTecacherSearchById = state => state.teacher.searchTeacherById

export const getDepartmentsData = createSelector(
  getDepartments,
  departments => departments
)
export const getTeachersData = createSelector(
  teachersById,
  teachersAllIds,
  (byId, allIds) => {
    if (allIds.length === 0) return []
    return allIds.map(id => {
      const {
        _id,
        name,
        department,
        employee_code,
        gender,
        phone,
        joining
      } = byId[id]

      return {
        _id,
        name,
        department,
        employee_code,
        gender,
        phone,
        joining
      }
    })
  }
)

export const getTeacherDataById = (state, teacherId) =>
  Object.assign(
    {},
    {
      _id: '',
      name: '',
      archive: false,
      department: '',
      employee_code: '',
      gender: '',
      email: ''
    },
    state.teacher.teachersById[teacherId]
  )

// get teacher name for a given teacherId
export const getTeacherName = (state, teacherId) => {
  const byId = teachersById(state)
  const { name } = Object.assign({ name: '' }, byId[teacherId])
  return name
}
