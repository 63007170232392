import {
  GET_STUDENT_CERTI_BY_INSTITUTION_ID_START,
  GET_STUDENT_CERTI_BY_INSTITUTION_ID,
  GET_STUDENT_CERTI_BY_INSTITUTION_ID_FAIL,
  GET_TEACHER_CERTI_BY_INSTITUTION_ID_START,
  GET_TEACHER_CERTI_BY_INSTITUTION_ID,
  GET_TEACHER_CERTI_BY_INSTITUTION_ID_FAIL,
  POST_CERTI_START,
  POST_CERTI,
  POST_CERTI_FAIL,
  PATCH_CERTI_BY_INSTITUTION_ID_START,
  PATCH_CERTI_BY_INSTITUTION_ID,
  PATCH_CERTI_BY_INSTITUTION_ID_FAIL,
  DELETE_CERTI_START,
  DELETE_CERTI,
  DELETE_CERTI_FAIL
} from '../helpers/actions'

const initialState = {
  postCertiLoading: false,
  getCertiLoading: false,
  patchCertiLoading: false,
  delCertiLoading: false,
  err: false,
  studentCertificates: [],
  teacherCertificates: [],
  message: ''
}
export const studentTeacherCertificate = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_CERTI_BY_INSTITUTION_ID_START:
      return {
        ...state,
        getCertiLoading: true,
        err: false,
        message: ''
      }
    case GET_STUDENT_CERTI_BY_INSTITUTION_ID:
      return {
        ...state,
        getCertiLoading: false,
        studentCertificates: action.data,
        err: false,
        message: ''
      }
    case GET_STUDENT_CERTI_BY_INSTITUTION_ID_FAIL:
      return {
        ...state,
        getCertiLoading: false,
        err: true,
        message: ''
      }
    case GET_TEACHER_CERTI_BY_INSTITUTION_ID_START:
      return {
        ...state,
        getCertiLoading: true,
        err: false,
        message: ''
      }
    case GET_TEACHER_CERTI_BY_INSTITUTION_ID:
      return {
        ...state,
        getCertiLoading: false,
        teacherCertificates: action.data,
        err: false,
        message: ''
      }
    case GET_TEACHER_CERTI_BY_INSTITUTION_ID_FAIL:
      return {
        ...state,
        getCertiLoading: false,
        err: true,
        message: ''
      }
    case POST_CERTI_START:
      return {
        ...state,
        postCertiLoading: true,
        err: false,
        message: ''
      }
    case POST_CERTI:
      return {
        ...state,
        postCertiLoading: false,
        err: false,
        message: 'post'
      }
    case POST_CERTI_FAIL:
      return {
        ...state,
        postCertiLoading: false,
        err: true,
        message: ''
      }
    case PATCH_CERTI_BY_INSTITUTION_ID_START:
      return {
        ...state,
        patchCertiLoading: true,
        err: false,
        message: ''
      }
    case PATCH_CERTI_BY_INSTITUTION_ID:
      return {
        ...state,
        patchCertiLoading: false,
        err: false,
        message: 'patch'
      }
    case PATCH_CERTI_BY_INSTITUTION_ID_FAIL:
      return {
        ...state,
        patchCertiLoading: false,
        err: true,
        message: ''
      }
    case DELETE_CERTI_START:
      return {
        ...state,
        delCertiLoading: true,
        err: false,
        message: ''
      }
    case DELETE_CERTI:
      return {
        ...state,
        delCertiLoading: false,
        err: false,
        message: 'delete'
      }
    case DELETE_CERTI_FAIL:
      return {
        ...state,
        delCertiLoading: false,
        err: true,
        message: ''
      }

    default:
      return state
  }
}

export const postCertiLoading = state =>
  state.studentTeacherCertificate.postCertiLoading
export const getCertiLoading = state =>
  state.studentTeacherCertificate.getCertiLoading
export const patchCertiLoading = state =>
  state.studentTeacherCertificate.patchCertiLoading
export const delCertiLoading = state =>
  state.studentTeacherCertificate.delCertiLoading
export const getStudentCertificates = state =>
  state.studentTeacherCertificate.studentCertificates
export const getErr = state => state.studentTeacherCertificate.err
export const getTeacherCertificates = state =>
  state.studentTeacherCertificate.teacherCertificates
export const getCertificateMessage = state =>
  state.studentTeacherCertificate.message
