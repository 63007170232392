import React from 'react'
import FontAwesome from 'react-fontawesome'

export const AddButton = ({ onClick, extraStyle, newTitle }) => {
  return (
    <span
      className={'button button--blue button--round'}
      title={newTitle ? newTitle : 'Add'}
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="plus" />
    </span>
  )
}

export const BackButton = ({ onClick }) => {
  return (
    <span
      className={'button button--blue button--round'}
      title="Back"
      onClick={e => onClick(e)}
      style={{ marginLeft: 30, marginTop: 5 }}
    >
      <FontAwesome name="arrow-left" />
    </span>
  )
}

export const DeleteButton = ({ onClick, extraStyle }) => {
  return (
    <span
      className={'button button--red button--round'}
      title="Remove"
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="trash" />
    </span>
  )
}

export const EditButton = ({ onClick, extraStyle }) => {
  return (
    <span
      className={'button button--blue button--round'}
      title="Edit"
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="edit" />
    </span>
  )
}

export const EditOutlineButton = ({ onClick, extraStyle }) => {
  return (
    <span
      className={'button button--bordered button--round'}
      title="Edit"
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="edit" />
    </span>
  )
}

export const AddOutlineButton = ({ onClick, extraStyle, newTitle }) => {
  return (
    <span
      className={'button button--white button--round'}
      title={newTitle ? newTitle : 'Add'}
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="plus" />
    </span>
  )
}

export const DownloadButton = ({ onClick, extraStyle, newTitle }) => {
  return (
    <span
      className={'button button--blue button--round'}
      title={newTitle ? newTitle : 'Download'}
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="download" />
    </span>
  )
}
export const DeleteOutlineButton = ({ onClick, extraStyle }) => {
  return (
    <span
      className={'button button--white button--round'}
      title="Remove"
      onClick={e => onClick(e)}
      style={extraStyle}
    >
      <FontAwesome name="trash" />
    </span>
  )
}
