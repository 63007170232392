import { SAVE_DATA_TO_MARKS_REPORT, APP_LOGOUT } from '../../helpers/actions'

const initialState = {
  umbrellaGroup: '',
  groupId: '',
  testId: ''
}

export const marksReport = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_TO_MARKS_REPORT:
      return Object.assign({}, state, action.data)
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getUiMarksReportData = state => state.ui.marksReport

export const groupIdForMarks = state => state.ui.marksReport.groupId

export const testIdForMarks = state => state.ui.marksReport.testId
