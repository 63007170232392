// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import enums
import { STUDENT_DETAILS } from '../../helpers/enums'

class TableHead extends Component {
  static propTypes = {
    tableHeaders: PropTypes.array.isRequired,
    groupSubjectIds: PropTypes.array.isRequired,
    subjectsById: PropTypes.object.isRequired
  }

  getItems() {
    const {
      setSortBy,
      sortBy,
      direction,
      toggleDirection,
      tableHeaders
    } = this.props
    return tableHeaders.map((header, index) => {
      const active = header === sortBy
      const disableSort = header === 'serial_number'
      return (
        <HeadToggle
          key={header}
          active={active}
          disableSort={disableSort}
          onClick={() => {
            if (active) {
              toggleDirection()
            }
            setSortBy(header)
          }}
        >
          {this.getTableHeaderCell(header)}{' '}
          {active ? (direction === 'asc' ? '▲' : '▼') : null}
        </HeadToggle>
      )
    })
  }

  getTableHeaderCell(header) {
    const { subjectsById, groupSubjectIds } = this.props

    if (groupSubjectIds.indexOf(header) !== -1) {
      return subjectsById[header].name
    }

    let value
    Object.keys(STUDENT_DETAILS).some(headerValue => {
      return Object.keys(STUDENT_DETAILS[headerValue]).some(studentKey => {
        if (header === studentKey) {
          value = STUDENT_DETAILS[headerValue][studentKey]
          return true
        }
        return false
      })
    })
    return value
  }

  render() {
    return (
      <thead className="table-head table-head--dark">
        <tr className="table-head-row">{this.getItems()}</tr>
      </thead>
    )
  }
}

const HeadToggle = ({ children, active, onClick, disableSort }) => {
  return (
    <th
      onClick={!disableSort ? onClick : ''}
      className={classNames({
        'table-head-row__cell': true,
        'table-head-row__cell--pointer': true,
        'table-head-row__cell--bold-font': active,
        'table-head-row__cell--normal-font': !active
      })}
    >
      {children}
    </th>
  )
}

export default TableHead
