import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'

const GroupInfo = ({ umbrellaGroup, groupName, test, subjectName }) => {
  const subjectname = subjectName ? ` / ${subjectName}` : ''
  return (
    <div className="date-and-class">
      <FontAwesome name="pencil" />
      <span>{` ${umbrellaGroup} / ${groupName} - ${test}` + subjectname}</span>
    </div>
  )
}

GroupInfo.propTypes = {
  umbrellaGroup: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  test: PropTypes.string.isRequired
}

export default GroupInfo
