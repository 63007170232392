import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

//reducers
import { isLoggedIn } from '../../../reducers/accounts'

//components
import ErrorBoundary from '../../common/layout/errorBoundary'
import { CoverLayout } from '../../common/layout/layoutComponent'
import { Login } from '../../../components/login/login'
import LoginComponent from '../../account/login'
const ForgotPasswordComponent = lazy(() =>
  import('../../account/forgotPassword')
)

const PublicRouter = () => {
  const loggedIn = useSelector(isLoggedIn)

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <CoverLayout
              exact
              path="/"
              name="login"
              component={LoginComponent}
            />
            <CoverLayout
              exact
              path="/login"
              name="login"
              component={LoginComponent}
            />
            <CoverLayout
              exact
              path="/reset-password"
              name="reset-password"
              component={ForgotPasswordComponent}
            />
            {!loggedIn && <Redirect to="/login" />}
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default PublicRouter
