// import actions
import {
  START_FETCH_NAVLIST_FOR_HEADER,
  SET_NAVLIST_DATA_FOR_HEADER,
  NAVLIST_DATA_FOR_HEADER_FAILED,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  navlistData: []
}

export const navlist = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_FETCH_NAVLIST_FOR_HEADER:
      return Object.assign({}, state, {
        isLoading: true
      })
    case SET_NAVLIST_DATA_FOR_HEADER:
      return Object.assign({}, state, {
        navlistData: action.data,
        isLoading: false
      })
    case NAVLIST_DATA_FOR_HEADER_FAILED:
      return Object.assign({}, state, {
        isLoading: false
      })
    default:
      return state
  }
}

export const isLoading = state => state.navlist.isLoading
export const getNavlistData = state => state.navlist.navlistData

export default navlist
