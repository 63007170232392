import {
  APP_LOGOUT,
  TIMETABLE_FETCH_START,
  TIMETABLE_FETCH_FAIL,
  TIMETABLE_DATA,
  TIMETABLE_POST_SUCCESS,
  TIMETABLE_POST_FAILURE
} from '../helpers/actions'
import { createSelector } from 'reselect'

//libs
import _ from 'underscore'
// import selectors
import { getHoursByDay } from './hours'
// import helper functions
import { getAllWeekDays } from '../helpers/utils'
import { union } from 'lodash'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  isErr: false,
  errMsg: '',
  postSuccess: false,
  postData: {}
}

export const timetable = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case TIMETABLE_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        postSuccess: false
      })
    case TIMETABLE_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        postSuccess: false
      })
    case TIMETABLE_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.entities,
        allIds: action.result,
        postSuccess: false
      })
    case TIMETABLE_POST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        postSuccess: true,
        byId: {
          ...state.byId,
          [action.data.hour]: action.data
        },
        allIds: union(state.allIds, [action.data.hour])
      })
    case TIMETABLE_POST_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        postSuccess: false
      })
    default:
      return state
  }
}

export const byId = state => state.timetable.byId
export const allIds = state => state.timetable.allIds
export const isTimetableLoading = state => state.timetable.isLoading
export const getPostStatus = state => state.timetable.postSuccess

export const getPostData = state => state.timetable.postData

export const timetableArr = createSelector(byId, allIds, (byId, allIds) => {
  if (!allIds || allIds.length === 0) return []
  return allIds.map(id => byId[id])
})

export const getTimeTableByHour = createSelector(
  byId,
  allIds,
  (byId, allIds) => {
    console.log('byId: ', byId)
    if (!allIds || allIds.length === 0) return {}
    const obj = {}
    allIds.forEach(id => {
      obj[byId[id]?.hour] = byId?.[id]
    })
    return obj
  }
)

export const getTimeTableGroupByHour = createSelector(
  byId,
  allIds,
  (byId, allIds) => {
    if (!allIds || allIds.length === 0) return {}
    const timetableArray = allIds.map((id, index) => {
      return byId[id]
    })
    return _.groupBy(timetableArray, x => x.hour)
  }
)

export const getTimeTableByStudentGroup = createSelector(
  byId,
  allIds,
  (byId, allIds) => {
    if (!allIds || allIds.length === 0) return {}
    const obj = {}
    allIds.forEach(id => {
      if (Object.keys(obj).indexOf(byId?.[id]?.studentGroup) !== -1) {
        obj[byId[id].studentGroup][byId[id].hour] = byId[id]
      } else {
        obj[byId[id]?.studentGroup] = {}
        obj[byId[id]?.studentGroup][byId[id]?.hour] = byId[id]
      }
    })
    return obj
  }
)

export const getTimetableByHourForMultipleGroups = (state, hourIds) => {
  const allTimetableData = timetableArr(state)
  if (allTimetableData.length === 0) return {}
  const obj = {}
  allTimetableData
    .filter(timetableObj => hourIds.includes(timetableObj.hour))
    .forEach(timetableObj => {
      const { hour, studentGroup: groupId } = timetableObj
      if (Object.keys(obj).includes(hour)) {
        obj[hour][groupId] = timetableObj
      } else {
        obj[hour] = {
          [groupId]: timetableObj
        }
      }
    })
  return obj
}

export const getTimetableByDay = createSelector(
  timetableArr,
  getHoursByDay,
  (timetableArr, hoursByDayObj) => {
    if (timetableArr.length === 0 || Object.keys(hoursByDayObj).length === 0)
      return {}
    const obj = {}
    // to have all the days in the obj atleast an empty array
    getAllWeekDays().forEach(day => {
      obj[day] = []
    })
    timetableArr.forEach(ttObj => {
      const { hour } = ttObj
      let hourInDay = ''
      Object.keys(hoursByDayObj).some(day => {
        if (hoursByDayObj[day].includes(hour)) {
          hourInDay = day
          obj[hourInDay].push(ttObj)
          return true
        }
        return false
      })
    })
    return obj
  }
)
