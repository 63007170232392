import {
  FETCH_MAIL_BY_STATUS,
  POST_MAIL_DATA,
  MAIL_POST_FAILURE,
  SET_POST_MAIL_STATUS,
  SET_POST_MAIL,
  MAIL_FETCH_START,
  GET_EMAIL_START,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAIL,
  GET_EMAIL_LOGS_BY_EMAILID_START,
  GET_EMAIL_LOGS_BY_EMAILID,
  GET_EMAIL_LOGS_BY_EMAILID_FAIL
} from '../helpers/actions.js'
import querystring from 'query-string'
import { emitAuthFailed } from './login.js'
import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'

// post actions
const postNewMail = data => {
  let Authorization = composeAuth(data.jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/mail'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      subject: data.subject,
      body: data.body,
      attachments: data.attachments,
      receipents: data.receipents,
      recipient_email_type: data.recipient_email_type
    })
  })
}

export const startNewMail = data => {
  return function(dispatch) {
    dispatch(emitPostStart(false))
    return postNewMail(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Email sent successfully')
        dispatch(emitPostSmsResponse(json))
      })
      .catch(err => {
        errorToast('Unable to send Email')
        dispatch(emitPostFailure(err))
      })
  }
}

export const emitPostStart = data => ({
  type: SET_POST_MAIL_STATUS,
  data
})

const emitPostFailure = err => ({
  type: MAIL_POST_FAILURE,
  data: err
})

// get actions
const fetchSms = ({ jwt, status, level }) => {
  let url
  switch (level) {
    case 'principal':
    case 'admissionOfficer':
    case 'admin':
      url =
        process.env.REACT_APP_SERVER_NEW +
        `/api/sms/school/` +
        encodeURIComponent(status)
      break
    default:
      url =
        process.env.REACT_APP_SERVER_NEW +
        `/api/sms/support/status?` +
        querystring.stringify({ status: status })
      break
  }

  let Authorization = composeAuth(jwt)
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchSms = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchSms(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Email sent successfully')
        dispatch(emitSmsData(json.data))
      })
      .catch(err => {
        console.error(err)
        errorToast('Unable to send Email')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const emitStart = () => ({
  type: MAIL_FETCH_START
})

const emitSmsData = data => {
  const entities = {}
  const result = data.map(smsObj => {
    entities[smsObj._id] = smsObj
    return smsObj._id
  })
  const payload = { entities, result }
  return {
    type: FETCH_MAIL_BY_STATUS,
    payload
  }
}

// patch actions
const sendSms = (jwt, data) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/sms/` +
    encodeURIComponent(data._id)
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      status: data.status
    })
  })
}

export const startSendSms = ({ jwt, data }) => {
  return function(dispatch) {
    return sendSms(jwt, data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('data inside startSendSms', json)
        dispatch(emitPostSmsResponse(json))
        successToast('Email sent successfully')
      })
      .catch(err => {
        console.error(err)
        errorToast('Unable to send Email')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

export const emitPostSmsResponse = json => ({
  type: POST_MAIL_DATA,
  data: json.data,
  message: json.message
})

export const setPostMessage = () => ({
  type: SET_POST_MAIL
})

const fetchSmsForLoggedInUser = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/sms/smslist`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchSmsForLoggedInUser = data => dispatch => {
  return fetchSmsForLoggedInUser(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startFetchSmsForLoggedInUser', json)
      dispatch(emitSmsData(json.data))
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

//fetch email
const emitEmailStart = () => ({
  type: GET_EMAIL_START
})
const emitEmailSuccess = data => {
  const entities = {}
  const result = data.map(email => {
    entities[email._id] = email
    return email._id
  })
  const payload = { entities, result }
  return {
    type: GET_EMAIL_SUCCESS,
    payload
  }
}
const emitEmailFail = () => ({
  type: GET_EMAIL_FAIL
})

const fetchEmail = ({ jwt, status }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/mail/support?' +
    querystring.stringify({ status: status })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFectchEmail = data => dispatch => {
  dispatch(emitEmailStart())
  return fetchEmail(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startFetchEmail', json.data)
      dispatch(emitEmailSuccess(json.data))
    })
    .catch(err => {
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(emitEmailFail())
    })
}

// get email logs by email id
const emitGetEmailLogsByEmailIdStart = () => ({
  type: GET_EMAIL_LOGS_BY_EMAILID_START
})
const emitGetEmailLogsByEmailId = data => ({
  type: GET_EMAIL_LOGS_BY_EMAILID,
  data
})

const emitGetEmailLogsByEmailIdFail = () => ({
  type: GET_EMAIL_LOGS_BY_EMAILID_FAIL
})

const getEmailLogsByEmailId = ({ jwt, id }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/mail/delivery/email/${id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGetEmailLogsByEmailId = data => dispatch => {
  dispatch(emitGetEmailLogsByEmailIdStart())
  return getEmailLogsByEmailId(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitGetEmailLogsByEmailId(json.data))
    })
    .catch(err => {
      dispatch(emitGetEmailLogsByEmailIdFail())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}
