import React from 'react'

//components
import PublicRouter from './publicRouter'
import PrivateRouter from './privateRouter'
import { isLoggedIn, isSupportLoggedIn } from '../../reducers/accounts'
import { useSelector } from 'react-redux'

export const SealtabsRouter = () => {
  const loggedIn = useSelector(isLoggedIn)
  const supportLoggedIn = useSelector(isSupportLoggedIn)

  return loggedIn || supportLoggedIn ? <PrivateRouter /> : <PublicRouter />
}
