// import packages
import React, { Component } from 'react'

class TableHead extends Component {
  getItems() {
    const { tableHeaders } = this.props
    return tableHeaders.map((key, index) => {
      return <th key={index}>{key}</th>
    })
  }

  render() {
    return (
      <thead className="table-head table-head--dark">
        <tr className="table-head-row">{this.getItems()}</tr>
      </thead>
    )
  }
}

export default TableHead
