import {
  SET_ACTIVE_APPLICATION,
  SET_STATUS_FILTER,
  APP_LOGOUT
} from '../../helpers/actions'

const initialState = {
  activeApplication: '',
  statusFilter: ''
}

export const application = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SET_ACTIVE_APPLICATION:
      return Object.assign({}, state, {
        activeApplication: action.id
      })
    case SET_STATUS_FILTER:
      return Object.assign({}, state, { statusFilter: action.filter })
    default:
      return state
  }
}

export const getActiveApplication = state =>
  state.ui.application.activeApplication
export const getStatusFilter = state => state.ui.application.statusFilter
