import { normalize } from 'normalizr'
import { reset } from 'redux-form'
import querystring from 'query-string'
import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'

import {
  STUDENTS_DATA,
  FLATTENED_STUDENTS_DATA,
  SET_SEARCH_FILTER_FOR_STUDENT_LIST,
  SET_SEARCH_FILTER_SUBJECTS_FOR_STUDENT_LIST,
  FLATTENED_STUDENTS_GET_STARTED,
  STUDENTS_GET_STARTED,
  ADD_STUDENT_START,
  ADD_STUDENT_SUCCESS,
  ADD_STUDENT_FAIL,
  RESET_STUDENT_STORE,
  ADD_NEW_STUDENT,
  ADD_STUDENT_TO_GROUP,
  EDIT_STUDENT,
  CHECK_ADMISSION_NUMBER_UNIQUENESS,
  UPDATE_ADMISSION_GROUP,
  STUDENT_RESET_PASSWORD_START,
  STUDENT_RESET_PASSWORD_SUCCESS,
  STUDENT_RESET_PASSWORD_FAIL,
  STUDENTS_SEARCH_DATA,
  SET_ARCHIVE_FILTER_FOR_STUDENT_LIST,
  ADD_STUDENT_CSV_FAIL,
  FETCH_ALL_STUDENT_GROUPS_SUCCESS,
  FETCH_ADMISSION_GROUPS_FAIL,
  ADD_STUDENT_USERS_START,
  ADD_STUDENT_USERS,
  ADD_STUDENT_USERS_FAIL,
  REMOVE_STUDENT_START,
  REMOVE_STUDENT_SUCCESS,
  REMOVE_STUDENT_FAIL,
  GET_STUDENT_EMAIL_LOGS_START,
  GET_STUDENT_EMAIL_LOGS,
  GET_STUDENT_EMAIL_LOGS_FAIL,
  STUDENT_UPLOAD_PROFILE_PIC_START,
  STUDENT_UPLOAD_PROFILE_PIC,
  STUDENT_UPLOAD_PROFILE_PIC_FAIL,
  GET_STUDENT_HOUSE_COUNT_FAIL,
  GET_STUDENT_HOUSE_COUNT_START,
  GET_STUDENT_HOUSE_COUNT_SUCCESS
} from '../helpers/actions.js'

import { GROUPS_GROUP_TYPE, ADDSTUDENT_FORM_NAME } from '../helpers/enums'

import {
  startFetchGroups,
  emitPatchGroupData,
  emitStart as emitGroupStart,
  emitSuccess,
  emitFailure,
  emitStartFetch as emitStartFetchGroups
} from './groups'
import { saveData } from './ui/studentProfile'
import { studentListSchema } from '../schemas/student.js'
import { emitAuthFailed } from './login.js'
import { fileUpload } from './fileUpload'

export const emitData = (entities, result) => ({
  type: STUDENTS_DATA,
  entities,
  result
})

export const emitStart = () => ({
  type: STUDENTS_GET_STARTED
})

export const fetchData = ({ jwt, students }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/ids?' +
    querystring.stringify({ ids: students })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const fetchDataLong = ({ jwt, students }) => {
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/student/prolonged'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      ids: students
    })
  })
}

export const startFetchData = data => {
  return function(dispatch) {
    dispatch(emitStart())
    if (data.students && data.students.length > 100) {
      return fetchDataLong(data)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          dispatch(emitFlattenedData(json.data))
          const normalizedGroups = normalize(json.data, studentListSchema)
          const { entities, result } = normalizedGroups
          dispatch(emitData(entities, result))
        })
        .catch(err => {
          console.error(err)
          if (err instanceof AuthError) dispatch(emitAuthFailed())
        })
    } else {
      return fetchData(data)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          dispatch(emitFlattenedData(json.data))
          const normalizedGroups = normalize(json.data, studentListSchema)
          const { entities, result } = normalizedGroups
          dispatch(emitData(entities, result))
        })
        .catch(err => {
          console.error(err)
          if (err instanceof AuthError) dispatch(emitAuthFailed())
        })
    }
  }
}
export const fetchDataForUmbrellaGroup = ({ jwt, groupIds }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/studentGroups?' +
    querystring.stringify({ studentGroups: groupIds })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchDataForUmbrellaGroup = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchDataForUmbrellaGroup(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitFlattenedData(json.data))
        const normalizedGroups = normalize(json.data, studentListSchema)
        const { entities, result } = normalizedGroups
        dispatch(emitData(entities, result))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

// Create Student
const addStudentStart = () => ({
  type: ADD_STUDENT_START
})

const addStudentSuccess = () => ({
  type: ADD_STUDENT_SUCCESS
})

const addStudentFail = err => ({
  type: ADD_STUDENT_FAIL,
  err
})
const addCSVStudentFail = () => ({
  type: ADD_STUDENT_CSV_FAIL
})
export const addNewStudent = ({ jwt, body }) => {
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/student'

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}

export const addStudentToGroup = ({ jwt, sectionId, studentId }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/${sectionId}/addstudent`
  const body = {
    students: [studentId]
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}

export const startAddStudentToGroup = data => dispatch => {
  dispatch(emitGroupStart())
  return addStudentToGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Successfully added student to the class')
    })
    .catch(err => {
      dispatch(emitFailure())
      errorToast('Unable to add student. Try Again!')
    })
}

export const addStudent = data => {
  const { jwt, sectionId } = data
  return function(dispatch) {
    dispatch(addStudentStart())
    addNewStudent(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        return json
      })
      .then(data => {
        dispatch(addNewStudentToStore(data.name))
        const obj = {
          jwt,
          sectionId,
          studentId: data.name._id
        }
        addStudentToGroup(obj)
          .then(checkStatus)
          .then(parseJSON)
          .then(res => {
            dispatch(addStudentToGroupSuccess(res.data))
            successToast('Student added successfully')
            dispatch(reset(ADDSTUDENT_FORM_NAME))
          })
      })
      .catch(err => {
        errorToast('Student could not be created, Please try again.')
        dispatch(addStudentFail(err))
      })
  }
}

export const addStudentToStudentGroup = ({ jwt, body }) => {
  return function(dispatch) {
    dispatch(addStudentStart())
    addNewStudent({ jwt, body })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(addStudentToGroupSuccess(json.data))
        dispatch(addStudentSuccess())
        successToast('Student added successfully')
        dispatch(reset(ADDSTUDENT_FORM_NAME))
      })
      .catch(err => {
        errorToast('Student could not be created, Please try again.')
        dispatch(addStudentFail(err))
      })
  }
}

const addNewStudentToStore = data => ({
  type: ADD_NEW_STUDENT,
  data
})

const addStudentToGroupSuccess = data => ({
  type: ADD_STUDENT_TO_GROUP,
  data
})

// edit student
export const edit = ({ id, jwt, body }) => {
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/student/${id}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}

export const editStudent = data => {
  return function(dispatch) {
    dispatch(addStudentStart())
    edit(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Student Updated Successfully.')
        dispatch(updateExistingStudent(json.data))
      })
      .catch(err => {
        errorToast('Update student failed, Please try again.')
        dispatch(addStudentFail(err))
      })
  }
}

const updateExistingStudent = data => ({
  type: EDIT_STUDENT,
  data
})

// upload csv
const uploadCSV = ({ jwt, studentGroupId, path }) => {
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/student/csv/${studentGroupId}`

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      path
    })
  })
}

export const startUploadCSV = data => dispatch => {
  dispatch(addStudentStart())
  return uploadCSV(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('csv uploaded successfully')
      dispatch(
        startFetchGroups({
          jwt: data.jwt,
          type: GROUPS_GROUP_TYPE[0]
        })
      )
      dispatch(addStudentSuccess())
    })
    .catch(err => {
      errorToast(err)
      dispatch(addStudentFail(err))
    })
}

//bulk edit student
const bulkEditStudent = ({ jwt, path }) => {
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/student/bulk/edit`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ filepath: path })
  })
}

export const startBulkEditStudent = data => dispatch => {
  dispatch(addStudentStart())
  return bulkEditStudent(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('csv uploaded successfully')
      dispatch(
        startFetchGroups({
          jwt: data.jwt,
          type: GROUPS_GROUP_TYPE[0]
        })
      )
      dispatch(addStudentSuccess())
    })
    .catch(err => {
      errorToast(err)
      dispatch(addStudentFail(err))
    })
}

// fetch flattened data
export const startFetchFlattenedData = data => {
  return function(dispatch) {
    dispatch(emitStartFlattenedData())
    if (data.students && data.students.length > 100) {
      return fetchDataLong(data)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          dispatch(emitFlattenedData(json.data))
          const normalizedGroups = normalize(json.data, studentListSchema)
          const { entities, result } = normalizedGroups
          dispatch(emitData(entities, result))
        })
        .catch(err => {
          console.error(err)
          if (err instanceof AuthError) dispatch(emitAuthFailed())
        })
    } else {
      return fetchData(data)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          dispatch(emitFlattenedData(json.data))
          const normalizedGroups = normalize(json.data, studentListSchema)
          const { entities, result } = normalizedGroups
          dispatch(emitData(entities, result))
        })
        .catch(err => {
          console.error(err)
          if (err instanceof AuthError) dispatch(emitAuthFailed())
        })
    }
  }
}

const emitStartFlattenedData = () => ({
  type: FLATTENED_STUDENTS_GET_STARTED
})

const emitFlattenedData = data => {
  const entities = {}
  const result = []
  data.forEach(studentObj => {
    let studentInfoFlattened = {}
    flattenStudentData(studentObj, studentInfoFlattened)
    entities[studentObj._id] = studentInfoFlattened
    result.push(studentObj._id)
  })
  return {
    type: FLATTENED_STUDENTS_DATA,
    entities,
    result
  }
}

const flattenStudentData = (studentObj, studentInfoFlattened) => {
  Object.keys(studentObj).forEach(studentKey => {
    flattenEachKey(studentObj, studentKey, studentInfoFlattened)
  })
}

const flattenEachKey = (studentObj, studentKey, studentInfoFlattened) => {
  switch (studentKey) {
    case 'addresses':
      handleAddress(studentObj, studentKey, studentInfoFlattened)
      break
    case 'current_class':
      objectHandler(studentObj, studentKey, studentInfoFlattened)
      break
    case 'joining':
      objectHandler(studentObj, studentKey, studentInfoFlattened)
      break
    case 'parents':
      handleParents(studentObj, studentKey, studentInfoFlattened)
      break
    case 'phone':
      handlePhone(studentObj, studentKey, studentInfoFlattened)
      break
    case 'previous_school':
      objectHandler(studentObj, studentKey, studentInfoFlattened)
      break
    case 'passport':
      objectHandler(studentObj, studentKey, studentInfoFlattened)
      break
    default:
      handleStringData(studentObj, studentKey, studentInfoFlattened)
      break
  }
}

const objectHandler = (studentObj, studentKey, studentInfoFlattened) => {
  Object.keys(studentObj[studentKey]).forEach(item => {
    Object.assign(studentInfoFlattened, {
      [`${studentKey}_${item}`]: studentObj[studentKey]
        ? studentObj[studentKey][item]
          ? studentObj[studentKey][item]
          : ''
        : ''
    })
  })
}

const handleStringData = (studentObj, studentKey, studentInfoFlattened) => {
  Object.assign(studentInfoFlattened, {
    [studentKey]:
      studentObj[studentKey] !== undefined ? studentObj[studentKey] : ''
  })
}

const handleAddress = (studentObj, studentKey, studentInfoFlattened) => {
  studentObj[studentKey].forEach(address => {
    Object.assign(studentInfoFlattened, {
      [`addresses_${address.type}`]: address.address ? address.address : ''
    })
  })
}

const handleParents = (studentObj, studentKey, studentInfoFlattened) => {
  studentObj[studentKey].forEach(parent => {
    const type = parent.type
    Object.keys(parent).forEach(parentItem => {
      if (parentItem !== 'type') {
        Object.assign(studentInfoFlattened, {
          [`${type}_${parentItem}`]: parent[parentItem]
        })
      }
    })
  })
}

const handlePhone = (studentObj, studentKey, studentInfoFlattened) => {
  studentObj[studentKey].forEach(phone => {
    const belongs = phone.belongs
    Object.keys(phone).forEach(phoneItem => {
      if (phoneItem !== 'belongs') {
        Object.assign(studentInfoFlattened, {
          [`${belongs}_${phoneItem}`]: phone[phoneItem]
            ? phone[phoneItem]
            : false
        })
      }
    })
  })
}

export const setArchiveTypeFilter = data => ({
  type: SET_ARCHIVE_FILTER_FOR_STUDENT_LIST,
  data
})
export const setSearchFilter = data => ({
  type: SET_SEARCH_FILTER_FOR_STUDENT_LIST,
  data
})

export const setSearchFilterSubjects = data => ({
  type: SET_SEARCH_FILTER_SUBJECTS_FOR_STUDENT_LIST,
  data
})

export const resetStudentStore = () => ({
  type: RESET_STUDENT_STORE
})

export const emitAllStudentGroups = data => ({
  type: FETCH_ALL_STUDENT_GROUPS_SUCCESS,
  data
})

export const fetchStudentStudentGroup = ({ jwt, students }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/student?' +
    querystring.stringify({ student: students })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchStudentStudentGroup = data => dispatch => {
  dispatch(emitStartFetchGroups())
  return fetchStudentStudentGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      /******** Note: This code needs to be refactored to handle multiple student groups *******/
      if (json.data && json.data.length > 0) {
        let academicGroup = json.data.find(
          item => item?.group_type === 'academics'
        )
        dispatch(
          saveData({
            groupId: academicGroup['_id'],
            groupType: academicGroup['group_type'],
            groupName: academicGroup['group_name'],
            umbrellaGroup: academicGroup['umbrella_group']
          })
        )
        dispatch(updateGroupData(academicGroup))
        dispatch(emitAllStudentGroups(json.data))
      }
      dispatch(emitSuccess())
      /******************** End Note ***************/
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

export const fetchStudentStudentGroupAcademicyear = ({
  jwt,
  students,
  academic_year
}) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/student/' +
    encodeURIComponent(JSON.stringify(students)) +
    '/academicyear/' +
    academic_year
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchStudentStudentGroupAcademicyear = data => dispatch => {
  dispatch(emitStartFetchGroups())
  return fetchStudentStudentGroupAcademicyear(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      /******** Note: This code needs to be refactored to handle multiple student groups *******/
      dispatch(
        saveData({
          groupId: json.data[0]['_id'],
          groupType: json.data[0]['group_type'],
          umbrellaGroup: json.data[0]['umbrella_group']
        })
      )
      dispatch(updateGroupData(json.data[0]))
      dispatch(emitSuccess())
      /******************** End Note ***************/
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

const emitAdmissionGroup = group => ({
  type: UPDATE_ADMISSION_GROUP,
  group
})

const removeStudentStart = () => ({
  type: REMOVE_STUDENT_START
})

const removeStudentSuccess = () => ({
  type: REMOVE_STUDENT_SUCCESS
})

const removeStudentFail = () => ({
  type: REMOVE_STUDENT_FAIL
})

const removeStudentsFromGroup = ({ jwt, sectionId, studentIds }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/' +
    encodeURIComponent(sectionId) +
    '/removestudent'

  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({ students: studentIds })
  })
}

export const startRemoveStudentsFromGroup = data => dispatch => {
  dispatch(removeStudentStart())
  return removeStudentsFromGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('Students Removed Successfully')
      dispatch(removeStudentSuccess())
    })
    .catch(err => {
      console.error(err)
      errorToast('Failed to remove students')
      dispatch(removeStudentFail())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

export const startUpdateStudentSection = ({
  jwt,
  sectionId,
  studentId,
  newSectionId
}) => dispatch => {
  const obj = {
    jwt,
    sectionId: newSectionId,
    studentId
  }
  return addStudentToGroup(obj)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(updateGroupData(json.data))
      return removeStudentsFromGroup({
        jwt,
        sectionId,
        studentIds: [studentId]
      })
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          successToast('successfully Change Class')
          dispatch(updateGroupData(json.data))
        })
        .catch(err => {
          errorToast('Failed to Remove Student From Old Group', err)
        })
    })
    .catch(err => {
      errorToast('Failed to Add Student To New Group', err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

const updateGroupData = group => {
  switch (group['group_type']) {
    case 'admission':
      return emitAdmissionGroup(group)
    case 'academics':
      return emitPatchGroupData(group)
    default:
      return () => {}
  }
}

// check admission number
const checkAdmissionNumberUniqueness = data => ({
  type: CHECK_ADMISSION_NUMBER_UNIQUENESS,
  data
})
const checkAdmissionNumber = ({ jwt, admissionNumber }) => {
  const Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/admissionNumber?' +
    querystring.stringify({ admissionNumber: admissionNumber })

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const admissionNumberChecker = data => {
  return function(dispatch) {
    return checkAdmissionNumber(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        // data.length > 0 means API returned a student
        json.data.length > 0
          ? dispatch(checkAdmissionNumberUniqueness(false))
          : dispatch(checkAdmissionNumberUniqueness(true))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

// reset password
export const startResetPassword = data => dispatch => {
  let levelResetPassword =
    data?.level === 'student' ? resetPassword : resetParentPassword
  dispatch(emitResetPasswordStart())
  return levelResetPassword(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast(' Password reset successfully')
      dispatch(resetPasswordSuccess())
    })
    .catch(err => {
      console.error(err)
      errorToast('Password reset failed')
      dispatch(resetPasswordFailure())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

const emitResetPasswordStart = () => ({
  type: STUDENT_RESET_PASSWORD_START
})

const resetParentPassword = ({ jwt, ids, level }) => {
  const Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/parent/reset/password/ids?' +
    querystring.stringify({ ids: ids })
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      ids
    })
  })
}

const resetPassword = ({ jwt, ids }) => {
  const Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/update/ids?' +
    querystring.stringify({ ids: ids })
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      ids
    })
  })
}

const resetPasswordSuccess = () => ({
  type: STUDENT_RESET_PASSWORD_SUCCESS
})

const resetPasswordFailure = () => ({
  type: STUDENT_RESET_PASSWORD_FAIL
})

export const emitStudentSearchData = (entities, result) => ({
  type: STUDENTS_SEARCH_DATA,
  entities,
  result
})

const fetchStudentSearch = ({ jwt, filter, search }) => {
  const Authorization = composeAuth(jwt)
  const url = `${
    process.env.REACT_APP_SERVER_NEW
  }/api/student/filter/${filter}/search/${encodeURIComponent(search)}`

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchStudentSearch = data => dispatch => {
  const { search } = data
  if (search === '') return null

  /*
  TODO: Right now there's multiple status flags for each action
  we can repurpose the addStudentstart action and everything
  can use that.
  */
  dispatch(addStudentStart())
  return fetchStudentSearch(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      // data.length > 0 means API returned a student
      dispatch(emitFlattenedData(json.data))
      const normalizedGroups = normalize(json.data, studentListSchema)
      const { entities, result } = normalizedGroups
      dispatch(emitStudentSearchData(entities, result))
      // dispatch(emitFlattenedData(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(addStudentFail())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

// add new admission student
export const addAdmissionStudent = data => {
  const { body, jwt } = data
  const bodyClone = Object.assign({}, body)
  delete bodyClone.transaction
  const sectionId = bodyClone.current_class.section
  return function(dispatch) {
    dispatch(addStudentStart())
    addNewStudent({ jwt, body: bodyClone })
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        return json.name
      })
      .then(studentObj => {
        const studentId = studentObj._id
        let promiseArr = [
          addStudentToGroupPromise({ jwt, sectionId, studentId }),
          addFeeReceiptWithoutFeeStructurePromise({
            jwt,
            payload: {
              studentId,
              studentGroupId: sectionId,
              transaction: body.transaction
            }
          })
        ]
        Promise.all(promiseArr)
          .then(res => {
            successToast('Post Success')
          })
          .catch(err => {
            errorToast('Post unsuccessful, try again later')
          })
      })
      .catch(err => {
        errorToast('Error in adding student')
      })
  }
}

// return promise of adding student to a group
const addStudentToGroupPromise = data => {
  return addStudentToGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => json.data)
}

// return promise of adding fee receipt without fee structure
const addFeeReceiptWithoutFeeStructurePromise = data => {
  return addFeeReceiptWithoutFeeStructure(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      return json.data
    })
}

// Add fee receipt for fees paid by a student without fee structure.
const addFeeReceiptWithoutFeeStructure = data => {
  const { jwt, payload } = data
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + '/api/student/feereceiptonly'

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

// csv upload for the promotion next class
const fetchStudentsFromCSV = ({ jwt, csvLink }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/data/file/' +
    encodeURIComponent(csvLink)

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchStudentsFromCSV = data => {
  return function(dispatch) {
    dispatch(emitStart())
    return fetchStudentsFromCSV(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitFlattenedData(json.data))
        const normalizedGroups = normalize(json.data, studentListSchema)
        const { entities, result } = normalizedGroups
        dispatch(emitData(entities, result))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(addCSVStudentFail())
      })
  }
}

//refactor it lator with startAddStudentToGroup
export const addStudentToPtomotionalGroup = ({
  jwt,
  sectionId,
  studentIds
}) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/studentgroup/${sectionId}/addstudent`
  const body = {
    students: studentIds
  }
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}

export const startAddStudentToPromotionGroup = data => dispatch => {
  dispatch(emitGroupStart())
  return addStudentToPtomotionalGroup(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitPatchGroupData(json.data))
      dispatch(emitSuccess())
      successToast('Students Updated Successfully')
    })
    .catch(err => {
      dispatch(emitFailure())
      errorToast('Students Update unsuccessful, try again later')
    })
}

//add bulk student users
const emitAddStudentUsersBulkStart = () => ({
  type: ADD_STUDENT_USERS_START
})
const emitAddStudentUsersBulkSuccess = () => ({
  type: ADD_STUDENT_USERS
})

const emitAddStudentUsersBulkFailure = () => ({
  type: ADD_STUDENT_USERS_FAIL
})

const addStudentUsersBulk = ({ jwt, students }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/student/update/ids?' +
    querystring.stringify({ ids: students })
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startAddStudentUsersBulk = data => dispatch => {
  dispatch(emitAddStudentUsersBulkStart())
  return addStudentUsersBulk(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('Student users Created')
      dispatch(emitAddStudentUsersBulkSuccess())
    })
    .catch(err => {
      console.log('err: ', err)
      errorToast('Student users creation failed')
      dispatch(emitAddStudentUsersBulkFailure())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

//list of boarders in the entire institution
export const fetchStudentBoarders = ({ jwt, academicYear }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/student/boarders/academic_year/` +
    encodeURIComponent(academicYear)
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchStudentBoarders = data => dispatch => {
  dispatch(emitStartFlattenedData())
  return fetchStudentBoarders(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitFlattenedData(json.data))
    })
    .catch(err => {
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

// get student email logs
const emitGetStudentEmailLogsStart = () => ({
  type: GET_STUDENT_EMAIL_LOGS_START
})
const emitGetStudentEmailLogs = data => ({
  type: GET_STUDENT_EMAIL_LOGS,
  data
})

const emitGetStudentEmailLogsFail = () => ({
  type: GET_STUDENT_EMAIL_LOGS_FAIL
})

const getStudentEmailLogs = ({ jwt, id }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW + `/api/mail/delivery/levelId/${id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGetStudentEmailLogs = data => dispatch => {
  dispatch(emitGetStudentEmailLogsStart())
  return getStudentEmailLogs(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(emitGetStudentEmailLogs(json.data))
    })
    .catch(err => {
      dispatch(emitGetStudentEmailLogsFail())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

//student upload profile picture
const emitStudentUploadProfilePicStart = () => ({
  type: STUDENT_UPLOAD_PROFILE_PIC_START
})
const emitStudentUploadProfilePicSuccess = () => ({
  type: STUDENT_UPLOAD_PROFILE_PIC
})

const emitStudentUploadProfilePicFail = () => ({
  type: STUDENT_UPLOAD_PROFILE_PIC_FAIL
})

const emitStudentUploadProfilePic = ({ jwt, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/student/profile-pic'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startStudentUploadProfilePic = ({ jwt, file }) => dispatch => {
  dispatch(emitStudentUploadProfilePicStart())
  return fileUpload({ jwt, body: file })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      let payload = {
        picture: json?.data?.publicUrl
      }
      return emitStudentUploadProfilePic({ jwt, payload })
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          dispatch(emitStudentUploadProfilePicSuccess(json.data))
          successToast('Profile Picture Uploaded Successfully')
        })
    })
    .catch(err => {
      console.error('error occured')
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      else dispatch(emitStudentUploadProfilePicFail(err.message))
      errorToast('Failed to upload. Try Again')
    })
}

//get student house count
const getStudentHouseCountStart = () => ({
  type: GET_STUDENT_HOUSE_COUNT_START
})
const getStudentHouseCountSuccess = houseCount => ({
  type: GET_STUDENT_HOUSE_COUNT_SUCCESS,
  houseCount
})

const getStudentHouseCountFail = errMsg => ({
  type: GET_STUDENT_HOUSE_COUNT_FAIL,
  errMsg
})

const getStudentHouseCount = ({ jwt, academicYear }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/student/house-count/academic_year/${academicYear}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGetStudentHouseCount = data => dispatch => {
  dispatch(getStudentHouseCountStart())
  return getStudentHouseCount(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(getStudentHouseCountSuccess(json.count))
    })
    .catch(err => {
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      else dispatch(getStudentHouseCountFail(err.message))
    })
}
