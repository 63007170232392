// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, Cell } from 'recharts'
import underscore from 'underscore'

class MultipleTestComparisonGraph extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  }
  getBarColor(percentage) {
    if (percentage >= 0 && percentage <= 30) return '#ff3838'
    else if (percentage >= 31 && percentage <= 60) return '#ff9f1a'
    else if (percentage >= 61 && percentage <= 74) return '#c56cf0'
    else if (percentage >= 75 && percentage <= 85) return '#7158e2'
    else return '#3ae374'
  }
  render() {
    const { data } = this.props

    let testList = data.map(dataObj => {
      return dataObj.testName.filter(name => dataObj[name] !== '')
    })
    testList = underscore.flatten(testList)
    const testNames = underscore.uniq(testList)
    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', height: '200' }}>
          {/* <ResponsiveContainer> */}
          <BarChart width={900} height={250} data={data}>
            <XAxis dataKey="name"></XAxis>
            <YAxis domain={[0, 100]} />

            {testNames.map(testName => (
              <Bar
                dataKey={testName}
                barSize={26}
                legendType="line"
                label={{ fill: '#000', fontSize: 12, position: 'top' }}
              >
                {' '}
                {data.map((dataObj, index) => {
                  const color = this.getBarColor(Number(dataObj[testName]))
                  return <Cell key={index} fill={color} />
                })}
              </Bar>
            ))}
          </BarChart>
          {/* </ResponsiveContainer> */}
        </div>
        <div className="legend">
          <span className="legend__item">
            <span className="box box--r" />{' '}
            <span>
              0 - 30
              {/* background: #ff3838; */}
              {/* 'rgba(254, 56, 36, 1)' */}
            </span>
          </span>
          <span className="legend__item">
            <span className="box box--o" />{' '}
            <span>
              31 - 60
              {/* 'rgba(255, 150, 0, 1)' */}
            </span>
          </span>
          <span className="legend__item">
            <span className="box box--p" />{' '}
            <span>
              61 - 74
              {/* 'rgba(102, 51, 153, 1)' */}
            </span>
          </span>
          <span className="legend__item">
            <span className="box box--b" />{' '}
            <span>
              75 - 85
              {/* 'rgba(84, 199, 252, 1)' */}
            </span>
          </span>
          <span className="legend__item">
            <span className="box box--g" />{' '}
            <span>
              91 - 100
              {/* 'rgba(68, 219, 94, 1)' */}
            </span>
          </span>
        </div>
      </div>
    )
  }
}

export default MultipleTestComparisonGraph
