import map from 'lodash/map'
import moment from 'moment'

import {
  APP_LOGOUT,
  FETCH_OVERALL_ATTENDANCE_START,
  FETCH_OVERALL_ATTENDANCE_SUCCESS,
  FETCH_OVERALL_ATTENDANCE_FAIL
} from './../helpers/actions'

const initialState = {
  isLoading: false,
  byStudent: {},
  allStudents: [],
  errorMsg: '',
  isError: false
}

export const monthDetails = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case FETCH_OVERALL_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        errorMsg: ''
      })
    case FETCH_OVERALL_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        byStudent: action.byStudent,
        allStudents: action.allStudents
      })
    case FETCH_OVERALL_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        errorMsg: 'Failed to fetch monthly details'
      })
    default:
      return state
  }
}

/* Selectors */

export const isLoading = state => state.reports.monthDetails.isLoading
export const getMergedAttdByStudentId = state =>
  state.reports.monthDetails.byStudent

export const getYearlyAttendance = state => {
  const byId = state.students.byId
  const monthDetails = state.reports.monthDetails.byStudent
  const studentIds = state.ui.attendance.studentIds
  if (Object.keys(byId).length === 0 || !studentIds || studentIds.length === 0)
    return []
  const newStudentsById = {}
  studentIds.forEach(id => {
    newStudentsById[id] = byId[id]
  })

  let attendanceArr = map(newStudentsById, val => {
    const attd = []
    attd.length = 12
    attd.fill({ month: '' })
    try {
      if (monthDetails?.[val?._id]) {
        monthDetails?.[val?._id].forEach(item => {
          if (item?.month) {
            attd[item.month - 1] = item
          } else {
            attd[Number(moment(item?.date).format('MM')) - 1] = item
          }
        })
      }
    } catch (err) {
      console.error('getYearlyAttdVals: ', err.message)
      return []
    }

    return {
      _id: val?._id,
      roll_number: val?.roll_number,
      studentName: val?.name,
      registration_number: val?.registration_number,
      attendance: attd
    }
  })
  attendanceArr = attendanceArr.sort((a, b) => {
    if (a?.studentName) {
      if (a?.studentName.toLowerCase() < b?.studentName.toLowerCase()) return -1
    }
    return 1
  })
  return attendanceArr
}

export const getYearlyAttdVals = (state, studentIds) => {
  const byId = state.students.byId
  const monthDetails = state.reports.monthDetails.byStudent
  if (Object.keys(byId).length === 0 || !studentIds || studentIds.length === 0)
    return []
  const newStudentsById = {}
  studentIds.forEach(id => {
    newStudentsById[id] = byId[id]
  })

  let attendanceArr = map(newStudentsById, val => {
    const attd = []
    attd.length = 12
    attd.fill({ month: '' })

    try {
      if (monthDetails[val._id]) {
        monthDetails[val._id].forEach(item => {
          attd[item.month - 1] = item
        })
      }
    } catch (err) {
      console.error('getYearlyAttdVals: ', err.message)
      return []
    }

    return {
      _id: val._id,
      roll_number: val.roll_number,
      studentName: val.name,
      attendance: attd
    }
  })
  attendanceArr = attendanceArr.sort((a, b) => {
    if (a.studentName) {
      if (a.studentName.toLowerCase() < b.studentName.toLowerCase()) return -1
    }
    return 1
  })
  return attendanceArr
}
