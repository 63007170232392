import {
  APP_LOGOUT,
  POST_PAYROLL_POLICY_START,
  POST_PAYROLL_POLICY_FAIL,
  POST_PAYROLL_POLICY_SUCCESS,
  GET_PAYROLL_POLICY_START,
  GET_PAYROLL_POLICY_FAIL,
  GET_PAYROLL_POLICY_SUCCESS,
  PATCH_PAYROLL_POLICY_START,
  PATCH_PAYROLL_POLICY_FAIL,
  PATCH_PAYROLL_POLICY_SUCCESS,
  POST_PAYROLL_POLICY_TO_TEACHER_START,
  POST_PAYROLL_POLICY_TO_TEACHER_FAIL,
  POST_PAYROLL_POLICY_TO_TEACHER_SUCCESS,
  GET_PAYROLL_POLICY_BY_TEACHER_ID_START,
  GET_PAYROLL_POLICY_BY_TEACHER_ID_FAIL,
  GET_PAYROLL_POLICY_BY_TEACHER_ID_SUCCESS
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isLoadingEdit: false,
  isLoadingTeacherPolicy: false,
  isErr: false,
  errMsg: '',
  policy: [],
  teacherPolicy: []
}

export const payrollPolicy = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_PAYROLL_POLICY_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErr: false,
        errMsg: ''
      })
    case POST_PAYROLL_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: false
      })
    case POST_PAYROLL_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case GET_PAYROLL_POLICY_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case GET_PAYROLL_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        policy: action.data
      })
    case GET_PAYROLL_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case PATCH_PAYROLL_POLICY_START:
      return Object.assign({}, state, {
        isLoadingEdit: true,
        isErr: false,
        errMsg: ''
      })
    case PATCH_PAYROLL_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoadingEdit: false,
        isErr: false
      })
    case PATCH_PAYROLL_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoadingEdit: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case POST_PAYROLL_POLICY_TO_TEACHER_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErr: false,
        errMsg: ''
      })
    case POST_PAYROLL_POLICY_TO_TEACHER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: false
      })
    case POST_PAYROLL_POLICY_TO_TEACHER_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case GET_PAYROLL_POLICY_BY_TEACHER_ID_START:
      return Object.assign({}, state, {
        isLoadingTeacherPolicy: true,
        isErr: false,
        errMsg: ''
      })
    case GET_PAYROLL_POLICY_BY_TEACHER_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoadingTeacherPolicy: false,
        isErr: false,
        teacherPolicy: action.data
      })
    case GET_PAYROLL_POLICY_BY_TEACHER_ID_FAIL:
      return Object.assign({}, state, {
        isLoadingTeacherPolicy: false,
        isErr: true,
        errMsg: action.error
      })
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.payrollPolicy.isLoading
export const getIsLoadingPost = state => state.payrollPolicy.isLoadingPost
export const getIsLoadingEdit = state => state.payrollPolicy.isLoadingEdit
export const getIsLoadingTeacherPolicy = state =>
  state.payrollPolicy.isLoadingTeacherPolicy
export const getIsError = state => state.payrollPolicy.isErr
export const getErrMsg = state => state.payrollPolicy.errMsg
export const getPayrollPolicy = state => state.payrollPolicy.policy
export const getTeacherPayrollPolicy = state =>
  state.payrollPolicy.teacherPolicy
