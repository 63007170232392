// import packages
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import FontAwesome from 'react-fontawesome'
// import enums
import { CHANGE_PASSWORD_FORM_NAME } from '../../helpers/enums'

class ChangePasswordFormComponent extends Component {
  required(value) {
    if (!value) return 'Required'
    return undefined
  }

  compareOldAndNewPassword(value, allValues) {
    const newPassword = value ? value : undefined
    const oldPassword = allValues.oldPassword
      ? allValues.oldPassword
      : undefined
    if (newPassword && oldPassword && oldPassword === newPassword) {
      return 'Old and new passwords cannot be the same'
    }
    return undefined
  }

  minLength(value, allValues) {
    if (value && allValues.oldPassword && allValues.newPassword.length < 6) {
      return 'Password should have a minimum of 6 characters'
    }
    return undefined
  }

  compareNewAndNewConfirmPassword(value, allValues) {
    const newPasswordConfirm = value ? value : undefined
    const newPassword = allValues.newPassword
      ? allValues.newPassword
      : undefined
    if (
      newPassword &&
      newPasswordConfirm &&
      newPassword !== newPasswordConfirm
    ) {
      return 'Passwords do not match'
    }
    return undefined
  }

  renderField({
    input,
    type,
    meta,
    meta: { touched, error, visited, active },
    placeholder
  }) {
    console.log('meta', meta)
    return (
      <div>
        <input
          type={type}
          {...input}
          placeholder={placeholder}
          className="info-cell"
        />
        {visited && !active && !error && <FontAwesome name="check" />}
        {visited && !active && error && <FontAwesome name="times" />}
        <br />
        {touched && error && <span>{error}</span>}
      </div>
    )
  }

  render() {
    const { handleSubmit } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <div className="info-row">
          <label className="info-cell info-cell--left">Old Password</label>
          <span className="dash" />
          <Field
            type="password"
            name="oldPassword"
            component={this.renderField}
            placeholder="enter old password"
            className="info-cell"
            validate={[this.required]}
          />
        </div>
        <div className="info-row">
          <label className="info-cell info-cell--left">New Password</label>
          <span className="dash" />
          <Field
            type="password"
            name="newPassword"
            component={this.renderField}
            placeholder="enter new password"
            className="info-cell"
            validate={[
              this.required,
              this.compareOldAndNewPassword,
              this.minLength
            ]}
          />
        </div>
        <div className="info-row">
          <label className="info-cell info-cell--left">
            New Password Confirm
          </label>
          <span className="dash" />
          <Field
            type="password"
            name="newPasswordConfirm"
            component={this.renderField}
            placeholder="enter new password again"
            validate={[this.required, this.compareNewAndNewConfirmPassword]}
          />
        </div>
        <div className="space-creator" />
        <div className="submit-field">
          <button className="button button--blue" type="submit">
            Submit
          </button>
        </div>
      </form>
    )
  }
}

const ChangePasswordForm = reduxForm({
  form: CHANGE_PASSWORD_FORM_NAME
})(ChangePasswordFormComponent)

export default ChangePasswordForm
