import {
  APP_LOGOUT,
  REQUEST_STUDENT_DOC_START,
  REQUEST_STUDENT_DOC,
  REQUEST_STUDENT_DOC_FAIL,
  GET_STUDENT_DOC_REQUEST_START,
  GET_STUDENT_DOC_REQUEST,
  GET_STUDENT_DOC_REQUEST_FAIL,
  PATCH_STUDENT_DOC_START,
  PATCH_STUDENT_DOC,
  PATCH_STUDENT_DOC_FAIL,
  GET_STUDENT_DOC_REQUEST_FOR_ME,
  GET_STUDENT_DOC_REQUEST_BY_STUDENT,
  UPLOAD_STUDENT_DOC_START,
  UPLOAD_STUDENT_DOC,
  UPLOAD_STUDENT_DOC_FAIL,
  // PATCH_UPLOADED_STUDENT_DOC_START,
  PATCH_UPLOADED_STUDENT_DOC,
  // PATCH_UPLOADED_STUDENT_DOC_FAIL,
  GET_UPLOADED_STUDENT_DOC_START,
  GET_UPLOADED_STUDENT_DOC_BY_ID,
  GET_UPLOADED_STUDENT_DOC_FOR_ME,
  GET_UPLOADED_STUDENT_DOC_FAIL,
  GET_STUDENT_DOC_BY_DOC_ID_START,
  GET_STUDENT_DOC_BY_DOC_ID_SUCCESS,
  GET_STUDENT_DOC_BY_DOC_ID_FAIL
} from '../helpers/actions'

const initialState = {
  requestLoading: false,
  patchLoading: false,
  isErr: false,
  requestDoc: [],
  uploadDocLoading: false,
  studentDocumentData: [],
  documentLoading: false,
  msg: '',
  allRequestDoc: []
}

export const studentDocument = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case REQUEST_STUDENT_DOC_START:
      return {
        ...state,
        requestLoading: true,
        isErr: false
      }
    case REQUEST_STUDENT_DOC:
      return {
        ...state,
        requestLoading: false,
        isErr: false
      }
    case REQUEST_STUDENT_DOC_FAIL:
      return {
        ...state,
        requestLoading: false,
        isErr: true
      }
    case GET_STUDENT_DOC_REQUEST_START:
      return {
        ...state,
        requestLoading: true,
        isErr: false
      }
    case GET_STUDENT_DOC_REQUEST:
      return {
        ...state,
        requestLoading: false,
        requestDoc: action.data,
        isErr: false
      }
    case GET_STUDENT_DOC_REQUEST_FOR_ME:
      return {
        ...state,
        requestLoading: false,
        requestDoc: action.data,
        isErr: false
      }
    case GET_STUDENT_DOC_REQUEST_BY_STUDENT:
      return {
        ...state,
        requestLoading: false,
        requestDoc: action.data,
        isErr: false
      }
    case GET_STUDENT_DOC_REQUEST_FAIL:
      return {
        ...state,
        requestLoading: false,
        isErr: true
      }
    case PATCH_STUDENT_DOC_START:
      return {
        ...state,
        patchLoading: true,
        isErr: false
      }
    case PATCH_STUDENT_DOC:
      return {
        ...state,
        patchLoading: false,
        requestDoc: action.data,
        isErr: false
      }
    case PATCH_STUDENT_DOC_FAIL:
      return {
        ...state,
        patchLoading: false,
        isErr: true
      }
    case UPLOAD_STUDENT_DOC_START:
      return {
        ...state,
        uploadDocLoading: true,
        isErr: false,
        msg: ''
      }
    case UPLOAD_STUDENT_DOC:
      return {
        ...state,
        uploadDocLoading: false,
        isErr: false,
        msg: 'post'
      }
    case UPLOAD_STUDENT_DOC_FAIL:
      return {
        ...state,
        uploadDocLoading: false,
        isErr: true,
        msg: ''
      }
    case PATCH_UPLOADED_STUDENT_DOC:
      return {
        ...state,
        uploadDocLoading: false,
        isErr: false,
        msg: 'patch'
      }
    case GET_UPLOADED_STUDENT_DOC_START:
      return {
        ...state,
        documentLoading: true,
        isErr: false
      }
    case GET_UPLOADED_STUDENT_DOC_BY_ID:
      return {
        ...state,
        documentLoading: false,
        studentDocumentData: action.data,
        isErr: false
      }
    case GET_UPLOADED_STUDENT_DOC_FOR_ME:
      return {
        ...state,
        documentLoading: false,
        studentDocumentData: action.data,
        isErr: false
      }
    case GET_UPLOADED_STUDENT_DOC_FAIL:
      return {
        ...state,
        documentLoading: false,
        isErr: false
      }
    case GET_STUDENT_DOC_BY_DOC_ID_START:
      return {
        ...state,
        documentLoading: false,
        documentByStudentId: {},
        isErr: false
      }
    case GET_STUDENT_DOC_BY_DOC_ID_SUCCESS:
      return {
        ...state,
        documentLoading: false,
        documentByStudentId: action.byId,
        isErr: false
      }
    case GET_STUDENT_DOC_BY_DOC_ID_FAIL:
      return {
        ...state,
        documentLoading: false,
        documentByStudentId: {},
        isErr: false
      }
    default:
      return state
  }
}

export const getRequestLoading = state => state.studentDocument.requestLoading
export const getPatchRequestLoading = state =>
  state.studentDocument.patchLoading
export const getDocumentRequest = state => state.studentDocument.requestDoc
export const getDocumentLoading = state => state.studentDocument.documentLoading
export const getUplaodDocLoading = state =>
  state.studentDocument.uploadDocLoading
export const getStudentDocumentData = state =>
  state.studentDocument.studentDocumentData
export const getError = state => state.studentDocument.isErr
export const getMessage = state => state.studentDocument.msg
export const getDocumentByStudentId = state =>
  state.studentDocument.documentByStudentId
