// import packages
// import action types
import {
  APP_LOGOUT,
  START_TC_FETCH,
  FETCH_TC_SUCCESS,
  FETCH_TC_FAIL,
  START_MERGED_TC_PDF_DATA,
  MERGED_TC_PDF_DATA,
  FAILED_MERGED_TC_PDF_DATA,
  START_TC_FETCH_PER_STUDENT,
  FETCH_TC_FAIL_PER_STUDENT,
  FETCH_TC_SUCCESS_PER_STUDENT,
  POST_TC_START,
  POST_TC_SUCCESS,
  POST_TC_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  mergedPDFData: '',
  isPostLoading: false,
  allTc: []
}

export const transferCertificate = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_TC_FETCH:
      return Object.assign({}, state, {
        isLoading: true
      })
    case FETCH_TC_SUCCESS:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false,
        allTc: action.allTc
      })
    case FETCH_TC_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case START_TC_FETCH_PER_STUDENT:
      return Object.assign({}, state, {
        isLoading: true
      })
    case FETCH_TC_SUCCESS_PER_STUDENT:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false
      })
    case FETCH_TC_FAIL_PER_STUDENT:
      return Object.assign({}, state, {
        isLoading: false
      })
    case START_MERGED_TC_PDF_DATA:
      return Object.assign({}, state, {
        isLoading: true,
        mergedPDFData: ''
      })
    case MERGED_TC_PDF_DATA:
      return Object.assign({}, state, {
        mergedPDFData: action.data,
        isLoading: false
      })
    case FAILED_MERGED_TC_PDF_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        mergedPDFData: ''
      })
    case POST_TC_START:
      return Object.assign({}, state, {
        isPostLoading: true
      })
    case POST_TC_SUCCESS:
      return Object.assign({}, state, {
        isPostLoading: false
      })
    case POST_TC_FAIL:
      return Object.assign({}, state, {
        isPostLoading: false
      })
    default:
      return state
  }
}

export const byId = state => state.transferCertificate.byId
export const isLoading = state => state.transferCertificate.isLoading
export const isWorking = state => state.transferCertificate.isWorking
export const mergedPDFData = state => state.transferCertificate.mergedPDFData
export const getPostLoading = state => state.transferCertificate.isPostLoading
export const getAllTc = state => state.transferCertificate.allTc
