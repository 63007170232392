import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

class ReduxCheckbox extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string
  }

  render() {
    const { options, name, placeholder = 'No Options Found' } = this.props
    return (
      <div>
        {options.length > 0 &&
          options.map((option, index) => (
            <span className="check-tab-ctr" key={option.id}>
              <label className="check-tag">
                <Field
                  name={`${name}.${option.id}`}
                  component="input"
                  type="checkbox"
                  className="check-tag__input"
                />
                <span className="check-tag__text">{option.name}</span>
              </label>
            </span>
          ))}
        {!options.length && (
          <span className="text text-muted">{placeholder}</span>
        )}
      </div>
    )
  }
}

export default ReduxCheckbox
