// import packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, formValueSelector } from 'redux-form'
import classNames from 'classnames'
// import actions
import { startFetchGroups } from '../../actions/groups'
// import selectors
import { getJwt } from '../../reducers/accounts'
import { getInstitutionData } from '../../reducers/institution'
import {
  isLoading as groupsLoading,
  getAcademicGroups
} from '../../reducers/groups'
// import components
import { SelectClassForm } from '../forms'
import { ReduxSelectWithId } from '../forms/inputs'
import ReduxSelect from '../forms/inputs'
// import enums
import {
  CONDUCT_CERTIFICATE_NAME,
  registerCertyTemplateObj,
  registerCertyTemplateObjBCBS
} from '../../helpers/enums'
// import helpers
import { filterSectionsByParent } from '../../helpers/utils'

class GroupSelectorContainer extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    groupsLoading: PropTypes.bool.isRequired,
    academicGroups: PropTypes.array.isRequired,
    grade: PropTypes.string.isRequired,
    fetchGroups: PropTypes.func.isRequired
  }
  componentDidMount() {
    const { jwt, fetchGroups } = this.props
    fetchGroups({ jwt, type: 'academics' })
  }

  isReady() {
    const { grade, groupId } = this.props
    if (!grade || !groupId) return false
    return true
  }

  render() {
    const {
      grade,
      academicGroups,
      handleSubmit,
      nameOfComponent,
      institutionData
    } = this.props

    return (
      <div className="form-ctr">
        <form
          className={
            nameOfComponent === 'register-cerificate'
              ? 'st-group-form st-tests-form'
              : 'st-group-form'
          }
          onSubmit={handleSubmit}
        >
          <p className="form__heading">Class Details</p>
          <div
            className={
              nameOfComponent === 'register-cerificate'
                ? 'fields-ctr fields-ctr--has-3-fields'
                : 'fields-ctr fields-ctr--has-2-fields fields-ctr--no-border'
            }
          >
            <div className="field">
              <SelectClassForm />
            </div>
            <div className="field">
              <ReduxSelectWithId
                options={filterSectionsByParent(grade, academicGroups)}
                name="groupId"
                placeholder="Select Section"
              />
            </div>
            {nameOfComponent === 'register-cerificate' && (
              <div className="field">
                <ReduxSelectWithId
                  options={Object.keys(
                    institutionData._id === '5bb0916e60ed7f1faeb650b9'
                      ? registerCertyTemplateObjBCBS
                      : registerCertyTemplateObj
                  ).map(key => {
                    return {
                      id: key,
                      name: registerCertyTemplateObj[key]
                    }
                  })}
                  name="templete"
                  placeholder="Select templete"
                />
              </div>
            )}
          </div>
          <div className="field submit-field">
            <button
              type="submit"
              className={classNames({
                'button button--blue': true,
                disabled: !this.isReady()
              })}
              disabled={!this.isReady()}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const selector = formValueSelector(CONDUCT_CERTIFICATE_NAME)

const mapStateToProps = state => {
  const umbrellaGroup = selector(state, 'grade')
  let groupId = selector(state, 'groupId')
  return {
    // state props
    jwt: getJwt(state),
    institutionData: getInstitutionData(state),
    groupsLoading: groupsLoading(state),
    academicGroups: getAcademicGroups(state),
    // redux form props
    grade: umbrellaGroup ? umbrellaGroup : '',
    groupId: groupId ? groupId : ''
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups(data) {
    dispatch(startFetchGroups(data))
  }
})

const GroupSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSelectorContainer)

export default reduxForm({
  form: CONDUCT_CERTIFICATE_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true
})(GroupSelector)
