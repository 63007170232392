import {
  APP_LOGOUT,
  SAVE_CONCESSION_NOTIFICATION_DATA
} from '../../helpers/actions'

const initialState = {
  umbrellaGroup: '',
  studentGroup: ''
}

export const notification = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONCESSION_NOTIFICATION_DATA:
      return {
        ...state,
        umbrellaGroup: action.data.umbrellaGroup,
        studentGroup: action.data.studentGroup
      }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getUmbrellaGroup = state => state.ui.notification.umbrellaGroup
export const getStudentGroup = state => state.ui.notification.studentGroup
