import {
  SAVE_GROUP_FOR_CLASS_PROFILE_ATTENDANCE,
  SAVE_PARENT_FOR_CLASS_PROFILE,
  SAVE_DETAILS_FOR_TEST_EDIT,
  SAVE_DETAILS_FOR_SUBJECT_EDIT,
  SAVE_DETAILS_FOR_GRADE_EDIT,
  APP_LOGOUT
} from '../../helpers/actions'

const initialState = {
  umbrellaGroup: '',
  groupId: '',
  academicYear: '',
  groupType: '',
  testId: '',
  subjectId: '',
  gradeSetId: ''
}

export const classProfile = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SAVE_GROUP_FOR_CLASS_PROFILE_ATTENDANCE:
      return Object.assign({}, state, {
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId
      })
    case SAVE_PARENT_FOR_CLASS_PROFILE:
      return Object.assign({}, state, {
        umbrellaGroup: action.umbrellaGroup
      })
    case SAVE_DETAILS_FOR_TEST_EDIT:
      return Object.assign({}, state, {
        academicYear: action.academicYear,
        groupType: action.groupType,
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId,
        testId: action.testId
      })
    case SAVE_DETAILS_FOR_SUBJECT_EDIT:
      return Object.assign({}, state, {
        academicYear: action.academicYear,
        groupType: action.groupType,
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId,
        subjectId: action.subjectId
      })
    case SAVE_DETAILS_FOR_GRADE_EDIT:
      return Object.assign({}, state, {
        academicYear: action.academicYear,
        groupType: action.groupType,
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId,
        gradeSetId: action.gradeSetId
      })
    default:
      return state
  }
}

export const umbrellaGroup = state => state.ui.classProfile.umbrellaGroup
export const groupId = state => state.ui.classProfile.groupId
export const academicYear = state => state.ui.classProfile.academicYear
export const groupType = state => state.ui.classProfile.groupType
export const testId = state => state.ui.classProfile.testId
export const subjectId = state => state.ui.classProfile.subjectId
export const gradeSetId = state => state.ui.classProfile.gradeSetId
