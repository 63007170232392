import {
  SAVE_CLASS_AND_SECTION_FOR_TIMETABLE,
  APP_LOGOUT
} from '../../helpers/actions'

const initialState = {
  umbrellaGroup: '',
  groupId: ''
}

export const timetable = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SAVE_CLASS_AND_SECTION_FOR_TIMETABLE:
      return Object.assign({}, state, {
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId
      })
    default:
      return state
  }
}

export const umbrellaGroupForTimetable = state =>
  state.ui.timetable.umbrellaGroup
export const groupIdForTimetable = state => state.ui.timetable.groupId
