import { SMS_TEMPLATE_DATA, APP_LOGOUT } from '../../helpers/actions'

const initialState = {
  templateId: '',
  templateData: []
}

export const smsTemplateData = (state = initialState, action) => {
  switch (action.type) {
    case SMS_TEMPLATE_DATA:
      return Object.assign({}, state, {
        templateId: action.data.templateId,
        templateData: action.data.templateData
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getTemplateId = state => state.ui.smsTemplateData.templateId
export const getTemplateData = state => state.ui.smsTemplateData.templateData
