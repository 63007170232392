// get notifications
import {
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  REMOVE_NOTIFICATIONS_FAIL,
  REMOVE_NOTIFICATIONS_START,
  REMOVE_NOTIFICATIONS_SUCCESS
} from '../helpers/actions'
import {
  AuthError,
  checkStatus,
  composeAuth,
  parseJSON
} from '../helpers/utils'
import { emitAuthFailed } from './login'

import querystring from 'query-string'

export const getNotificationStart = () => ({
  type: GET_NOTIFICATIONS_START
})

export const getNotificationFail = errMsg => ({
  type: GET_NOTIFICATIONS_FAIL,
  errMsg
})

const getNotificationSuccess = data => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    data
  }
}
const getNotifications = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/notifications`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGetNotifications = data => {
  return function(dispatch) {
    dispatch(getNotificationStart())
    return getNotifications(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(getNotificationSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(getNotificationFail(err.message))
      })
  }
}

export const removeNotificationStart = () => ({
  type: REMOVE_NOTIFICATIONS_START
})

export const removeNotificationFail = errMsg => ({
  type: REMOVE_NOTIFICATIONS_FAIL,
  errMsg
})

const removeNotificationSuccess = () => {
  return {
    type: REMOVE_NOTIFICATIONS_SUCCESS
  }
}

const removeNotifications = ({ jwt, notiIds }) => {
  let Authorization = composeAuth(jwt)
  let url =
    `${process.env.REACT_APP_SERVER_NEW}/api/notifications?` +
    querystring.stringify({ ids: notiIds })
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startRemoveNotifications = data => dispatch => {
  dispatch(removeNotificationStart())
  return removeNotifications(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(removeNotificationSuccess())
    })
    .catch(err => {
      console.error(err)
      dispatch(removeNotificationFail())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}
