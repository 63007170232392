import {
  APP_LOGOUT,
  SUBJECTS_FETCH_START,
  SUBJECTS_SUBJECT_DATA,
  SUBJECTS_FETCH_FAIL,
  ELECTIVES_FETCH_START,
  ELECTIVES_SUBJECT_DATA,
  ELECTIVES_FETCH_FAIL,
  ADD_ELECTIVE_SUCCESS,
  ADD_SUBJECT_SUCCESS,
  ADD_ELECTIVE_FAIL,
  EDIT_SUBJECT_SUCCESS,
  EDIT_SUBJECT_FAIL,
  EDIT_SUBJECT_START,
  GET_HOD_SUBJECTS_START,
  GET_HOD_SUBJECTS_SUCCESS,
  GET_HOD_SUBJECTS_FAILED
} from '../helpers/actions'
import { createSelector } from 'reselect'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  electivesById: {},
  electivesAllIds: [],
  isErr: false,
  errMsg: '',
  isPatchLoading: false,
  msg: '',
  electiveLoading: false,
  hodSubjects: [],
  hodSubjectsLoading: false,
  hodSubjectsErr: false
}

export const subjects = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SUBJECTS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case SUBJECTS_SUBJECT_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.entities,
        allIds: action.result
      })
    case SUBJECTS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case ADD_SUBJECT_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        allIds: [...state.allIds, action.data._id]
      })
    case ELECTIVES_FETCH_START:
      return Object.assign({}, state, {
        // isLoading: true,
        electiveLoading: true,
        isErr: false,
        errMsg: ''
      })
    case ELECTIVES_SUBJECT_DATA:
      return Object.assign({}, state, {
        electiveLoading: false,
        // isLoading: false,
        electivesById: action.entities,
        electivesAllIds: action.result
      })
    case ELECTIVES_FETCH_FAIL:
      return Object.assign({}, state, {
        electiveLoading: false,
        // isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case ADD_ELECTIVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case ADD_ELECTIVE_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case EDIT_SUBJECT_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        isErr: false,
        errMsg: '',
        msg: ''
      })
    case EDIT_SUBJECT_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        isPatchLoading: false,
        isErr: false,
        errMsg: '',
        msg: 'patched'
      })
    case EDIT_SUBJECT_FAIL:
      return Object.assign({}, state, {
        isErr: true,
        errMsg: action.message,
        isPatchLoading: false,
        msg: ''
      })
    case GET_HOD_SUBJECTS_START:
      return Object.assign({}, state, {
        hodSubjectsLoading: true,
        hodSubjectsErr: false
      })
    case GET_HOD_SUBJECTS_SUCCESS:
      return Object.assign({}, state, {
        hodSubjectsLoading: false,
        hodSubjectsErr: false,
        hodSubjects: action.data?.filter(subject => !subject?.archive)
      })
    case GET_HOD_SUBJECTS_FAILED:
      return Object.assign({}, state, {
        hodSubjectsLoading: false,
        hodSubjectsErr: true
      })
    default:
      return state
  }
}

export const getHodSubjectsLoading = state => state.subjects.hodSubjectsLoading
export const getHodSubjectsErr = state => state.subjects.hodSubjectsErr
export const getHodSubjects = state => state.subjects.hodSubjects

export const getIsLoading = state => state.subjects.isLoading
export const byId = state => state.subjects.byId
export const allIds = state => state.subjects.allIds
export const getError = state => state.subjects.isErr
export const getIsPatchLoading = state => state.subjects.isPatchLoading
export const getSubjectMsg = state => state.subjects.msg
export const getElectiveLoading = state => state.subjects.electiveLoading

export const subjectDataArray = createSelector(byId, allIds, (byId, allIds) => {
  if (!allIds || allIds.length === 0) return []
  return allIds
    .map(id => {
      const {
        _id,
        name,
        order,
        code,
        archive,
        electives,
        is_main,
        marksGrade,
        umbrellaName = '',
        umbrellaOrder = '',
        umbrellaCode = '',
        totalCalculation,
        compulsoryForTotal,
        percentageFifty,
        electiveNotUnderUmbrella,
        groupBy,
        teacher_edit,
        language
      } = byId[id]
      return {
        _id,
        name,
        order,
        code,
        archive,
        electives,
        is_main,
        marksGrade,
        umbrellaName,
        umbrellaOrder,
        umbrellaCode,
        totalCalculation,
        compulsoryForTotal,
        percentageFifty,
        electiveNotUnderUmbrella,
        groupBy,
        teacher_edit,
        language
      }
    })
    .sort((a, b) => a.order - b.order)
})

export const subjectArray = createSelector(byId, allIds, (byId, allIds) => {
  if (allIds.length === 0) return []
  return allIds
    .map(subjId => {
      const {
        _id: id,
        _id,
        name,
        electives,
        is_main,
        order,
        umbrellaName = '',
        umbrellaOrder = '',
        umbrellaCode = '',
        marksGrade,
        code,
        groupBy = ''
      } = byId[subjId]
      return {
        id,
        _id,
        name,
        electives,
        is_main,
        order,
        umbrellaName,
        umbrellaOrder,
        umbrellaCode,
        marksGrade,
        code,
        groupBy
      }
    })
    .sort((a, b) => a.order - b.order)
})

export const orderedSubjectArray = createSelector(
  subjectDataArray,
  subjects => {
    if (subjects.length === 0) return []
    return subjects.sort((a, b) => a.order - b.order)
  }
)

export const getMainSubjects = createSelector(orderedSubjectArray, subjects => {
  return subjects
    .filter(subject => subject.is_main === true && !subject?.archive)
    ?.sort((a, b) => a?.order - b?.order)
})

export const electivesById = state => state.subjects.electivesById
export const electivesAllIds = state => state.subjects.electivesAllIds

export const electivesArray = createSelector(
  electivesById,
  electivesAllIds,
  (electivesById, electivesAllIds) => {
    return electivesAllIds.map(subjId => {
      const { _id, name, electives, archive } = electivesById[subjId]
      const id = _id
      return { id, name, electives, archive }
    })
  }
)

export const getUmbrellaSubjects = createSelector(
  subjectDataArray,
  subjects =>
    subjects
      .filter(subject => subject.is_main && subject.umbrellaName !== '')
      .map(subject => subject.umbrellaName) //extract umbrellaNames
      .filter(
        (umbrellaName, index, array) => array.indexOf(umbrellaName) === index
      ) //test uniquesness
)

export const getUmbrellaMap = createSelector(getMainSubjects, subjects => {
  const map = {}
  subjects
    .filter(sub => sub.umbrellaName)
    .forEach(sub => {
      const { umbrellaName, umbrellaOrder, umbrellaCode } = sub
      if (!map[umbrellaName])
        map[umbrellaName] = {
          umbrellaName,
          umbrellaOrder,
          umbrellaCode
        }
    })

  return map
})
