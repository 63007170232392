// packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PageHelmet } from '../common'
import FontAwesome from 'react-fontawesome'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
// import actions
import * as Students from '../../actions/students'
import { saveData } from '../../actions/ui/studentProfile'
import { startFetchSubjects } from '../../actions/subjects'
// selectors
import StudentStore from '../../reducers/flattenedStudents'
import { groupsById } from '../../reducers/groups'
import { teachersById } from '../../reducers/teacher'
import { getInstitutionData } from '../../reducers/institution'
import { getGroupId } from '../../reducers/ui/studentProfile'
import {
  getIsLoading as subjectsLoading,
  byId as subjectsById
} from '../../reducers/subjects'
// components
import StudentTable from './studentTable'
import SearchBar from '../common/search'
import Spinner from '../spinner'

const mapStateToProps = state => {
  const groupId = getGroupId(state)
  const allGroups = groupsById(state)
  const allTeachers = teachersById(state)

  const activeGroup = groupId !== '' ? allGroups[groupId] : {}
  const { class_teacher = '' } = activeGroup

  activeGroup.teacherName =
    class_teacher !== '' ? allTeachers[class_teacher].name : ''

  return {
    jwt: state.accounts.jwt,
    ready: !StudentStore.isLoading(state) && !subjectsLoading(state),
    activeGroup,
    students: StudentStore.getStudentsSortedByName(state),
    searchFilter: StudentStore.getSearchFilter(state),
    searchFilterSubjects: StudentStore.getSearchFilterSubjects(state),
    subjectsById: subjectsById(state),
    institution: getInstitutionData(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchStudents: data => dispatch(Students.startFetchFlattenedData(data)),
  saveData: data => dispatch(saveData(data)),
  setSearchFilter: data => dispatch(Students.setSearchFilter(data)),
  fetchSubjects: data => dispatch(startFetchSubjects(data))
})

class StudentListComponent extends Component {
  static propTypes = {
    //mapStateToProps
    jwt: PropTypes.string.isRequired,
    ready: PropTypes.bool.isRequired,
    activeGroup: PropTypes.shape({
      _id: PropTypes.string,
      teacherName: PropTypes.string,
      umbrella_group: PropTypes.string,
      group_name: PropTypes.string,
      students: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    subjectsById: PropTypes.object.isRequired, // TODO: phase out
    searchFilter: PropTypes.object.isRequired,
    students: PropTypes.array.isRequired,
    institution: PropTypes.shape({
      name: PropTypes.string.isRequired,
      current_academic_year: PropTypes.string.isRequired
    }).isRequired,
    //mapDispatchToProps
    fetchStudents: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    fetchSubjects: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      search: '',
      order: {},
      conditionToSortBy: '',
      pdfWorking: false,
      width: 110,
      numRows: 26
    }
    this.sortBy = this.sortBy.bind(this)
    this.getSortIcon = this.getSortIcon.bind(this)
    this.getToolTip = this.getToolTip.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.handleSearchFieldClick = this.handleSearchFieldClick.bind(this)
    this.splitStudents = this.splitStudents.bind(this)
    this.createPdf = this.createPdf.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  active = () => {
    const { ready, students } = this.props
    return ready && 0 !== students.length ? true : false
  }

  setSearch(e) {
    e.preventDefault()
    const obj = {}
    obj[e.target.name] = e.target.value.toLowerCase()
    this.setState(obj)
  }

  filter = search => {
    const { activeGroup, students } = this.props
    const studentIds = activeGroup ? activeGroup.students : []
    let studentsArr = students.filter(
      studentObj => studentIds.indexOf(studentObj._id) !== -1
    )

    if (0 === search.length) return studentsArr

    const filterAcross = (fields = []) => {
      return student => {
        return fields.some(field => {
          return (
            student[field] !== undefined &&
            student[field].toLowerCase().indexOf(search) !== -1
          )
        })
      }
    }

    return studentsArr.filter(
      filterAcross(['name', 'roll_number', 'admission_number', 'gender'])
    )
  }

  sortBy(e) {
    const { order } = this.state
    const conditionToSortBy = e.target.getAttribute('data-sortBy')
    this.setState({
      order: {
        ...order,
        [conditionToSortBy]: !order[conditionToSortBy]
      },
      conditionToSortBy
    })
  }

  getSortIcon(conditionToSortBy) {
    const { order } = this.state
    if (order[conditionToSortBy]) return 'sort-amount-desc'
    return 'sort-amount-asc'
  }

  getToolTip(conditionToSortBy) {
    const { order } = this.state
    if (order[conditionToSortBy]) return 'sort ascending'
    return 'sort descending'
  }

  getTableHeaders() {
    const { searchFilter, searchFilterSubjects } = this.props
    if (Object.keys(searchFilter).length === 0) return []
    // get all table headers including subjects
    let tableHeaders = []
    tableHeaders = tableHeaders.concat(
      Object.keys(searchFilter).filter(
        studentDetail => searchFilter[studentDetail] === true
      ),
      Object.keys(searchFilterSubjects).filter(
        subjectId => searchFilterSubjects[subjectId] === true
      )
    )
    console.log('tableHeaders', tableHeaders)
    return tableHeaders
  }

  getGroupSubjectIds() {
    const { activeGroup } = this.props
    return activeGroup
      ? activeGroup.subjects.map(subjectObj => subjectObj.subject_id)
      : []
  }

  handleSearchFieldClick(e) {
    const { searchFilter, setSearchFilter } = this.props
    const { value } = e.target
    const newSearchFilter = Object.assign({}, searchFilter, {
      [value]: !searchFilter[value]
    })
    setSearchFilter(newSearchFilter)
  }

  splitStudents(students) {
    const { numRows } = this.state
    let arrStudentSlices = []
    let it = 0
    while (it < students.length)
      arrStudentSlices.push(students.slice(it, (it += numRows)))

    return arrStudentSlices
  }

  createPdf(e) {
    const { search, width: minWidth, numRows } = this.state
    const {
      activeGroup: { umbrella_group = '', group_name = '' }
    } = this.props
    const outputName = `studentreport-${umbrella_group}-${group_name}.pdf`
    console.info('StudentList::createPdf - Printing: ', outputName)
    const students = this.filter(search).length
    let numberOfTable = Math.floor(students / numRows)
    let promiseArrayCanvas = []
    if (students % numRows !== 0) numberOfTable++

    for (let i = 1; i <= numberOfTable; i++) {
      const StudentBody = document.getElementById(`StudentCompleteTable${i}`)
      promiseArrayCanvas.push(html2canvas(StudentBody))
    }
    this.setState({
      pdfWorking: true
    })
    Promise.all(promiseArrayCanvas)
      .then(arrayofpromise => {
        const pdf = new jsPDF('p', 'mm', 'a4')
        arrayofpromise.forEach((canvas, index) => {
          let tabeImageData = canvas.toDataURL('image/png')
          const width = canvas.width
          const height = canvas.height
          const millimeters = {}
          millimeters.width = Math.floor(width * 0.264583) - minWidth
          millimeters.height = Math.floor(height * 0.264583)
          if (index !== 0) pdf.addPage()
          pdf.addImage(
            tabeImageData,
            'JPEG',
            0,
            2,
            millimeters.width,
            millimeters.height
          )
        })
        pdf.save(outputName)
      })
      .then(() => {
        this.setState({
          pdfWorking: false
        })
      })
      .catch(err => {
        console.log(err, 'err')
      })
  }

  handleChange(e) {
    const newState = {}
    newState[e.target.name] = Number(e.target.value)
    this.setState(newState)
  }

  render() {
    const {
      activeGroup,
      searchFilter,
      ready,
      institution: { name, current_academic_year }
    } = this.props
    const next_year = Number(current_academic_year) + 1
    const { search, pdfWorking, width, numRows } = this.state
    const allStudents = this.filter(search)
    const {
      teacherName = '',
      umbrella_group = '',
      group_name = ''
    } = activeGroup
    return (
      <div>
        <PageHelmet>Student List</PageHelmet>
        <div className="container">
          <div className="row">
            <div className="col-xs-3">
              <div className="tab">
                <p className="tab__text">STUDENT LIST REPORT</p>
              </div>
            </div>
          </div>
        </div>
        <div className="attd-search">
          <div className="container">
            <div className="row">
              <div className="col-xs-1">
                <Link to="/student/show">
                  <button className="btn-right btn-right-filter">
                    <FontAwesome name="caret-left" /> Back
                  </button>
                </Link>
              </div>
              <div className="col-xs-3">
                <SearchBar
                  active={this.active()}
                  handleChange={this.setSearch}
                  value={search}
                />
              </div>
              <div className="col-xs-1">
                <div className="date-and-class">
                  Students: {allStudents.length}
                </div>
              </div>
              <div className="col-xs-2">
                {'width'}
                <input
                  name="width"
                  type="number"
                  onChange={this.handleChange}
                  value={width}
                />
                {'px'}
              </div>
              <div className="col-xs-2">
                {'Rows'}
                <input
                  name="numRows"
                  type="number"
                  onChange={this.handleChange}
                  value={numRows}
                />
              </div>
              <div className="col-xs-2">
                <div className="btn-container pull-right">
                  <button
                    className="btn-right btn-right-csv"
                    onClick={this.createPdf}
                    disabled={pdfWorking}
                  >
                    {!pdfWorking && (
                      <span>
                        <FontAwesome name="download" /> Print PDF
                      </span>
                    )}
                    {pdfWorking && (
                      <span>
                        <FontAwesome name="spinner" spin /> Working
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {ready &&
            this.splitStudents(allStudents).map((students, index) => {
              const indexStart = index * numRows + 1
              return (
                <div
                  key={index}
                  className="container"
                  id={`StudentCompleteTable${index + 1}`}
                >
                  <div className="text-center">
                    <h4>
                      <b>{name}</b>
                    </h4>
                  </div>
                  <div className="row">
                    <div className="text-center col-xs-4">
                      <span className="info-text">
                        {umbrella_group} - {group_name} (Total Students:{' '}
                        {allStudents.length})
                      </span>
                    </div>
                    <div className="text-center col-xs-4">
                      <span className="info-text">
                        Student List ({current_academic_year} - {next_year})
                      </span>
                    </div>
                    <div className="text-center col-xs-4">
                      <span className="info-text">
                        Class Teacher: {teacherName}
                      </span>
                    </div>
                    <div className="col-xs-12">
                      <StudentTable
                        active={this.active()}
                        students={students}
                        tableHeaders={this.getTableHeaders()}
                        searchFilter={searchFilter}
                        sortBy={this.sortBy}
                        getSortIcon={this.getSortIcon}
                        getToolTip={this.getToolTip}
                        groupSubjectIds={this.getGroupSubjectIds()}
                        groupSubjectArr={
                          activeGroup ? activeGroup.subjects : []
                        }
                        indexStart={indexStart}
                      />
                    </div>
                    <div className="col-xs-offset-8 col-xs-4">
                      <span className="info-text">Principal: </span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        {!ready && <Spinner />}
      </div>
    )
  }
}

const StudentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentListComponent)

export default StudentList
