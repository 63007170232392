import { useState } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

/*components*/
import { Input, PasswordInput } from '../../common/atom/inputs'

const LoginForm = ({
  handleChange,
  handleSubmit,
  error,
  errorMsg,
  isLoggingIn
}) => {
  const [passwordState, setPasswordState] = useState(true)

  const onToggleShow = e => {
    e.preventDefault()
    setPasswordState(!passwordState)
  }
  return (
    <div>
      <form
        className={`${error ? 'error' : ''} st-login-form`}
        onSubmit={handleSubmit}
      >
        <Input
          name="name"
          type="text"
          placeholder="User ID"
          handleChange={handleChange}
        />
        <PasswordInput
          name="password"
          type={passwordState ? 'password' : 'text'}
          placeholder="Password"
          handleChange={handleChange}
          onToggleShow={onToggleShow}
        />
        <p className="text-center error-text">{errorMsg}</p>
        <button
          disabled={isLoggingIn}
          type="submit"
          className="btn btn-primary btn-block text-uppercase"
        >
          {isLoggingIn ? (
            <FontAwesome spin size="2x" name="spinner" />
          ) : (
            <span>Sign In</span>
          )}
        </button>
        <Link to={'/reset-password'} className="text-small text-center">
          Cannot access your account?
        </Link>
      </form>
    </div>
  )
}

export default LoginForm
