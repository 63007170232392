import { QUESTION_PAPER_DATA, APP_LOGOUT } from '../../helpers/actions'

const initialState = {
  name: '',
  umbrellaGroup: '',
  subject: '',
  test: '',
  id: '',
  academicYear: ''
}

export const questionpaper = (state = initialState, action) => {
  switch (action.type) {
    case QUESTION_PAPER_DATA:
      return Object.assign({}, state, {
        name: action.data.name,
        umbrellaGroup: action.data.umbrellaGroup,
        subject: action.data.subject,
        test: action.data.test,
        id: action.data.id,
        academicYear: action.data.academicYear
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getName = state => state.ui.questionpaper.name
export const getUmbrellaGroup = state => state.ui.questionpaper.umbrellaGroup
export const getSubject = state => state.ui.questionpaper.subject
export const getTest = state => state.ui.questionpaper.test
export const getId = state => state.ui.questionpaper.id
export const getData = state => state.ui.questionpaper
