import React from 'react'

import PolicyAndEmailComponentWrapper from '../policies/index'

const PolicyComponent = () => (
  <PolicyAndEmailComponentWrapper
    PageHelmetText="Add Policy"
    nameOfComponent="addPolicyComponent"
  />
)
const EmailComponent = () => (
  <PolicyAndEmailComponentWrapper
    PageHelmetText="Send Email"
    nameOfComponent="sendEmailComponent"
  />
)

export { PolicyComponent, EmailComponent }
