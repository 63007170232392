// packages
import React from 'react'
import FontAwesome from 'react-fontawesome'
import ReportModal from './reportModal'

import { SelectInputWithID } from '../inputs.jsx'

// import enums
import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../helpers/enums'

const ModalCompoentWithCSV = ({
  downloadReport,
  openSearchFilter,
  handleArchiveToggle,
  options,
  archiveFilterType,
  isDisabled,
  getMarksStudentlistReportHousewiseGrades,
  openModal,
  showModal,
  closeModal,
  handleNoOfStudentsChange
}) => {
  return (
    <div className="col-xs-10">
      <div className="btn-container button-group  pull-right">
        <span className="slash">&nbsp;</span>
        <button
          onClick={openModal}
          className="button button--blue button--short button--no-mg-right"
          disabled={isDisabled}
        >
          Generate Report
        </button>
        <span className="slash">&nbsp;</span>
        <span className="slash">&nbsp;</span>
        <button
          onClick={openSearchFilter}
          className="button button--white button--short button--no-mg-right"
          disabled={isDisabled}
        >
          <FontAwesome name="filter" />
          Filter
        </button>
        <span className="slash">&nbsp;</span>
        <SelectInputWithID
          name="group_name"
          options={options}
          id={archiveFilterType}
          handleChange={handleArchiveToggle}
          {...STYLE_PROPS_FOR_GROUP_SELECTOR}
        />
        {showModal && (
          <ReportModal
            getMarksStudentlistReportHousewiseGrades={
              getMarksStudentlistReportHousewiseGrades
            }
            openModal={openModal}
            downloadReport={downloadReport}
            openSearchFilter={openSearchFilter}
            closeModal={closeModal}
            showModal={showModal}
            options={options}
            handleNoOfStudentsChange={handleNoOfStudentsChange}
          />
        )}
      </div>
    </div>
  )
}
export default ModalCompoentWithCSV
