import { createSelector } from 'reselect'
import merge from 'lodash/merge'

import {
  APP_LOGOUT,
  APPOINTMENT_DATA,
  START_POST_APPOINTMENT,
  APPOINTMENT_POST_FAILURE,
  APPOINTMENT_POST_SUCCESS
} from '../helpers/actions'

import { getActiveApplication } from './ui/application'

const initialState = {
  byId: {},
  allIds: [],
  postInProgress: false,
  postStatus: ''
}

export const appointment = (state = initialState, action) => {
  switch (action.type) {
    case START_POST_APPOINTMENT:
      return Object.assign({}, state, {
        postInProgress: true,
        postStatus: ''
      })
    case APPOINTMENT_POST_SUCCESS:
      return Object.assign({}, state, {
        postInProgress: false,
        postStatus: 'success'
      })
    case APPOINTMENT_POST_FAILURE:
      return Object.assign({}, state, {
        postInProgress: false,
        postStatus: 'fail'
      })
    case APPOINTMENT_DATA: {
      const byId = merge({}, state.byId, action.byId)
      const allIds = merge([], state.allIds, action.allIds)
      return Object.assign({}, state, {
        byId,
        allIds
      })
    }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors

export const getById = state => state.appointment.byId
export const getAllIds = state => state.appointment.allIds
export const getPostInProgress = state => state.appointment.postInProgress
export const getPostStatus = state => state.appointment.postStatus

export const getActiveApplicationAppointment = createSelector(
  getById,
  getAllIds,
  getActiveApplication,
  (byId, appointments, activeApplication) => {
    const appointment = appointments.filter(
      app => byId[app]['appointmentFor'] === activeApplication
    )
    return appointment
      .map(app => byId[app])
      .sort((a, b) => new Date(b.date) - new Date(a.date))
  }
)
