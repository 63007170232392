// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
// import components
import { StudentLink } from '../../common'

class TableBodyContainer extends Component {
  static propTypes = {
    selectedStudents: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired
  }
  getMergeStudent(studentStatusObj) {
    const { selectedStudents, handleInputChange } = this.props
    const studentId = studentStatusObj.studentId
    if (studentStatusObj.status === 'savedToS3') {
      return (
        <label className="group-select group-select--all">
          <input
            name={studentId}
            type="checkbox"
            checked={selectedStudents[studentId]}
            onChange={handleInputChange}
          />
          <span className="square">
            <span className="tick" />
          </span>
        </label>
      )
    } else return ''
  }

  getReportCardStatus(studentStatusObj) {
    switch (studentStatusObj.status) {
      case 'savedToS3':
        return (
          <button
            className={classNames({
              'button button--small button--blue': true
            })}
            onClick={() => window.open(studentStatusObj.url, '_blank')}
          >
            View
          </button>
        )
      case 'started':
      case 'reportPDFGen':
        return (
          <FontAwesome
            name="circle-o-notch spin 3x fw"
            style={{ color: 'orange' }}
          />
        )
      case 'error':
        return <FontAwesome name="times" />
      default:
        return ''
    }
  }

  render() {
    const { studentDataAndTc } = this.props
    return (
      <tbody className="st-table__body">
        {studentDataAndTc.map(studentObj => {
          return (
            <tr key={studentObj._id} className="st-table__body--row">
              <td className="st-table__body--cell st-table__text--body">
                {studentObj.roll_number ? studentObj.roll_number : ''}
              </td>
              <td className="st-table__body--cell st-table__text--body">
                {studentObj.admission_number ? studentObj.admission_number : ''}
              </td>
              <td className="st-table__body--cell st-table__text--bodyName">
                <StudentLink studentId={studentObj._id}>
                  {studentObj.name}
                </StudentLink>
              </td>
              <td className="st-table__body--cell st-table__text--body">
                {this.getReportCardStatus(studentObj)}
              </td>
              <td className="st-table__body--cell st-table__text--body">
                {this.getMergeStudent(studentObj)}
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }
}

export default TableBodyContainer
