import {
  APP_LOGOUT,
  ADD_STOP,
  ADD_STOP_START,
  ADD_STOP_FAIL,
  PATCH_STOP,
  PATCH_STOP_START,
  PATCH_STOP_FAIL,
  ASSIGN_STOP_START,
  ASSIGN_STOP_SUCCESS,
  ASSIGN_STOP_FAIL,
  UNASSIGN_STOP_START,
  UNASSIGN_STOP_SUCCESS,
  UNASSIGN_STOP_FAIL
} from '../../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false
}

export const transportStop = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ADD_STOP_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case ADD_STOP:
      return {
        ...state,
        isLoading: false,
        isErr: false
      }
    case ADD_STOP_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    case PATCH_STOP_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case PATCH_STOP:
      return {
        ...state,
        isLoading: false,
        isErr: false
      }
    case PATCH_STOP_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    case ASSIGN_STOP_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case ASSIGN_STOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isErr: false
      }
    case ASSIGN_STOP_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    case UNASSIGN_STOP_START:
      return {
        ...state,
        isLoading: true,
        isErr: false
      }
    case UNASSIGN_STOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isErr: false
      }
    case UNASSIGN_STOP_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true
      }
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.transportStop.isLoading
export const getIsError = state => state.transportStop.isErr
