import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import { composeWithDevTools } from 'redux-devtools-extension'

//reducer
import { RootReducer } from '../reducers/index.js'

//persist librarys
import storage from 'redux-persist/lib/storage/session'

//Middleware
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'state',
  storage: storage
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

export default () => {
  let store = createStore(
    persistedReducer,
    {},
    composeWithDevTools(applyMiddleware(thunk))
  )
  let persistor = persistStore(store)
  return { store, persistor }
}
