import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
import { PrivateLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

//teacher leave application
const TeacherLeaveApplication = lazy(() =>
  import('../../../leaveManagement/leaveApplication/teacher')
)
const TeacherLeaveApplicationList = lazy(() =>
  import('../../../leaveManagement/leaveApplication/teacher/leaveApplications')
)
const TeacherLeaveBalance = lazy(() =>
  import('../../../leaveManagement/leaveApplication/teacher/leaveBalance')
)

//sms
const SmsView = lazy(() => import('../../../sms/smsView'))

const TeacherRoute = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Frontend v3 routes */}

            {/* Frontend v4 routes */}

            <PrivateLayout
              path="/me/apply-leave/:type"
              name="teacher apply-leave"
              component={TeacherLeaveApplication}
            />
            <PrivateLayout
              path="/me/leaves"
              name="teacher pending leaves"
              component={TeacherLeaveApplicationList}
            />
            <PrivateLayout
              path="/me/leave-balance"
              name="teacher leave balance"
              component={TeacherLeaveBalance}
            />
            <PrivateLayout
              exact
              path="/sms/view"
              name="view all sms"
              component={SmsView}
            />
            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default TeacherRoute
