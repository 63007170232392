import {
  APP_LOGOUT,
  START_NAVIGATION_FETCH,
  START_NAVIGATION_SUCCESS,
  START_NAVIGATION_FAIL,
  START_NAVIGATION_BY_USER_FETCH,
  START_NAVIGATION_BY_USER_SUCCESS,
  START_NAVIGATION_BY_USER_FAIL,
  DELETE_NAVIGATION_START,
  DELETE_NAVIGATION,
  DELETE_NAVIGATION_FAIL,
  PATCH_NAVIGATION_START,
  PATCH_NAVIGATION,
  PATCH_NAVIGATION_FAIL,
  POST_NAVIGATION_START,
  POST_NAVIGATION_SUCCESS,
  POST_NAVIGATION_FAIL,
  POST_SUB_NAVIGATION_START,
  POST_SUB_NAVIGATION_SUCCESS,
  POST_SUB_NAVIGATION_FAIL
} from '../helpers/actions'

import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'

//fetch navigation
export const fetchNavigationStart = () => ({
  type: START_NAVIGATION_FETCH
})
export const fetchNavigationSuccess = data => ({
  type: START_NAVIGATION_SUCCESS,
  data
})
export const fetchNavigationFail = () => ({
  type: START_NAVIGATION_FAIL
})

export const emitLogout = () => ({
  type: APP_LOGOUT
})

const fetchNavigation = jwt => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/navigation-new'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchNavigation = data => dispatch => {
  dispatch(fetchNavigationStart())
  return fetchNavigation(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(fetchNavigationSuccess(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(fetchNavigationFail())
      errorToast('Error occured ..please try again')
    })
}

//get navigation by level
export const fetchNavigationByUserStart = () => ({
  type: START_NAVIGATION_BY_USER_FETCH
})
export const fetchNavigationByUserSuccess = data => ({
  type: START_NAVIGATION_BY_USER_SUCCESS,
  data
})
export const fetchNavigationByUserFail = () => ({
  type: START_NAVIGATION_BY_USER_FAIL
})

const fetchLevelNavigation = ({ jwt, level }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW + '/api/navigation-new/level/' + level
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchLevelNavigation = data => dispatch => {
  dispatch(fetchNavigationByUserStart())
  return fetchLevelNavigation(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(fetchNavigationByUserSuccess(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(fetchNavigationByUserFail())
      errorToast('Error occured ..please try again')
    })
}

//get navigation by institution and level
const fetchInstitutionNavigation = ({ jwt, institutionId, level }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/navigation-new/institution/${institutionId}/level/${level} `
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchInstitutionNavigation = data => dispatch => {
  dispatch(fetchNavigationByUserStart())
  return fetchInstitutionNavigation(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(fetchNavigationByUserSuccess(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(fetchNavigationByUserFail())
      errorToast('Error occured ..please try again')
    })
}

//get navigation by userId
const fetchUserNavigation = ({ jwt, userId }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW + '/api/navigation-new/user/' + userId
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchUserNavigation = data => dispatch => {
  dispatch(fetchNavigationByUserStart())
  return fetchUserNavigation(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(fetchNavigationByUserSuccess(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(fetchNavigationByUserFail())
      errorToast('Error occured ..please try again')
    })
}

//delete navigation
const deleteNavigationStart = () => ({
  type: DELETE_NAVIGATION_START
})
const deleteNavigationSuccess = data => ({
  type: DELETE_NAVIGATION,
  data
})
const deleteNavigationFail = () => ({
  type: DELETE_NAVIGATION_FAIL
})

const deleteNavigation = ({ jwt, id }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/navigation-new/${id}`
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startDeleteNavigation = data => {
  return function(dispatch) {
    dispatch(deleteNavigationStart())
    return deleteNavigation(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(deleteNavigationSuccess(json.data))
        successToast('Successfully removed the navigation')
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        dispatch(deleteNavigationFail(err))
        errorToast('Unable to removed the navigation')
      })
  }
}

//patch navigation by id
const patchNavigationStart = () => ({
  type: PATCH_NAVIGATION_START
})

const patchNavigationSuccess = data => ({
  type: PATCH_NAVIGATION,
  data
})

const patchNavigationFail = errMsg => ({
  type: PATCH_NAVIGATION_FAIL,
  errMsg
})

const patchNavigation = ({ jwt, id, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/navigation-new/${id}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPatchNavigation = data => {
  return function(dispatch) {
    dispatch(patchNavigationStart())
    return patchNavigation(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(patchNavigationSuccess(json.data))
        successToast('Successfully edited the navigation')
      })
      .catch(err => {
        console.error(err)
        errorToast('Failed to edit navigation')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(patchNavigationFail(err.message))
      })
  }
}

//add new navigation
const postNavigationStart = () => ({
  type: POST_NAVIGATION_START
})
const postNavigationSuccess = data => ({
  type: POST_NAVIGATION_SUCCESS,
  data
})
const postNavigationFail = () => ({
  type: POST_NAVIGATION_FAIL
})

const postNavigation = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/navigation-new'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPostNavigation = data => dispatch => {
  dispatch(postNavigationStart())
  return postNavigation(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(postNavigationSuccess(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(postNavigationFail())
      errorToast('Error occured ..please try again')
    })
}

//add sub navigation
const postSubNavigationStart = () => ({
  type: POST_SUB_NAVIGATION_START
})
const postSubNavigationSuccess = data => ({
  type: POST_SUB_NAVIGATION_SUCCESS,
  data
})
const postSubNavigationFail = () => ({
  type: POST_SUB_NAVIGATION_FAIL
})

const postSubNavigation = data => {
  const { jwt, subNavigation } = data
  return Promise.all(
    subNavigation.map(nav => {
      let url = process.env.REACT_APP_SERVER_NEW + `/api/navigation-new`
      let Authorization = composeAuth(jwt)
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization
        },
        body: JSON.stringify(nav)
      })
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          return json.data
        })
        .catch(err => {
          dispatch(postSubNavigationSuccess(err))
          errorToast('Unable to add Sub Navigation')
        })
    })
  )
}

export const startPostSubNavigation = data => {
  return function(dispatch) {
    dispatch(postSubNavigationStart())
    return postSubNavigation(data).then(dataArr => {
      let finalData = []
      dataArr.forEach(item => {
        finalData = finalData.concat(item)
      })
      dispatch(postSubNavigationFail(finalData))
    })
  }
}
