import React from 'react'
import NoData from '../../../assets/images/empty.png'

const EmptyState = ({ text }) => {
  return (
    <div className="transfer-certificate">
      <img src={NoData} alt="Empty State" height="280" width="280" />
      <p>{text}</p>
    </div>
  )
}

export default EmptyState
