import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
import { PrivateLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

//sms
const SmsView = lazy(() => import('../../../sms/smsView'))

//exam portions and timetable
const StudentViewExamPortionsTimetable = lazy(() =>
  import('../../../examPortionTimetable/view')
)
const DisplayExamTimetable = lazy(() =>
  import('../../../timetable/displayExamTimeTable')
)

//profile
const StudentProfile = lazy(() => import('../../../student/studentProfile'))
const StudentAttendance = lazy(() =>
  import('../../../student/studentAttendance')
)

//feedback
const StudentFeedback = lazy(() =>
  import('../../../feedbackAndSurveys/studentFeedback')
)
//Worksheet
// const worksheet = lazy(() => import('../'))

const StudentRoute = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Frontend v3 routes */}

            {/* Frontend v4 routes */}
            <PrivateLayout
              exact
              path="/sms/view"
              name="view all sms"
              component={SmsView}
            />
            <PrivateLayout
              exact
              path="/exam-portion-timetable/view"
              name="exam-portion-timetable"
              component={StudentViewExamPortionsTimetable}
            />
            <PrivateLayout
              exact
              path="/profile/student/profile"
              name="student profile"
              component={StudentProfile}
            />
            <PrivateLayout
              exact
              path="/attendance/student"
              name="student attendance"
              component={StudentAttendance}
            />
            <PrivateLayout
              exact
              path="/feedback/student"
              name="student feedback"
              component={StudentFeedback}
            />
            {/* <PrivateLayout
              exact
              path="/timetable/exam"
              name="exam timetable"
              component={DisplayExamTimetable}
            /> */}
            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default StudentRoute
