import {
  SAVE_CLASS_SECTION_TEST_AND_SUBJECT,
  SAVE_GROUP_AND_TEST_FOR_DASHBOARD,
  SAVE_SELECTOR_DATA,
  SAVE_DATA_FOR_MARKS_ENTRY,
  SAVE_GROUPSELECTOR_DATA_FOR_MARKS_ENTRY,
  APP_LOGOUT
} from '../../helpers/actions'

const initialState = {
  academicYear: '',
  umbrellaGroup: '',
  groupId: '',
  testId: '',
  subjectId: '',
  electiveId: ''
}

export const marks = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SAVE_CLASS_SECTION_TEST_AND_SUBJECT:
      const { umbrellaGroup, groupId, testId, subjectId } = action
      return Object.assign({}, state, {
        umbrellaGroup,
        groupId,
        testId,
        subjectId
      })
    case SAVE_SELECTOR_DATA:
      // action.obj will contain obj with
      // key as umbrellaGroup, groupId, testId, subjId etc, and respective value
      return Object.assign({}, state, action.data)
    case SAVE_GROUP_AND_TEST_FOR_DASHBOARD:
      return Object.assign({}, state, {
        groupId: action.groupId,
        testId: action.testId
      })
    case SAVE_DATA_FOR_MARKS_ENTRY:
      return Object.assign({}, state, {
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId,
        testId: action.testId,
        subjectId: action.subjectId
      })
    case SAVE_GROUPSELECTOR_DATA_FOR_MARKS_ENTRY:
      return Object.assign({}, state, {
        academicYear: action.academicYear,
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId,
        testId: action.testId,
        subjectId: action.subjectId,
        electiveId: action.electiveId
      })
    default:
      return state
  }
}

export const academicYearForMarks = state => state.ui.marks.academicYear
export const groupIdForMarks = state => state.ui.marks.groupId
export const umbrellaGroup = state => state.ui.marks.umbrellaGroup
export const subjectIdForMarks = state => state.ui.marks.subjectId
export const testIdForMarks = state => state.ui.marks.testId
export const electiveIdForMarks = state => state.ui.marks.electiveId
