// import helpers
import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'
import { emitAuthFailed } from './login.js'
// import action types
import {
  START_REPORT_CARD_REQUEST,
  REPORT_CARD_REQUEST_SUCCESS,
  REPORT_CARD_REQUEST_FAIL,
  START_FETCH_REPORT_CARD_STATUS,
  REPORT_CARD_STATUS_FETCH_FAIL,
  REPORT_CARD_STATUS_DATA,
  MERGED_PDF_DATA,
  START_FETCH_REPORT_CARD_STATUS_PER_STUDENT,
  REPORT_CARD_STATUS_FETCH_FAIL_PER_STUDENT,
  REPORT_CARD_STATUS_DATA_PER_STUDENT,
  CLEAR_MERGED_PDF_DATA,
  PUBLISH_REPORT_CARD_START,
  PUBLISH_REPORT_CARD_FAIL,
  PUBLISH_REPORT_CARD_SUCCESS,
  GET_PUBLISHED_REPORT_CARD_STUDENT_START,
  GET_PUBLISHED_REPORT_CARD_STUDENT_SUCCESS,
  GET_PUBLISHED_REPORT_CARD_STUDENT_FAIL,
  PUBLISH_MARKS_START,
  PUBLISH_MARKS_SUCCESS,
  PUBLISH_MARKS_FAIL
} from '../helpers/actions'
import querystring from 'query-string'

// start post requests
const emitStart = () => ({
  type: START_REPORT_CARD_REQUEST
})
// post requests failure
const emitFailure = () => ({
  type: REPORT_CARD_REQUEST_FAIL
})
// post request success
const emitSuccess = () => ({
  type: REPORT_CARD_REQUEST_SUCCESS
})

// start get requests
const emitStartFetchStatus = () => ({
  type: START_FETCH_REPORT_CARD_STATUS
})

const emitFetchStatusFail = () => ({
  type: REPORT_CARD_STATUS_FETCH_FAIL
})

//start get request for each student
// start get requests
const emitStartFetchStatusPerStudent = () => ({
  type: START_FETCH_REPORT_CARD_STATUS_PER_STUDENT
})

const emitFetchStatusFailPerStudent = () => ({
  type: REPORT_CARD_STATUS_FETCH_FAIL_PER_STUDENT
})
const emitReportCardStatusDataPerStudent = data => {
  const entities = {}
  const result = []
  data.forEach(obj => {
    entities[obj._id] = obj
    result.push(obj._id)
  })
  return {
    type: REPORT_CARD_STATUS_DATA_PER_STUDENT,
    entities,
    result
  }
}
// post report card generation details
export const postReportCardDetails = data => {
  const {
    students,
    test,
    studentGroup,
    academic_year,
    attendanceMonths,
    attendanceDateRange,
    genrateTemplate
  } = data
  const Authorization = composeAuth(data.jwt)
  const newServer = process.env.REACT_APP_SERVER_NEW + `/api/reportCard`
  const oldServer = process.env.REACT_APP_SERVER + `/api/reportCard`
  // const url =
  //   data.institutionId === '5bb0916e60ed7f1faeb650b9' ||
  //   data.institutionId === '5af3d395aca8a243ac793861' ||
  //   data.institutionId === '5afff8883329ac552611cb4e'
  //     ? newServer
  //     : oldServer
  const url = process.env.REACT_APP_SERVER_NEW + `/api/reportCard`
  const body = {
    students,
    test,
    studentGroup,
    academic_year,
    attendanceMonths,
    attendanceDateRange,
    genrateTemplate
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(body)
  })
}

export const startPostRepordCardDetails = data => dispatch => {
  console.log('data inside startPostRepordCardDetails', data)
  dispatch(emitStart())
  return postReportCardDetails(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('json inside startPostRepordCardDetails', json)
      successToast('Report Card Generation has started')
      dispatch(emitSuccess())
    })
    .catch(err => {
      console.log('err inside startPostRepordCardDetails', err)
      errorToast('Report Card Generation failed to start')
      dispatch(emitFailure())
    })
}

// fetch report card status
// export const startFetchReportCardStatus = data => {
//   return function(dispatch) {
//     dispatch(emitStartFetchStatus())
//     return fetchReportCardStatus(data)
//       .then(checkStatus)
//       .then(parseJSON)
//       .then(json => {
//         console.log('data inside startFetchReportCardStatus', json)
//         dispatch(emitReportCardStatusData(json.data))
//       })
//       .catch(err => {
//         console.error(err)
//         if (err instanceof AuthError) dispatch(emitAuthFailed())
//         else dispatch(emitFetchStatusFail())
//       })
//   }
// }

// const fetchReportCardStatus = data => {
//   const { jwt, owner, test, academic_year } = data
//   let Authorization = composeAuth(jwt)
//   let url =
//     `${process.env.REACT_APP_SERVER_NEW}/api/reportCard/test/${test}/academic_year/${academic_year}/owner?` +
//     querystring.stringify({ owner: owner })

//   return fetch(url, {
//     method: 'GET',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       Authorization
//     }
//   })
// }

export const startFetchReportCardStatus = data => {
  return function(dispatch) {
    dispatch(emitStartFetchStatus())
    return fetchReportCardStatus(data).then(dataArr => {
      let finalData = []
      dataArr.forEach(data => {
        finalData = finalData.concat(data)
      })
      dispatch(emitReportCardStatusData(finalData))
    })
  }
}

const fetchReportCardStatus = data => {
  const { jwt, owner, academic_year, test } = data
  let temporal = []
  let index = 0
  let chunkSize = 50
  for (index = 0; index < owner?.length; index += chunkSize) {
    let arrChunk = owner.slice(index, index + chunkSize)
    temporal.push(arrChunk)
  }
  return Promise.all(
    temporal.map(st => {
      let url =
        `${process.env.REACT_APP_SERVER_NEW}/api/reportCard/test/${test}/academic_year/${academic_year}/owner?` +
        querystring.stringify({ owner: owner })
      let Authorization = composeAuth(jwt)
      return fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization
        }
      })
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          return json.data
        })
        .catch(err => {
          dispatch(emitFetchStatusFail(err))
          errorToast('Error Fetching Register Certificate')
        })
    })
  )
}

const emitReportCardStatusData = data => {
  const entities = {}
  const result = []
  data.forEach(obj => {
    entities[obj.onwerId] = obj
    result.push(obj.onwerId)
  })
  return {
    type: REPORT_CARD_STATUS_DATA,
    entities,
    result
  }
}

// merge PDF
export const mergePDF = ({ jwt, data }) => {
  const Authorization = composeAuth(jwt)
  const url = process.env.REACT_APP_SERVER_NEW + `/api/merge-pdfs`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startMergePDF = data => dispatch => {
  dispatch(emitStart())
  return mergePDF(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('json inside startMergePDF', json)
      dispatch(emitMergedPdfData(json.data))
      successToast('PDF Merge Complete')
    })
    .catch(err => {
      console.log('err inside startMergePDF', err)
      errorToast('PDF Merge Fail')
      dispatch(emitFailure())
    })
}

const emitMergedPdfData = data => ({
  type: MERGED_PDF_DATA,
  data
})

export const clearMergedPdfData = () => ({
  type: CLEAR_MERGED_PDF_DATA
})

// fetch report card status per student
export const startFetchReportCardStatusPerStudent = data => {
  return function(dispatch) {
    dispatch(emitStartFetchStatusPerStudent())
    return fetchReportCardStatusPerStudent(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('data inside startFetchReportCardStatus', json)
        dispatch(emitReportCardStatusDataPerStudent(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFetchStatusFailPerStudent())
      })
  }
}

const fetchReportCardStatusPerStudent = data => {
  const { jwt, owner, academic_year } = data
  let Authorization = composeAuth(jwt)
  let url =
    `${process.env.REACT_APP_SERVER_NEW}/api/reportCard/academic_year/${academic_year}/owner?` +
    querystring.stringify({ owner: owner })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

//publish report card
const publishReportCardStart = () => ({
  type: PUBLISH_REPORT_CARD_START
})

const publishReportCardFail = errMsg => ({
  type: PUBLISH_REPORT_CARD_FAIL,
  errMsg
})

const publishReportCardSuccess = data => ({
  type: PUBLISH_REPORT_CARD_SUCCESS,
  data
})

const publishReportCard = ({ jwt, studentGroup, payload }) => {
  let Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/' +
    encodeURIComponent(studentGroup) +
    '/reportcardpublishmarks'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPublishReportCard = data => {
  return function(dispatch) {
    dispatch(publishReportCardStart())
    return publishReportCard(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('data inside startPublishReportCard', json)
        dispatch(publishReportCardSuccess(json.data))
        successToast('Successfully published report card')
      })
      .catch(err => {
        console.error(err)
        errorToast('Failed to publish report card')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(publishReportCardFail(err.message))
      })
  }
}

//get student's published report card
const getStudentPublishedReportCardStart = () => ({
  type: GET_PUBLISHED_REPORT_CARD_STUDENT_START
})

const getStudentPublishedReportCardFail = errMsg => ({
  type: GET_PUBLISHED_REPORT_CARD_STUDENT_FAIL,
  errMsg
})

const getStudentPublishedReportCardSuccess = data => ({
  type: GET_PUBLISHED_REPORT_CARD_STUDENT_SUCCESS,
  data
})

const getStudentPublishedReportCard = ({ jwt, academic_year }) => {
  let Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/reportCard/academic_year/${academic_year}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startGetStudentPublishedReportCardStart = data => {
  console.log('data: ', data)
  return function(dispatch) {
    dispatch(getStudentPublishedReportCardStart())
    return getStudentPublishedReportCard(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('data inside startGetStudentPublishedReportCardStart', json)
        dispatch(getStudentPublishedReportCardSuccess(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(getStudentPublishedReportCardFail(err.message))
      })
  }
}

//publish marks
const publishMarksStart = () => ({
  type: PUBLISH_MARKS_START
})

const publishMarksFail = errMsg => ({
  type: PUBLISH_MARKS_FAIL,
  errMsg
})

const publishMarksSuccess = data => ({
  type: PUBLISH_MARKS_SUCCESS,
  data
})

const publishMarks = ({ jwt, studentGroup, payload }) => {
  let Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/studentgroup/' +
    encodeURIComponent(studentGroup) +
    '/publishmarks'
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPublishMarks = data => {
  return function(dispatch) {
    dispatch(publishMarksStart())
    return publishMarks(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('data inside startPublishMarks', json)
        dispatch(publishMarksSuccess(json.data))
        successToast('Successfully published marks')
      })
      .catch(err => {
        console.error(err)
        errorToast('Failed to publish marks')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(publishMarksFail(err.message))
      })
  }
}
