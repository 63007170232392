import {
  APP_LOGOUT,
  FLATTENED_TEACHERS_DATA,
  FLATTENED_TEACHERS_GET_STARTED,
  FLATTENED_TEACHERS_FETCH_FAIL,
  FLATTENED_ARCHIVE_TEACHERS_DATA,
  SET_SEARCH_FILTER_FOR_TEACHER_LIST,
  CLEAR_TEACHER_DATA,
  FLATTENED_ARCHIVE_TEACHERS_GET_STARTED,
  FLATTENED_ARCHIVE_TEACHERS_GET_FAIL
} from '../helpers/actions.js'

import { createSelector } from 'reselect'

import { TEACHER_DETAILS_DEFAULT_SELECTED } from '../helpers/enums'

const initialState = {
  byId: {},
  allIds: [],
  archiveById: {},
  archiveAllIds: [],
  isLoading: false,
  searchFilter: TEACHER_DETAILS_DEFAULT_SELECTED,
  archiveLoading: false
}

export const flattenedTeachers = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case FLATTENED_TEACHERS_GET_STARTED:
      return Object.assign({}, state, {
        isLoading: true
      })
    case FLATTENED_TEACHERS_DATA:
      return Object.assign({}, state, {
        byId: action.entities,
        allIds: action.result,
        isLoading: false
      })
    case FLATTENED_ARCHIVE_TEACHERS_DATA:
      return Object.assign({}, state, {
        archiveById: action.entities,
        archiveAllIds: action.result,
        archiveLoading: false
      })
    case FLATTENED_TEACHERS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case FLATTENED_ARCHIVE_TEACHERS_GET_STARTED:
      return Object.assign({}, state, {
        archiveLoading: true
      })
    case FLATTENED_ARCHIVE_TEACHERS_GET_FAIL:
      return Object.assign({}, state, {
        archiveLoading: false
      })
    case SET_SEARCH_FILTER_FOR_TEACHER_LIST:
      return Object.assign({}, state, {
        searchFilter: action.data
      })
    case CLEAR_TEACHER_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: {},
        allIds: [],
        archiveById: {},
        archiveAllIds: []
      })
    default:
      return state
  }
}

export const getById = state => state.flattenedTeachers.byId

export const getAllIds = state => state.flattenedTeachers.allIds

export const isLoading = state => state.flattenedTeachers.isLoading

export const getSearchFilter = state => state.flattenedTeachers.searchFilter

export const getArchiveById = state => state.flattenedTeachers.archiveById

export const getArchiveAllIds = state => state.flattenedTeachers.archiveAllIds

export const getArchiveLoading = state => state.flattenedTeachers.archiveLoading

export const getTeachersData = createSelector(
  getById,
  getAllIds,
  (teachersById, teacherAllIds) => {
    if (teacherAllIds.length === 0) return []
    return teacherAllIds.map(teacherId => teachersById[teacherId])
  }
)

export const getArchiveTeachersData = createSelector(
  getArchiveById,
  getArchiveAllIds,
  (teachersById, teacherAllIds) => {
    if (teacherAllIds?.length === 0) return []
    return teacherAllIds?.map(teacherId => teachersById[teacherId])
  }
)

export const getUnArchivedTeachersData = createSelector(
  getById,
  getAllIds,
  (teachersById, teacherAllIds) => {
    if (teacherAllIds.length === 0) return []
    let temp = teacherAllIds.map(teacherId => teachersById[teacherId])
    return temp.filter(item => !item.archive)
  }
)

export const getTeachersDataSortedByName = createSelector(
  getTeachersData,
  teacherArr => {
    return teacherArr.sort((a, b) => {
      if (a.name < b.name) return -1
      return 1
    })
  }
)

export const getArchiveTeachersDataSortedByName = createSelector(
  getArchiveTeachersData,
  teacherArr => {
    return teacherArr?.sort((a, b) => {
      if (a.name < b.name) return -1
      return 1
    })
  }
)

export const getAllTeachersById = createSelector(
  getById,
  getArchiveById,
  (byId, archiveById) => {
    let allTeachers = { ...byId, ...archiveById }
    return allTeachers
  }
)
