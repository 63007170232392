import React from 'react'
import { SpinnerRoundFilled } from 'spinners-react'

const SpinnerNew = () => {
  return (
    <div className="spinner">
      <SpinnerRoundFilled
        size={50}
        thickness={135}
        speed={100}
        color="rgba(57, 130, 172, 1)"
        secondaryColor="rgba(0, 0, 0, 0.44)"
      />
    </div>
  )
}

export default SpinnerNew
