import { SET_ACTIVE_GALLERY, APP_LOGOUT } from '../../helpers/actions'

const initialState = {
  activeGallery: ''
}

export const gallery = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SET_ACTIVE_GALLERY:
      return Object.assign({}, state, {
        activeGallery: action.id
      })
    default:
      return state
  }
}

export const getActiveGallery = state => state.ui.gallery.activeGallery
