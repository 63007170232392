// import packages
import { useRef, useEffect } from 'react'
import moment from 'moment'
import underscore from 'underscore'
import ReactTooltip from 'react-tooltip'
// import enums
import { DAYS, MONTHS_TYPES } from './enums'
/* Custom Errors */
import customError from 'custom-error'
import { toast } from 'react-toastify'
import * as json2csv from 'json2csv'
import jsreport from 'jsreport-browser-client-dist'
import $ from 'jquery'
import jquery from 'jquery'

export const AuthError = customError('AuthError')

/* API Response Helpers */

export const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) return response

  if (401 === response.status && response.statusText !== '') {
    let error = new AuthError()
    error.message = response.statusText
    throw error
  } else {
    let error = new Error()
    error.message = response.statusText
    throw error
  }
}

export const parseJSON = response => response.json()

export const composeAuth = jwt => 'Bearer ' + jwt

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const uniqueParentGroups = groups => {
  if (groups.length === 0) return []
  return groups
    .sort((a, b) => a.orderUmbrella_group - b.orderUmbrella_group)
    .map(group => group?.parent_group || group?.umbrella_group)
    .filter((group, index, array) => {
      /*if item is the first instance of that element in
    the array then return it*/
      if (array.indexOf(group) === index) return true
      return false
    })
}

export const uniqueParentGroupsByAttdType = (groups, type) => {
  if (groups.length === 0) return []
  return groups
    .filter(
      group =>
        group?.attendance_type === type || group?.attendance_type === 'both'
    )
    .sort((a, b) => a.orderUmbrella_group - b.orderUmbrella_group)
    .map(group => group?.parent_group || group?.umbrella_group)
    .filter((group, index, array) => {
      /*if item is the first instance of that element in
    the array then return it*/
      if (array.indexOf(group) === index) return true
      return false
    })
}

export const uniqueParentGroupsBySubGroup = (parent, groups) => {
  if (groups.length === 0) return []
  let sections = getSections(parent, groups)
  const umbrellaBySubGroups = sections
    .sort((a, b) => a.orderUmbrella_group - b.orderUmbrella_group)
    .filter(group => {
      if (group.subGroup) {
        return true
      }
    })
    .map(group => group.subGroup)
  return underscore.uniq(umbrellaBySubGroups)
}

export const filterSectionsByParent = (parent, groups) => {
  return getSections(parent, groups).map(group => ({
    ...group,
    id: group._id,
    name: group.group_name
  }))
}

export const filterSectionsByParentAttdType = (parent, groups, type) => {
  return getSections(parent, groups)
    ?.filter(
      grp => grp?.attendance_type === type || grp?.attendance_type === 'both'
    )
    .map(group => ({
      id: group._id,
      name: group.group_name
    }))
}

export const filterSectionsByParentSubGroup = (subGroup, groups) => {
  if (parent !== '')
    return groups
      .filter(group => {
        if (subGroup === group.subGroup) return true
        return false
      })
      .sort((a, b) => a.orderGroup - b.orderGroup)
      .map(group => ({
        ...group,
        id: group._id,
        name: group.group_name
      }))
  else return []
}

/* TODO will refactor to remove this later*/
export const filterSections = (parent, groups) => {
  return getSections(parent, groups).map(group => group.group_name)
}

export const getSections = (parent, groups) => {
  if (parent !== '')
    return groups
      .filter(group => {
        if (parent === group.parent_group || parent === group.umbrella_group)
          return true
        return false
      })
      .sort((a, b) => a.orderGroup - b.orderGroup)
  else return []
}

export const getSectionIds = (parent, groups) => {
  console.log('parent: ', parent)

  let sections = getSections(parent, groups)
  let sectionIds = sections.map(item => item?._id)
  return sectionIds
}

export const getGroupIDBySectionName = (parent, groups, groupName) => {
  let groupId = ''
  if (parent !== '') {
    groups.forEach(group => {
      if (parent === group.parent_group && groupName === group.group_name) {
        groupId = group._id
      }
    })
  }
  return groupId
}

export const getStudentIdsForUmbrellaGroup = (
  umbrellaGroup,
  academicGroups,
  groupsById
) => {
  let sections = getSections(umbrellaGroup, academicGroups)
  let studentIds = sections.reduce(
    (acc, section) => acc.concat(groupsById[section._id].students),
    []
  )
  return studentIds
}
export const zeroReasonString = reason => {
  switch (reason.toLowerCase()) {
    case 'ab':
      return 'absent'
    case 'ne':
      return 'not eligible'
    case 'ch':
      return 'cheated'
    case 'ex':
      return 'exempted'
    case 'ns':
      return 'not submitted'
    case 'ml':
      return 'medical leave'
    default:
      return 'ERR'
  }
}

export const getZeroReason = reason => {
  if (reason === 'cheated') return 'ch'
  if (reason === 'absent') return 'ab'
  if (reason === 'not eligible') return 'ne'
  if (reason === 'exempted') return 'ex'
  if (reason === 'not submitted') return 'ns'
  if (reason === 'medical leave') return 'ml'
  return 0
}

export const marksAndZeroReason = mark => {
  if (isNaN(mark)) return [0, zeroReasonString(mark)]
  if (mark === 0) return [mark, 'zero']
  return [mark, 'notZero']
}

export const successToast = message => toast.success(`${message}`)

export const errorToast = message => toast.error(`${message}`)

//normalize Utils

export const normalizeToBoolean = value => (value === 'true' ? true : false)

// check if provided value is valid date as per HTML5 date spec and then format it
export const normalizeDate = value =>
  moment(value).format('mm/dd/yyyy').isValid
    ? moment(value).format('YYYY-MM-DD')
    : value

// get all hours for a day
export const getAllHoursInADay = () => {
  const hourArr = []
  for (let i = 1; i <= 12; i++) {
    i = String(i).length === 1 ? '0' + i : String(i)
    hourArr.push(i)
  }
  return hourArr
}

// get all minutes per hourArr
export const getAllMinutesPerHour = () => {
  const minuteArr = []
  for (let i = 0; i <= 59; i++) {
    i = String(i).length === 1 ? '0' + i : String(i)
    minuteArr.push(i)
  }
  return minuteArr
}

export const getById = (data, field = '_id') => {
  let byId = {}
  data?.forEach(s => {
    byId[s[field]] = s
  })
  return byId
}

export const getAllIds = (data, field = '_id') => {
  let allIds = []
  data.forEach(s => {
    allIds.push(s[field])
  })
  return allIds
}

export const getInstituionFeeTypes = data => {
  const institutionFee = {}
  Object.keys(data).forEach(key => {
    institutionFee[key] = data[key]['name']
  })
  return institutionFee
}

// map subjects names along with the elective names
export const getSubjectNames = (subjectsById, subjectId) => {
  if (
    Object.keys(subjectsById)?.length === 0 ||
    subjectId === '' ||
    !subjectsById?.[subjectId]
  )
    return 'Subject Not Found'
  const { name, umbrellaName, is_main } = Object.assign(
    { name: '', umbrellaName: '', is_main: false },
    subjectsById[subjectId]
  )
  if (is_main === true) {
    return umbrellaName ? `${umbrellaName} - ${name} ` : name
  }
  let mainSubject = {}
  const hasMainSubject = Object.keys(subjectsById)?.some(id => {
    if (subjectsById?.[id]?.electives?.includes(subjectId)) {
      mainSubject = subjectsById?.[id]
      return true
    }
    return false
  })
  if (!hasMainSubject) return 'Elective Not Found'
  if (!mainSubject?.umbrellaName) {
    mainSubject.umbrellaName = ''
  }
  const subjectName = mainSubject?.umbrellaName
    ? `${mainSubject?.umbrellaName} - ${mainSubject?.name} - ${subjectsById?.[subjectId]?.name}`
    : `${mainSubject?.name} - ${subjectsById?.[subjectId]?.name}`
  return subjectName
}

export const convertJSONToCSV = (data, fields) => {
  try {
    let csv = json2csv.parse(data, { fields })
    return csv
  } catch (err) {
    return null
  }
}

export const getFormData = files => {
  let formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const fsize = files[i].size
    const fileaccurate = Math.round(fsize / 1024)
    if (fileaccurate > 40960) {
      errorToast('File should be less than 20MB.')
      break
    } else formData.append('file', file, file.name)
  }
  return formData
}

// get subjectIds per group
export const getGroupSubjectIds = (groupsById, groupId) => {
  if (groupId === '' || Object.keys(groupsById).length === 0) return []
  /*
  TODO: No need for `Object.assign` for simple guards like this
  for a single attribute you can peacefully use the `?` operator only

  ```js
  const subjects = groupsById[groupId]? groupsById[groupId]: []
  ```

  OR

  ```js
  const subjects = groupsById[groupId]
  return subjects? subjects: []
  ```

  depending on the case
  */
  const { subjects } = Object.assign({ subjects: [] }, groupsById[groupId])
  return subjects?.map(subObj => subObj?.subject_id)
}

// get all subject ids of  a class
export const getClassSubjectIds = (academicGroups, umbrellaGroup) => {
  if (academicGroups?.length === 0 || umbrellaGroup === '') return []
  return underscore.uniq(
    underscore.flatten(
      academicGroups
        ?.filter(
          groupObj =>
            groupObj?.parent_group === umbrellaGroup ||
            groupObj?.umbrella_group === umbrellaGroup
        )
        ?.map(({ subjects }) => {
          return subjects?.map(subjectObj => subjectObj?.subject_id)
        })
    )
  )
}

export const getTeacherSubjectIdsPerGroup = (
  teacherSubjectIds,
  groupSubjectIds
) => {
  return teacherSubjectIds.filter(subjectId =>
    groupSubjectIds.includes(subjectId)
  )
}

// sort and filter students
export const filterStudents = ({
  studentsData,
  order,
  header,
  search,
  fields = ['roll_number', 'name']
}) => {
  let students = studentsData.slice()
  if (header) {
    if (
      header === 'roll_number' ||
      header === 'totalAttDay' ||
      header === 'totalPercentage' ||
      header === 'percentage' ||
      header === 'totalMarks' ||
      header === 'grandTotal'
    ) {
      students = students.sort((a, b) => {
        if (order[header]) return Number(b[header]) - Number(a[header])
        return Number(a[header]) - Number(b[header])
      })
    } else {
      students = students.sort((a, b) => {
        if (order[header]) {
          if (a?.[header]?.toLowerCase() < b?.[header]?.toLowerCase()) return 1
          return -1
        } else {
          if (a?.[header]?.toLowerCase() < b?.[header]?.toLowerCase()) return -1
          return 1
        }
      })
    }
  }

  if (0 === search.length) return students

  const filterAcross = (fields = []) => {
    return student => {
      return fields.some(field => {
        if (
          student[field] !== undefined &&
          -1 !==
            student[field]
              .toString()
              .toLowerCase()
              .indexOf(search)
        )
          return true
        return false
      })
    }
  }

  return students.filter(filterAcross(fields))
}

export const filterTeachers = ({
  teachersData,
  order,
  header,
  search,
  fields = ['name']
}) => {
  let teachers = teachersData.slice()
  if (header) {
    teachers = teachers.sort((a, b) => {
      if (order[header]) {
        if (a[header].toLowerCase() < b[header].toLowerCase()) return 1
        return -1
      } else {
        if (a[header].toLowerCase() < b[header].toLowerCase()) return -1
        return 1
      }
    })
  }

  if (search.length === 0) return teachers

  const filterAcross = (fields = []) => {
    return teacher => {
      return fields.some(field => {
        return (
          teacher[field] !== undefined &&
          teacher[field]
            .toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        )
      })
    }
  }
  return teachers.filter(filterAcross(fields))
}

export const getSortIcon = ({ header, order }) => {
  if (order[header]) return 'sort-amount-desc'
  return 'sort-amount-asc'
}

export const getToolTip = ({ header, order }) => {
  if (order[header]) return 'sort ascending'
  return 'sort descending'
}

export const getToolTipInfo = (header, order) => {
  return (
    <ReactTooltip className="tooltip" place="bottom" type="light" id={header}>
      <div className="tooltip-text">{getToolTip({ header, order })}</div>
    </ReactTooltip>
  )
}

export const downloadReport = ({
  nameOfTemplate,
  dataForTemplate,
  downloadedFileName
}) => {
  jsreport.serverUrl = 'https://jsreport.sealtabs.com'

  const request = {
    template: {
      name: nameOfTemplate,
      engine: 'handlebars',
      recipe: 'chrome-pdf'
    },
    data: dataForTemplate
  }

  jsreport.renderAsync(request).then(res => {
    res.download(`${downloadedFileName}.pdf`)
  })
}

// get all subjectIDs across an institution
export const getAllSubjectIdsAcrossInstitution = academicGroups => {
  return underscore.uniq(
    underscore.flatten(
      academicGroups.map(({ subjects }) => {
        return subjects.map(({ subject_id }) => subject_id)
      })
    )
  )
}

// get hourArr for current day
export const getHoursDataForCurrentDay = (hoursArr, date) => {
  const dayIndex = date.day()
  const currentDay = DAYS[dayIndex]
  return hoursArr.filter(hourObj => hourObj.day === currentDay)
}

// get hourArr for the selectedDay
export const getHourArrForSelectedDay = (hourArr, day) => {
  if (hourArr.length === 0 || !day) return []
  return hourArr.filter(hourObj => hourObj.day === day)
}

// get subject array for given umbrella group
export const getAllSubjectsForSelectedUmbrellaGroup = (
  umbrellaGroup,
  academicGroups,
  groupsById
) => {
  const groups = filterSectionsByParent(umbrellaGroup, academicGroups)
  const subjects = underscore.uniq(
    underscore.flatten(
      groups.map(group => {
        return [
          groupsById[group.id].subjects.map(subject => subject['subject_id']),
          groupsById[group.id].coscholastic
        ]
      })
    )
  )
  return subjects
}

// get subject data per group for group selector
export const getSubjectDataForSelector = ({
  groupId,
  groupsById,
  subjectsLoading,
  subjectsById,
  level,
  subjectIdsForTeacher
}) => {
  if (groupId === '' || Object.keys(groupsById).length === 0 || subjectsLoading)
    return []
  if (level === 'teacher') {
    const groupSubjectIds = getGroupSubjectIds(groupsById, groupId)
    const subjectIds = getTeacherSubjectIdsPerGroup(
      subjectIdsForTeacher,
      groupSubjectIds
    )
    return subjectIds
      .filter(sub => !subjectsById[sub].archive)
      .filter(subjectId =>
        subjectsById[subjectId] && subjectsById[subjectId].electives
          ? subjectsById[subjectId].electives.length === 0
          : false
      )
      .map(subjectId => {
        const name = getSubjectNames(subjectsById, subjectId)
        return {
          id: subjectId,
          name
        }
      })
  }
  return groupsById[groupId].subjects
    .filter(subjectObj => {
      return (
        subjectsById[subjectObj.subject_id]?.is_main &&
        !subjectsById[subjectObj.subject_id]?.archive
      )
    })
    .map(subjectObj => {
      const { _id: id, name } = subjectsById[subjectObj.subject_id]
      return { id, name }
    })
}

// to display elective subjects in the dropdown of groupSelector
export const getElectivesArr = (subjectsById, subjectId) => {
  if (subjectId === '' || Object.keys(subjectsById).length === 0) return []
  return subjectsById[subjectId].electives.map(subjectId => {
    const { _id, name } = Object.assign(
      { _id: '', name: '' },
      subjectsById[subjectId]
    )
    return { id: _id, name }
  })
}

export const getElectivesArrToAssignSubject = (
  subjectsById,
  subjectId,
  groupSubjectIds
) => {
  if (
    subjectId === '' ||
    Object.keys(subjectsById).length === 0 ||
    groupSubjectIds.length === 0
  )
    return []
  const { electives } = Object.assign(
    { electives: [] },
    subjectsById[subjectId]
  )
  return electives
    .filter(electiveId => groupSubjectIds.includes(electiveId))
    .map(subjectId => {
      const { _id, name, archive } = Object.assign(
        { _id: '', name: 'Elective Not Found', archive: '' },
        subjectsById[subjectId]
      )
      return { id: _id, name, archive }
    })
    .filter(sub => !sub.archive)
}

//get students data by studentIds
export const getStudentData = (studentsById, studentIds) => {
  return studentIds.map(id => {
    return Object.assign(
      {},
      {
        _id: 'archived',
        name: '',
        roll_number: '',
        admission_number: ''
      },
      studentsById[id]
    )
  })
}

/*
  get studentIds from the selected group which r not assigned to any of the
  electives
*/
export const getUnassignedStudentIds = (
  groupStudentIds,
  electiveIds,
  groupSubjects,
  electiveId,
  subjectId
) => {
  let studentIds = []

  if (electiveId !== '') {
    if (subjectId === electiveId) {
      studentIds = studentIds.concat(
        groupSubjects
          .filter(subjectObj => subjectObj.subject_id === subjectId)
          .map(subjectObj => subjectObj.student_id)
      )
    } else {
      studentIds = studentIds.concat(
        groupSubjects
          .filter(subjectObj => electiveIds.includes(subjectObj.subject_id))
          .map(subjectObj => subjectObj.student_id)
      )
    }
  } else {
    studentIds = studentIds.concat(
      groupSubjects
        .filter(subjectObj => subjectObj.subject_id === subjectId)
        .map(subjectObj => subjectObj.student_id)
    )
  }
  studentIds = underscore.flatten(studentIds)
  return underscore.difference(groupStudentIds, studentIds)
}

// get months arr for groupSelector
export const getMonthsDataForSelectors = () => {
  return MONTHS_TYPES.map((month, index) => {
    return { id: index + 1, name: month }
  })
}

// get studentIds from the selected subject/elective
export const getStudentIdsPerSubject = (groupStudents, subjects, subjectId) => {
  if (!subjectId || subjects.length === 0) return []
  const [subjectStudents] = subjects
    .filter(subjectObj => subjectObj.subject_id === subjectId)
    .map(subjectObj => subjectObj.student_id)
  return underscore.intersection(groupStudents, subjectStudents)
}

export const renderMaxMarks = (subject, studentData) => {
  if (subject.marksGrade === 'grade') return 'G'
  const subjectId = subject._id
  const studentObjWithMaxMarks = studentData?.find(studentObj => {
    if (
      Object.keys(studentObj['marks']).includes(subjectId) &&
      studentObj['marks']?.[subjectId]?.maxMarks
    )
      return Object.keys(studentObj['marks']).includes(subjectId)
  })
  if (studentObjWithMaxMarks) {
    return studentObjWithMaxMarks['marks'][subjectId]['maxMarks']
  }
  return null
}

export const renderMaxMarksForSubtest = (subject, studentData, test) => {
  if (subject.marksGrade === 'grade') return 'G'
  const subjectId = subject._id
  const studentObjWithMaxMarks = studentData?.find(studentObj => {
    if (
      Object.keys(studentObj['marks']).includes(subjectId) &&
      studentObj['marks']?.[subjectId][test]?.maxMarks
    )
      return Object.keys(studentObj['marks']).includes(subjectId)
  })
  if (studentObjWithMaxMarks) {
    return studentObjWithMaxMarks['marks'][subjectId][test]['maxMarks']
  }
  return null
}

export const getMark = (mark, subject) => {
  if (!mark.zeroReason) return mark.mark
  if (mark.zeroReason !== 'notZero') return getZeroReason(mark.zeroReason)
  if (subject.marksGrade === 'grade') {
    if (mark.grade) return mark.grade
  }
  return mark.mark
}

export const checkForElective = markObj => {
  return markObj && markObj.elective ? `${markObj.elective}` : null
}

// get the subjectId of the main subject to which the elective Id belongs to
export const getParentSubjectOfAnElective = (mainSubjectsArr, electiveId) => {
  if (mainSubjectsArr.length === 0 || !electiveId) return ''
  let mainSubjectId = ''
  mainSubjectsArr.some(subObj => {
    const { electives } = Object.assign({ electives: [] }, subObj)
    if (electives.includes(electiveId)) {
      mainSubjectId = subObj._id
      return true
    }
    return false
  })
  return mainSubjectId
}

// get all weekdays from monday to saturday
export const getAllWeekDays = () => {
  return DAYS.filter(day => day !== 'Sunday')
}

// get subject name with safe values
export const getSubjectNameWithSafeValues = (subjectsById, subjectId) => {
  if (Object.keys(subjectsById).length === 0 || subjectId === '') return ''
  const { name } = Object.assign({ name: '' }, subjectsById[subjectId])
  return name
}

// get all months which fall between start and end dates
export const getMonthsBetweenDates = (startDate, endDate) => {
  const allDateMonths = []
  for (
    let i = startDate;
    moment(i).isSameOrBefore(endDate);
    i = moment(i).add(1, 'day')
  ) {
    const month = moment(i).format('MM')
    allDateMonths.push(Number(month))
  }
  let months = underscore.uniq(allDateMonths)
  months.pop()
  months.shift()
  return months
}

// get graph data for student performance
export const getGraphData = ({
  testId,
  testsById,
  subjectArray,
  marksByTestId,
  reportByTestId
}) => {
  const testObj = testsById[testId]
  const { subjects } = Object.assign({ subjects: [] }, testObj)
  const testSubjectIds = subjects.map(subObj => subObj.subject)
  return subjectArray
    .filter(subObj => {
      if (subObj.is_main) return testSubjectIds.includes(subObj.id)
      else {
        return testSubjectIds.includes(
          getParentSubjectOfAnElective(subjectArray, subObj.id)
        )
      }
    })
    .filter(subObj => subObj.marksGrade === 'marks')
    .filter(subObj => subObj.electives.length === 0)
    .map(subObj => {
      const { id } = subObj
      let markObj = { maxMarks: '', mark: '' }
      // let reportObj = { classAvg: '', sectionAvg: '' }
      if (
        marksByTestId?.[testId] &&
        marksByTestId?.[testId]?.[id] !== undefined
      ) {
        markObj = marksByTestId[testId][id]
        // reportObj = reportByTestId[testId][id]
      } else {
        let maxMarks = 0
        let mark = 0
        let subtestSubject = subjects?.find(sub => sub?.subject === subObj?.id)
        subtestSubject?.tests &&
          subtestSubject?.tests?.length > 0 &&
          subtestSubject?.tests?.forEach(test => {
            mark = mark + marksByTestId?.[test]?.[id]?.mark
            maxMarks = maxMarks + marksByTestId?.[test]?.[id]?.maxMarks
          })
        markObj = {
          maxMarks,
          mark
        }
      }
      const { maxMarks, mark, zeroReason } = Object.assign(
        { maxMarks: '', mark: '', zeroReason: '' },
        markObj
      )
      let mainSubjectId = ''
      let mainSubjectDetails = {}
      const subjectDetails = subjectArray?.find(sub => sub?.id === id)
      if (!subjectDetails?.is_main) {
        mainSubjectId = getParentSubjectOfAnElective(subjectArray, id)
        mainSubjectDetails = subjectArray?.find(
          sub => sub?.id === mainSubjectId
        )
      }
      // if ()
      // const { classAvg, sectionAvg } = Object.assign(
      //   { classAvg: '', sectionAvg: '' },
      //   reportObj
      // )
      const studentPercentage = calculatePercentage(
        mark,
        maxMarks,
        testObj?.percentage
      )
      // const classPercentage = calculatePercentage(classAvg, maxMarks)
      // const sectionPercentage = calculatePercentage(sectionAvg, maxMarks)
      return {
        code: subObj.code,
        name: subjectDetails?.is_main
          ? subObj.name
          : `${mainSubjectDetails?.name} - ${subObj?.name}`,
        mark: mark,
        maxMarks: maxMarks,
        percentage: studentPercentage,
        order: !subjectDetails?.is_main
          ? mainSubjectDetails?.order
          : subjectDetails?.order,
        zeroReason: zeroReason
        // classAverage: classPercentage,
        // sectionAverage: sectionPercentage
      }
    })
}

export const calculatePercentage = (mark, maxMarks, testPercentage) => {
  const percentage = Math.round(
    (Number(mark) / Number(maxMarks)) * testPercentage
  )
  if (isNaN(percentage)) return ''
  return percentage
}

// check if test is published, for performance
export const isTestPublished = ({ testId, groupsById, studentId }) => {
  if (!testId) return false
  const groupId = Object.keys(groupsById).find(groupId => {
    const { students } = groupsById[groupId]
    return students.includes(studentId)
  })

  const groupObj = groupsById[groupId]
  const { publish } = Object.assign({ publish: [] }, groupObj)
  const testObj = publish.find(obj => obj.test === testId)
  if (!testObj) return false
  return true
}

// check if test is published, for performance
export const isTestPublishedYear = ({
  testId,
  groupsById,
  studentId,
  year
}) => {
  if (!testId) return false
  const groupId = Object.keys(groupsById).find(groupId => {
    const { students, academic_year } = groupsById[groupId]

    return students.includes(studentId) && academic_year === year
  })

  const groupObj = groupsById[groupId]

  const { publish } = Object.assign({ publish: [] }, groupObj)
  const testObj = publish.find(obj => obj.test === testId)
  if (!testObj) return false
  if (!testObj.reportcardPublish) return false
  return true
}

// get groupObj for the given student Id
export const getGroupPerStudentId = (studentId, groupsById) => {
  if (!studentId || Object.keys(groupsById).length === 0) return undefined
  const groupId = Object.keys(groupsById).find(groupId => {
    return groupsById[groupId].students.includes(studentId)
  })
  if (groupId === undefined) {
    const archiveGroupId = Object.keys(groupsById).find(groupId => {
      return groupsById[groupId].archiveStudents.includes(studentId)
    })
  }
  const archiveGroupId = Object.keys(groupsById).find(groupId => {
    return groupsById[groupId].archiveStudents.includes(studentId)
  })
  return groupId !== undefined
    ? groupsById[groupId]
    : groupsById[archiveGroupId]
}
//// get groupObj for the given student Id
export const getGroupPerStudentIdForBothArchiveAndUnarchive = (
  studentId,
  groupsById,
  student
) => {
  const { archived } = Object.assign(
    {},
    {
      archived: ''
    },
    student
  )
  if (!studentId || Object.keys(groupsById).length === 0 || archived === '')
    return undefined
  const groupId = archived
    ? Object.keys(groupsById).find(groupId => {
        return groupsById[groupId].archiveStudents.includes(studentId)
      })
    : Object.keys(groupsById).find(groupId => {
        return groupsById[groupId].students.includes(studentId)
      })
  return groupsById[groupId]
}

// used to set default testId with order 1
export const getTestObjectWithOrder1 = testArr => {
  if (testArr.length === 0) return undefined
  const testObj = testArr.find(testObj => Number(testObj.order) === 1)
  return testObj
}

// get mark data for graph (using for multiple test comparison)
export const getMarkDataForGraph = ({
  group,
  groupsById,
  studentId,
  testArr,
  subjectArray,
  marksByTestId
}) => {
  const data = []
  // const publishedTestArr = testArr.filter(testObj => {
  //   return isTestPublished({ testId: testObj._id, groupsById, studentId })
  // })

  let bySubjectId = {}
  if (group) {
    group?.subjects?.forEach(sub => {
      bySubjectId[sub.subject_id] = sub
    })
  }

  const subjectList = subjectArray.filter(sub => {
    return bySubjectId[sub._id]
      ? bySubjectId[sub._id].student_id.includes(studentId)
      : null
  })
  subjectList
    .filter(subObj => subObj.marksGrade === 'marks')
    .filter(subObj => subObj.electives.length === 0)
    .forEach(subObj => {
      const { _id: subjectId, code, is_main } = subObj
      const obj = { name: code, testName: [] }
      testArr.forEach(testObj => {
        const { subjects, _id: testId, name: testName } = Object.assign(
          { subjects: [] },
          testObj
        )
        const testSubjectIds = subjects.map(subObj => subObj.subject)
        if (
          (is_main && testSubjectIds.includes(subjectId)) ||
          (!is_main &&
            testSubjectIds.includes(
              getParentSubjectOfAnElective(subjectArray, subjectId)
            ))
        ) {
          let markObj = { maxMarks: '', mark: '' }
          if (marksByTestId[testId]) {
            markObj = marksByTestId[testId][subjectId]
          }
          const { mark, maxMarks } = Object.assign(
            { mark: '', maxMarks: '' },
            markObj
          )
          const percentage = calculatePercentage(
            mark,
            maxMarks,
            testObj?.percentage
          )
          obj[testName] = percentage
          obj.testName.push(testName)
        }
      })
      data.push(obj)
    })
  return data
}

// get groups for admission groups
export const getAdmissionGroupsForDropdown = admissionGroups => {
  if (admissionGroups.length === 0) return []
  return admissionGroups.map(groupObj => {
    const { _id: id, parent_group: name } = groupObj
    return { id, name }
  })
}

// get student arr sorted by admission date
export const getStudentArrSortedByDate = (studentArr, dateType) => {
  return studentArr.sort((a, b) => {
    return a[dateType] - b[dateType]
  })
}

// get tests Ids of a selected class
export const getTestIdsOfAClass = (umbrellaGroup, academicGroups) => {
  if (umbrellaGroup === '' || academicGroups.length === 0) return []
  return underscore.uniq(
    underscore.flatten(
      academicGroups
        .filter(
          groupObj =>
            groupObj.parent_group === umbrellaGroup ||
            groupObj.umbrella_group === umbrellaGroup
        )
        .map(groupObj => {
          const { tests } = Object.assign({ tests: [] }, groupObj)
          return tests
        })
    )
  )
}

// get test arr of a class
export const getTestArrOfAClass = (umbrellaGroup, academicGroups, testArr) => {
  if (
    umbrellaGroup === '' ||
    academicGroups?.length === 0 ||
    testArr?.length === 0
  )
    return []
  const testIds = getTestIdsOfAClass(umbrellaGroup, academicGroups)
  return testArr?.filter(testObj => testIds?.includes(testObj._id))
}

// get testIds of a section
export const getTestIdsOfASection = (groupId, academicGroups) => {
  if (groupId === '' || academicGroups.length === 0) return []
  const groupObj = academicGroups.find(groupObj => groupObj._id === groupId)
  const { tests } = Object.assign({ tests: [] }, groupObj)
  return tests
}

// get testArr of a section
export const getTestArrOfASection = (groupId, academicGroups, testArr) => {
  if (groupId === '' || academicGroups?.length === 0 || testArr?.length === 0)
    return []
  const testIds = getTestIdsOfASection(groupId, academicGroups)
  if (testIds?.length === 0) return []
  return testArr?.filter(testObj => testIds?.includes(testObj._id))
}

// get testArr of a subject
export const getTestArrOfASubject = (subjectId, testArr) => {
  if (subjectId === '' || testArr?.length === 0) return []
  const testArrOfSubjectId = []
  testArr?.forEach(testObj => {
    const subjectIds = testObj?.subjects?.map(subObj => subObj?.subject)
    if (subjectIds.includes(subjectId)) {
      testArrOfSubjectId.push(testObj)
    }
  })
  return testArrOfSubjectId
}

// get subject teachers of a class
export const getSubjectTeacherObjOfAClass = (umbrellaGroup, academicGroups) => {
  if (umbrellaGroup === '' || academicGroups?.length === 0) return {}
  const obj = {}
  academicGroups
    ?.filter(
      groupObj =>
        groupObj?.parent_group === umbrellaGroup ||
        groupObj?.umbrella_group === umbrellaGroup
    )
    ?.forEach(groupObj => {
      groupObj?.subjects?.forEach(subObj => {
        const { subject_id, teacher_id } = subObj
        if (!Object.keys(obj).includes(subject_id)) {
          obj[subject_id] = teacher_id
        }
      })
    })
  return obj
}

// get subject teachers of a section
export const getSubjectTeacherObjOfASection = groupSubjectArr => {
  if (groupSubjectArr?.length === 0) return {}
  const obj = {}
  groupSubjectArr?.forEach(subObj => {
    const { subject_id, teacher_id } = subObj
    if (!Object.keys(obj).includes(subject_id)) {
      obj[subject_id] = teacher_id
    }
  })
  return obj
}

// get class teacher for all sections
export const getClassTeachersPerClass = (umbrellaGroup, academicGroups) => {
  if (umbrellaGroup === '' || academicGroups?.length === 0) return {}
  const obj = {}
  academicGroups
    ?.filter(groupObj => {
      return (
        groupObj?.parent_group === umbrellaGroup ||
        groupObj?.umbrella_group === umbrellaGroup
      )
    })
    ?.forEach(groupObj => {
      obj[groupObj._id] = groupObj?.class_teacher
    })
  return obj
}

// get subject Id of a student (marks entry)
export const getSubjectIdOfAStudent = ({
  studentId,
  subjectId,
  subjectsById,
  groupsById,
  mainSubjectId,
  groupId
}) => {
  if (subjectId === 'all') {
    let elective
    const { electives } = Object.assign(
      { electives: [] },
      subjectsById?.[mainSubjectId]
    )
    const { subjects } = Object.assign({ subjects: [] }, groupsById?.[groupId])
    electives?.some(electiveId => {
      const subjectObj = subjects?.find(
        subjectObj => subjectObj?.subject_id === electiveId
      )
      if (subjectObj && subjectObj?.student_id.includes(studentId)) {
        elective = electiveId
        return true
      }
      return false
    })
    return elective
  }
  return subjectId
}

export const getSubjectGradesByMarks = ({
  marksByTestIdObj,
  gradesById,
  gradesInTest
}) => {
  const test = gradesInTest.filter(grade => {
    if (gradesById[grade]) {
      const { minMarks, maxMarks } = gradesById[grade]
      if (
        minMarks <= marksByTestIdObj['mark'] &&
        maxMarks >= marksByTestIdObj['mark']
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  })[0]
  return gradesById[test] ? gradesById[test] : ''
}
export const getElectiveIdForMainSubject = ({
  subjectsGroupDataById,
  subject,
  student,
  subjectId
}) => {
  if (subject.electives.length) {
    const electiveSubjectId = subject.electives.filter(sub => {
      return subjectsGroupDataById?.[sub]?.['student_id'].includes(student)
    })[0]

    return electiveSubjectId
  } else {
    if (subjectsGroupDataById[subjectId]['student_id'].includes(student)) {
      return subjectId
    } else {
      return false
    }
  }
}

export const getElectiveIdForstudent = ({
  subjectsGroupDataById,
  subject,
  student,
  subjectId
}) => {
  const electiveSubjectId = subject?.electives?.filter(sub => {
    return (
      subjectsGroupDataById?.[sub] &&
      subjectsGroupDataById?.[sub]?.['student_id']?.includes(student)
    )
  })[0]

  return electiveSubjectId
}

export const addEmptyEntries = (divisor, arr) => {
  const newArr = []
  const num = arr.length
  const remainder = num % divisor
  if (remainder !== 0)
    for (let i = 0; i < divisor - remainder; i++) {
      newArr.push(<div key={i} className="entries__entry" />)
    }
  return newArr
}

export const getLatestAcademicYearPlus = academicyears => {
  let latestAcademicYear = Math.max(...academicyears)
  let newAcademicYearList = academicyears.map(item => item)
  for (let i = 0; i < 2; i++) {
    newAcademicYearList.push((latestAcademicYear + (i + 1)).toString())
  }
  return newAcademicYearList
}

export const getAcademicYearWithNextYear = () => {
  let currentYear = moment().year()
  let yearArr = [currentYear]
  for (let i = 1; i <= 5; i++) {
    yearArr.push(currentYear - i)
    yearArr.push(currentYear + i)
  }
  let yearData = yearArr
    .sort((a, b) => b - a)
    .map(item => {
      return {
        id: item?.toString(),
        name: `${item?.toString()}-${(item + 1)?.toString()?.substr(-2)}`
      }
    })
  return yearData
}

export const getGroupSubjectIdPerLevel = (
  groupsById,
  groupId,
  level,
  levelId
) => {
  if (groupId === '' || Object.keys(groupsById).length === 0) return []
  const { subjects } = Object.assign({ subjects: [] }, groupsById[groupId])

  const subjects_list = []
  subjects?.forEach(subObj => {
    if (level !== 'teacher') {
      subjects_list.push(subObj?.subject_id)
    } else if (subObj.teacher_id.includes(levelId)) {
      subjects_list.push(subObj?.subject_id)
    }
  })
  return subjects_list
}

export const enterFuntion = e => {
  jquery.extend(jquery.expr[':'], {
    focusable: (el, index, selector) => {
      return $(el).is(' :input, [tabindex]')
    }
  })

  if (e.which === 13) {
    e.preventDefault()
    // Get all focusable elements on the page
    var $canfocus = $(':focusable')
    var index = $canfocus.index(document.activeElement) + 1
    if (index >= $canfocus.length) index = 0
    $canfocus.eq(index).focus()
  }
}

export const getAttdToolTip = (
  group,
  hourId,
  groupsById,
  teachersById,
  subjectsById,
  timetableByGroup,
  attendanceType
) => {
  if (attendanceType === 'daily') {
    const groupObj = groupsById?.[group]
    return teachersById?.[groupObj?.class_teacher]
  } else {
    let subjectDetail = []
    const groupTimetable = timetableByGroup?.[group]
    if (groupTimetable) {
      const hourObj = groupTimetable?.[hourId]
      if (hourObj) {
        subjectDetail = hourObj?.details
      }
    }
    if (subjectDetail && subjectDetail?.length > 0) {
      let teacherArr = []
      let subjectArr = []
      subjectDetail?.forEach(item => {
        teacherArr.push(teachersById?.[item?.teacher])
        subjectArr.push(subjectsById?.[item?.subject]?.name)
      })
      return { teacherArr, subjectArr }
    }
    return { teacherArr: [], subjectArr: [] }
  }
}

export const getGrade = (mark, gradesArr) => {
  let grade = ''
  gradesArr.forEach(gradeObj => {
    const { minMarks, maxMarks, name } = gradeObj
    if (mark >= minMarks && mark <= maxMarks) {
      grade = name
      return true
    }
    return false
  })
  return grade
}
