import {
  POST_FINE_TEMPLATE_START,
  POST_FINE_TEMPLATE_SUCCESS,
  POST_FINE_TEMPLATE_FAIL,
  GET_FINE_TEMPLATE_START,
  GET_FINE_TEMPLATE_SUCCESS,
  GET_FINE_TEMPLATE_FAIL,
  PATCH_FINE_TEMPLATE_START,
  PATCH_FINE_TEMPLATE_SUCCESS,
  PATCH_FINE_TEMPLATE_FAIL,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isPostLoading: false,
  isError: false,
  isLoading: false,
  fineTemplateData: []
}

export const fineTemplate = (state = initialState, action) => {
  switch (action.type) {
    case POST_FINE_TEMPLATE_START:
      return {
        ...state,
        isPostLoading: true,
        isError: false
      }
    case POST_FINE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        isError: false
      }
    case POST_FINE_TEMPLATE_FAIL:
      return {
        ...state,
        isPostLoading: false,
        isError: true
      }
    case GET_FINE_TEMPLATE_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        fineTemplateData: []
      }
    case GET_FINE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        fineTemplateData: action.data
      }
    case GET_FINE_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        fineTemplateData: []
      }
    case PATCH_FINE_TEMPLATE_START:
      return {
        ...state,
        isPostLoading: true,
        isError: false
      }
    case PATCH_FINE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        isError: false
      }
    case PATCH_FINE_TEMPLATE_FAIL:
      return {
        ...state,
        isPostLoading: false,
        isError: true
      }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getIsPostLoading = state => state.fineTemplate.isPostLoading
export const getIsLoading = state => state.fineTemplate.isLoading
export const getFineTemplate = state => state.fineTemplate.fineTemplateData
export const getIsError = state => state.fineTemplate.isError
