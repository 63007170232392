import {
  APP_LOGOUT,
  POST_TEACHER_DOC_START,
  POST_TEACHER_DOC_SUCCESS,
  POST_TEACHER_DOC_FAIL,
  GET_TEACHER_DOC_START,
  GET_TEACHER_DOC_SUCCESS,
  GET_TEACHER_DOC_FAIL,
  PATCH_TEACHER_DOC_START,
  PATCH_TEACHER_DOC_SUCCESS,
  PATCH_TEACHER_DOC_FAIL
} from '../helpers/actions.js'

const initialState = {
  isPostLoading: false,
  isGetLoading: false,
  isPatchLoading: false,
  document: [],
  error: false
}

export const teacherDocument = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_TEACHER_DOC_START:
      return {
        ...state,
        isPostLoading: true,
        error: false
      }
    case POST_TEACHER_DOC_SUCCESS:
      return {
        ...state,
        isPostLoading: false,
        error: false
      }
    case POST_TEACHER_DOC_FAIL:
      return {
        ...state,
        isPostLoading: false,
        error: true
      }
    case GET_TEACHER_DOC_START:
      return {
        ...state,
        isGetLoading: true,
        error: false
      }
    case GET_TEACHER_DOC_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        document: action.data,
        error: false
      }
    case GET_TEACHER_DOC_FAIL:
      return {
        ...state,
        isGetLoading: false,
        error: true
      }
    case PATCH_TEACHER_DOC_START:
      return {
        ...state,
        isPatchLoading: true,
        error: false
      }
    case PATCH_TEACHER_DOC_SUCCESS:
      return {
        ...state,
        isPatchLoading: false,
        requestDoc: action.data,
        error: false
      }
    case PATCH_TEACHER_DOC_FAIL:
      return {
        ...state,
        isPatchLoading: false,
        error: true
      }
    default:
      return state
  }
}

export const getPostLoading = state => state.teacherDocument.isPostLoading
export const getLoading = state => state.teacherDocument.isGetLoading
export const getPatchLoading = state => state.teacherDocument.isPatchLoading
export const getTeacherDocuments = state => state.teacherDocument.document
export const getError = state => state.teacherDocument.error
