import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import FontAwesome from 'react-fontawesome'
import classnames from 'classnames'

const Navigation = ({
  mainNavigation,
  subNavigation,
  targetId,
  urlarray,
  hideSubmenu,
  handleClick
}) => {
  return (
    <div className="header-right-bottom">
      <div className="nav-items">
        {mainNavigation &&
          mainNavigation?.length > 0 &&
          mainNavigation?.map(nav => {
            if (subNavigation?.[nav?._id]) {
              return (
                <div key={nav?._id} className="nav-item-ctr">
                  <div
                    data-id={nav?.name}
                    onClick={
                      targetId && targetId === nav?.name
                        ? hideSubmenu
                        : handleClick
                    }
                    className={classnames({
                      'nav-items__item': true,
                      'nav-items__item--active':
                        nav?.activePath === urlarray[1],
                      'nav-items__item--clicked':
                        targetId && targetId === nav.name
                    })}
                  >
                    <FontAwesome name={nav.icon} />
                    {nav.name}
                    <FontAwesome name="caret-down" />
                  </div>
                  <div
                    className={classnames({
                      submenu: true,
                      'submenu--visible': targetId === nav.name
                    })}
                  >
                    {subNavigation?.[nav?._id] &&
                      Object.keys(subNavigation?.[nav?._id])?.map(
                        (subNav, index) => {
                          return (
                            <div key={index}>
                              <div className="submenu-block">
                                <p className="submenu-block__title">
                                  <FontAwesome
                                    name={
                                      subNavigation?.[nav?._id][subNav][0]
                                        ?.groupByIcon
                                    }
                                  />{' '}
                                  {subNav}
                                </p>
                                <div className="submenu-block-items">
                                  {subNavigation?.[nav?._id][subNav]
                                    ?.sort((a, b) => a?.order - b?.order)
                                    ?.map(link => {
                                      return (
                                        <Link
                                          key={link?._id}
                                          className="submenu-block-items__item"
                                          to={link?.link}
                                          onClick={hideSubmenu}
                                        >
                                          {link.name}
                                        </Link>
                                      )
                                    })}
                                </div>
                              </div>
                              <div className="verticalline-header" />
                            </div>
                          )
                        }
                      )}
                  </div>
                </div>
              )
            } else {
              let url = nav?.link ? nav?.link : '/dashboard'
              return (
                <div key={nav?._id} className="nav-item-ctr">
                  <Link
                    onClick={hideSubmenu}
                    className={classnames({
                      'nav-items__item': true,
                      'nav-items__item--active': nav?.activePath === urlarray[1]
                    })}
                    to={url}
                  >
                    <FontAwesome name={nav?.icon} />
                    {nav?.name}
                  </Link>
                </div>
              )
            }
          })}
      </div>
    </div>
  )
}

export default Navigation
