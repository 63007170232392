/*
# Application wide actions.

Handle in all sub-reducers if necessary
*/
export const APP_LOGIN_START = 'APP_LOGIN_START'
export const APP_LOGIN = 'APP_LOGIN'
export const APP_LOGIN_PARENT = 'APP_LOGIN_PARENT'
export const APP_LOGIN_SUPPORT = 'APP_LOGIN_SUPPORT'
export const APP_LOGIN_FAIL = 'APP_LOGIN_FAIL'
export const APP_LOGOUT = 'APP_LOGOUT'
export const APP_AUTH_FAILED = 'APP_AUTH_FAILED'
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const APP_PARENT_LEVEL = 'APP_PARENT_LEVEL'
export const APP_SUPPORT_LEVEL = 'APP_SUPPORT_LEVEL'

/* Instituion Actions */
export const INSTITUTION_DATA = 'INSTITUTION_DATA'
export const INSTITUTION_DATA_START = 'INSTITUTION_DATA_START'
export const INSTITUTION_DATA_FAIL = 'INSTITUTION_DATA_FAIL'
export const SET_ACADEMIC_YEAR_DATA = 'SET_ACADEMIC_YEAR_DATA'
export const POST_INSTITUTION_START = 'POST_INSTITUTION_START'
export const POST_INSTITUTION_FAIL = 'POST_INSTITUTION_FAIL'
export const POST_INSTITUTION_DATA = 'POST_INSTITUTION_DATA'
export const FETCH_ALL_INSTITUTIONS_DATA = 'FETCH_ALL_INSTITUTIONS_DATA'
export const FETCH_ALL_INSTITUTIONS_START = 'FETCH_ALL_INSTITUTIONS_START'
export const FETCH_ALL_INSTITUTIONS_FAIL = 'FETCH_ALL_INSTITUTIONS_FAIL'
export const FETCH_ALL_INSTITUTIONS_USERS_DATA =
  'FETCH_ALL_INSTITUTIONS_USERS_DATA'
export const FETCH_ALL_INSTITUTIONS_USERS_START =
  'FETCH_ALL_INSTITUTIONS_USERS_START'
export const FETCH_ALL_INSTITUTIONS_USERS_FAIL =
  'FETCH_ALL_INSTITUTIONS_USERS_FAIL'
export const ADD_INSTITUTIONS_USER_DATA = 'ADD_INSTITUTIONS_USER_DATA'
export const ADD_INSTITUTIONS_USER_START = 'ADD_INSTITUTIONS_USER_START'
export const ADD_INSTITUTIONS_USER_FAIL = 'ADD_INSTITUTIONS_USER_FAIL'

export const POST_INSTITUTION_FINANCIAL_DATA = 'POST_INSTITUTION_FINANCIAL_DATA'
export const POST_INSTITUTION_FINANCIAL_START =
  'POST_INSTITUTION_FINANCIAL_START'
export const POST_INSTITUTION_FINANCIAL_FAIL = 'POST_INSTITUTION_FINANCIAL_FAIL'
export const POST_BILLING_TRANSACTIONS_DATA = 'POST_BILLING_TRANSACTIONS_DATA'
export const POST_BILLING_TRANSACTIONS_START = 'POST_BILLING_TRANSACTIONS_START'
export const POST_BILLING_TRANSACTIONS_FAIL = 'POST_BILLING_TRANSACTIONS_FAIL'
export const CHANGE_ACADEMIC_YEAR_FOR_SESSION =
  'CHANGE_ACADEMIC_YEAR_FOR_SESSION'
export const GET_SUPPORT_INSTITUTION_START = 'GET_SUPPORT_INSTITUTION_START'
export const GET_SUPPORT_INSTITUTION_SUCCESS = 'GET_SUPPORT_INSTITUTION_SUCCESS'
export const GET_SUPPORT_INSTITUTION_FAIL = 'GET_SUPPORT_INSTITUTION_FAIL'
export const FETCH_ALL_SUPPORT = 'FETCH_ALL_SUPPORT'
export const FETCH_ALL_SUPPORT_START = 'FETCH_ALL_SUPPORT_START'
export const FETCH_ALL_SUPPORT_FAIL = 'FETCH_ALL_SUPPORT_FAIL'
export const DELETE_SUPPORT_USER_START = 'DELETE_SUPPORT_USER_START'
export const DELETE_SUPPORT_USER_SUCCESS = 'DELETE_SUPPORT_USER_SUCCESS'
export const DELETE_SUPPORT_USER_FAIL = 'DELETE_SUPPORT_USER_FAIL'
export const RESET_SUPPORT_START = 'RESET_SUPPORT_START'
export const RESET_SUPPORT_SUCCESS = 'RESET_SUPPORT_SUCCESS'
export const RESET_SUPPORT_FAIL = 'RESET_SUPPORT_FAIL'
export const GET_LOG_REPORT_START = 'GET_LOG_REPORT_START'
export const GET_LOG_REPORT_SUCCESS = 'GET_LOG_REPORT_SUCCESS'
export const GET_LOG_REPORT_FAIL = 'GET_LOG_REPORT_FAIL'

/* StudentGroup Actions */
export const GROUPS_ADD_GROUP = 'GROUPS_ADD_GROUP'
export const GROUPS_GROUP_DATA = 'GROUPS_GROUP_DATA'
export const UMBRELLA_GROUPS_NAME = 'UMBRELLA_GROUPS_NAME'
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS'
export const START_FETCH_GROUPS = 'START_FETCH_GROUPS'
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'UPDATE_FAILURE'
export const GROUPS_PATCH_START = 'GROUPS_PATCH_START'
export const UPDATE_GROUP_DATA = 'UPDATE_GROUP_DATA'
export const PATCH_TEACHER_PER_SUBJECT = 'PATCH_TEACHER_PER_SUBJECT'
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS'
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS'

/* Students */
export const STUDENTS_DATA = 'STUDENTS_DATA'
export const STUDENTS_PATCH_START = 'STUDENTS_PATCH_START'
export const STUDENTS_PATCH_SUCCESS = 'STUDENTS_PATCH_SUCCESS'
export const STUDENTS_PATCH_FAIL = 'STUDENTS_PATCH_FAIL'
export const FLATTENED_STUDENTS_DATA = 'FLATTENED_STUDENTS_DATA'
export const STUDENTS_GET_STARTED = 'STUDENTS_GET_STARTED'
export const FLATTENED_STUDENTS_GET_STARTED = 'FLATTENED_STUDENTS_GET_STARTED'
export const STUDENTS_GET_FINISHED = 'STUDENTS_GET_FINISHED'
export const SET_SEARCH_FILTER_FOR_STUDENT_LIST =
  'SET_SEARCH_FILTER_FOR_STUDENT_LIST'
export const SET_SEARCH_FILTER_SUBJECTS_FOR_STUDENT_LIST =
  'SET_SEARCH_FILTER_SUBJECTS_FOR_STUDENT_LIST'
export const CHECK_ADMISSION_NUMBER_UNIQUENESS =
  'CHECK_ADMISSION_NUMBER_UNIQUENESS'
export const RESET_STUDENT_STORE = 'RESET_STUDENT_STORE'
export const UPDATE_ARCHIVED_STUDENT = 'UPDATE_ARCHIVED_STUDENT'
export const UPDATE_UNARCHIVED_STUDENT = 'UPDATE_UNARCHIVED_STUDENT'
export const STUDENT_RESET_PASSWORD_START = 'STUDENT_RESET_PASSWORD_START'
export const STUDENT_RESET_PASSWORD_SUCCESS = 'STUDENT_RESET_PASSWORD_SUCCESS'
export const STUDENT_RESET_PASSWORD_FAIL = 'STUDENT_RESET_PASSWORD_FAIL'
export const STUDENTS_SEARCH_DATA = 'STUDENTS_SEARCH_DATA'
export const UPDATE_PATCH_PICTURE_DATA = 'UPDATE_PATCH_PICTURE_DATA'
export const FETCH_ALL_STUDENT_GROUPS_SUCCESS =
  'FETCH_ALL_STUDENT_GROUPS_SUCCESS'
export const ADD_STUDENT_USERS_START = 'ADD_STUDENT_USERS_START'
export const ADD_STUDENT_USERS = 'ADD_STUDENT_USERS'
export const ADD_STUDENT_USERS_FAIL = 'ADD_STUDENT_USERS_FAIL'
export const REMOVE_STUDENT_START = 'REMOVE_STUDENT_START'
export const REMOVE_STUDENT_SUCCESS = 'REMOVE_STUDENT_SUCCESS'
export const REMOVE_STUDENT_FAIL = 'REMOVE_STUDENT_FAIL'
export const STUDENT_UPLOAD_PROFILE_PIC_START =
  'STUDENT_UPLOAD_PROFILE_PIC_START'
export const STUDENT_UPLOAD_PROFILE_PIC = 'STUDENT_UPLOAD_PROFILE_PIC'
export const STUDENT_UPLOAD_PROFILE_PIC_FAIL = 'STUDENT_UPLOAD_PROFILE_PIC_FAIL'
export const GET_STUDENT_EMAIL_LOGS_START = 'GET_STUDENT_EMAIL_LOGS_START'
export const GET_STUDENT_EMAIL_LOGS = 'GET_STUDENT_EMAIL_LOGS'
export const GET_STUDENT_EMAIL_LOGS_FAIL = 'GET_STUDENT_EMAIL_LOGS_FAIL'

/* Attendance */
// fetching data for st-attendance reducer
export const ST_DAILY_ATTENDANCE_START = 'ST_DAILY_ATTENDANCE_START'
export const ST_DAILY_ATTENDANCE_DATA = 'ST_DAILY_ATTENDANCE_DATA'
export const ST_DAILY_ATTENDANCE_FAIL = 'ST_DAILY_ATTENDANCE_FAIL'
// post attendance for st-attendance
export const START_ATTENDANCE_POST = 'START_ATTENDANCE_POST'
export const POST_ATTENDANCE_SUCCESS = 'POST_ATTENDANCE_SUCCESS'
export const POST_ATTENDANCE_FAIL = 'POST_ATTENDANCE_FAIL'

export const ATTENDANCE_DATA = 'ATTENDANCE_DATA'
export const ATTENDANCE_STUDENT_DATA = 'ATTENDANCE_STUDENT_DATA'
export const ATTENDANCE_STUDENT_DATA_HOURLY = 'ATTENDANCE_STUDENT_DATA_HOURLY'
export const ATTENDANCE_GET_STARTED = 'ATTENDANCE_GET_STARTED'
export const ATTENDANCE_PER_GROUP = 'ATTENDANCE_PER_GROUP'
export const ATTENDANCE_GET_FINISHED = 'ATTENDANCE_GET_FINISHED'
export const ATTENDANCE_POST_START = 'ATTENDANCE_POST_START'
export const ATTENDANCE_POST_FAILURE = 'ATTENDANCE_POST_FAILURE'
export const ATTENDANCE_POST_SUCCESS = 'ATTENDANCE_POST_SUCCESS'
export const HOURLY_ATTENDANCE_DATA = 'HOURLY_ATTENDANCE_DATA'
export const HOURLY_ATTENDANCE_REPORT = 'HOURLY_ATTENDANCE_REPORT'
export const ATTENDANCE_DATA_FOR_CLASS_PROFILE =
  'ATTENDANCE_DATA_FOR_CLASS_PROFILE'
export const HOURLY_ATTENDANCE_DATA_FOR_CLASS_PROFILE =
  'HOURLY_ATTENDANCE_DATA_FOR_CLASS_PROFILE'
export const HOURLY_ATTENDANCE_PER_GROUP = 'HOURLY_ATTENDANCE_PER_GROUP'
// hourly bulk attendance month wise fetch
export const HOURLY_ATTENDANCE_FETCH_START = 'HOURLY_ATTENDANCE_FETCH_START'
export const HOURLY_ATTENDANCE_MONTH_WISE_FETCH =
  'HOURLY_ATTENDANCE_MONTH_WISE_FETCH'
export const HOURLY_ATTENDANCE_FETCH_FAIL = 'HOURLY_ATTENDANCE_FETCH_FAIL'
export const HOURLY_ATTENDANCE_REPORT_PER_GROUP =
  'HOURLY_ATTENDANCE_REPORT_PER_GROUP'
// bulk and marked hourly attendance data
export const MARKED_AND_BULK_DATA = 'MARKED_AND_BULK_DATA'
export const BULK_DATA = 'BULK_DATA'
export const MARKED_AND_BULK_DATA_FAIL = 'MARKED_AND_BULK_DATA_FAIL'
// daily bulk attendance month wise fetch
export const DAILY_ATTENDANCE_FETCH_START = 'DAILY_ATTENDANCE_FETCH_START'
export const DAILY_ATTENDANCE_MONTH_WISE_FETCH =
  'DAILY_ATTENDANCE_MONTH_WISE_FETCH'
export const DAILY_ATTENDANCE_FETCH_FAIL = 'DAILY_ATTENDANCE_FETCH_FAIL'

export const BULK_ATTENDANCE_POST_DATA = 'BULK_ATTENDANCE_POST_DATA'
export const SET_ATTENDANCE_STATUS = 'SET_ATTENDANCE_STATUS'
export const RESET_ATTENDANCE_STATUS = 'RESET_ATTENDANCE_STATUS'
export const RESET_BULK_ATTENDANCE_POST_OBJECT =
  'RESET_BULK_ATTENDANCE_POST_OBJECT'
export const SAVE_DATA_FOR_STUDENT_PROFILE = 'SAVE_DATA_FOR_STUDENT_PROFILE'
export const SAVE_DATA_FOR_UI_ATTENDANCE = 'SAVE_DATA_FOR_UI_ATTENDANCE'
export const SAVE_STUDENTS_FOR_ATTENDANCE = 'SAVE_STUDENTS_FOR_ATTENDANCE'
export const SAVE_YEAR_MONTH_FOR_ATTENDANCE = 'SAVE_YEAR_MONTH_FOR_ATTENDANCE'
export const SAVE_DATA_FOR_WEEKLY_ATTENDANCE = 'SAVE_DATA_FOR_WEEKLY_ATTENDANCE'
/* Attendance report */
export const FETCH_OVERALL_ATTENDANCE_START = 'FETCH_OVERALL_ATTENDANCE_START'
export const FETCH_OVERALL_ATTENDANCE_SUCCESS =
  'FETCH_OVERALL_ATTENDANCE_SUCCESS'
export const FETCH_OVERALL_ATTENDANCE_FAIL = 'FETCH_OVERALL_ATTENDANCE_FAIL'

export const FETCH_MONTHLY_ATTENDANCE_START = 'FETCH_MONTHLY_ATTENDANCE_START'
export const FETCH_MONTHLY_ATTENDANCE_SUCCESS =
  'FETCH_MONTHLY_ATTENDANCE_SUCCESS'
export const FETCH_MONTHLY_ATTENDANCE_FAIL = 'FETCH_MONTHLY_ATTENDANCE_FAIL'
export const SAVE_CLASS_AND_SECTION_FOR_TIMETABLE =
  'SAVE_CLASS_AND_SECTION_FOR_TIMETABLE'
export const SAVE_START_AND_END_DATE = 'SAVE_START_AND_END_DATE'
export const SAVE_DATA = 'SAVE_DATA'
export const SAVE_GROUPSELECTOR_DATA_FOR_MARKS_ENTRY =
  'SAVE_GROUPSELECTOR_DATA_FOR_MARKS_ENTRY'
export const SET_IN_PROGRESS = 'SET_IN_PROGRESS'
export const GET_ALL_STUDENTS_ATTENDANCE_START =
  'GET_ALL_STUDENTS_ATTENDANCE_START'
export const GET_ALL_STUDENTS_ATTENDANCE_SUCCESS =
  'GET_ALL_STUDENTS_ATTENDANCE_SUCCESS'
export const GET_ALL_STUDENTS_ATTENDANCE_FAIL =
  'GET_ALL_STUDENTS_ATTENDANCE_FAIL'
export const GET_ATTENDANCE_REMARKS_START = 'GET_ATTENDANCE_REMARKS_START'
export const GET_ATTENDANCE_REMARKS_SUCCESS = 'GET_ATTENDANCE_REMARKS_SUCCESS'
export const GET_ATTENDANCE_REMARKS_FAIL = 'GET_ATTENDANCE_REMARKS_FAIL'
export const DELETE_ATTENDANCE_START = 'DELETE_ATTENDANCE_START'
export const DELETE_ATTENDANCE_SUCCESS = 'DELETE_ATTENDANCE_SUCCESS'
export const DELETE_ATTENDANCE_FAIL = 'DELETE_ATTENDANCE_FAIL'

// marks report ui
export const SAVE_DATA_TO_MARKS_REPORT = 'SAVE_DATA_TO_MARKS_REPORT'

/* Tests */
export const FLATTENED_TESTS_TEST_DATA = 'FLATTENED_TESTS_TEST_DATA'
export const TESTS_TEST_DATA = 'TESTS_TEST_DATA'
export const TESTS_FETCH_START = 'TESTS_FETCH_START'
export const TESTS_FAIL = 'TESTS_FAIL'
export const ADD_TEST_START = 'ADD_TEST_START'
export const ADD_TEST_SUCCESS = 'ADD_TEST_SUCCESS'
export const EDIT_TEST_SUCCESS = 'EDIT_TEST_SUCCESS'
export const EDIT_TEST_FAIL = 'EDIT_TEST_FAIL'
export const ADD_GRADES_TO_TEST_START = 'ADD_GRADES_TO_TEST_START'
export const ADD_GRADES_TO_TEST = 'ADD_GRADES_TO_TEST'
export const ADD_GRADES_TO_TEST_FAIL = 'ADD_GRADES_TO_TEST_FAIL'
export const FETCH_SUB_TEST_START = 'FETCH_SUB_TEST_START'
export const SUB_TEST_DATA = 'SUB_TEST_DATA'
export const FLATTENED_SUB_TEST_DATA = 'FLATTENED_SUB_TEST_DATA'
export const EDIT_SUB_TEST_START = 'EDIT_SUB_TEST_START'
export const EDIT_SUB_TEST_SUCCESS = 'EDIT_SUB_TEST_SUCCESS'
export const ADD_MIN_MARKS_GRADES_FAIL = 'ADD_MIN_MARKS_GRADES_FAIL'
export const ADD_MIN_MARKS_GRADES_START = 'ADD_MIN_MARKS_GRADES_START'
export const ADD_MIN_MARKS_GRADES_SUCCESS = 'ADD_MIN_MARKS_GRADES_SUCCESS'

/* Marks */
export const MARKS_FETCH_START = 'MARKS_FETCH_START'
export const MARKS_FETCH_FAIL = 'MARKS_FETCH_FAIL'
export const MARKS_FETCH_DATA = 'MARKS_FETCH_DATA'
export const FETCH_TESTS_SUBJECTS_DATA = 'FETCH_TESTS_SUBJECTS_DATA'
export const FETCH_MARKS_FOR_TEST_SUBJ = 'FETCH_MARKS_FOR_TEST_SUBJ'

/* Grades */
export const START_FETCH_GRADES = 'START_FETCH_GRADES'
export const EMIT_GRADES_DATA = 'EMIT_GRADES_DATA'
export const EMIT_GRADES_FAIL = 'EMIT_GRADES_FAIL'

// create student
export const ADD_STUDENT_START = 'ADD_STUDENT_START'
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS'
export const ADD_STUDENT_FAIL = 'ADD_STUDENT_FAIL'
export const SAVE_DATA_FOR_MARKS_ENTRY = 'SAVE_DATA_FOR_MARKS_ENTRY'
export const ADD_STUDENT_TO_GROUP = 'ADD_STUDENT_TO_GROUP'
export const ADD_NEW_STUDENT = 'ADD_NEW_STUDENT'
export const EDIT_STUDENT = 'EDIT_STUDENT'
export const SET_ARCHIVE_FILTER_FOR_STUDENT_LIST =
  'SET_ARCHIVE_FILTER_FOR_STUDENT_LIST'

export const UPDATE_EXISTING_MARKS_FOR_FORM = 'UPDATE_EXISTING_MARKS_FOR_FORM'
/* ui */
export const SAVE_GROUP_FOR_CLASS_PROFILE_ATTENDANCE =
  'SAVE_GROUP_FOR_CLASS_PROFILE_ATTENDANCE'
export const SAVE_CLASS_SECTION_TEST_AND_SUBJECT =
  'SAVE_CLASS_SECTION_TEST_AND_SUBJECT'
export const SAVE_GROUP_AND_TEST_FOR_DASHBOARD =
  'SAVE_GROUP_AND_TEST_FOR_DASHBOARD'
export const SAVE_PARENT_FOR_CLASS_PROFILE = 'SAVE_PARENT_FOR_CLASS_PROFILE'
export const SAVE_CLASS_AND_SECTION_FOR_ATTENDANCE =
  'SAVE_CLASS_AND_SECTION_FOR_ATTENDANCE'
export const SAVE_GROUPSELECTOR_DATA_FOR_ATTENDANCE =
  'SAVE_GROUPSELECTOR_DATA_FOR_ATTENDANCE'
export const SAVE_DETAILS_FOR_TEST_EDIT = 'SAVE_DETAILS_FOR_TEST_EDIT'
export const SAVE_DETAILS_FOR_SUBJECT_EDIT = 'SAVE_DETAILS_FOR_SUBJECT_EDIT'
export const SAVE_DETAILS_FOR_GRADE_EDIT = 'SAVE_DETAILS_FOR_GRADE_EDIT'

/* ui marks */
export const SAVE_SELECTOR_DATA = 'SAVE_SELECTOR_DATA'

/* Subjects */
export const SUBJECTS_NEW_START = 'SUBJECTS_NEW_START'
export const SUBJECTS_NEW_FAIL = 'SUBJECTS_NEW_FAIL'
export const SUBJECTS_NEW_SUCCESS = 'SUBJECTS_NEW_SUCCESS'

/* Marks Post */
export const MARKS_POST_IN_PROCESS = 'MARKS_POST_IN_PROCESS'
export const MARKS_POST_SUCCESS = 'MARKS_POST_SUCCESS'
export const MARKS_POST_FAIL = 'MARKS_POST_FAIL'
export const CLASS_MARKS_FETCH_DATA = 'CLASS_MARKS_FETCH_DATA'
export const MARKS_POST_BULK_SUCCESS = 'MARKS_POST_BULK_SUCCESS'
export const MARKS_POST_BULK_FAIL = 'MARKS_POST_BULK_FAIL'
export const MARKS_POST_BULK_START = 'MARKS_POST_BULK_START'

/* Marks Report */
export const RESET_MARKS_STATUS_OBJECT = 'RESET_MARKS_STATUS_OBJECT'
export const MARKS_REPORT_DATA = 'MARKS_REPORT_DATA'

/* Generate Rank */
export const GENERATE_RANK_START = 'GENERATE_RANK_START'
export const GENERATE_RANK_SUCCESS = 'GENERATE_RANK_SUCCESS'
export const GENERATE_RANK_FAIL = 'GENERATE_RANK_FAIL'

/* Marks calculation */
export const MARKS_CALCULATION_START = 'MARKS_CALCULATION_START'
export const MARKS_CALCULATION_SUCCESS = 'MARKS_CALCULATION_SUCCESS'
export const MARKS_CALCULATION_FAIL = 'MARKS_CALCULATION_FAIL'

/* Subjects */
export const SUBJECTS_FETCH_START = 'SUBJECTS_FETCH_START'
export const SUBJECTS_SUBJECT_DATA = 'SUBJECTS_SUBJECT_DATA'
export const SUBJECTS_FETCH_FAIL = 'SUBJECTS_FETCH_FAIL'
export const ELECTIVES_FETCH_START = 'ELECTIVES_FETCH_START'
export const ELECTIVES_SUBJECT_DATA = 'ELECTIVES_SUBJECT_DATA'
export const ELECTIVES_FETCH_FAIL = 'ELECTIVES_FETCH_FAIL'
export const ADD_ELECTIVE_SUCCESS = 'ADD_ELECTIVE_SUCCESS'
export const ADD_ELECTIVE_FAIL = 'ADD_ELECTIVE_FAIL'
export const EDIT_SUBJECT_SUCCESS = 'EDIT_SUBJECT_SUCCESS'
export const EDIT_SUBJECT_FAIL = 'EDIT_SUBJECT_FAIL'
export const EDIT_SUBJECT_START = 'EDIT_SUBJECT_START'
export const ADD_SUBJECT_SUCCESS = 'ADD_SUBJECT_SUCCESS'

/* Sms */
export const SMS_FETCH_START = 'SMS_FETCH_START'
export const FETCH_SMS_BY_STATUS = 'FETCH_SMS_FOR_SCHOOL_BY_STATUS'
export const POST_SMS_DATA = 'POST_SMS_DATA'
export const SMS_POST_FAILURE = 'SMS_POST_FAILURE'
export const SET_POST_STATUS = 'SET_POST_STATUS'
export const SET_POST_MESSAGE = 'SET_POST_MESSAGE'
export const IMPORTANT_SMS_FETCH_START = 'IMPORTANT_SMS_FETCH_START'
export const IMPORTANT_SMS_FETCH_FAIL = 'IMPORTANT_SMS_FETCH_FAIL'
export const IMPORTANT_SMS_DATA = 'IMPORTANT_SMS_DATA'
export const GET_SMS_COUNT_START = 'GET_SMS_COUNT_START'
export const GET_SMS_COUNT_FAILURE = 'GET_SMS_COUNT_FAILURE'
export const GET_SMS_COUNT_DATA = 'GET_SMS_COUNT_DATA'
export const GET_SMS_PER_STUDENT_START = 'GET_SMS_PER_STUDENT_START'
export const GET_SMS_PER_STUDENT_SUCCESS = 'GET_SMS_PER_STUDENT_SUCCESS'
export const GET_SMS_PER_STUDENT_FAIL = 'GET_SMS_PER_STUDENT_FAIL'

/* Teacher and Department */
export const DEPARTMENTS_DATA = 'DEPARTMENTS_DATA'
export const TEACHERS_DATA = 'TEACHER_DATA'
export const TEACHERS_FETCH_START = 'TEACHERS_FETCH_START'
export const TEACHERS_FETCH_FAIL = 'TEACHERS_FETCH_FAIL'
export const UPDATE_TEACHER_STORE = 'UPDATE_TEACHER_STORE'
export const DEPARTMENTS_FETCH_START = 'DEPARTMENTS_FETCH_START'
export const ARCHIVE_TEACHER_START = 'ARCHIVE_TEACHER_START'
export const ARCHIVE_TEACHER_SUCCESS = 'ARCHIVE_TEACHER_SUCCESS'
export const ARCHIVE_TEACHER_FAIL = 'ARCHIVE_TEACHER_FAIL'
export const UNARCHIVE_TEACHER_START = 'UNARCHIVE_TEACHER_START'
export const UNARCHIVE_TEACHER_SUCCESS = 'UNARCHIVE_TEACHER_SUCCESS'
export const UNARCHIVE_TEACHER_FAIL = 'UNARCHIVE_TEACHER_FAIL'
export const TEACHER_RESET_PASSWORD_START = 'TEACHER_RESET_PASSWORD_START'
export const TEACHER_RESET_PASSWORD_SUCCESS = 'TEACHER_RESET_PASSWORD_SUCCESS'
export const TEACHER_RESET_PASSWORD_FAILURE = 'TEACHER_RESET_PASSWORD_FAILURE'
export const ADD_TEACHER_USERS_START = 'ADD_TEACHER_USERS_START'
export const ADD_TEACHER_USERS = 'ADD_TEACHER_USERS'
export const ADD_TEACHER_USERS_FAIL = 'ADD_TEACHER_USERS_FAIL'
export const ADD_NEW_TEACHER_START = 'ADD_NEW_TEACHER_START'
export const ADD_NEW_TEACHER_FAIL = 'ADD_NEW_TEACHER_FAIL'
export const ADD_NEW_TEACHER_SUCCESS = 'ADD_NEW_TEACHER_SUCCESS'
export const EDIT_TEACHER_START = 'EDIT_TEACHER_START'
export const EDIT_TEACHER_SUCCESS = 'EDIT_TEACHER_SUCCESS'
export const EDIT_TEACHER_FAIL = 'EDIT_TEACHER_FAIL'
export const TEACHER_SEARCH_DATA = 'TEACHER_SEARCH_DATA'
export const CLEAR_TEACHER_DATA = 'CLEAR_TEACHER_DATA'

/* Teacher Attendance */
export const POST_TEACHERS_ATTENDANCE_START = 'POST_TEACHERS_ATTENDANCE_START'
export const POST_TEACHERS_ATTENDANCE_FAIL = 'POST_TEACHERS_ATTENDANCE_FAIL'
export const POST_TEACHERS_ATTENDANCE_SUCCESS =
  'POST_TEACHERS_ATTENDANCE_SUCCESS'
export const GET_TEACHERS_ATTENDANCE_START = 'GET_TEACHERS_ATTENDANCE_START'
export const GET_TEACHERS_ATTENDANCE_FAIL = 'GET_TEACHERS_ATTENDANCE_FAIL'
export const GET_TEACHERS_ATTENDANCE_SUCCESS = 'GET_TEACHERS_ATTENDANCE_SUCCESS'
export const GET_ATTENDANCE_FOR_TEACHER_START =
  'GET_ATTENDANCE_FOR_TEACHER_START'
export const GET_ATTENDANCE_FOR_TEACHER_SUCCESS =
  'GET_ATTENDANCE_FOR_TEACHER_SUCCESS'
export const GET_ATTENDANCE_FOR_TEACHER_FAIL = 'GET_ATTENDANCE_FOR_TEACHER_FAIL'
export const GET_MONTHLY_TEACHERS_ATTENDANCE_START =
  'GET_MONTHLY_TEACHERS_ATTENDANCE_START'
export const GET_MONTHLY_TEACHERS_ATTENDANCE_SUCCESS =
  'GET_MONTHLY_TEACHERS_ATTENDANCE_SUCCESS'
export const GET_MONTHLY_TEACHERS_ATTENDANCE_FAIL =
  'GET_MONTHLY_TEACHERS_ATTENDANCE_FAIL'

/*Payslip */
export const POST_GENERATE_PAYSLIP_START = 'POST_GENERATE_PAYSLIP_START'
export const POST_GENERATE_PAYSLIP_FAIL = 'POST_GENERATE_PAYSLIP_FAIL'
export const POST_GENERATE_PAYSLIP_SUCCESS = 'POST_GENERATE_PAYSLIP_SUCCESS'
export const GET_DOWNLOAD_PAYSLIP_START = 'GET_DOWNLOAD_PAYSLIP_START'
export const GET_DOWNLOAD_PAYSLIP_FAIL = 'GET_DOWNLOAD_PAYSLIP_FAIL'
export const GET_DOWNLOAD_PAYSLIP_SUCCESS = 'GET_DOWNLOAD_PAYSLIP_SUCCESS'

/*Teacher Leave Application */
export const POST_TEACHER_LEAVE_START = 'POST_TEACHER_LEAVE_START'
export const POST_TEACHER_LEAVE_FAIL = 'POST_TEACHER_LEAVE_FAIL'
export const POST_TEACHER_LEAVE_SUCCESS = 'POST_TEACHER_LEAVE_SUCCESS'
export const PATCH_TEACHER_LEAVE_START = 'PATCH_TEACHER_LEAVE_START'
export const PATCH_TEACHER_LEAVE_FAIL = 'PATCH_TEACHER_LEAVE_FAIL'
export const PATCH_TEACHER_LEAVE_SUCCESS = 'PATCH_TEACHER_LEAVE_SUCCESS'
export const GET_TEACHER_LEAVE_START_BY_STATUS =
  'GET_TEACHER_LEAVE_START_BY_STATUS'
export const GET_TEACHER_LEAVE_FAIL_BY_STATUS =
  'GET_TEACHER_LEAVE_FAIL_BY_STATUS'
export const GET_TEACHER_LEAVE_SUCCESS_BY_STATUS =
  'GET_TEACHER_LEAVE_SUCCESS_BY_STATUS'
export const DELETE_TEACHER_LEAVE_START = 'DELETE_TEACHER_LEAVE_START'
export const DELETE_TEACHER_LEAVE_FAIL = 'DELETE_TEACHER_LEAVE_FAIL'
export const DELETE_TEACHER_LEAVE_SUCCESS = 'DELETE_TEACHER_LEAVE_SUCCESS'
export const GET_TEACHER_LEAVE_COUNT_START = 'GET_TEACHER_LEAVE_COUNT_START'
export const GET_TEACHER_LEAVE_COUNT_FAIL = 'GET_TEACHER_LEAVE_COUNT_FAIL'
export const GET_TEACHER_LEAVE_COUNT_SUCCESS = 'GET_TEACHER_LEAVE_COUNT_SUCCESS'
export const GET_TEACHER_LEAVE_START = 'GET_TEACHER_LEAVE_START'
export const GET_TEACHER_LEAVE_FAIL = 'GET_TEACHER_LEAVE_FAIL'
export const GET_TEACHER_LEAVE_SUCCESS = 'GET_TEACHER_LEAVE_SUCCESS'
export const GET_TEACHER_UNPAID_LEAVE_START = 'GET_TEACHER_UNPAID_LEAVE_START'
export const GET_TEACHER_UNPAID_LEAVE_FAIL = 'GET_TEACHER_UNPAID_LEAVE_FAIL'
export const GET_TEACHER_UNPAID_LEAVE_SUCCESS =
  'GET_TEACHER_UNPAID_LEAVE_SUCCESS'

/* Teacher Leave Approval */
export const GET_TEACHER_LEAVE_STATUS_START = 'GET_TEACHER_LEAVE_STATUS_START'
export const GET_TEACHER_LEAVE_STATUS_FAIL = 'GET_TEACHER_LEAVE_STATUS_FAIL'
export const GET_TEACHER_LEAVE_STATUS_SUCCESS =
  'GET_TEACHER_LEAVE_STATUS_SUCCESS'
export const PATCH_TEACHER_LEAVE_APPROVAL_START =
  'PATCH_TEACHER_LEAVE_APPROVAL_START'
export const PATCH_TEACHER_LEAVE_APPROVAL_FAIL =
  'PATCH_TEACHER_LEAVE_APPROVAL_FAIL'
export const PATCH_TEACHER_LEAVE_APPROVAL_SUCCESS =
  'PATCH_TEACHER_LEAVE_APPROVAL_SUCCESS'

/* Teacher Leave Policy */
export const TEACHERS_LEAVE_POLICY_START = 'TEACHERS_LEAVE_POLICY_START'
export const TEACHERS_LEAVE_POLICY_FAIL = 'TEACHERS_LEAVE_POLICY_FAIL'
export const TEACHERS_LEAVE_POLICY_SUCCESS = 'TEACHERS_LEAVE_POLICY_SUCCESS'
export const LEAVE_POLICY_BY_POLICY_ID_START = 'LEAVE_POLICY_BY_POLICY_ID_START'
export const LEAVE_POLICY_BY_POLICY_ID_SUCCESS =
  'LEAVE_POLICY_BY_POLICY_ID_SUCCESS'
export const LEAVE_POLICY_BY_POLICY_ID_FAIL = 'LEAVE_POLICY_BY_POLICY_ID_FAIL'
export const LEAVE_POLICY_FOR_TEACHER_START = 'LEAVE_POLICY_FOR_TEACHER_START'
export const LEAVE_POLICY_FOR_TEACHER_FAIL = 'LEAVE_POLICY_FOR_TEACHER_FAIL'
export const LEAVE_POLICY_FOR_TEACHER_SUCCESS =
  'LEAVE_POLICY_FOR_TEACHER_SUCCESS'
export const ASSIGN_LEAVE_POLICY_FOR_TEACHER_START =
  'ASSIGN_LEAVE_POLICY_FOR_TEACHER_START'
export const ASSIGN_LEAVE_POLICY_FOR_TEACHER_FAIL =
  'ASSIGN_LEAVE_POLICY_FOR_TEACHER_FAIL'
export const ASSIGN_LEAVE_POLICY_FOR_TEACHER_SUCCESS =
  'ASSIGN_LEAVE_POLICY_FOR_TEACHER_SUCCESS'

/* Flattened Teachers */
export const FLATTENED_TEACHERS_DATA = 'FLATTENED_TEACHERS_DATA'
export const FLATTENED_ARCHIVE_TEACHERS_GET_STARTED =
  'FLATTENED_ARCHIVE_TEACHERS_GET_STARTED'
export const FLATTENED_ARCHIVE_TEACHERS_GET_FAIL =
  'FLATTENED_ARCHIVE_TEACHERS_GET_FAIL'
export const FLATTENED_ARCHIVE_TEACHERS_DATA = 'FLATTENED_ARCHIVE_TEACHERS_DATA'
export const FLATTENED_TEACHERS_GET_STARTED = 'FLATTENED_TEACHERS_GET_STARTED'
export const SET_SEARCH_FILTER_FOR_TEACHER_LIST =
  'SET_SEARCH_FILTER_FOR_TEACHER_LIST'
export const FLATTENED_TEACHERS_FETCH_FAIL = 'FLATTENED_TEACHERS_FETCH_FAIL'

/* Hours */
export const HOURS_FETCH_START = 'HOURS_FETCH_START'
export const HOURS_FETCH_FAIL = 'HOURS_FETCH_FAIL'
export const HOURS_DATA = 'HOURS_DATA'
export const HOUR_POST_START = 'HOUR_POST_START'
export const HOUR_POST_SUCCESS = 'HOUR_POST_SUCCESS'
export const HOUR_POST_FAILURE = 'HOUR_POST_FAILURE'
export const HOUR_PATCH_SUCCESS = 'HOUR_PATCH_SUCCESS'
export const RESET_HOUR_ADDED = 'RESET_HOUR_ADDED'
export const DELETE_HOUR_START = 'DELETE_HOUR_START'
export const DELETE_HOUR_SUCCESS = 'DELETE_HOUR_SUCCESS'
export const DELETE_HOUR_FAIL = 'DELETE_HOUR_FAIL'

/* TimeTable */
export const TIMETABLE_FETCH_START = 'TIMETABLE_FETCH_START'
export const TIMETABLE_FETCH_FAIL = 'TIMETABLE_FETCH_FAIL'
export const TIMETABLE_DATA = 'TIMETABLE_DATA'
export const TIMETABLE_POST_SUCCESS = 'TIMETABLE_POST'
export const TIMETABLE_POST_FAILURE = 'TIMETABLE_POST_FAILURE'

/* UI EditGroup */
export const SAVE_CLASS_AND_SECTION_FOR_EDITGROUP =
  'SAVE_CLASS_AND_SECTION_FOR_EDITGROUP'

/* UI Student Profile */
export const SET_ACTIVE_STUDENT = 'SET_ACTIVE_STUDENT'
export const CLEAR_ACTIVE_STUDENT = 'CLEAR_ACTIVE_STUDENT'

/*  Add students to electives */
export const ADD_STUDENTS_TO_ELECTIVES_SUCCESS =
  'ADD_STUDENTS_TO_ELECTIVES_SUCCESS'

// common add/remove students from electives actions
export const START_ADD_OR_REMOVE_STUDENTS = 'START_ADD_OR_REMOVE_STUDENTS'
export const ADD_OR_REMOVE_STUDENTS_FAIL = 'ADD_OR_REMOVE_STUDENTS_FAIL'

/* Remove students form electives */
export const REMOVE_STUDENTS_FROM_ELECTIVES_SUCCESS =
  'REMOVE_STUDENTS_FROM_ELECTIVES_SUCCESS'
/* AdmissionGroup actions */
export const START_FETCH_ADMISSION_GROUPS = 'START_FETCH_ADMISSION_GROUPS'
export const ADMISSION_GROUPS_DATA = 'ADMISSION_GROUPS_DATA'
export const UPDATE_ADMISSION_GROUP = 'UPDATE_ADMISSION_GROUP'
export const FETCH_ADMISSION_GROUPS_FAIL = 'FETCH_ADMISSION_GROUPS_FAIL'

/* Application */
export const START_POST_APPLICATION = 'START_POST_APPLICATION'
export const START_ENQUIRY_LIST = 'START_ENQUIRY_LIST'
export const FAILED_ENQUIRY_LIST = 'FAILED_ENQUIRY_LIST'
export const ENQUIRY_LIST = 'ENQUIRY_LIST'
export const APPLICATION_POST_FAILURE = 'APPLICATION_POST_FAILURE'
export const APPLICATION_POST_SUCCESS = 'APPLICATION_POST_SUCCESS'
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION'

/* UI Application */
export const SET_ACTIVE_APPLICATION = 'SET_ACTIVE_APPLICATION'
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER'

/* Comments */
export const COMMENTS_DATA = 'COMMENTS_DATA'
export const ADD_COMMENT = 'ADD_COMMENT'
export const START_COMMENTS_DATA = 'START_COMMENTS_DATA'

/* Appointment */
export const APPOINTMENT_DATA = 'APPOINTMENT_DATA'
export const START_POST_APPOINTMENT = 'START_POST_APPOINTMENT'
export const APPOINTMENT_POST_FAILURE = 'APPOINTMENT_POST_FAILURE'
export const APPOINTMENT_POST_SUCCESS = 'APPOINTMENT_POST_SUCCESS'

/* Instituion Fees */
export const INSTITUION_FEES_DATA = 'INSTITUION_FEES_DATA'
export const FEES_RECEIPT_REPORT_START = 'FEES_RECEIPT_REPORT_START'
export const FEES_RECEIPT_REPORT = 'FEES_RECEIPT_REPORT'
export const FEES_RECEIPT_REPORT_FAIL = 'FEES_RECEIPT_REPORT_FAIL'
export const APP_GET_FEE_ORDER_START = 'APP_GET_FEE_ORDER_START'
export const APP_GET_FEE_ORDER = 'APP_GET_FEE_ORDER'
export const APP_GET_FEE_ORDER_FAIL = 'APP_GET_FEE_ORDER_FAIL'
export const APP_GET_ALL_CLASS_FEE_REPORT_START =
  'APP_GET_ALL_CLASS_FEE_REPORT_START'
export const APP_GET_ALL_CLASS_FEE_REPORT = 'APP_GET_ALL_CLASS_FEE_REPORT'
export const APP_GET_ALL_CLASS_FEE_REPORT_FAIL =
  'APP_GET_ALL_CLASS_FEE_REPORT_FAIL'
export const APP_POST_FEE_CONCESSION_START = 'APP_POST_FEE_CONCESSION_START'
export const APP_POST_FEE_CONCESSION = 'APP_POST_FEE_CONCESSION'
export const APP_POST_FEE_CONCESSION_FAIL = 'APP_POST_FEE_CONCESSION_FAIL'
export const FEE_RECEIPT_DATA_DELETE = 'FEE_RECEIPT_DATA_DELETE'
export const START_DELETE_STUDENT_FEES = 'START_DELETE_STUDENT_FEES'
export const POST_FEE_STRUCTURE_START = 'POST_FEE_STRUCTURE_START'
export const POST_FEE_STRUCTURE = 'POST_FEE_STRUCTURE'
export const POST_FEE_STRUCTURE_FAIL = 'POST_FEE_STRUCTURE_FAIL'
export const POST_FEE_RECEIPT_NUMBER_START = 'POST_FEE_RECEIPT_NUMBER_START'
export const POST_FEE_RECEIPT_NUMBER = 'POST_FEE_RECEIPT_NUMBER'
export const POST_FEE_RECEIPT_NUMBER_FAIL = 'POST_FEE_RECEIPT_NUMBER_FAIL'
export const GET_FEE_CONCESSION_BY_STUDENT_ID_START =
  'GET_FEE_CONCESSION_BY_STUDENT_ID_START'
export const GET_FEE_CONCESSION_BY_STUDENT_ID =
  'GET_FEE_CONCESSION_BY_STUDENT_ID'
export const GET_FEE_CONCESSION_BY_STUDENT_ID_FAIL =
  'GET_FEE_CONCESSION_BY_STUDENT_ID_FAIL'
export const GET_WAIVE_STUDENT_FEES_FAIL = 'GET_WAIVE_STUDENT_FEES_FAIL'
export const GET_WAIVE_STUDENT_FEES_START = 'GET_WAIVE_STUDENT_FEES_START'
export const GET_WAIVE_STUDENT_FEES = 'GET_WAIVE_STUDENT_FEES'
export const PATCH_FEE_STRUCTURE_START = 'PATCH_FEE_STRUCTURE_START'
export const PATCH_FEE_STRUCTURE = 'PATCH_FEE_STRUCTURE'
export const PATCH_FEE_STRUCTURE_FAIL = 'PATCH_FEE_STRUCTURE_FAIL'
export const GET_FEE_ARREARS_START = 'GET_FEE_ARREARS_START'
export const GET_FEE_ARREARS = 'GET_FEE_ARREARS'
export const GET_FEE_ARREARS_FAIL = 'GET_FEE_ARREARS_FAIL'
export const POST_TRANSPORT_FEE_START = 'POST_TRANSPORT_FEE_START'
export const POST_TRANSPORT_FEE_SUCCESS = 'POST_TRANSPORT_FEE_SUCCESS'
export const POST_TRANSPORT_FEE_FAIL = 'POST_TRANSPORT_FEE_FAIL'
export const GET_STUDENT_TRANSPORT_FEE_START = 'GET_STUDENT_TRANSPORT_FEE_START'
export const GET_STUDENT_TRANSPORT_FEE_SUCCESS =
  'GET_STUDENT_TRANSPORT_FEE_SUCCESS'
export const GET_STUDENT_TRANSPORT_FEE_FAIL = 'GET_STUDENT_TRANSPORT_FEE_FAIL'
export const PATCH_TRANSPORT_FEE_START = 'PATCH_TRANSPORT_FEE_START'
export const PATCH_TRANSPORT_FEE_SUCCESS = 'PATCH_TRANSPORT_FEE_SUCCESS'
export const PATCH_TRANSPORT_FEE_FAIL = 'PATCH_TRANSPORT_FEE_FAIL'
export const GET_FEES_CLASS_WISE_START = 'GET_FEES_CLASS_WISE_START'
export const GET_FEES_CLASS_WISE_SUCCESS = 'GET_FEES_CLASS_WISE_SUCCESS'
export const GET_FEES_CLASS_WISE_FAIL = 'GET_FEES_CLASS_WISE_FAIL'
export const GET_DELETED_RECEIPTS_START = 'GET_DELETED_RECEIPTS_START'
export const GET_DELETED_RECEIPTS_SUCCESS = 'GET_DELETED_RECEIPTS_SUCCESS'
export const GET_DELETED_RECEIPTS_FAIL = 'GET_DELETED_RECEIPTS_FAIL'
export const DELETE_STUDENT_FEES_START = 'DELETE_STUDENT_FEES_START'
export const DELETE_STUDENT_FEES_SUCCESS = 'DELETE_STUDENT_FEES_SUCCESS'
export const DELETE_STUDENT_FEES_FAIL = 'DELETE_STUDENT_FEES_FAIL'
export const POST_STUDENT_FINE_FEES_START = 'POST_STUDENT_FINE_FEES_START'
export const POST_STUDENT_FINE_FEES_SUCCESS = 'POST_STUDENT_FINE_FEES_SUCCESS'
export const POST_STUDENT_FINE_FEES_FAIL = 'POST_STUDENT_FINE_FEES_FAIL'
export const RESET_PROCESSING_FEE_START = 'RESET_PROCESSING_FEE_START'
export const RESET_PROCESSING_FEE_SUCCESS = 'RESET_PROCESSING_FEE_SUCCESS'
export const RESET_PROCESSING_FEE_FAIL = 'RESET_PROCESSING_FEE_FAIL'
export const PATCH_FEE_DONTPAYONLINE_START = 'PATCH_FEE_DONTPAYONLINE_START'
export const PATCH_FEE_DONTPAYONLINE_SUCCESS = 'PATCH_FEE_DONTPAYONLINE_SUCCESS'
export const PATCH_FEE_DONTPAYONLINE_FAIL = 'PATCH_FEE_DONTPAYONLINE_FAIL'
export const ADD_FEE_AND_GENERATE_RECEIPT_START =
  'ADD_FEE_AND_GENERATE_RECEIPT_START'
export const ADD_FEE_AND_GENERATE_RECEIPT_FAIL =
  'ADD_FEE_AND_GENERATE_RECEIPT_FAIL'
export const GET_STUDENT_WAIVE_START = 'GET_STUDENT_WAIVE_START'
export const GET_STUDENT_WAIVE_SUCCESS = 'GET_STUDENT_WAIVE_SUCCESS'
export const GET_STUDENT_WAIVE_FAIL = 'GET_STUDENT_WAIVE_FAIL'
export const UPDATE_BULK_FEE_RECEIPT_START = 'UPDATE_BULK_FEE_RECEIPT_START'
export const UPDATE_BULK_FEE_RECEIPT_SUCCESS = 'UPDATE_BULK_FEE_RECEIPT_SUCCESS'
export const UPDATE_BULK_FEE_RECEIPT_FAIL = 'UPDATE_BULK_FEE_RECEIPT_FAIL'
export const CHANGE_CONCESSION_STATUS_START = 'CHANGE_CONCESSION_STATUS_START'
export const CHANGE_CONCESSION_STATUS_SUCCESS =
  'CHANGE_CONCESSION_STATUS_SUCCESS'
export const CHANGE_CONCESSION_STATUS_FAIL = 'CHANGE_CONCESSION_STATUS_FAIL'
export const GET_FEE_RECEIPT_NUMBER_TYPE_START =
  'GET_FEE_RECEIPT_NUMBER_TYPE_START'
export const GET_FEE_RECEIPT_NUMBER_TYPE_SUCCESS =
  'GET_FEE_RECEIPT_NUMBER_TYPE_SUCCESS'
export const GET_FEE_RECEIPT_NUMBER_TYPE_FAIL =
  'GET_FEE_RECEIPT_NUMBER_TYPE_FAIL'

/* Student Fees */
export const STUDENT_FEES_DATA = 'STUDENT_FEES_DATA'
export const START_POST_STUDENT_FEES = 'START_POST_STUDENT_FEES'
export const STUDENT_FEES_POST_FAILURE = 'STUDENT_FEES_POST_FAILURE'
export const UPDATE_STUDENTFEE_DATA = 'UPDATE_STUDENTFEE_DATA'
export const FEE_RECEIPT_DATA = 'FEE_RECEIPT_DATA'
export const START_FETCH_STUDENT_FEE_RECEIPT = 'START_FETCH_STUDENT_FEE_RECEIPT'
export const FEE_RECEIPT_ARRAY = 'FEE_RECEIPT_ARRAY'
export const FEE_RECEIPT_FETCH_FAIL = 'FEE_RECEIPT_FETCH_FAIL'
export const APP_POST_ORDER_START = 'APP_POST_ORDER_START'
export const APP_POST_ORDER = 'APP_POST_ORDER'
export const APP_POST_ORDER_FAIL = 'APP_POST_ORDER_FAIL'
export const APP_CONFIRM_ORDER_START = 'APP_CONFIRM_ORDER_START'
export const APP_CONFIRM_ORDER = 'APP_CONFIRM_ORDER'
export const APP_CONFIRM_ORDER_FAIL = 'APP_CONFIRM_ORDER_FAIL'
export const APP_POST_REFUND_RECEIPT_START = 'APP_POST_REFUND_RECEIPT_START'
export const APP_POST_REFUND_RECEIPT = 'APP_POST_REFUND_RECEIPT'
export const APP_POST_REFUND_RECEIPT_FAIL = 'APP_POST_REFUND_RECEIPT_FAIL'
export const POST_FEE_DUE_REMINDER_START = 'POST_FEE_DUE_REMINDER_START'
export const POST_FEE_DUE_REMINDER_SUCCESS = 'POST_FEE_DUE_REMINDER_SUCCESS'
export const POST_FEE_DUE_REMINDER_FAIL = 'POST_FEE_DUE_REMINDER_FAIL'
export const WAIVE_STUDENT_FEES_START = 'WAIVE_STUDENT_FEES_START'
export const WAIVE_STUDENT_FEES = 'WAIVE_STUDENT_FEES'
export const WAIVE_STUDENT_FEES_FAIL = 'WAIVE_STUDENT_FEES_FAIL'

/* Ui Student Fees */
export const SET_ACTIVE_STUDENTFEE = 'SET_ACTIVE_STUDENTFEE'
export const SET_SELECTED_REFUND_RECIEPTS = 'SET_SELECTED_REFUND_RECIEPTS'

/* SET ACTIVE GALLERY */
export const SET_ACTIVE_GALLERY = 'SET_ACTIVE_GALLERY'

/* SET GALLERY */
export const GET_GALLERY_START = 'GET_GALLERY_START'
export const GALLERY_DATA = 'GALLERY_DATA'
export const GET_GALLERY_FAIL = 'GET_GALLERY_FAIL'
export const START_POST_IMAGES_TO_GALLERY = 'START_POST_IMAGES_TO_GALLERY'
export const SET_IMAGES_TO_GALLERY = 'SET_IMAGES_TO_GALLERY'
export const POST_IMAGE_TO_GALLERY_FAILED = 'POST_IMAGE_TO_GALLERY_FAILED'
export const PATCH_GALLERY_START = 'PATCH_GALLERY_START'
export const PATCH_GALLERY_SUCCESS = 'PATCH_GALLERY_SUCCESS'
export const PATCH_GALLERY_FAIL = 'PATCH_GALLERY_FAIL'
export const DELETE_GALLERY_START = 'DELETE_GALLERY_START'
export const DELETE_GALLERY_SUCCESS = 'DELETE_GALLERY_SUCCESS'
export const DELETE_GALLERY_FAIL = 'DELETE_GALLERY_FAIL'

/* Student Login */
/* Attendance */
export const DAILY_STUDENT_LAST_ATTENDANCE = 'DAILY_STUDENT_LAST_ATTENDANCE'
export const HOURLY_STUDENT_LAST_ATTENDANCE = 'HOURLY_STUDENT_LAST_ATTENDANCE'
export const DAILY_STUDENT_MONTHLY_ATTENDANCE =
  'DAILY_STUDENT_MONTHLY_ATTENDANCE'
export const HOURLY_STUDENT_MONTHLY_ATTENDANCE =
  'HOURLY_STUDENT_MONTHLY_ATTENDANCE'
export const START_LAST_ATTENDANCE_FETCH = 'START_LAST_ATTENDANCE_FETCH'
export const START_MONTHLY_ATTENDANCE_FETCH = 'START_MONTHLY_ATTENDANCE_FETCH'
export const LAST_ATTENDANCE_FETCH_ERROR = 'LAST_ATTENDANCE_FETCH_ERROR'
export const MONTHLY_ATTENDANCE_FETCH_ERROR = 'MONTHLY_ATTENDANCE_FETCH_ERROR'
// actions for the attendance of the entire academic year
export const ATTENDANCE_ACADEMIC_YEAR_FETCH_START =
  'ATTENDANCE_ACADEMIC_YEAR_FETCH_START'
export const ATTENDANCE_ACADEMIC_YEAR_DATA = 'ATTENDANCE_ACADEMIC_YEAR_DATA'
export const ATTENDANCE_ACADEMIC_YEAR_FETCH_FAIL =
  'ATTENDANCE_ACADEMIC_YEAR_FETCH_FAIL'

/* QuestionBank */
export const START_FETCH_QUESTION_BANK = 'START_FETCH_QUESTION_BANK'
export const QUESTION_BANK_DATA = 'QUESTION_BANK_DATA'
export const FAIL_FETCH_QUESTION_BANK = 'FAIL_FETCH_QUESTION_BANK'
export const ADD_QUESTION_BANK = 'ADD_QUESTION_BANK'
export const POST_QUESTION_BANK_FAILED = 'POST_QUESTION_BANK_FAILED'
export const POST_QUESTION_BANK_SUCCESS = 'POST_QUESTION_BANK_SUCCESS'
export const START_POST_QUESTION_BANK = 'START_POST_QUESTION_BANK'
export const START_FETCH_QUESTION_BANK_BY_ID = 'START_FETCH_QUESTION_BANK_BY_ID'
export const QUESTION_BANK_DATA_BY_ID = 'QUESTION_BANK_DATA_BY_ID'
export const FAIL_FETCH_QUESTION_BANK_BY_ID = 'FAIL_FETCH_QUESTION_BANK_BY_ID'
export const EDIT_QUESTION_BANK_START = 'EDIT_QUESTION_BANK_START'
export const EDIT_QUESTION_BANK_SUCCESS = 'EDIT_QUESTION_BANK_SUCCESS'
export const EDIT_QUESTION_BANK_FAIL = 'EDIT_QUESTION_BANK_FAIL'
export const START_POST_QUESTION_BANK_CSV = 'START_POST_QUESTION_BANK_CSV'
export const POST_QUESTION_BANK_CSV_SUCCESS = 'POST_QUESTION_BANK_CSV_SUCCESS'
export const POST_QUESTION_BANK_CSV_FAILED = 'POST_QUESTION_BANK_CSV_FAILED'
export const START_FETCH_QUESTION_BANK_CSV = 'START_FETCH_QUESTION_BANK_CSV'
export const QUESTION_BANK_CSV_DATA = 'QUESTION_BANK_CSV_DATA'
export const FAIL_FETCH_QUESTION_BANK_CSV = 'FAIL_FETCH_QUESTION_BANK_CSV'
export const EDIT_QUESTION_BANK_CSV_START = 'EDIT_QUESTION_BANK_CSV_START'
export const EDIT_QUESTION_BANK_CSV_SUCCESS = 'EDIT_QUESTION_BANK_CSV_SUCCESS'
export const EDIT_QUESTION_BANK_CSV_FAIL = 'EDIT_QUESTION_BANK_CSV_FAIL'

/*File Upload*/
export const START_FILE_UPLOAD = 'START_FILE_UPLOAD'
export const SET_FILE_UPLOAD_DETAILS = 'SET_FILE_UPLOAD_DETAILS'
export const POST_FILE_UPLOAD_FAILED = 'POST_FILE_UPLOAD_FAILED'
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS'
export const CLEAR_FILE_UPLOAD = 'CLEAR_FILE_UPLOAD'
export const UPDATE_FILE_LINKS = 'UPDATE_FILE_LINKS'

/* UI Document Upload */
export const SET_ACTIVE_DOCUMNET_UPLOAD_DATA = 'SET_ACTIVE_DOCUMNET_UPLOAD_DATA'
export const CLEAR_ACTIVE_DOCUMENT_UPLOAD_DATA =
  'CLEAR_ACTIVE_DOCUMENT_UPLOAD_DATA'

/* Documents */
export const SET_DOCUMENTS_DATA = 'SET_DOCUMENTS_DATA'
export const DOCUMENTS_FETCH_START = 'DOCUMENTS_FETCH_START'
export const DOCUMENTS_FETCH_FAIL = 'DOCUMENTS_FETCH_FAIL'
export const UPDATE_DOCUMENTS_DATA = 'UPDATE_DOCUMENTS_DATA'

/* Question Paper */
export const QUESTION_PAPER_DATA = 'QUESTION_PAPER_DATA'

/* Report Card */
// post request for report card generation
export const START_REPORT_CARD_REQUEST = 'START_REPORT_CARD_REQUESTS'
export const REPORT_CARD_REQUEST_SUCCESS = 'REPORT_CARD_REQUEST_SUCCESS'
export const REPORT_CARD_REQUEST_FAIL = 'REPORT_CARD_REQUEST_FAIL'
// get request for report card Status
export const START_FETCH_REPORT_CARD_STATUS = 'START_FETCH_REPORT_CARD_STATUS'
export const REPORT_CARD_STATUS_FETCH_FAIL = 'REPORT_CARD_STATUS_FETCH_FAIL'
export const REPORT_CARD_STATUS_DATA = 'REPORT_CARD_STATUS_DATA'
//get request per student
export const START_FETCH_REPORT_CARD_STATUS_PER_STUDENT =
  'START_FETCH_REPORT_CARD_STATUS_PER_STUDENT'
export const REPORT_CARD_STATUS_FETCH_FAIL_PER_STUDENT =
  'REPORT_CARD_STATUS_FETCH_FAIL_PER_STUDENT'
export const REPORT_CARD_STATUS_DATA_PER_STUDENT =
  'REPORT_CARD_STATUS_DATA_PER_STUDENT'
// Merge PDF
export const MERGED_PDF_DATA = 'MERGED_PDF_DATA'
export const CLEAR_MERGED_PDF_DATA = 'CLEAR_MERGED_PDF_DATA'
//publish report card
export const PUBLISH_REPORT_CARD_START = 'PUBLISH_REPORT_CARD_START'
export const PUBLISH_REPORT_CARD_FAIL = 'PUBLISH_REPORT_CARD_FAIL'
export const PUBLISH_REPORT_CARD_SUCCESS = 'PUBLISH_REPORT_CARD_SUCCESS'
export const GET_PUBLISHED_REPORT_CARD_STUDENT_START =
  'GET_PUBLISHED_REPORT_CARD_STUDENT_START'
export const GET_PUBLISHED_REPORT_CARD_STUDENT_SUCCESS =
  'GET_PUBLISHED_REPORT_CARD_STUDENT_SUCCESS'
export const GET_PUBLISHED_REPORT_CARD_STUDENT_FAIL =
  'GET_PUBLISHED_REPORT_CARD_STUDENT_FAIL'
export const PUBLISH_MARKS_START = 'PUBLISH_MARKS_START'
export const PUBLISH_MARKS_SUCCESS = 'PUBLISH_MARKS_SUCCESS'
export const PUBLISH_MARKS_FAIL = 'PUBLISH_MARKS_FAIL'
/* Performance */
export const START_FETCH_MARKS_FOR_PERFORMANCE =
  'START_FETCH_MARKS_FOR_PERFORMANCE'
export const MARKS_DATA_FOR_PERFORMANCE = 'MARKS_DATA_FOR_PERFORMANCE'
export const FETCH_MARKS_FOR_PERFORMANCE_FAIL =
  'FETCH_MARKS_FOR_PERFORMANCE_FAIL'
export const START_FETCH_REPORT_FOR_PERFORMANCE =
  'START_FETCH_REPORT_FOR_PERFORMANCE'
export const REPORT_DATA_FOR_PERFORMANCE = 'REPORT_DATA_FOR_PERFORMANCE'
export const FETCH_REPORT_FOR_PERFORMANCE_FAIL =
  'FETCH_REPORT_FOR_PERFORMANCE_FAIL'

/* Portion */
export const START_FETCH_PORTION = 'START_FETCH_PORTION'
export const START_POST_PORTION = 'START_POST_PORTION'
export const POST_PORTION_SUCCESS = 'POST_PORTION_SUCCESS'
export const POST_PORTION_FAILED = 'POST_PORTION_FAILED'
export const PORTION_DATA = 'PORTION_DATA'
export const PORTION_FETCH_FAIL = 'PORTION_FETCH_FAIL'
export const START_PORTION_FILE_UPLOAD = 'START_PORTION_FILE_UPLOAD'
export const POST_PORTION_FILE_SUCCESS = 'POST_PORTION_FILE_SUCCESS'
export const POST_PORTION_FILE_FAILED = 'POST_PORTION_FILE_FAILED'
export const SET_PORTION_FILE_DETAILS = 'SET_PORTION_FILE_DETAILS'
export const PATCH_PORTION_START = 'PATCH_PORTION_START'
export const PATCH_PORTION_SUCCESS = 'PATCH_PORTION_SUCCESS'
export const PATCH_PORTION_FAIL = 'PATCH_PORTION_FAIL'
export const DELETE_PORTION_START = 'DELETE_PORTION_START'
export const DELETE_PORTION_SUCCESS = 'DELETE_PORTION_SUCCESS'
export const DELETE_PORTION_FAIL = 'DELETE_PORTION_FAIL'

/* Events */
/* Get Events */
export const GET_EVENTS_START = 'GET_EVENTS_START'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL'

/* Create Event */
export const CREATE_EVENT_START = 'CREATE_EVENT_START'
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL'
export const EDIT_EVENT_START = 'EDIT_EVENT_START'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAIL = 'EDIT_EVENT_FAIL'
export const DELETE_EVENT_START = 'DELETE_EVENT_START'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL'
/* navlist */
export const START_FETCH_NAVLIST_FOR_HEADER = 'START_FETCH_NAVLIST_FOR_HEADER'
export const SET_NAVLIST_DATA_FOR_HEADER = 'SET_NAVLIST_DATA_FOR_HEADER'
export const NAVLIST_DATA_FOR_HEADER_FAILED = 'NAVLIST_DATA_FOR_HEADER_FAILED'
export const START_POST_NAVLIST_FOR_HEADER = 'START_POST_NAVLIST_FOR_HEADER'
export const SET_POST_NAVLIST_DATA_FOR_HEADER =
  'SET_POST_NAVLIST_DATA_FOR_HEADER'
export const POST_NAVLIST_DATA_FOR_HEADER_FAILED =
  'POST_NAVLIST_DATA_FOR_HEADER_FAILED'

/* New Navigation */
export const START_NAVIGATION_FETCH = 'START_NAVIGATION_FETCH'
export const START_NAVIGATION_SUCCESS = 'START_NAVIGATION_SUCCESS'
export const START_NAVIGATION_FAIL = 'START_NAVIGATION_FAIL'
export const START_NAVIGATION_BY_USER_FETCH = 'START_NAVIGATION_BY_USER_FETCH'
export const START_NAVIGATION_BY_USER_SUCCESS =
  'START_NAVIGATION_BY_USER_SUCCESS'
export const START_NAVIGATION_BY_USER_FAIL = 'START_NAVIGATION_BY_USER_FAIL'
export const DELETE_NAVIGATION_START = 'DELETE_NAVIGATION_START'
export const DELETE_NAVIGATION = 'DELETE_NAVIGATION'
export const DELETE_NAVIGATION_FAIL = 'DELETE_NAVIGATION_FAIL'
export const PATCH_NAVIGATION_START = 'PATCH_NAVIGATION_START'
export const PATCH_NAVIGATION = 'PATCH_NAVIGATION'
export const PATCH_NAVIGATION_FAIL = 'PATCH_NAVIGATION_FAIL'
export const POST_NAVIGATION_START = 'POST_NAVIGATION_START'
export const POST_NAVIGATION_SUCCESS = 'POST_NAVIGATION_SUCCESS'
export const POST_NAVIGATION_FAIL = 'POST_NAVIGATION_FAIL'
export const POST_SUB_NAVIGATION_START = 'POST_SUB_NAVIGATION_START'
export const POST_SUB_NAVIGATION_SUCCESS = 'POST_SUB_NAVIGATION_SUCCESS'
export const POST_SUB_NAVIGATION_FAIL = 'POST_SUB_NAVIGATION_FAIL'

//transferCertificate
export const START_TC_FETCH = 'START_TC_FETCH'
export const FETCH_TC_SUCCESS = 'FETCH_TC_SUCCESS'
export const FETCH_TC_FAIL = 'FETCH_TC_FAIL'
export const START_MERGED_TC_PDF_DATA = 'START_MERGED_TC_PDF_DATA'
export const MERGED_TC_PDF_DATA = 'MERGED_TC_PDF_DATA'
export const FAILED_MERGED_TC_PDF_DATA = 'FAILED_MERGED_TC_PDF_DATA'
export const START_TC_FETCH_PER_STUDENT = 'START_TC_FETCH_PER_STUDENT'
export const FETCH_TC_FAIL_PER_STUDENT = 'FETCH_TC_FAIL_PER_STUDENT'
export const FETCH_TC_SUCCESS_PER_STUDENT = 'FETCH_TC_SUCCESS_PER_STUDENT'
export const POST_TC_START = 'POST_TC_START'
export const POST_TC_SUCCESS = 'POST_TC_SUCCESS'
export const POST_TC_FAIL = 'POST_TC_FAIL'

//conductCertificate
export const START_CONDUCT_CERTIFICATE_FETCH = 'START_CONDUCT_CERTIFICATE_FETCH'
export const FETCH_CONDUCT_CERTIFICATE_SUCCESS =
  'FETCH_CONDUCT_CERTIFICATE_SUCCESS'
export const FETCH_CONDUCT_CERTIFICATE_FAIL = 'FETCH_CONDUCT_CERTIFICATE_FAIL'
export const START_MERGED_CONDUCT_CERTIFICATE_PDF_DATA =
  'START_MERGED_CONDUCT_CERTIFICATE_PDF_DATA'
export const MERGED_CONDUCT_CERTIFICATE_PDF_DATA =
  'MERGED_CONDUCT_CERTIFICATE_PDF_DATA'
export const FAILED_MERGED_CONDUCT_CERTIFICATE_PDF_DATA =
  'FAILED_MERGED_CONDUCT_CERTIFICATE_PDF_DATA'

//REGISTER CERTIFICATE
export const START_REGISTER_CERTIFICATE_FETCH =
  'START_REGISTER_CERTIFICATE_FETCH'
export const FETCH_REGISTER_CERTIFICATE_FAIL = 'FETCH_REGISTER_CERTIFICATE_FAIL'
export const FETCH_REGISTER_CERTIFICATE_SUCCESS =
  'FETCH_REGISTER_CERTIFICATE_SUCCESS'
export const START_MERGED_REGISTER_CERTIFICATE_PDF_DATA =
  'START_MERGED_REGISTER_CERTIFICATE_PDF_DATA'
export const MERGED_REGISTER_CERTIFICATE_PDF_DATA =
  'MERGED_REGISTER_CERTIFICATE_PDF_DATA'
export const FAILED_MERGED_REGISTER_CERTIFICATE_PDF_DATA =
  'FAILED_MERGED_REGISTER_CERTIFICATE_PDF_DATA'

//promotional year group
export const PROMOTION_GROUPS_START = 'PROMOTION_GROUPS_START'
export const PROMOTION_GROUPS_FAILURE = 'PROMOTION_GROUPS_FAILURE'
export const PROMOTION_GROUP_DATA = 'PROMOTION_GROUP_DATA'
export const START_PROMOTION_ACADEMIC_YEAR_DATA =
  'START_PROMOTION_ACADEMIC_YEAR_DATA'
export const PROMOTION_ACADEMIC_YEAR_DATA = 'PROMOTION_ACADEMIC_YEAR_DATA'
export const ADMISSION_YEAR_DATA_START = 'ADMISSION_YEAR_DATA_START'
export const ADMISSION_YEAR_DATA = 'ADMISSION_YEAR_DATA'
export const ADMISSION_YEAR_DATA_FAIL = 'ADMISSION_YEAR_DATA_FAIL'

//promotion next class
export const START_DUPLICATE_STUDENT_GROUP_DATA =
  'START_DUPLICATE_STUDENT_GROUP_DATA'
export const DUPLICATE_STUDENT_GROUP_DATA = 'DUPLICATE_STUDENT_GROUP_DATA'
export const FAILED_START_DUPLICATE_STUDENT_GROUP_DATA =
  'FAILED_START_DUPLICATE_STUDENT_GROUP_DATA'
export const ADD_STUDENT_CSV_FAIL = 'ADD_STUDENT_CSV_FAIL'
export const DUPLICATE_TEST_START = 'DUPLICATE_TEST_START'
export const DUPLICATE_TEST_SUCCESS = 'DUPLICATE_TEST_SUCCESS'
export const DUPLICATE_TEST_FAIL = 'DUPLICATE_TEST_FAIL'

//homework and assignemnt
export const START_HOMEWORK_DOC_UPLOAD = 'START_HOMEWORK_DOC_UPLOAD'
export const HOMEWORK_DOC_UPLOAD_SUCCESS = 'HOMEWORK_DOC_UPLOAD_SUCCESS'
export const HOMEWORK_DOC_UPLOAD_FAILURE = 'HOMEWORK_DOC_UPLOAD_FAILURE'
export const UPDATE_LINKS_HOMEWORK = 'UPDATE_LINKS_HOMEWORK'
export const START_POST_HOMEWORK = 'START_POST_HOMEWORK'
export const POST_HOMEWORK_SUCCESS = 'POST_HOMEWORK_SUCCESS'
export const POST_HOMEWORK_FAILURE = 'POST_HOMEWORK_FAILURE'
export const START_FETCH_HOMEWORK = 'START_FETCH_HOMEWORK'
export const FETCH_HOMEWORK_SUCCESS = 'FETCH_HOMEWORK_SUCCESS'
export const FETCH_HOMEWORK_FAILURE = 'FETCH_HOMEWORK_FAILURE'
export const PATCH_HOMEWORK_START = 'PATCH_HOMEWORK_START'
export const PATCH_HOMEWORK_SUCCESS = 'PATCH_HOMEWORK_SUCCESS'
export const PATCH_HOMEWORK_FAIL = 'PATCH_HOMEWORK_FAIL'
export const DELETE_HOMEWORK_START = 'DELETE_HOMEWORK_START'
export const DELETE_HOMEWORK_SUCCESS = 'DELETE_HOMEWORK_SUCCESS'
export const DELETE_HOMEWORK_FAIL = 'DELETE_HOMEWORK_FAIL'

//add profile picture
export const ADD_PROFILE_PICTURE_START = 'ADD_PROFILE_PICTURE_START'
export const ADD_PROFILE_PICTURE_SUCCESS = 'ADD_PROFILE_PICTURE_SUCCESS'
export const ADD_PROFILE_PICTURE_FAILURE = 'ADD_PROFILE_PICTURE_FAILURE'
export const CLEAR_STUDENT_PICTURE = 'CLEAR_STUDENT_PICTURE'

//policy
export const START_POST_POLICY = 'START_POST_POLICY'
export const POST_POLICY_SUCCESS = 'POST_POLICY_SUCCESS'
export const POST_POLICY_FAILURE = 'POST_POLICY_FAILURE'
export const START_FETCH_POLICY = 'START_FETCH_POLICY'
export const START_FETCH_POLICY_SUCCESS = 'START_FETCH_POLICY_SUCCESS'
export const START_FETCH_POLICY_FAILURE = 'START_FETCH_POLICY_FAILURE'
export const START_UPLOAD_POLICY_DOCUMENT = 'START_UPLOAD_POLICY_DOCUMENT'
export const UPLOAD_POLICY_DOCUMENT_SUCCESS = 'UPLOAD_POLICY_DOCUMENT_SUCCESS'
export const UPLOAD_POLICY_DOCUMENT_FAILURE = 'UPLOAD_POLICY_DOCUMENT_FAILURE'
export const REMOVE_POLICY_DOCUMENT_LINK = 'REMOVE_POLICY_DOCUMENT_LINK'
//student remarks
export const START_POST_STUDENT_REMARKS = 'START_POST_STUDENT_REMARKS'
export const POST_STUDENT_REMARKS_SUCCESS = 'POST_STUDENT_REMARKS_SUCCESS'
export const POST_STUDENT_REMARKS_FAILURE = 'POST_STUDENT_REMARKS_FAILURE'
export const START_FETCH_STUDENT_REMARKS = 'START_FETCH_STUDENT_REMARKS'
export const START_FETCH_STUDENT_REMARKS_SUCCESS =
  'START_FETCH_STUDENT_REMARKS_SUCCESS'
export const START_FETCH_STUDENT_REMARKS_FAILURE =
  'START_FETCH_STUDENT_REMARKS_FAILURE'
export const POST_REMARKS_START = 'POST_REMARKS_START'
export const POST_REMARKS_SUCCESS = 'POST_REMARKS_SUCCESS'
export const POST_REMARKS_FAIL = 'POST_REMARKS_FAIL'
export const FETCH_REMARKS_START = 'FETCH_REMARKS_START'
export const FETCH_REMARKS_SUCCESS = 'FETCH_REMARKS_SUCCESS'
export const FETCH_REMARKS_FAIL = 'FETCH_REMARKS_FAIL'
export const PATCH_TEST_REMARKS_START = 'PATCH_TEST_REMARKS_START'
export const PATCH_TEST_REMARKS_SUCCESS = 'PATCH_TEST_REMARKS_SUCCESS'
export const PATCH_TEST_REMARKS_FAIL = 'PATCH_TEST_REMARKS_FAIL'

//cms
export const START_POST_CMS = 'START_POST_CMS'
export const POST_CMS_SUCCESS = 'POST_CMS_SUCCESS'
export const POST_CMS_FAILURE = 'POST_CMS_FAILURE'
export const PATCH_CMS_SUCCESS = 'PATCH_CMS_SUCCESS'
export const START_FETCH_CMS = 'START_FETCH_CMS'
export const START_FETCH_CMS_SUCCESS = 'START_FETCH_CMS_SUCCESS'
export const START_FETCH_CMS_FAILURE = 'START_FETCH_CMS_FAILURE'
export const START_UPLOAD_CMS_DOCUMENT = 'START_UPLOAD_CMS_DOCUMENT'
export const UPLOAD_CMS_DOCUMENT_SUCCESS = 'UPLOAD_CMS_DOCUMENT_SUCCESS'
export const UPLOAD_CMS_DOCUMENT_FAILURE = 'UPLOAD_CMS_DOCUMENT_FAILURE'
export const REMOVE_CMS_DOCUMENT_LINK = 'REMOVE_CMS_DOCUMENT_LINK'

//email
export const FETCH_MAIL_BY_STATUS = 'FETCH_MAIL_BY_STATUS'
export const POST_MAIL_DATA = 'POST_MAIL_DATA'
export const MAIL_POST_FAILURE = 'MAIL_POST_FAILURE'
export const MAIL_FETCH_START = 'MAIL_FETCH_START'
export const SET_POST_MAIL_STATUS = 'SET_POST_MAIL_STATUS'
export const SET_POST_MAIL = 'SET_POST_MAIL'
export const GET_EMAIL_START = 'GET_EMAIL_START'
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS'
export const GET_EMAIL_FAIL = 'GET_EMAIL_FAIL'
export const GET_EMAIL_LOGS_BY_EMAILID_START = 'GET_EMAIL_LOGS_BY_EMAILID_START'
export const GET_EMAIL_LOGS_BY_EMAILID = 'GET_EMAIL_LOGS_BY_EMAILID'
export const GET_EMAIL_LOGS_BY_EMAILID_FAIL = 'GET_EMAIL_LOGS_BY_EMAILID_FAIL'

//proposal
export const START_POST_PROPOSAL = 'START_POST_PROPOSAL'
export const PROPOSAL_POST_FAILURE = 'PROPOSAL_POST_FAILURE'
export const PROPOSAL_POST_SUCCESS = 'PROPOSAL_POST_SUCCESS'
export const GET_PROPOSAL = 'START_GET_PROPOSAL'
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL'
export const SEARCH_ITEAMS_DATA = 'SEARCH_ITEAMS_DATA'
export const START_SEARCH_ITEAMS_DATA = 'START_SEARCH_ITEAMS_DATA'
export const POST_REQUISITIONS_DATA = 'POST_REQUISITIONS_DATA'
export const START_POST_REQUISITIONS_DATA = 'START_POST_REQUISITIONS_DATA'
export const POST_REQUISITIONS_DATA_FAIL = 'POST_REQUISITIONS_DATA_FAIL'

//survey
export const POST_SURVEY_START = 'POST_SURVEY_START'
export const POST_SURVEY_SUCCESS = 'POST_SURVEY_SUCCESS'
export const POST_SURVEY_FAIL = 'POST_SURVEY_FAIL'
export const PATCH_SURVEY_START = 'PATCH_SURVEY_START'
export const PATCH_SURVEY_SUCCESS = 'PATCH_SURVEY_SUCCESS'
export const PATCH_SURVEY_FAIL = 'PATCH_SURVEY_FAIL'
export const DELETE_SURVEY_START = 'DELETE_SURVEY_START'
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS'
export const DELETE_SURVEY_FAIL = 'DELETE_SURVEY_FAIL'
export const START_GET_QUESTION = 'START_GET_QUESTION'
export const QUESTION_GET_FAILURE = 'QUESTION_GET_FAILURE'
export const ANSWER_POST_SUCCESS = 'ANSWER_POST_SUCCESS'
export const GET_QUESTION = 'GET_QUESTION'
export const START_GET_SURVEY = 'START_GET_SURVEY'
export const SURVEY_GET_FAILURE = 'SURVEY_GET_FAILURE'
export const SURVEY_FETCHED = 'SURVEY_FETCHED'
export const GET_FEEDBACK_TEACHER = 'GET_FEEDBACK_TEACHER'
export const GET_FEEDBACK_TEACHER_REPORT = 'GET_FEEDBACK_TEACHER_REPORT'
export const START_GET_FEEDBACK_TEACHER = 'START_GET_FEEDBACK_TEACHER'
export const START_GET_FEEDBACK_TEACHER_REPORT =
  'START_GET_FEEDBACK_TEACHER_REPORT'
export const GET_FEEDBACK_TEACHER_REPORT_COMMENTS =
  'GET_FEEDBACK_TEACHER_REPORT_COMMENTS'
export const START_GET_FEEDBACK_TEACHER_REPORT_COMMENTS =
  'START_GET_FEEDBACK_TEACHER_REPORT_COMMENTS'
export const SURVEY_RESULTS_FETCHED = 'SURVEY_RESULTS_FETCHED'
export const START_GET_SURVEY_RESULTS = 'START_GET_SURVEY_RESULTS'
export const SURVEY_RESULTS_GET_FAILURE = 'SURVEY_RESULTS_GET_FAILURE'
export const DELETE_SURVEY_QUESTION_START = 'DELETE_SURVEY_QUESTION_START'
export const DELETE_SURVEY_QUESTION_SUCCESS = 'DELETE_SURVEY_QUESTION_SUCCESS'
export const DELETE_SURVEY_QUESTION_FAIL = 'DELETE_SURVEY_QUESTION_FAIL'
export const POST_SURVEY_QUESTIONS_START = 'POST_SURVEY_QUESTIONS_START'
export const POST_SURVEY_QUESTIONS_SUCCESS = 'POST_SURVEY_QUESTIONS_SUCCESS'
export const POST_SURVEY_QUESTIONS_FAIL = 'POST_SURVEY_QUESTIONS_FAIL'
export const GET_SURVEY_ANSWER_START = 'GET_SURVEY_ANSWER_START'
export const GET_SURVEY_ANSWER_SUCCESS = 'GET_SURVEY_ANSWER_SUCCESS'
export const GET_SURVEY_ANSWER_FAIL = 'GET_SURVEY_ANSWER_FAIL'
//worksheet
export const START_POST_WORKSHEET = 'START_POST_WORKSHEET,'
export const WORKSHEET_POST_FAILURE = 'WORKSHEET_POST_FAILURE,'
export const WORKSHEET_POST_SUCCESS = 'WORKSHEET_POST_SUCCESS,'
export const START_GET_WORKSHEET = 'START_GET_WORKSHEET,'
export const WORKSHEET_GET_FAILURE = 'WORKSHEET_GET_FAILURE,'
export const WORKSHEET_GET_SUCCESS = 'WORKSHEET_GET_SUCCESS,'
export const DELETE_WORKSHEET_START = 'DELETE_WORKSHEET_START'
export const DELETE_WORKSHEET_SUCCESS = 'DELETE_WORKSHEET_SUCCESS'
export const DELETE_WORKSHEET_FAIL = 'DELETE_WORKSHEET_FAIL'
export const SAVE_SELECTED_WORKSHEET = 'SAVE_SELECTED_WORKSHEET'
export const PATCH_WORKSHEET_START = 'PATCH_WORKSHEET_START'
export const PATCH_WORKSHEET_SUCCESS = 'PATCH_WORKSHEET_SUCCESS'
export const PATCH_WORKSHEET_FAIL = 'PATCH_WORKSHEET_FAIL'

//library management
export const POST_LIBRARY_CATALOG_START = 'POST_LIBRARY_CATALOG_START'
export const POST_LIBRARY_CATALOG = 'POST_LIBRARY_CATALOG'
export const POST_LIBRARY_CATALOG_FAIL = 'POST_LIBRARY_CATALOG_FAIL'
export const GET_LIBRARY_CATALOG_POLICIES_START =
  'GET_LIBRARY_CATALOG_POLICIES_START'
export const GET_LIBRARY_CATALOG_POLICIES = 'GET_LIBRARY_CATALOG_POLICIES'
export const GET_LIBRARY_CATALOG_POLICIES_FAIL =
  'GET_LIBRARY_CATALOG_POLICIES_FAIL'
export const PATCH_LIBRARY_CATALOG_POLICY_START =
  'PATCH_LIBRARY_CATALOG_POLICY_START'
export const PATCH_LIBRARY_CATALOG_POLICY_SUCCESS =
  'PATCH_LIBRARY_CATALOG_POLICY_SUCCESS'
export const PATCH_LIBRARY_CATALOG_POLICY_FAIL =
  'PATCH_LIBRARY_CATALOG_POLICY_FAIL'
export const DELETE_LIBRARY_CATALOG_POLICY_START =
  'DELETE_LIBRARY_CATALOG_POLICY_START'
export const DELETE_LIBRARY_CATALOG_POLICY = 'DELETE_LIBRARY_CATALOG_POLICY'
export const DELETE_LIBRARY_CATALOG_POLICY_FAIL =
  'DELETE_LIBRARY_CATALOG_POLICY_FAIL'
export const POST_LIBRARY_LENDING_START = 'POST_LIBRARY_LENDING_START'
export const POST_LIBRARY_LENDING = 'POST_LIBRARY_LENDING'
export const POST_LIBRARY_LENDING_FAIL = 'POST_LIBRARY_LENDING_FAIL'
export const LIBRARY_USERS_SEARCH_START = 'LIBRARY_USERS_SEARCH_START'
export const LIBRARY_USERS_SEARCH = 'LIBRARY_USERS_SEARCH'
export const LIBRARY_USERS_SEARCH_FAIL = 'LIBRARY_USERS_SEARCH_FAIL'
export const GET_LIBRARY_CHALLAN_BY_USERID_START =
  'GET_LIBRARY_CHALLAN_BY_USERID_START'
export const GET_LIBRARY_CHALLAN_BY_USERID = 'GET_LIBRARY_CHALLAN_BY_USERID'
export const GET_LIBRARY_CHALLAN_BY_USERID_FAIL =
  'GET_LIBRARY_CHALLAN_BY_USERID_FAIL'
export const GET_LIBRARY_USER_POLICY_ID_START =
  'GET_LIBRARY_USER_POLICY_ID_START'
export const GET_LIBRARY_USER_POLICY_ID = 'GET_LIBRARY_USER_POLICY_ID'
export const GET_LIBRARY_USER_POLICY_ID_FAIL = 'GET_LIBRARY_USER_POLICY_ID_FAIL'
export const POST_LIBRARY_USER_POLICY_START = 'POST_LIBRARY_USER_POLICY_START'
export const POST_LIBRARY_USER_POLICY = 'POST_LIBRARY_USER_POLICY'
export const POST_LIBRARY_USER_POLICY_FAIL = 'POST_LIBRARY_USER_POLICY_FAIL'
export const DELETE_LIBRARY_CATALOG_START = 'DELETE_LIBRARY_CATALOG_START'
export const DELETE_LIBRARY_CATALOG = 'DELETE_LIBRARY_CATALOG'
export const DELETE_LIBRARY_CATALOG_FAIL = 'DELETE_LIBRARY_CATALOG_FAIL'
export const RETURN_LIBRARY_LENDING_START = 'RETURN_LIBRARY_LENDING_START'
export const RETURN_LIBRARY_LENDING = 'RETURN_LIBRARY_LENDING'
export const RETURN_LIBRARY_LENDING_FAIL = 'RETURN_LIBRARY_LENDING_FAIL'
export const LIBRARY_CHALLAN_CHANGE_STATUS_START =
  'LIBRARY_CHALLAN_CHANGE_STATUS_START'
export const LIBRARY_CHALLAN_CHANGE_STATUS_FAIL =
  'LIBRARY_CHALLAN_CHANGE_STATUS_FAIL'
export const LIBRARY_CHALLAN_CHANGE_STATUS = 'LIBRARY_CHALLAN_CHANGE_STATUS'
export const GET_LIBRARY_CATALOG_STATS_START = 'GET_LIBRARY_CATALOG_STATS_START'
export const GET_LIBRARY_CATALOG_STATS = 'GET_LIBRARY_CATALOG_STATS'
export const GET_LIBRARY_CATALOG_STATS_FAIL = 'GET_LIBRARY_CATALOG_STATS_FAIL'
export const GET_LIBRARY_CATALOG_TAGS_START = 'GET_LIBRARY_CATALOG_TAGS_START'
export const GET_LIBRARY_CATALOG_TAGS = 'GET_LIBRARY_CATALOG_TAGS'
export const GET_LIBRARY_CATALOG_TAGS_FAIL = 'GET_LIBRARY_CATALOG_TAGS_FAIL'
export const GET_LIBRARY_CHALLAN_STATS_START = 'GET_LIBRARY_CHALLAN_STATS_START'
export const GET_LIBRARY_CHALLAN_STATS = 'GET_LIBRARY_CHALLAN_STATS'
export const GET_LIBRARY_CHALLAN_STATS_FAIL = 'GET_LIBRARY_CHALLAN_STATS_FAIL'
export const GET_LIBRARY_LENDING_STATS_START = 'GET_LIBRARY_LENDING_STATS_START'
export const GET_LIBRARY_LENDING_STATS = 'GET_LIBRARY_LENDING_STATS'
export const GET_LIBRARY_LENDING_STATS_FAIL = 'GET_LIBRARY_LENDING_STATS_FAIL'
export const GET_LIBRARY_USER_POLICY_START = 'GET_LIBRARY_USER_POLICY_START'
export const GET_LIBRARY_USER_POLICY = 'GET_LIBRARY_USER_POLICY'
export const GET_LIBRARY_USER_POLICY_FAIL = 'GET_LIBRARY_USER_POLICY_FAIL'
export const PATCH_LIBRARY_USER_POLICY_START = 'PATCH_LIBRARY_USER_POLICY_START'
export const PATCH_LIBRARY_USER_POLICY_SUCCESS =
  'PATCH_LIBRARY_USER_POLICY_SUCCESS'
export const PATCH_LIBRARY_USER_POLICY_FAIL = 'PATCH_LIBRARY_USER_POLICY_FAIL'
export const POST_ATTACH_POLICY_TO_USER_START =
  'POST_ATTACH_POLICY_TO_USER_START'
export const POST_ATTACH_POLICY_TO_USER_SUCCESS =
  'POST_ATTACH_POLICY_TO_USER_SUCCESS'
export const POST_ATTACH_POLICY_TO_USER_FAIL = 'POST_ATTACH_POLICY_TO_USER_FAIL'
export const GET_LIBRARY_USER_LENDING_START = 'GET_LIBRARY_USER_LENDING_START'
export const GET_LIBRARY_USER_LENDING = 'GET_LIBRARY_USER_LENDING'
export const GET_LIBRARY_USER_LENDING_FAIL = 'GET_LIBRARY_USER_LENDING_FAIL'
export const GET_LIBRARY_USER_CHALLAN_START = 'GET_LIBRARY_USER_CHALLAN_START'
export const GET_LIBRARY_USER_CHALLAN = 'GET_LIBRARY_USER_CHALLAN'
export const GET_LIBRARY_USER_CHALLAN_FAIL = 'GET_LIBRARY_USER_CHALLAN_FAIL'
export const GET_LIBRARY_CHALLAN_BY_STATUS_START =
  'GET_LIBRARY_CHALLAN_BY_STATUS_START'
export const GET_LIBRARY_CHALLAN_BY_STATUS_SUCCESS =
  'GET_LIBRARY_CHALLAN_BY_STATUS_SUCCESS'
export const GET_LIBRARY_CHALLAN_BY_STATUS_FAIL =
  'GET_LIBRARY_CHALLAN_BY_STATUS_FAIL'
export const GET_LIBRARY_USER_LENDING_BY_STATUS_START =
  'GET_LIBRARY_USER_LENDING_BY_STATUS_START'
export const GET_LIBRARY_USER_LENDING_BY_STATUS_SUCCESS =
  'GET_LIBRARY_USER_LENDING_BY_STATUS_SUCCESS'
export const GET_LIBRARY_USER_LENDING_BY_STATUS_FAIL =
  'GET_LIBRARY_USER_LENDING_BY_STATUS_FAIL'
export const LIBRARY_LENDING_CHANGE_STATUS_START =
  'LIBRARY_LENDING_CHANGE_STATUS_START'
export const LIBRARY_LENDING_CHANGE_STATUS_SUCCESS =
  'LIBRARY_LENDING_CHANGE_STATUS_SUCCESS'
export const LIBRARY_LENDING_CHANGE_STATUS_FAIL =
  'LIBRARY_LENDING_CHANGE_STATUS_FAIL'
export const GET_LIBRARAY_USER_BY_ID_START = 'GET_LIBRARAY_USER_BY_ID_START'
export const GET_LIBRARAY_USER_BY_ID_SUCCESS = 'GET_LIBRARAY_USER_BY_ID_SUCCESS'
export const GET_LIBRARAY_USER_BY_ID_FAIL = 'GET_LIBRARAY_USER_BY_ID_FAIL'

//Leave Policy
export const LEAVE_POLICY_POST_START = 'LEAVE_POLICY_POST_START'
export const LEAVE_POLICY_POST_SUCCESS = 'LEAVE_POLICY_POST_SUCCESS'
export const LEAVE_POLICY_POST_FAILURE = 'LEAVE_POLICY_POST_FAILURE'
export const LEAVE_POLICY_PATCH_START = 'LEAVE_POLICY_PATCH_START'
export const LEAVE_POLICY_PATCH_SUCCESS = 'LEAVE_POLICY_PATCH_SUCCESS'
export const LEAVE_POLICY_PATCH_FAILURE = 'LEAVE_POLICY_PATCH_FAILURE'
export const LEAVE_POLICY_FETCH_START = 'LEAVE_POLICY_FETCH_START'
export const LEAVE_POLICY_FETCH_SUCCESS = 'LEAVE_POLICY_FETCH_SUCCESS'
export const LEAVE_POLICY_FETCH_FAILURE = 'LEAVE_POLICY_FETCH_FAILURE'
export const LEAVE_POLICY_DELETE_START = 'LEAVE_POLICY_DELETE_START'
export const LEAVE_POLICY_DELETE_SUCCESS = 'LEAVE_POLICY_DELETE_SUCCESS'
export const LEAVE_POLICY_DELETE_FAILURE = 'LEAVE_POLICY_DELETE_FAILURE'

//Holiday
export const HOLIDAY_FETCH_START = 'HOLIDAY_FETCH_START'
export const HOLIDAY_FETCH_SUCCESS = 'HOLIDAY_FETCH_SUCCESS'
export const HOLIDAY_FETCH_FAILURE = 'HOLIDAY_FETCH_FAILURE'
export const POST_HOLIDAY_FETCH_START = 'POST_HOLIDAY_FETCH_START'
export const POST_HOLIDAY_FETCH_SUCCESS = 'POST_HOLIDAY_FETCH_SUCCESS'
export const POST_HOLIDAY_FETCH_FAILURE = 'POST_HOLIDAY_FETCH_FAILURE'
export const PATCH_HOLIDAY_FETCH_START = 'PATCH_HOLIDAY_FETCH_START'
export const PATCH_HOLIDAY_FETCH_SUCCESS = 'PATCH_HOLIDAY_FETCH_SUCCESS'
export const PATCH_HOLIDAY_FETCH_FAILURE = 'PATCH_HOLIDAY_FETCH_FAILURE'
export const DELETE_HOLIDAY_FETCH_START = 'DELETE_HOLIDAY_FETCH_START'
export const DELETE_HOLIDAY_FETCH_SUCCESS = 'DELETE_HOLIDAY_FETCH_SUCCESS'
export const DELETE_HOLIDAY_FETCH_FAILURE = 'DELETE_HOLIDAY_FETCH_FAILURE'
export const TEACHER_HOLIDAY_FETCH_START = 'TEACHER_HOLIDAY_FETCH_START'
export const TEACHER_HOLIDAY_FETCH_SUCCESS = 'TEACHER_HOLIDAY_FETCH_SUCCESS'
export const TEACHER_HOLIDAY_FETCH_FAILURE = 'TEACHER_HOLIDAY_FETCH_FAILURE'

//library ui
export const SAVE_SELECTED_LIBRARY_USER = 'SAVE_SELECTED_LIBRARY_USER'
export const SAVE_SELECTED_LIBRARY_CATALOGS = 'SAVE_SELECTED_LIBRARY_CATALOGS'
export const CLEAR_LIBRARY_CATALOG_EDIT_STATUS =
  'CLEAR_LIBRARY_CATALOG_EDIT_STATUS'
export const SAVE_SELECTED_LIBRARY_CATALOG = 'SAVE_SELECTED_LIBRARY_CATALOG'
export const SAVE_LIBRARY_CATALOG_EDIT_STATUS =
  'SAVE_LIBRARY_CATALOG_EDIT_STATUS'

//registration certificate
export const START_FETCH_REGISTRATION_CERTY = 'START_FETCH_REGISTRATION_CERTY'
export const START_FETCH_REGISTRATION_CERTY_SUCCESS =
  'START_FETCH_REGISTRATION_CERTY_SUCCESS'
export const START_FETCH_REGISTRATION_CERTY_FAILURE =
  'START_FETCH_REGISTRATION_CERTY_FAILURE'

//student idcards
export const POST_STUDENT_IDCARD_START = 'POST_STUDENT_IDCARD_START'
export const POST_STUDENT_IDCARD_SUCCESS = 'POST_STUDENT_IDCARD_SUCCESS'
export const POST_STUDENT_IDCARD_FAILURE = 'POST_STUDENT_IDCARD_FAILURE'
export const GET_STUDENT_IDCARD_START = 'GET_STUDENT_IDCARD_START'
export const GET_STUDENT_IDCARD_SUCCESS = 'GET_STUDENT_IDCARD_SUCCESS'
export const GET_STUDENT_IDCARD_FAILURE = 'GET_STUDENT_IDCARD_FAILURE'

//teacher idcards
export const POST_TEACHER_IDCARD_START = 'POST_TEACHER_IDCARD_START'
export const POST_TEACHER_IDCARD_SUCCESS = 'POST_TEACHER_IDCARD_SUCCESS'
export const POST_TEACHER_IDCARD_FAILURE = 'POST_TEACHER_IDCARD_FAILURE'
export const GET_TEACHER_IDCARD_START = 'GET_TEACHER_IDCARD_START'
export const GET_TEACHER_IDCARD_SUCCESS = 'GET_TEACHER_IDCARD_SUCCESS'
export const GET_TEACHER_IDCARD_FAILURE = 'GET_TEACHER_IDCARD_FAILURE'

//payroll policy
export const POST_PAYROLL_POLICY_START = 'POST_PAYROLL_POLICY_START'
export const POST_PAYROLL_POLICY_FAIL = 'POST_PAYROLL_POLICY_FAIL'
export const POST_PAYROLL_POLICY_SUCCESS = 'POST_PAYROLL_POLICY_SUCCESS'
export const GET_PAYROLL_POLICY_START = 'GET_PAYROLL_POLICY_START'
export const GET_PAYROLL_POLICY_FAIL = 'GET_PAYROLL_POLICY_FAIL'
export const GET_PAYROLL_POLICY_SUCCESS = 'GET_PAYROLL_POLICY_SUCCESS'
export const PATCH_PAYROLL_POLICY_START = 'PATCH_PAYROLL_POLICY_START'
export const PATCH_PAYROLL_POLICY_FAIL = 'PATCH_PAYROLL_POLICY_FAIL'
export const PATCH_PAYROLL_POLICY_SUCCESS = 'PATCH_PAYROLL_POLICY_SUCCESS'
export const POST_PAYROLL_POLICY_TO_TEACHER_START =
  'POST_PAYROLL_POLICY_TO_TEACHER_START'
export const POST_PAYROLL_POLICY_TO_TEACHER_FAIL =
  'POST_PAYROLL_POLICY_TO_TEACHER_FAIL'
export const POST_PAYROLL_POLICY_TO_TEACHER_SUCCESS =
  'POST_PAYROLL_POLICY_TO_TEACHER_SUCCESS'
export const GET_PAYROLL_POLICY_BY_TEACHER_ID_START =
  'GET_PAYROLL_POLICY_BY_TEACHER_ID_START'
export const GET_PAYROLL_POLICY_BY_TEACHER_ID_FAIL =
  'GET_PAYROLL_POLICY_BY_TEACHER_ID_FAIL'
export const GET_PAYROLL_POLICY_BY_TEACHER_ID_SUCCESS =
  'GET_PAYROLL_POLICY_BY_TEACHER_ID_SUCCESS'

//financial details
export const GET_FINANCIAL_DETAILS_START = 'GET_FINANCIAL_DETAILS_START'
export const GET_FINANCIAL_DETAILS_FAIL = 'GET_FINANCIAL_DETAILS_FAIL'
export const GET_FINANCIAL_DETAILS_SUCCESS = 'GET_FINANCIAL_DETAILS_SUCCESS'
export const POST_FINANCIAL_DETAILS_START = 'POST_FINANCIAL_DETAILS_START'
export const POST_FINANCIAL_DETAILS_FAIL = 'POST_FINANCIAL_DETAILS_FAIL'
export const POST_FINANCIAL_DETAILS_SUCCESS = 'POST_FINANCIAL_DETAILS_SUCCESS'
export const PATCH_FINANCIAL_DETAILS_START = 'PATCH_FINANCIAL_DETAILS_START'
export const PATCH_FINANCIAL_DETAILS_FAIL = 'PATCH_FINANCIAL_DETAILS_FAIL'
export const PATCH_FINANCIAL_DETAILS_SUCCESS = 'PATCH_FINANCIAL_DETAILS_SUCCESS'

//Hallticket
export const GET_HALLTICKET_START = 'GET_HALLTICKET_START'
export const GET_HALLTICKET_FAIL = 'GET_HALLTICKET_FAIL'
export const GET_HALLTICKET_SUCCESS = 'GET_HALLTICKET_SUCCESS'
export const POST_HALLTICKET_START = 'POST_HALLTICKET_START'
export const POST_HALLTICKET_FAIL = 'POST_HALLTICKET_FAIL'
export const POST_HALLTICKET_SUCCESS = 'POST_HALLTICKET_SUCCESS'
export const POST_TIMETABLE_START = 'POST_TIMETABLE_START'
export const POST_TIMETABLE_FAIL = 'POST_TIMETABLE_FAIL'
export const POST_TIMETABLE_SUCCESS = 'POST_TIMETABLE_SUCCESS'
export const GET_EXAMTIMETABLE_DETAILS_START = 'GET_EXAMTIMETABLE_DETAILS_START'
export const GET_EXAMTIMETABLE_DETAILS_FAIL = 'GET_EXAMTIMETABLE_DETAILS_FAIL'
export const GET_EXAMTIMETABLE_DETAILS_SUCCESS =
  'GET_EXAMTIMETABLE_DETAILS_SUCCESS'

//payslips
export const PAYSLIPS_FETCH_START = 'PAYSLIPS_FETCH_START'
export const PAYSLIPS_DATA = 'PAYSLIPS_DATA'
export const PAYSLIPS_FETCH_FAIL = 'PAYSLIPS_FETCH_FAIL'

//sms template
export const GET_SMS_TEMPLATE_START = 'GET_SMS_TEMPLATE_START'
export const GET_SMS_TEMPLATE_SUCCESS = 'GET_SMS_TEMPLATE_SUCCESS'
export const GET_SMS_TEMPLATE_FAIL = 'GET_SMS_TEMPLATE_FAIL'
export const GET_SMS_TEMPLATE_GROUP_NAMES_START =
  'GET_SMS_TEMPLATE_GROUP_NAMES_START'
export const GET_SMS_TEMPLATE_GROUP_NAMES_SUCCESS =
  'GET_SMS_TEMPLATE_GROUP_NAMES_SUCCESS'
export const GET_SMS_TEMPLATE_GROUP_NAMES_FAIL =
  'GET_SMS_TEMPLATE_GROUP_NAMES_FAIL'
export const POST_SMS_TEMPLATE_START = 'POST_SMS_TEMPLATE_START'
export const POST_SMS_TEMPLATE_SUCCESS = 'POST_SMS_TEMPLATE_SUCCESS'
export const POST_SMS_TEMPLATE_FAIL = 'POST_SMS_TEMPLATE_FAIL'
export const SMS_TEMPLATE_DATA = 'SMS_TEMPLATE_DATA'
export const DELETE_SMS_TEMPLATE_START = 'DELETE_SMS_TEMPLATE_START'
export const DELETE_SMS_TEMPLATE_SUCCESS = 'DELETE_SMS_TEMPLATE_SUCCESS'
export const DELETE_SMS_TEMPLATE_FAIL = 'DELETE_SMS_TEMPLATE_FAIL'
export const PATCH_SMS_TEMPLATE_START = 'PATCH_SMS_TEMPLATE_START'
export const PATCH_SMS_TEMPLATE_SUCCESS = 'PATCH_SMS_TEMPLATE_SUCCESS'
export const PATCH_SMS_TEMPLATE_FAIL = 'PATCH_SMS_TEMPLATE_FAIL'
export const GET_SMS_TEMPLATE_BY_ID_START = 'GET_SMS_TEMPLATE_BY_ID_START'
export const GET_SMS_TEMPLATE_BY_ID_SUCCESS = 'GET_SMS_TEMPLATE_BY_ID_SUCCESS'
export const GET_SMS_TEMPLATE_BY_ID_FAIL = 'GET_SMS_TEMPLATE_BY_ID_FAIL'
export const POST_SMS_TEMPLATE_CSV_START = 'POST_SMS_TEMPLATE_CSV_START'
export const POST_SMS_TEMPLATE_CSV_SUCCESS = 'POST_SMS_TEMPLATE_CSV_SUCCESS'
export const POST_SMS_TEMPLATE_CSV_FAIL = 'POST_SMS_TEMPLATE_CSV_FAIL'

/*forgot password */
export const GET_OTP_START = 'GET_OTP_START'
export const GET_OTP_FAIL = 'GET_OTP_FAIL'
export const GET_OTP = 'GET_OTP'
export const VERIFY_OTP_START = 'VERIFY_OTP_START'
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'
export const VERIFY_OTP = 'VERIFY_OTP'
export const RESET_PASSWORD_STARTS = 'RESET_PASSWORD_STARTS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD = 'RESET_PASSWORD'

/*student achievements*/
export const POST_STUDENT_ACHIEVEMENT_START = 'POST_STUDENT_ACHIEVEMENT_START'
export const POST_STUDENT_ACHIEVEMENT = 'POST_STUDENT_ACHIEVEMENT'
export const POST_STUDENT_ACHIEVEMENT_FAIL = 'POST_STUDENT_ACHIEVEMENT_FAIL'
export const GET_ACHIEVEMENT_BY_STUDENT_ID_START =
  'GET_ACHIEVEMENT_BY_STUDENT_ID_START'
export const GET_ACHIEVEMENT_BY_STUDENT_ID = 'GET_ACHIEVEMENT_BY_STUDENT_ID'
export const GET_ACHIEVEMENT_BY_STUDENT_ID_FAIL =
  'GET_ACHIEVEMENT_BY_STUDENT_ID_FAIL'
export const PATCH_ACHIEVEMENT_BY_STUDENT_ID_START =
  'PATCH_ACHIEVEMENT_BY_STUDENT_ID_START'
export const PATCH_ACHIEVEMENT_BY_STUDENT_ID = 'PATCH_ACHIEVEMENT_BY_STUDENT_ID'
export const PATCH_ACHIEVEMENT_BY_STUDENT_ID_FAIL =
  'PATCH_ACHIEVEMENT_BY_STUDENT_ID_FAIL'
export const DELETE_STUDENT_ACHIEVEMENT_START =
  'DELETE_STUDENT_ACHIEVEMENT_START'
export const DELETE_STUDENT_ACHIEVEMENT_FAIL = 'DELETE_STUDENT_ACHIEVEMENT_FAIL'
export const DELETE_STUDENT_ACHIEVEMENT = 'DELETE_STUDENT_ACHIEVEMENT'
export const GET_ACHIEVEMENT_FOR_STUDENT_START =
  'GET_ACHIEVEMENT_FOR_STUDENT_START'
export const GET_ACHIEVEMENT_FOR_STUDENT = 'GET_ACHIEVEMENT_FOR_STUDENT'
export const GET_ACHIEVEMENT_FOR_STUDENT_FAIL =
  'GET_ACHIEVEMENT_FOR_STUDENT_FAIL'

//student document
export const REQUEST_STUDENT_DOC_START = 'REQUEST_STUDENT_DOC_START'
export const REQUEST_STUDENT_DOC = 'REQUEST_STUDENT_DOC'
export const REQUEST_STUDENT_DOC_FAIL = 'REQUEST_STUDENT_DOC_FAIL'
export const GET_STUDENT_DOC_REQUEST_START = 'GET_STUDENT_DOC_REQUEST_START'
export const GET_STUDENT_DOC_REQUEST = 'GET_STUDENT_DOC_REQUEST'
export const GET_STUDENT_DOC_REQUEST_FAIL = 'GET_STUDENT_DOC_REQUEST_FAIL'
export const PATCH_STUDENT_DOC_START = 'PATCH_STUDENT_DOC_START'
export const PATCH_STUDENT_DOC = 'PATCH_STUDENT_DOC'
export const PATCH_STUDENT_DOC_FAIL = 'PATCH_STUDENT_DOC_FAIL'
export const GET_STUDENT_DOC_REQUEST_BY_STUDENT =
  'GET_STUDENT_DOC_REQUEST_BY_STUDENT'
export const GET_STUDENT_DOC_REQUEST_FOR_ME = 'GET_STUDENT_DOC_REQUEST_FOR_ME'
export const UPLOAD_STUDENT_DOC_START = 'UPLOAD_STUDENT_DOC_START'
export const UPLOAD_STUDENT_DOC = 'UPLOAD_STUDENT_DOC'
export const UPLOAD_STUDENT_DOC_FAIL = 'UPLOAD_STUDENT_DOC_FAIL'
export const PATCH_UPLOADED_STUDENT_DOC_START =
  'PATCH_UPLOADED_STUDENT_DOC_START'
export const PATCH_UPLOADED_STUDENT_DOC = 'PATCH_UPLOADED_STUDENT_DOC'
export const PATCH_UPLOADED_STUDENT_DOC_FAIL = 'PATCH_UPLOADED_STUDENT_DOC_FAIL'
export const GET_UPLOADED_STUDENT_DOC_START = 'GET_UPLOADED_STUDENT_DOC_START'
export const GET_UPLOADED_STUDENT_DOC_BY_ID = 'GET_UPLOADED_STUDENT_DOC_BY_ID'
export const GET_UPLOADED_STUDENT_DOC_FOR_ME = 'GET_UPLOADED_STUDENT_DOC_FOR_ME'
export const GET_UPLOADED_STUDENT_DOC_FAIL = 'GET_UPLOADED_STUDENT_DOC_FAIL'
export const GET_STUDENT_DOC_BY_DOC_ID_START = 'GET_STUDENT_DOC_BY_DOC_ID_START'
export const GET_STUDENT_DOC_BY_DOC_ID_SUCCESS =
  'GET_STUDENT_DOC_BY_DOC_ID_SUCCESS'
export const GET_STUDENT_DOC_BY_DOC_ID_FAIL = 'GET_STUDENT_DOC_BY_DOC_ID_FAIL'

//fee types
export const GET_FEE_TYPES_START = 'GET_FEE_TYPES_START'
export const GET_FEE_TYPES = 'GET_FEE_TYPES'
export const GET_FEE_TYPES_FAIL = 'GET_FEE_TYPES_FAIL'
export const POST_FEE_TYPES_START = 'POST_FEE_TYPES_START'
export const POST_FEE_TYPES = 'POST_FEE_TYPES'
export const POST_FEE_TYPES_FAIL = 'POST_FEE_TYPES_FAIL'
export const PATCH_FEE_TYPES_START = 'PATCH_FEE_TYPES_START'
export const PATCH_FEE_TYPES = 'PATCH_FEE_TYPES'
export const PATCH_FEE_TYPES_FAIL = 'PATCH_FEE_TYPES_FAIL'
export const DELETE_FEE_TYPES_START = 'DELETE_FEE_TYPES_START'
export const DELETE_FEE_TYPES = 'DELETE_FEE_TYPES'
export const DELETE_FEE_TYPES_FAIL = 'DELETE_FEE_TYPES_FAIL'

//fine
export const POST_FINE_TEMPLATE_START = 'POST_FINE_TEMPLATE_START'
export const POST_FINE_TEMPLATE_SUCCESS = 'POST_FINE_TEMPLATE_SUCCESS'
export const POST_FINE_TEMPLATE_FAIL = 'POST_FINE_TEMPLATE_FAIL'
export const GET_FINE_TEMPLATE_START = 'GET_FINE_TEMPLATE_START'
export const GET_FINE_TEMPLATE_SUCCESS = 'GET_FINE_TEMPLATE_SUCCESS'
export const GET_FINE_TEMPLATE_FAIL = 'GET_FINE_TEMPLATE_FAIL'
export const PATCH_FINE_TEMPLATE_START = 'PATCH_FINE_TEMPLATE_START'
export const PATCH_FINE_TEMPLATE_SUCCESS = 'PATCH_FINE_TEMPLATE_SUCCESS'
export const PATCH_FINE_TEMPLATE_FAIL = 'PATCH_FINE_TEMPLATE_FAIL'

//challan
export const POST_CHALLAN_GENERATE_START = 'POST_CHALLAN_GENERATE_START'
export const POST_CHALLAN_GENERATE_SUCCESS = 'POST_CHALLAN_GENERATE_SUCCESS'
export const POST_CHALLAN_GENERATE_FAIL = 'POST_CHALLAN_GENERATE_FAIL'
export const GET_CHALLAN_START = 'GET_CHALLAN_START'
export const GET_CHALLAN_SUCCESS = 'GET_CHALLAN_SUCCESS'
export const GET_CHALLAN_FAIL = 'GET_CHALLAN_FAIL'

//lesson plan
export const GET_LESSON_PLAN_BY_TEACHER_START =
  'GET_LESSON_PLAN_BY_TEACHER_START'
export const GET_LESSON_PLAN_BY_TEACHER = 'GET_LESSON_PLAN_BY_TEACHER'
export const GET_LESSON_PLAN_BY_TEACHER_FAIL = 'GET_LESSON_PLAN_BY_TEACHER_FAIL'
export const POST_LESSON_PLAN_START = 'POST_LESSON_PLAN_START'
export const POST_LESSON_PLAN = 'POST_LESSON_PLAN'
export const POST_LESSON_PLAN_FAIL = 'POST_LESSON_PLAN_FAIL'
export const GET_LESSON_PLAN_BY_ID_START = 'GET_LESSON_PLAN_BY_ID_START'
export const GET_LESSON_PLAN_BY_ID = 'GET_LESSON_PLAN_BY_ID'
export const GET_LESSON_PLAN_BY_ID_FAIL = 'GET_LESSON_PLAN_BY_ID_FAIL'
export const PATCH_LESSON_PLAN_START = 'PATCH_LESSON_PLAN_START'
export const PATCH_LESSON_PLAN = 'PATCH_LESSON_PLAN'
export const PATCH_LESSON_PLAN_FAIL = 'PATCH_LESSON_PLAN_FAIL'
export const DELETE_LESSON_PLAN_START = 'DELETE_LESSON_PLAN_START'
export const DELETE_LESSON_PLAN = 'DELETE_LESSON_PLAN'
export const DELETE_LESSON_PLAN_FAIL = 'DELETE_LESSON_PLAN_FAIL'

// institution wise certificates for teacher and teachers
export const GET_STUDENT_CERTI_BY_INSTITUTION_ID_START =
  'GET_STUDENT_CERTI_BY_INSTITUTION_ID_START'
export const GET_STUDENT_CERTI_BY_INSTITUTION_ID =
  'GET_STUDENT_CERTI_BY_INSTITUTION_ID'
export const GET_STUDENT_CERTI_BY_INSTITUTION_ID_FAIL =
  'GET_STUDENT_CERTI_BY_INSTITUTION_ID_FAIL'
export const GET_TEACHER_CERTI_BY_INSTITUTION_ID_START =
  'GET_TEACHER_CERTI_BY_INSTITUTION_ID_START'
export const GET_TEACHER_CERTI_BY_INSTITUTION_ID =
  'GET_TEACHER_CERTI_BY_INSTITUTION_ID'
export const GET_TEACHER_CERTI_BY_INSTITUTION_ID_FAIL =
  'GET_TEACHER_CERTI_BY_INSTITUTION_ID_FAIL'
export const POST_CERTI_START = 'POST_CERTI_START'
export const POST_CERTI = 'POST_CERTI'
export const POST_CERTI_FAIL = 'POST_CERTI_FAIL'
export const PATCH_CERTI_BY_INSTITUTION_ID_START =
  'PATCH_CERTI_BY_INSTITUTION_ID_START'
export const PATCH_CERTI_BY_INSTITUTION_ID = 'PATCH_CERTI_BY_INSTITUTION_ID'
export const PATCH_CERTI_BY_INSTITUTION_ID_FAIL =
  'PATCH_CERTI_BY_INSTITUTION_ID_FAIL'
export const DELETE_CERTI_START = 'DELETE_CERTI_START'
export const DELETE_CERTI = 'DELETE_CERTI'
export const DELETE_CERTI_FAIL = 'DELETE_CERTI_FAIL'

//notes
export const POST_NOTES_START = 'POST_NOTES_START'
export const POST_NOTES_SUCCESS = 'POST_NOTES_SUCCESS'
export const POST_NOTES_FAIL = 'POST_NOTES_FAIL'
export const GET_NOTES_BY_ID_START = 'GET_NOTES_BY_ID_START'
export const GET_NOTES_BY_ID_SUCCESS = 'GET_NOTES_BY_ID_SUCCESS'
export const GET_NOTES_BY_ID_FAIL = 'GET_NOTES_BY_ID_FAIL'
export const GET_NOTES_BY_GROUP_START = 'GET_NOTES_BY_GROUP_START'
export const GET_NOTES_BY_GROUP_SUCCESS = 'GET_NOTES_BY_GROUP_SUCCESS'
export const GET_NOTES_BY_GROUP_FAIL = 'GET_NOTES_BY_GROUP_FAIL'
export const PATCH_NOTES_BY_ID_START = 'PATCH_NOTES_BY_ID_START'
export const PATCH_NOTES_BY_ID_SUCCESS = 'PATCH_NOTES_BY_ID_SUCCESS'
export const PATCH_NOTES_BY_ID_FAIL = 'PATCH_NOTES_BY_ID_FAIL'
export const DELETE_NOTES_BY_ID_START = 'DELETE_NOTES_BY_ID_START'
export const DELETE_NOTES_BY_ID_SUCCESS = 'DELETE_NOTES_BY_ID_SUCCESS'
export const DELETE_NOTES_BY_ID_FAIL = 'DELETE_NOTES_BY_ID_FAIL'

//student house count
export const GET_STUDENT_HOUSE_COUNT_START = 'GET_STUDENT_HOUSE_COUNT_START'
export const GET_STUDENT_HOUSE_COUNT_SUCCESS = 'GET_STUDENT_HOUSE_COUNT_SUCCESS'
export const GET_STUDENT_HOUSE_COUNT_FAIL = 'GET_STUDENT_HOUSE_COUNT_FAIL'

//parent user list
export const GET_PARENT_USERS_START = 'GET_PARENT_USERS_START'
export const GET_PARENT_USERS_SUCCESS = 'GET_PARENT_USERS_SUCCESS'
export const GET_PARENT_USERS_FAIL = 'GET_PARENT_USERS_FAIL'
export const PATCH_PARENT_USERS_START = 'PATCH_PARENT_USERS_START'
export const PATCH_PARENT_USERS_SUCCESS = 'PATCH_PARENT_USERS_SUCCESS'
export const PATCH_PARENT_USERS_FAIL = 'PATCH_PARENT_USERS_FAIL'

//Exam timetable for studentLogin
export const GET_STUDENT_EXAM_TIMETABLE_START =
  'GET_STUDENT_EXAM_TIMETABLE_START'
export const GET_STUDENT_EXAM_TIMETABLE_SUCCESS =
  'GET_STUDENT_EXAM_TIMETABLE_SUCCESS'
export const GET_STUDENT_EXAM_TIMETABLE_FAIL = 'GET_STUDENT_EXAM_TIMETABLE_FAIL'

//Minutes of meeting

export const GET_MOM = 'GET_MOM'
export const GET_MOM_START = 'GET_MOM_START'
export const GET_MOM_FAILED = 'GET_MOM_FAILED'

export const POST_MOM = 'POST_MOM'
export const POST_MOM_START = 'POST_MOM_START'
export const POST_MOM_FAILED = 'POST_MOM_FAILED'

export const PATCH_MOM = 'PATCH_MOM'
export const PATCH_MOM_START = 'PATCH_MOM_START'
export const PATCH_MOM_FAILED = 'PATCH_MOM_FAILED'

export const REMOVE_MOM = 'REMOVE_MOM'
export const REMOVE_MOM_START = 'REMOVE_MOM_START'
export const REMOVE_MOM_FAILED = 'REMOVE_MOM_FAILED'

//query ticketing system
export const CREATE_TICKET = 'CREATE_TICKET'
export const CREATE_TICKET_START = 'CREATE_TICKET_START'
export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED'

export const GET_TICKET = 'GET_TICKET'
export const GET_TICKET_START = 'GET_TICKET_START'
export const GET_TICKET_FAILED = 'GET_TICKET_FAILED'

export const PATCH_TICKET = 'PATCH_TICKET'
export const PATCH_TICKET_START = 'PATCH_TICKET_START'
export const PATCH_TICKET_FAILED = 'PATCH_TICKET_FAILED'

export const REMOVE_TICKET = 'REMOVE_TICKET'
export const REMOVE_TICKET_START = 'REMOVE_TICKET_START'
export const REMOVE_TICKET_FAILED = 'REMOVE_TICKET_FAILED'

export const GET_CHATS = 'GET_CHATS'
export const GET_CHATS_START = 'GET_CHATS_START'
export const GET_CHATS_FAILED = 'GET_CHATS_FAILED'

export const POST_CHAT = 'POST_CHAT'
export const POST_CHAT_START = 'POST_CHAT_START'
export const POST_CHAT_FAILED = 'POST_CHAT_FAILED'

export const PATCH_CHAT = 'PATCH_CHAT'
export const PATCH_CHAT_START = 'PATCH_CHAT_START'
export const PATCH_CHAT_FAILED = 'PATCH_CHAT_FAILED'
/*Transportation */

//vehicle
export const ADD_VEHICLE = 'ADD_VEHICLE'
export const ADD_VEHICLE_START = 'ADD_VEHICLE_START'
export const ADD_VEHICLE_FAIL = 'ADD_VEHICLE_FAIL'
export const GET_VEHICLE = 'GET_VEHICLE'
export const GET_VEHICLE_START = 'GET_VEHICLE_START'
export const GET_VEHICLE_FAIL = 'GET_VEHICLE_FAIL'
export const PATCH_VEHICLE_START = 'PATCH_VEHICLE_START'
export const PATCH_VEHICLE_SUCCESS = 'PATCH_VEHICLE_SUCCESS'
export const PATCH_VEHICLE_FAIL = 'PATCH_VEHICLE_FAIL'

//driver
export const ADD_DRIVER = 'ADD_DRIVER'
export const ADD_DRIVER_START = 'ADD_DRIVER_START'
export const ADD_DRIVER_FAIL = 'ADD_DRIVER_FAIL'
export const GET_DRIVER = 'GET_DRIVER'
export const GET_DRIVER_BY_ID = 'GET_DRIVER_BY_ID'
export const GET_DRIVER_START = 'GET_DRIVER_START'
export const GET_DRIVER_FAIL = 'GET_DRIVER_FAIl'
export const PATCH_DRIVER_START = 'PATCH_DRIVER_START'
export const PATCH_DRIVER = 'PATCH_DRIVER'
export const PATCH_DRIVER_FAIL = 'PATCH_DRIVER_FAIL'

//route
export const ADD_ROUTE = 'ADD_ROUTE'
export const ADD_ROUTE_START = 'ADD_ROUTE_START'
export const ADD_ROUTE_FAIL = 'ADD_ROUTE_FAIL'
export const GET_ROUTE = 'GET_ROUTE'
export const GET_ROUTE_START = 'GET_ROUTE_START'
export const GET_ROUTE_FAIL = 'GET_ROUTE_FAIL'
export const GET_ROUTE_BY_ID = 'GET_ROUTE_BY_ID'
export const PATCH_ROUTE = 'PATCH_ROUTE'
export const PATCH_ROUTE_START = 'PATCH_ROUTE_START'
export const PATCH_ROUTE_FAIL = 'PATCH_ROUTE_FAIL'

//stops
export const ADD_STOP = 'ADD_STOP'
export const ADD_STOP_START = 'ADD_STOP_START'
export const ADD_STOP_FAIL = 'ADD_STOP_FAIL'
export const PATCH_STOP = 'PATCH_STOP'
export const PATCH_STOP_START = 'PATCH_STOP_START'
export const PATCH_STOP_FAIL = 'PATCH_STOP_FAIL'
export const ASSIGN_STOP_START = 'ASSIGN_STOP_START'
export const ASSIGN_STOP_SUCCESS = 'ASSIGN_STOP_SUCCESS'
export const ASSIGN_STOP_FAIL = 'ASSIGN_STOP_FAIL'
export const UNASSIGN_STOP_START = 'UNASSIGN_STOP_START'
export const UNASSIGN_STOP_SUCCESS = 'UNASSIGN_STOP_SUCCESS'
export const UNASSIGN_STOP_FAIL = 'UNASSIGN_STOP_FAIL'

//get deleted fees
export const GET_DELETED_FEES_START = 'GET_DELETED_FEES_START'
export const GET_DELETED_FEES_FAILED = 'GET_DELETED_FEES_FAILED'
export const GET_DELETED_FEES_SUCCESS = 'GET_DELETED_FEES_SUCCESS'

//get all student Fee report for csv
export const GET_FEE_REPORT_ALL_START = 'GET_FEE_REPORT_ALL_START'
export const GET_FEE_REPORT_ALL_SUCCESS = 'GET_FEE_REPORT_ALL_SUCCESS'
export const GET_FEE_REPORT_ALL_FAILED = 'GET_FEE_REPORT_ALL_FAILED'

//post lesson plan chapters
export const POST_LESSON_CHAPTER_START = 'POST_LESSON_CHAPTER_START'
export const POST_LESSON_CHAPTER_FAILED = 'POST_LESSON_CHAPTER_FAILED'
export const POST_LESSON_CHAPTER_SUCCESS = 'POST_LESSON_CHAPTER_SUCCESS'

//get lesson plan chapters
export const GET_LESSON_CHAPTER_START = 'GET_LESSON_CHAPTER_START'
export const GET_LESSON_CHAPTER_FAILED = 'GET_LESSON_CHAPTER_FAILED'
export const GET_LESSON_CHAPTER_SUCCESS = 'GET_LESSON_CHAPTER_SUCCESS'

//patch lesson plan chapters
export const PATCH_LESSON_CHAPTER_START = 'PATCH_LESSON_CHAPTER_START'
export const PATCH_LESSON_CHAPTER_FAILED = 'PATCH_LESSON_CHAPTER_FAILED'
export const PATCH_LESSON_CHAPTER_SUCCESS = 'PATCH_LESSON_CHAPTER_SUCCESS'

//fetch subjects for HOD
export const GET_HOD_SUBJECTS_START = 'GET_HOD_SUBJECTS_START'
export const GET_HOD_SUBJECTS_SUCCESS = 'GET_HOD_SUBJECTS_SUCCESS'
export const GET_HOD_SUBJECTS_FAILED = 'GET_HOD_SUBJECTS_FAILED'

//lesson plan chapters
export const GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_START =
  'GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_START'
export const GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_SUCCESS =
  'GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_SUCCESS'
export const GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_FAIL =
  'GET_LESSON_PLAN_CHAPTER_PER_SUBJECT_FAIL'
export const GET_LESSON_PLAN_FOR_HOD_START = 'GET_LESSON_PLAN_FOR_HOD_START'
export const GET_LESSON_PLAN_FOR_HOD_SUCCESS = 'GET_LESSON_PLAN_FOR_HOD_SUCCESS'
export const GET_LESSON_PLAN_FOR_HOD_FAIL = 'GET_LESSON_PLAN_FOR_HOD_FAIL'
export const LESSON_PLAN_VERIFY_START = 'LESSON_PLAN_VERIFY_START'
export const LESSON_PLAN_VERIFY_SUCCESS = 'LESSON_PLAN_VERIFY_SUCCESS'
export const LESSON_PLAN_VERIFY_FAIL = 'LESSON_PLAN_VERIFY_FAIL'
export const LESSON_PLAN_APPROVAL_START = 'LESSON_PLAN_APPROVAL_START'
export const LESSON_PLAN_APPROVAL_SUCCESS = 'LESSON_PLAN_APPROVAL_SUCCESS'
export const LESSON_PLAN_APPROVAL_FAIL = 'LESSON_PLAN_APPROVAL_FAIL'
export const GET_LESSON_PLAN_BY_DATES_START = 'GET_LESSON_PLAN_BY_DATES_START'
export const GET_LESSON_PLAN_BY_DATES_SUCCESS =
  'GET_LESSON_PLAN_BY_DATES_SUCCESS'
export const GET_LESSON_PLAN_BY_DATES_FAIL = 'GET_LESSON_PLAN_BY_DATES_FAIL'

//notifications
export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL'
export const REMOVE_NOTIFICATIONS_START = 'REMOVE_NOTIFICATIONS_START'
export const REMOVE_NOTIFICATIONS_SUCCESS = 'REMOVE_NOTIFICATIONS_SUCCESS'
export const REMOVE_NOTIFICATIONS_FAIL = 'REMOVE_NOTIFICATIONS_FAIL'
export const SAVE_CONCESSION_NOTIFICATION_DATA =
  'SAVE_CONCESSION_NOTIFICATION_DATA'

//biometric
export const ADD_NEW_DEVICE = 'ADD_NEW_DEVICE'
export const ADD_NEW_DEVICE_START = 'ADD_NEW_DEVICE_START'
export const ADD_NEW_DEVICE_FAILED = 'ADD_NEW_DEVICE_FAILED'
export const GET_ALL_DEVICES = 'GET_ALL_DEVICES'
export const GET_ALL_DEVICES_START = 'GET_ALL_DEVICES_START'
export const GET_ALL_DEVICES_FAILED = 'GET_ALL_DEVICES_FAILED'
export const ASSIGN_TEACHER_FINGERPRINT = 'ASSIGN_TEACHER_FINGERPRINT'
export const ASSIGN_TEACHER_FINGERPRINT_START =
  'ASSIGN_TEACHER_FINGERPRINT_START'
export const ASSIGN_TEACHER_FINGERPRINT_FAILED =
  'ASSIGN_TEACHER_FINGERPRINT_FAILED'
export const GET_FINGERPRINT = 'GET_FINGERPRINT'
export const GET_FINGERPRINT_START = 'GET_FINGERPRINT_START'
export const GET_FINGERPRINT_FAILED = 'GET_FINGERPRINT_FAILED'

// month-wise fee report
export const GET_MONTHWISE_FEE_REPORT_START = 'GET_MONTHWISE_FEE_REPORT_START'
export const GET_MONTHWISE_FEE_REPORT = 'GET_MONTHWISE_FEE_REPORT'
export const GET_MONTHWISE_FEE_REPORT_FAILED = 'GET_MONTHWISE_FEE_REPORT_FAILED'

//teacher document
export const POST_TEACHER_DOC_START = 'POST_TEACHER_DOC_START'
export const POST_TEACHER_DOC_SUCCESS = 'POST_TEACHER_DOC_SUCCESS'
export const POST_TEACHER_DOC_FAIL = 'POST_TEACHER_DOC_FAIL'
export const GET_TEACHER_DOC_START = 'GET_TEACHER_DOC_START'
export const GET_TEACHER_DOC_SUCCESS = 'GET_TEACHER_DOC_SUCCESS'
export const GET_TEACHER_DOC_FAIL = 'GET_TEACHER_DOC_FAIL'
export const PATCH_TEACHER_DOC_START = 'PATCH_TEACHER_DOC_START'
export const PATCH_TEACHER_DOC_SUCCESS = 'PATCH_TEACHER_DOC_SUCCESS'
export const PATCH_TEACHER_DOC_FAIL = 'PATCH_TEACHER_DOC_FAIL'
