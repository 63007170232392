// import packages
import React from 'react'

//import components
import RenderGroups from './renderGroups'
import RenderDepartments from './renderDepartments'
import Spinner from '../spinner'

const GroupSelectionComponent = ({
  allGroups,
  departments,
  groupIsLoading,
  teacherIsLoading,
  navigateBack,
  handleSubmitGroupSelect,
  handleUmbrellaGroupChange,
  handleGroupChange,
  handleSelectAllChange,
  handleAllDepartmentChange,
  handleDepartmentChange,
  getSelectedDepartments,
  calculateClasses,
  calculateSections,
  calculateDepartments,
  groups,
  selectAll,
  message,
  selectedDepartments
}) => {
  if (groupIsLoading || teacherIsLoading) {
    return <Spinner />
  }
  return (
    groups &&
    Object.keys(groups).length !== 0 && (
      <div>
        <div className="group-selection-form-ctr">
          <div className="group-selection-inner-ctr">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-9 form-left">
                  <div className="row">
                    <div className="space-creator" />
                    <div className="col-xs-12">
                      <div className="group">
                        <label className="group-select group-select--all">
                          <input
                            type="checkbox"
                            name="selectAll"
                            onChange={handleSelectAllChange}
                            checked={selectAll}
                          />
                          <span className="square">
                            <span className="tick" />
                          </span>
                          <span className="text">Select All</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <RenderDepartments
                        departments={departments}
                        selectedDepartments={selectedDepartments}
                        handleDepartmentChange={handleDepartmentChange}
                        handleAllDepartmentChange={handleAllDepartmentChange}
                      />
                    </div>
                    <div className="col-xs-12">
                      <RenderGroups
                        //props
                        groups={allGroups}
                        message={message}
                        //computed props
                        classes={calculateClasses()}
                        sections={calculateSections()}
                        departmentCount={calculateDepartments()}
                        departments={getSelectedDepartments(
                          selectedDepartments
                        )}
                        //state
                        groupState={groups}
                        //handleChange
                        handleUmbrellaGroupChange={handleUmbrellaGroupChange}
                        handleGroupChange={handleGroupChange}
                      />
                    </div>
                    <div className="field submit-field-no-absolute">
                      <button
                        onClick={navigateBack}
                        className="button button--white"
                      >
                        Go Back
                      </button>
                      <button
                        onClick={() =>
                          handleSubmitGroupSelect({
                            classes: calculateClasses(),
                            sections: calculateSections(),
                            departments: getSelectedDepartments(
                              selectedDepartments
                            ),
                            groups,
                            departmentCount: calculateDepartments()
                          })
                        }
                        className="button button--blue"
                        type="submit"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default GroupSelectionComponent
