import { APP_LOGOUT, SAVE_SELECTED_WORKSHEET } from '../../helpers/actions'

const initialState = {
  selectedWorksheet: {}
}

export const worksheet = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SAVE_SELECTED_WORKSHEET:
      return Object.assign({}, state, {
        selectedWorksheet: action.data
      })
    default:
      return state
  }
}

export const getSelectedWorksheet = state =>
  state.ui.worksheet.selectedWorksheet
