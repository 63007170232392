import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { accounts } from './accounts'
import { groups } from './groups'
import { students } from './students'
import { flattenedStudents } from './flattenedStudents'
import { attendance } from './attendance'
import { tests } from './tests'
import { marks } from './marks'
import { monthOverall } from './monthOverall'
import { monthDetails } from './monthDetails'
import { subjects } from './subjects.js'
import { sms } from './sms'
import { teacher } from './teacher'
import { flattenedTeachers } from './flattenedTeachers'
import { hours } from './hours'
import { timetable } from './timetable'
import { ui } from './ui/index'
import { institution } from './institution'
import { classProfile } from './classProfile'
import { admissionGroups } from './admissionGroups'
import { applications } from './applications'
import { comments } from './comments'
import { appointment } from './appointment'
import { fees } from './fees'
import { fileUpload } from './fileUpload'
import { studentFees } from './studentFees'
import { st_attendance } from './st-attendance'
import { gallery } from './gallery'
import { questionBank } from './questionBank'
import { studentLogin } from './studentLogin'
import { documents } from './documents'
import { reportCard } from './reportCard'
import { performance } from './performance'
import { portion } from './portion'
import { portionFileUpload } from './portionFileUpload'
import EventsReducer from './calendarEventsReducer'
import navlist from './navlist'
import { transferCertificate } from './transferCertificate'
import { conductCertificate } from './conductCertificate'
import { promotionalgroups } from './promotionGroups'
import { homeworkAndAssignment } from './homework-assignment'
import { policy } from './policy'
import { studentremarks } from './student-remarks'
import { cms } from './cms'
import { inventory } from './inventory'
import { studentFeedback } from './studentFeedback'
import { worksheet } from './worksheet'
import { libraryCatlog } from './library/catalog'
import { libraryLendings } from './library/lendings'
import { libraryUsers } from './library/users'
import { libraryChallans } from './library/challan'
import { leavePolicy } from './leavePolicy'
import { leaveApproval } from './leaveApproval'
import { holiday } from './holidayPolicy'
import { registrationCertificate } from './registrationCertificate'
import { idCards } from './idCards'
import { payslip } from './payslip'
import { teacherLeavePolicy } from './teacherLeavePolicy'
import { teacherAttendance } from './teacherAttendance'
import { teacherLeave } from './teacherLeave'
import { payrollPolicy } from './payrollPolicy'
import { financialDetails } from './financialDetails'
import { hallticket } from './hallticket'
import { smsTemplate } from './smsTemplate'
import { forgotPassword } from './forgotPassword'
import { mail } from './mail'
import { achievements } from './achievements'
import { navigation } from './navigation'
import { studentDocument } from './studentDocument'
import { feeTypes } from './feeTypes'
import { challan } from './challan'
import { fineTemplate } from './fineTemplate'
import { lessonPlan } from './lessonPlan'
import { studentTeacherCertificate } from './studentTeacherCertificate.js'
import { notes } from './notes'
import { parent } from './parent.js'
import { mom } from './mom.js'
import { ticket } from './queryTicketing.js'
import { teacherDocument } from './teacherDocument.js'

//transportation
import { transportVehicle } from './transportation/vehicle'
import { transportDriver } from './transportation/driver'
import { transportRoute } from './transportation/route'
import { transportStop } from './transportation/stop'
import { notifications } from './notifications.js'

//biometric
import { biometric } from './biometric.js'

const reports = combineReducers({
  monthOverall,
  monthDetails
})

export const RootReducer = combineReducers({
  accounts,
  groups,
  students,
  flattenedStudents,
  attendance,
  form: formReducer,
  marks,
  tests,
  reports,
  subjects,
  sms,
  teacher,
  flattenedTeachers,
  hours,
  timetable,
  ui,
  institution,
  classProfile,
  admissionGroups,
  comments,
  applications,
  appointment,
  fees,
  fileUpload,
  studentFees,
  st_attendance,
  gallery,
  questionBank,
  studentLogin,
  documents,
  reportCard,
  performance,
  portion,
  portionFileUpload,
  events: EventsReducer,
  navlist,
  transferCertificate,
  conductCertificate,
  promotionalgroups,
  homeworkAndAssignment,
  policy,
  studentremarks,
  cms,
  inventory,
  studentFeedback,
  worksheet,
  libraryCatlog,
  libraryLendings,
  libraryUsers,
  libraryChallans,
  registrationCertificate,
  leavePolicy,
  leaveApproval,
  holiday,
  idCards,
  payslip,
  teacherLeavePolicy,
  teacherAttendance,
  teacherLeave,
  payrollPolicy,
  financialDetails,
  hallticket,
  smsTemplate,
  forgotPassword,
  mail,
  achievements,
  navigation,
  studentDocument,
  feeTypes,
  challan,
  fineTemplate,
  lessonPlan,
  studentTeacherCertificate,
  notes,
  parent,
  mom,
  ticket,
  notifications,
  teacherDocument,
  // transportation
  transportVehicle,
  transportDriver,
  transportRoute,
  transportStop,
  biometric
})
