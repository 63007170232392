import {
  GET_MOM,
  GET_MOM_START,
  GET_MOM_FAILED,
  POST_MOM,
  POST_MOM_START,
  POST_MOM_FAILED,
  PATCH_MOM,
  PATCH_MOM_START,
  PATCH_MOM_FAILED,
  REMOVE_MOM,
  REMOVE_MOM_START,
  REMOVE_MOM_FAILED,
  APP_LOGOUT
} from '../helpers/actions'

import { createSelector } from 'reselect'

const initialState = {
  isLoading: false,
  byId: {},
  isErr: false,
  errMsg: '',
  allMom: [],
  status: ''
}
export const mom = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_MOM_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case POST_MOM:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'post'
      })
    case POST_MOM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case GET_MOM_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case GET_MOM:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        allMom: action.data
      })
    case GET_MOM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case PATCH_MOM_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case PATCH_MOM:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false
      })
    case PATCH_MOM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case REMOVE_MOM_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case REMOVE_MOM:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'delete'
      })
    case REMOVE_MOM_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    default:
      return state
  }
}

export const getIsLoadingMom = state => state.mom.isLoading
export const getIsErrMom = state => state.mom.isErr
export const getErrMsgMom = state => state.mom.errMsg
export const getMomArr = state => state.mom.allMom
export const getMomStatus = state => state.mom.status
