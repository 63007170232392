import { createSelector } from 'reselect'
import underscore from 'underscore'

import {
  APP_LOGOUT,
  GROUPS_GROUP_DATA,
  UMBRELLA_GROUPS_NAME,
  ADD_GROUP_SUCCESS,
  START_FETCH_GROUPS,
  GROUPS_PATCH_START,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  UPDATE_GROUP_DATA,
  PATCH_TEACHER_PER_SUBJECT,
  START_ADD_OR_REMOVE_STUDENTS,
  ADD_STUDENTS_TO_ELECTIVES_SUCCESS,
  ADD_OR_REMOVE_STUDENTS_FAIL,
  REMOVE_STUDENTS_FROM_ELECTIVES_SUCCESS,
  ADD_STUDENT_TO_GROUP,
  STUDENTS_PATCH_START,
  STUDENTS_PATCH_SUCCESS,
  STUDENTS_PATCH_FAIL,
  DELETE_CLASS_SUCCESS,
  DELETE_SECTION_SUCCESS
} from '../helpers/actions'

import { GROUPS_GROUP_TYPE } from '../helpers/enums'

import { getLevelId, getLevel } from './accounts'

const initialState = {
  isLoading: false,
  isWorking: false, // post / patch actions in progress
  byId: {},
  allIds: [],
  umbrella_groups_name: [],
  nextAcademicYear: [],
  isSuccess: '',
  postInProgress: false,
  groupType: ''
}

export const groups = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_FETCH_GROUPS:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: ''
      })
    case GROUPS_GROUP_DATA:
      if (!action.groups || !action.result) return state
      const { groups, result, groupType } = action
      return Object.assign({}, state, {
        isLoading: false,
        byId: groups,
        allIds: result,
        isSuccess: 'fetched',
        groupType: groupType
      })
    case UMBRELLA_GROUPS_NAME:
      if (action.result) {
        return Object.assign({}, state, {
          isLoading: false,
          umbrella_groups_name: action.result
        })
      }
      break
    case ADD_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case ADD_STUDENT_TO_GROUP:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        }
      })
    case GROUPS_PATCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: ''
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isSuccess: 'success',
        isLoading: false
      })
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        isSuccess: 'fail',
        isLoading: false
      })
    case UPDATE_GROUP_DATA: {
      const byId = state.byId
      const group = action.group
      return Object.assign({}, state, {
        byId: {
          ...byId,
          [group._id]: group
        },
        isSuccess: 'success',
        isLoading: false
      })
    }
    case PATCH_TEACHER_PER_SUBJECT:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        isSuccess: 'success',
        isLoading: false
      })
    case START_ADD_OR_REMOVE_STUDENTS:
      return Object.assign({}, state, {
        postInProgress: true,
        isSuccess: ''
      })
    case ADD_STUDENTS_TO_ELECTIVES_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        postInProgress: false,
        isSuccess: 'success'
      })
    case REMOVE_STUDENTS_FROM_ELECTIVES_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        postInProgress: false,
        isSuccess: 'success'
      })
    case ADD_OR_REMOVE_STUDENTS_FAIL:
      return Object.assign({}, state, {
        postInProgress: false,
        isSuccess: ''
      })
    case STUDENTS_PATCH_START:
      return Object.assign({}, state, {
        isWorking: true
      })
    case STUDENTS_PATCH_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        isWorking: false
      })
    case STUDENTS_PATCH_FAIL:
      return Object.assign({}, state, {
        isWorking: false
      })
    case DELETE_CLASS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: 'success'
      })
    case DELETE_SECTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: 'success'
      })
    // case PROMOTION_ACADEMIC_YEAR_DATA:
    //   return  Object.assign({}, state, {
    //     isLoading: false,
    //     nextAcademicYear:action.data
    //   })
    default:
      return Object.assign({}, state, {})
  }
}

export const groupsById = state => state.groups.byId

export const isLoading = state => state.groups.isLoading

export const isWorking = state => state.groups.isWorking

export const getAllIds = state => state.groups.allIds

export const getUmbrellaGroupNames = state => state.groups.umbrella_groups_name

export const getIsSuccess = state => state.groups.isSuccess
//export const getPromotionalAcademicYear = state => state.groups.nextAcademicYear

export const getPostInProgress = state => state.groups.postInProgress
export const getGroupType = state => state.groups.groupType

export const getGroupsForSms = createSelector(
  groupsById,
  getAllIds,
  (byId, allIds) => {
    const groupObj = {}
    if (!byId || !allIds) return {}
    allIds.forEach(id => {
      if (!groupObj[byId[id].umbrella_group]) {
        groupObj[byId[id].umbrella_group] = []
        groupObj[byId[id].umbrella_group].push(byId[id])
      } else {
        groupObj[byId[id].umbrella_group].push(byId[id])
      }
    })
    return groupObj
  }
)

export const groupsForApproveSms = createSelector(getGroupsForSms, groups => {
  const groupObj = {}
  Object.keys(groups).forEach(umbrellaGroup => {
    groupObj[umbrellaGroup] = []
    groups[umbrellaGroup].forEach(obj => {
      groupObj[umbrellaGroup].push(obj._id)
    })
  })
  return groupObj
})

export const getAcademicGroups = createSelector(
  groupsById,
  getAllIds,
  getGroupType,
  (byId, groups, groupType) => {
    return groups
      .filter(group => {
        const { group_type, archive } = byId[group]
        let type = groupType ? groupType : GROUPS_GROUP_TYPE[0]
        if (type === group_type && false === archive) return true
        return false
      })
      .sort((a, b) => {
        const {
          orderUmbrella_group: orderUmbrella_groupOfA,
          orderGroup: orderGroupOfA
        } = Object.assign({ orderUmbrella_group: 0, orderGroup: 0 }, byId[a])
        const {
          orderUmbrella_group: orderUmbrella_groupOfB,
          orderGroup: orderGroupOfB
        } = Object.assign({ orderUmbrella_group: 0, orderGroup: 0 }, byId[b])
        const aValue = orderUmbrella_groupOfA * 10 + orderGroupOfA
        const bValue = orderUmbrella_groupOfB * 10 + orderGroupOfB
        return aValue - bValue
      })
      .map(groupId => {
        const {
          group_name,
          umbrella_group,
          group_type,
          subjects,
          students,
          tests,
          class_teacher,
          orderUmbrella_group,
          orderGroup,
          tcTemplate,
          subGroup,
          attendance_type
        } = Object.assign(
          {
            group_name: '',
            umbrella_group: '',
            group_type: '',
            subjects: [],
            students: [],
            tests: [],
            class_teacher: '',
            orderUmbrella_group: '',
            orderGroup: '',
            tcTemplate: [],
            subGroup: '',
            attendance_type: ''
          },
          byId[groupId]
        )
        return {
          id: groupId,
          _id: groupId,
          // group_name,
          parent_group: umbrella_group,
          // group_type,
          // subjects,
          // students,
          // tests,
          // class_teacher,
          // orderUmbrella_group,
          // orderGroup,
          // subGroup,
          // tcTemplate,
          // attendance_type,
          ...byId[groupId]
        }
      })
  }
)

export const getAcademicGrades = createSelector(getAcademicGroups, sections => {
  return underscore.uniq(sections.map(section => section.parent_group))
})

export const getAllStudentIds = createSelector(getAcademicGroups, groups => {
  const studenstArr = groups.map(groupObj => groupObj.students)
  return [].concat.apply([], studenstArr)
})

export const getAcademicAllDeets = createSelector(
  groupsById,
  getAllIds,
  (byId, groups) => {
    return groups
      .filter(group => {
        const { group_type, archive } = byId[group]
        if (GROUPS_GROUP_TYPE[0] === group_type && false === archive)
          return true
        return false
      })
      .map(group => byId[group])
  }
)

export const getAllTests = createSelector(
  groupsById,
  getAllIds,
  (byId, groups) => {
    return groups
      .map(groupId => byId[groupId].tests)
      .reduce((flat, flatten) => flat.concat(flatten), [])
  }
)

export const getTeacherGroupsById = createSelector(
  getLevelId,
  getLevel,
  groupsById,
  getAllIds,
  (levelId, level, groupsById, allGroupIds) => {
    if (level !== 'teacher' || allGroupIds.length === 0) return {}
    const obj = {}
    let classTeacherGroup = {}
    let groupId = ''
    groupsById &&
      Object.keys(groupsById)?.length > 0 &&
      Object.keys(groupsById)?.map(grp => {
        if (groupsById[grp]?.class_teacher === levelId) {
          groupId = grp
        }
      })
    if (groupId && groupId !== '') {
      classTeacherGroup[groupId] = groupsById[groupId]
    }
    allGroupIds.forEach(groupId => {
      groupsById[groupId].subjects.forEach(subObj => {
        if (subObj.teacher_id.indexOf(levelId) !== -1) {
          obj[groupId] = groupsById[groupId]
          return false
        }
      })
    })
    let finalObj = { ...obj, ...classTeacherGroup }
    return finalObj
  }
)

export const getAcademicTeacherGroups = createSelector(
  getTeacherGroupsById,
  byId => {
    if (Object.keys(byId).length === 0) return []
    return Object.keys(byId)
      .filter(groupId => {
        if (
          byId[groupId].group_type === 'academics' &&
          byId[groupId].archive === false
        )
          return true
        return false
      })
      .map(groupId => {
        const {
          _id,
          group_name,
          umbrella_group,
          group_type,
          subjects,
          students,
          tests,
          orderUmbrella_group,
          orderGroup,
          academic_year
        } = byId[groupId]
        return {
          // _id,
          // group_name,
          parent_group: umbrella_group,
          // group_type,
          // subjects,
          // students,
          // tests,
          // orderUmbrella_group,
          // orderGroup,
          // academic_year
          ...byId[groupId]
        }
      })
  }
)

export const getTeacherSubjects = createSelector(
  getAcademicTeacherGroups,
  getLevelId,
  (groupsArr, teacherId) => {
    if (groupsArr.length === 0) return []
    let subjectIds = []
    groupsArr.forEach(groupObj => {
      const subjects = groupObj.subjects
        .filter(subjectObj => {
          if (subjectObj.teacher_id.indexOf(teacherId) !== -1) return true
          return false
        })
        .map(subjectObj => subjectObj.subject_id)
      subjectIds = subjectIds.concat(subjects)
    })
    return underscore.uniq(subjectIds)
  }
)

// get teacherSubjectIds, send teacherId from the component
export const getTeacherSubjectIds = (state, teacherId) => {
  const academicGroupsArr = getAcademicGroups(state)
  if (academicGroupsArr.length === 0) return []
  let subjectIds = []
  academicGroupsArr.forEach(groupObj => {
    const subjects = groupObj.subjects
      .filter(subjectObj => {
        if (subjectObj.teacher_id.indexOf(teacherId) !== -1) return true
        return false
      })
      .map(subjectObj => subjectObj.subject_id)
    subjectIds = subjectIds.concat(subjects)
  })
  return underscore.uniq(subjectIds)
}

// get section name for a groupId
export const getSectionNameByGroupId = (state, groupId) => {
  const groupsById = state.groups.byId
  if (!groupId || Object.keys(groupsById).length === 0) return ''
  if (!groupsById[groupId]) return ''
  return groupsById[groupId].group_name
}

// get student ids of a group
export const getStudentIdsOfAGroup = (state, groupId) => {
  const groupsById = state.groups.byId
  if (!groupId || Object.keys(groupsById).length === 0) return []
  if (!groupsById[groupId]) return []
  return groupsById[groupId].students
}

export const getStudentGroup = (state, group_type) => {
  const groupsById = state.groups.byId
  const group = Object.keys(groupsById)
    .filter(group => {
      const { archive } = groupsById[group]
      if (GROUPS_GROUP_TYPE[0] === group_type && false === archive) return true
      return false
    })
    .map(group => {
      const groupObj = Object.assign(
        {
          isGrades: ''
        },
        groupsById[group]
      )
      return groupObj
    })
  return group[0]
}
