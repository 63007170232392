import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { Input, PasswordInput } from '../inputs.jsx'

import { emitLogin } from '../../actions/login.js'

import { getErrorState, getLoggingIn } from '../../reducers/accounts.js'

const mapStateToProps = state => ({
  error: getErrorState(state),
  isLoggingIn: getLoggingIn(state)
})

const mapDispatchToProps = dispatch => ({
  handleSubmit(username, password) {
    const data = {
      // device_id: '',
      device_type: 'Desktop',
      browser: navigator?.userAgentData?.brands?.[0]?.brand,
      app_version: navigator?.userAgent,
      os: navigator?.userAgentData?.platform,
      user: username,
      password
    }
    dispatch(emitLogin(data))
  }
})

class LoginFormComponent extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.shape({
      isTrue: PropTypes.bool.isRequired,
      msg: PropTypes.string.isRequired
    }),
    isLoggingIn: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      /*values to be submitted*/
      name: '',
      password: '',
      showPassword: false,
      /*state variables*/
      formValid: false /*TODO add form validation*/
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleSubmit(e, message) {
    e.preventDefault()
    this.props.handleSubmit(this.state.name, this.state.password)
  }

  handleChange(evt) {
    evt.preventDefault()
    let newState = {}
    newState[evt.target.name] = evt.target.value
    this.setState(newState)
  }

  render() {
    let { error, isLoggingIn } = this.props
    let { showPassword } = this.state
    return (
      <div>
        <form
          className={classNames({
            error: error.isTrue,
            'st-login-form': true
          })}
          onSubmit={this.handleSubmit}
        >
          <Input
            name="name"
            type="text"
            placeholder="User ID"
            handleChange={this.handleChange}
          />
          <PasswordInput
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            handleChange={this.handleChange}
            onToggleShow={e => {
              e.preventDefault()
              this.setState({
                showPassword: !showPassword
              })
            }}
          />
          <p className="text-center error-text">{error.msg}</p>
          <button
            disabled={isLoggingIn}
            type="submit"
            className="btn btn-primary btn-block text-uppercase"
          >
            {isLoggingIn ? (
              <FontAwesome spin size="2x" name="spinner" />
            ) : (
              <span>Sign In</span>
            )}
          </button>
          <Link to={'/reset-password'} className="text-small text-center">
            Cannot access your account?
          </Link>
        </form>
      </div>
    )
  }
}

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent)
