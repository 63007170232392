import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//selectors
import { isLoading as groupsLoading } from '../../reducers/groups'
import { isLoading as testsLoading } from '../../reducers/tests'
import { getIsLoading as subjectsLoading } from '../../reducers/subjects'
import { getSubjects, getElectives } from '../../reducers/ui/newSubTest'
//components
import Spinner from '../spinner'
import { ReduxSelectWithId } from './inputs'

const mapStateToProps = state => ({
  ready:
    !groupsLoading(state) && !testsLoading(state) && !subjectsLoading(state),
  subjects: getSubjects(state),
  electives: getElectives(state)
})

class SelectSingleSubjectFormComponent extends Component {
  static propTypes = {
    ready: PropTypes.bool.isRequired,
    subjects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired
      })
    ).isRequired,
    electives: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired
      })
    ).isRequired
  }

  render() {
    const { ready, subjects, electives } = this.props
    console.log('SelectSinglseSubjectForm - subjects: ', subjects)
    console.log('SelectSinglseSubjectForm - electives: ', electives)
    if (ready)
      return (
        <div>
          <p className="form__heading">Select Subject</p>
          <ReduxSelectWithId
            name="subject"
            options={subjects}
            placeholder="Select Subject"
          />
          <p className="form__heading">Select Electives</p>
          {electives.length > 0 && (
            <ReduxSelectWithId
              name="elective"
              options={electives}
              placeholder="Select Elective"
            />
          )}
          {electives.length === 0 && (
            <span>No electives found for this subject</span>
          )}
        </div>
      )
    return <Spinner />
  }
}

const SelectSingleSubjectForm = connect(mapStateToProps)(
  SelectSingleSubjectFormComponent
)

export default SelectSingleSubjectForm
