// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import enums
import { WORKING_DAYS } from '../../helpers/enums'

class DaySelector extends Component {
  static propTypes = {
    parentComponent: PropTypes.string.isRequired,
    selectedDay: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  placeButtonHorizontallyOrVertically() {
    const { parentComponent } = this.props
    switch (parentComponent) {
      case 'studentTimetable':
        return 'sectionDivRight'
      case 'classProfile':
        return 'sectionDivRight__horizontal'
      default:
        return 'sectionDivRight__horizontal'
    }
  }
  render() {
    const { handleChange, selectedDay } = this.props
    return (
      <div className={this.placeButtonHorizontallyOrVertically()}>
        {WORKING_DAYS.map(day => (
          <button
            key={day}
            value={day}
            onClick={handleChange}
            className={classNames({
              sectionButton: true,
              'sectionButton__active-class': day === selectedDay
            })}
          >
            {day}
          </button>
        ))}
      </div>
    )
  }
}

export default DaySelector
