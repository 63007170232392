import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { PageHelmet } from '../common'
/* Dependancies */
import { LoginForm } from './form.jsx'
import { connect } from 'react-redux'
/*selectors*/
import { isLoggedIn } from '../../reducers/accounts'

const LoginComponent = ({ isLoggedIn }) => {
  if (!isLoggedIn) {
    return (
      <div className="container login-page zIndex--2">
        <PageHelmet>Login</PageHelmet>
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1">
            <div className="col-xs-11 col-xs-offset-1">
              <span>
                <img
                  src="sealtabs-logo.png"
                  alt="sealtabs-logo"
                  className="sealtabs-logo"
                />
              </span>
            </div>
            <div className="col-lg-12 quote-and-form-container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="st-home-quote">
                    <h2 className="st-home-quote st-home-quote__text">
                      "Education is the most powerful tool which you can use to
                      change the world."
                    </h2>
                    <p className="st-home-quote st-home-quote__author">
                      - Nelson Mandela
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="st-form">
                    <LoginForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <Redirect to="/dashboard" />
  }
}

LoginComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state)
})

export const Login = connect(mapStateToProps, null)(LoginComponent)
