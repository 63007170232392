import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'
import querystring from 'query-string'
import {
  MARKS_FETCH_START,
  MARKS_FETCH_FAIL,
  MARKS_FETCH_DATA,
  MARKS_POST_SUCCESS,
  MARKS_POST_FAIL,
  MARKS_POST_IN_PROCESS,
  MARKS_POST_BULK_SUCCESS,
  MARKS_POST_BULK_FAIL,
  MARKS_POST_BULK_START,
  UPDATE_EXISTING_MARKS_FOR_FORM,
  MARKS_REPORT_DATA,
  RESET_MARKS_STATUS_OBJECT,
  CLASS_MARKS_FETCH_DATA,
  GENERATE_RANK_START,
  GENERATE_RANK_SUCCESS,
  GENERATE_RANK_FAIL,
  MARKS_CALCULATION_START,
  MARKS_CALCULATION_SUCCESS,
  MARKS_CALCULATION_FAIL
} from '../helpers/actions'
import { emitAuthFailed } from './login.js'
import _ from 'underscore'

export const emitStart = () => ({
  type: MARKS_FETCH_START
})

export const emitFail = errMsg => ({
  type: MARKS_FETCH_FAIL,
  errMsg
})

export const emitResetMarksStatus = failure => ({
  type: RESET_MARKS_STATUS_OBJECT,
  failureStatus: failure
})

export const emitData = data => {
  // const normalizedData = normalize(data, marksSchema)
  let marksById = {}
  let allMarks = [],
    allStudents = [],
    allTests = [],
    allSubjects = []
  data.forEach(marksObj => {
    const { _id, subjectId, testId, studentId } = marksObj
    marksById[_id] = marksObj
    allMarks.push(_id)
    allStudents.push(studentId)
    allTests.push(testId)
    allSubjects.push(subjectId)
  })

  return {
    type: MARKS_FETCH_DATA,
    byId: marksById,
    allIds: allMarks,
    allStudents,
    allTests,
    allSubjects
  }
}
export const emitClassMarksData = data => {
  // const normalizedData = normalize(data, marksSchema)
  let marksById = {}
  let allMarks = [],
    allStudents = [],
    allTests = [],
    allSubjects = []
  data.forEach(marksObj => {
    const { _id, subjectId, testId, studentId } = marksObj
    marksById[_id] = marksObj
    allMarks.push(_id)
    allStudents.push(studentId)
    allTests.push(testId)
    allSubjects.push(subjectId)
  })

  return {
    type: CLASS_MARKS_FETCH_DATA,
    byId: marksById,
    allIds: allMarks,
    allStudents,
    allTests,
    allSubjects
  }
}
const fetchMarksForTestSubjByAcademicYear = ({
  jwt,
  testId,
  subjectArr,
  studentArr,
  academicYear
}) => {
  const testArr = Array.isArray(testId) ? testId : [].concat(testId)
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/subjects/tests/students/academic-year/${academicYear}?` +
    querystring.stringify({
      subjects: subjectArr,
      tests: testArr,
      students: studentArr
    })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
    .then(checkStatus)
    .then(parseJSON)
}

const fetchMarksForTestSubj = ({ jwt, testId, subjectArr, studentArr }) => {
  const testArr = [].concat(testId)
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/subjects/tests/students?` +
    querystring.stringify({
      subjects: subjectArr,
      tests: testId,
      students: studentArr
    })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
    .then(checkStatus)
    .then(parseJSON)
}

export const startFetchMarksForTestSubj = data => {
  return dispatch => {
    dispatch(emitStart())
    return (data.academicYear && data.academicYear.length
      ? fetchMarksForTestSubjByAcademicYear(data)
      : fetchMarksForTestSubj(data)
    )
      .then(json => {
        dispatch(emitData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err))
      })
  }
}

const fetchMarksForTestSubjByGroup = ({
  jwt,
  testId,
  subjectArr,
  studentGroupArr
}) => {
  const testArr = [].concat(testId)
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/studentgroup/subjects/tests/studentgroups?` +
    querystring.stringify({
      subjects: subjectArr,
      tests: testArr,
      studentgroups: studentGroupArr
    })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
    .then(checkStatus)
    .then(parseJSON)
}

export const startFetchMarksForTestSubjByGroup = data => {
  return dispatch => {
    dispatch(emitStart())
    return fetchMarksForTestSubjByGroup(data)
      .then(json => {
        dispatch(emitData(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err))
      })
  }
}

//fetch marks for studentgroups per academic year
const fetchMarksForTestSubjByGroupByAcademicYear = ({
  jwt,
  testId,
  subjectArr,
  studentGroupArr,
  academic_year
}) => {
  const testArr = Array.isArray(testId) ? testId : [].concat(testId)
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/studentgroup/subjects/tests/studentgroups/academic_year/${academic_year}?` +
    querystring.stringify({
      subjects: subjectArr,
      tests: testArr,
      studentgroups: studentGroupArr
    })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
    .then(checkStatus)
    .then(parseJSON)
}

export const startFetchMarksForTestSubjByGroupByAcademicYear = data => {
  return dispatch => {
    dispatch(emitStart())
    return fetchMarksForTestSubjByGroupByAcademicYear(data)
      .then(json => {
        dispatch(emitData(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err))
      })
  }
}

//fetch marts for classwise report
const fetchMarksForClassTestSubj = ({
  jwt,
  testId,
  subjectArr,
  groupIds,
  academic_year
}) => {
  const testArr = Array.isArray(testId) ? testId : [].concat(testId)
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/academic_year/${academic_year}/studentGroups/tests/subjects?` +
    querystring.stringify({
      studentGroups: groupIds,
      tests: testArr,
      subjects: subjectArr
    })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
    .then(checkStatus)
    .then(parseJSON)
}

export const startFetchMarksForClassTestSubj = data => {
  return dispatch => {
    dispatch(emitStart())
    return fetchMarksForClassTestSubj(data)
      .then(json => {
        dispatch(emitClassMarksData(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err))
      })
  }
}
// post marks

const postMarks = ({ jwt, payload, level }) => {
  const Authorization = composeAuth(jwt)
  let url
  let data
  if (level !== 'teacher') {
    url = process.env.REACT_APP_SERVER_NEW + '/api/marks'
    data = payload
  } else {
    url = process.env.REACT_APP_SERVER_NEW + '/api/marks/teacher'
    delete payload.academic_year
    data = payload
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const emitPostFail = (statusObj, error) => {
  const keys = Object.keys(statusObj)
  keys.map(key => {
    Object.assign(statusObj, { [key]: 'failure' })
    return key
  })

  return {
    type: MARKS_POST_FAIL,
    statusObj,
    errMsg: error
  }
}

export const emitPostInprocess = statusObj => ({
  type: MARKS_POST_IN_PROCESS,
  statusObj
})

export const emitPostSuccess = statusObj => ({
  type: MARKS_POST_SUCCESS,
  statusObj
})

export const extractStatusObj = responseData => {
  const statusObj = {}
  responseData.map(resObj => {
    const { testId, subjectId, studentId } = resObj
    const marksStatus = { [`${testId}.${subjectId}.${studentId}`]: 'success' }
    Object.assign(statusObj, marksStatus)
    return resObj
  })
  return statusObj
}

export const startPostReqMarks = data => {
  const { testId, marks, subjectId } = data.payload
  const statusObj = {}
  marks.map(mark => {
    const { studentId } = mark
    const marksStatus = {
      [`${testId}.${subjectId}.${studentId}`]: 'in_progress'
    }
    Object.assign(statusObj, marksStatus)
    return studentId
  })
  return dispatch => {
    dispatch(emitPostInprocess(statusObj))
    return postMarks(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostSuccess(extractStatusObj(json.data)))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFail(statusObj, err.message))
        errorToast(err.message === 'Forbidden' ? 'Not Eligible' : err.message)
      })
  }
}

// post grades
const postGrade = ({ jwt, payload, level }) => {
  const Authorization = composeAuth(jwt)
  let url
  if (level !== 'teacher') {
    url = process.env.REACT_APP_SERVER_NEW + '/api/marks/grades'
  } else {
    url = process.env.REACT_APP_SERVER_NEW + '/api/marks/grades/teacher'
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPostReqGrade = data => {
  const { testId, marks, subjectId } = data.payload
  const statusObj = {}
  marks.map(mark => {
    const { studentId } = mark
    const marksStatus = {
      [`${testId}.${subjectId}.${studentId}`]: 'in_progress'
    }
    Object.assign(statusObj, marksStatus)
    return studentId
  })
  return dispatch => {
    dispatch(emitPostInprocess(statusObj))
    return postGrade(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostSuccess(extractStatusObj(json.data)))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFail(statusObj))
        errorToast(err.message === 'Forbidden' ? 'Not Eligible' : err.message)
      })
  }
}

export const emitUpdateMarksStore = ({ existingMarksForForm }) => ({
  type: UPDATE_EXISTING_MARKS_FOR_FORM,
  existingMarksForForm
})

export const updateMarksStore = ({ existingMarksForForm }) => {
  return dispatch => {
    return dispatch(emitUpdateMarksStore({ existingMarksForForm }))
  }
}

// report(overall and section)
const fetchReportsByAcademicYear = ({
  jwt,
  groupId,
  type,
  testIds,
  academicYear
}) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/rank/studentGroup/${groupId}/tests/type/academic_year/${academicYear}?` +
    querystring.stringify({ tests: testIds, type: type })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}
const fetchReports = ({ jwt, groupId, type, testIds }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/rank/studentGroup/${encodeURIComponent(groupId)}/tests/type?` +
    querystring.stringify({
      tests: testIds,
      type: type
    })

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchReports = data => {
  return function(dispatch) {
    return (data.academicYear && data.academicYear.length
      ? fetchReportsByAcademicYear(data)
      : fetchReports(data)
    )
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitReportData(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err.message))
      })
  }
}

const fetchReportsForGroupsByAcademicYear = ({
  jwt,
  groupId,
  type,
  testIds,
  academicYear
}) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/marks/rank/studentGroups/tests/type/academic_year/${academicYear}?` +
    querystring.stringify({
      studentGroups: groupId,
      tests: testIds,
      type: type
    })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchReportsForGroups = data => {
  return function(dispatch) {
    return fetchReportsForGroupsByAcademicYear(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitReportDataForGroups(json.data))
      })
      .catch(err => {
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFail(err.message))
      })
  }
}

const emitReportDataForGroups = res => {
  const subjectWiseReport = []
  let overallReport = []
  res.forEach(reportObj => {
    const { type } = reportObj
    if (type === 'subject') subjectWiseReport.push(reportObj)
    else overallReport.push(reportObj)
  })
  return {
    type: MARKS_REPORT_DATA,
    subjectWiseReport,
    overallReport
  }
}

const emitReportData = res => {
  const subjectWiseReport = []
  let overallReport = {}
  res.forEach(reportObj => {
    const { type } = reportObj
    if (type === 'subject') subjectWiseReport.push(reportObj)
    else overallReport = reportObj
  })
  return {
    type: MARKS_REPORT_DATA,
    subjectWiseReport,
    overallReport: [overallReport]
  }
}

//bcbs marks report fourToeleven
const postGenerateRankBcBsfourToEleven = ({ jwt, payload }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW + '/api/marks/reports/bcbs/fourtoeleven'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}
export const startGenerateRankForFourToEleven = data => dispatch => {
  const { jwt, ...payload } = data
  return postGenerateRankBcBsfourToEleven({ jwt, payload })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('Ranks generated successfully')
    })
    .catch(err => {
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      errorToast('failed to generate rank .Please try again')
    })
}

//bcbs marks report eleven and twelve
const postGenerateRankBcBsElevenAndTwelve = ({ jwt, payload }) => {
  const Authorization = composeAuth(jwt)
  const url =
    process.env.REACT_APP_SERVER_NEW + '/api/marks/reports/bcbs/twelth'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}
export const startGenerateRankForElevenAndTwelve = data => dispatch => {
  const { jwt, ...payload } = data
  return postGenerateRankBcBsElevenAndTwelve({ jwt, payload })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('Ranks generated successfully')
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      errorToast('failed to generate rank .Please try again')
    })
}

//marks bulk upload
const emitPostBulkMarksUploadStart = () => ({
  type: MARKS_POST_BULK_START
})

const emitPostBulkMarksUploadFail = errMsg => ({
  type: MARKS_POST_BULK_FAIL,
  errMsg
})

const emitPostBulkMarksUploadData = data => {
  return {
    type: MARKS_POST_BULK_SUCCESS,
    data
  }
}

const postBulkMarksUpload = ({ jwt, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/marks/csv'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPostBulkMarksUpload = data => {
  return function(dispatch) {
    dispatch(emitPostBulkMarksUploadStart())
    return postBulkMarksUpload(data)
      .then(parseJSON)
      .then(json => {
        if (json.statusCode >= 200 && json.statusCode <= 300) {
          dispatch(emitPostBulkMarksUploadData(json.data))
          successToast('Marks Updated Successfully')
        } else {
          dispatch(emitPostBulkMarksUploadFail())
        }
      })
      .catch(err => {
        dispatch(emitPostBulkMarksUploadFail(err))
        errorToast('Marks Update Failed')
      })
  }
}

//generate rank
const generateRankStart = () => ({
  type: GENERATE_RANK_START
})

const generateRankFail = errMsg => ({
  type: GENERATE_RANK_FAIL,
  errMsg
})

const generateRankSuccess = data => {
  return {
    type: GENERATE_RANK_SUCCESS,
    data
  }
}

const generateRank = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/marks/reports'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startGenerateRank = data => {
  return function(dispatch) {
    dispatch(generateRankStart())
    return generateRank(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(generateRankSuccess(json.data))
        successToast('Ranks generated successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(generateRankFail(err.message))
        errorToast('Failed to generate rank .Please try again')
      })
  }
}

//
const marksCalculationStart = () => ({
  type: MARKS_CALCULATION_START
})

const marksCalculationFail = errMsg => ({
  type: MARKS_CALCULATION_FAIL,
  errMsg
})

const marksCalculationSuccess = data => {
  return {
    type: MARKS_CALCULATION_SUCCESS,
    data
  }
}

const marksCalculation = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/marks/calculated'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startMarksCalculation = data => {
  return function(dispatch) {
    dispatch(marksCalculationStart())
    return marksCalculation(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(marksCalculationSuccess(json.data))
        successToast('Marks calculated successfully')
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(marksCalculationFail(err.message))
        errorToast('Failed to calculate marks. Please try again')
      })
  }
}
