import {
  CREATE_TICKET,
  CREATE_TICKET_FAILED,
  CREATE_TICKET_START,
  GET_TICKET,
  GET_TICKET_FAILED,
  GET_TICKET_START,
  PATCH_TICKET,
  PATCH_TICKET_FAILED,
  PATCH_TICKET_START,
  REMOVE_TICKET,
  REMOVE_TICKET_START,
  REMOVE_TICKET_FAILED,
  GET_CHATS,
  GET_CHATS_FAILED,
  GET_CHATS_START,
  POST_CHAT,
  POST_CHAT_START,
  POST_CHAT_FAILED,
  PATCH_CHAT,
  PATCH_CHAT_START,
  PATCH_CHAT_FAILED,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  errMsg: '',
  status: '',
  successMsg: '',
  tickets: [],
  isChatLoading: false,
  isChatErr: false,
  postChatLoading: false,
  chats: [],
  chatStatus: '',
  postedChat: {},
  patchChatLoading: false,
  patchedChat: {}
}

export const ticket = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case CREATE_TICKET_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case CREATE_TICKET:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'posted'
      })
    case CREATE_TICKET_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case GET_TICKET_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_TICKET:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        tickets: action.data,
        chats: [],
        status: 'fetched'
      })
    case GET_TICKET_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case PATCH_TICKET_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case PATCH_TICKET:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'patch'
      })
    case PATCH_TICKET_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_CHATS_START:
      return Object.assign({}, state, {
        isChatLoading: true,
        isChatErr: false,
        errMsg: '',
        status: ''
      })
    case GET_CHATS:
      return Object.assign({}, state, {
        isChatLoading: false,
        isChatErr: false,
        errMsg: '',
        chats: action.data,
        chatStatus: 'fetched'
      })
    case GET_CHATS_FAILED:
      return Object.assign({}, state, {
        isChatLoading: false,
        isChatErr: true,
        errMsg: action.error,
        status: '',
        chatStatus: ''
      })
    case POST_CHAT_START:
      return Object.assign({}, state, {
        postChatLoading: true,
        isChatErr: false,
        chatStatus: ''
      })
    case POST_CHAT:
      return Object.assign({}, state, {
        postChatLoading: false,
        isChatErr: false,
        chatStatus: 'chatPosted',
        postedChat: action.data
      })
    case POST_CHAT_FAILED:
      return Object.assign({}, state, {
        postChatLoading: false,
        isChatErr: true,
        errMsg: action.error,
        chatStatus: ''
      })
    case PATCH_CHAT_START:
      return Object.assign({}, state, {
        patchChatLoading: true,
        isChatErr: false,
        chatStatus: ''
      })
    case PATCH_CHAT:
      return Object.assign({}, state, {
        patchChatLoading: false,
        isChatErr: false,
        chatStatus: 'chatPatched',
        patchedChat: action.data
      })
    case PATCH_CHAT_FAILED:
      return Object.assign({}, state, {
        patchChatLoading: false,
        isChatErr: true,
        errMsg: action.error,
        chatStatus: ''
      })
    default:
      return state
  }
}

export const getTicketIsLoading = state => state.ticket.isLoading
export const getTicketIsError = state => state.ticket.isErr
export const getTicketErrorMessage = state => state.ticket.errMsg
export const getStatusTicket = state => state.ticket.status
export const getTickets = state => state.ticket.tickets
export const getChats = state => state.ticket.chats
export const getChatsLoading = state => state.ticket.isChatLoading
export const getChatError = state => state.ticket.isChatErr
export const getChatPostLoading = state => state.ticket.postChatLoading
export const getChatStatus = state => state.ticket.chatStatus
export const getPostedChat = state => state.ticket.postedChat
export const getPatchedChat = state => state.ticket.patchedChat
