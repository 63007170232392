import React from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

import classNames from 'classnames'

const ConfirmGroupSelectionComponent = ({
  description,
  confirmObj,
  navigateBack,
  handleSubmit,
  showModal,
  close,
  getPostDetails,
  postMsg,
  documents = []
}) => {
  const { sections, departmentCount } = confirmObj

  return (
    <div className="group-selection-form-ctr">
      <div className="group-selection-inner-ctr">
        <div className="container-fluid">
          <div className="col-xs-12">
            <div className="group">
              <div className="container-fluid">
                <div className="confirm-group-ctr">
                  <div>
                    <Modal show={showModal} onHide={close}>
                      <Modal.Header closeButton>
                        <p className="modal-title" />
                      </Modal.Header>
                      <Modal.Body>{getPostDetails()}</Modal.Body>
                      <Modal.Footer>
                        {postMsg === 'success' ? (
                          <Link to="/dashboard">
                            <button className="button button--white">
                              Close
                            </button>
                          </Link>
                        ) : (
                          <button
                            className="button button--white"
                            onClick={close}
                          >
                            Close
                          </button>
                        )}
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="confirm-group-form">
                    <div className="confirm-group-inner-ctr">
                      <div className="inner-ctr">
                        <div className="inner-ctr-upper">
                          <h4 className="heading heading--with-underline">
                            Description
                          </h4>
                          <p>{description}</p>
                        </div>
                        <div className="inner-ctr-upper">
                          <h4 className="heading heading--with-underline">
                            Document Name
                          </h4>
                          {documents.map((el, index) => (
                            <p key={index}>{el.name}</p>
                          ))}
                        </div>
                        <div className="inner-ctr-lower">
                          <h4 className="heading heading--with-underline">
                            To
                          </h4>
                          <div className="msg-recievers">
                            <p className="msg-recievers__recipient">
                              <span className="num">{sections}</span>
                              <span>Sections</span>
                            </p>

                            <p className="msg-recievers__recipient msg-recievers__recipient--light">
                              <span className="num">{departmentCount}</span>
                              <span>Teacher Departments</span>
                            </p>
                          </div>
                        </div>
                        <div className="field submit-field-no-absolute">
                          <Link
                            to="/dashboard"
                            className="button button--white"
                          >
                            Cancel
                          </Link>
                          <button
                            onClick={navigateBack}
                            className="button button--white"
                          >
                            Go Back
                          </button>
                          <button
                            className={classNames({
                              'button button--blue': true
                            })}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmGroupSelectionComponent
