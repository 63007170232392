import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//reducers
import { isSupportLoggedIn, isLoggedIn } from '../../../../reducers/accounts'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
import { BlankLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

const NewSupportDashboard = lazy(() => import('../../../dashboard/newSupport'))

const SuperAdminRoute = () => {
  const supportLoggedIn = useSelector(isSupportLoggedIn)
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Frontend v3 routes */}

            {/* Frontend v4 routes */}
            {supportLoggedIn && (
              <BlankLayout
                exact
                path="/dashboard"
                name="support institutions list"
                component={NewSupportDashboard}
              />
            )}
            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default SuperAdminRoute
