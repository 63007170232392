import {
  APP_LOGOUT,
  GET_FINANCIAL_DETAILS_START,
  GET_FINANCIAL_DETAILS_FAIL,
  GET_FINANCIAL_DETAILS_SUCCESS,
  POST_FINANCIAL_DETAILS_START,
  POST_FINANCIAL_DETAILS_FAIL,
  POST_FINANCIAL_DETAILS_SUCCESS,
  PATCH_FINANCIAL_DETAILS_START,
  PATCH_FINANCIAL_DETAILS_FAIL,
  PATCH_FINANCIAL_DETAILS_SUCCESS
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  errMsg: '',
  details: [],
  isLoadingPost: false,
  isErrPost: false
}

export const financialDetails = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_FINANCIAL_DETAILS_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case GET_FINANCIAL_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        details: action.data
      })
    case GET_FINANCIAL_DETAILS_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case POST_FINANCIAL_DETAILS_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErrPost: false,
        errMsg: ''
      })
    case POST_FINANCIAL_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErrPost: false
      })
    case POST_FINANCIAL_DETAILS_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErrPost: true,
        errMsg: action.errMsg
      })
    case PATCH_FINANCIAL_DETAILS_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErrPost: false,
        errMsg: ''
      })
    case PATCH_FINANCIAL_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErrPost: false
      })
    case PATCH_FINANCIAL_DETAILS_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErrPost: true,
        errMsg: action.errMsg
      })
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.financialDetails.isLoading
export const getIsError = state => state.financialDetails.isErr
export const getErrMsg = state => state.financialDetails.errMsg
export const getFinancialDetails = state => state.financialDetails.details
export const getIsLoadingPost = state => state.financialDetails.isLoadingPost
export const getIsErrorPost = state => state.financialDetails.isErrPost
