// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
// import actions
import { startFetchGroups } from '../../../actions/groups'
import { startFetchFlattenedData } from '../../../actions/students'
import {
  startFetchConductCertificateStatus,
  startFetchRegisterCertificateStatus
} from '../../../actions/conductCertificate'
import { startMergePDF, clearMergedPdfData } from '../../../actions/reportCard'
// import selectors
import { getJwt } from '../../../reducers/accounts'
import {
  isLoading as groupsLoading,
  groupsById
} from '../../../reducers/groups'
import { getAcademicYear } from '../../../reducers/institution'
import StudentStore from '../../../reducers/flattenedStudents'
import {
  isLoadingRegisterCertificate,
  registerCertificateById,
  byId,
  isLoading as isConductCertificateLoading
} from '../../../reducers/conductCertificate'
import {
  isWorking as mergeInProgress,
  mergedPDFData
} from '../../../reducers/reportCard'
// import components
import Spinner from '../../spinner'
import GroupSelector from './groupSelector'
import StudentList from './studentList'
import { PageHelmet } from '../../common'

class ConductCertificateStatusComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    groupsLoading: PropTypes.bool.isRequired,
    groupsById: PropTypes.object.isRequired,
    studentsLoading: PropTypes.bool.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    fetchStudents: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      umbrellaGroup: '',
      groupId: '',
      selectAll: false,
      goClicked: false,
      isDirty: false,
      selectedStudents: {}
    }
  }

  componentDidMount() {
    const { jwt, fetchGroups, clearMergedPdfData } = this.props
    clearMergedPdfData()
    fetchGroups({ jwt, type: 'academics' })
    this.setState({
      selectedStudents: this.initializeStudentObj()
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectAll !== this.state.selectAll) {
      this.setState({
        selectedStudents: this.initializeStudentObj()
      })
    }
  }
  handleUmbrellaChange = e => {
    this.props.clearMergedPdfData()
    this.setState({
      umbrellaGroup: e.target.value,
      groupId: '',
      testId: '',
      isDirty: false,
      goClicked: false,
      selectedStudents: {}
    })
  }

  handleSectionChange = e => {
    this.props.clearMergedPdfData()
    const { value: groupId } = e.target
    this.setState({
      groupId,
      testId: '',
      isDirty: true,
      goClicked: false,
      selectedStudents: {}
    })
  }

  handleMerge = e => {
    e.preventDefault()
    const {
      jwt,
      mergePDF,
      nameOfComponent,
      registerCertificateById,
      conductbyId,
      clearMergedPdfData
    } = this.props
    clearMergedPdfData()
    const { selectedStudents, groupId } = this.state
    const students = Object.keys(selectedStudents).filter(
      studentId => selectedStudents[studentId]
    )
    const allUrls = []
    if (students && students.length > 0) {
      if (nameOfComponent === 'register-cerificate') {
        students.forEach(id => {
          if (
            registerCertificateById[id] &&
            id &&
            registerCertificateById[id]['url']
          ) {
            allUrls.push(registerCertificateById[id]['url'])
          }
        })
      } else {
        students.forEach(id => {
          if (conductbyId[id] && id && conductbyId[id]['url']) {
            allUrls.push(conductbyId[id]['url'])
          }
        })
      }
      const opId =
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      const data = {
        operation_id: opId,
        files: allUrls
      }
      mergePDF({
        jwt,
        data
      })
    }

    this.setState({
      mergeClicked: true
    })
  }
  shapeStudentDataAndCertificate = () => {
    const {
      studentsById,
      registerCertificateById,
      conductbyId,
      nameOfComponent
    } = this.props

    if (nameOfComponent === 'register-cerificate') {
      return Object.keys(registerCertificateById)
        .map(id => {
          return Object.assign(registerCertificateById[id], studentsById[id])
        })
        .sort((a, b) => Number(a.roll_number) - Number(b.roll_number))
    } else {
      return Object.keys(conductbyId)
        .map(id => {
          return Object.assign(conductbyId[id], studentsById[id])
        })
        .sort((a, b) => Number(a.roll_number) - Number(b.roll_number))
    }
  }

  handleInputChange = e => {
    const { name: studentId } = e.target
    const { selectedStudents } = this.state
    const { registerCertificateById, conductbyId, nameOfComponent } = this.props
    this.props.clearMergedPdfData()
    const obj = {
      ...selectedStudents,
      [studentId]: !selectedStudents[studentId]
    }
    if (nameOfComponent === 'register-cerificate') {
      const count = Object.values(registerCertificateById).filter(id => {
        if (id.url) return id
      }).length
      const stdArr = []
      Object.keys(obj)
        .filter(stdId => obj[stdId])
        .forEach(stdId => stdArr.push(stdId))
      this.setState({
        selectedStudents: obj,
        selectAll: count === stdArr.length ? true : false
      })
    } else {
      const count = Object.values(conductbyId).filter(id => {
        if (id.url) return id
      }).length
      const stdArr = []
      Object.keys(obj)
        .filter(stdId => obj[stdId])
        .forEach(stdId => stdArr.push(stdId))
      this.setState({
        selectedStudents: obj,
        selectAll: count === stdArr.length ? true : false
      })
    }
  }

  handleSelectAllChange = e => {
    const { selectedStudents } = this.state
    this.props.clearMergedPdfData()
    const obj = {}
    Object.keys(selectedStudents).map(el => (obj[el] = !e.target.value))
    this.setState({
      selectAll: !this.state.selectAll,
      selectedStudents: obj
    })
  }
  initializeStudentObj = () => {
    const {
      studentsArr,
      registerCertificateById,
      conductbyId,
      nameOfComponent
    } = this.props
    const { selectedStudents } = this.state
    const obj = {}
    if (nameOfComponent === 'register-cerificate') {
      studentsArr.forEach(studentObj => {
        if (Object.keys(registerCertificateById).includes(studentObj._id)) {
          if (registerCertificateById[studentObj._id].status === 'savedToS3') {
            if (this.state.selectAll) {
              obj[studentObj._id] = this.state.selectAll
            } else {
              obj[studentObj._id] = selectedStudents[studentObj._id]
            }
          } else obj[studentObj._id] = false
        } else obj[studentObj._id] = false
      })
      return obj
    } else {
      studentsArr.forEach(studentObj => {
        if (Object.keys(conductbyId).includes(studentObj._id)) {
          if (conductbyId[studentObj._id].status === 'savedToS3') {
            if (this.state.selectAll) {
              obj[studentObj._id] = this.state.selectAll
            } else {
              obj[studentObj._id] = selectedStudents[studentObj._id]
            }
          } else obj[studentObj._id] = false
        } else obj[studentObj._id] = false
      })
      return obj
    }
  }
  handleGo = e => {
    e.preventDefault()
    const {
      jwt,
      groupsById,
      academic_year,
      fetchStudents,
      fetchConductCertificateStatus,
      nameOfComponent,
      fetchRegisterCertificateStatus,
      clearMergedPdfData
    } = this.props
    clearMergedPdfData()
    const { groupId } = this.state
    const groupStudentIds = groupsById[groupId].students
    const groupArchivedStudents = groupsById[groupId]['archiveStudents']
    const allStudents = groupStudentIds.concat(groupArchivedStudents)
    fetchStudents({
      jwt,
      students: allStudents
    })

    if (nameOfComponent === 'register-cerificate') {
      fetchRegisterCertificateStatus({
        jwt,
        studentId: allStudents,
        academic_year: academic_year
      })
    } else {
      fetchConductCertificateStatus({
        jwt,
        studentId: allStudents,
        academic_year: academic_year
      })
    }

    this.setState({
      goClicked: true,
      isDirty: false
    })
  }

  isReady = () => {
    const { studentsLoading, certificateIsLoading } = this.props
    if (studentsLoading || certificateIsLoading) return false
    return true
  }

  renderInfo = () => {
    const { groupsById } = this.props
    const { umbrellaGroup, groupId } = this.state
    const { group_name } = Object.assign(
      { group_name: '' },
      groupsById[groupId]
    )
    return (
      <div className="date-and-class">
        <FontAwesome name="pencil" />
        <span>{`${umbrellaGroup} - ${group_name}`}</span>
      </div>
    )
  }

  render() {
    const {
      groupsLoading,
      PageHelmetText,
      mergedPDFData,
      mergeInProgress
    } = this.props
    const {
      umbrellaGroup,
      groupId,
      isDirty,
      goClicked,
      selectedStudents,
      selectAll
    } = this.state

    console.log(mergedPDFData, 'mergedPDFData')
    return (
      <div className="attd-container">
        <PageHelmet>{PageHelmetText}</PageHelmet>
        <div className="container">
          <div className="row">
            <div className="col-xs-3">
              <div className="tab">
                <p className="tab__text">{PageHelmetText}</p>
              </div>
            </div>
            <div className="col-xs-9">
              {!groupsLoading ? (
                <GroupSelector
                  umbrellaGroup={umbrellaGroup}
                  groupId={groupId}
                  isDirty={isDirty}
                  handleUmbrellaChange={this.handleUmbrellaChange}
                  handleSectionChange={this.handleSectionChange}
                  handleGo={this.handleGo}
                />
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
        <div className="attd-search">
          <div className="container">{goClicked && this.renderInfo()}</div>
        </div>
        {goClicked && this.isReady() && (
          <StudentList
            studentDataAndTc={this.shapeStudentDataAndCertificate()}
            selectedStudents={selectedStudents}
            handleInputChange={this.handleInputChange}
            handleMerge={this.handleMerge}
            mergedPDFData={mergedPDFData}
            mergeInProgress={mergeInProgress}
            handleSelectAllChange={this.handleSelectAllChange}
            selectAll={selectAll}
          />
        )}
        {goClicked && !this.isReady() && <Spinner />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state),
  groupsLoading: groupsLoading(state),
  groupsById: groupsById(state),
  registerCertificateById: registerCertificateById(state),
  studentsArr: StudentStore.getStudentsSortedByName(state),
  studentsById: StudentStore.getById(state),
  studentsLoading: StudentStore.isLoading(state),
  certificateIsLoading: isLoadingRegisterCertificate(state),
  academic_year: getAcademicYear(state),
  conductbyId: byId(state),
  mergedPDFData: mergedPDFData(state),
  mergeInProgress: mergeInProgress(state),
  conductCertificateLoading: isConductCertificateLoading(state)
})

const mapDispatchToProps = dispatch => ({
  fetchGroups(data) {
    dispatch(startFetchGroups(data))
  },
  fetchStudents(data) {
    dispatch(startFetchFlattenedData(data))
  },
  fetchConductCertificateStatus(data) {
    dispatch(startFetchConductCertificateStatus(data))
  },
  fetchRegisterCertificateStatus(data) {
    dispatch(startFetchRegisterCertificateStatus(data))
  },
  mergePDF(data) {
    dispatch(startMergePDF(data))
  },
  clearMergedPdfData(data) {
    dispatch(clearMergedPdfData(data))
  }
})

const ConductCertificateStatus = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConductCertificateStatusComponent)

export default ConductCertificateStatus
