import { emitAuthFailed } from './login.js'

import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'
import {
  // SET_FILE_UPLOAD_DETAILS,
  POST_FILE_UPLOAD_FAILED,
  START_FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  CLEAR_FILE_UPLOAD,
  UPDATE_FILE_LINKS
} from '../helpers/actions.js'

const startUploading = () => ({
  type: START_FILE_UPLOAD
})

export const clearFileUpload = () => ({
  type: CLEAR_FILE_UPLOAD
})

const setFileUploadFailed = () => ({
  type: POST_FILE_UPLOAD_FAILED
})

const emitFileUploadLInk = (data, original) => {
  const links = Array.isArray(data)
    ? data.map(el => {
        return {
          publicUrl: el.publicUrl,
          filename: el.filename
        }
      })
    : [{ publicUrl: data.publicUrl, filename: data.filename }]
  return {
    type: FILE_UPLOAD_SUCCESS,
    data: links,
    original
  }
}

export const fileUpload = ({ jwt, body }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/s3/v2'

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/*',
      Authorization
    },
    body
  })
}

export const startFileUpload = data => dispatch => {
  dispatch(startUploading())
  return fileUpload(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startFileUpload', json)
      dispatch(emitFileUploadLInk(json.data, data.original))
      successToast('File uploaded successfully')
    })
    .catch(err => {
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      console.error('error occured')
      dispatch(setFileUploadFailed())
      errorToast('failed to upload File')
    })
}

export const deleteDocumentLinks = data => ({
  type: UPDATE_FILE_LINKS,
  data
})
