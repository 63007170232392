import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// actions
import { startPatchUmbrellaGroupNameAndOrder } from '../../../actions/groups'
import { startFetchGroups } from '../../../actions/groups'
import { saveClassAndSection } from '../../../actions/ui/editGroups'

// reducers
import { getJwt } from '../../../reducers/accounts'
import {
  getIsSuccess,
  isLoading,
  getAcademicGroups
} from '../../../reducers/groups'
import { getAcademicYear } from '../../../reducers/institution'

// components
import Snackbar from './snackbar'

class EditClassComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    setSnackbarType: PropTypes.func.isRequired,
    showSnackbar: PropTypes.bool.isRequired,
    snackbarType: PropTypes.string.isRequired,
    fetchStudentGroups: PropTypes.func.isRequired,
    editUmbrellaGroupNameAndOrder: PropTypes.func.isRequired,
    academicGroups: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      existingUmbrellaGroupName: '',
      new_umbrella_group_name: '',
      orderUmbrella_group: 0
    }
    this.inputFieldRef = null
    this.inputOrderRef = null
    this.handleClick = this.handleClick.bind(this)
    this.handleNewUmbrellaName = this.handleNewUmbrellaName.bind(this)
    this.handleOrderUmbrellaGroup = this.handleOrderUmbrellaGroup.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.uniqueParentGroupsWithDetails = this.uniqueParentGroupsWithDetails.bind(
      this
    )
  }

  componentDidMount() {
    this.props.fetchStudentGroups({
      jwt: this.props.jwt,
      type: 'academics'
    })
  }

  componentDidUpdate(prevProps) {
    const { isLoading: isLoadingPrev } = prevProps
    const { existingUmbrellaGroupName, new_umbrella_group_name } = this.state
    const {
      jwt,
      isLoading,
      success,
      fetchStudentGroups,
      storeClassAndSection,
      umbrellaGroup,
      selectedgroupId
    } = this.props
    if (
      !isLoading &&
      isLoadingPrev !== undefined &&
      isLoading !== isLoadingPrev
    ) {
      if (success === 'success') {
        fetchStudentGroups({
          jwt,
          type: 'academics'
        })
        if (existingUmbrellaGroupName !== umbrellaGroup) {
          this.setState({
            existingUmbrellaGroupName: '',
            new_umbrella_group_name: '',
            orderUmbrella_group: 0
          })
        }
      }

      if (
        success === 'fetched' &&
        existingUmbrellaGroupName === umbrellaGroup
      ) {
        storeClassAndSection({
          umbrellaGroup: new_umbrella_group_name,
          groupId: selectedgroupId
        })
        this.setState({
          existingUmbrellaGroupName: '',
          new_umbrella_group_name: '',
          orderUmbrella_group: 0
        })
      }
    }
  }

  uniqueParentGroupsWithDetails = groups => {
    if (groups.length === 0) return []
    const groupsDetails = groups.map(group => {
      return {
        parent_group: group.parent_group,
        orderUmbrella_group: group.orderUmbrella_group
      }
    })
    let uniqueGroupsArray = []
    let uniqueObject = {}
    for (let i in groupsDetails) {
      let objTitle = groupsDetails[i]['parent_group']
      uniqueObject[objTitle] = groupsDetails[i]
    }
    for (let i in uniqueObject) {
      uniqueGroupsArray.push(uniqueObject[i])
    }
    return uniqueGroupsArray
  }

  handleChange(e) {
    e.preventDefault()
    const selectedGroup = this.uniqueParentGroupsWithDetails(
      this.props.academicGroups
    ).find(grp => grp.parent_group === e.target.value)
    this.setState({
      ...this.state,
      existingUmbrellaGroupName: e.target.value,
      new_umbrella_group_name: e.target.value,
      orderUmbrella_group: selectedGroup.orderUmbrella_group
    })
  }

  handleNewUmbrellaName(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      new_umbrella_group_name: e.target.value
    })
  }

  handleOrderUmbrellaGroup(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      orderUmbrella_group: Number(e.target.value)
    })
  }

  handleClick(e) {
    e.preventDefault()
    const { editUmbrellaGroupNameAndOrder, academic_year, jwt } = this.props
    const data = {
      ...this.state
    }
    if (this.inputFieldRef.value !== '' && this.inputOrderRef.value !== '')
      editUmbrellaGroupNameAndOrder({
        jwt,
        academic_year,
        data
      })
    this.props.setSnackbarType('class')
    this.inputFieldRef.value = ''
    this.inputOrderRef.value = ''
  }

  render() {
    const {
      new_umbrella_group_name,
      existingUmbrellaGroupName,
      orderUmbrella_group
    } = this.state
    const {
      snackbarType,
      showSnackbar,
      hideSnackbar,
      academicGroups
    } = this.props
    return (
      <div className="renameClass">
        <h3 className="page__subHead">Rename Class</h3>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label for="selectClassName">Selected Class</label>
            {this.uniqueParentGroupsWithDetails(academicGroups).length ===
              0 && (
              <select id="selectClassName" className="form-control">
                <option disabled>No Class available</option>
              </select>
            )}
            {this.uniqueParentGroupsWithDetails(academicGroups).length !==
              0 && (
              <select
                id="selectClassName"
                className="form-control"
                onChange={this.handleChange}
                value={existingUmbrellaGroupName}
              >
                <option value="">Select a class</option>
                {this.uniqueParentGroupsWithDetails(academicGroups).map(
                  (um, index) => (
                    <option key={index} value={um.parent_group}>
                      {um.parent_group}
                    </option>
                  )
                )}
              </select>
            )}
          </div>
          <div className="form-group col-md-6">
            <label for="newClassName">New Class name</label>
            <input
              id="newClassName"
              type="text"
              className="form-control"
              placeholder="New Class Name"
              value={new_umbrella_group_name}
              onChange={this.handleNewUmbrellaName}
              ref={field => (this.inputFieldRef = field)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label for="orderUmbrella_group">Class Order</label>
            <input
              id="orderUmbrella_group"
              type="text"
              className="form-control"
              placeholder="Class order"
              value={orderUmbrella_group > 0 ? orderUmbrella_group : ''}
              onChange={this.handleOrderUmbrellaGroup}
              ref={field => (this.inputOrderRef = field)}
            />
          </div>
        </div>
        <div className="clear"></div>

        <div className="buttonalign__center buttonalign__center--margins">
          <input
            className={classNames({
              'button button--blue': true,
              disabled:
                new_umbrella_group_name.length === 0 ||
                existingUmbrellaGroupName.length === 0
            })}
            disabled={
              new_umbrella_group_name.length === 0 ||
              existingUmbrellaGroupName.length === 0
            }
            type="button"
            value="Save"
            onClick={this.handleClick}
          />
        </div>
        <div className="clear"></div>
        <Snackbar
          text="Class Name changed Sucessfully"
          showSnackbar={snackbarType === 'class' && showSnackbar}
          hideSnackbar={hideSnackbar}
        />
        <div className="clear"></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state),
  academic_year: getAcademicYear(state),
  isLoading: isLoading(state),
  success: getIsSuccess(state),
  academicGroups: getAcademicGroups(state)
})

const mapDispatchToProps = dispatch => ({
  editUmbrellaGroupNameAndOrder(data) {
    dispatch(startPatchUmbrellaGroupNameAndOrder(data))
  },
  fetchStudentGroups(data) {
    dispatch(startFetchGroups(data))
  },
  storeClassAndSection(data) {
    dispatch(saveClassAndSection(data))
  }
})

export const EditClass = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClassComponent)
