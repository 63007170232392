// import packages
import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import { connect } from 'react-redux'
import { PageHelmet } from '../../common'
import _ from 'underscore'

// import actions
import { startFetchGroups } from '../../../actions/groups'
import { startFetchData } from '../../../actions/students'
import { startFetchSubjects } from '../../../actions/subjects'
import { startFetchTests } from '../../../actions/tests'
import { startFetchMarksForTestSubj } from '../../../actions/marks'

// import selectors
import {
  getAcademicGroups,
  isLoading as isGroupsLoading,
  groupsById
} from '../../../reducers/groups.js'
import { getLevel, getLevelId } from '../../../reducers/accounts'
import { getLoading } from '../../../reducers/students'
import StudentStore from '../../../reducers/flattenedStudents'
import {
  getInstitutionData,
  getAcademicYear
} from '../../../reducers/institution'
import {
  getIsLoading,
  getMainSubjects,
  orderedSubjectArray
} from '../../../reducers/subjects'
import {
  isLoading as getIsLoadingTests,
  unMergedTestsById
} from '../../../reducers/tests'
import {
  getIsLoading as getisLoadingMarks,
  getMarksAsArray
} from '../../../reducers/marks'

// components
import { GroupSelector } from './groupSelector.jsx'
import Spinner from '../../spinner'
import StudentTable from './studentTable'
import {
  uniqueParentGroups,
  filterSectionsByParent,
  downloadReport
} from '../../../helpers/utils'
import { toInteger } from 'lodash-es'

class SubjectWiseConsolidatedMarksReportComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      parent_group: '',
      groupId: '',
      showTable: false
    }
  }

  componentDidMount() {
    const { jwt, fetchGroups } = this.props
    fetchGroups({ type: 'academics', jwt })
  }

  componentDidUpdate(prevProps) {
    const { isLoadingMarks } = this.props
    const { isLoadingMarks: isLoadingMarksPrev } = prevProps
    if (!isLoadingMarks && isLoadingMarks !== isLoadingMarksPrev) {
      this.setState({
        showTable: true
      })
    }
  }

  downloadReport = (e, templateType) => {
    let template = 'performanceReport'
    if (templateType === 'a3') template = 'performanceReportA3'
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: {},
      downloadedFileName: 'performance-report'
    })
  }

  downloadReport = e => {
    const { institutionData, groupsById, mainSubjects } = this.props
    const { parent_group, groupId, subjectId } = this.state
    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    let template = 'performanceCummculativeAvgConsolidated'
    let data = {
      institutionName: institutionName,
      institutionAddress: institutionAddress,
      className: parent_group,
      sectionName: groupsById[groupId].group_name,
      subjectName: mainSubjects.filter(sub => sub._id === subjectId)[0]
        ? mainSubjects.filter(sub => sub._id === subjectId)[0].name
        : '',
      tableHeader: this.getTableHeader(),
      tableBody: this.getTableBody()
    }
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: data,
      downloadedFileName: 'performanceCummculativeAvgConsolidated-report'
    })
  }

  getSubjectsWithElectivesOfGroup = () => {
    const { orderedSubjectArray } = this.props
    const finalArray = []
    orderedSubjectArray.forEach(sub => {
      if (sub.is_main && sub.electives && sub.electives.length) {
        orderedSubjectArray
          .filter(item => sub.electives.includes(item._id))
          .forEach(item => {
            finalArray.push(item._id)
          })
      } else if (sub.is_main && sub.electives.length === 0) {
        finalArray.push(sub._id)
      }
    })
    return finalArray
  }

  handleParentChange = e => {
    this.setState({
      parent_group: e.target.value,
      groupId: '',
      showTable: false
    })
  }

  fetchSubjectsForGroup = groupId => {
    const { groupsById, fetchSubjects, jwt } = this.props
    if (groupsById[groupId] && groupsById[groupId].subjects) {
      const selectedGroupSubjectIds = groupsById[groupId].subjects.map(
        sub => sub.subject_id
      )
      fetchSubjects({ jwt, subjects: selectedGroupSubjectIds })
    }
  }

  fetchStudentsForGroup = groupId => {
    const { groupsById, startFetchStudents, jwt } = this.props
    if (groupsById[groupId] && groupsById[groupId].students) {
      startFetchStudents({ jwt, students: groupsById[groupId].students })
    }
  }

  fetchTestsForGroup = groupId => {
    const { groupsById, fetchTests, jwt } = this.props
    if (groupsById[groupId] && groupsById[groupId].tests) {
      fetchTests({ jwt, tests: groupsById[groupId].tests })
    }
  }

  setSection = e => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      showTable: false
    })
    this.fetchSubjectsForGroup(value)
    this.fetchStudentsForGroup(value)
    this.fetchTestsForGroup(value)
  }

  getTableHeader = () => {
    const { testsById, mainSubjects } = this.props
    const finalHeader = [
      {
        groupBy: 'Roll No',
        subHeader: []
      },
      {
        groupBy: 'Student Name',
        subHeader: []
      }
    ]
    const subjectsByUmbrellaSubject = _.groupBy(
      mainSubjects,
      x => x.umbrellaName
    )
    Object.keys(subjectsByUmbrellaSubject).forEach(umbrellaName => {
      subjectsByUmbrellaSubject[umbrellaName].forEach(subjects => {
        finalHeader.push({
          groupBy: subjects.name,
          subjectId: subjects._id,
          subHeader: Object.keys(testsById)
            .map(testId => {
              return {
                label: testsById[testId].name,
                value: testId
              }
            })
            .concat({
              label: 'Agg %',
              value: subjects._id
            })
        })
      })
      if (umbrellaName && umbrellaName.length > 0) {
        finalHeader.push({
          groupBy: umbrellaName + ' Avg',
          subjectId: umbrellaName + '_Avg',
          subHeader: []
        })
      }
    })
    return finalHeader
  }

  getTableBody = () => {
    const { studentData, marksAsArray, mainSubjects } = this.props
    const finalData = []
    studentData.forEach(student => {
      const rollNo = student.roll_number
      const studentName = student.name
      const studentMarks = marksAsArray.filter(
        mark => mark.studentId === student._id
      )
      const marksBySubjectId = _.groupBy(studentMarks, x => x.subjectId)
      let temp = {}
      const subjectsByUmbrellaSubject = _.groupBy(
        mainSubjects,
        x => x.umbrellaName
      )
      Object.keys(subjectsByUmbrellaSubject).forEach(umbrellaName => {
        let umbrellaSubjectAvgMarksSum = 0
        subjectsByUmbrellaSubject[umbrellaName].forEach(subject => {
          if (
            subject.is_main &&
            subject.electives &&
            subject.electives.length
          ) {
            subject.electives.forEach(electiveSub => {
              let allTestMaxMarksSum = 0
              let allTestMarksSum = 0
              if (
                marksBySubjectId[electiveSub] &&
                marksBySubjectId[electiveSub].length > 0
              ) {
                const marksbytestId = _.groupBy(
                  marksBySubjectId[electiveSub],
                  x => x.testId
                )
                Object.keys(marksbytestId).forEach(testId => {
                  let sum = 0
                  Object.keys(marksbytestId[testId]).forEach(key => {
                    if (
                      marksbytestId[testId][key].mark >= 0 &&
                      marksbytestId[testId][key].zeroReason === 'notZero'
                    ) {
                      allTestMaxMarksSum =
                        allTestMaxMarksSum + marksbytestId[testId][key].maxMarks
                      allTestMarksSum =
                        allTestMarksSum + marksbytestId[testId][key].mark
                    }
                    sum = sum + marksbytestId[testId][key].mark
                  })
                  temp = Object.assign({}, temp, {
                    [subject._id + testId]: sum
                  })
                })
                temp = Object.assign({}, temp, {
                  [subject._id]:
                    (allTestMarksSum / allTestMaxMarksSum) * 100
                      ? ((allTestMarksSum / allTestMaxMarksSum) * 100).toFixed(
                          0
                        )
                      : ''
                })
                umbrellaSubjectAvgMarksSum =
                  parseFloat(umbrellaSubjectAvgMarksSum) +
                  parseFloat(
                    (allTestMarksSum / allTestMaxMarksSum) * 100
                      ? ((allTestMarksSum / allTestMaxMarksSum) * 100).toFixed(
                          0
                        )
                      : 0
                  )
              }
            })
          } else {
            let allTestMaxMarksSum = 0
            let allTestMarksSum = 0
            if (
              marksBySubjectId[subject._id] &&
              marksBySubjectId[subject._id].length > 0
            ) {
              const marksbytestId = _.groupBy(
                marksBySubjectId[subject._id],
                x => x.testId
              )
              Object.keys(marksbytestId).forEach(testId => {
                let sum = 0
                Object.keys(marksbytestId[testId]).forEach(key => {
                  if (
                    marksbytestId[testId][key].mark >= 0 &&
                    marksbytestId[testId][key].zeroReason === 'notZero'
                  ) {
                    allTestMaxMarksSum =
                      allTestMaxMarksSum + marksbytestId[testId][key].maxMarks
                    allTestMarksSum =
                      allTestMarksSum + marksbytestId[testId][key].mark
                  }
                  sum = sum + marksbytestId[testId][key].mark
                })
                temp = Object.assign({}, temp, {
                  [subject._id + testId]: sum
                })
              })
              temp = Object.assign({}, temp, {
                [subject._id]:
                  (allTestMarksSum / allTestMaxMarksSum) * 100
                    ? ((allTestMarksSum / allTestMaxMarksSum) * 100).toFixed(0)
                    : ''
              })
              umbrellaSubjectAvgMarksSum =
                parseFloat(umbrellaSubjectAvgMarksSum) +
                parseFloat(
                  (allTestMarksSum / allTestMaxMarksSum) * 100
                    ? ((allTestMarksSum / allTestMaxMarksSum) * 100).toFixed(0)
                    : 0
                )
            }
          }
        })
        if (umbrellaName && umbrellaName.length > 0) {
          temp = Object.assign({}, temp, {
            [umbrellaName + ' Avg']: (
              umbrellaSubjectAvgMarksSum /
              subjectsByUmbrellaSubject[umbrellaName].length
            ).toFixed(0)
          })
        }
      })
      finalData.push(
        Object.assign({}, temp, {
          'Roll No': rollNo,
          'Student Name': studentName
        })
      )
    })
    return finalData.sort((a, b) => a['Roll No'] - b['Roll No'])
  }

  onGo = e => {
    e.preventDefault()
    const { groupsById, jwt, fetchMarksForTestSubj, mainSubjects } = this.props
    const { groupId } = this.state
    if (
      groupsById[groupId] &&
      groupsById[groupId].students &&
      groupsById[groupId].tests
    ) {
      const selectedGroupTests = groupsById[groupId].tests
      const selectedGroupStudents = groupsById[groupId].students
      fetchMarksForTestSubj({
        jwt,
        testId: selectedGroupTests,
        subjectArr: this.getSubjectsWithElectivesOfGroup(),
        studentArr: selectedGroupStudents
      })
      this.setState({
        showTable: true
      })
    }
  }

  render() {
    const {
      academicGroups,
      groupsLoading,
      isLoadingSubjects,
      isLoadingStudents,
      isLoadingTests
    } = this.props
    const { parent_group, groupId, showTable } = this.state
    return groupsLoading ? (
      <Spinner />
    ) : (
      <div>
        <div className="attd-container">
          <PageHelmet>Marks Report</PageHelmet>
          <div className="container">
            <div className="row">
              <div className="col-xs-3">
                <div className="tab">
                  <p className="tab__text">Marks Report</p>
                </div>
              </div>
              <div className="col-xs-9">
                <GroupSelector
                  // data
                  umbrellaGroup={parent_group}
                  groupId={groupId}
                  parentNames={uniqueParentGroups(academicGroups)}
                  sections={filterSectionsByParent(
                    parent_group,
                    academicGroups
                  )}
                  // callbacks
                  handleParentChange={this.handleParentChange}
                  setSection={this.setSection}
                  handleGo={this.onGo}
                  isDirty={parent_group && groupId}
                />
              </div>
            </div>
          </div>
          <div className="attd-search">
            <div className="container">
              <div className="row">
                <div className="col-xs-6">
                  <div className="btn-container pull-right">
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!showTable}
                      onClick={e => this.downloadReport(e, 'a4')}
                    >
                      <FontAwesome name="download" />
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTable && parent_group && groupId ? (
            isLoadingSubjects || isLoadingStudents || isLoadingTests ? (
              <Spinner />
            ) : (
              <StudentTable
                studentData={this.getTableBody()}
                tableHeaders={this.getTableHeader()}
              />
            )
          ) : null}
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    jwt: state.accounts.jwt,
    groupsById: groupsById(state),
    academicGroups: getAcademicGroups(state),
    groupsLoading: isGroupsLoading(state),
    institutionData: getInstitutionData(state),
    academicYear: getAcademicYear(state),
    level: getLevel(state),
    levelId: getLevelId(state),
    isLoadingSubjects: getIsLoading(state),
    mainSubjects: getMainSubjects(state),
    orderedSubjectArray: orderedSubjectArray(state),
    isLoadingMarks: getisLoadingMarks(state),
    marksAsArray: getMarksAsArray(state),
    isLoadingStudents: getLoading(state),
    studentData: StudentStore.getStudentsSortedByRollNumber(state),
    isLoadingTests: getIsLoadingTests(state),
    testsById: unMergedTestsById(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups(data) {
    dispatch(startFetchGroups(data))
  },
  fetchSubjects(data) {
    dispatch(startFetchSubjects(data))
  },
  fetchMarksForTestSubj(data) {
    dispatch(startFetchMarksForTestSubj(data))
  },
  startFetchStudents(data) {
    dispatch(startFetchData(data))
  },
  fetchTests(data) {
    dispatch(startFetchTests(data))
  }
})

export const SubjectWiseConsolidatedMarksReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectWiseConsolidatedMarksReportComponent)
