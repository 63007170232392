import {
  renderMaxMarks,
  getMark,
  checkForElective,
  getById,
  downloadReport
} from '../../../helpers/utils'
import { isUndefined } from 'util'
import underscore from 'underscore'

const downloadReportPer = ({
  props,
  studentData,
  subjectData,
  templateType,
  state
}) => {
  console.log(templateType, 'templateType')

  const { groups, subjectsById, tests, institutionData, academicYear } = props
  const { parent_group, groupId, testId } = state
  const { name: institutionName, address: institutionAddress } = Object.assign(
    { name: '', address: '' },
    institutionData
  )
  const subjectOfTestById = getById(tests[testId].subjects, 'subject')

  const { academic_year, group_name, umbrella_group } = groups[groupId]
  const maximumMarks = subjectData.map(obj => {
    let returnObj = {}
    returnObj['maxMarks'] = renderMaxMarks(obj, studentData)
    if (obj.electives.length > 0) returnObj['span'] = true
    return returnObj
  })
  const subjectNames = subjectData.map(sub => {
    let obj = {}
    obj['isMain'] = sub.totalCalculation
    obj['name'] = sub.name
    obj['umbrellaName'] = sub.umbrellaName
    if (sub.electives.length > 0) obj['span'] = true
    return obj
  })
  const studentdetail = studentData.map(student => {
    let stdobj = {}
    let arr = []
    const subjectIds = subjectData.map(sub => sub._id)

    let marksDataShaped = []
    subjectIds.forEach((sub, i) => {
      let obj = {}
      obj['isMain'] = subjectsById[sub].totalCalculation
      if (!isUndefined(subjectsById[sub])) {
        if (subjectsById[sub].electives.length > 0) {
          obj['span'] = true
        }
      }
      if (isUndefined(student.marks[sub]) || isUndefined(subjectsById[sub])) {
        obj['marks'] = null
        obj['noMarks'] = true
      } else {
        obj['marks'] = `${getMark(student.marks[sub], subjectsById[sub])} ${
          checkForElective(student.marks[sub])
            ? checkForElective(student.marks[sub])
            : ''
        }`
        obj['marksPrint'] = getMark(student.marks[sub], subjectsById[sub])
        obj['elective'] = `${
          checkForElective(student.marks[sub])
            ? checkForElective(student.marks[sub])
            : ''
        }`
        if (subjectsById[sub].marksGrade === 'marks') {
          const marksOnly = student.marks[sub]['mark']
          const maxMarks = student.marks[sub]['maxMarks']
          const minMarks = subjectOfTestById[sub]['minMarks']
          // const per = Math.round(((marksOnly / maxMarks) * 100).toFixed(1))
          if (student.marks[sub].zeroReason === 'notZero') {
            obj['marksPrint'] = marksOnly
            if (subjectsById[sub].totalCalculation) {
              if (marksOnly < minMarks) obj['fail'] = true
              marksDataShaped.push({
                isMain: obj['isMain'],
                span: obj['span'],
                noMarks: obj['noMarks'],
                marksPrint: obj['marksPrint'],
                elective: obj['elective'],
                marks: marksOnly,
                exempted: false,
                maxMarks,
                minMarks,
                umbrellaName: subjectsById[sub].umbrellaName,
                isCalculation: true,
                fail: obj['fail']
              })
            } else {
              if (marksOnly < minMarks) obj['fail'] = true
            }
          } else {
            obj['zeroReason'] = true
            if (subjectsById[sub].totalCalculation) {
              if (
                student.marks[sub].zeroReason === 'absent' ||
                student.marks[sub].zeroReason === 'medical leave'
              ) {
                marksDataShaped.push({
                  isMain: obj['isMain'],
                  span: obj['span'],
                  noMarks: obj['noMarks'],
                  marksPrint: obj['marksPrint'],
                  elective: obj['elective'],
                  exempted: false,
                  zeroReason: true,
                  marks: 0,
                  maxMarks,
                  minMarks,
                  umbrellaName: subjectsById[sub].umbrellaName,
                  isCalculation: true,
                  fail: true
                })
              } else if (student.marks[sub].zeroReason === 'exempted') {
                marksDataShaped.push({
                  isMain: obj['isMain'],
                  span: obj['span'],
                  noMarks: obj['noMarks'],
                  marksPrint: obj['marksPrint'],
                  elective: obj['elective'],
                  exempted: true,
                  zeroReason: true,
                  marks: 0,
                  maxMarks: 0,
                  minMarks: minMarks,
                  umbrellaName: subjectsById[sub].umbrellaName,
                  isCalculation: true
                })
              }
            }
          }
        }
      }

      arr.push(obj)
    })

    const marksDataShapedById = underscore.groupBy(
      marksDataShaped,
      obj => obj.umbrellaName
    )

    let newMarksData = []
    Object.keys(marksDataShapedById).forEach((key, mainIndex) => {
      if (key !== 'undefined' && marksDataShapedById[key].length > 1) {
        let sumMax = 0
        let sumTotal = 0
        let sumMin = 0
        let subLength = 0
        marksDataShapedById[key].forEach((subj, index) => {
          if (!subj.exempted) {
            sumTotal += subj['marks']
            sumMax += subj['maxMarks']
            sumMin += subj['minMarks']
            subj['isCalculation'] = false
            newMarksData.push(subj)
            subLength++
          } else {
            newMarksData.push(subj)
          }
        })

        console.log(subLength, 'subLength')

        const avgTotal = Math.round(sumTotal / subLength)
        const avgMax = sumMax / subLength
        const avgMin = sumMin / subLength

        let obj = {
          isMain: true,
          span: marksDataShapedById[key][0]['span'],
          noMarks: marksDataShapedById[key][0]['noMarks'],
          marksPrint: avgTotal,
          elective: marksDataShapedById[key][0]['elective'],
          marks: avgTotal,
          maxMarks: avgMax,
          fail: avgTotal < avgMin,
          isCalculation: true
        }
        newMarksData.push(obj)
      } else {
        marksDataShapedById[key].forEach((subj, index) => {
          newMarksData.push(subj)
        })
      }
    })

    let totalmarks = 0
    let totalMax = 0
    let failSub = 0

    newMarksData.forEach(obj => {
      if (obj.isCalculation) {
        totalmarks += obj['marks']
        totalMax += obj['maxMarks']
        if (obj.fail) {
          failSub++
        }
      }
    })

    stdobj['rollno'] = student.roll_number
    stdobj['name'] = student.name
    stdobj['marks'] = newMarksData
    stdobj['total'] = student.total
    stdobj['totalPer'] = totalmarks
    stdobj['failSub'] = failSub
    stdobj['percentage'] = totalMax
      ? ((totalmarks / totalMax) * 100).toFixed(2)
      : 0
    stdobj['sectionPosition'] = student.sectionPosition
    stdobj['classPosition'] = student.classPosition
    return stdobj
  })
  const academic_year_next = Number(academic_year) + 1

  const subjectNamesById = underscore.groupBy(
    subjectNames,
    obj => obj.umbrellaName
  )

  let newSubjectData = []
  Object.keys(subjectNamesById).forEach((key, mainIndex) => {
    if (key !== 'undefined' && subjectNamesById[key].length > 1) {
      subjectNamesById[key].forEach((subj, index) => {
        newSubjectData.push(subj)
      })
      let obj = {
        isMain: true,
        name: subjectNamesById[key][0]['umbrellaName']
      }
      newSubjectData.push(obj)
    } else {
      subjectNamesById[key].forEach((subj, index) => {
        newSubjectData.push(subj)
      })
    }
  })

  const data = {
    title: `${parent_group}-${groups[groupId].group_name}-${tests[testId].name}`,
    maxMarks: maximumMarks,
    span: 6 + newSubjectData.length,
    students: studentdetail.map(data => {
      let stddata = {}
      stddata['rollno'] = data.rollno
      stddata['name'] = data.name
      stddata['marks'] = data.marks
      stddata['total'] = data.total
      stddata['totalPer'] = data.totalPer
      stddata['percentage'] = data.percentage
      stddata['sectionPosition'] = data.sectionPosition
      stddata['classPosition'] = data.classPosition
      stddata['failSub'] = data.failSub
      return stddata
    }),
    subjects: newSubjectData,
    classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-${academic_year_next}`,
    year: `${academic_year}-${academic_year_next}`,
    class: umbrella_group,
    section: group_name
  }
  data['institutionName'] = institutionName
  data['institutionAddress'] = institutionAddress
  data['institutionAddress'] = institutionAddress
  data['class'] = umbrella_group
  data['section'] = group_name
  data['testName'] = tests[testId].name
  const academicYearPlushOne = Number(academicYear) + 1
  data['academicYear'] = `${academicYear} - ${academicYearPlushOne}`
  let template = 'performanceReport'
  if (templateType === 'a3') template = 'performanceReportPerA3'
  console.log('data', data)
  downloadReport({
    nameOfTemplate: template,
    dataForTemplate: data,
    downloadedFileName: 'performance-report'
  })
}
export default downloadReportPer
