import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'

import { SealtabsRouter } from './mainRouter'
import configureStore from '../../helpers/configureStore'

let { store, persistor } = configureStore()

export const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="top-right"
          type="default"
          autoClose={5000}
          hideProgressBar={false}
          preventDuplicates
          newestOnTop={false}
          toastClassName="toast-class"
          closeOnClick
          pauseOnHover
        />
        <SealtabsRouter />
      </PersistGate>
    </Provider>
  )
}
