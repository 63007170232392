import {
  APP_LOGOUT,
  POST_GENERATE_PAYSLIP_START,
  POST_GENERATE_PAYSLIP_FAIL,
  POST_GENERATE_PAYSLIP_SUCCESS,
  GET_DOWNLOAD_PAYSLIP_START,
  GET_DOWNLOAD_PAYSLIP_FAIL,
  GET_DOWNLOAD_PAYSLIP_SUCCESS,
  PAYSLIPS_FETCH_START,
  PAYSLIPS_DATA,
  PAYSLIPS_FETCH_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  errMsg: '',
  status: '',
  successMsg: '',
  payslipData: []
}

export const payslip = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_GENERATE_PAYSLIP_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case POST_GENERATE_PAYSLIP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'posted'
      })
    case POST_GENERATE_PAYSLIP_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case GET_DOWNLOAD_PAYSLIP_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_DOWNLOAD_PAYSLIP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        payslipData: action.data,
        status: 'fetched'
      })
    case GET_DOWNLOAD_PAYSLIP_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case PAYSLIPS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case PAYSLIPS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: 'fail'
      })
    case PAYSLIPS_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        payslipData: action.data,
        successMsg: 'success'
      })
    default:
      return state
  }
}

export const getIsLoading = state => state.payslip.isLoading
export const getIsError = state => state.payslip.isErr
export const getErrorMessage = state => state.payslip.errMsg
export const getStatus = state => state.payslip.status
export const getPayslipData = state => state.payslip.payslipData
