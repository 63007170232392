import {
  GET_TEACHER_LEAVE_STATUS_START,
  GET_TEACHER_LEAVE_STATUS_FAIL,
  GET_TEACHER_LEAVE_STATUS_SUCCESS,
  PATCH_TEACHER_LEAVE_APPROVAL_START,
  PATCH_TEACHER_LEAVE_APPROVAL_FAIL,
  PATCH_TEACHER_LEAVE_APPROVAL_SUCCESS,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingApproval: false,
  isSuccess: false,
  isErr: false,
  hasErr: false,
  leaveStatus: [],
  msg: ''
}

export const leaveApproval = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState

    case GET_TEACHER_LEAVE_STATUS_START:
      return Object.assign({}, state, {
        isLoading: true
      })

    case GET_TEACHER_LEAVE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
        leaveStatus: action.data
      })

    case GET_TEACHER_LEAVE_STATUS_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        isSuccess: false
      })
    case PATCH_TEACHER_LEAVE_APPROVAL_SUCCESS:
      return Object.assign({}, state, {
        isSuccess: true,
        isLoadingApproval: false,
        hasErr: false,
        msg: 'success'
      })

    case PATCH_TEACHER_LEAVE_APPROVAL_FAIL:
      return Object.assign({}, state, {
        isSuccess: false,
        isLoadingApproval: false,
        hasErr: true,
        msg: 'failure'
      })

    case PATCH_TEACHER_LEAVE_APPROVAL_START:
      return Object.assign({}, state, {
        isSuccess: action.data,
        isLoadingApproval: true,
        hasErr: false,
        msg: ''
      })

    //   case LEAVE_POLICY_POST_SUCCESS:
    //     return Object.assign({}, state, {
    //       isSuccess: true,
    //       isLoading: false,
    //       isErr: false,
    //       msg: 'success'
    //     })

    //   case LEAVE_POLICY_POST_FAILURE:
    //     return Object.assign({}, state, {
    //       isSuccess: false,
    //       isLoading: false,
    //       isErr: true,
    //       msg: 'failure'
    //     })

    //   case LEAVE_POLICY_POST_START:
    //     return Object.assign({}, state, {
    //       isSuccess: action.data,
    //       isLoading: true,
    //       isErr: false,
    //       msg: ''
    //     })

    default:
      return state
  }
}

export const isLoadingLeaveStatus = state => state.leaveApproval.isLoading
export const isLoadingLeaveApproval = state =>
  state.leaveApproval.isLoadingApproval
export const isSuccessLeaveApproval = state => state.leaveApproval.isSuccess
export const getLeaveApprovalHasError = state => state.leaveApproval.hasErr
export const getLeaveStatusData = state => state.leaveApproval.leaveStatus
export const getLeaveStatusHasError = state => state.leaveApproval.isErr
//   export const getPostStatusLeavePolicy = state => state.leavePolicy.msg
