import {
  APP_LOGOUT,
  POST_TEACHER_LEAVE_START,
  POST_TEACHER_LEAVE_FAIL,
  POST_TEACHER_LEAVE_SUCCESS,
  PATCH_TEACHER_LEAVE_START,
  PATCH_TEACHER_LEAVE_FAIL,
  PATCH_TEACHER_LEAVE_SUCCESS,
  GET_TEACHER_LEAVE_START_BY_STATUS,
  GET_TEACHER_LEAVE_FAIL_BY_STATUS,
  GET_TEACHER_LEAVE_SUCCESS_BY_STATUS,
  DELETE_TEACHER_LEAVE_START,
  DELETE_TEACHER_LEAVE_FAIL,
  DELETE_TEACHER_LEAVE_SUCCESS,
  GET_TEACHER_LEAVE_COUNT_START,
  GET_TEACHER_LEAVE_COUNT_FAIL,
  GET_TEACHER_LEAVE_COUNT_SUCCESS,
  GET_TEACHER_LEAVE_START,
  GET_TEACHER_LEAVE_FAIL,
  GET_TEACHER_LEAVE_SUCCESS,
  GET_TEACHER_UNPAID_LEAVE_START,
  GET_TEACHER_UNPAID_LEAVE_FAIL,
  GET_TEACHER_UNPAID_LEAVE_SUCCESS
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  errMsg: '',
  leaveApplications: [],
  unpaidleaves: [],
  status: '',
  leaveCount: []
}

export const teacherLeave = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_TEACHER_LEAVE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case POST_TEACHER_LEAVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'posted'
      })
    case POST_TEACHER_LEAVE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case PATCH_TEACHER_LEAVE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case PATCH_TEACHER_LEAVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'edited'
      })
    case PATCH_TEACHER_LEAVE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case GET_TEACHER_LEAVE_START_BY_STATUS:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_TEACHER_LEAVE_SUCCESS_BY_STATUS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        leaveApplications: action.data,
        status: 'fetched'
      })
    case GET_TEACHER_LEAVE_FAIL_BY_STATUS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case DELETE_TEACHER_LEAVE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        status: ''
      })
    case DELETE_TEACHER_LEAVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'deleted'
      })
    case DELETE_TEACHER_LEAVE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        status: ''
      })
    case GET_TEACHER_LEAVE_COUNT_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_TEACHER_LEAVE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        leaveCount: action.data,
        status: 'fetched count'
      })
    case GET_TEACHER_LEAVE_COUNT_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_TEACHER_LEAVE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_TEACHER_LEAVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        leaveApplications: action.data,
        status: 'fetched all'
      })
    case GET_TEACHER_LEAVE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_TEACHER_UNPAID_LEAVE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_TEACHER_UNPAID_LEAVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        unpaidleaves: action.data,
        status: 'fetched'
      })
    case GET_TEACHER_UNPAID_LEAVE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })

    default:
      return state
  }
}

export const getIsLoading = state => state.teacherLeave.isLoading
export const getIsError = state => state.teacherLeave.isErr
export const getErrorMessage = state => state.teacherLeave.errMsg
export const getLeaveApplications = state =>
  state.teacherLeave.leaveApplications
export const getStatus = state => state.teacherLeave.status
export const getLeaveCount = state => state.teacherLeave.leaveCount
export const getUnpaidLeaves = state => state.teacherLeave.unpaidleaves
