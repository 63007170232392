import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class StudentLink extends Component {
  render() {
    const { children, studentId } = this.props
    return (
      <Link className="student-name" to={`/student/show/${studentId}`}>
        {children}
      </Link>
    )
  }
}

export default StudentLink
