import {
  APP_LOGOUT,
  SMS_FETCH_START,
  FETCH_SMS_BY_STATUS,
  POST_SMS_DATA,
  SMS_POST_FAILURE,
  SET_POST_STATUS,
  SET_POST_MESSAGE,
  IMPORTANT_SMS_FETCH_START,
  IMPORTANT_SMS_DATA,
  IMPORTANT_SMS_FETCH_FAIL,
  GET_SMS_COUNT_START,
  GET_SMS_COUNT_FAILURE,
  GET_SMS_COUNT_DATA
} from '../helpers/actions.js'
import { createSelector } from 'reselect'

const initialState = {
  smsById: {},
  smsAllIds: [],
  postSuccess: false,
  isLoading: false,
  message: '',
  impSms: [],
  smsLog: [],
  isLoadingSmsLog: false,
  isEorrorSmsLog: false,
  smsLog: [],
  isLoadingGetImportantSms: false
}

export const smsById = state => state.sms.smsById
export const smsAllIds = state => state.sms.smsAllIds
export const postStatus = state => state.sms.postSuccess
export const getIsLoading = state => state.sms.isLoading
export const getPostMessage = state => state.sms.message
export const getImpSms = state => state.sms.impSms
export const getisLoadingGetImportantSms = state =>
  state.sms.isLoadingGetImportantSms
export const getSmsLog = state => state.sms.smsLog
export const getisLoadingSmsLog = state => state.sms.isLoadingSmsLog
export const getisEorrorSmsLog = state => state.sms.isEorrorSmsLog

export const getSmsArray = createSelector(
  smsById,
  smsAllIds,
  (byId, allIds) => {
    if (!allIds || !byId) return []
    return allIds
      .map(id => byId[id])
      .sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate)
      })
  }
)

export const sms = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SMS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case FETCH_SMS_BY_STATUS:
      return Object.assign({}, state, {
        smsById: action.payload.entities,
        smsAllIds: action.payload.result,
        postSuccess: false,
        isLoading: false,
        message: ''
      })
    case POST_SMS_DATA:
      return Object.assign({}, state, {
        postSuccess: true,
        isLoading: false,
        message: action.message
      })
    case SMS_POST_FAILURE:
      return Object.assign({}, state, {
        postSuccess: false,
        isLoading: false,
        message: 'error'
      })
    case SET_POST_STATUS:
      return Object.assign({}, state, {
        postSuccess: action.data,
        isLoading: true,
        message: ''
      })
    case SET_POST_MESSAGE:
      return Object.assign({}, state, {
        message: ''
      })
    case IMPORTANT_SMS_FETCH_START:
      return Object.assign({}, state, {
        isLoadingGetImportantSms: true
      })
    case IMPORTANT_SMS_DATA:
      return Object.assign({}, state, {
        isLoadingGetImportantSms: false,
        impSms: action.data
      })
    case IMPORTANT_SMS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoadingGetImportantSms: false,
        message: action.errMsg
      })
    case GET_SMS_COUNT_START:
      return Object.assign({}, state, {
        isLoadingSmsLog: true
      })
    case GET_SMS_COUNT_DATA:
      return Object.assign({}, state, {
        isLoadingSmsLog: false,
        smsLog: action.data
      })
    case GET_SMS_COUNT_FAILURE:
      return Object.assign({}, state, {
        isLoadingSmsLog: false,
        isEorrorSmsLog: true
      })
    default:
      return state
  }
}
