import {
  APP_LOGOUT,
  START_NAVIGATION_FETCH,
  START_NAVIGATION_SUCCESS,
  START_NAVIGATION_FAIL,
  START_NAVIGATION_BY_USER_FETCH,
  START_NAVIGATION_BY_USER_SUCCESS,
  START_NAVIGATION_BY_USER_FAIL,
  DELETE_NAVIGATION_START,
  DELETE_NAVIGATION,
  DELETE_NAVIGATION_FAIL,
  PATCH_NAVIGATION_START,
  PATCH_NAVIGATION,
  PATCH_NAVIGATION_FAIL,
  POST_NAVIGATION_START,
  POST_NAVIGATION_SUCCESS,
  POST_NAVIGATION_FAIL,
  POST_SUB_NAVIGATION_START,
  POST_SUB_NAVIGATION_SUCCESS,
  POST_SUB_NAVIGATION_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingForUser: false,
  navigationArr: [],
  isErr: false,
  navigationForUser: [],
  isDeleteLoading: false,
  isEditLoading: false,
  isPostLoading: false,
  addedNavigation: [],
  isPostSubLoading: false,
  parentMsg: '',
  childMsg: ''
}

export const navigation = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_NAVIGATION_FETCH:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false
      })
    case START_NAVIGATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        navigationArr: action.data,
        isErr: false
      })
    case START_NAVIGATION_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true
      })
    case START_NAVIGATION_BY_USER_FETCH:
      return Object.assign({}, state, {
        isLoadingForUser: true,
        isErr: false
      })
    case START_NAVIGATION_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingForUser: false,
        navigationForUser: action.data,
        isErr: false
      })
    case START_NAVIGATION_BY_USER_FAIL:
      return Object.assign({}, state, {
        isLoadingForUser: false,
        isErr: true
      })
    case DELETE_NAVIGATION_START:
      return Object.assign({}, state, {
        isDeleteLoading: true,
        isErr: false
      })
    case DELETE_NAVIGATION:
      return Object.assign({}, state, {
        isDeleteLoading: false,
        isErr: false
      })
    case DELETE_NAVIGATION_FAIL:
      return Object.assign({}, state, {
        isDeleteLoading: false,
        isErr: true
      })
    case PATCH_NAVIGATION_START:
      return Object.assign({}, state, {
        isEditLoading: true,
        isErr: false
      })
    case PATCH_NAVIGATION:
      return Object.assign({}, state, {
        isEditLoading: false,
        isErr: false
      })
    case PATCH_NAVIGATION_FAIL:
      return Object.assign({}, state, {
        isEditLoading: false,
        isErr: true
      })
    case POST_NAVIGATION_START:
      return Object.assign({}, state, {
        isPostLoading: true,
        isErr: false,
        parentMsg: ''
      })
    case POST_NAVIGATION_SUCCESS:
      return Object.assign({}, state, {
        isPostLoading: false,
        addedNavigation: action.data,
        isErr: false,
        parentMsg: 'success'
      })
    case POST_NAVIGATION_FAIL:
      return Object.assign({}, state, {
        isPostLoading: false,
        isErr: true,
        parentMsg: 'fail'
      })
    case POST_SUB_NAVIGATION_START:
      return Object.assign({}, state, {
        isPostSubLoading: true,
        isErr: false,
        childMsg: ''
      })
    case POST_SUB_NAVIGATION_SUCCESS:
      return Object.assign({}, state, {
        isPostSubLoading: false,
        isErr: false,
        childMsg: 'success'
      })
    case POST_SUB_NAVIGATION_FAIL:
      return Object.assign({}, state, {
        isPostSubLoading: false,
        isErr: true,
        childMsg: 'fail'
      })
    default:
      return state
  }
}

export const getIsLoading = state => state.navigation.isLoading
export const getNavigation = state => state.navigation.navigationArr
export const getIsError = state => state.navigation.isErr
export const getIsLoadingForUser = state => state.navigation.isLoadingForUser
export const getNavigationForUser = state => state.navigation.navigationForUser
export const getIsDeleteLoading = state => state.navigation.isDeleteLoading
export const getIsEditLoading = state => state.navigation.isEditLoading
export const getIsPostLoading = state => state.navigation.isPostLoading
export const getIsPostSubLoading = state => state.navigation.isPostSubLoading
export const getAddedNavigation = state => state.navigation.addedNavigation
export const getParentMsg = state => state.navigation.parentMsg
export const getChildMsg = state => state.navigation.childMsg
