// import packages
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const RenderGroups = ({
  groups,
  groupState,
  handleUmbrellaGroupChange,
  handleGroupChange
}) => {
  return (
    <div>
      <form>
        {Object.keys(groupState).map(umbrellaGroup => {
          return (
            <div className="group" key={umbrellaGroup}>
              <label className="group-select group-select--all">
                <input
                  type="checkbox"
                  name={umbrellaGroup}
                  onChange={handleUmbrellaGroupChange}
                  checked={groupState[umbrellaGroup].checked}
                />
                <span className="square">
                  <span className="tick" />
                </span>
                <span className="text">{umbrellaGroup}</span>
              </label>
              <div className="group-select-ctr">
                {Object.keys(groupState[umbrellaGroup]).map(groupId => {
                  return (
                    <RenderEachGroup
                      key={groupId}
                      groupId={groupId}
                      groupState={groupState}
                      umbrellaGroup={umbrellaGroup}
                      handleGroupChange={handleGroupChange}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </form>
    </div>
  )
}

RenderGroups.propTypes = {
  groups: PropTypes.object.isRequired,
  //  message: PropTypes.string.isRequired,
  groupState: PropTypes.object.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sections: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  departmentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.object.isRequired,
  departments: PropTypes.array.isRequired,
  handleUmbrellaGroupChange: PropTypes.func.isRequired,
  handleGroupChange: PropTypes.func.isRequired
}

const RenderEachGroup = ({
  groupId,
  groupState,
  umbrellaGroup,
  handleGroupChange
}) => {
  return (
    groupId !== 'checked' && (
      <label className="group-select">
        <input
          type="checkbox"
          name={groupId}
          checked={groupState[umbrellaGroup][groupId].checked}
          onChange={handleGroupChange}
        />
        <span className="square">
          <span className="tick" />
        </span>
        <span className="text">{groupState[umbrellaGroup][groupId].name}</span>
      </label>
    )
  )
}

RenderEachGroup.propTypes = {
  groupState: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  umbrellaGroup: PropTypes.string.isRequired,
  handleGroupChange: PropTypes.func.isRequired
}

const ShowSelectionDetails = ({
  groupState,
  classes,
  sections,
  departmentCount,
  group_type
}) => {
  return (
    <div className="msg-reciever">
      <p className="msg-reciever__recipient">
        Sections : <span className="num">{sections}</span>
      </p>
      {group_type === 'academics' && (
        <p className="msg-reciever__recipient msg-reciever__recipient--light">
          Teacher Departments: <span className="num">{departmentCount}</span>
        </p>
      )}
    </div>
  )
}

ShowSelectionDetails.propTypes = {
  groupState: PropTypes.object.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sections: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

//TODO: check if this is still relevant
export default withRouter(RenderGroups)
