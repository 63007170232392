import {
  POST_NOTES_START,
  POST_NOTES_SUCCESS,
  POST_NOTES_FAIL,
  GET_NOTES_BY_ID_START,
  GET_NOTES_BY_ID_SUCCESS,
  GET_NOTES_BY_ID_FAIL,
  GET_NOTES_BY_GROUP_START,
  GET_NOTES_BY_GROUP_SUCCESS,
  GET_NOTES_BY_GROUP_FAIL,
  PATCH_NOTES_BY_ID_START,
  PATCH_NOTES_BY_ID_SUCCESS,
  PATCH_NOTES_BY_ID_FAIL,
  DELETE_NOTES_BY_ID_START,
  DELETE_NOTES_BY_ID_SUCCESS,
  DELETE_NOTES_BY_ID_FAIL,
  APP_LOGOUT
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  errMsg: '',
  status: '',
  successMsg: '',
  notesData: []
}

export const notes = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_NOTES_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case POST_NOTES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'posted'
      })
    case POST_NOTES_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        status: ''
      })
    case GET_NOTES_BY_ID_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_NOTES_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        payslipData: action.data,
        status: 'fetched'
      })
    case GET_NOTES_BY_ID_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case GET_NOTES_BY_GROUP_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case GET_NOTES_BY_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        notesData: action.data,
        status: 'fetched'
      })
    case GET_NOTES_BY_GROUP_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case PATCH_NOTES_BY_ID_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case PATCH_NOTES_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'patch'
      })
    case PATCH_NOTES_BY_ID_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    case DELETE_NOTES_BY_ID_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        status: ''
      })
    case DELETE_NOTES_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        status: 'delete'
      })
    case DELETE_NOTES_BY_ID_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error,
        status: ''
      })
    default:
      return state
  }
}

export const getIsLoading = state => state.notes.isLoading
export const getIsError = state => state.notes.isErr
export const getErrorMessage = state => state.notes.errMsg
export const getStatus = state => state.notes.status
export const getNotes = state => state.notes.notesData
