import {
  APP_LOGOUT,
  SAVE_DATA_FOR_STUDENT_PROFILE,
  SET_ACTIVE_STUDENT,
  CLEAR_ACTIVE_STUDENT
} from '../../helpers/actions'

const initialState = {
  groupId: '',
  umbrellaGroup: '',
  groupType: '',
  activeStudent: '',
  academicYear: '',
  studentlistGroupId: '',
  studentlistUmbrellaGroup: '',
  groupName: ''
}

export const studentProfile = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SAVE_DATA_FOR_STUDENT_PROFILE:
      return Object.assign({}, state, action.data)
    case SET_ACTIVE_STUDENT:
      return Object.assign({}, state, { activeStudent: action.studentId })
    case CLEAR_ACTIVE_STUDENT:
      return Object.assign({}, state, { activeStudent: '' })
    default:
      return state
  }
}

export const getGroupType = state => state.ui.studentProfile.groupType
export const getUmbrellaGroup = state => state.ui.studentProfile.umbrellaGroup
export const getGroupName = state => state.ui.studentProfile.groupName
export const getGroupId = state => state.ui.studentProfile.groupId
export const getStudentlistGroupId = state =>
  state.ui.studentProfile.studentlistGroupId
export const getActiveStudent = state => state.ui.studentProfile.activeStudent
export const getSelectedAcademicYear = state =>
  state.ui.studentProfile.academicYear
export const getStudentListGroupId = state =>
  state.ui.studentProfile.studentlistGroupId
export const getStudentListUmbrellaGroup = state =>
  state.ui.studentProfile.studentlistUmbrellaGroup
