// import packages
import React, { Component } from 'react'

class TableHead extends Component {
  render() {
    const { tableHeaders } = this.props
    return (
      <thead className="table-head table-head--dark">
        <tr className="table-head-row">
          {tableHeaders.map((key, index) => {
            return (
              <th
                key={index}
                colSpan={key.subHeader.length}
                rowSpan={key.subHeader.length ? 1 : 2}
              >
                {key.groupBy}
              </th>
            )
          })}
        </tr>
        <tr className="table-head-row">
          {tableHeaders
            .filter(item => item.subHeader && item.subHeader.length)
            .map(key => {
              return key.subHeader.map((item, index) => {
                return <th key={index}>{item.label}</th>
              })
            })}
        </tr>
      </thead>
    )
  }
}

export default TableHead
