import {
  APP_LOGIN_START,
  APP_LOGIN,
  APP_LOGIN_PARENT,
  APP_LOGIN_FAIL,
  APP_LOGOUT,
  APP_AUTH_FAILED,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  APP_PARENT_LEVEL,
  APP_LOGIN_SUPPORT,
  APP_SUPPORT_LEVEL
} from '../helpers/actions'

import { createSelector } from 'reselect'

const initialState = {
  jwt: '',
  name: '',
  level: '',
  roles: [],
  level_id: '',
  username: '',
  institutionId: '',
  error: false,
  isLoggingIn: false,
  isloggedIn: false,
  message: '',
  isLoading: false,
  parentJwt: '',
  siblings: [],
  supportJwt: '',
  isSupportLoggingIn: false
}

export const getJwt = state => state.accounts.jwt
export const getName = state => state.accounts.name
export const getLoggingIn = state => state.accounts.isLoggingIn
export const getIsloggedIn = state => state.accounts.isloggedIn
export const isSupportLoggedIn = state => state.accounts.isSupportLoggingIn
export const isLoggedIn = state => {
  const {
    isLoggingIn,
    jwt,
    parentJwt,
    level,
    supportJwt,
    isSupportLoggingIn
  } = state.accounts

  if (level == 'parent') {
    return !isLoggingIn && parentJwt && parentJwt.length !== 0
  } else if (isSupportLoggingIn) {
    return isSupportLoggingIn && supportJwt && supportJwt.length !== 0
  }
  // else if (level == 'support') {
  //   console.log("supportJwt: ", supportJwt)
  //   return !isSupportLoggingIn && supportJwt && supportJwt.length !== 0
  // }
  else {
    return !isLoggingIn && jwt.length !== 0 && !isSupportLoggingIn
  }
}
export const getLevelId = state => state.accounts.level_id
export const getLevel = state => state.accounts.level
export const getRoles = state => state.accounts.roles

export const getError = state => state.accounts.error
export const getMsg = state => state.accounts.message
export const isLoading = state => state.accounts.isLoading

export const getParentJwt = state => state.accounts.parentJwt
export const getSiblings = state => state.accounts.siblings
export const getSupportJwt = state => state.accounts.supportJwt

export const getErrorState = createSelector(getError, getMsg, (err, msg) => ({
  isTrue: err,
  msg
}))

export const accounts = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case APP_LOGIN_START:
      return Object.assign({}, state, {
        isLoggingIn: true,
        isloggedIn: false,
        error: false,
        message: ''
      })
    case APP_LOGIN:
      return Object.assign({}, state, {
        isLoggingIn: false,
        isSupportLoggingIn: false,
        error: false,
        message: '',
        jwt: action.jwt,
        name: action.name,
        level: action.level,
        roles: action.roles,
        level_id: action.level_id,
        username: action.username,
        isloggedIn: true,
        institutionId: action.institutionId
      })
    case APP_LOGIN_PARENT:
      return Object.assign({}, state, {
        isLoggingIn: false,
        error: false,
        message: '',
        parentJwt: action.jwt,
        name: action.name,
        level: action.level,
        roles: action.roles,
        level_id: action.level_id,
        username: action.username,
        institutionId: action.institutionId,
        isloggedIn: true,
        siblings: action.students
      })
    case APP_LOGIN_SUPPORT:
      return Object.assign({}, state, {
        isLoggingIn: false,
        isSupportLoggingIn: true,
        error: false,
        message: '',
        supportJwt: action.jwt,
        name: action.name,
        level: action.level,
        roles: action.roles,
        level_id: action.level_id,
        isloggedIn: true,
        username: action.username
      })
    case APP_LOGIN_FAIL:
      return Object.assign({}, state, {
        error: true,
        isLoggingIn: false,
        isloggedIn: false,
        isSupportLoggedIn: false,
        message: 'Incorrect username/password. Please try again.'
      })
    case APP_AUTH_FAILED:
      return Object.assign({}, initialState, {
        error: true,
        message: 'Session Expired, please login again.'
      })
    case CHANGE_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case RESET_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case APP_PARENT_LEVEL:
      return Object.assign({}, state, {
        level: 'parent'
      })
    case APP_SUPPORT_LEVEL:
      return Object.assign({}, state, {
        isLoggingIn: false,
        isSupportLoggingIn: true,
        error: false
      })
    default:
      return state
  }
}
