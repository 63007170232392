import {
  SAVE_CLASS_AND_SECTION_FOR_ATTENDANCE,
  SAVE_GROUPSELECTOR_DATA_FOR_ATTENDANCE,
  SAVE_START_AND_END_DATE,
  SAVE_DATA,
  SAVE_DATA_FOR_UI_ATTENDANCE,
  SET_IN_PROGRESS,
  APP_LOGOUT,
  SAVE_STUDENTS_FOR_ATTENDANCE,
  SAVE_YEAR_MONTH_FOR_ATTENDANCE,
  SAVE_DATA_FOR_WEEKLY_ATTENDANCE
} from '../../helpers/actions'

import moment from 'moment'

const initialState = {
  umbrellaGroup: '',
  groupId: '',
  date: moment(),
  startDate: moment(),
  endDate: moment(),
  startRange: 0,
  endRange: 100,
  subjectId: '',
  hourId: '',
  inProgress: false,
  studentIds: [],
  month: 0,
  year: ''
}

export const attendance = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SAVE_DATA_FOR_UI_ATTENDANCE:
      return Object.assign({}, state, action.data)
    case SAVE_CLASS_AND_SECTION_FOR_ATTENDANCE:
      return Object.assign({}, state, {
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId
      })
    case SAVE_START_AND_END_DATE:
      return Object.assign({}, state, {
        startDate: action.startDate,
        endDate: action.endDate
      })
    case SAVE_DATA:
      return Object.assign({}, state, action.data)
    case SET_IN_PROGRESS:
      return Object.assign({}, state, {
        inProgress: action.inProgress
      })
    case SAVE_GROUPSELECTOR_DATA_FOR_ATTENDANCE:
      return Object.assign({}, state, action.data)
    case SAVE_STUDENTS_FOR_ATTENDANCE:
      return Object.assign({}, state, {
        studentIds: action.data
      })
    case SAVE_YEAR_MONTH_FOR_ATTENDANCE:
      return Object.assign({}, state, {
        year: action.year,
        month: action.month
      })
    case SAVE_DATA_FOR_WEEKLY_ATTENDANCE:
      return Object.assign({}, state, {
        date: action.date,
        umbrellaGroup: action.umbrellaGroup,
        groupId: action.groupId
      })
    default:
      return state
  }
}

export const umbrellaGroup = state => state.ui.attendance.umbrellaGroup
export const groupId = state => state.ui.attendance.groupId
export const getStartDate = state => state.ui.attendance.startDate
export const getEndDate = state => state.ui.attendance.endDate
export const getSubjectId = state => state.ui.attendance.subjectId
export const getHourId = state => state.ui.attendance.hourId
export const getProgressStatus = state => state.ui.attendance.inProgress
export const getDate = state => state.ui.attendance.date
export const getStartRange = state => state.ui.attendance.startRange
export const getEndRange = state => state.ui.attendance.endRange
export const getAcademicYear = state => state.ui.attendance.year
export const getMonth = state => state.ui.attendance.month
