import merge from 'lodash/merge'
import { createSelector } from 'reselect'
import underscore from 'underscore'
import moment from 'moment'
import { DAYS } from '../helpers/enums'

import {
  APP_LOGOUT,
  ATTENDANCE_DATA,
  ATTENDANCE_GET_STARTED,
  ATTENDANCE_STUDENT_DATA,
  ATTENDANCE_STUDENT_DATA_HOURLY,
  ATTENDANCE_POST_START,
  ATTENDANCE_POST_FAILURE,
  ATTENDANCE_POST_SUCCESS,
  ATTENDANCE_PER_GROUP,
  HOURLY_ATTENDANCE_DATA,
  HOURLY_ATTENDANCE_PER_GROUP,
  HOURLY_ATTENDANCE_REPORT,
  // hourly bulk attendance month wise fetch
  HOURLY_ATTENDANCE_FETCH_START,
  HOURLY_ATTENDANCE_FETCH_FAIL,
  HOURLY_ATTENDANCE_MONTH_WISE_FETCH,
  // daily bulk attendance month wise fetch
  DAILY_ATTENDANCE_FETCH_START,
  DAILY_ATTENDANCE_MONTH_WISE_FETCH,
  DAILY_ATTENDANCE_FETCH_FAIL,
  // hourly bulk attendance post
  BULK_ATTENDANCE_POST_DATA,
  SET_ATTENDANCE_STATUS,
  RESET_BULK_ATTENDANCE_POST_OBJECT,
  HOURLY_ATTENDANCE_REPORT_PER_GROUP,
  RESET_ATTENDANCE_STATUS,
  // marked and bulk data,
  MARKED_AND_BULK_DATA,
  MARKED_AND_BULK_DATA_FAIL,
  BULK_DATA,
  //fetch all students attendance
  GET_ALL_STUDENTS_ATTENDANCE_START,
  GET_ALL_STUDENTS_ATTENDANCE_SUCCESS,
  GET_ALL_STUDENTS_ATTENDANCE_FAIL,
  GET_ATTENDANCE_REMARKS_START,
  GET_ATTENDANCE_REMARKS_SUCCESS,
  GET_ATTENDANCE_REMARKS_FAIL,
  DELETE_ATTENDANCE_START,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAIL
} from '../helpers/actions.js'

import { groupId, getSubjectId } from './ui/attendance'
import { groupsById, isLoading as groupsLoading } from './groups'

const initialState = {
  isLoading: false,
  date: [],
  byStudent: {},
  allStudents: [],
  byStudentHourly: {},
  allStudentsHourly: [],
  attendancePerGroup: [],
  hourlyAttendancePerGroup: [],
  hourlyAttendanceReport: [],
  hourlyAttendanceByMonth: [],
  dailyAttendanceByMonth: [],
  bulkAttendancePostObj: {},
  attendanceStatus: {},
  // marked and bulk data(hourly),
  markedAndBulkData: {},
  /*set by button dispatch, reset by POST finish*/
  isDirty: false,
  attendancePostStatus: '',
  postInProgress: false,
  hourlyAttendanceReportPerGroup: {},
  bulkData: [],
  allStudentsAttendance: [],
  isAttendanceRemarksLoading: false,
  attendanceRemarks: [],
  deleteAttendanceLoading: false,
  attendanceDeleteErr: false
}

export const attendance = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case ATTENDANCE_DATA: {
      const byStudent = merge({}, state.byStudent, action.attendance)
      const allStudents = merge([], state.allStudents, action.result)
      return Object.assign({}, state, {
        isLoading: false,
        date: action.date,
        byStudent,
        allStudents,
        attendancePostStatus: ''
      })
    }
    case HOURLY_ATTENDANCE_DATA: {
      const byStudentHourly = merge(
        {},
        state.byStudentHourly,
        action.attendance
      )
      const allStudentsHourly = merge(
        {},
        state.allStudentsHourly,
        action.result
      )
      return Object.assign({}, state, {
        isLoading: false,
        date: [action.date],
        byStudentHourly,
        allStudentsHourly,
        attendancePostStatus: ''
      })
    }
    case ATTENDANCE_PER_GROUP:
      return Object.assign({}, state, {
        attendancePerGroup: action.data,
        isLoading: false
      })
    case HOURLY_ATTENDANCE_PER_GROUP:
      return Object.assign({}, state, {
        hourlyAttendancePerGroup: action.data,
        isLoading: false
      })
    case HOURLY_ATTENDANCE_REPORT:
      return Object.assign({}, state, {
        hourlyAttendanceReport: action.data,
        isLoading: false
      })
    case ATTENDANCE_GET_STARTED:
      return Object.assign({}, state, {
        isLoading: true,
        date: action.date,
        attendancePostStatus: ''
      })
    case ATTENDANCE_STUDENT_DATA: {
      const student = action.student
      const attendance = {}
      attendance[student] = {
        student,
        attendance: action.data
      }
      const byStudent = merge({}, state.byStudent, attendance)
      return Object.assign({}, state, {
        byStudent,
        isDirty: true,
        attendancePostStatus: '',
        isLoading: false
      })
    }
    case ATTENDANCE_STUDENT_DATA_HOURLY: {
      const student = action.student
      const attendance = {}
      attendance[student] = {
        student,
        attendance: action.data
      }
      const byStudentHourly = merge({}, state.byStudentHourly, attendance)
      return Object.assign({}, state, {
        byStudentHourly,
        isDirty: true,
        attendancePostStatus: '',
        isLoading: false
      })
    }
    case ATTENDANCE_POST_START:
      return { ...state, postInProgress: true }
    case ATTENDANCE_POST_SUCCESS:
      return Object.assign({}, state, {
        isDirty: false,
        attendancePostStatus: 'success',
        postInProgress: false
      })
    case ATTENDANCE_POST_FAILURE:
      return { ...state, attendancePostStatus: 'fail', postInProgress: false }
    case HOURLY_ATTENDANCE_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case HOURLY_ATTENDANCE_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case BULK_ATTENDANCE_POST_DATA:
      return Object.assign({}, state, {
        bulkAttendancePostObj: {
          ...state.bulkAttendancePostObj,
          [action.data.student]: action.data
        },
        postInProgress: false
      })
    case HOURLY_ATTENDANCE_MONTH_WISE_FETCH:
      return Object.assign({}, state, {
        hourlyAttendanceByMonth: action.data,
        isLoading: false
      })
    case DAILY_ATTENDANCE_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case DAILY_ATTENDANCE_MONTH_WISE_FETCH:
      return Object.assign({}, state, {
        dailyAttendanceByMonth: action.data,
        isLoading: false
      })
    case DAILY_ATTENDANCE_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case SET_ATTENDANCE_STATUS:
      return Object.assign({}, state, {
        attendanceStatus: Object.assign({}, state.attendanceStatus, action.data)
      })
    case RESET_ATTENDANCE_STATUS:
      return Object.assign({}, state, {
        attendanceStatus: {}
      })
    case RESET_BULK_ATTENDANCE_POST_OBJECT:
      return Object.assign({}, state, {
        bulkAttendancePostObj: {}
      })
    case HOURLY_ATTENDANCE_REPORT_PER_GROUP:
      return Object.assign({}, state, {
        hourlyAttendanceReportPerGroup: action.data,
        isLoading: false
      })
    case MARKED_AND_BULK_DATA:
      return Object.assign({}, state, {
        markedAndBulkData: action.data,
        isLoading: false
      })
    case BULK_DATA:
      return Object.assign({}, state, {
        bulkData: action.data,
        isLoading: false
      })
    case MARKED_AND_BULK_DATA_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case GET_ALL_STUDENTS_ATTENDANCE_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case GET_ALL_STUDENTS_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        allStudentsAttendance: action.data
      })
    case GET_ALL_STUDENTS_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case GET_ATTENDANCE_REMARKS_START:
      return Object.assign({}, state, {
        isAttendanceRemarksLoading: true
      })
    case GET_ATTENDANCE_REMARKS_SUCCESS:
      return Object.assign({}, state, {
        isAttendanceRemarksLoading: false,
        attendanceRemarks: action.data
      })
    case GET_ATTENDANCE_REMARKS_FAIL:
      return Object.assign({}, state, {
        isAttendanceRemarksLoading: false
      })
    case DELETE_ATTENDANCE_START:
      return Object.assign({}, state, {
        deleteAttendanceLoading: true,
        attendanceDeleteErr: false
      })
    case DELETE_ATTENDANCE_SUCCESS:
      return Object.assign({}, state, {
        deleteAttendanceLoading: false,
        attendanceDeleteErr: false
      })
    case DELETE_ATTENDANCE_FAIL:
      return Object.assign({}, state, {
        deleteAttendanceLoading: false,
        attendanceDeleteErr: true
      })
    default:
      return state
  }
}

/* Selectors */
export const isLoading = state => state.attendance.isLoading
export const getDate = state => state.attendance.date
export const getAttendance = state => state.attendance.byStudent
export const getAttendanceHourly = state => state.attendance.byStudentHourly
export const getStudents = state => state.attendance.allStudents
export const getbulkData = state => state.attendance.bulkData
export const attendancePostStatus = state =>
  state.attendance.attendancePostStatus
export const attendancePerGroup = state => state.attendance.attendancePerGroup
export const getHourlyAttendancePerGroup = state =>
  state.attendance.hourlyAttendancePerGroup

// hourly bulk attendance post object
export const bulkAttendancePostObj = state =>
  state.attendance.bulkAttendancePostObj

export const hourlyAttendanceByMonth = state =>
  state.attendance.hourlyAttendanceByMonth

export const dailyAttendanceByMonth = state =>
  state.attendance.dailyAttendanceByMonth

export const attendanceStatus = state => state.attendance.attendanceStatus

export const getHourlyAttendanceReportPerGroup = state =>
  state.attendance.hourlyAttendanceReportPerGroup

// get markedAndBulkData
export const markedAndBulkData = state => state.attendance.markedAndBulkData

//all studentGroups attendance
export const getAllStudentsAttendance = state =>
  state.attendance.allStudentsAttendance

export const getAttendanceRemarksLoading = state =>
  state.attendance.isAttendanceRemarksLoading
export const getAttendanceRemarks = state => state.attendance.attendanceRemarks
export const getDeleteAttendanceLoading = state =>
  state.attendance.deleteAttendanceLoading
export const getAttendanceDeleteError = state =>
  state.attendance.attendanceDeleteErr

export const getAttendanceVals = createSelector(
  getAttendance,
  getStudents,
  (byStudent, students) => {
    return students.map(student => {
      return {
        student,
        attendance: byStudent[student].attendance
      }
    })
  }
)

export const getAttendancePerGroupSorted = createSelector(
  attendancePerGroup,
  groupsById,
  groupsLoading,
  (attendanceArr, groupsById, groupsLoading) => {
    if (
      groupsLoading ||
      Object.keys(groupsById).length === 0 ||
      attendanceArr.length === 0
    )
      return []
    return attendanceArr.sort((a, b) => {
      const aValue = groupsById[a._id]
        ? groupsById[a._id]?.orderUmbrella_group * 10 +
          groupsById[a._id]?.orderGroup
        : 0
      const bValue = groupsById[b._id]
        ? groupsById[b._id]?.orderUmbrella_group * 10 +
          groupsById[b._id]?.orderGroup
        : 0
      return aValue - bValue
    })
  }
)

export const getHourlyAttendancePerGroupSorted = createSelector(
  getHourlyAttendancePerGroup,
  groupsById,
  groupsLoading,
  (attendanceArr, groupsById, groupsLoading) => {
    if (
      groupsLoading ||
      Object.keys(groupsById).length === 0 ||
      attendanceArr.length === 0
    )
      return []
    return attendanceArr.sort((a, b) => {
      const aValue =
        groupsById[a._id]?.orderUmbrella_group * 10 +
        groupsById[a._id]?.orderGroup
      const bValue =
        groupsById[b._id]?.orderUmbrella_group * 10 +
        groupsById[b._id]?.orderGroup
      return aValue - bValue
    })
  }
)

export const getAttendanceValsPerGroup = createSelector(
  getAttendance,
  groupsById,
  groupId,
  (byStudent, groupsById, groupId) => {
    if (
      Object.keys(byStudent).length === 0 ||
      Object.keys(groupsById).length === 0 ||
      groupId === ''
    )
      return []
    const studentIds = groupsById[groupId].students
    if (studentIds.length === 0) return []
    return Object.keys(byStudent)
      .filter(studentId => studentIds.includes(studentId))
      .map(studentId => {
        return {
          student: studentId,
          attendance: byStudent[studentId].attendance
        }
      })
  }
)

export const getAttendanceValsPerGroupHourly = createSelector(
  getAttendanceHourly,
  groupsById,
  groupId,
  getSubjectId,
  (byStudent, groupsById, groupId, subjectId) => {
    if (
      Object.keys(byStudent).length === 0 ||
      Object.keys(groupsById).length === 0 ||
      groupId === '' ||
      subjectId === ''
    )
      return []
    const [subjectStudents] = groupsById[groupId].subjects
      .filter(subObj => subObj.subject_id === subjectId)
      .map(subObj => subObj.student_id)
    const studentIds = underscore.intersection(
      groupsById[groupId].students,
      subjectStudents
    )
    if (studentIds.length === 0) return []
    return Object.keys(byStudent)
      .filter(studentId => studentIds.includes(studentId))
      .map(studentId => {
        return {
          student: studentId,
          attendance: byStudent[studentId].attendance
        }
      })
  }
)

export const getHourlyAttendanceReport = state =>
  state.attendance.hourlyAttendanceReport

export const getHourlyReportByStudent = createSelector(
  getHourlyAttendanceReport,
  reportArr => {
    if (reportArr.length === 0) return {}
    const obj = {}
    reportArr.forEach(reportObj => {
      if (Object.keys(obj).indexOf(reportObj.student) === -1) {
        obj[reportObj.student] = {}
        obj[reportObj.student][reportObj.subject] = {}
        obj[reportObj.student][reportObj.subject].attendance =
          reportObj.attendance
        obj[reportObj.student][reportObj.subject].total = 1
      } else {
        if (
          Object.keys(obj[reportObj.student]).indexOf(reportObj.subject) === -1
        ) {
          obj[reportObj.student][reportObj.subject] = {}
          obj[reportObj.student][reportObj.subject].attendance =
            reportObj.attendance
          obj[reportObj.student][reportObj.subject].total = 1
        } else {
          const att = obj[reportObj.student][reportObj.subject].attendance
          let total = obj[reportObj.student][reportObj.subject].total
          obj[reportObj.student][reportObj.subject].attendance =
            att + reportObj.attendance
          obj[reportObj.student][reportObj.subject].total = ++total
        }
      }
    })
    return obj
  }
)

// get hourly attendance per month by student
export const initialValuesForHourlyAttendanceByMonth = createSelector(
  hourlyAttendanceByMonth,
  hourlyAttendanceByMonthArr => {
    if (hourlyAttendanceByMonthArr.length === 0) return {}
    const obj = {}
    hourlyAttendanceByMonthArr.forEach((attdObj, index) => {
      if (index === 0) {
        obj.total_days = attdObj.total_days
      }
      obj[attdObj.student] = attdObj.attendance
    })
    return obj
  }
)

// map studentId to classes attended (bulk attendance)  - daily attendance
export const initialValuesForDailyAttendanceByMonth = createSelector(
  dailyAttendanceByMonth,
  dailyAttendanceByMonthArr => {
    if (dailyAttendanceByMonthArr.length === 0) return {}
    const obj = {}
    dailyAttendanceByMonthArr.forEach((attdObj, index) => {
      if (index === 0) {
        obj.total_days = attdObj.total_days
      }
      obj[attdObj.student] = attdObj.attendance
    })
    return obj
  }
)

// get studentId to attd obj (bulk attendance) - daily attendance
export const dailyBulkAttendanceByStudent = createSelector(
  dailyAttendanceByMonth,
  dailyAttendanceByMonthArr => {
    if (dailyAttendanceByMonthArr.length === 0) return {}
    const obj = {}
    dailyAttendanceByMonthArr.forEach((attdObj, index) => {
      obj[attdObj.student] = attdObj
    })
    return obj
  }
)

export const getHourlyAttendancePerMonthByStudent = createSelector(
  hourlyAttendanceByMonth,
  hourlyAttendanceByMonthArr => {
    if (hourlyAttendanceByMonthArr.length === 0) return {}
    const obj = {}
    hourlyAttendanceByMonthArr.forEach(attdObj => {
      obj[attdObj.student] = attdObj
    })
    return obj
  }
)

// get attendance per group by hour
export const getAttendancePerGroupByHour = createSelector(
  getHourlyAttendancePerGroup,
  attendanceArr => {
    if (attendanceArr.length === 0) return {}
    const obj = {}
    attendanceArr.forEach(attdObj => {
      const { _id: groupId, hour, status } = attdObj
      if (Object.keys(obj).includes(hour)) {
        obj[hour][groupId] = status
      } else {
        obj[hour] = {
          [groupId]: status
        }
      }
    })
    return obj
  }
)

// get total marked and bulk attendance data
export const getTotalMarkedAndBulkData = createSelector(
  markedAndBulkData,
  dataObj => {
    if (Object.keys(dataObj).length === 0) return {}
    const obj = {}
    Object.keys(dataObj).forEach(studentId => {
      const studentObjWithSubjectIds = dataObj[studentId]
      Object.keys(studentObjWithSubjectIds).forEach(subjectId => {
        const subjectObjwithMonths = studentObjWithSubjectIds[subjectId]
        let attendance = 0
        let total_days = 0
        Object.keys(subjectObjwithMonths).forEach(month => {
          const {
            attendance: existingAttendance,
            total_days: existingTotalDays
          } = subjectObjwithMonths[month]
          attendance +=
            existingAttendance !== undefined ? existingAttendance : 0
          total_days += existingTotalDays !== undefined ? existingTotalDays : 0
        })

        obj[studentId] = {
          ...obj[studentId],
          [subjectId]: {
            attendance,
            total_days
          }
        }
      })
    })
    return obj
  }
)

export const monthWiseHourlyReportByStudent = createSelector(
  hourlyAttendanceByMonth,
  hourlyAttendanceByMonthArr => {
    if (hourlyAttendanceByMonthArr.length === 0) return {}
    const obj = {}
    hourlyAttendanceByMonthArr.forEach(attdObj => {
      const { student, month } = attdObj
      if (Object.keys(obj).includes(student)) {
        obj[student][month] = attdObj
      } else {
        obj[student] = {
          [month]: attdObj
        }
      }
    })
    return obj
  }
)

export const getInitialValuesForCorrectionAttendance = createSelector(
  monthWiseHourlyReportByStudent,
  reportObj => {
    if (Object.keys(reportObj).length === 0) return {}
    const obj = {}
    Object.keys(reportObj).forEach((studentId, index) => {
      const studentObjWithMonths = reportObj[studentId]
      // calculate total days
      if (index === 0) {
        let totalDays = 0
        Object.keys(studentObjWithMonths).forEach(month => {
          const { total_days } = Object.assign(
            { total_days: 0 },
            studentObjWithMonths[month]
          )
          totalDays = totalDays + total_days
        })
        obj.total_days = totalDays
      }
      // calculate total classes attended per month
      let totalClassesAttended = 0
      Object.keys(studentObjWithMonths).forEach(month => {
        const { attendance } = Object.assign(
          { attendance: 0 },
          studentObjWithMonths[month]
        )
        totalClassesAttended = totalClassesAttended + attendance
      })
      obj[studentId] = totalClassesAttended
    })
    return obj
  }
)

// shape hourly attendance report data for weekly report
export const shapeHourlyAttdDataForWeeklyReport = createSelector(
  getHourlyAttendanceReport,
  attdData => {
    if (attdData.length === 0) return {}
    const obj = {}
    attdData.forEach(dataObj => {
      const { student, date, hour } = dataObj
      const day = DAYS[moment(date).day()]
      if (Object.keys(obj).includes(student)) {
        const studentObjWithDates = obj[student]
        if (Object.keys(studentObjWithDates).includes(day)) {
          const dateObjWithHours = studentObjWithDates[day]
          dateObjWithHours[hour] = dataObj
        } else {
          studentObjWithDates[day] = {
            [hour]: dataObj
          }
        }
      } else {
        obj[student] = {
          [day]: {
            [hour]: dataObj
          }
        }
      }
    })
    return obj
  }
)

export const attdTakenHoursObj = createSelector(
  getHourlyAttendanceReport,
  attdArr => {
    if (attdArr.length === 0) return {}
    const obj = {}
    attdArr.forEach(attdObj => {
      const { hour, subject } = attdObj
      if (!Object.keys(obj).includes(hour)) {
        obj[hour] = subject
      }
    })
    return obj
  }
)
