import React from 'react'
import PropTypes from 'prop-types'
import { StudentListTable } from './studentListTable.jsx'
import FontAwesome from 'react-fontawesome'
import ReactTooltip from 'react-tooltip'

export const StudentList = ({
  studentData,
  subjectData,
  handleGroupBy,
  getSortIcon,
  getToolTip
}) => {
  const getToolTipInfo = header => (
    <ReactTooltip
      className="st-tooltip"
      place="bottom"
      type="light"
      id={header}
    >
      <div className="tooltip-text">{getToolTip(header)}</div>
    </ReactTooltip>
  )
  return (
    <div className="st-student-table">
      <div className="attd-report-table">
        <div className="row">
          <div className="col-lg-12">
            <table className="table">
              <thead>
                <tr className="table-head-row">
                  <th className="table-head-row__cell">
                    Roll No.
                    <FontAwesome
                      id="roll_number"
                      onClick={handleGroupBy}
                      name={getSortIcon('roll_number')}
                      data-for="roll_number"
                      data-tip
                    />
                    {getToolTipInfo('roll_number')}
                  </th>

                  <th className="table-head-row__cell">
                    Name{' '}
                    <FontAwesome
                      id="name"
                      onClick={handleGroupBy}
                      name={getSortIcon('name')}
                      data-for="name"
                      data-tip
                    />
                    {getToolTipInfo('name')}
                  </th>
                  {subjectData.map(obj => {
                    return (
                      <th key={obj._id} className="table-head-row__cell">
                        {obj.umbrellaName} {obj.name}
                        <FontAwesome
                          id={obj._id}
                          onClick={handleGroupBy}
                          name={getSortIcon(obj._id)}
                          data-for={obj._id}
                          data-tip
                        />
                        {getToolTipInfo(obj._id)}
                      </th>
                    )
                  })}
                  <th className="table-head-row__cell">
                    Total{' '}
                    <FontAwesome
                      id="total"
                      onClick={handleGroupBy}
                      name={getSortIcon('total')}
                      data-for="total"
                      data-tip
                    />
                    {getToolTipInfo('total')}
                  </th>
                  <th className="table-head-row__cell">
                    Percentage{' '}
                    <FontAwesome
                      id="percentage"
                      onClick={handleGroupBy}
                      name={getSortIcon('percentage')}
                      data-for="percentage"
                      data-tip
                    />
                    {getToolTipInfo('percentage')}
                  </th>
                  <th className="table-head-row__cell">
                    Section Position{' '}
                    <FontAwesome
                      id="sectionPosition"
                      onClick={handleGroupBy}
                      name={getSortIcon('sectionPosition')}
                      data-for="sectionPosition"
                      data-tip
                    />
                    {getToolTipInfo('sectionPosition')}
                  </th>
                  <th className="table-head-row__cell">
                    Class Position{' '}
                    <FontAwesome
                      id="classPosition"
                      onClick={handleGroupBy}
                      name={getSortIcon('classPosition')}
                      data-for="classPosition"
                      data-tip
                    />
                    {getToolTipInfo('classPosition')}
                  </th>
                </tr>
              </thead>
              <StudentListTable
                studentData={studentData}
                subjectData={subjectData}
                handleGroupBy={handleGroupBy}
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

StudentList.propsTypes = {
  studentData: PropTypes.array.isRequired,
  subjectData: PropTypes.array.isRequired,
  handleGroupBy: PropTypes.func.isRequired,
  getSortIcon: PropTypes.func.isRequired,
  getToolTip: PropTypes.func.isRequired
}
