import React from 'react'
import PropTypes from 'prop-types'
//enums
import { MARKS_REPORT } from '../../helpers/enums'
//componets
import StudentReportItem from './marksreportitem'

const MarksReportList = ({ downloadReportData, finalExamData }) => {
  return (
    <div className="add-student-form-outer person-info">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div id="academicInfoForm">
              <div className="add-student-form">
                {MARKS_REPORT.map((report, index) => (
                  <StudentReportItem
                    report={report}
                    index={index}
                    key={index}
                    downloadReportData={downloadReportData}
                  />
                ))}
                <div className="info-row">
                  <label className="info-cell info-cell--left" htmlFor="name">
                    Final Report
                  </label>
                  <span className="dash" />
                  <div className="info-cell info-row-certificate">
                    <button
                      className="button button--blue"
                      onClick={finalExamData}
                    >
                      download pdf
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
MarksReportList.propTypes = {
  downloadReportData: PropTypes.object.isRequired
}

export default MarksReportList
