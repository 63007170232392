import React, { Component } from 'react'
import PropTypes from 'prop-types'
import underscore from 'underscore'
import { connect } from 'react-redux'
import { PageHelmet } from '../common'
import $ from 'jquery'
import jquery from 'jquery'

import {
  uniqueParentGroups,
  filterSectionsByParent,
  getParentSubjectOfAnElective
} from '../../helpers/utils.js'
import {
  getAcademicGroups,
  getAcademicTeacherGroups,
  getTeacherGroupsById,
  isLoading as groupsLoading
} from '../../reducers/groups.js'
import { testsById, isLoading as isTestsLoading } from '../../reducers/tests.js'
import { getLevel, getLevelId, getRoles } from '../../reducers/accounts'
import { getLoading as studentsLoading } from '../../reducers/students'
import * as Subjects from '../../reducers/subjects.js'
import {
  startFetchGroups,
  startFetchGroupsForTeacher
} from '../../actions/groups'
import { startFetchTests } from '../../actions/tests.js'
import {
  saveGroupSelectorData,
  saveDataForMarksEntry
} from '../../actions/ui/marks'
import {
  startFetchSubjects,
  startFetchElectives
} from '../../actions/subjects.js'
import { startFetchData } from '../../actions/students'
import {
  emitResetMarksStatus,
  startFetchMarksForTestSubj
} from '../../actions/marks'

import { GroupSelector } from './groupSelector.jsx'
import { MarksEntry } from './marksEntry'
import Snackbar from './snackbar'
import Spinner from '../spinner'

class MarksComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    allData: PropTypes.object.isRequired,
    //marksReady: PropTypes.bool.isRequired,
    groups: PropTypes.object.isRequired,
    academicGroups: PropTypes.array.isRequired,
    tests: PropTypes.object.isRequired,
    //functions
    fetchGroups: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      umbrellaGroup: '',
      groupId: '',
      testId: '',
      subjectId: '',
      electiveId: '',
      studentsOfTests: [],
      selectedTests: [],
      selectedSubj: [],
      clickedGo: false,
      isDirty: false,
      showSnackbar: false,
      fetchData: true,
      showMainTest: false,
      shouldFetchTestsAndSubjects: false,
      fetchSubtestsAndElectives: false
    }
    this.filterTestsByGroup = this.filterTestsByGroup.bind(this)
    this.filterElectives = this.filterElectives.bind(this)
    this.handleUmbrellaChange = this.handleUmbrellaChange.bind(this)
    this.handleSectionChange = this.handleSectionChange.bind(this)
    this.handleSubjectChange = this.handleSubjectChange.bind(this)
    this.handleTestChange = this.handleTestChange.bind(this)
    this.handleElectiveChange = this.handleElectiveChange.bind(this)
    this.handleGo = this.handleGo.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.resetMarks = this.resetMarks.bind(this)
    this.showMaxMarksModalError = this.showMaxMarksModalError.bind(this)
    this.hideSnackbar = this.hideSnackbar.bind(this)
    this.filterSubtests = this.filterSubtests.bind(this)
    this.filterStudentsFromSubj = this.filterStudentsFromSubj.bind(this)
    this.filterSubjectsForSelector = this.filterSubjectsForSelector.bind(this)
    this.showElectives = this.showElectives.bind(this)
    this.handleShowMainTestChange = this.handleShowMainTestChange.bind(this)
    this.enterFuntion = this.enterFuntion.bind(this)
  }

  enterFuntion(e) {
    jquery.extend(jquery.expr[':'], {
      focusable: (el, index, selector) => {
        return $(el).is(' :input, [tabindex]')
      }
    })

    if (e.which === 13) {
      e.preventDefault()
      // Get all focusable elements on the page
      var $canfocus = $(':focusable')
      var index = $canfocus.index(document.activeElement) + 1
      if (index >= $canfocus.length) index = 0
      $canfocus.eq(index).focus()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterFuntion)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.enterFuntion)
    const { level, fetchTeacherGroups, jwt, fetchGroups } = this.props
    if (level === 'teacher') {
      fetchTeacherGroups({ jwt, type: 'academics' })
    } else {
      fetchGroups({ jwt, type: 'academics' })
    }
    this.setState({
      shouldFetchTestsAndSubjects: true,
      umbrellaGroup: this.props.allData.umbrellaGroup,
      groupId: this.props.allData.groupId,
      testId: this.props.allData.testId,
      subjectId: this.props.allData.subjectId,
      electiveId: this.props.allData.electiveId
    })
  }

  componentDidUpdate() {
    const {
      jwt,
      groups,
      isLoading: isGroupsLoading,
      subjectsLoading,
      subjectsById,
      testsLoading,
      tests,
      fetchTestsAndSubjects,
      resetMarksStatus,
      fetchTestsWithSelectedSubTest,
      fetchElectives,
      fetchStudents
    } = this.props
    const {
      shouldFetchTestsAndSubjects,
      fetchSubtestsAndElectives,
      groupId,
      umbrellaGroup,
      testId,
      subjectId,
      electiveId,
      clickedGo
    } = this.state
    /*
    When the group fetch has completed, fetch the subjects and tests of the
    selected section
    */
    if (
      !isGroupsLoading &&
      shouldFetchTestsAndSubjects &&
      umbrellaGroup &&
      testId &&
      subjectId
    ) {
      const { tests } = Object.assign({ tests: [] }, groups[groupId])
      const subjects = this.getGroupSubjects(groupId)
      fetchTestsAndSubjects({
        jwt,
        tests,
        subjects
      })
      this.setState({
        shouldFetchTestsAndSubjects: false,
        fetchSubtestsAndElectives: true
      })
    }

    /*
    When the tests and subjects have been fetched,
     - Fetch Students
     - Fetch Subtests if exists
     - Fetch Electives if exists
     - Save the students of the selected test, selected tests and selected subject
        in the state
    */

    if (
      fetchSubtestsAndElectives &&
      !isGroupsLoading &&
      !subjectsLoading &&
      !testsLoading &&
      umbrellaGroup &&
      groupId &&
      subjectId &&
      testId &&
      !clickedGo
    ) {
      const selectedSubjectId = electiveId !== '' ? electiveId : subjectId
      const subTests = this.filterSubtests(tests, testId, selectedSubjectId)
      // find students of subject , if elective exists then find its
      const studentsOfSelectedTest = this.filterStudentsFromSubj(
        subjectId,
        groups,
        groupId,
        electiveId
      )

      fetchStudents({ jwt, students: studentsOfSelectedTest })
      // fetch sub tests
      if (subTests.length !== 0) {
        fetchTestsWithSelectedSubTest({
          jwt,
          tests: subTests
        })
      }
      const selectedTests = subTests.length === 0 ? [testId] : subTests
      if (electiveId !== '') {
        const { electives } = Object.assign(
          { electives: [] },
          subjectsById[subjectId]
        )
        if (electives.length !== 0) {
          fetchElectives({ jwt, electives })
        }
      }
      resetMarksStatus({})

      this.setState({
        fetchSubtestsAndElectives: false,
        clickedGo: true,
        studentsOfTests: studentsOfSelectedTest,
        selectedTests,
        selectedSubj: electiveId !== '' ? [electiveId] : [subjectId]
      })
    }
  }

  // On change of max marks, render modal
  showMaxMarksModalError() {
    this.setState({
      showSnackbar: true
    })
  }
  // close function of max marks modal
  hideSnackbar() {
    this.setState({
      showSnackbar: false
    })
  }

  handleUmbrellaChange(e) {
    const { resetMarksStatus } = this.props
    this.setState(
      {
        umbrellaGroup: e.target.value,
        groupId: '',
        testId: '',
        subjectId: '',
        electiveId: '',
        clickedGo: false,
        isDirty: false,
        shouldFetchTestsAndSubjects: false,
        fetchSubtestsAndElectives: false
      },
      resetMarksStatus({})
    )
  }

  handleSectionChange(e) {
    const groupId = e.target.value
    this.setState(
      {
        groupId,
        testId: '',
        subjectId: '',
        electiveId: '',
        clickedGo: false,
        isDirty: false,
        fetchData: false,
        shouldFetchTestsAndSubjects: false,
        fetchSubtestsAndElectives: false
      },
      this.fetchData(groupId)
    )
  }

  fetchData(groupId) {
    const { jwt, groups, resetMarksStatus, fetchTestsAndSubjects } = this.props
    resetMarksStatus({})
    fetchTestsAndSubjects({
      jwt,
      tests: groups[groupId].tests,
      subjects: groups[groupId].subjects.map(subject => subject.subject_id)
    })
  }

  handleTestChange(e) {
    const { fetchTests, jwt, tests } = this.props
    const subtests = underscore.flatten(
      tests[e.target.value].subjects.map(sub => sub.tests)
    )
    if (subtests.length > 0) fetchTests({ jwt, tests: subtests })
    this.setState({
      testId: e.target.value,
      subjectId: '',
      electiveId: '',
      clickedGo: false,
      isDirty: false,
      shouldFetchTestsAndSubjects: false,
      fetchSubtestsAndElectives: false
    })
  }

  handleSubjectChange(e) {
    const { value } = e.target
    const { subjectsById, fetchElectives, jwt, resetMarksStatus } = this.props
    const electives = subjectsById[value].electives
    if (electives.length === 0) {
      resetMarksStatus({})
      this.setState({
        clickedGo: false,
        subjectId: value,
        electiveId: '',
        isDirty: true,
        fetchData: false,
        shouldFetchTestsAndSubjects: false,
        fetchSubtestsAndElectives: false
      })
    } else {
      const data = { jwt, electives }
      resetMarksStatus({})
      this.setState(
        {
          clickedGo: false,
          subjectId: value,
          electiveId: '',
          isDirty: false,
          shouldFetchTestsAndSubjects: false,
          fetchSubtestsAndElectives: false
        },
        fetchElectives(data)
      )
    }
  }

  handleElectiveChange(e) {
    const { resetMarksStatus } = this.props
    this.setState(
      {
        electiveId: e.target.value,
        clickedGo: false,
        isDirty: true,
        shouldFetchTestsAndSubjects: false,
        fetchSubtestsAndElectives: false
      },
      resetMarksStatus({})
    )
  }

  filterSubjectsForSelector() {
    const { subjects, tests, groups, subjectsLoading } = this.props
    const { testId, groupId } = this.state
    if (testId === '' || subjectsLoading || Object.keys(groups).length === 0)
      return []
    const groupSubjects = this.getGroupSubjects(groupId)
    const testSubjects = tests[testId].subjects.map(testObj => testObj.subject)
    if (groupSubjects.length === 0 || testSubjects.length === 0) return []
    return subjects
      .filter(subObj => subObj.is_main === true)
      .filter(subObj => testSubjects.includes(subObj.id))
      .filter(subObj => groupSubjects.includes(subObj.id))
      .map(subObj => {
        const { id, umbrellaName, name } = Object.assign(
          { id: '', umbrellaName: '', name: '' },
          subObj
        )
        return {
          id,
          name: `${umbrellaName} - ${name}`
        }
      })
  }

  /* return all electives for support n electives which teacher handles
  for a teacher
  */

  filterElectivesForSelector() {
    const { subjectsLoading, level, subjectsById, mainSubjectArr } = this.props
    const { subjectId, groupId } = this.state
    const showElectives = this.showElectives()
    if (!showElectives || subjectsLoading || subjectId === '') return []
    if (level === 'teacher') {
      return this.getTeacherElectiveIds()
    }
    const groupSubjectIds = this.getGroupSubjects(groupId)
    const testSubjectIds = this.getTestSubjectIds()
    const { electives } = Object.assign(
      { electives: [] },
      subjectsById[subjectId]
    )
    return electives
      .filter(electiveId => {
        const mainSubjectId = getParentSubjectOfAnElective(
          mainSubjectArr,
          electiveId
        )
        return (
          groupSubjectIds.includes(electiveId) &&
          testSubjectIds.includes(mainSubjectId)
        )
      })
      .map(electiveId => {
        const { name } = Object.assign({ name: '' }, subjectsById[electiveId])
        return { id: electiveId, name }
      })
      .concat({ id: 'all', name: 'All' })
  }

  // get the electiveIds which the teacher handles

  getTeacherElectiveIds() {
    const { groups, subjectsById, levelId } = this.props
    const { groupId, subjectId } = this.state
    const teacherElectiveIds = []
    const mainSubjectElectiveIds = subjectsById[subjectId].electives
    const groupSubjects = groups[groupId].subjects
    groupSubjects.forEach(subObj => {
      if (
        mainSubjectElectiveIds.includes(subObj.subject_id) &&
        subObj.teacher_id.includes(levelId)
      ) {
        teacherElectiveIds.push(subObj.subject_id)
      }
    })
    return teacherElectiveIds.map(electiveId => {
      const { _id: id, name } = subjectsById[electiveId]
      return { id, name }
    })
  }

  /* if level is teacher, return the subjects which teacher handles, else
    return all group subjects */

  getGroupSubjects(groupId) {
    const { level, groups, groupsLoading } = this.props
    if (level !== 'teacher') {
      if (groupsLoading || Object.keys(groups) === 0) {
        return []
      }
      return groups[groupId].subjects.map(subjObj => subjObj.subject_id)
    }
    return this.getTeacherSubjects(groupId)
  }

  getTeacherSubjects(groupId) {
    const { subjectsById, groups, levelId } = this.props
    const subjectIds = []
    const groupSubjects = groups[groupId].subjects
    groupSubjects.forEach(subjectObj => {
      if (subjectObj.teacher_id.includes(levelId)) {
        subjectIds.push(subjectObj.subject_id)
        if (subjectsById[subjectObj.subject_id].is_main === false) {
          groupSubjects.some(subObj => {
            if (
              subjectsById[subObj.subject_id] &&
              subjectsById[subObj.subject_id].electives.includes(
                subjectObj.subject_id
              )
            ) {
              subjectIds.push(subObj.subject_id)
              return true
            }
            return false
          })
        }
      }
    })
    return subjectIds
  }

  getTestSubjectIds = () => {
    const { testId } = this.state
    const { tests } = this.props
    if (!testId || Object.keys(tests).length === 0) return []
    const { subjects } = Object.assign({ subjects: [] }, tests[testId])
    return subjects.map(subObj => subObj.subject)
  }

  // filter tests of group selected and structure data
  filterTestsByGroup() {
    const { tests, groups, testsLoading, groupsLoading } = this.props
    const { groupId } = this.state
    if (testsLoading || groupsLoading) return []
    const arrTests =
      Boolean(groupId) &&
      tests !== undefined &&
      Object.keys(tests).length !== 0 &&
      Object.keys(groups).length !== 0
        ? groups[groupId].tests || []
        : []

    const notAllGroupTestsPresent = arrTests.some(
      testId => Object.keys(tests).indexOf(testId) === -1
    )
    if (notAllGroupTestsPresent) return []

    return arrTests
      .sort((testId1, testId2) => {
        return tests[testId1].order - tests[testId2].order
      })
      .map(testId => ({
        id: tests[testId]._id,
        name: tests[testId].name
      }))
  }
  // filter electives for subject selected
  filterElectives(subjects, subject) {
    const [subjectDetails] = subjects.filter(subj => {
      return subj.id === subject
    })
    return subjectDetails.electives
  }

  // reset marks `status` that is rendered with failure(type: object)
  resetMarks(failure) {
    this.props.resetMarksStatus(failure)
  }

  // check for subtest exist and return(if exist)
  filterSubtests(tests, testId, subjectId) {
    const { subjectId: mainSubjectId } = this.state
    const { subjectsById } = this.props
    const { subjects } = tests[testId]
    if (subjects.length === 0) return []
    let testIds = []
    if (subjectId === 'all') {
      const { electives } = Object.assign(
        { electives: [] },
        subjectsById[mainSubjectId]
      )
      electives.forEach(electiveId => {
        const subjectObj = subjects.find(
          subObj => subObj.subject === electiveId
        )
        if (subjectObj) {
          testIds = testIds.concat(subjectObj.tests)
        }
      })
    } else {
      const subjectObj = subjects.find(subObj => subObj.subject === subjectId)
      if (subjectObj) {
        testIds = testIds.concat(subjectObj.tests)
      }
    }
    return underscore.uniq(testIds)
  }
  // return student ids enrolled for the subject
  filterStudentsFromSubj(subjectId, groups, groupId, electiveId) {
    const subjects = groups[groupId].subjects
    if (subjects.length === 0) return []
    const returnSubject = subject => {
      let finalSubjectId = subject
      if (subject === 'all') finalSubjectId = subjectId
      return subjects.filter(subject => {
        return subject.subject_id === finalSubjectId
      })
    }
    const [subjectDetails] =
      electiveId === '' ? returnSubject(subjectId) : returnSubject(electiveId)
    return subjectDetails && subjectDetails.student_id !== undefined
      ? this.getStudentsOfSelectedSubject(
          groups[groupId].students,
          subjectDetails.student_id
        )
      : []
  }

  getStudentsOfSelectedSubject(groupStudents, subjectStudents) {
    return underscore.intersection(groupStudents, subjectStudents)
  }

  showElectives() {
    const { subjectId } = this.state
    if (subjectId === '') return false
    const { subjectsById } = this.props
    if (
      subjectsById[subjectId] === undefined ||
      subjectsById[subjectId].electives === undefined
    )
      return false
    if (subjectsById[subjectId].electives.length === 0) return false
    return true
  }

  // Onclick of GO, render marks entry with students of subject, test/subtest
  handleGo(e) {
    e.preventDefault()
    const { jwt, groups, tests, saveDataToStore, fetchStudents } = this.props
    const { umbrellaGroup, groupId, testId, subjectId, electiveId } = this.state

    const selectedSubjectId = electiveId !== '' ? electiveId : subjectId
    const subTests = this.filterSubtests(tests, testId, selectedSubjectId) // find subtests
    // find students of subject , if elective exists then find its
    const studentsOfSelectedTest = this.filterStudentsFromSubj(
      subjectId,
      groups,
      groupId,
      electiveId
    )

    const selectedTests = subTests.length === 0 ? [testId] : subTests
    // selectedTests.push(testId)
    fetchStudents({
      jwt,
      students: studentsOfSelectedTest
    })
    saveDataToStore({
      umbrellaGroup,
      groupId,
      testId,
      subjectId,
      electiveId
    })

    this.setState({
      clickedGo: true,
      isDirty: false,
      studentsOfTests: studentsOfSelectedTest,
      selectedTests,
      selectedSubj: electiveId !== '' ? [electiveId] : [subjectId],
      showMainTest: false,
      shouldFetchTestsAndSubjects: false,
      fetchSubtestsAndElectives: false
    })
  }

  isReady() {
    const {
      groupsLoading,
      subjectsLoading,
      testsLoading,
      studentsLoading
    } = this.props
    if (groupsLoading || subjectsLoading || testsLoading || studentsLoading)
      return false
    return true
  }

  getSubjectType() {
    const { subjectsById } = this.props
    const { electiveId, subjectId } = this.state
    const subject = electiveId ? electiveId : subjectId
    let finalSubjectId = subject
    if (subject === 'all') {
      finalSubjectId = subjectId
    }
    const { marksGrade } = Object.assign(
      { marksGrade: 'marks' },
      subjectsById[finalSubjectId]
    )
    return marksGrade
  }

  getSelectedTests() {
    const {
      testId,
      selectedTests,
      subjectId,
      electiveId,
      showMainTest
    } = this.state
    const { tests } = this.props
    const selectedSubjectId = electiveId ? electiveId : subjectId
    const subTests = this.filterSubtests(tests, testId, selectedSubjectId)
    if (subTests.length !== 0 && showMainTest) {
      return [].concat(testId, selectedTests)
    }
    if (subTests.length !== 0 && !showMainTest) {
      return subTests
    }
    return [testId]
  }

  getSelectedTestsGrade() {
    const { testId, subjectId, electiveId, showMainTest } = this.state
    const { tests } = this.props
    const selectedSubjectId = electiveId ? electiveId : subjectId
    const subTests = this.filterSubtests(tests, testId, selectedSubjectId)
    if (subTests.length !== 0 && showMainTest) {
      return [testId, ...subTests]
    }
    if (subTests.length !== 0 && !showMainTest) {
      return [...subTests, testId]
    }
    return [testId]
  }

  handleShowMainTestChange(e) {
    const { value } = e.target
    const { fetchMarks, groups, jwt, tests } = this.props
    const { testId, groupId, subjectId, electiveId } = this.state
    const newValue = value === 'yes' ? true : false
    if (newValue) {
      const subject = electiveId ? electiveId : subjectId
      const subtestIds = this.filterSubtests(tests, testId, subject)
      const selectedTestIds = [testId].concat(subtestIds)
      const { students } = Object.assign({ students: [] }, groups[groupId])
      fetchMarks({
        jwt,
        testId: selectedTestIds,
        subjectArr: [subject],
        studentArr: students
      })
    }
    this.setState({
      showMainTest: newValue
    })
  }

  render() {
    const { academicGroups, tests, subjects, roles } = this.props
    const {
      umbrellaGroup,
      groupId,
      testId,
      subjectId,
      electiveId,
      clickedGo,
      isDirty,
      selectedSubj,
      studentsOfTests,
      showMainTest
    } = this.state
    console.log(academicGroups, 'academicGroups')
    return roles.includes('restrictedMarks') ? (
      <div className="container">
        <div className="row">
          <div className="col-xs-3">
            <div className="tab">
              <p className="tab__text">Access Restricted.</p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="attd-container">
        <PageHelmet>Marks Entry</PageHelmet>
        <div className="container">
          <div className="row">
            <div className="col-xs-3">
              <div className="tab">
                <p className="tab__text">Marks Entry</p>
              </div>
            </div>
          </div>
          <div>
            <Snackbar
              text="Max marks has changed please revisit all fields"
              showSnackbar={this.state.showSnackbar}
              hideSnackbar={this.hideSnackbar}
            />
            <div className="row">
              <div className="col-xs-12">
                <GroupSelector // component that renders dropdown to select subjs
                  // data
                  parentNames={uniqueParentGroups(academicGroups)}
                  sections={filterSectionsByParent(
                    umbrellaGroup,
                    academicGroups
                  )}
                  tests={this.filterTestsByGroup()}
                  subjects={this.filterSubjectsForSelector()}
                  subjectId={subjectId}
                  testId={testId}
                  umbrellaGroup={umbrellaGroup}
                  groupId={groupId}
                  electiveId={electiveId}
                  showElectives={this.showElectives()}
                  // callbacks
                  handleUmbrellaChange={this.handleUmbrellaChange}
                  handleSectionChange={this.handleSectionChange}
                  handleTestChange={this.handleTestChange}
                  handleSubjectChange={this.handleSubjectChange}
                  handleElectiveChange={this.handleElectiveChange}
                  handleGo={this.handleGo}
                  setSection={this.setSection}
                  electives={this.filterElectivesForSelector()}
                  // utility functions
                  filterElectives={this.filterElectives}
                  showMaxMarksModalError={this.showMaxMarksModalError}
                  isDirty={isDirty}
                  /* data: classes sections tests, funcs: handleGo setSection */
                />
              </div>
            </div>
          </div>
        </div>

        {clickedGo && this.isReady() && (
          <MarksEntry
            showMainTest={showMainTest}
            handleShowMainTestChange={this.handleShowMainTestChange}
            selectedTests={this.getSelectedTests()}
            selectedTestsGrade={this.getSelectedTestsGrade()}
            selectedSubj={selectedSubj}
            mainSubjectId={subjectId}
            groupId={groupId}
            // contains subTest if any
            studentsOfTests={studentsOfTests}
            showMaxMarksModalError={this.showMaxMarksModalError}
            tests={tests}
            subjects={subjects}
            type={this.getSubjectType()}
            // name of group and MainTest
            groupSelected={groupId}
            mainTest={testId}
            resetMarks={this.resetMarks}
            clickedGo={clickedGo}
          />
        )}
        {!this.isReady() && <Spinner />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const getGroupsObj = () => {
    if (state.accounts.level === 'teacher') {
      return getTeacherGroupsById(state)
    }
    return state.groups.byId
  }
  const getGroupsArr = () => {
    if (state.accounts.level === 'teacher') {
      return getAcademicTeacherGroups(state)
    }
    return getAcademicGroups(state)
  }

  return {
    isLoading: state.groups.isLoading,
    groups: getGroupsObj(),
    level: getLevel(state),
    roles: getRoles(state),
    levelId: getLevelId(state),
    jwt: state.accounts.jwt,
    academicGroups: getGroupsArr(),
    tests: testsById(state),
    subjects: Subjects.subjectArray(state),
    subjectsById: Subjects.byId(state),
    electives: Subjects.electivesArray(state),
    allData: state.ui.marks,
    testsLoading: isTestsLoading(state),
    subjectsLoading: Subjects.getIsLoading(state),
    marksLoading: state.marks.isLoading,
    studentsLoading: studentsLoading(state),
    groupsLoading: groupsLoading(state),
    mainSubjectArr: Subjects.getMainSubjects(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups(data) {
    console.log('indisse')
    dispatch(startFetchGroups(data))
  },
  fetchTeacherGroups(data) {
    dispatch(startFetchGroupsForTeacher(data))
  },
  fetchTests(data) {
    dispatch(startFetchTests(data))
  },
  fetchTestsAndSubjects({ jwt, tests, subjects }) {
    dispatch(startFetchTests({ jwt, tests }))
    dispatch(startFetchSubjects({ jwt, subjects }))
  },
  fetchElectives({ jwt, electives }) {
    const subjects = electives
    dispatch(startFetchElectives({ jwt, subjects }))
  },
  fetchStudents(data) {
    dispatch(startFetchData(data))
  },
  resetMarksStatus(failure) {
    dispatch(emitResetMarksStatus(failure))
  },
  fetchTestsWithSelectedSubTest({ jwt, tests }) {
    dispatch(startFetchTests({ jwt, tests })) // fetch test for subtest if exist
  },
  dispatchSelectorData(data) {
    // data fetched from store for UI
    dispatch(saveGroupSelectorData(data))
  },
  saveDataToStore(data) {
    dispatch(saveDataForMarksEntry(data))
  },
  fetchMarks(data) {
    dispatch(startFetchMarksForTestSubj(data))
  }
})

export const Marks = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarksComponent)
