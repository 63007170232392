import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast
} from '../helpers/utils.js'
import { emitAuthFailed } from './login.js'
import querystring from 'query-string'
import {
  DEPARTMENTS_DATA,
  TEACHERS_DATA,
  TEACHERS_FETCH_START,
  DEPARTMENTS_FETCH_START,
  TEACHERS_FETCH_FAIL,
  UPDATE_TEACHER_STORE,
  FLATTENED_TEACHERS_DATA,
  FLATTENED_ARCHIVE_TEACHERS_GET_STARTED,
  FLATTENED_ARCHIVE_TEACHERS_DATA,
  FLATTENED_ARCHIVE_TEACHERS_GET_FAIL,
  FLATTENED_TEACHERS_GET_STARTED,
  FLATTENED_TEACHERS_FETCH_FAIL,
  SET_SEARCH_FILTER_FOR_TEACHER_LIST,
  ARCHIVE_TEACHER_START,
  ARCHIVE_TEACHER_SUCCESS,
  ARCHIVE_TEACHER_FAIL,
  UNARCHIVE_TEACHER_START,
  UNARCHIVE_TEACHER_SUCCESS,
  UNARCHIVE_TEACHER_FAIL,
  TEACHER_RESET_PASSWORD_START,
  TEACHER_RESET_PASSWORD_SUCCESS,
  TEACHER_RESET_PASSWORD_FAILURE,
  ADD_TEACHER_USERS_START,
  ADD_TEACHER_USERS,
  ADD_TEACHER_USERS_FAIL,
  ADD_NEW_TEACHER_START,
  ADD_NEW_TEACHER_FAIL,
  ADD_NEW_TEACHER_SUCCESS,
  EDIT_TEACHER_START,
  EDIT_TEACHER_SUCCESS,
  EDIT_TEACHER_FAIL,
  TEACHER_SEARCH_DATA,
  CLEAR_TEACHER_DATA
} from '../helpers/actions.js'
import { reset } from 'redux-form'
import { ADDTEACHER_FORM_NAME } from '../helpers/enums'
import { getById } from '../helpers/utils'

const fetchDepartmentData = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/departments'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchDepartmentData = data => {
  return function(dispatch) {
    dispatch(emistStartFetchDepartments())
    return fetchDepartmentData(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitDepartmentData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const emistStartFetchDepartments = () => ({
  type: DEPARTMENTS_FETCH_START
})

const emitDepartmentData = data => ({
  type: DEPARTMENTS_DATA,
  data
})

const fetchTeachersByDepartment = ({ jwt, departments }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/teacher/departments?' +
    querystring.stringify({ ids: departments })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchTeachersByDepartment = data => {
  return function(dispatch) {
    return fetchTeachersByDepartment(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitTeacherData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const emitTeacherData = data => {
  const entities = {}
  const result = data.map(teacherObj => {
    entities[teacherObj._id] = teacherObj
    return teacherObj._id
  })
  return {
    type: TEACHERS_DATA,
    entities,
    result
  }
}

// fetch all teachers
export const startFetchAllTeachers = jwt => {
  return function(dispatch) {
    dispatch(emitStartFetchTeacher())
    return fetchAllTeachers(jwt)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitTeacherData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const fetchAllTeachers = jwt => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/teacher/getAllTeachers'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

// fetch all teachers
export const startUploadCSV = body => {
  return function(dispatch) {
    return uploadCSV(body)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Teacher added successfully')
        dispatch(emitTeacherData(json.data))
      })
      .catch(err => {
        console.error(err)
        errorToast(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const uploadCSV = ({ jwt, path }) => {
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/teacher/csv`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      path
    })
  })
}

//bulk edit teachers
export const startEditTeacherCSV = body => {
  return function(dispatch) {
    return editTeacherCSV(body)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Teacher edited successfully')
        dispatch(emitTeacherData(json.data))
      })
      .catch(err => {
        console.error(err)
        errorToast(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const editTeacherCSV = ({ jwt, filepath }) => {
  const Authorization = composeAuth(jwt)
  const url = `${process.env.REACT_APP_SERVER_NEW}/api/teacher/bulk/edit`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      filepath
    })
  })
}

// fetch teachers by id
const emitStartFetchTeacher = () => ({
  type: TEACHERS_FETCH_START
})

const emitTeacherFail = errMsg => ({
  type: TEACHERS_FETCH_FAIL,
  errMsg
})

export const startFetchTeachersById = data => {
  return function(dispatch) {
    dispatch(emitStartFetchTeacher())
    return fetchTeachersById(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitTeacherData(json.data))
        dispatch(emitFlattenedTeachersData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitTeacherFail(err.message))
      })
  }
}

const fetchTeachersById = ({ jwt, teacherIds }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    '/api/teacher?' +
    querystring.stringify({ ids: teacherIds })
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

// post teacher
const addTeacherStart = () => ({
  type: ADD_NEW_TEACHER_START
})
const addTeacherSuccess = () => ({
  type: ADD_NEW_TEACHER_SUCCESS
})
const addTeacherFail = () => ({
  type: ADD_NEW_TEACHER_FAIL
})
const postNewTeacher = data => {
  const { jwt, payload } = data
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/teacher'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startPostTeacher = data => {
  return function(dispatch) {
    dispatch(addTeacherStart())
    return postNewTeacher(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        successToast('Teacher added successfully')
        dispatch(addTeacherSuccess())
        dispatch(reset(ADDTEACHER_FORM_NAME))
      })
      .catch(err => {
        console.error(err)
        errorToast('Teacher could not be created, Please try again.')
        dispatch(addTeacherFail())
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

// Edit Teacher -----

// post teacher
const editTeacher = data => {
  const { jwt, teacherId, payload } = data
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/teacher/${teacherId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startEditTeacher = data => {
  return function(dispatch) {
    return editTeacher(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(updateTeacherStore(json.data))
        successToast('Teacher Updated successfully')
        dispatch(
          startFetchTeachersById({
            jwt: data.jwt,
            teacherIds: [data.teacherId]
          })
        )
      })
      .catch(err => {
        console.error(err)
        errorToast('Teacher Update failed, Please try again.')
        if (err instanceof AuthError) dispatch(emitAuthFailed())
      })
  }
}

const updateTeacherStore = data => ({
  type: UPDATE_TEACHER_STORE,
  data
})

// fetch archived flattened teachers
export const startFetchArchiveTeachers = jwt => {
  return function(dispatch) {
    dispatch(emitFlattenedArchiveTeachersStart())
    return fetchArchiveTeachers(jwt)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitFlattenedArchiveTeachersData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError)
          dispatch(emitFlattenedArchiveTeachersFail())
      })
  }
}

const fetchArchiveTeachers = jwt => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/teacher/archived'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

// fetch flattened teachers
export const startFetchFlattenedTeachers = jwt => {
  return function(dispatch) {
    dispatch(emitStartFetchFlattenedTeachers())
    return fetchFlattenedTeachers(jwt)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitFlattenedTeachersData(json.data))
      })
      .catch(err => {
        console.error(err)
        if (err instanceof AuthError) dispatch(emitFlattenedTeacherFetchFail())
      })
  }
}

const fetchFlattenedTeachers = jwt => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/teacher/getAllTeachers'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

const emitStartFetchFlattenedTeachers = () => ({
  type: FLATTENED_TEACHERS_GET_STARTED
})

const emitFlattenedTeachersData = data => {
  const entities = {}
  const result = []
  data.forEach(teacherObj => {
    let teacherInfoFlattened = {}
    flattenTeacherData(teacherObj, teacherInfoFlattened)
    entities[teacherObj._id] = teacherInfoFlattened
    result.push(teacherObj._id)
  })
  return {
    type: FLATTENED_TEACHERS_DATA,
    entities,
    result
  }
}

const emitFlattenedArchiveTeachersStart = () => ({
  type: FLATTENED_ARCHIVE_TEACHERS_GET_STARTED
})

const emitFlattenedArchiveTeachersData = data => {
  const entities = {}
  const result = []
  data.forEach(teacherObj => {
    let teacherInfoFlattened = {}
    flattenTeacherData(teacherObj, teacherInfoFlattened)
    entities[teacherObj._id] = teacherInfoFlattened
    result.push(teacherObj._id)
  })
  return {
    type: FLATTENED_ARCHIVE_TEACHERS_DATA,
    entities,
    result
  }
}

const emitFlattenedArchiveTeachersFail = () => ({
  type: FLATTENED_ARCHIVE_TEACHERS_GET_FAIL
})

const flattenTeacherData = (teacherObj, teacherInfoFlattened) => {
  Object.keys(teacherObj).forEach(teacherKey => {
    flattenEachKey(teacherObj, teacherKey, teacherInfoFlattened)
  })
}

const flattenEachKey = (teacherObj, teacherKey, teacherInfoFlattened) => {
  switch (teacherKey) {
    case 'joining':
      handleObject(teacherObj, teacherKey, teacherInfoFlattened)
      break
    case 'addresses':
      handleAddress(teacherObj, teacherKey, teacherInfoFlattened)
      break
    case 'phone':
      handlePhone(teacherObj, teacherKey, teacherInfoFlattened)
      break
    default:
      handleOtherKeys(teacherObj, teacherKey, teacherInfoFlattened)
      break
  }
}

const handleObject = (teacherObj, teacherKey, teacherInfoFlattened) => {
  Object.keys(teacherObj[teacherKey]).forEach(item => {
    Object.assign(teacherInfoFlattened, {
      [`${teacherKey}_${item}`]: teacherObj[teacherKey]
        ? teacherObj[teacherKey][item]
          ? teacherObj[teacherKey][item]
          : ''
        : ''
    })
  })
}

const handleAddress = (teacherObj, teacherKey, teacherInfoFlattened) => {
  teacherObj[teacherKey].forEach(addressObj => {
    const { type, address } = addressObj
    Object.assign(teacherInfoFlattened, {
      [`${teacherKey}_${type}`]: address
    })
  })
}

const handlePhone = (teacherObj, teacherKey, teacherInfoFlattened) => {
  const primary = []
  const secondary = []
  teacherObj[teacherKey].forEach((phoneObj, index) => {
    if (index === 0) {
      primary.push(phoneObj)
    } else {
      secondary.push(phoneObj)
    }
  })
  Object.assign(teacherInfoFlattened, {
    [`${teacherKey}_primary`]: primary,
    [`${teacherKey}_secondary`]: secondary
  })
}

const handleOtherKeys = (teacherObj, teacherKey, teacherInfoFlattened) => {
  Object.assign(teacherInfoFlattened, {
    [teacherKey]: teacherObj[teacherKey]
  })
}

const emitFlattenedTeacherFetchFail = () => ({
  type: FLATTENED_TEACHERS_FETCH_FAIL
})

export const setSearchFilter = data => ({
  type: SET_SEARCH_FILTER_FOR_TEACHER_LIST,
  data
})

// archive teacher
const archiveTeacherStart = () => ({
  type: ARCHIVE_TEACHER_START
})

const archiveTeacherSuccess = teacherId => ({
  type: ARCHIVE_TEACHER_SUCCESS,
  teacherId
})

const archiveTeacherFail = errMsg => ({
  type: ARCHIVE_TEACHER_FAIL,
  errMsg
})

const archiveTeacher = ({ jwt, teacherId, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/teacher/archive/${teacherId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const emitArchiveTeacher = data => dispatch => {
  dispatch(archiveTeacherStart())
  archiveTeacher(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(archiveTeacherSuccess(data.teacherId))
      successToast('Teacher Archived successfully')
    })
    .catch(err => {
      console.error(err)
      errorToast('Teacher Archive failed')
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(archiveTeacherFail(err.message))
    })
}

// un-archive teacher
const unArchiveTeacherStart = () => ({
  type: UNARCHIVE_TEACHER_START
})

const unArchiveTeacherSuccess = teacherId => ({
  type: UNARCHIVE_TEACHER_SUCCESS,
  teacherId
})

const unArchiveTeacherFail = errMsg => ({
  type: UNARCHIVE_TEACHER_FAIL,
  errMsg
})

const unArchiveTeacher = ({ jwt, teacherId }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/teacher/unarchive/${teacherId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const emitUnArchiveTeacher = data => dispatch => {
  dispatch(unArchiveTeacherStart())
  unArchiveTeacher(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(unArchiveTeacherSuccess(data.teacherId))
      successToast('Teacher Unarchived successfully')
    })
    .catch(err => {
      console.error(err)
      errorToast('Teacher Unarchive failed')
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      dispatch(unArchiveTeacherFail(err.message))
    })
}

//reset Password
const emitTeacherResetPasswordStart = () => ({
  type: TEACHER_RESET_PASSWORD_START
})
const teacherresetPasswordSuccess = () => ({
  type: TEACHER_RESET_PASSWORD_SUCCESS
})

const teacherresetPasswordFailure = () => ({
  type: TEACHER_RESET_PASSWORD_FAILURE
})

const teacherResetPassword = ({ jwt, ids }) => {
  let Authorization = composeAuth(jwt)
  let url = `${
    process.env.REACT_APP_SERVER_NEW
  }/api/teacher/resetpassword?${querystring.stringify({ ids: ids })}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startTeacherPasswordReset = data => dispatch => {
  dispatch(emitTeacherResetPasswordStart())
  return teacherResetPassword(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('Password reset successfully')
      dispatch(teacherresetPasswordSuccess())
    })
    .catch(err => {
      console.error(err)
      errorToast('Password reset failed')
      dispatch(teacherresetPasswordFailure())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

//add teacher users bulk
const emitAddTeacherUsersBulkStart = () => ({
  type: ADD_TEACHER_USERS_START
})
const emitAddTeacherUsersBulkSuccess = () => ({
  type: ADD_TEACHER_USERS
})

const emitAddTeacherUsersBulkFailure = () => ({
  type: ADD_TEACHER_USERS_FAIL
})

const addTeacherUsersBulk = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = `${process.env.REACT_APP_SERVER_NEW}/api/teacher/create/ids/bulk`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startAddTeacherUsersBulk = data => dispatch => {
  dispatch(emitAddTeacherUsersBulkStart())
  return addTeacherUsersBulk(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      successToast('Teacher users Created')
      dispatch(emitAddTeacherUsersBulkSuccess())
    })
    .catch(err => {
      errorToast('Teacher users creation failed')
      dispatch(emitAddTeacherUsersBulkFailure())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

//search teacher
const emitTeacherSearchData = entities => ({
  type: TEACHER_SEARCH_DATA,
  entities,
  result: getById(entities, '_id')
})

const fetchTeacherSearch = ({ jwt, filter, search }) => {
  const Authorization = composeAuth(jwt)
  const url = `${
    process.env.REACT_APP_SERVER_NEW
  }/api/teacher/filter/${filter}/search/${encodeURIComponent(search)}`

  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchTeacherSearch = data => dispatch => {
  const { search } = data
  if (search === '') return null

  /*
  TODO: Right now there's multiple status flags for each action
  we can repurpose the addStudentstart action and everything
  can use that.
  */
  dispatch(addTeacherStart())
  return fetchTeacherSearch(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside fetchUniversalSearch', json.data)
      dispatch(emitTeacherSearchData(json.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(addTeacherFail())
      if (err instanceof AuthError) dispatch(emitAuthFailed())
    })
}

export const clearTeacherData = () => ({
  type: CLEAR_TEACHER_DATA
})
