// import packages
import React, { Component } from 'react'

class TableHeader extends Component {
  render() {
    const { handleSelectAllChange, selectAll } = this.props

    return (
      <thead className="st-table__head">
        <tr className="st-table__head--lightblue">
          <th className="st-table__row--headcell st-table__text--head">
            Roll No.
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            Admission No.
          </th>
          <th className="st-table__row--headcell st-table__text--head">Name</th>
          <th className="st-table__row--headcell st-table__text--head">
            Status
          </th>
          <th className="st-table__row--headcell st-table__text--head">
            <label className="group-select group-select--all">
              <input
                type="checkbox"
                checked={selectAll}
                value={selectAll}
                onChange={handleSelectAllChange}
              />{' '}
              select All
              <span className="square">
                <span className="tick" />
              </span>
            </label>
          </th>
        </tr>
      </thead>
    )
  }
}

export default TableHeader
