import {
  START_POST_PROPOSAL,
  PROPOSAL_POST_FAILURE,
  PROPOSAL_POST_SUCCESS,
  GET_PROPOSAL,
  SEARCH_ITEAMS_DATA,
  START_SEARCH_ITEAMS_DATA,
  POST_REQUISITIONS_DATA,
  START_POST_REQUISITIONS_DATA,
  POST_REQUISITIONS_DATA_FAIL,
  APP_LOGOUT
} from '../helpers/actions'

import { getById } from '../helpers/utils'

const initialState = {
  error: false,
  isLoading: false,
  proposal: [],
  items: [],
  isLoadingIteams: false,
  isLoadingRequisitions: false,

  requisitions: []
}

export const inventory = (state = initialState, action) => {
  switch (action.type) {
    case START_POST_PROPOSAL:
      return Object.assign({}, state, {
        isLoading: true
      })

    case PROPOSAL_POST_SUCCESS:
      return Object.assign({}, state, {
        proposal: action.data,
        isLoading: false
      })
    case GET_PROPOSAL:
      return Object.assign({}, state, {
        proposal: action.data,
        isLoading: false
      })
    case PROPOSAL_POST_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoading: false
      })
    case SEARCH_ITEAMS_DATA:
      return Object.assign({}, state, {
        items: action.data,
        isLoadingItems: false
      })
    case START_SEARCH_ITEAMS_DATA:
      return Object.assign({}, state, {
        isLoadingItems: false
      })
    case POST_REQUISITIONS_DATA:
      return Object.assign({}, state, {
        requisitions: action.data,
        isLoadingRequisitions: false,
        error: false
      })
    case START_POST_REQUISITIONS_DATA:
      return Object.assign({}, state, {
        isLoadingRequisitions: true,
        error: false
      })
    case POST_REQUISITIONS_DATA_FAIL:
      return Object.assign({}, state, {
        isLoadingRequisitions: false,
        error: true
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors

export const getProposal = state => state.inventory.proposal
export const getItems = state => state.inventory.items
export const isLoadingItems = state => state.inventory.isLoadingItems
export const isLoadingRequisitions = state =>
  state.inventory.isLoadingRequisitions
export const getError = state => state.inventory.error
export const getProposalById = state => {
  return getById(state.inventory.proposal)
}
export const isLoading = state => state.inventory.isLoading
