import { merge, union } from 'lodash'
import {
  APP_LOGOUT,
  START_POST_STUDENT_REMARKS,
  POST_STUDENT_REMARKS_SUCCESS,
  POST_STUDENT_REMARKS_FAILURE,
  START_FETCH_STUDENT_REMARKS,
  START_FETCH_STUDENT_REMARKS_SUCCESS,
  START_FETCH_STUDENT_REMARKS_FAILURE,
  POST_REMARKS_START,
  POST_REMARKS_SUCCESS,
  POST_REMARKS_FAIL,
  FETCH_REMARKS_START,
  FETCH_REMARKS_SUCCESS,
  FETCH_REMARKS_FAIL,
  PATCH_TEST_REMARKS_START,
  PATCH_TEST_REMARKS_SUCCESS,
  PATCH_TEST_REMARKS_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  message: '',
  isPostRemarkLoading: false,
  remarkError: false,
  isFetchRemarkLoading: false,
  remarksList: [],
  patchLoading: false
}

export const studentremarks = (state = initialState, action) => {
  let obj = {}
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_POST_STUDENT_REMARKS:
      obj = Object.assign(
        {},
        {
          message: 'in_progress'
        },
        action.data
      )
      return Object.assign({}, state, {
        isLoading: true,
        byId: merge({}, state.byId, { [obj.studentId]: obj }),
        allIds: state.allIds
      })
    case POST_STUDENT_REMARKS_SUCCESS:
      obj = Object.assign(
        {},
        {
          message: 'success'
        },
        action.data
      )
      return Object.assign({}, state, {
        byId: merge({}, state.byId, {
          [`${obj.studentId}-${obj.subject}-${obj.testId}`]: obj
        }),
        allIds: union([], state.allIds, action.allIds),
        isLoading: false
      })
    case POST_STUDENT_REMARKS_FAILURE:
      obj = Object.assign(
        {},
        {
          message: 'failure'
        },
        action.data
      )
      return Object.assign({}, state, {
        isLoading: false,
        byId: merge({}, state.byId, {
          [`${obj.studentId}-${obj.subject}-${obj.testId}`]: obj
        }),
        allIds: state.allIds
      })
    case START_FETCH_STUDENT_REMARKS:
      return Object.assign({}, state, {
        isLoading: true
      })
    case START_FETCH_STUDENT_REMARKS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.byId,
        allIds: action.allIds
      })
    case START_FETCH_STUDENT_REMARKS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.byId,
        allIds: action.allIds
      })
    case POST_REMARKS_START:
      return Object.assign({}, state, {
        isPostRemarkLoading: true,
        remarkError: false
      })
    case POST_REMARKS_SUCCESS:
      return Object.assign({}, state, {
        isPostRemarkLoading: false,
        remarkError: false
      })
    case POST_REMARKS_FAIL:
      return Object.assign({}, state, {
        isPostRemarkLoading: false,
        remarkError: true
      })
    case FETCH_REMARKS_START:
      return Object.assign({}, state, {
        isFetchRemarkLoading: true,
        remarksList: []
      })
    case FETCH_REMARKS_SUCCESS:
      return Object.assign({}, state, {
        isFetchRemarkLoading: false,
        remarksList: action.data
      })
    case FETCH_REMARKS_FAIL:
      return Object.assign({}, state, {
        isFetchRemarkLoading: false,
        remarksList: []
      })
    case PATCH_TEST_REMARKS_START:
      return Object.assign({}, state, {
        patchLoading: true
      })
    case PATCH_TEST_REMARKS_SUCCESS:
      return Object.assign({}, state, {
        patchLoading: false
      })
    case PATCH_TEST_REMARKS_FAIL:
      return Object.assign({}, state, {
        patchLoading: false
      })
    default:
      return state
  }
}

export const byId = state => state.studentremarks.byId
export const isLoading = state => state.studentremarks.isLoading
export const successMsg = state => state.studentremarks.message
export const getPostRemarkLoading = state =>
  state.studentremarks.isPostRemarkLoading
export const getRemarkError = state => state.studentremarks.remarkError
export const getFetchRemarkLoading = state =>
  state.studentremarks.isFetchRemarkLoading
export const getRemarksList = state => state.studentremarks.remarksList
export const getRemarkPatchLoading = state => state.studentremarks.patchLoading
