import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
import { PrivateLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

const CashierRoute = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Frontend v3 routes */}

            {/* Frontend v4 routes */}

            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default CashierRoute
