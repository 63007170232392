import {
  APP_LOGOUT,
  LIBRARY_USERS_SEARCH_START,
  LIBRARY_USERS_SEARCH,
  LIBRARY_USERS_SEARCH_FAIL,
  GET_LIBRARY_USER_POLICY_ID_START,
  GET_LIBRARY_USER_POLICY_ID,
  GET_LIBRARY_USER_POLICY_ID_FAIL,
  POST_LIBRARY_USER_POLICY_START,
  POST_LIBRARY_USER_POLICY,
  POST_LIBRARY_USER_POLICY_FAIL,
  SAVE_SELECTED_LIBRARY_USER,
  GET_LIBRARY_USER_POLICY_START,
  GET_LIBRARY_USER_POLICY,
  GET_LIBRARY_USER_POLICY_FAIL,
  PATCH_LIBRARY_USER_POLICY_START,
  PATCH_LIBRARY_USER_POLICY_SUCCESS,
  PATCH_LIBRARY_USER_POLICY_FAIL,
  POST_ATTACH_POLICY_TO_USER_START,
  POST_ATTACH_POLICY_TO_USER_SUCCESS,
  POST_ATTACH_POLICY_TO_USER_FAIL,
  GET_LIBRARAY_USER_BY_ID_START,
  GET_LIBRARAY_USER_BY_ID_SUCCESS,
  GET_LIBRARAY_USER_BY_ID_FAIL
} from '../../helpers/actions'

const initialState = {
  users: [],
  isLoadingLibraryUsers: false,
  usersById: {},
  selectedLibraryUser: {},
  userPolicies: {},
  userPoliciesById: [],
  isLoadingLibraryUsersPoliciesById: false,
  isLoadingPostLibraryUsersPolicies: false,
  isLoadingGetUserPolicies: false,
  isLoadingPatchUserPolicies: false,
  isLoadingAttachPolicy: false,
  allUserPolicies: {},
  error: false,
  usersByEntityId: {}
}

export const libraryUsers = (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_USERS_SEARCH_START:
      return Object.assign({}, state, {
        isLoadingLibraryUsers: true,
        error: false
      })
    case LIBRARY_USERS_SEARCH:
      return Object.assign({}, state, {
        isLoadingLibraryUsers: false,
        users: action.data,
        usersById: action.byId,
        error: false
      })
    case LIBRARY_USERS_SEARCH_FAIL:
      return Object.assign({}, state, {
        isLoadingLibraryUsers: false,
        error: true
      })
    case GET_LIBRARY_USER_POLICY_ID_START:
      return Object.assign({}, state, {
        isLoadingLibraryUsersPoliciesById: true
      })
    case GET_LIBRARY_USER_POLICY_ID:
      return Object.assign({}, state, {
        isLoadingLibraryUsersPoliciesById: false,
        userPolicies: action.data,
        userPoliciesById: action.byId
      })
    case GET_LIBRARY_USER_POLICY_ID_FAIL:
      return Object.assign({}, state, {
        isLoadingLibraryUsersPoliciesById: false
      })
    case POST_LIBRARY_USER_POLICY_START:
      return Object.assign({}, state, {
        isLoadingPostLibraryUsersPolicies: true,
        error: false
      })
    case POST_LIBRARY_USER_POLICY:
      return Object.assign({}, state, {
        isLoadingPostLibraryUsersPolicies: false,
        error: false
      })
    case POST_LIBRARY_USER_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoadingPostLibraryUsersPolicies: false,
        error: true
      })
    case SAVE_SELECTED_LIBRARY_USER:
      return Object.assign({}, state, {
        selectedLibraryUser: action.data
      })
    case GET_LIBRARY_USER_POLICY_START:
      return Object.assign({}, state, {
        isLoadingGetUserPolicies: true,
        error: false
      })
    case GET_LIBRARY_USER_POLICY:
      return Object.assign({}, state, {
        isLoadingGetUserPolicies: false,
        allUserPolicies: action.data,
        error: false
      })
    case GET_LIBRARY_USER_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoadingGetUserPolicies: false,
        error: true
      })
    case PATCH_LIBRARY_USER_POLICY_START:
      return Object.assign({}, state, {
        isLoadingPatchUserPolicies: true,
        error: false
      })
    case PATCH_LIBRARY_USER_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPatchUserPolicies: false,
        error: false
      })
    case PATCH_LIBRARY_USER_POLICY_FAIL:
      return Object.assign({}, state, {
        isLoadingPatchUserPolicies: false,
        error: true
      })
    case POST_ATTACH_POLICY_TO_USER_START:
      return Object.assign({}, state, {
        isLoadingAttachPolicy: true,
        error: false
      })
    case POST_ATTACH_POLICY_TO_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingAttachPolicy: false,
        error: false
      })
    case POST_ATTACH_POLICY_TO_USER_FAIL:
      return Object.assign({}, state, {
        isLoadingAttachPolicy: false,
        error: true
      })
    case GET_LIBRARAY_USER_BY_ID_START:
      return Object.assign({}, state, {
        isLoadingLibraryUsers: true
      })
    case GET_LIBRARAY_USER_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoadingLibraryUsers: false,
        users: action.data,
        usersByEntityId: action.byId
      })
    case GET_LIBRARAY_USER_BY_ID_FAIL:
      return Object.assign({}, state, {
        isLoadingLibraryUsers: false
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getLibraryUsers = state =>
  state.libraryUsers.users ? state.libraryUsers.users : {}
export const getisLoadingLibraryUsers = state =>
  state.libraryUsers.isLoadingLibraryUsers
    ? state.libraryUsers.isLoadingLibraryUsers
    : false
export const getLibraryUsersById = state =>
  state.libraryUsers.usersById ? state.libraryUsers.usersById : {}

export const getLibraryUserPolicies = state =>
  state.libraryUsers.userPolicies ? state.libraryUsers.userPolicies : {}
export const getisLoadingLibraryUsersPolicies = state =>
  state.libraryUsers.isLoadingLibraryUsersPoliciesById
    ? state.libraryUsers.isLoadingLibraryUsersPoliciesById
    : false
export const getLibraryUserPoliciesById = state =>
  state.libraryUsers.userPoliciesById ? state.libraryUsers.userPoliciesById : {}

export const getError = state =>
  state.libraryUsers.error ? state.libraryUsers.error : false
export const getisLoadingPostLibraryUsersPolicies = state =>
  state.libraryUsers.isLoadingPostLibraryUsersPolicies
    ? state.libraryUsers.isLoadingPostLibraryUsersPolicies
    : false

export const getSelectedLibraryUser = state =>
  state.libraryUsers.selectedLibraryUser
    ? state.libraryUsers.selectedLibraryUser
    : {}
export const getisLoadingGetUserPolicies = state =>
  state.libraryUsers.isLoadingGetUserPolicies
    ? state.libraryUsers.isLoadingGetUserPolicies
    : false
export const getisLoadingPatchUserPolicies = state =>
  state.libraryUsers.isLoadingPatchUserPolicies
    ? state.libraryUsers.isLoadingPatchUserPolicies
    : false
export const getisLoadingAttachPolicy = state =>
  state.libraryUsers.isLoadingAttachPolicy
    ? state.libraryUsers.isLoadingAttachPolicy
    : false
export const getAllUserPolicies = state => state.libraryUsers.allUserPolicies
export const getUsersByEntityId = state => state.libraryUsers.usersByEntityId
