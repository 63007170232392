// packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { PageHelmet } from '../common'
import FontAwesome from 'react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
// import actions
import {
  startFetchGroupsPerYear,
  startFetchAcademicYearPromotionalGroups
} from '../../actions/groups'
import * as Students from '../../actions/students'
import { saveData } from '../../actions/ui/studentProfile'
import { startFetchSubjects } from '../../actions/subjects'
import { startFetchAllTeachers } from '../../actions/teacher'
// selectors
import StudentStore from '../../reducers/flattenedStudents'
import { getInstitutionData, getAcademicYear } from '../../reducers/institution'
import {
  getAcademicGroups,
  isLoading as groupsIsloading
} from '../../reducers/groups'
import { getUmbrellaGroup, getGroupId } from '../../reducers/ui/studentProfile'
import {
  getIsLoading as subjectsLoading,
  byId as subjectsById
} from '../../reducers/subjects'
import { teachersById } from '../../reducers/teacher'
import { getPromotionalAcademicYear } from '../../reducers/promotionGroups'

// components
import StudentTable from './studentTable'
import GroupSelector from './groupselector'
import SearchBar from '../common/search'
import Spinner from '../spinner'
import SideButtonsWithCSV from './side-buttons-withcsv'

// import enums
import { STUDENT_DETAILS, STUDENT_DETAIL_HEADERS } from '../../helpers/enums'
import { downloadReport, convertJSONToCSV } from '../../helpers/utils'

const mapStateToProps = state => {
  return {
    jwt: state.accounts.jwt,
    ready: !StudentStore.isLoading(state) && !subjectsLoading(state),
    groupsIsloading: groupsIsloading(state),
    groups: state.groups.byId,
    students: StudentStore.getStudentsSortedByName(state),
    searchFilter: StudentStore.getSearchFilter(state),
    searchFilterSubjects: StudentStore.getSearchFilterSubjects(state),
    academicGroups: getAcademicGroups(state),
    uiUmbrellaGroup: getUmbrellaGroup(state),
    uiGroupId: getGroupId(state),
    subjectsById: subjectsById(state),
    teachersById: teachersById(state),
    institutionData: getInstitutionData(state),
    academicYear: getAcademicYear(state),
    archiveFilterType: StudentStore.getArchiveStudents(state),
    academicyears: getPromotionalAcademicYear(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchStudents: data => dispatch(Students.startFetchFlattenedData(data)),
  fetchAllTeachers: data => dispatch(startFetchAllTeachers(data)),
  saveData: data => dispatch(saveData(data)),
  setSearchFilter: data => dispatch(Students.setSearchFilter(data)),
  setArchiveTypeFilter: data => dispatch(Students.setArchiveTypeFilter(data)),
  setSearchFilterSubjects: data =>
    dispatch(Students.setSearchFilterSubjects(data)),
  fetchSubjects: data => dispatch(startFetchSubjects(data)),
  fetchGroupsByYearGroups: data => dispatch(startFetchGroupsPerYear(data)),
  fetchAllAcademicyears: data =>
    dispatch(startFetchAcademicYearPromotionalGroups(data))
})

class StudentListComponent extends Component {
  static propTypes = {
    //mapStateToProps
    jwt: PropTypes.string.isRequired,
    ready: PropTypes.bool.isRequired,
    groups: PropTypes.object.isRequired, //TODO: phase out
    subjectsById: PropTypes.object.isRequired, // TODO: phase out
    searchFilter: PropTypes.object.isRequired,
    students: PropTypes.array.isRequired,
    academicGroups: PropTypes.array.isRequired,
    uiUmbrellaGroup: PropTypes.string.isRequired,
    uiGroupId: PropTypes.string.isRequired,
    //mapDispatchToProps
    fetchStudents: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    fetchSubjects: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      search: '',
      umbrella_group: '',
      groupId: '',
      academic_year: '',
      goClicked: false,
      isDirty: false,
      showSearchFilter: false,
      studentDetails: STUDENT_DETAILS,
      fetchData: false,
      options: [
        { id: 'archived', name: 'archived' },
        { id: 'unarchived', name: 'unarchived' }
      ]
    }
    this.setGroup = this.setGroup.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.openSearchFilter = this.openSearchFilter.bind(this)
    this.hideSearchFilter = this.hideSearchFilter.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.handleSearchFieldClick = this.handleSearchFieldClick.bind(this)
    this.downloadReport = this.downloadReport.bind(this)
    this.getTextForBreadCrumb = this.getTextForBreadCrumb.bind(this)
  }

  componentDidMount() {
    const {
      uiUmbrellaGroup,
      uiGroupId,
      fetchAllTeachers,
      jwt,
      fetchAllAcademicyears,
      fetchGroupsByYearGroups,
      academicYear
    } = this.props
    const { groupId } = this.state
    fetchAllAcademicyears({ jwt, type: 'academics' })
    fetchGroupsByYearGroups({
      jwt,
      academic_year: academicYear,
      type: 'academics'
    })
    this.setState({
      academic_year: academicYear
    })
    fetchAllTeachers(jwt)
    if (groupId !== '') {
      this.fetchData(uiGroupId)
      this.setState({
        umbrella_group: uiUmbrellaGroup,
        groupId: uiGroupId,
        goClicked: true,
        fetchData: true
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { ready, subjectsById } = nextProps
    const { goClicked, groupId, studentDetails, fetchData } = this.state

    if (ready && goClicked && fetchData) {
      const subjectIds = this.getGroupSubjectIds(groupId).filter(subjectId => {
        return subjectsById[subjectId].is_main === true
      })
      const newStudentDetails = this.getNewStudentDetails(
        studentDetails,
        subjectIds,
        subjectsById
      )
      this.fetchData(groupId)
      this.setState({
        studentDetails: newStudentDetails,
        fetchData: false
      })
    }
  }

  getNewStudentDetails(studentDetails, subjectIds, subjectsById) {
    const newStudentDetails = Object.assign({}, studentDetails)
    newStudentDetails.subjects = {}
    subjectIds.forEach(subjectId => {
      newStudentDetails.subjects[subjectId] = subjectsById[subjectId].name
    })
    return newStudentDetails
  }

  setGroup(e) {
    const { name, value } = e.target
    if (name === 'parent_group') {
      this.setState({
        umbrella_group: value,
        groupId: '',
        isDirty: false,
        goClicked: false
      })
    } else {
      this.setState({
        groupId: value,
        isDirty: true,
        goClicked: false
      })
    }
  }

  handleSubmit(e) {
    const {
      saveData,
      setSearchFilterSubjects,
      setArchiveTypeFilter,
      archiveFilterType
    } = this.props
    const { umbrella_group, groupId } = this.state
    e.preventDefault()
    //TODO: What is even the contor flow here??
    saveData({
      umbrellaGroup: umbrella_group,
      groupId
    })
    setArchiveTypeFilter(archiveFilterType)
    const obj = {}
    const subjectIds = this.getGroupSubjectIds(groupId)
    subjectIds.forEach(subjectId => {
      obj[subjectId] = false
    })
    setSearchFilterSubjects(obj)
    this.fetchData(groupId)
    this.setState({
      search: '',
      goClicked: true,
      isDirty: false,
      fetchData: true
    })
  }

  fetchData = groupId => {
    const {
      groups,
      fetchStudents,
      fetchSubjects,
      jwt,
      archiveFilterType
    } = this.props
    const students =
      archiveFilterType === 'archived'
        ? groups[groupId].archiveStudents
        : groups[groupId].students
    const data = {
      jwt,
      students
    }
    fetchStudents(data)
    fetchSubjects({
      jwt,
      subjects: this.getGroupSubjectIds(groupId)
    })
  }

  active = () => {
    const { ready, students } = this.props
    return ready && 0 !== students.length ? true : false
  }

  setSearch(e) {
    e.preventDefault()
    const obj = {}
    obj[e.target.name] = e.target.value.toLowerCase()
    this.setState(obj)
  }

  filter = () => {
    const { groupId, search } = this.state
    const { groups, students, archiveFilterType } = this.props

    const studentIds =
      archiveFilterType === 'archived'
        ? groups[groupId].archiveStudents
        : groups[groupId].students
    let studentsArr = students.filter(
      studentObj => studentIds.indexOf(studentObj._id) !== -1
    )
    if (0 === search.length) return studentsArr

    const filterAcross = (fields = []) => {
      return student => {
        return fields.some(field => {
          return (
            student[field] !== undefined &&
            student[field]
              .toString()
              .toLowerCase()
              .indexOf(search) !== -1
          )
        })
      }
    }

    return studentsArr.filter(
      filterAcross([
        'name',
        'roll_number',
        'admission_number',
        'gender',
        'account_number',
        'reservation'
      ])
    )
  }

  getTableHeaders() {
    const { searchFilter, searchFilterSubjects } = this.props
    if (Object.keys(searchFilter).length === 0) return []
    // get all table headers including subjects
    let tableHeaders = []
    tableHeaders = tableHeaders.concat(
      Object.keys(searchFilter).filter(
        studentDetail => searchFilter[studentDetail] === true
      ),
      Object.keys(searchFilterSubjects).filter(
        subjectId => searchFilterSubjects[subjectId] === true
      )
    )
    return tableHeaders
  }

  getGroupSubjectIds(groupId) {
    const { groups } = this.props
    return groups[groupId].subjects.map(subjectObj => subjectObj.subject_id)
  }

  openSearchFilter() {
    this.setState({
      showSearchFilter: true
    })
  }

  hideSearchFilter() {
    this.setState({
      showSearchFilter: false
    })
  }

  handleSearchFieldClick(e) {
    const {
      searchFilter,
      searchFilterSubjects,
      setSearchFilter,
      setSearchFilterSubjects
    } = this.props
    const { groupId } = this.state
    const { value } = e.target
    const subjectIds = this.getGroupSubjectIds(groupId)
    if (subjectIds.includes(value)) {
      const newSearchFilterSubjects = Object.assign({}, searchFilterSubjects, {
        [value]: !searchFilterSubjects[value]
      })
      setSearchFilterSubjects(newSearchFilterSubjects)
    } else {
      const newSearchFilter = Object.assign({}, searchFilter, {
        [value]: !searchFilter[value]
      })
      setSearchFilter(newSearchFilter)
    }
  }

  renderCheckboxData(header, studentKey) {
    const { studentDetails } = this.state
    return (
      <label>
        <input
          type="checkbox"
          checked={this.isFilterChecked(studentKey)}
          onClick={this.handleSearchFieldClick}
          value={studentKey}
        />
        {studentDetails[header][studentKey]}
      </label>
    )
  }

  isFilterChecked(studentKey) {
    const { searchFilter, searchFilterSubjects } = this.props
    if (Object.keys(searchFilter).includes(studentKey))
      return searchFilter[studentKey]
    return searchFilterSubjects[studentKey]
  }

  downloadReport() {
    const { groups, institutionData, subjectsById, teachersById } = this.props
    const { groupId } = this.state
    const { academic_year, class_teacher, group_name, umbrella_group } = groups[
      groupId
    ]
    const { name: institutionName } = institutionData
    const { name: classTeacherName } = Object.assign(
      { name: '' },
      teachersById[class_teacher]
    )
    const headers = this.getTableHeaders()
    const tableBody = this.getStudentData()
    const subjectIds = Object.keys(subjectsById)
    const tableHeader = headers.map(item => {
      if (subjectIds.includes(item)) {
        return {
          key: item,
          name: subjectsById[item].name
        }
      }
      const obj = Object.values(STUDENT_DETAILS).find(obj => {
        return Object.keys(obj).includes(item)
      })
      if (obj) {
        return {
          key: item,
          name: obj[item]
        }
      }
      return { key: '', name: '' }
    })
    const data = {
      institutionName,
      class: umbrella_group,
      year: academic_year,
      title: 'Student List',
      section: group_name,
      classTeacher: classTeacherName,
      tableHeader,
      tableBody
    }
    // console.log('jsreportData', JSON.stringify(data, 2, null))
    console.log('table header............', tableHeader)
    downloadReport({
      nameOfTemplate: 'student-list',
      dataForTemplate: data,
      downloadedFileName: `student-list-${umbrella_group}-${group_name}`
    })
  }

  getTextForBreadCrumb() {
    const { groups } = this.props
    const { umbrella_group, groupId } = this.state

    const date = moment().format('DD MMM, YYYY')
    const group_name = groups[groupId].group_name
    if (date && group_name && umbrella_group)
      return `${date} / ${umbrella_group} - ${group_name}`
    return ''
  }

  getStudentData() {
    const students = this.filter()
    const tableHeaders = this.getTableHeaders()
    const studentsData = []
    students.forEach((studentObj, index) => {
      const student = {}
      student._id = studentObj._id
      tableHeaders.forEach(header => {
        student[header] = this.renderCell(header, student._id, studentObj)
      })
      studentsData.push(student)
    })
    return studentsData
  }

  renderCell(header, studentId, studentObj) {
    const { subjectsById } = this.props
    const { groupId } = this.state
    const groupSubjectIds = this.getGroupSubjectIds(groupId)
    /* if header is a main subjectId, check to which elective subject the
    student belongs
    */
    if (groupSubjectIds.indexOf(header) !== -1) {
      if (subjectsById[header].electives.length === 0) {
        if (this.checkForMainSubject(header, studentId) === true) return 'Yes'
        return 'No'
      }
      return this.checkForElectiveSubject(header, studentId)
    }
    const value = studentObj[header]

    // `!value` makes boolean `false` to `true` hence if ANY field's value is `false` we always get empty string
    if (typeof value === 'undefined' || value === null || value === '')
      return ''

    if (typeof value === 'boolean') {
      if (value) return 'Yes'
      return 'No'
    }

    if (header === 'roll_number') return Number(studentObj[header])

    return value
  }

  // check if student is part of main subject
  checkForMainSubject(header, studentId) {
    const groupSubjectArr = this.getGroupSubjectArr()
    let studentExists = false
    groupSubjectArr.some(subObj => {
      if (
        subObj.subject_id === header &&
        subObj.student_id.includes(studentId)
      ) {
        studentExists = true
        return true
      }
      return false
    })
    return studentExists
  }

  // check if student is part of an elective subject
  checkForElectiveSubject(header, studentId) {
    const { subjectsById } = this.props
    const electives = subjectsById[header].electives
    const studentElectiveId = this.getStudentElective(electives, studentId)
    if (!studentElectiveId) return ''
    return subjectsById[studentElectiveId].name
  }

  // check if student is assigned to any elective
  getStudentElective(electives, studentId) {
    const groupSubjectArr = this.getGroupSubjectArr()
    let studentElectiveId = false
    electives.some(electiveId => {
      return groupSubjectArr.some(subjectObj => {
        if (
          subjectObj.subject_id === electiveId &&
          subjectObj.student_id.includes(studentId)
        ) {
          studentElectiveId = electiveId
          return true
        }
        return false
      })
    })
    return studentElectiveId
  }

  getGroupSubjectArr() {
    const { groupId } = this.state
    const { groups } = this.props
    if (groupId === '' || Object.keys(groups).length === 0) return []
    const { subjects } = Object.assign({ subjects: [] }, groups[groupId])
    return subjects
  }
  handleArchiveToggle = e => {
    const { setSearchFilterSubjects, setArchiveTypeFilter } = this.props
    const { groupId } = this.state
    e.preventDefault()
    setArchiveTypeFilter(e.target.value)
    const obj = {}
    const subjectIds = this.getGroupSubjectIds(groupId)
    subjectIds.forEach(subjectId => {
      obj[subjectId] = false
    })
    setSearchFilterSubjects(obj)
    this.fetchData(groupId)
    this.setState({
      search: '',
      goClicked: true,
      isDirty: false,
      fetchData: true
    })
  }
  getCSV = () => {
    const { subjectsById } = this.props
    const { groupId } = this.state
    let tableHeader = this.getTableHeaders() || []
    let tableBody = this.getStudentData()
    const arr = []
    const groupSubIds = this.getGroupSubjectIds(groupId)
    tableBody.forEach(stu => {
      const obj = { ...stu }
      Object.keys(stu).forEach(sub => {
        if (groupSubIds.indexOf(sub) !== -1 && Boolean(subjectsById[sub])) {
          return (obj[subjectsById[sub].name] = stu[sub])
        }
      })
      obj['date_of_birth'] = moment(stu['date_of_birth']).format('DD-MM-YYYY')
      obj['joining_date'] = moment(stu['joining_date']).format('DD-MM-YYYY')
      arr.push(obj)
    })

    const headersWithSubject = tableHeader.map(header => {
      if (groupSubIds.indexOf(header) !== -1) {
        return subjectsById[header].name
      }
      return header
    })
    return convertJSONToCSV(arr, headersWithSubject)
  }
  downloadFile(e, fileName, urlData) {
    let link = document.createElement('a')
    var csvData = new Blob([urlData], { type: 'text/csv' })
    link.setAttribute('href', URL.createObjectURL(csvData))
    link.setAttribute('download', fileName)
    link.click()
  }
  handleAcademicYearChange = e => {
    const { fetchGroupsByYearGroups, jwt } = this.props
    e.preventDefault()
    this.setState({
      academic_year: e.target.value,
      groupId: '',
      umbrella_group: ''
    })
    fetchGroupsByYearGroups({
      jwt,
      academic_year: e.target.value,
      type: 'academics'
    })
  }
  render() {
    const {
      academicGroups,
      ready,
      archiveFilterType,
      academicyears,
      groupsIsloading
    } = this.props
    const {
      search,
      umbrella_group,
      goClicked,
      isDirty,
      groupId,
      options,
      showSearchFilter,
      studentDetails,
      academic_year
    } = this.state
    console.log(
      'qdfghjkwsedrfghjkwsedrftgyhujikopedrfghj',
      goClicked && ready && groupId !== '' && this.getStudentData()
    )
    return (
      <div>
        <Modal show={showSearchFilter} onHide={this.hideSearchFilter}>
          <ModalHeader closeButton>
            <p className="modal-title">
              <FontAwesome name="pencil" /> SELECT THE FIELDS
            </p>
          </ModalHeader>
          <ModalBody className="filter-modal-body">
            <div className="filter-modal-padding">
              {Object.keys(STUDENT_DETAIL_HEADERS).map(header => (
                <div key={header}>
                  <p className="filter-modal-text-header">
                    {STUDENT_DETAIL_HEADERS[header]}
                  </p>
                  <div className="row">
                    {Object.keys(studentDetails[header]).map(studentKey => (
                      <div className="col-sm-4" key={studentKey}>
                        {this.renderCheckboxData(header, studentKey)}
                      </div>
                    ))}
                  </div>
                  <div className="space-creator" />
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.hideSearchFilter}
              className="button button--small button--blue"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
        <div className="container">
          <PageHelmet>Student List</PageHelmet>
          <div className="row">
            <div className="col-md-3 col-md-offset-0 col-xs-4 col-xs-offset-4">
              <div className="tab">
                <p className="tab__text">STUDENT LIST</p>
              </div>
            </div>
            <div className="col-md-9 col-xs-12">
              <GroupSelector
                isDirty={isDirty}
                academicGroups={academicGroups}
                umbrellaGroup={umbrella_group}
                groupId={groupId}
                handleSubmit={this.handleSubmit}
                handleChange={this.setGroup}
                handleAcademicYearChange={this.handleAcademicYearChange}
                academicyears={academicyears}
                groupsIsloading={groupsIsloading}
                academic_year={academic_year}
              />
            </div>
          </div>
        </div>
        <div className="attd-search">
          <div className="container">
            <div className="row">
              <div className="col-xs-6" />
              <SideButtonsWithCSV
                downloadFile={this.downloadFile}
                isDisabled={!goClicked || !ready}
                downloadReport={this.downloadReport}
                openSearchFilter={this.openSearchFilter}
                archiveFilterType={archiveFilterType}
                options={options}
                csvData={this.getCSV}
                handleArchiveToggle={this.handleArchiveToggle}
              />
            </div>
            <SearchBar
              active={this.active()}
              handleChange={this.setSearch}
              placeholderText="Filter students by name, roll number,
              admission number, gender, account number or reservation"
              value={search}
            />
            {goClicked && groupId !== '' && (
              <div className="date-and-class">
                Total Students: {this.filter(search).length}
              </div>
            )}
          </div>
        </div>
        <div className="container">
          {goClicked && ready && groupId !== '' && (
            <StudentTable
              active={this.active()}
              studentData={this.getStudentData()}
              tableHeaders={this.getTableHeaders()}
              groupSubjectIds={this.getGroupSubjectIds(groupId)}
            />
          )}
        </div>
        {!ready && <Spinner />}
      </div>
    )
  }
}

const StudentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentListComponent)

export default StudentList
