import { CHANGE_ACADEMIC_YEAR_FOR_SESSION } from '../../helpers/actions'
import { successToast } from '../../helpers/utils'

export const saveAcademicYearForSession = data => {
  successToast('Successfully changed the academic year')
  // window.location.reload(true)
  return {
    type: CHANGE_ACADEMIC_YEAR_FOR_SESSION,
    data
  }
}
