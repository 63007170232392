import {
  APP_LOGOUT,
  HOURS_FETCH_START,
  HOURS_DATA,
  HOURS_FETCH_FAIL,
  HOUR_POST_START,
  HOUR_POST_SUCCESS,
  HOUR_PATCH_SUCCESS,
  HOUR_POST_FAILURE,
  RESET_HOUR_ADDED,
  DELETE_HOUR_START,
  DELETE_HOUR_SUCCESS,
  DELETE_HOUR_FAIL
} from '../helpers/actions'
import { createSelector } from 'reselect'
// import helper functions
import { getAllWeekDays } from '../helpers/utils'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  isErr: false,
  errMsg: '',
  postInProgress: false,
  hourAdded: false,
  successMsg: '',
  deleteLoading: false
}

export const hours = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case HOURS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case HOURS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        successMsg: ''
      })
    case HOURS_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.entities,
        allIds: action.result,
        successMsg: ''
      })
    case HOUR_POST_START:
      return Object.assign({}, state, {
        postInProgress: true,
        hourAdded: false,
        successMsg: ''
      })
    case HOUR_POST_SUCCESS:
      return Object.assign({}, state, {
        postInProgress: false,
        hourAdded: true,
        successMsg: action.message
      })
    case HOUR_PATCH_SUCCESS:
      return Object.assign({}, state, {
        postInProgress: false,
        byId: {
          ...state.byId,
          [action.data._id]: action.data
        },
        successMsg: 'patched'
      })
    case HOUR_POST_FAILURE:
      return Object.assign({}, state, {
        postInProgress: false,
        hourAdded: false,
        successMsg: ''
      })
    case RESET_HOUR_ADDED:
      return Object.assign({}, state, {
        postInProgress: false,
        hourAdded: false
      })
    case DELETE_HOUR_START:
      return Object.assign({}, state, {
        deleteLoading: true,
        isErr: false,
        errMsg: '',
        successMsg: ''
      })
    case DELETE_HOUR_SUCCESS:
      return Object.assign({}, state, {
        deleteLoading: false,
        isErr: false
      })
    case DELETE_HOUR_FAIL:
      return Object.assign({}, state, {
        deleteLoading: false,
        isErr: true
      })
    default:
      return state
  }
}

export const byId = state => state.hours.byId
export const allIds = state => state.hours.allIds
export const isHourLoading = state => state.hours.isLoading
export const getPostProgress = state => state.hours.postInProgress
export const getHourAdded = state => state.hours.hourAdded
export const getSuccessMsg = state => state.hours.successMsg
export const getDeleteLoading = state => state.hours.deleteLoading

export const getHourArr = createSelector(byId, allIds, (byId, allIds) => {
  if (!allIds || allIds.length === 0) return []
  return allIds
    .filter(id => !byId[id]?.archive)
    .map(id => byId[id])
    .sort((a, b) => Number(a.order) - Number(b.order))
})

export const getHoursByDay = createSelector(getHourArr, hoursArr => {
  if (hoursArr.length === 0) return {}
  const obj = {}
  // to have all the days in the obj atleast an empty array
  getAllWeekDays().forEach(day => {
    obj[day] = []
  })
  hoursArr.forEach(hourObj => {
    const { day, _id: hourId } = hourObj
    obj[day]?.push({
      hourId,
      subjectId: '-'
    })
  })
  return obj
})
