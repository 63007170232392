import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
//import selectors
import { getInstitutionData } from '../../reducers/institution'

const mapStateToProps = state => ({
  institution: getInstitutionData(state)
})

class PageHelmetComponent extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    //connect
    institution: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }

  render() {
    const {
      children,
      institution: { name }
    } = this.props
    return <Helmet title={`${children} | ${name} | Sealtabs`} />
  }
}

const PageHelmet = connect(mapStateToProps)(PageHelmetComponent)

export default PageHelmet
