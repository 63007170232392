// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
// import enums
import { CONDUCT_CERTIFICATE_NAME } from '../../helpers/enums'
// import selectors
import {
  getSectionNameByGroupId,
  getStudentIdsOfAGroup
} from '../../reducers/groups'

class ConfirmationPageContainer extends Component {
  static propTypes = {
    grade: PropTypes.string.isRequired,
    students: PropTypes.object.isRequired,
    groupName: PropTypes.string.isRequired,
    groupStudentIds: PropTypes.array.isRequired
  }
  render() {
    const {
      // redux form props
      handleSubmit,
      submitting,
      students,
      grade,
      // store props
      groupName,
      groupStudentIds
    } = this.props
    const selectedStudentsLength = Object.keys(students).filter(
      studentId => students[studentId]
    ).length
    const groupStudentsLength = groupStudentIds.length
    return (
      <div className="form-ctr">
        <form className="st-group-form" onSubmit={handleSubmit}>
          <div className="new-sub__breadcrumb">
            <p className="info-title">Confirmation Page:</p>
            <div className="modal-wrapper">
              <div className="info-row">
                <span className="info-cell info-cell--left">
                  Class - Section:
                </span>
                <span className="dash" />
                <span className="info-cell">
                  {grade} - {groupName}
                </span>
              </div>
              <div className="info-row">
                <span className="info-cell info-cell--left">
                  Selected Students:
                </span>
                <span className="dash" />
                <span className="info-cell">
                  {selectedStudentsLength} / {groupStudentsLength} students
                </span>
              </div>
            </div>
          </div>

          <div className="field submit-field">
            <button
              className="button button--blue"
              type="submit"
              disabled={submitting}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const selector = formValueSelector(CONDUCT_CERTIFICATE_NAME)

const mapStateToProps = state => {
  const grade = selector(state, 'grade')
  const groupId = selector(state, 'groupId')
  const students = selector(state, 'students')
  return {
    grade,
    students,
    groupName: getSectionNameByGroupId(state, groupId),
    groupStudentIds: getStudentIdsOfAGroup(state, groupId)
  }
}

const ConfirmationPage = connect(mapStateToProps)(
  reduxForm({
    form: CONDUCT_CERTIFICATE_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(ConfirmationPageContainer)
)

export default ConfirmationPage
