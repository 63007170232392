// import packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Tabs, Tab } from 'react-bootstrap'
//selectors
import { getJwt } from '../../../reducers/accounts'
//actions
import {
  startGroupPatch,
  startDeleteGroupSubjects,
  startDeleteGroupTests
} from '../../../actions/groups'
// import components
import EditGroupData from './editClassData'
import { DeleteSubjects } from './deleteSubjects'
import { DeleteTests } from './deleteTests'
import { EditClass } from './renameClass'
import AddOrEditSubjectTeachers from './assignTeacher'

class StudentGroupComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    patchGroupData: PropTypes.func.isRequired,
    subjects: PropTypes.object.isRequired,
    testIds: PropTypes.array.isRequired,
    tests: PropTypes.object.isRequired,
    validSubjects: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    teachers: PropTypes.array.isRequired,
    groupId: PropTypes.string.isRequired,
    deleteGroupSubjects: PropTypes.func.isRequired,
    snackbarType: PropTypes.string.isRequired,
    setSnackbarType: PropTypes.func.isRequired,
    showSnackbar: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      deleteSubjects: this.makeMapOfSubjects(),
      deletedTests: this.makeMapOfTests()
    }

    this.handleSubmitGroupData = this.handleSubmitGroupData.bind(this)
    this.makeMapOfSubjects = this.makeMapOfSubjects.bind(this)
    this.makeMapOfTests = this.makeMapOfTests.bind(this)
    this.deleteSubject = this.deleteSubject.bind(this)
    this.handleDeleteSubjects = this.handleDeleteSubjects.bind(this)
    this.deleteTest = this.deleteTest.bind(this)
    this.handleDeleteTests = this.handleDeleteTests.bind(this)
  }

  handleSubmitGroupData(e) {
    e.preventDefault()
    const {
      jwt,
      groupId,
      patchGroupData,
      selectedGroup,
      setSnackbarType
    } = this.props
    patchGroupData({ jwt, groupId, data: selectedGroup })
    setSnackbarType('groupData')
  }

  makeMapOfSubjects() {
    const { validSubjects } = this.props
    const deleteSubjects = {}
    validSubjects.forEach(sub => {
      deleteSubjects[sub] = false
    })
    return deleteSubjects
  }

  deleteSubject(e) {
    e.preventDefault()
    const { deleteSubjects } = this.state
    deleteSubjects[e.target.id] = !deleteSubjects[e.target.id]
    this.setState({ deleteSubjects })
  }

  handleDeleteSubjects(e) {
    e.preventDefault()
    const { jwt, groupId, deleteGroupSubjects, setSnackbarType } = this.props
    const { deleteSubjects } = this.state
    let subjects = []
    Object.keys(deleteSubjects).forEach(sub => {
      if (deleteSubjects[sub]) subjects.push(sub)
    })
    deleteGroupSubjects({
      jwt,
      data: {
        groupId,
        subjects
      }
    })
    setSnackbarType('subjects')
  }

  makeMapOfTests() {
    const { testIds } = this.props
    const deleteTests = {}
    testIds.forEach(test => {
      deleteTests[test] = false
    })
    return deleteTests
  }

  deleteTest(e) {
    e.preventDefault()
    const { deletedTests } = this.state
    deletedTests[e.target.id] = !deletedTests[e.target.id]
    this.setState({ deletedTests })
  }

  handleDeleteTests(e) {
    e.preventDefault()
    const { jwt, groupId, deleteGroupTests, setSnackbarType } = this.props
    const { deletedTests } = this.state
    const tests = []
    Object.keys(deletedTests).forEach(test => {
      if (deletedTests[test]) tests.push(test)
    })
    deleteGroupTests({
      jwt,
      groupId,
      tests
    })
    setSnackbarType('tests')
  }

  getSubjectsWithoutElectives(subjects) {
    const subjectClone = Object.assign({}, subjects)
    const obj = {}
    Object.keys(subjectClone)
      .filter(subjectId => subjectClone[subjectId].electives.length === 0)
      .forEach(subjectId => {
        obj[subjectId] = subjectClone[subjectId]
      })
    return obj
  }

  render() {
    const {
      umbrellaGroup,
      selectedgroupId,
      selectedGroup,
      subjects,
      testIds,
      tests,
      validSubjects,
      handleChange,
      teachers,
      showSnackbar,
      snackbarType,
      hideSnackbar,
      subjectTeachers,
      teachersById,
      groupId,
      setSnackbarType
    } = this.props

    const { deleteSubjects, deletedTests } = this.state
    return (
      <div className="editClassSectionTab">
        <Tabs defaultActiveKey="editClass">
          <Tab eventKey="editClass" title="Edit Section">
            <EditGroupData
              selectedGroup={selectedGroup}
              handleChange={handleChange}
              teachers={teachers}
              handleClick={this.handleSubmitGroupData}
              showSnackbar={showSnackbar}
              hideSnackbar={hideSnackbar}
              snackbarType={snackbarType}
            />
            <EditClass
              umbrellaGroup={umbrellaGroup}
              selectedgroupId={selectedgroupId}
              snackbarType={snackbarType}
              showSnackbar={showSnackbar}
              setSnackbarType={setSnackbarType}
              hideSnackbar={hideSnackbar}
            />
          </Tab>
          <Tab eventKey="deleteSubjects" title="Delete Subjects">
            <DeleteSubjects
              subjects={subjects}
              existingSubjects={validSubjects}
              deleteSubject={this.deleteSubject}
              deletedSubjects={deleteSubjects}
              handleDeleteSubjects={this.handleDeleteSubjects}
              showSnackbar={showSnackbar}
              hideSnackbar={hideSnackbar}
              snackbarType={snackbarType}
            />
          </Tab>
          <Tab eventKey="deleteTests" title="Delete Tests">
            <DeleteTests
              tests={tests}
              existingTestIds={testIds}
              deleteTest={this.deleteTest}
              deletedTests={deletedTests}
              handleDeleteTests={this.handleDeleteTests}
              showSnackbar={showSnackbar}
              hideSnackbar={hideSnackbar}
              snackbarType={snackbarType}
            />
          </Tab>
          <Tab eventKey="assignTeacher" title="Assign Teacher">
            {Object.keys(teachersById).length !== 0 &&
              Object.keys(subjects).length !== 0 && (
                <AddOrEditSubjectTeachers
                  subjectTeachers={subjectTeachers}
                  teacherArr={teachers}
                  subjectsById={this.getSubjectsWithoutElectives(subjects)}
                  teachersById={teachersById}
                  groupId={groupId}
                />
              )}
          </Tab>
        </Tabs>
        <div className="clear"></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  jwt: getJwt(state)
})

const mapDispatchToProps = dispatch => ({
  patchGroupData(data) {
    dispatch(startGroupPatch(data))
  },
  deleteGroupSubjects(data) {
    dispatch(startDeleteGroupSubjects(data))
  },
  deleteGroupTests(data) {
    dispatch(startDeleteGroupTests(data))
  }
})

export const StudentGroup = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentGroupComponent)
