import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import DatePicker from 'react-datepicker'
import moment from 'moment'

/*
TODO integrate these as redux-form components
*/

export const PasswordInput = ({
  name,
  type,
  placeholder,
  handleChange,
  onToggleShow
}) => (
  <div className="Input--password">
    <div className="Input Input--full">
      <input
        name={name}
        autoComplete="false"
        required
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <label htmlFor={name} />
    </div>
    {/* <button onClick={e => onToggleShow(e)}>
      <i class={type == 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
    </button> */}
  </div>
)

export const Input = ({ name, type, placeholder, handleChange }) => (
  <div className="Input">
    <input
      name={name}
      autoComplete="false"
      required
      type={type}
      placeholder={placeholder}
      onChange={handleChange}
    />
    <label htmlFor={name} />
  </div>
)

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}

export const TextInput = ({
  name = 'name-id',
  placeholder = 'Type here',
  handleChange,
  type = 'text',
  required = false,
  disabled = false,
  value
}) => {
  return (
    <div className="text-input">
      <input
        name={name}
        type={type}
        autoComplete="false"
        required={required}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        value={value}
      />
      <label htmlFor={name} />
    </div>
  )
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool
}

export const TextAreaInput = ({
  name,
  placeholder,
  handleChange,
  required = false
}) => {
  return (
    <div className="textarea-input">
      <input
        name={name}
        type="textarea"
        autoComplete="false"
        required={required}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <label htmlFor={name} />
    </div>
  )
}

TextAreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool
}

export const SelectInput = ({
  name,
  options,
  handleChange,
  umbrellaGroup,
  required = false,
  className,
  labelClass,
  fieldClass,
  leftIconClass,
  rightIconClass,
  showLeftIcon,
  showRightIcon,
  iconSize,
  leftIconName,
  rightIconName,
  placeholder,
  placeholderEnable
}) => {
  if (0 !== options.length) {
    return (
      <label className={labelClass}>
        {showLeftIcon && (
          <span className={`selector__field__icon ${leftIconClass}`}>
            <FontAwesome className={`icon ${iconSize}`} name={leftIconName} />
          </span>
        )}
        <select
          className={fieldClass}
          required={required}
          name={name}
          value={umbrellaGroup}
          onChange={handleChange}
        >
          <option value="" disabled={!placeholderEnable}>
            {' '}
            {placeholder}{' '}
          </option>
          {options.map((value, index) => {
            return (
              <option key={index} value={value}>
                {value}
              </option>
            )
          })}
        </select>
        {showRightIcon && (
          <span className={`selector__field__icon ${rightIconClass}`}>
            <FontAwesome className={`icon ${iconSize}`} name={rightIconName} />
          </span>
        )}
      </label>
    )
  } else
    return (
      <label className={labelClass}>
        {showLeftIcon && (
          <span className={`selector__field__icon ${leftIconClass}`}>
            <FontAwesome className={`icon ${iconSize}`} name={leftIconName} />
          </span>
        )}
        <select className={fieldClass}>
          <option>No options available</option>
        </select>
        {showRightIcon && (
          <span className={`selector__field__icon ${rightIconClass}`}>
            <FontAwesome className={`icon ${iconSize}`} name={rightIconName} />
          </span>
        )}
      </label>
    )
}

SelectInput.defaultProps = {
  placeholder: 'Select an Option'
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  leftIconClass: PropTypes.string,
  rightIconClass: PropTypes.string,
  showLeftIcon: PropTypes.bool,
  showRightIcon: PropTypes.bool,
  placeholder: PropTypes.string
}

export const SelectInputWithID = ({
  placeholder,
  name,
  options = [],
  handleChange,
  id,
  required = false,
  labelClass,
  fieldClass,
  leftIconClass,
  rightIconClass,
  showLeftIcon,
  showRightIcon,
  iconSize,
  leftIconName,
  rightIconName
}) => {
  if (options.length === 0) {
    // options will be an array of objects of the form [{Id: value}]
    return (
      <label className={labelClass}>
        {showLeftIcon && (
          <span className={`selector__field__icon ${leftIconClass}`}>
            <FontAwesome className={`icon ${iconSize}`} name={leftIconName} />
          </span>
        )}
        <select className={fieldClass}>
          <option value="">
            {placeholder ? placeholder : 'No options available'}
          </option>
        </select>
        {showRightIcon && (
          <span className={`selector__field__icon ${rightIconClass}`}>
            <FontAwesome className={`icon ${iconSize}`} name={rightIconName} />
          </span>
        )}
      </label>
    )
  }
  return (
    <label className={labelClass}>
      {showLeftIcon && (
        <span className={`selector__field__icon ${leftIconClass}`}>
          <FontAwesome className={`icon ${iconSize}`} name={leftIconName} />
        </span>
      )}
      <select
        className={fieldClass}
        required={required}
        name={name}
        value={id}
        onChange={handleChange}
      >
        <option value=""> {placeholder} </option>
        {options.map((option, index) => {
          return (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </select>
      {showRightIcon && (
        <span className={`selector__field__icon ${rightIconClass}`}>
          <FontAwesome className={`icon ${iconSize}`} name={rightIconName} />
        </span>
      )}
    </label>
  )
}

SelectInputWithID.defaultProps = {
  placeholder: 'Select an option'
}

SelectInputWithID.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  leftIconClass: PropTypes.string,
  rightIconClass: PropTypes.string,
  showLeftIcon: PropTypes.bool,
  showRightIcon: PropTypes.bool
}

export const DatePickerWrapper = ({
  date,
  handleDateChange,
  placeholder
  // restrictedAttendance
}) => (
  <div className="selector__field">
    <span className="selector__field__icon selector__field__icon--small selector__field__icon--gray-bg">
      <FontAwesome className="icon icon--small" name="list-ol" />
    </span>
    {/* {restrictedAttendance ? (
      <DatePicker
        dateFormat="DD/MM/YYYY"
        selected={date}
        onChange={handleDateChange}
        className="selector__field__input"
        placeholderText={placeholder ? placeholder : 'Click to select date'}
        maxDate={moment()}
      />
    ) : ( */}
    <DatePicker
      dateFormat="DD/MM/YYYY"
      selected={date}
      onChange={handleDateChange}
      className="selector__field__input"
      placeholderText={placeholder ? placeholder : 'Click to select date'}
    />
    <span
      className="selector__field__icon
    selector__field__icon--small selector__field__icon--white-bg"
    >
      <FontAwesome className="icon icon--small" name="caret-down" />
    </span>
  </div>
)

export const NewSelectInput = ({
  name,
  options,
  handleChange,
  value,
  required = false,
  iconName = '',
  defaultOption = 'Select an Option'
}) => {
  if (options && options.length !== 0) {
    return (
      <label className="selector__field">
        <span className="selector__field__icon selector__field__icon--gray-bg selector__field__icon--small">
          {
            (iconName = '' ? null : (
              <FontAwesome className="icon icon--small" name={iconName} />
            ))
          }
        </span>
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option selected value="">
            {' '}
            {defaultOption}{' '}
          </option>
          {options.map((value, index) => {
            return (
              <option key={index} value={value}>
                {value}
              </option>
            )
          })}
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    )
  }
  return (
    <label>
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option>No options available</option>
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    </label>
  )
}

export const NewSelectInputWithID = ({
  name,
  options,
  handleChange,
  value,
  required = false,
  defaultOption = 'Select an Option'
}) => {
  if (options && Object.keys(options).length !== 0) {
    return (
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option selected value="">
            {' '}
            {defaultOption}{' '}
          </option>
          {Object.keys(options).map((k, index) => {
            return (
              <option key={index} value={k}>
                {options[k]}
              </option>
            )
          })}
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    )
  }
  return (
    <label>
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option>No options available</option>
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    </label>
  )
}

export const NewSelectInputWithIDAndName = ({
  name,
  options,
  handleChange,
  value,
  required = false,
  defaultOption = 'Select an Option'
}) => {
  if (options && Object.keys(options).length !== 0) {
    return (
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={e => handleChange(e)}
          className="selector__field__input"
        >
          <option selected value="">
            {' '}
            {defaultOption}{' '}
          </option>
          {Object.keys(options).map((k, index) => {
            return (
              <option key={index} value={k}>
                {options[k]['name']}
              </option>
            )
          })}
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    )
  }
  return (
    <label>
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option>No options available</option>
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    </label>
  )
}

export const NewSelectInputArray = ({
  name,
  options,
  handleChange,
  value,
  required = false,
  defaultOption = 'Select an Option',
  noNull
}) => {
  if (options && options.length !== 0) {
    return (
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={e => handleChange(e)}
          className="selector__field__input"
        >
          {!noNull ? (
            <option selected value="">
              {' '}
              {defaultOption}{' '}
            </option>
          ) : null}
          {options.map((k, index) => {
            return (
              <option key={index} value={k._id}>
                {k['title']}
              </option>
            )
          })}
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    )
  }
  return (
    <label>
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option>No options available</option>
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    </label>
  )
}

export const FileUploader = ({ handleChange, handleSubmit, multiple }) => {
  return (
    <form onSubmit={handleSubmit}>
      <input type="file" multiple={multiple} onChange={handleChange} />
      <input type="submit" value="Upload" />
    </form>
  )
}

export const SelectClassAndSection = ({
  name,
  options,
  handleChange,
  value,
  required = false,
  defaultOption = 'Select an Option'
}) => {
  if (options && Object.keys(options).length !== 0) {
    return (
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={e => handleChange(e)}
          className="selector__field__input"
        >
          <option selected value="">
            {' '}
            {defaultOption}{' '}
          </option>
          {Object.keys(options).map((k, index) => {
            return (
              <option key={index} value={k}>
                {options[k]['umbrella_group']} - {options[k]['group_name']}
              </option>
            )
          })}
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    )
  }
  return (
    <label>
      <label className="selector__field">
        <select
          required={required}
          name={name}
          value={value}
          onChange={handleChange}
          className="selector__field__input"
        >
          <option>No options available</option>
        </select>
        <span className="selector__field__icon selector__field__icon--white-bg selector__field__icon--small">
          <FontAwesome className="icon icon--small" name="caret-down" />
        </span>
      </label>
    </label>
  )
}

//button pills
export const ButtonPills = ({
  name,
  options,
  handleChange,
  umbrellaGroup,
  required = false,
  className,
  labelClass,
  fieldClass,
  leftIconClass,
  rightIconClass,
  showLeftIcon,
  showRightIcon,
  iconSize,
  leftIconName,
  rightIconName,
  placeholder
}) => {
  if (0 !== options.length) {
    return (
      <div className="buttonPills" required={required}>
        {options.map((value, index) => {
          return (
            <button
              className={
                'buttonPills__button ' +
                (umbrellaGroup === value ? 'buttonPills__active' : '')
              }
              value={umbrellaGroup}
              onClick={handleChange}
              key={index}
              value={value}
            >
              {value}
            </button>
          )
        })}
        <div className="clear"></div>
      </div>
    )
  } else return <p>No options available</p>
}

ButtonPills.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  leftIconClass: PropTypes.string,
  rightIconClass: PropTypes.string,
  showLeftIcon: PropTypes.bool,
  showRightIcon: PropTypes.bool,
  placeholder: PropTypes.string
}
