import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput, SelectInputWithID } from '../../inputs.jsx'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import Spinner from '../../spinner'

import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../../helpers/enums'

export const GroupSelector = ({
  umbrellaGroup,
  groupId,
  parentNames,
  sections,
  setSection,
  handleParentChange,
  handleGo,
  isDirty
}) => {
  return (
    <div className="group-selector">
      <SelectInput
        name="parent_group"
        options={parentNames}
        umbrellaGroup={umbrellaGroup}
        handleChange={handleParentChange}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />

      <FontAwesome size="2x" name="caret-right" />
      <SelectInputWithID
        name="groupId"
        options={sections}
        id={groupId}
        handleChange={setSection}
        required
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <FontAwesome size="2x" name="caret-right" />
      <button
        onClick={handleGo}
        type="submit"
        className={classNames({
          'button button--small button--blue': true,
          disabled: !isDirty
        })}
        disabled={!isDirty}
      >
        Go
      </button>
    </div>
  )
}
