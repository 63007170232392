import {
  APP_LOGOUT,
  POST_STUDENT_ACHIEVEMENT_START,
  POST_STUDENT_ACHIEVEMENT,
  POST_STUDENT_ACHIEVEMENT_FAIL,
  GET_ACHIEVEMENT_BY_STUDENT_ID_START,
  GET_ACHIEVEMENT_BY_STUDENT_ID,
  GET_ACHIEVEMENT_BY_STUDENT_ID_FAIL,
  PATCH_ACHIEVEMENT_BY_STUDENT_ID_START,
  PATCH_ACHIEVEMENT_BY_STUDENT_ID,
  PATCH_ACHIEVEMENT_BY_STUDENT_ID_FAIL,
  DELETE_STUDENT_ACHIEVEMENT_START,
  DELETE_STUDENT_ACHIEVEMENT_FAIL,
  DELETE_STUDENT_ACHIEVEMENT,
  GET_ACHIEVEMENT_FOR_STUDENT_START,
  GET_ACHIEVEMENT_FOR_STUDENT,
  GET_ACHIEVEMENT_FOR_STUDENT_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isLoadingPatch: false,
  isLoadingDelete: false,
  isErr: false,
  errMsg: '',
  studentAchievements: []
}

export const achievements = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_STUDENT_ACHIEVEMENT_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErr: false,
        errMsg: ''
      })
    case POST_STUDENT_ACHIEVEMENT:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: false
      })
    case POST_STUDENT_ACHIEVEMENT_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case GET_ACHIEVEMENT_BY_STUDENT_ID_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case GET_ACHIEVEMENT_BY_STUDENT_ID:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        studentAchievements: action.data
      })
    case GET_ACHIEVEMENT_BY_STUDENT_ID_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case GET_ACHIEVEMENT_FOR_STUDENT_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case GET_ACHIEVEMENT_FOR_STUDENT:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: false,
        studentAchievements: action.data
      })
    case GET_ACHIEVEMENT_FOR_STUDENT_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.error
      })
    case PATCH_ACHIEVEMENT_BY_STUDENT_ID_START:
      return Object.assign({}, state, {
        isLoadingPatch: true,
        isErr: false,
        errMsg: ''
      })
    case PATCH_ACHIEVEMENT_BY_STUDENT_ID:
      return Object.assign({}, state, {
        isLoadingPatch: false,
        isErr: false
      })
    case PATCH_ACHIEVEMENT_BY_STUDENT_ID_FAIL:
      return Object.assign({}, state, {
        isLoadingPatch: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case DELETE_STUDENT_ACHIEVEMENT_START:
      return Object.assign({}, state, {
        isLoadingDelete: true,
        isErr: false
      })
    case DELETE_STUDENT_ACHIEVEMENT:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isSuccess: true,
        isErr: false
      })
    case DELETE_STUDENT_ACHIEVEMENT_FAIL:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isErr: true,
        errMsg: action.errMsg,
        isSuccess: false
      })
    default:
      return state
  }
}

// selectors
export const getIsLoading = state => state.achievements.isLoading
export const getIsLoadingPost = state => state.achievements.isLoadingPost
export const getIsLoadingPatch = state => state.achievements.isLoadingPatch
export const getIsLoadingDelete = state => state.achievements.isLoadingDelete
export const getIsError = state => state.achievements.isErr
export const getErrMsg = state => state.achievements.errMsg
export const getAchievements = state => state.achievements.studentAchievements
