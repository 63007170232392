import {
  INSTITUTION_DATA,
  INSTITUTION_DATA_START,
  INSTITUTION_DATA_FAIL,
  SET_ACADEMIC_YEAR_DATA,
  POST_INSTITUTION_START,
  POST_INSTITUTION_FAIL,
  POST_INSTITUTION_DATA,
  FETCH_ALL_INSTITUTIONS_DATA,
  FETCH_ALL_INSTITUTIONS_START,
  FETCH_ALL_INSTITUTIONS_FAIL,
  FETCH_ALL_INSTITUTIONS_USERS_DATA,
  FETCH_ALL_INSTITUTIONS_USERS_START,
  FETCH_ALL_INSTITUTIONS_USERS_FAIL,
  POST_INSTITUTION_FINANCIAL_DATA,
  POST_INSTITUTION_FINANCIAL_START,
  POST_INSTITUTION_FINANCIAL_FAIL,
  ADD_INSTITUTIONS_USER_DATA,
  ADD_INSTITUTIONS_USER_START,
  ADD_INSTITUTIONS_USER_FAIL,
  POST_BILLING_TRANSACTIONS_DATA,
  POST_BILLING_TRANSACTIONS_START,
  POST_BILLING_TRANSACTIONS_FAIL,
  GET_SUPPORT_INSTITUTION_START,
  GET_SUPPORT_INSTITUTION_SUCCESS,
  GET_SUPPORT_INSTITUTION_FAIL,
  FETCH_ALL_SUPPORT,
  FETCH_ALL_SUPPORT_START,
  FETCH_ALL_SUPPORT_FAIL,
  DELETE_SUPPORT_USER_START,
  DELETE_SUPPORT_USER_SUCCESS,
  DELETE_SUPPORT_USER_FAIL,
  RESET_SUPPORT_START,
  RESET_SUPPORT_SUCCESS,
  RESET_SUPPORT_FAIL,
  GET_LOG_REPORT_START,
  GET_LOG_REPORT_SUCCESS,
  GET_LOG_REPORT_FAIL
} from '../helpers/actions.js'

import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  successToast,
  errorToast,
  getById
} from '../helpers/utils.js'

import { emitAuthFailed } from './login.js'

const fetchInstitution = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/institution'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchInstitution = data => {
  console.log('data startFetchInstitution: ', data)
  return function(dispatch) {
    dispatch(emitInstitutionDataStart())
    return fetchInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionDataFail())
      })
  }
}

//by id
const fetchInstitutionById = ({ jwt, id }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/institution/${id}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchInstitutionById = data => {
  return function(dispatch) {
    dispatch(emitInstitutionDataStart())
    return fetchInstitutionById(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionDataFail())
      })
  }
}
const emitInstitutionData = data => ({
  type: INSTITUTION_DATA,
  data
})
const emitInstitutionDataStart = () => ({
  type: INSTITUTION_DATA_START
})
const emitInstitutionDataFail = () => ({
  type: INSTITUTION_DATA_FAIL
})

const emitAcadmicYearData = data => ({
  type: SET_ACADEMIC_YEAR_DATA,
  data
})

export const startSetAcadmicYear = data => {
  return function(dispatch) {
    return dispatch(emitAcadmicYearData(data))
  }
}

const emitPostFinancialInstitutionData = data => ({
  type: POST_INSTITUTION_FINANCIAL_DATA,
  data
})

const emitPostFinancialInstitutionDataStart = () => ({
  type: POST_INSTITUTION_FINANCIAL_START
})

const emitPostFinancialInstitutionDataFail = () => ({
  type: POST_INSTITUTION_FINANCIAL_FAIL
})

//Add Finicial details of institution
const postFinicialInstitution = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/billing-details'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPostFinicialInstitution = data => {
  return function(dispatch) {
    dispatch(emitPostFinancialInstitutionDataStart())
    return postFinicialInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostFinancialInstitutionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFinancialInstitutionDataFail())
      })
  }
}

//Edit Finicial details of institution
const patchFinicialInstitution = ({
  jwt,
  data,
  billingId,
  institutionId,
  academicYear
}) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/billing-details/${institutionId}/${academicYear}/${billingId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPatchFinicialInstitution = data => {
  return function(dispatch) {
    dispatch(emitPostFinancialInstitutionDataStart())
    return patchFinicialInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostFinancialInstitutionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFinancialInstitutionDataFail())
      })
  }
}

//Add institution
const postInstitution = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/institution'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPostInstitution = data => {
  return function(dispatch) {
    dispatch(emitPostInstitutionDataStart())
    return postInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostInstitutionData(json.data))
        dispatch(startFetchAllInstitution({ jwt: data.jwt }))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostInstitutionDataFail())
      })
  }
}

//Edit institution
const editInstitution = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/institution`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startEditInstitution = data => {
  return function(dispatch) {
    dispatch(emitPostInstitutionDataStart())
    return editInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostInstitutionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostInstitutionDataFail())
      })
  }
}

//Edit institution by id
const editInstitutionById = ({ jwt, data, id }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/institution/${id}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startEditInstitutionById = data => {
  return function(dispatch) {
    dispatch(emitPostInstitutionDataStart())
    return editInstitutionById(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostInstitutionData(json.data))
        dispatch(startFetchAllInstitution({ jwt: data.jwt }))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostInstitutionDataFail())
      })
  }
}

//Delete institution
const deleteInstitution = ({ jwt, id }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/institution/${id}`
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startDeleteInstitution = data => {
  return function(dispatch) {
    dispatch(emitPostInstitutionDataStart())
    return deleteInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostInstitutionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostInstitutionDataFail())
      })
  }
}

const emitPostInstitutionData = data => ({
  type: POST_INSTITUTION_DATA,
  data
})

const emitPostInstitutionDataStart = () => ({
  type: POST_INSTITUTION_START
})

const emitPostInstitutionDataFail = () => ({
  type: POST_INSTITUTION_FAIL
})

//Get All institutions
const fetchAllInstitutions = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/institution/all`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchAllInstitution = data => {
  return function(dispatch) {
    dispatch(emitFetchAllInstitutionsDataStart())
    return fetchAllInstitutions(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitFetchAllInstitutionsData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitFetchAllInstitutionsDataFail())
      })
  }
}

const emitFetchAllInstitutionsData = data => ({
  type: FETCH_ALL_INSTITUTIONS_DATA,
  data,
  byId: getById(data, '_id')
})

const emitFetchAllInstitutionsDataStart = () => ({
  type: FETCH_ALL_INSTITUTIONS_START
})

const emitFetchAllInstitutionsDataFail = () => ({
  type: FETCH_ALL_INSTITUTIONS_FAIL
})

//fetch all institution users
const emitInstitutionUsersData = data => ({
  type: FETCH_ALL_INSTITUTIONS_USERS_DATA,
  data
})
const emitInstitutionUsersDataStart = () => ({
  type: FETCH_ALL_INSTITUTIONS_USERS_START
})
const emitInstitutionUsersDataFail = () => ({
  type: FETCH_ALL_INSTITUTIONS_USERS_FAIL
})

const getInstitutionUsers = ({ jwt, institutionId }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/support/user/institutionid/${institutionId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchInstitutionUsers = data => {
  return function(dispatch) {
    dispatch(emitInstitutionUsersDataStart())
    return getInstitutionUsers(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionUsersData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionUsersDataFail())
      })
  }
}

const getInstitutionUsersByLevel = ({ jwt, institutionId, level }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/user/institutionId/${institutionId}/level/${level}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchInstitutionUsersByLevel = data => {
  return function(dispatch) {
    dispatch(emitInstitutionUsersDataStart())
    return getInstitutionUsersByLevel(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionUsersData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionUsersDataFail())
      })
  }
}

//add institution user
const emitInstitutionAddUserData = data => ({
  type: ADD_INSTITUTIONS_USER_DATA,
  data
})
const emitInstitutionAddUserDataStart = () => ({
  type: ADD_INSTITUTIONS_USER_START
})
const emitInstitutionAddUserDataFail = () => ({
  type: ADD_INSTITUTIONS_USER_FAIL
})

const addInstitutionSupportUser = ({ jwt, data }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/support/user'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startAddInstitutionSupportUser = data => {
  return function(dispatch) {
    dispatch(emitInstitutionAddUserDataStart())
    return addInstitutionSupportUser(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionAddUserData(json.data))
        successToast('Successfully created the user')
      })
      .catch(error => {
        errorToast('User not created. Try Again')
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionAddUserDataFail())
      })
  }
}

const addInstitutionUser = ({ jwt, payload }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/user'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}

export const startAddInstitutionUser = data => {
  return function(dispatch) {
    dispatch(emitInstitutionAddUserDataStart())
    return addInstitutionUser(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionAddUserData(json.data))
        successToast('Successfully created the user')
      })
      .catch(error => {
        errorToast('User not created. Try Again')
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionAddUserDataFail())
      })
  }
}

//edit user
const editInstitutionUser = ({ jwt, data, userId, level }) => {
  let Authorization = composeAuth(jwt)
  let url =
    level === 'support' || level === 'superAdmin'
      ? process.env.REACT_APP_SERVER_NEW + `/api/support/user/${userId}`
      : process.env.REACT_APP_SERVER_NEW + `/api/user/${userId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startEditInstitutionUser = data => {
  return function(dispatch) {
    dispatch(emitInstitutionAddUserDataStart())
    return editInstitutionUser(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionAddUserData(json.data))
        successToast('User Updated Successfully!')
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionAddUserDataFail())
        errorToast('Unable to Update. Try Again!')
      })
  }
}

//delete user
const deleteInstitutionUser = ({ jwt, institutionId, userId }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW + `/api/user/${userId}/${institutionId}`
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startDeleteInstitutionUser = data => {
  return function(dispatch) {
    dispatch(emitInstitutionAddUserDataStart())
    return deleteInstitutionUser(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitInstitutionAddUserData(json.data))
        successToast('Successfully deleted the user.')
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitInstitutionAddUserDataFail())
        errorToast('Failed. Try Again!')
      })
  }
}

//billing transactions
const emitPostFinancialTransactionData = data => ({
  type: POST_BILLING_TRANSACTIONS_DATA,
  data
})

const emitPostFinancialTransactionDataStart = () => ({
  type: POST_BILLING_TRANSACTIONS_START
})

const emitPostFinancialTransactionDataFail = () => ({
  type: POST_BILLING_TRANSACTIONS_FAIL
})

//Delete billing transactions
const deleteFinancialTransaction = ({
  jwt,
  transactionId,
  institutionId,
  academicYear
}) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/billing-details/transcation/${institutionId}/${academicYear}/${transactionId}`
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startDeleteFinancialTransaction = data => {
  return function(dispatch) {
    dispatch(emitPostFinancialTransactionDataStart())
    return deleteFinancialTransaction(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostFinancialTransactionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFinancialTransactionDataFail())
      })
  }
}

//Edit billing transactions
const patchFinancialTransaction = ({
  jwt,
  data,
  transactionId,
  institutionId,
  academicYear
}) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/billing-details/transcation/${institutionId}/${academicYear}/${transactionId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(data)
  })
}

export const startPatchFinancialTransaction = data => {
  return function(dispatch) {
    dispatch(emitPostFinancialTransactionDataStart())
    return patchFinancialTransaction(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(emitPostFinancialTransactionData(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitPostFinancialTransactionDataFail())
      })
  }
}

//fetch support institution details
const supportInstitutionStart = () => ({
  type: GET_SUPPORT_INSTITUTION_START
})
const supportInstitutionSuccess = data => ({
  type: GET_SUPPORT_INSTITUTION_SUCCESS,
  data
})
const supportInstitutionFail = () => ({
  type: GET_SUPPORT_INSTITUTION_FAIL
})
const fetchSupportInstitution = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/support/user/me'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchSupportInstitution = data => {
  return function(dispatch) {
    dispatch(supportInstitutionStart())
    return fetchSupportInstitution(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(supportInstitutionSuccess(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(supportInstitutionFail())
      })
  }
}

//get all support users
const fetchAllSupportUsersSuccess = data => ({
  type: FETCH_ALL_SUPPORT,
  data
})

const fetchAllSupportUsersStart = () => ({
  type: FETCH_ALL_SUPPORT_START
})

const fetchAllSupportUsersFail = () => ({
  type: FETCH_ALL_SUPPORT_FAIL
})

const fetchAllSupportUsers = ({ jwt }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/support/user/all`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchAllSupportUsers = data => {
  return function(dispatch) {
    dispatch(fetchAllSupportUsersStart())
    return fetchAllSupportUsers(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(fetchAllSupportUsersSuccess(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(fetchAllSupportUsersFail())
      })
  }
}

//delete support user
const deleteSupportUserStart = data => ({
  type: DELETE_SUPPORT_USER_START
})

const deleteSupportUserSuccess = () => ({
  type: DELETE_SUPPORT_USER_SUCCESS
})

const deleteSupportUserFail = () => ({
  type: DELETE_SUPPORT_USER_FAIL
})

const deleteSupportUser = ({ jwt, userId }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + `/api/support/user/${userId}`
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startDeleteSupportUser = data => {
  return function(dispatch) {
    dispatch(deleteSupportUserStart())
    return deleteSupportUser(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(deleteSupportUserSuccess(json.data))
        successToast('User Deleted Successfully!')
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(deleteSupportUserFail())
        errorToast('Unable to delete. Try Again!')
      })
  }
}

//reset support password
const resetPswdSupportStart = data => ({
  type: RESET_SUPPORT_START
})

const resetPswdSupportSuccess = () => ({
  type: RESET_SUPPORT_SUCCESS
})

const resetPswdSupportFail = () => ({
  type: RESET_SUPPORT_FAIL
})

const resetPswdSupport = ({ jwt, levelId }) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/user/support/reset-password/${levelId}`
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startResetPswdSupport = data => {
  return function(dispatch) {
    dispatch(resetPswdSupportStart())
    return resetPswdSupport(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        dispatch(resetPswdSupportSuccess(json.data))
        successToast('User Deleted Successfully!')
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(resetPswdSupportFail())
        errorToast('Unable to delete. Try Again!')
      })
  }
}

//getlog reports per institution

const emitLogReportStart = () => ({
  type: GET_LOG_REPORT_START
})

const emitLogReport = data => ({
  type: GET_LOG_REPORT_SUCCESS,
  data
})
const emitLogReportFail = () => ({
  type: GET_LOG_REPORT_FAIL
})

const fetchInstitutionLogReport = ({
  jwt,
  institutionId,
  startDate,
  endDate
}) => {
  let Authorization = composeAuth(jwt)
  let url =
    process.env.REACT_APP_SERVER_NEW +
    `/api/user_sessions/institutionId/${institutionId}/start_date/${startDate}/end_date/${endDate}`
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}

export const startFetchInstitutionLogReport = data => {
  return function(dispatch) {
    dispatch(emitLogReportStart())
    return fetchInstitutionLogReport(data)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        console.log('json: ', json)
        dispatch(emitLogReport(json.data))
      })
      .catch(error => {
        if (error instanceof AuthError) dispatch(emitAuthFailed())
        else dispatch(emitLogReportFail())
      })
  }
}
