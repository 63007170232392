import {
  APP_LOGOUT,
  SET_FILE_UPLOAD_DETAILS,
  POST_FILE_UPLOAD_FAILED,
  START_FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  CLEAR_FILE_UPLOAD,
  UPDATE_FILE_LINKS
} from '../helpers/actions'

const initialState = {
  file: [],
  isLoading: false,
  messagge: '',
  byId: {},
  allIds: []
}

export const fileUpload = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_FILE_UPLOAD:
      return Object.assign({}, state, {
        isLoading: true,
        message: ''
      })
    case FILE_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        file: action.original
          ? [...action.data]
          : [...state.file, ...action.data],
        isLoading: false
      })
    case SET_FILE_UPLOAD_DETAILS:
      return Object.assign({}, state, {
        byId: {
          [action.id]: action.data
        },
        allIds: [action.id],
        isLoading: false,
        file: [],
        message: 'success'
      })
    case POST_FILE_UPLOAD_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'fail'
      })
    case CLEAR_FILE_UPLOAD:
      return Object.assign({}, state, initialState)
    case UPDATE_FILE_LINKS:
      return Object.assign({}, state, {
        file: action.data.data
      })
    default:
      return state
  }
}

//selectors
export const getById = state => state.fileUpload.byId
export const getAllIds = state => state.fileUpload.allIds
export const isFileLoading = state => state.fileUpload.isLoading
export const getFileSuccessMsg = state => state.fileUpload.message
export const getFileDocumentLink = state => state.fileUpload.file
