import {
  APP_LOGOUT,
  START_HOMEWORK_DOC_UPLOAD,
  HOMEWORK_DOC_UPLOAD_SUCCESS,
  HOMEWORK_DOC_UPLOAD_FAILURE,
  UPDATE_LINKS_HOMEWORK,
  START_POST_HOMEWORK,
  POST_HOMEWORK_SUCCESS,
  POST_HOMEWORK_FAILURE,
  START_FETCH_HOMEWORK,
  FETCH_HOMEWORK_SUCCESS,
  FETCH_HOMEWORK_FAILURE,
  PATCH_HOMEWORK_START,
  PATCH_HOMEWORK_SUCCESS,
  PATCH_HOMEWORK_FAIL,
  DELETE_HOMEWORK_START,
  DELETE_HOMEWORK_SUCCESS,
  DELETE_HOMEWORK_FAIL
} from '../helpers/actions.js'

const initialState = {
  isLoading: false,
  images: [],
  byId: {},
  allIds: [],
  msg: '',
  isPatchLoading: false
}

export const homeworkAndAssignment = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_HOMEWORK_DOC_UPLOAD:
      return Object.assign({}, state, {
        isLoading: true
      })
    case HOMEWORK_DOC_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        images: [...state.images, ...action.data],
        isLoading: false
      })
    case HOMEWORK_DOC_UPLOAD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false
      })
    case UPDATE_LINKS_HOMEWORK:
      return Object.assign({}, state, {
        images: action.data.data
      })
    case START_POST_HOMEWORK:
      return Object.assign({}, state, {
        isLoading: true,
        msg: ''
      })
    case POST_HOMEWORK_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        byId: {
          [action.data._id]: action.data
        },
        allIds: [action.data._id],
        images: [],
        msg: 'success'
      })
    case POST_HOMEWORK_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        msg: 'failure'
      })
    case START_FETCH_HOMEWORK:
      return Object.assign({}, state, {
        isLoading: true,
        msg: ''
      })
    case FETCH_HOMEWORK_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.byId,
        allIds: action.allIds,
        msg: 'fetch'
      })
    case FETCH_HOMEWORK_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        msg: ''
      })
    case PATCH_HOMEWORK_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        msg: ''
      })
    case PATCH_HOMEWORK_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        msg: 'patch'
      })
    case PATCH_HOMEWORK_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        msg: ''
      })
    case DELETE_HOMEWORK_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        msg: ''
      })
    case DELETE_HOMEWORK_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        msg: 'delete'
      })
    case DELETE_HOMEWORK_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        msg: ''
      })
    default:
      return state
  }
}
export const isHomeworkLoading = state => state.homeworkAndAssignment.isLoading
export const getById = state => state.homeworkAndAssignment.byId
export const getAllIds = state => state.homeworkAndAssignment.allIds
export const getSuccessMsg = state => state.homeworkAndAssignment.msg
export const homeworkDocumentsLink = state => state.homeworkAndAssignment.images
export const getPatchLoading = state =>
  state.homeworkAndAssignment.isPatchLoading
