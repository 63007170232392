// packages
import React from 'react'

import FontAwesome from 'react-fontawesome'

import { SelectInputWithID } from '../inputs.jsx'

// import enums
import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../helpers/enums'

const ModalCompoentWithCSV = ({
  downloadFile,
  downloadReport,
  openSearchFilter,
  handleArchiveToggle,
  options,
  archiveFilterType,
  isDisabled,
  csvData
}) => {
  return (
    <div className="col-xs-6">
      <div className="btn-container button-group  pull-right">
        {
          <button
            onClick={e => downloadFile(e, 'groups.csv', `${csvData()}`)}
            className="button button--blue button--short button--no-mg-right"
            disabled={isDisabled}
          >
            Generate CSV
          </button>
        }
        <span className="slash">&nbsp;</span>
        <button
          onClick={downloadReport}
          className="button button--blue button--short button--no-mg-right"
          disabled={isDisabled}
        >
          <FontAwesome name="filter" />
          Generate Report
        </button>
        <span className="slash">&nbsp;</span>

        <button
          onClick={openSearchFilter}
          className="button button--white button--short button--no-mg-right"
          disabled={isDisabled}
        >
          <FontAwesome name="filter" />
          Filter
        </button>
        <span className="slash">&nbsp;</span>
        <SelectInputWithID
          name="group_name"
          options={options}
          id={archiveFilterType}
          handleChange={handleArchiveToggle}
          {...STYLE_PROPS_FOR_GROUP_SELECTOR}
        />
      </div>
    </div>
  )
}
export default ModalCompoentWithCSV
