import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TestDetailSection from './components/testDetails'
import { FieldArray } from 'redux-form'

class MultipleTestForm extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired
  }

  render() {
    const { name = 'subtests' } = this.props
    return <FieldArray name={name} component={TestDetailSection} />
  }
}

export default MultipleTestForm
