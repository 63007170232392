import React from 'react'
import PropTypes from 'prop-types'
import { StudentRow } from './studentRow.jsx'

import { renderMaxMarks } from '../../helpers/utils'

export const StudentListTable = ({ studentData, subjectData }) => {
  return (
    <tbody>
      <tr className="heading heading--total-and-percentage">
        <td className="table-row__cell table-row__cell--empty-left table-head-maxmarks" />
        <td className="table-row__cell table-row__cell--empty-left table-head-maxmarks" />
        {subjectData.map(obj => {
          return (
            <td key={obj._id} className="table-head-maxmarks">
              {renderMaxMarks(obj, studentData)}
            </td>
          )
        })}
        <td className="table-row__cell table-row__cell--empty-right table-head-maxmarks" />
        <td className="table-row__cell table-row__cell--empty-right table-head-maxmarks" />
        <td className="table-row__cell table-row__cell--empty-right table-head-maxmarks" />
        <td className="table-row__cell table-row__cell--empty-right table-head-maxmarks" />
      </tr>
      {studentData.map(student => {
        return (
          <StudentRow
            key={student._id}
            student={student}
            subjectData={subjectData}
          />
        )
      })}
    </tbody>
  )
}

StudentListTable.propTypes = {
  studentData: PropTypes.array.isRequired,
  subjectData: PropTypes.array.isRequired
}
