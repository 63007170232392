// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
//import {Modal} from 'react-bootstrap'
import { connect } from 'react-redux'
import { PageHelmet } from '../common'
import underscore from 'underscore'
// import actions
import {
  startFetchGroupsPerYear,
  startFetchGroupsForTeacherByAcademicYear
} from '../../actions/groups'
import { startFetchTests } from '../../actions/tests.js'
import {
  startFetchMarksForTestSubj,
  startFetchReports
} from '../../actions/marks'
import { startFetchSubjects } from '../../actions/subjects.js'
import { startFetchData } from '../../actions/students.js'
import { saveData } from '../../actions/ui/marksReport'
import { startFetchAcademicYearPromotionalGroups } from '../../actions/groups'
// import selectors
import { getLevel, getLevelId } from '../../reducers/accounts'
import { testsById, isLoading as isTestsLoading } from '../../reducers/tests.js'
import * as Subjects from '../../reducers/subjects.js'
import * as Marks from '../../reducers/marks.js'
import {
  getAcademicGroups,
  isLoading as isGroupsLoading,
  getTeacherGroupsById,
  getAcademicTeacherGroups
} from '../../reducers/groups.js'
import { getUiMarksReportData } from '../../reducers/ui/marksReport'
import { getLoading as isStudentsLoading } from '../../reducers/students'
import StudentStore from '../../reducers/flattenedStudents'
import { getInstitutionData, getAcademicYear } from '../../reducers/institution'
import { getPromotionalAcademicYear } from '../../reducers/promotionGroups'
// import components
import {
  uniqueParentGroups,
  filterSectionsByParent,
  downloadReport
} from '../../helpers/utils.js'
//import {TestReportRender} from './testReportRender'
import { GroupSelector } from './groupSelector.jsx'
import { StudentList } from './renderStudents.jsx'
import SearchBar from '../common/search'
import GroupInfo from './groupInfo'
import Spinner from '../spinner'
import { isUndefined } from 'util'
import {
  renderMaxMarks,
  getMark,
  checkForElective,
  getById,
  getParentSubjectOfAnElective,
  getGroupPerStudentId
} from '../../helpers/utils'
import { ZERO_REASON_OBJ } from '../../helpers/enums'

class MarksReportComponent extends Component {
  static propTypes = {
    jwt: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    marksReady: PropTypes.bool.isRequired,
    groups: PropTypes.object.isRequired,
    academicGroups: PropTypes.array.isRequired,
    tests: PropTypes.object.isRequired,
    subjects: PropTypes.array.isRequired,
    // studentDataWithMarks: PropTypes.array.isRequired,
    //functions
    fetchGroups: PropTypes.func.isRequired,
    fetchTests: PropTypes.func.isRequired,
    fetchSubjectsMarksStudentsAndReport: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      parent_group: '',
      groupId: '',
      testId: '',
      goClicked: false,
      search: '',
      isDirty: false,
      order: { roll_number: false },
      subjectId: '',
      headerId: 'roll_number',
      fetchData: false,
      allTestsForParentGroup: [],
      selectedAcademicYear: this.props.academicYear,
      yearChanged: false
    }
    this.setSection = this.setSection.bind(this)
    this.handleAcademicYearChange = this.handleAcademicYearChange.bind(this)
    this.handleParentChange = this.handleParentChange.bind(this)
    this.handleTestChange = this.handleTestChange.bind(this)
    this.handleGo = this.handleGo.bind(this)
    this.filterTestsByGroup = this.filterTestsByGroup.bind(this)
    this.filterStudents = this.filterStudents.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.active = this.active.bind(this)
    this.handleGroupBy = this.handleGroupBy.bind(this)
    this.getSortIcon = this.getSortIcon.bind(this)
    this.getToolTip = this.getToolTip.bind(this)
    this.downloadReport = this.downloadReport.bind(this)
    this.downloadReportPer = this.downloadReportPer.bind(this)
    this.downloadReportUmbrellaName = this.downloadReportUmbrellaName.bind(this)
  }

  componentDidMount() {
    // fetch groups
    const {
      jwt,
      level,
      fetchAllAcademicyears,
      fetchGroupsPerYear,
      fetchTeacherGroupsByAcademicYear
    } = this.props
    const { selectedAcademicYear } = this.state
    let data = {
      jwt,
      type: 'academics',
      academic_year: selectedAcademicYear
    }
    fetchAllAcademicyears(data)
    if (level !== 'teacher') fetchGroupsPerYear(data)
    else fetchTeacherGroupsByAcademicYear(data)
    this.setState({
      fetchData: true,
      parent_group: '',
      groupId: '',
      testId: ''
    })
  }

  componentDidUpdate() {
    const {
      jwt,
      groupsLoading,
      groups,
      groupSelectorData,
      fetchTests,
      fetchSubjectsMarksStudentsAndReport,
      level,
      fetchGroupsPerYear,
      fetchTeacherGroupsByAcademicYear
    } = this.props
    const { fetchData, selectedAcademicYear, yearChanged } = this.state
    const { academic_year, umbrellaGroup, groupId, testId } = groupSelectorData
    const academicYear = selectedAcademicYear
    if (yearChanged) {
      let data = {
        jwt,
        type: 'academics',
        academic_year: selectedAcademicYear
      }
      if (level !== 'teacher') fetchGroupsPerYear(data)
      else fetchTeacherGroupsByAcademicYear(data)
      this.setState({
        yearChanged: false,
        parent_group: '',
        groupId: '',
        testId: ''
      })
    }
    // if (
    //   !groupsLoading &&
    //   umbrellaGroup &&
    //   groupId &&
    //   groupId !== 'all' &&
    //   testId &&
    //   fetchData
    // ) {
    //   const { subjects, tests, students } = Object.assign(
    //     { subjects: [], tests: [], students: [] },
    //     groups[groupId]
    //   )
    //   const subjectIds = subjects.map(subObj => subObj.subject_id)
    //   const order = {}
    //   subjectIds.forEach(subjectId => (order[subjectId] = false))
    //   order.roll_number = false
    //   order.name = false
    //   order.total = false
    //   order.classPosition = false
    //   fetchTests({ jwt, tests })
    //   fetchSubjectsMarksStudentsAndReport(
    //     jwt,
    //     students,
    //     subjectIds,
    //     testId,
    //     groupId,
    //     academicYear
    //   )

    //   this.setState({
    //     order,
    //     parent_group: umbrellaGroup,
    //     groupId,
    //     testId,
    //     goClicked: true,
    //     isDirty: false,
    //     fetchData: false
    //   })
    // }
  }

  getSubjectData() {
    const { subjectsById, groups, tests } = this.props
    const { groupId, testId, sections } = this.state
    const testSubject = tests[testId].subjects.map(sub => sub.subject)
    let groupSubject = []
    if (groupId !== 'all') {
      groupSubject = groups[groupId].subjects.map(
        subjectObj => subjectObj.subject_id
      )
    } else {
      const subjects = sections.reduce(
        (acc, section) => acc.concat(groups[section.id].subjects),
        []
      )
      groupSubject = subjects.map(subjectObj => subjectObj.subject_id)
    }
    const intersectionList = underscore.intersection(testSubject, groupSubject)
    return intersectionList
      .map(subjectId => {
        let obj = subjectsById[subjectId]
        if (obj.umbrellaName) {
          obj['mainOrder'] = obj.umbrellaOrder
          obj['subjectOrder'] = obj.order
        } else {
          obj['mainOrder'] = obj.order
        }
        return obj
      })
      .filter(subObj => subObj.is_main === true)
      .sort((a, b) => {
        const returnValue = a.mainOrder - b.mainOrder
        if (returnValue === 0) return a.subjectOrder - b.subjectOrder
        return returnValue
      })
  }

  handleGo(e) {
    e.preventDefault()
    const { jwt, groups, fetchSubjectsMarksStudentsAndReport } = this.props
    const {
      parent_group,
      groupId,
      testId,
      sections,
      selectedAcademicYear
    } = this.state
    const academicYear = selectedAcademicYear
    if (groupId !== 'all') {
      const subjectIds = groups[groupId].subjects.map(
        subObj => subObj.subject_id
      )
      const order = {}
      subjectIds.forEach(subjectId => (order[subjectId] = false))
      order.roll_number = false
      order.name = false
      order.total = false
      order.classPosition = false
      fetchSubjectsMarksStudentsAndReport(
        jwt,
        groups[groupId].students,
        subjectIds,
        testId,
        groupId,
        academicYear
      )
      this.setState({
        order,
        goClicked: true,
        isDirty: false,
        fetchData: false,
        search: ''
      })
    } else {
      const subjectIds = sections.reduce(
        (acc, section) =>
          acc.concat(groups[section.id].subjects.map(sub => sub.subject_id)),
        []
      )
      const studentIds = sections.reduce(
        (acc, section) => acc.concat(groups[section.id].students),
        []
      )
      const groupIds = sections.map(section => section.id)
      const uniqueSubjects = [...new Set(subjectIds)]
      const order = {}
      uniqueSubjects.forEach(subjectId => (order[subjectId] = false))
      order.roll_number = false
      order.name = false
      order.total = false
      order.classPosition = false
      fetchSubjectsMarksStudentsAndReport(
        jwt,
        studentIds,
        uniqueSubjects,
        testId,
        groupIds,
        academicYear
      )
      this.setState({
        order,
        goClicked: true,
        isDirty: false,
        fetchData: false,
        search: ''
      })
    }
  }

  active() {
    return this.state.goClicked
  }

  handleAcademicYearChange(e) {
    this.setState({
      selectedAcademicYear: e.target.value,
      goClicked: false,
      isDirty: true,
      yearChanged: true,
      parent_group: '',
      groupId: '',
      testId: ''
    })
  }

  handleParentChange(e) {
    this.setState({
      parent_group: e.target.value,
      groupId: '',
      testId: '',
      goClicked: false,
      isDirty: false,
      yearChanged: false
    })
  }

  handleTestChange(e) {
    this.setState({
      testId: e.target.value,
      goClicked: false,
      isDirty: true,
      yearChanged: false
    })
  }

  setSection(e) {
    const { name, value } = e.target
    this.setState(
      {
        [name]: value,
        testId: '',
        goClicked: false,
        isDirty: false,
        yearChanged: false
      },
      this.fetchTestForGroup(value)
    )
  }
  fetchTestForGroup = groupId => {
    const { jwt, groups, fetchTests, academicGroups } = this.props
    const { parent_group } = this.state
    const sections = filterSectionsByParent(parent_group, academicGroups)
    if (groupId !== 'all') {
      fetchTests({
        jwt,
        tests: groups[groupId].tests
      })
    } else {
      const allTests = []
      sections.forEach(section => allTests.push(...groups[section.id].tests))
      const uniqueTests = new Set(allTests)
      fetchTests({
        jwt,
        tests: [...uniqueTests]
      })
      this.setState({
        allTestsForParentGroup: [...uniqueTests],
        sections
      })
    }
  }
  filterTestsByGroup() {
    const { tests, groups, testsLoading } = this.props
    const { groupId, allTestsForParentGroup } = this.state
    if (testsLoading || Object.keys(tests).length === 0) return []
    let arrTests = []

    if (groupId && tests !== undefined && groupId !== 'all') {
      if (Object.keys(tests).length) arrTests = groups[groupId].tests
    }
    if (groupId && tests !== undefined && groupId === 'all') {
      if (Object.keys(tests).length) {
        arrTests = allTestsForParentGroup
      }
    }
    const notAllGroupTestsPresent = arrTests.some(
      testId => Object.keys(tests).indexOf(testId) === -1
    )
    if (notAllGroupTestsPresent) return []

    return arrTests
      .map(testId => ({
        id: tests[testId]._id,
        name: tests[testId].name,
        order: tests[testId].order
      }))
      .sort((a, b) => a.order - b.order)
  }

  filterStudents() {
    const { studentArr, groups } = this.props
    const { search, headerId, subjectId, order, groupId, sections } = this.state
    if (studentArr.length === 0 || Object.keys(groups).length === 0) return []
    let studentIds = []
    if (groupId !== 'all') {
      studentIds = groups[groupId].students
    } else {
      studentIds = sections.reduce(
        (acc, section) => acc.concat(groups[section.id].students),
        []
      )
    }
    let studentDataWithMarksPerGroup = studentArr.filter(
      obj => studentIds.indexOf(obj._id) !== -1
    )
    let studentData = studentDataWithMarksPerGroup
    if (subjectId !== '') {
      if (order[subjectId]) {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj.marks[subjectId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => b.marks[subjectId].mark - a.marks[subjectId].mark
        )
        studentData = sorted.concat(empty)
      } else {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj.marks[subjectId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => a.marks[subjectId].mark - b.marks[subjectId].mark
        )
        studentData = sorted.concat(empty)
      }
    }

    if (headerId === 'name') {
      if (order[headerId]) {
        studentData = studentDataWithMarksPerGroup.sort((a, b) => {
          if (a.name < b.name) return 1
          return -1
        })
      } else {
        studentData = studentDataWithMarksPerGroup.sort((a, b) => {
          if (a.name < b.name) return -1
          return 1
        })
      }
    }

    if (headerId !== '' && headerId !== 'name') {
      if (order[headerId]) {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj[headerId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => Number(b[headerId]) - Number(a[headerId])
        )
        studentData = sorted.concat(empty)
      } else {
        const empty = []
        const filled = []
        studentDataWithMarksPerGroup.forEach(obj => {
          if (obj[headerId]) filled.push(obj)
          else empty.push(obj)
        })
        const sorted = filled.sort(
          (a, b) => Number(a[headerId]) - Number(b[headerId])
        )
        studentData = sorted.concat(empty)
      }
    }

    if (search.length === 0) return studentData

    const filterAcross = (fields = []) => {
      return student => {
        return fields.some(field => {
          return -1 !== student[field].toLowerCase().indexOf(search)
        })
      }
    }

    return studentData.filter(filterAcross(['roll_number', 'name']))
  }

  setSearch(e) {
    e.preventDefault()
    let obj = {}
    obj[e.target.name] = e.target.value.toLowerCase()
    this.setState(obj)
  }

  handleGroupBy(e) {
    const { subjects } = this.props
    const { order } = this.state
    const len = subjects.filter(subjectObj => subjectObj._id === e.target.id)
      .length
    if (len !== 0) {
      this.setState({
        ...this.state,
        order: {
          ...order,
          [e.target.id]: !order[e.target.id]
        },
        subjectId: e.target.id,
        headerId: ''
      })
    } else {
      this.setState({
        ...this.state,
        order: {
          ...order,
          [e.target.id]: !order[e.target.id]
        },
        headerId: e.target.id,
        subjectId: ''
      })
    }
  }

  getSortIcon(header) {
    const { order } = this.state
    if (order[header]) return 'sort-amount-desc'
    return 'sort-amount-asc'
  }

  getToolTip(header) {
    const { order } = this.state
    if (order[header]) return 'sort ascending'
    return 'sort descending'
  }

  isReady() {
    const {
      groupsLoading,
      studentsLoading,
      subjectsLoading,
      marksLoading,
      testsLoading
    } = this.props
    if (
      groupsLoading ||
      studentsLoading ||
      subjectsLoading ||
      marksLoading ||
      testsLoading
    ) {
      return false
    }
    return true
  }

  downloadReport(e, templateType) {
    const {
      groups,
      subjectsById,
      tests,
      institutionData,
      academicYear
    } = this.props
    e.preventDefault()
    const { parent_group, groupId, testId, selectedAcademicYear } = this.state
    let studentData = this.getTableBody()
    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    const subjectOfTestById = getById(tests[testId].subjects, 'subject')
    const subjectData = this.getSubjectData()
    let academic_year = ''
    let group_name = ''
    let umbrella_group = ''
    if (groupId !== 'all') {
      const groupObj = groups[groupId]
      // academic_year = groupObj.academic_year
      academic_year = selectedAcademicYear
      group_name = groupObj.group_name
      umbrella_group = groupObj.umbrella_group
    } else {
      academic_year = selectedAcademicYear
      group_name = null
      umbrella_group = parent_group
    }

    const maximumMarks = subjectData.map(obj => {
      let returnObj = {}
      returnObj['maxMarks'] = renderMaxMarks(obj, studentData)
      if (obj.electives.length > 0) returnObj['span'] = true
      return returnObj
    })
    const subjectNames = subjectData.map(sub => {
      let obj = {}
      obj['name'] = sub.umbrellaName
        ? `${sub.umbrellaName} ${sub.name}`
        : sub.name
      if (sub.electives.length > 0) obj['span'] = true
      return obj
    })
    const studentdetail = studentData.map(student => {
      let stdobj = {}
      let arr = []
      const subjectIds = subjectData.map(sub => sub._id)
      subjectIds.forEach((sub, i) => {
        let obj = {}
        if (!isUndefined(subjectsById[sub])) {
          if (subjectsById[sub].electives.length > 0) {
            obj['span'] = true
          }
        }
        if (isUndefined(student.marks[sub]) || isUndefined(subjectsById[sub])) {
          obj['marks'] = null
          obj['noMarks'] = true
        } else {
          obj['marks'] = `${getMark(student.marks[sub], subjectsById[sub])} ${
            checkForElective(student.marks[sub])
              ? checkForElective(student.marks[sub])
              : ''
          }`
          obj['marksPrint'] = getMark(student.marks[sub], subjectsById[sub])
          obj['elective'] = `${
            checkForElective(student.marks[sub])
              ? checkForElective(student.marks[sub])
              : ''
          }`

          if (subjectsById[sub].marksGrade === 'marks') {
            const marksOnly = student.marks[sub]['mark']
            // const maxMarks = student.marks[sub]['maxMarks']
            const minMarks = subjectOfTestById[sub]['minMarks']
            // const per = (marksOnly / maxMarks) * 100
            if (student.marks[sub].zeroReason === 'notZero') {
              if (subjectsById[sub].totalCalculation) {
                if (marksOnly < minMarks) obj['fail'] = true
              } else {
                if (marksOnly < minMarks) obj['fail'] = true
              }
            } else {
              obj['zeroReason'] = true
            }
          }
        }
        arr.push(obj)
      })
      stdobj['rollno'] = student.roll_number
      stdobj['name'] = student.name
      stdobj['registration_number'] = student.registration_number
      stdobj['marks'] = arr
      stdobj['total'] = student.total
      stdobj['percentage'] = student.percentage
      stdobj['sectionPosition'] = student.sectionPosition
      stdobj['classPosition'] = student.classPosition
      return stdobj
    })
    const data = {
      title: `${parent_group}-${
        Boolean(groups[groupId]) ? groups[groupId].group_name : ''
      }-${tests[testId].name}`,
      maxMarks: maximumMarks,
      span: 6 + subjectNames.length,
      students: studentdetail.map(data => {
        let stddata = {}
        stddata['rollno'] = data.rollno
        stddata['name'] = data.name
        stddata['registration_number'] = data.registration_number
        stddata['marks'] = data.marks
        stddata['total'] = data.total
        stddata['percentage'] = data.percentage
        stddata['sectionPosition'] = data.sectionPosition
        stddata['classPosition'] = data.classPosition
        return stddata
      }),
      subjects: subjectNames,
      classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-2019`,
      year: `${academic_year}-2019`,
      class: umbrella_group,
      section: group_name
    }
    data['institutionName'] = institutionName
    data['institutionAddress'] = institutionAddress
    data['class'] = umbrella_group
    data['section'] = group_name
    data['testName'] = tests[testId].name
    const academicYearPlushOne = Number(selectedAcademicYear) + 1
    data['academicYear'] = `${selectedAcademicYear} - ${academicYearPlushOne}`
    let template = 'performanceReport'
    if (templateType === 'a3') template = 'performanceReportA3'
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: data,
      downloadedFileName: 'performance-report'
    })
  }

  downloadReportCoScholastic(e, templateType) {
    const {
      groups,
      subjectsById,
      tests,
      institutionData,
      academicYear
    } = this.props
    e.preventDefault()
    const { parent_group, groupId, testId, selectedAcademicYear } = this.state
    let studentData = this.getTableBody()

    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    const subjectData = this.getSubjectData()
    let academic_year = ''
    let group_name = ''
    let umbrella_group = ''
    if (groupId !== 'all') {
      const groupObj = groups[groupId]
      // academic_year = groupObj.academic_year
      academic_year = selectedAcademicYear
      group_name = groupObj.group_name
      umbrella_group = groupObj.umbrella_group
    } else {
      academic_year = selectedAcademicYear
      group_name = null
      umbrella_group = parent_group
    }

    const maximumMarks = subjectData.map(obj => {
      let returnObj = {}
      returnObj['maxMarks'] = renderMaxMarks(obj, studentData)
      if (obj.electives.length > 0) returnObj['span'] = true
      return returnObj
    })
    const subjectNamesAll = subjectData.map(sub => {
      let obj = {}
      obj['marksGrade'] = sub.marksGrade
      obj['name'] = sub.umbrellaName
        ? `${sub.umbrellaName} ${sub.name}`
        : sub.name
      if (sub.electives.length > 0) obj['span'] = true
      return obj
    })

    const subjectNames = subjectNamesAll.filter(sub => {
      if (sub.marksGrade === 'grade') return true
      return false
    })

    const studentdetail = studentData.map(student => {
      let stdobj = {}
      let arr = []
      const subjectIds = subjectData.map(sub => sub._id)
      subjectIds.forEach((sub, i) => {
        if (subjectsById[sub].marksGrade === 'grade') {
          let obj = {}

          if (
            isUndefined(student.marks[sub]) ||
            isUndefined(subjectsById[sub])
          ) {
            obj['marks'] = null
            obj['noMarks'] = true
          } else {
            obj['marks'] = `${getMark(student.marks[sub], subjectsById[sub])} ${
              checkForElective(student.marks[sub])
                ? checkForElective(student.marks[sub])
                : ''
            }`
            obj['marksPrint'] = getMark(student.marks[sub], subjectsById[sub])
            obj['elective'] = `${
              checkForElective(student.marks[sub])
                ? checkForElective(student.marks[sub])
                : ''
            }`
          }
          arr.push(obj)
        }
      })
      stdobj['rollno'] = student.roll_number
      stdobj['name'] = student.name
      stdobj['registration_number'] = student.registration_number
      stdobj['marks'] = arr
      // stdobj['total'] = student.total
      // stdobj['percentage'] = student.percentage
      // stdobj['sectionPosition'] = student.sectionPosition
      // stdobj['classPosition'] = student.classPosition
      return stdobj
    })
    const data = {
      title: `${parent_group}-${
        Boolean(groups[groupId]) ? groups[groupId].group_name : ''
      }-${tests[testId].name}`,
      maxMarks: maximumMarks,
      span: 6 + subjectNames.length,
      students: studentdetail.map(data => {
        let stddata = {}
        stddata['rollno'] = data.rollno
        stddata['name'] = data.name
        stddata['registration_number'] = data.registration_number
        stddata['marks'] = data.marks
        return stddata
      }),
      subjects: subjectNames,
      classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-2019`,
      year: `${academic_year}-2019`,
      class: umbrella_group,
      section: group_name
    }
    data['institutionName'] = institutionName
    data['institutionAddress'] = institutionAddress
    data['class'] = umbrella_group
    data['section'] = group_name
    data['testName'] = tests[testId].name
    const academicYearPlushOne = Number(selectedAcademicYear) + 1
    data['academicYear'] = `${selectedAcademicYear} - ${academicYearPlushOne}`
    let template = 'performanceReportCo'
    if (templateType === 'a3') template = 'performanceReportA3'
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: data,
      downloadedFileName: 'performance-report'
    })
  }

  downloadReportPer(e, templateType) {
    e.preventDefault()
    const {
      groups,
      subjectsById,
      tests,
      institutionData,
      academicYear
    } = this.props
    const { parent_group, groupId, testId, selectedAcademicYear } = this.state
    let studentData = this.getTableBody()

    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    const subjectOfTestById = getById(tests[testId].subjects, 'subject')

    const subjectData = this.getSubjectData()
    let academic_year = ''
    let group_name = ''
    let umbrella_group = ''
    if (groupId !== 'all') {
      const groupObj = groups[groupId]
      // academic_year = groupObj.academic_year
      academic_year = selectedAcademicYear
      group_name = groupObj.group_name
      umbrella_group = groupObj.umbrella_group
    } else {
      academic_year = selectedAcademicYear
      group_name = null
      umbrella_group = parent_group
    }
    const maximumMarks = subjectData.map(obj => {
      let returnObj = {}
      returnObj['maxMarks'] = renderMaxMarks(obj, studentData)
      if (obj.electives.length > 0) returnObj['span'] = true
      return returnObj
    })
    const subjectNames = subjectData.map(sub => {
      let obj = {}
      obj['isMain'] = sub.totalCalculation
      obj['name'] = sub.umbrellaName
        ? `${sub.umbrellaName} ${sub.name}`
        : sub.name
      if (sub.electives.length > 0) obj['span'] = true
      return obj
    })
    const studentdetail = studentData.map(student => {
      let stdobj = {}
      let arr = []
      const subjectIds = subjectData.map(sub => sub._id)

      let marksDataShaped = []
      subjectIds.forEach((sub, i) => {
        let obj = {}
        obj['isMain'] = subjectsById[sub].totalCalculation
        if (!isUndefined(subjectsById[sub])) {
          if (subjectsById[sub].electives.length > 0) {
            obj['span'] = true
          }
        }
        if (isUndefined(student.marks[sub]) || isUndefined(subjectsById[sub])) {
          obj['marks'] = null
          obj['noMarks'] = true
        } else {
          obj['marks'] = `${getMark(student.marks[sub], subjectsById[sub])} ${
            checkForElective(student.marks[sub])
              ? checkForElective(student.marks[sub])
              : ''
          }`
          obj['marksPrint'] = getMark(student.marks[sub], subjectsById[sub])
          obj['elective'] = `${
            checkForElective(student.marks[sub])
              ? checkForElective(student.marks[sub])
              : ''
          }`
          if (subjectsById[sub].marksGrade === 'marks') {
            const marksOnly = student.marks[sub]['mark']
            const maxMarks = student.marks[sub]['maxMarks']
            const minMarks = subjectOfTestById[sub]['minMarks']
            const per = Math.round(((marksOnly / maxMarks) * 100).toFixed(1))
            if (student.marks[sub].zeroReason === 'notZero') {
              obj['marksPrint'] = per
              if (subjectsById[sub].totalCalculation) {
                if (per < minMarks) obj['fail'] = true
                marksDataShaped.push({
                  marks: per,
                  maxMarks: 100,
                  umbrellaName: subjectsById[sub].umbrellaName,
                  isCalculation: true
                })
              } else {
                if (marksOnly < minMarks) obj['fail'] = true
              }
            } else {
              obj['zeroReason'] = true
              if (subjectsById[sub].totalCalculation) {
                if (
                  student.marks[sub].zeroReason === 'absent' ||
                  student.marks[sub].zeroReason === 'medical leave'
                ) {
                  marksDataShaped.push({
                    marks: 0,
                    maxMarks: 100,
                    umbrellaName: subjectsById[sub].umbrellaName,
                    isCalculation: true
                  })
                } else if (student.marks[sub].zeroReason === 'exempted') {
                  marksDataShaped.push({
                    marks: 0,
                    maxMarks: 0,
                    umbrellaName: subjectsById[sub].umbrellaName,
                    isCalculation: true
                  })
                }
              }
            }
          }
        }

        arr.push(obj)
      })

      if (student._id === '5bb1baf060ed7f1faeb67baf') {
        console.log(marksDataShaped, 'marksDataShaped')
      }

      const marksDataShapedById = underscore.groupBy(
        marksDataShaped,
        obj => obj.umbrellaName
      )

      let newMarksData = []
      Object.keys(marksDataShapedById).forEach((key, mainIndex) => {
        if (key !== 'undefined' && marksDataShapedById[key].length > 1) {
          let sumMax = 0
          let sumTotal = 0
          marksDataShapedById[key].forEach((subj, index) => {
            sumTotal += subj['marks']
            sumMax += subj['maxMarks']
            subj['isCalculation'] = false
            newMarksData.push(subj)
          })

          const avgTotal = Math.round(
            sumTotal / marksDataShapedById[key].length
          )
          const avgMax = sumMax / marksDataShapedById[key].length

          let obj = {
            marks: avgTotal,
            maxMarks: avgMax,
            isCalculation: true
          }
          newMarksData.push(obj)
        } else {
          marksDataShapedById[key].forEach((subj, index) => {
            newMarksData.push(subj)
          })
        }
      })

      let totalmarks = 0
      let totalMax = 0
      newMarksData.forEach(obj => {
        if (obj.isCalculation) {
          totalmarks += obj['marks']
          totalMax += obj['maxMarks']
        }
      })

      stdobj['rollno'] = student.roll_number
      stdobj['name'] = student.name
      stdobj['marks'] = arr
      stdobj['total'] = student.total
      stdobj['totalPer'] = totalmarks
      stdobj['percentage'] = ((totalmarks / totalMax) * 100).toFixed(2)
      stdobj['sectionPosition'] = student.sectionPosition
      stdobj['classPosition'] = student.classPosition
      return stdobj
    })
    const data = {
      title: `${parent_group}-${groups[groupId].group_name}-${tests[testId].name}`,
      maxMarks: maximumMarks,
      span: 6 + subjectNames.length,
      students: studentdetail.map(data => {
        let stddata = {}
        stddata['rollno'] = data.rollno
        stddata['name'] = data.name
        stddata['marks'] = data.marks
        stddata['total'] = data.total
        stddata['totalPer'] = data.totalPer
        stddata['percentage'] = data.percentage
        stddata['sectionPosition'] = data.sectionPosition
        stddata['classPosition'] = data.classPosition
        return stddata
      }),
      subjects: subjectNames,
      classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-2019`,
      year: `${academic_year}-2019`,
      class: umbrella_group,
      section: group_name
    }
    data['institutionName'] = institutionName
    data['institutionAddress'] = institutionAddress
    data['institutionAddress'] = institutionAddress
    data['class'] = umbrella_group
    data['section'] = group_name
    data['testName'] = tests[testId].name
    const academicYearPlushOne = Number(selectedAcademicYear) + 1
    data['academicYear'] = `${selectedAcademicYear} - ${academicYearPlushOne}`
    let template = 'performanceReport'
    if (templateType === 'a3') template = 'performanceReportPerA3'
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: data,
      downloadedFileName: 'performance-report'
    })
  }

  downloadReportBestOfPer(e, templateType) {
    e.preventDefault()
    const {
      groups,
      subjectsById,
      tests,
      institutionData,
      academicYear
    } = this.props
    const { parent_group, groupId, testId, selectedAcademicYear } = this.state
    let studentData = this.getTableBody()

    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    const subjectOfTestById = getById(tests[testId].subjects, 'subject')

    const subjectData = this.getSubjectData()
    const { academic_year, group_name, umbrella_group } = groups[groupId]
    const maximumMarks = subjectData.map(obj => {
      let returnObj = {}
      returnObj['maxMarks'] = renderMaxMarks(obj, studentData)
      if (obj.electives.length > 0) returnObj['span'] = true
      return returnObj
    })
    const subjectNames = subjectData.map(sub => {
      let obj = {}
      obj['isMain'] = sub.totalCalculation
      obj['name'] = sub.umbrellaName
        ? `${sub.umbrellaName} ${sub.name}`
        : sub.name
      if (sub.electives.length > 0) obj['span'] = true
      return obj
    })
    const studentdetail = studentData.map(student => {
      let stdobj = {}
      let arr = []
      const subjectIds = subjectData.map(sub => sub._id)

      let marksDataShaped = []
      subjectIds.forEach((sub, i) => {
        let obj = {}
        obj['isMain'] = subjectsById[sub].totalCalculation
        if (!isUndefined(subjectsById[sub])) {
          if (subjectsById[sub].electives.length > 0) {
            obj['span'] = true
          }
        }
        if (isUndefined(student.marks[sub]) || isUndefined(subjectsById[sub])) {
          obj['marks'] = null
          obj['noMarks'] = true
        } else {
          obj['marks'] = `${getMark(student.marks[sub], subjectsById[sub])} ${
            checkForElective(student.marks[sub])
              ? checkForElective(student.marks[sub])
              : ''
          }`
          obj['marksPrint'] = getMark(student.marks[sub], subjectsById[sub])
          obj['elective'] = `${
            checkForElective(student.marks[sub])
              ? checkForElective(student.marks[sub])
              : ''
          }`
          if (subjectsById[sub].marksGrade === 'marks') {
            const marksOnly = student.marks[sub]['mark']
            const maxMarks = student.marks[sub]['maxMarks']
            const minMarks = subjectOfTestById[sub]['minMarks']
            //  const per = Math.round(((marksOnly / maxMarks) * 100).toFixed(1))
            if (student.marks[sub].zeroReason === 'notZero') {
              obj['marksPrint'] = marksOnly
              if (subjectsById[sub].totalCalculation) {
                if (marksOnly < minMarks) obj['fail'] = true
                marksDataShaped.push({
                  marks: marksOnly,
                  maxMarks: maxMarks,
                  compulsory: subjectsById[sub].compulsoryForTotal,
                  fail: marksOnly < minMarks ? true : false
                })
              } else {
                if (marksOnly < minMarks) obj['fail'] = true
              }
            } else {
              obj['zeroReason'] = true
              if (subjectsById[sub].totalCalculation) {
                if (
                  student.marks[sub].zeroReason === 'absent' ||
                  student.marks[sub].zeroReason === 'medical leave' ||
                  student.marks[sub].zeroReason === 'zero'
                ) {
                  marksDataShaped.push({
                    marks: 0,
                    maxMarks: 100,
                    compulsory: subjectsById[sub].compulsoryForTotal
                  })
                } else if (student.marks[sub].zeroReason === 'exempted') {
                  marksDataShaped.push({
                    marks: 0,
                    maxMarks: 0,
                    compulsory: subjectsById[sub].compulsoryForTotal
                  })
                }
              }
            }
          }
        }

        arr.push(obj)
      })

      const bestOf = tests[testId].bestOf
      const onlyNonCompulsory = []
      const onlyCompulsory = []

      marksDataShaped.forEach(obj => {
        if (obj.compulsory) onlyCompulsory.push(obj)
        else onlyNonCompulsory.push(obj)
      })

      const numberOfnonCompulsory = bestOf - onlyCompulsory.length

      onlyNonCompulsory.sort((a, b) => b.marks - a.marks)

      let totalCount = 0
      let totalmarks = 0
      let totalMax = 0
      let failCount = 0
      while (totalCount < numberOfnonCompulsory) {
        totalmarks += onlyNonCompulsory[totalCount]['marks']
        totalMax += onlyNonCompulsory[totalCount]['maxMarks']
        if (onlyNonCompulsory[totalCount]['fail']) {
          failCount++
        }
        totalCount++
      }

      onlyCompulsory.forEach(obj => {
        totalmarks += obj['marks']
        totalMax += obj['maxMarks']
        if (obj['fail']) {
          failCount++
        }
      })

      stdobj['rollno'] = student.roll_number
      stdobj['name'] = student.name
      stdobj['marks'] = arr
      stdobj['total'] = student.total
      stdobj['totalPer'] = totalmarks
      stdobj['percentage'] = ((totalmarks / totalMax) * 100).toFixed(2)
      stdobj['sectionPosition'] = student.sectionPosition
      stdobj['classPosition'] = student.classPosition
      stdobj['failSub'] = failCount
      return stdobj
    })
    const data = {
      title: `${parent_group}-${groups[groupId].group_name}-${tests[testId].name}`,
      maxMarks: maximumMarks,
      span: 6 + subjectNames.length,
      students: studentdetail.map(data => {
        let stddata = {}
        stddata['rollno'] = data.rollno
        stddata['name'] = data.name
        stddata['marks'] = data.marks
        stddata['total'] = data.total
        stddata['totalPer'] = data.totalPer
        stddata['percentage'] = data.percentage
        stddata['sectionPosition'] = data.sectionPosition
        stddata['classPosition'] = data.classPosition
        stddata['failSub'] = data.failSub
        return stddata
      }),
      subjects: subjectNames,
      classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-2019`,
      year: `${academic_year}-2019`,
      class: umbrella_group,
      section: group_name
    }
    data['institutionName'] = institutionName
    data['institutionAddress'] = institutionAddress
    data['class'] = umbrella_group
    data['section'] = group_name
    data['testName'] = tests[testId].name
    const academicYearPlushOne = Number(selectedAcademicYear) + 1
    data['academicYear'] = `${selectedAcademicYear} - ${academicYearPlushOne}`
    let template = 'performanceReport'
    if (templateType === 'a3') template = 'performanceReportPerA3'
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: data,
      downloadedFileName: 'performance-report'
    })
  }

  downloadReportUmbrellaName(e) {
    const { groups, tests, institutionData } = this.props
    const { groupId, testId, selectedAcademicYear, parent_group } = this.state
    const academicYearPlushOne = Number(selectedAcademicYear) + 1

    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    let group_name = groupId === 'all' ? 'All' : groups[groupId].group_name

    const subjectData = this.getSubjectData()
    const studentData = this.getTableBody()

    const studentMarks = []
    studentData.forEach(student => {
      const studentGroup = getGroupPerStudentId(student._id, groups)
      subjectData.forEach(subjectObj => {
        studentMarks.push({
          studentId: student._id,
          studentName: student.name,
          roll_number: student.roll_number,
          student_section: studentGroup.group_name,
          subjectName: subjectObj.name,
          umbrellaCode:
            subjectObj.umbrellaCode !== undefined
              ? subjectObj.umbrellaCode
              : subjectObj.name,
          umbrellaName:
            subjectObj.umbrellaName !== undefined
              ? subjectObj.umbrellaName
              : subjectObj.name,
          marks: (student.marks &&
          subjectObj._id &&
          student.marks[subjectObj._id] &&
          student.marks[subjectObj._id].zeroReason
          ? student.marks[subjectObj._id].zeroReason === 'notZero'
          : false)
            ? getMark(student.marks[subjectObj._id], subjectObj)
            : 0,
          elective:
            checkForElective(student.marks[subjectObj._id]) !== null
              ? checkForElective(student.marks[subjectObj._id])
              : '',
          maxMarks: renderMaxMarks(subjectObj, studentData),
          zeroReason:
            student.marks &&
            subjectObj._id &&
            student.marks[subjectObj._id] &&
            student.marks[subjectObj._id].zeroReason
              ? student.marks[subjectObj._id].zeroReason
              : ''
        })
      })
    })

    const marksByStudentId = underscore.groupBy(studentMarks, 'studentId')
    const tableBody = []
    Object.keys(marksByStudentId).map(student => {
      let subjMarksObj = {}
      const subjectsByUmbrellaName = underscore.groupBy(
        marksByStudentId[student],
        'umbrellaName'
      )
      Object.keys(subjectsByUmbrellaName).map(subjectObj => {
        let totalMarks = 0
        let finalMarks = 0
        subjectsByUmbrellaName[subjectObj].map(subj => {
          totalMarks = totalMarks + subj.marks * (100 / subj.maxMarks)
          subjMarksObj = {
            ...subjMarksObj,
            [subjectObj]: subj.elective
              ? {
                  elective: true,
                  electiveName: subj.elective,
                  zeroReason: subj.zeroReason
                }
              : {
                  elective: false,
                  electiveName: '',
                  zeroReason: subj.zeroReason
                }
          }
        })
        finalMarks = totalMarks / subjectsByUmbrellaName[subjectObj].length
        subjMarksObj[subjectObj].marks =
          Number(Math.round(finalMarks)) === 0 &&
          subjMarksObj[subjectObj].zeroReason !== 'notZero'
            ? ZERO_REASON_OBJ[subjMarksObj[subjectObj].zeroReason]
              ? ZERO_REASON_OBJ[subjMarksObj[subjectObj].zeroReason]
              : ''
            : Number(Math.round(finalMarks))
      })
      subjMarksObj = {
        ...subjMarksObj,
        studentId: marksByStudentId[student][0].studentId,
        name: marksByStudentId[student][0].studentName,
        roll_number: marksByStudentId[student][0].roll_number,
        student_section: marksByStudentId[student][0].student_section
      }
      tableBody.push(subjMarksObj)
    })
    const subjectHeader = underscore.uniq(
      subjectData.map(obj => (obj.umbrellaName ? obj.umbrellaName : obj.name))
    )
    const tableHeader = [
      { key: 'roll_number', name: 'Roll No' },
      {
        key: 'name',
        name: 'Student Name'
      },
      {
        key: 'student_section',
        name: 'Section'
      }
    ]
    subjectHeader.forEach(subj => {
      tableHeader.push({
        key: subj,
        name: subj
      })
    })

    const data = {
      instituionName: institutionName,
      institutionAddress: institutionAddress,
      class: parent_group,
      section: group_name,
      testName: tests[testId].name,
      academicYear: `${selectedAcademicYear} - ${academicYearPlushOne}`,
      tableHeader: tableHeader,
      tableBody: tableBody,
      span: tableHeader.length
    }

    console.log(data, 'data')

    downloadReport({
      nameOfTemplate: 'performanceReportForSATS',
      dataForTemplate: data,
      downloadedFileName: 'performance-report-for-SATS'
    })
  }

  getTableBody = () => {
    const {
      marksPerStudentByTestId,
      subjectsById,
      subjects: mainSubjectArr,
      groups,
      reportbyStudentId
    } = this.props
    const { testId, groupId, sections } = this.state
    let studentData = this.filterStudents()

    return studentData.map(studentObj => {
      const studentMarkWithTest = Object.assign(
        { [testId]: {} },
        marksPerStudentByTestId[studentObj._id]
      )
      const studentMarkObjBySubjectId = studentMarkWithTest[testId]
      // assign a marks attribute for each student
      studentObj['marks'] = {}
      let totalMarks = 0
      let totalMaxMarks = 0
      Object.keys(studentMarkObjBySubjectId).forEach(subjectId => {
        const { is_main, code: subjectCode } = Object.assign(
          { is_main: true },
          subjectsById[subjectId]
        )
        let subjects = []
        if (groupId !== 'all') {
          const groupObj = Object.assign({ subjects: [] }, groups[groupId])
          subjects = groupObj.subjects
        } else {
          subjects = sections.reduce(
            (acc, section) => acc.concat(groups[section.id].subjects),
            []
          )
        }
        let subjectObjMerge = subjects.filter(
          subObj => subObj.subject_id === subjectId
        )
        let allStudents = subjectObjMerge.reduce(
          (acc, sub) => acc.concat(sub.student_id),
          []
        )
        // let subjectObj = subjects.find(
        //   subObj => subObj.subject_id === subjectId
        // )
        // Assign marks only if the student has been assigned to the elective
        if (allStudents.includes(studentObj._id)) {
          let subject =
            is_main === true
              ? subjectId
              : getParentSubjectOfAnElective(mainSubjectArr, subjectId)
          const studentMarkObj = studentMarkObjBySubjectId[subjectId]
          studentObj['marks'][subject] = studentMarkObj
          if (is_main === false) {
            studentObj['marks'][subject]['elective'] = subjectCode
          }
          const { totalCalculation } = Object.assign(
            { totalCalculation: true },
            subjectsById[subject]
          )
          // add total marks and max marks only id totalCalculation is true
          if (studentMarkObj.mark && totalCalculation) {
            totalMarks += studentMarkObj.mark
          }
          if (studentMarkObj.maxMarks && totalCalculation) {
            totalMaxMarks += studentMarkObj.maxMarks
          }
        }
      })
      // derive total marks and percentage and add it as attributes to student data
      studentObj['total'] = totalMarks
      studentObj['totalMaxMarks'] = totalMaxMarks
      studentObj['percentage'] = this.getPercentage(totalMarks, totalMaxMarks)
      // assign class and section position
      const reportObj = reportbyStudentId[studentObj._id]
      const { classPosition, sectionPosition } = Object.assign(
        { classPosition: '', sectionPosition: '' },
        reportObj
      )
      studentObj['classPosition'] = classPosition
      studentObj['sectionPosition'] = sectionPosition
      return studentObj
    })
  }

  getPercentage = (totalMarks, totalMaxMarks) => {
    return Number(Math.round((totalMarks / totalMaxMarks) * 100))
  }

  render() {
    const {
      academicGroups,
      position,
      tests,
      groups,
      allAcademicYears,
      reportbyStudentId,
      groupsLoading,
      groupSelectorData
    } = this.props
    const {
      parent_group,
      groupId,
      testId,
      goClicked,
      isDirty,
      search,
      selectedAcademicYear
    } = this.state
    return (
      <div>
        <div className="attd-container">
          <PageHelmet>Marks Report</PageHelmet>
          <div className="container">
            <div className="row">
              <div className="col-xs-3">
                <div className="tab">
                  <p className="tab__text">Marks Report</p>
                </div>
              </div>
              <div className="col-xs-9">
                <GroupSelector
                  // data
                  groupsLoading={groupsLoading}
                  selectedAcademicYear={selectedAcademicYear}
                  allAcademicYears={allAcademicYears}
                  umbrellaGroup={parent_group}
                  groupId={groupId}
                  testId={testId}
                  parentNames={uniqueParentGroups(academicGroups)}
                  sections={filterSectionsByParent(
                    parent_group,
                    academicGroups
                  ).concat({
                    id: 'all',
                    name: 'All'
                  })}
                  tests={this.filterTestsByGroup()}
                  // callbacks
                  handleAcademicYearChange={this.handleAcademicYearChange}
                  handleParentChange={this.handleParentChange}
                  handleTestChange={this.handleTestChange}
                  setSection={this.setSection}
                  handleGo={this.handleGo}
                  isDirty={isDirty}
                />
              </div>
            </div>
          </div>
          <div className="attd-search">
            <div className="container">
              <div className="row">
                <div className="col-xs-6">
                  {goClicked && (
                    <GroupInfo
                      umbrellaGroup={parent_group}
                      groupName={
                        Boolean(groups[groupId])
                          ? groups[groupId].group_name
                          : ''
                      }
                      test={tests[testId].name}
                    />
                  )}
                </div>
                <div className="col-xs-6">
                  <div className="btn-container pull-right">
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!(goClicked && this.isReady())}
                      onClick={e => this.downloadReportUmbrellaName(e)}
                    >
                      <FontAwesome name="download" />
                      SATS PDF
                    </button>
                    <span className="slash">&nbsp;</span>
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!(goClicked && this.isReady())}
                      onClick={e => this.downloadReport(e, 'a4')}
                    >
                      <FontAwesome name="download" />
                      PDF A4
                    </button>
                    <span className="slash">&nbsp;</span>
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!(goClicked && this.isReady())}
                      onClick={e => this.downloadReportCoScholastic(e, 'a4')}
                    >
                      <FontAwesome name="download" />
                      Co - Scholastic
                    </button>
                    {/* <button
                      className="btn-right btn-right-csv"
                      disabled={!(goClicked && this.isReady())}
                      onClick={e => this.downloadReport(e, 'a3')}
                    >
                      <FontAwesome name="download" />
                      PDF A3
                    </button>
                    <span className="slash">&nbsp;</span>
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!(goClicked && this.isReady())}
                      onClick={e => this.downloadReportPer(e, 'a3')}
                    >
                      <FontAwesome name="download" />
                      PDF Per A4
                    </button>
                    <span className="slash">&nbsp;</span>
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!(goClicked && this.isReady())}
                      onClick={e => this.downloadReportBestOfPer(e, 'a3')}
                    >
                      <FontAwesome name="download" />
                      PDF Best Of
                    </button> */}
                  </div>
                </div>
              </div>
              <SearchBar
                handleChange={this.setSearch}
                active={this.active()}
                text="Please select above fields"
                value={search}
              />
            </div>
          </div>
        </div>
        {goClicked && this.isReady() && (
          <StudentList
            studentData={this.getTableBody()}
            subjectData={this.getSubjectData()}
            position={position}
            handleGroupBy={this.handleGroupBy}
            getSortIcon={this.getSortIcon}
            getToolTip={this.getToolTip}
          />
        )}
        {goClicked && !this.isReady() && <Spinner />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const getGroupsObj = () => {
    if (state.accounts.level === 'teacher') {
      return getTeacherGroupsById(state)
    }
    return state.groups.byId
  }
  const getGroupsArr = () => {
    if (state.accounts.level === 'teacher') {
      return getAcademicTeacherGroups(state)
    }
    return getAcademicGroups(state)
  }
  return {
    marksReady: !Subjects.getIsLoading(state) && !Marks.getIsLoading(state),
    allAcademicYears: getPromotionalAcademicYear(state),
    isLoading: state.groups.isLoading,
    groups: getGroupsObj(),
    jwt: state.accounts.jwt,
    academicGroups: getGroupsArr(),
    tests: testsById(state),
    subjects: Subjects.getMainSubjects(state),
    subjectsById: Subjects.byId(state),
    groupSelectorData: getUiMarksReportData(state),
    groupsLoading: isGroupsLoading(state),
    testsLoading: isTestsLoading(state),
    marksLoading: Marks.getIsLoading(state),
    subjectsLoading: Subjects.getIsLoading(state),
    studentsLoading: isStudentsLoading(state),
    institutionData: getInstitutionData(state),
    academicYear: getAcademicYear(state),
    level: getLevel(state),
    levelId: getLevelId(state),
    marksPerStudentByTestId: Marks.getMarksPerStudentByTestId(state),
    studentArr: StudentStore.getStudentsSortedByRollNumber(state),
    reportbyStudentId: Marks.getReportByStudentId(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAllAcademicyears(data) {
    dispatch(startFetchAcademicYearPromotionalGroups(data))
  },
  fetchGroupsPerYear(data) {
    dispatch(startFetchGroupsPerYear(data))
  },
  fetchTeacherGroupsByAcademicYear(data) {
    dispatch(startFetchGroupsForTeacherByAcademicYear(data))
  },
  fetchTests(data) {
    dispatch(startFetchTests(data))
  },
  fetchSubjectsMarksStudentsAndReport(
    jwt,
    studentArr,
    subjectArr,
    testId,
    groupId,
    academicYear
  ) {
    dispatch(
      startFetchSubjects({
        jwt,
        subjects: subjectArr
      })
    )
    dispatch(
      startFetchMarksForTestSubj({
        jwt,
        testId,
        subjectArr,
        studentArr,
        academicYear
      })
    )
    dispatch(startFetchData({ jwt, students: studentArr }))
    dispatch(
      startFetchReports({
        jwt,
        groupId,
        testIds: [testId],
        type: ['overall'],
        academicYear
      })
    )
  },
  storeGroupSelectorData(data) {
    dispatch(saveData(data))
  }
})

export const MarksReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarksReportComponent)
