// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'

//components
import SingleTestPerformanceForGrades from './singleTestPerformanceGrades'
import SingleTestPerformanceForMarks from './singleTestPerformaceForMarks'

class SingleTestPerformanceGraph extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    isGrades: PropTypes.bool.isRequired
  }
  getBarColor(percentage) {
    if (percentage >= 0 && percentage <= 30) return '#ff3838'
    else if (percentage >= 31 && percentage <= 60) return '#ff9f1a'
    else if (percentage >= 61 && percentage <= 74) return '#c56cf0'
    else if (percentage >= 75 && percentage <= 90) return '#7158e2'
    else return '#3ae374'
  }
  render() {
    const { data, isGrades } = this.props
    if (isGrades) {
      return (
        <SingleTestPerformanceForGrades
          data={data}
          getBarColor={this.getBarColor}
        />
      )
    }
    return (
      <SingleTestPerformanceForMarks
        data={data}
        getBarColor={this.getBarColor}
      />
    )
  }
}

export default SingleTestPerformanceGraph
