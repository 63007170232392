import {
  HOLIDAY_FETCH_START,
  HOLIDAY_FETCH_SUCCESS,
  HOLIDAY_FETCH_FAILURE,
  POST_HOLIDAY_FETCH_START,
  POST_HOLIDAY_FETCH_FAILURE,
  POST_HOLIDAY_FETCH_SUCCESS,
  DELETE_HOLIDAY_FETCH_START,
  DELETE_HOLIDAY_FETCH_SUCCESS,
  DELETE_HOLIDAY_FETCH_FAILURE,
  PATCH_HOLIDAY_FETCH_START,
  PATCH_HOLIDAY_FETCH_SUCCESS,
  PATCH_HOLIDAY_FETCH_FAILURE,
  APP_LOGOUT,
  TEACHER_HOLIDAY_FETCH_START,
  TEACHER_HOLIDAY_FETCH_SUCCESS,
  TEACHER_HOLIDAY_FETCH_FAILURE
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isLoadingPost: false,
  isLoadingDelete: false,
  isLoadingEdit: false,
  holidayPolicy: [],
  isSuccess: false,
  isErr: false,
  errMsg: '',
  msg: ''
}

export const holiday = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case HOLIDAY_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false
      })
    case HOLIDAY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
        holidayPolicy: action.data,
        isErr: false
      })
    case HOLIDAY_FETCH_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        isSuccess: false
      })
    case POST_HOLIDAY_FETCH_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        isErr: false
      })
    case POST_HOLIDAY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isSuccess: true,
        isErr: false
      })
    case POST_HOLIDAY_FETCH_FAILURE:
      return Object.assign({}, state, {
        isLoadingPost: false,
        isErr: true,
        errMsg: action.errMsg,
        isSuccess: false
      })
    case DELETE_HOLIDAY_FETCH_START:
      return Object.assign({}, state, {
        isLoadingDelete: true,
        isErr: false
      })
    case DELETE_HOLIDAY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isSuccess: true,
        isErr: false
      })
    case DELETE_HOLIDAY_FETCH_FAILURE:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isErr: true,
        errMsg: action.errMsg,
        isSuccess: false
      })
    case PATCH_HOLIDAY_FETCH_START:
      return Object.assign({}, state, {
        isLoadingEdit: true,
        isErr: false
      })
    case PATCH_HOLIDAY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoadingEdit: false,
        isSuccess: true,
        isErr: false
      })
    case PATCH_HOLIDAY_FETCH_FAILURE:
      return Object.assign({}, state, {
        isLoadingEdit: false,
        isErr: true,
        errMsg: action.errMsg,
        isSuccess: false
      })
    case TEACHER_HOLIDAY_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false
      })
    case TEACHER_HOLIDAY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
        holidayPolicy: action.data,
        isErr: false
      })
    case TEACHER_HOLIDAY_FETCH_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg,
        isSuccess: false
      })
    default:
      return state
  }
}

export const getHolidayPolicy = state => state.holiday.holidayPolicy
export const isLoadingHolidayPolicy = state => state.holiday.isLoading
export const getIsLoadingPostHolidayPolicy = state =>
  state.holiday.isLoadingPost
export const getisLoadingDeleteHolidayPolicy = state =>
  state.holiday.isLoadingDelete
export const getIsLoadingEditHolidayPolicy = state =>
  state.holiday.isLoadingEdit
export const getHolidayPolicyIsSuccess = state => state.holiday.isSuccess
export const getErrMsg = state => state.holiday.errMsg
export const getIsError = state => state.holiday.isErr
