import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

class ModalComponent extends Component {
  render() {
    const {
      showModal,
      closeModal,
      downloadReport,
      isDisabled,
      getMarksStudentlistReportHousewiseGrades,
      handleNoOfStudentsChange
    } = this.props
    return (
      <Modal show={showModal}>
        <ModalHeader>
          <p className="modal-title">Reports</p>
        </ModalHeader>
        <ModalBody>
          <div className="col-xs-12">
            <div>
              <div className="row row-eq-height">
                <div className="o-horizontal-center">
                  <div className="space-creator" />
                  <div className="add-student-form-outer person-info">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-6">
                          <div id="academicInfoForm">
                            <div className="add-student-form">
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Select the No Of Students
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <input
                                    name="noOfStudentsDownloadReport"
                                    defaultValue={30}
                                    type="number"
                                    placeholder="Select No Of Students"
                                    onChange={handleNoOfStudentsChange}
                                  />
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Generate Report
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={downloadReport}
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Marks Report - I & II
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={
                                      getMarksStudentlistReportHousewiseGrades
                                    }
                                    name="marks-report-I&II"
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Marks Report -IV & V
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={
                                      getMarksStudentlistReportHousewiseGrades
                                    }
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                    name="MarksReport-IV&V"
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Marks Report -VI & Above
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={
                                      getMarksStudentlistReportHousewiseGrades
                                    }
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                    name="MarksReport-V&Above"
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Marks Report - KG
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={
                                      getMarksStudentlistReportHousewiseGrades
                                    }
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                    name="MarksReport-KG"
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Marks Report - III
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={
                                      getMarksStudentlistReportHousewiseGrades
                                    }
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                    name="MarksReport-III"
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                              <div className="info-row">
                                <label
                                  className="info-cell info-cell--left"
                                  htmlFor="name"
                                >
                                  Grade Report
                                </label>
                                <span className="dash" />
                                <div className="info-cell info-row-certificate">
                                  <button
                                    onClick={
                                      getMarksStudentlistReportHousewiseGrades
                                    }
                                    className="button button--blue button--short button--no-mg-right"
                                    disabled={isDisabled}
                                    name="MarksGradeReport"
                                  >
                                    download pdf
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="comment-button-container">
            <button className="button button--white" onClick={closeModal}>
              CLOSE
            </button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalComponent
