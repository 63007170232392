import { createSelector } from 'reselect'
import underscore from 'underscore'

import {
  APP_LOGOUT,
  START_FETCH_QUESTION_BANK,
  QUESTION_BANK_DATA,
  FAIL_FETCH_QUESTION_BANK,
  START_POST_QUESTION_BANK,
  POST_QUESTION_BANK_SUCCESS,
  POST_QUESTION_BANK_FAILED,
  START_FETCH_QUESTION_BANK_BY_ID,
  QUESTION_BANK_DATA_BY_ID,
  FAIL_FETCH_QUESTION_BANK_BY_ID,
  EDIT_QUESTION_BANK_START,
  EDIT_QUESTION_BANK_SUCCESS,
  EDIT_QUESTION_BANK_FAIL,
  START_POST_QUESTION_BANK_CSV,
  POST_QUESTION_BANK_CSV_SUCCESS,
  POST_QUESTION_BANK_CSV_FAILED,
  START_FETCH_QUESTION_BANK_CSV,
  QUESTION_BANK_CSV_DATA,
  FAIL_FETCH_QUESTION_BANK_CSV,
  EDIT_QUESTION_BANK_CSV_START,
  EDIT_QUESTION_BANK_CSV_SUCCESS,
  EDIT_QUESTION_BANK_CSV_FAIL
} from '../helpers/actions'

import { getGroupId } from './ui/studentProfile'

const initialState = {
  isFetchLoading: false,
  isLoading: false,
  message: '',
  byId: {},
  allIds: [],
  questionBanks: [],
  questionBankById: [],
  isPatchLoading: false,
  isPatchErr: false,
  isPostCsv: false,
  isFetchCsv: false,
  isPatchCsv: false,
  csvData: '',
  csvMsg: ''
}

export const questionBank = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_QUESTION_BANK:
      return Object.assign({}, state, {
        isFetchLoading: true
      })
    case QUESTION_BANK_DATA:
      return Object.assign({}, state, {
        isFetchLoading: false,
        byId: action.byId,
        allIds: action.allIds,
        questionBanks: action.data
      })
    case FAIL_FETCH_QUESTION_BANK:
      return Object.assign({}, state, {
        isFetchLoading: false
      })
    case APP_LOGOUT:
      return initialState
    case START_POST_QUESTION_BANK:
      return Object.assign({}, state, {
        isLoading: true,
        message: ''
      })
    case POST_QUESTION_BANK_SUCCESS:
      return Object.assign({}, state, {
        byId: {
          [action.id]: action.data
        },
        allIds: [action.id],
        isLoading: false,
        message: 'success'
      })
    case POST_QUESTION_BANK_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'fail'
      })
    case START_FETCH_QUESTION_BANK_BY_ID:
      return Object.assign({}, state, {
        isFetchLoading: true
      })
    case QUESTION_BANK_DATA_BY_ID:
      return Object.assign({}, state, {
        isFetchLoading: false,
        questionBankById: action.data
      })
    case FAIL_FETCH_QUESTION_BANK_BY_ID:
      return Object.assign({}, state, {
        isFetchLoading: false
      })
    case EDIT_QUESTION_BANK_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        isPatchErr: false
      })
    case EDIT_QUESTION_BANK_SUCCESS:
      return Object.assign({}, state, {
        isPatchLoading: false,
        isPatchErr: false
      })
    case EDIT_QUESTION_BANK_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        isPatchErr: true
      })
    case START_POST_QUESTION_BANK_CSV:
      return Object.assign({}, state, {
        isPostCsv: true,
        csvMsg: ''
      })
    case POST_QUESTION_BANK_CSV_SUCCESS:
      return Object.assign({}, state, {
        isPostCsv: false,
        csvMsg: 'csvPosted'
      })
    case POST_QUESTION_BANK_CSV_FAILED:
      return Object.assign({}, state, {
        isPostCsv: false,
        csvMsg: ''
      })
    case START_FETCH_QUESTION_BANK_CSV:
      return Object.assign({}, state, {
        isFetchCsv: true
      })
    case QUESTION_BANK_CSV_DATA:
      return Object.assign({}, state, {
        isFetchCsv: false,
        csvData: action.data
      })
    case FAIL_FETCH_QUESTION_BANK_CSV:
      return Object.assign({}, state, {
        isFetchCsv: false
      })
    case EDIT_QUESTION_BANK_CSV_START:
      return Object.assign({}, state, {
        isPatchCsv: true,
        isPatchErr: false,
        csvMsg: ''
      })
    case EDIT_QUESTION_BANK_CSV_SUCCESS:
      return Object.assign({}, state, {
        isPatchCsv: false,
        isPatchErr: false,
        csvMsg: 'csvPatched'
      })
    case EDIT_QUESTION_BANK_CSV_FAIL:
      return Object.assign({}, state, {
        isPatchCsv: false,
        isPatchErr: true,
        csvMsg: ''
      })
    default:
      return state
  }
}

// selectors
export const getById = state => state.questionBank.byId
export const getAllIds = state => state.questionBank.allIds
export const isQuestionBankLoading = state => state.questionBank.isLoading
export const getSuccessMsg = state => state.questionBank.message
export const allQuestionBanks = state => state.questionBank.questionBanks
export const getFetchQuestionBankLoading = state =>
  state.questionBank.isFetchLoading
export const getQuestionBankById = state => state.questionBank.questionBankById
export const getPatchQuestionBankLoading = state =>
  state.questionBank.isPatchLoading
export const getPatchError = state => state.questionBank.isPatchErr
export const getPostCSVLoading = state => state.questionBank.isPostCsv
export const getFetchCSVLoading = state => state.questionBank.isFetchCsv
export const getPatchCSVLoading = state => state.questionBank.isPatchCsv
export const getCSVData = state => state.questionBank.csvData
export const getCSVMsg = state => state.questionBank.csvMsg

export const getSubjectsForActiveStudentGroup = createSelector(
  getById,
  getAllIds,
  getGroupId,
  (byId, allIds, activeStudentGroup) => {
    return underscore
      .uniq(
        allIds
          .filter(
            id =>
              byId?.[id]?.['studentGroup'].indexOf(activeStudentGroup) !== -1
          )
          .map(id => byId?.[id]?.['subject'])
      )
      .sort()
  }
)

export const getTestsForActiveStudentGroup = createSelector(
  getById,
  getAllIds,
  getGroupId,
  (byId, allIds, activeStudentGroup) => {
    return underscore
      .uniq(
        allIds
          .filter(
            id =>
              byId?.[id]?.['studentGroup'].indexOf(activeStudentGroup) !== -1
          )
          .map(id => byId?.[id]?.['test'])
      )
      .sort()
  }
)

export const getAcademicYearsForActiveStudentGroup = createSelector(
  getById,
  getAllIds,
  getGroupId,
  (byId, allIds, activeStudentGroup) => {
    return underscore
      .uniq(
        allIds
          .filter(
            id =>
              byId?.[id]?.['studentGroup'].indexOf(activeStudentGroup) !== -1
          )
          .map(id => byId?.[id]?.['academicYear'])
      )
      .sort()
  }
)

export const getQuestionBankForActiveStudentGroup = createSelector(
  getById,
  getAllIds,
  getGroupId,
  (byId, allIds, activeStudentGroup) => {
    return allIds
      .filter(
        id => byId?.[id]?.['studentGroup'].indexOf(activeStudentGroup) !== -1
      )
      .map(id => byId?.[id])
  }
)
