import { union } from 'lodash'
import {
  APP_LOGOUT,
  FLATTENED_STUDENTS_DATA,
  FLATTENED_STUDENTS_GET_STARTED,
  SET_SEARCH_FILTER_FOR_STUDENT_LIST,
  SET_SEARCH_FILTER_SUBJECTS_FOR_STUDENT_LIST,
  RESET_STUDENT_STORE,
  UPDATE_ARCHIVED_STUDENT,
  UPDATE_UNARCHIVED_STUDENT,
  STUDENT_RESET_PASSWORD_START,
  STUDENT_RESET_PASSWORD_SUCCESS,
  STUDENT_RESET_PASSWORD_FAIL,
  UPDATE_PATCH_PICTURE_DATA,
  SET_ARCHIVE_FILTER_FOR_STUDENT_LIST,
  ADD_STUDENT_CSV_FAIL
} from '../helpers/actions.js'

import { STUDENT_DETAILS_DEFAULT_SELECTED } from '../helpers/enums'

import { getActiveStudent } from './ui/studentProfile'
import { getLevelId, getLevel } from './accounts'

import { createSelector } from 'reselect'

const initialState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isWorking: false,
  searchFilter: STUDENT_DETAILS_DEFAULT_SELECTED,
  searchFilterSubjects: {},
  archiveType: 'unarchived'
}

export const flattenedStudents = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case FLATTENED_STUDENTS_GET_STARTED:
      return Object.assign({}, state, {
        isLoading: true
      })
    case FLATTENED_STUDENTS_DATA:
      return Object.assign({}, state, {
        byId: action.entities,
        allIds: action.result,
        isLoading: false
      })
    case SET_SEARCH_FILTER_FOR_STUDENT_LIST:
      return Object.assign({}, state, {
        searchFilter: action.data
      })
    case SET_SEARCH_FILTER_SUBJECTS_FOR_STUDENT_LIST:
      return Object.assign({}, state, {
        searchFilterSubjects: action.data
      })
    case UPDATE_ARCHIVED_STUDENT:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.studentId]: {
            ...state.byId[action.studentId],
            archived: true
          }
        }
      })
    case UPDATE_PATCH_PICTURE_DATA:
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.data['_id']]: action.data
        }),
        allIds: union([], state.allIds, [action.data['_id']])
      })
    case UPDATE_UNARCHIVED_STUDENT:
      return Object.assign({}, state, {
        byId: {
          ...state.byId,
          [action.studentId]: {
            ...state.byId[action.studentId],
            archived: false
          }
        }
      })
    case STUDENT_RESET_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case STUDENT_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case STUDENT_RESET_PASSWORD_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      })
    case SET_ARCHIVE_FILTER_FOR_STUDENT_LIST:
      return Object.assign({}, state, {
        archiveType: action.data
      })
    case ADD_STUDENT_CSV_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        byId: {},
        allIds: []
      })
    case RESET_STUDENT_STORE:
      return initialState
    default:
      return state
  }
}

const getSearchFilter = state => state.flattenedStudents.searchFilter

const getSearchFilterSubjects = state =>
  state.flattenedStudents.searchFilterSubjects

const isLoading = state => state.flattenedStudents.isLoading
const isWorking = state => state.flattenedStudents.isWorking
const getById = state => state.flattenedStudents.byId
const getAllIds = state => state.flattenedStudents.allIds
const getArchiveStudents = state => state.flattenedStudents.archiveType

const getStudents = createSelector(getById, getAllIds, (byId, allIds) => {
  if (allIds.length === 0) return []
  return allIds?.filter(stu => stu !== undefined)?.length > 0
    ? allIds.map(studentId => byId[studentId])
    : []
})

const getStudentsSortedByRollNumber = createSelector(
  getStudents,
  studentArr => {
    if (studentArr.length === 0) return []
    const rollNumberArr = studentArr?.map(studentObj => {
      if (studentObj?.roll_number) return studentObj?.roll_number
      return ''
    })
    const rollNumberSet = new Set(rollNumberArr)
    if (rollNumberArr.length === rollNumberSet.size) {
      return sortByRollNumber(studentArr)
    } else {
      return sortByName(studentArr)
    }
  }
)

const getStudentsSortedByName = createSelector(getStudents, studentsArr => {
  if (studentsArr.length === 0) return []
  const studentList = sortByName(studentsArr)
  return (
    studentList &&
    studentList?.length > 0 &&
    studentList?.map((studentObj, index) => {
      studentObj.serial_number = index + 1
      return studentObj
    })
  )
})

const sortByRollNumber = studentArr => {
  return studentArr.sort(
    (a, b) => Number(a.roll_number) - Number(b.roll_number)
  )
}

const sortByName = studentArr => {
  return studentArr.sort(compareNames)
}

const compareNames = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  return 1
}

const getActiveStudentData = createSelector(
  getById,
  getLevelId,
  isLoading,
  (studentsById, studentId, studentsLoading) => {
    if (
      Object.keys(studentsById).length === 0 ||
      studentId === '' ||
      studentsLoading
    )
      return {}
    if (!studentsById[studentId]) return {}
    return studentsById[studentId]
  }
)

export const getActiveFlattenStudentData = createSelector(
  getById,
  getActiveStudent,
  (studentById, id) => {
    return Object.assign(
      {},
      {
        _id: '',
        father_number: 0,
        father_email: '',
        mother_number: 0,
        mother_emagetStudentsSortedByNameil: ''
      },
      studentById[id]
    )
  }
)

export const getStudentDataByStudentId = (state, studentId) => {
  return Object.assign(
    {},
    {
      _id: '',
      father_number: 0,
      father_emailgetStudentsSortedByName: '',
      mother_number: 0,
      mother_email: ''
    },
    state.flattenedStudents.byId[studentId]
  )
}

//Add selectors to StudentStore to export them
const StudentStore = {
  getSearchFilter,
  isWorking,
  isLoading,
  getById,
  getAllIds,
  getStudents,
  getStudentsSortedByRollNumber,
  getStudentsSortedByName,
  getActiveStudentData,
  getStudentDataByStudentId,
  getSearchFilterSubjects,
  getArchiveStudents
}

export default StudentStore
