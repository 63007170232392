// import packages
import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import { connect } from 'react-redux'
import { PageHelmet } from '../../common'

// import actions
import { startFetchGroups } from '../../../actions/groups'
import { startFetchData } from '../../../actions/students'
import { startFetchSubjects } from '../../../actions/subjects'
import { startFetchTests } from '../../../actions/tests'
import { startFetchMarksForTestSubj } from '../../../actions/marks'

// import selectors
import {
  getAcademicGroups,
  isLoading as isGroupsLoading,
  groupsById
} from '../../../reducers/groups.js'
import { getLevel, getLevelId } from '../../../reducers/accounts'
import { getLoading } from '../../../reducers/students'
import StudentStore from '../../../reducers/flattenedStudents'
import {
  getInstitutionData,
  getAcademicYear
} from '../../../reducers/institution'
import { getIsLoading, orderedSubjectArray } from '../../../reducers/subjects'
import {
  isLoading as getIsLoadingTests,
  unMergedTestsById
} from '../../../reducers/tests'
import {
  getIsLoading as getisLoadingMarks,
  getMarksPerStudentByTestId
} from '../../../reducers/marks'

// components
import { GroupSelector } from './groupSelector.jsx'
import Spinner from '../../spinner'
import StudentTable from './studentTable'
import {
  uniqueParentGroups,
  filterSectionsByParent,
  downloadReport
} from '../../../helpers/utils'

class SubjectWiseMarksReportComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      parent_group: '',
      groupId: '',
      subjectId: '',
      showTable: false
    }
  }

  componentDidMount() {
    const { jwt, fetchGroups } = this.props
    fetchGroups({ type: 'academics', jwt })
  }

  componentDidUpdate(prevProps) {
    const { isLoadingMarks } = this.props
    const { isLoadingMarks: isLoadingMarksPrev } = prevProps
    if (!isLoadingMarks && isLoadingMarks !== isLoadingMarksPrev) {
      this.setState({
        showTable: true
      })
    }
  }

  downloadReport = e => {
    const { institutionData, groupsById, orderedSubjectArray } = this.props
    const { parent_group, groupId, subjectId } = this.state
    const {
      name: institutionName,
      address: institutionAddress
    } = Object.assign({ name: '', address: '' }, institutionData)
    let template = 'performanceCummculativeAvgSubject'
    let data = {
      institutionName: institutionName,
      institutionAddress: institutionAddress,
      className: parent_group,
      sectionName: groupsById[groupId].group_name,
      subjectName: orderedSubjectArray.filter(sub => sub._id === subjectId)[0]
        ? orderedSubjectArray.filter(sub => sub._id === subjectId)[0].name
        : '',
      tableHeader: this.getTableHeader(),
      tableBody: this.getTableBody()
    }
    downloadReport({
      nameOfTemplate: template,
      dataForTemplate: data,
      downloadedFileName: 'performanceCummculativeAvgSubject-report'
    })
  }

  filterSubjectsByGroup = () => {
    const { orderedSubjectArray } = this.props
    const finalArray = []
    orderedSubjectArray.forEach(sub => {
      if (sub.is_main && sub.electives && sub.electives.length) {
        orderedSubjectArray
          .filter(item => sub.electives.includes(item._id))
          .forEach(item => {
            finalArray.push({
              id: item._id,
              name: sub.name + ' - ' + item.name
            })
          })
      } else if (sub.is_main && sub.electives.length === 0) {
        finalArray.push({
          id: sub._id,
          name: sub.name
        })
      }
    })
    return finalArray
  }

  handleParentChange = e => {
    this.setState({
      parent_group: e.target.value,
      groupId: '',
      subjectId: '',
      showTable: false
    })
  }

  handleSubjectChange = e => {
    this.setState({
      subjectId: e.target.value,
      showTable: false
    })
  }

  fetchSubjectsForGroup = groupId => {
    const { groupsById, fetchSubjects, jwt } = this.props
    if (groupsById[groupId] && groupsById[groupId].subjects) {
      const selectedGroupSubjectIds = groupsById[groupId].subjects.map(
        sub => sub.subject_id
      )
      fetchSubjects({ jwt, subjects: selectedGroupSubjectIds })
    }
  }

  fetchStudentsForGroup = groupId => {
    const { groupsById, startFetchStudents, jwt } = this.props
    if (groupsById[groupId] && groupsById[groupId].students) {
      startFetchStudents({ jwt, students: groupsById[groupId].students })
    }
  }

  fetchTestsForGroup = groupId => {
    const { groupsById, fetchTests, jwt } = this.props
    if (groupsById[groupId] && groupsById[groupId].tests) {
      fetchTests({ jwt, tests: groupsById[groupId].tests })
    }
  }

  setSection = e => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      subjectId: '',
      showTable: false
    })
    this.fetchSubjectsForGroup(value)
    this.fetchStudentsForGroup(value)
    this.fetchTestsForGroup(value)
  }

  getTableHeader = () => {
    const { testsById } = this.props
    const finalHeader = ['Roll No', 'Student Name']
    Object.keys(testsById).forEach(testId => {
      finalHeader.push(testsById[testId].name)
    })
    return finalHeader.concat(['AGG'])
  }

  getTableBody = () => {
    const { studentData, marksPerStudentByTestId, testsById } = this.props
    const finalData = []
    studentData.forEach(student => {
      const rollNo = student.roll_number
      const studentName = student.name
      let temp = {}
      let allTestMaxMarksSum = 0
      let allTestMarksSum = 0
      if (marksPerStudentByTestId[student._id]) {
        Object.keys(marksPerStudentByTestId[student._id]).forEach(testId => {
          let sum = 0
          if (marksPerStudentByTestId[student._id][testId]) {
            Object.keys(marksPerStudentByTestId[student._id][testId]).forEach(
              key => {
                if (
                  marksPerStudentByTestId[student._id][testId][key].mark >= 0 &&
                  marksPerStudentByTestId[student._id][testId][key]
                    .zeroReason === 'notZero'
                ) {
                  allTestMaxMarksSum =
                    allTestMaxMarksSum +
                    marksPerStudentByTestId[student._id][testId][key].maxMarks
                  allTestMarksSum =
                    allTestMarksSum +
                    marksPerStudentByTestId[student._id][testId][key].mark
                }
                sum =
                  sum + marksPerStudentByTestId[student._id][testId][key].mark
              }
            )
          }
          temp = Object.assign({}, temp, {
            [testsById[testId].name]: sum
          })
        })
      }
      finalData.push(
        Object.assign({}, temp, {
          'Roll No': rollNo,
          'Student Name': studentName,
          AGG:
            (allTestMarksSum / allTestMaxMarksSum) * 100
              ? ((allTestMarksSum / allTestMaxMarksSum) * 100).toFixed(0)
              : ''
        })
      )
    })
    return finalData.sort((a, b) => a['Roll No'] - b['Roll No'])
  }

  onGo = e => {
    e.preventDefault()
    const { groupsById, jwt, fetchMarksForTestSubj } = this.props
    const { groupId, subjectId } = this.state
    if (
      groupsById[groupId] &&
      groupsById[groupId].students &&
      groupsById[groupId].tests
    ) {
      const selectedGroupTests = groupsById[groupId].tests
      const selectedGroupStudents = groupsById[groupId].students
      fetchMarksForTestSubj({
        jwt,
        testId: selectedGroupTests,
        subjectArr: [subjectId],
        studentArr: selectedGroupStudents
      })
    }
  }

  render() {
    const {
      academicGroups,
      groupsLoading,
      isLoadingSubjects,
      isLoadingStudents,
      isLoadingTests
    } = this.props
    const { parent_group, groupId, subjectId, showTable } = this.state
    return groupsLoading ? (
      <Spinner />
    ) : (
      <div>
        <div className="attd-container">
          <PageHelmet>Marks Report</PageHelmet>
          <div className="container">
            <div className="row">
              <div className="col-xs-3">
                <div className="tab">
                  <p className="tab__text">Marks Report</p>
                </div>
              </div>
              <div className="col-xs-9">
                <GroupSelector
                  // data
                  umbrellaGroup={parent_group}
                  groupId={groupId}
                  subject={subjectId}
                  parentNames={uniqueParentGroups(academicGroups)}
                  sections={filterSectionsByParent(
                    parent_group,
                    academicGroups
                  )}
                  isLoading={
                    isLoadingSubjects || isLoadingStudents || isLoadingTests
                  }
                  subjects={
                    groupId && parent_group ? this.filterSubjectsByGroup() : []
                  }
                  // callbacks
                  handleParentChange={this.handleParentChange}
                  handleSubjectChange={this.handleSubjectChange}
                  setSection={this.setSection}
                  handleGo={this.onGo}
                  isDirty={parent_group && groupId && subjectId}
                />
              </div>
            </div>
          </div>
          <div className="attd-search">
            <div className="container">
              <div className="row">
                <div className="col-xs-6">
                  <div className="btn-container pull-right">
                    <button
                      className="btn-right btn-right-csv"
                      disabled={!showTable}
                      onClick={e => this.downloadReport(e, 'a4')}
                    >
                      <FontAwesome name="download" />
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTable && parent_group && groupId && subjectId ? (
            <div className="container">
              <StudentTable
                studentData={this.getTableBody()}
                tableHeaders={this.getTableHeader()}
              />
            </div>
          ) : null}
        </div>
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    jwt: state.accounts.jwt,
    groupsById: groupsById(state),
    academicGroups: getAcademicGroups(state),
    groupsLoading: isGroupsLoading(state),
    institutionData: getInstitutionData(state),
    academicYear: getAcademicYear(state),
    level: getLevel(state),
    levelId: getLevelId(state),
    isLoadingSubjects: getIsLoading(state),
    isLoadingMarks: getisLoadingMarks(state),
    marksPerStudentByTestId: getMarksPerStudentByTestId(state),
    isLoadingStudents: getLoading(state),
    studentData: StudentStore.getStudentsSortedByRollNumber(state),
    isLoadingTests: getIsLoadingTests(state),
    testsById: unMergedTestsById(state),
    orderedSubjectArray: orderedSubjectArray(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups(data) {
    dispatch(startFetchGroups(data))
  },
  fetchSubjects(data) {
    dispatch(startFetchSubjects(data))
  },
  fetchMarksForTestSubj(data) {
    dispatch(startFetchMarksForTestSubj(data))
  },
  startFetchStudents(data) {
    dispatch(startFetchData(data))
  },
  fetchTests(data) {
    dispatch(startFetchTests(data))
  }
})

export const SubjectWiseMarksReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectWiseMarksReportComponent)
