import {
  APP_LOGOUT,
  MARKS_FETCH_START,
  MARKS_FETCH_FAIL,
  MARKS_FETCH_DATA,
  MARKS_POST_SUCCESS,
  MARKS_POST_FAIL,
  MARKS_POST_IN_PROCESS,
  MARKS_POST_BULK_SUCCESS,
  MARKS_POST_BULK_FAIL,
  MARKS_POST_BULK_START,
  UPDATE_EXISTING_MARKS_FOR_FORM,
  MARKS_REPORT_DATA,
  RESET_MARKS_STATUS_OBJECT,
  CLASS_MARKS_FETCH_DATA,
  GENERATE_RANK_START,
  GENERATE_RANK_SUCCESS,
  GENERATE_RANK_FAIL,
  MARKS_CALCULATION_START,
  MARKS_CALCULATION_SUCCESS,
  MARKS_CALCULATION_FAIL
} from '../helpers/actions'
import { createSelector } from 'reselect'

const initialState = {
  isLoading: false,
  byId: {},
  allIds: [],
  allStudents: [],
  allTests: [],
  allSubjects: [],
  isErr: false,
  errMsg: '',
  existingMarksForForm: {},
  marksStatus: {},
  subjectWiseReport: [],
  overallReport: {},
  isLoadingPostBulk: false,
  isRankLoading: false,
  isMarksCalculationLoading: false
}

export const marks = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case 'UPDATE_SELECTED_TEST_SUBJECT_STUDENTS':
      const selectedSubj = action.selectedSubj
      const groupSelected = action.groupSelected
      const selectedTests = action.selectedTests
      const studentsOfTests = action.studentsOfTests
      return {
        ...state,
        groupSelected,
        studentsOfTests,
        selectedSubj,
        selectedTests
      }
    case MARKS_POST_IN_PROCESS:
      // think immutable
      const marksStatus = Object.assign({}, state.marksStatus, action.statusObj)
      return { ...state, marksStatus }
    // return Object.assign({}, state, state.marksStatus, action.statusObj)

    case MARKS_POST_SUCCESS:
      const marksSuccessStatus = Object.assign(
        {},
        state.marksStatus,
        action.statusObj
      )
      return Object.assign({}, state, { marksStatus: marksSuccessStatus })

    case MARKS_POST_FAIL:
      return Object.assign({}, state, state.marksStatus, action.statusObj)

    case MARKS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
        isErr: false,
        errMsg: ''
      })
    case MARKS_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case MARKS_FETCH_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.byId,
        allIds: action.allIds,
        allStudents: action.allStudents,
        allTests: action.allTests,
        allSubjects: action.allSubjects
      })
    case MARKS_POST_BULK_START:
      return Object.assign({}, state, {
        isLoadingPostBulk: true,
        isErr: false,
        errMsg: ''
      })
    case MARKS_POST_BULK_FAIL:
      return Object.assign({}, state, {
        isLoadingPostBulk: false,
        isErr: true,
        errMsg: action.errMsg
      })
    case MARKS_POST_BULK_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPostBulk: false,
        isErr: false,
        errMsg: ''
      })
    case CLASS_MARKS_FETCH_DATA:
      return Object.assign({}, state, {
        isLoading: false,
        byId: action.byId,
        allIds: action.allIds,
        allStudents: action.allStudents,
        allTests: action.allTests,
        allSubjects: action.allSubjects
      })
    case RESET_MARKS_STATUS_OBJECT:
      // const marksStatus = Object.assign({}, action.failureStatus)
      return Object.assign({}, state, { marksStatus: action.failureStatus })
    case UPDATE_EXISTING_MARKS_FOR_FORM:
      return Object.assign({}, state, {
        isLoading: false,
        existingMarksForForm: action.existingMarksForForm
      })
    case MARKS_REPORT_DATA:
      return Object.assign({}, state, {
        subjectWiseReport: action.subjectWiseReport,
        overallReport: action.overallReport
      })
    case GENERATE_RANK_START:
      return Object.assign({}, state, {
        isRankLoading: true
      })
    case GENERATE_RANK_SUCCESS:
      return Object.assign({}, state, {
        isRankLoading: false
      })
    case GENERATE_RANK_FAIL:
      return Object.assign({}, state, {
        isRankLoading: false
      })
    case MARKS_CALCULATION_START:
      return Object.assign({}, state, {
        isMarksCalculationLoading: true
      })
    case MARKS_CALCULATION_SUCCESS:
      return Object.assign({}, state, {
        isMarksCalculationLoading: false
      })
    case MARKS_CALCULATION_FAIL:
      return Object.assign({}, state, {
        isMarksCalculationLoading: false
      })
    default:
      return state
  }
}

export const marksById = state => state.marks.byId
export const marksAllIds = state => state.marks.allIds
export const getIsLoadingPostBulk = state => state.marks.isLoadingPostBulk
export const getIsErr = state => state.marks.isErr
export const getMarksArr = createSelector(
  marksById,
  marksAllIds,
  (byId, allIds) =>
    allIds.map(id => {
      return byId[id]
    })
)

export const getIsLoading = state => state.marks.isLoading
export const getRankLoading = state => state.marks.isRankLoading
export const getMarksStatus = state => state.marks.marksStatus
export const getMarksCalculationLoading = state =>
  state.marks.isMarksCalculationLoading

export const getMarksAsArray = createSelector(
  marksById,
  marksAllIds,
  (byId, marks) => {
    if (marks.length === 0) return []
    return marks.map(marksId => {
      const {
        _id,
        subjectId,
        testId,
        maxMarks,
        studentId,
        mark,
        zeroReason,
        grade
      } = byId[marksId]
      return {
        _id,
        subjectId,
        testId,
        maxMarks,
        studentId,
        mark,
        zeroReason,
        grade
      }
    })
  }
)

export const getMarksPerStudentByTestId = createSelector(
  getMarksAsArray,
  marksArr => {
    if (marksArr.length === 0) return {}
    let obj = {}
    marksArr.forEach(markObj => {
      const { studentId, testId, subjectId } = markObj
      if (Object.keys(obj).includes(studentId)) {
        if (Object.keys(obj[studentId]).includes(testId)) {
          obj[studentId][testId][subjectId] = markObj
        } else {
          obj[studentId] = {
            ...obj[studentId],
            [testId]: {
              [subjectId]: markObj
            }
          }
        }
      } else {
        obj[studentId] = {
          [testId]: {
            [subjectId]: markObj
          }
        }
      }
    })
    return obj
  }
)

export const marksCountForDashboard = createSelector(getMarksArr, marksArr => {
  if (!marksArr || marksArr.length === 0) return {}
  const marks = {}
  marksArr.forEach(markObj => {
    if (!marks[markObj.subjectId]) {
      marks[markObj.subjectId] = 1
    } else {
      marks[markObj.subjectId]++
    }
  })
  return marks
})

export const subjectWiseReport = state => state.marks.subjectWiseReport

export const overallReport = state => state.marks.overallReport

// get mark per student by subtestId (2 level marks report)
export const getMarkPerStudentBySubtest = createSelector(
  getMarksAsArray,
  markArr => {
    if (markArr.length === 0) return {}
    const obj = {}
    markArr.forEach(markObj => {
      const { studentId, testId } = markObj
      if (!Object.keys(obj).includes(studentId)) {
        obj[studentId] = {
          [testId]: markObj
        }
      } else {
        obj[studentId][testId] = markObj
      }
    })
    return obj
  }
)

// get mark per student by subjectId (3 level marks report)
export const getMarksPerStudentBySubjectIdWithSubtests = createSelector(
  getMarksAsArray,
  markArr => {
    if (markArr.length === 0) return {}
    const obj = {}
    markArr.forEach(markObj => {
      const { studentId, subjectId, testId } = markObj
      if (!Object.keys(obj).includes(studentId)) {
        obj[studentId] = {
          [subjectId]: {
            [testId]: markObj
          }
        }
      } else {
        if (!Object.keys(obj[studentId]).includes(subjectId)) {
          obj[studentId][subjectId] = {
            [testId]: markObj
          }
        } else {
          obj[studentId] = {
            ...obj[studentId],
            [subjectId]: {
              ...obj[studentId][subjectId],
              [testId]: markObj
            }
          }
        }
      }
    })
    return obj
  }
)

// get class and section position by student Id
export const getReportByStudentId = createSelector(overallReport, reportObj => {
  let studentsReport = []
  reportObj &&
    reportObj.length > 0 &&
    reportObj.forEach(report => {
      report?.studentReport &&
        report?.studentReport?.length > 0 &&
        report?.studentReport.forEach(stu => studentsReport.push(stu))
    })
  // const { studentReport } = Object.assign({ studentReport: [] }, reportObj)
  console.log('studentReport: ', studentsReport)
  if (studentsReport.length === 0) return {}
  const obj = {}
  studentsReport.forEach(positionObj => {
    const { classPos, sectionPos, studentId } = positionObj
    obj[studentId] = {
      classPosition: classPos,
      sectionPosition: sectionPos
    }
  })
  return obj
})

export const getMarksByTestId = createSelector(getMarksAsArray, marksArr => {
  if (marksArr.length === 0) return {}
  const obj = {}
  marksArr.forEach(markObj => {
    const { subjectId, testId } = markObj
    if (!Object.keys(obj).includes(testId)) {
      obj[testId] = {
        [subjectId]: markObj
      }
    } else {
      obj[testId] = {
        ...obj[testId],
        [subjectId]: markObj
      }
    }
  })
  return obj
})
