export const upperCaseLetters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

export const registerCertyTemplateObj = {
  studentRegisterCertificates: 'Default',
  studentRegisterCertificates_IXBCBS: 'BCBS - Class IX',
  studentRegisterCertificates_IXBCGS: 'BCGS - Class IX',
  studentRegisterCertificates_XIBCBS: 'BCBS - Class XI',
  studentRegisterCertificates_XIBCGS: 'BCGS - Class XI'
}

export const registerCertyTemplateObjBCBS = {
  studentRegisterCertificates_IXBCBS: 'BCBS - Class IX',
  studentRegisterCertificates_XIBCBS: 'BCBS - Class XI'
}

export const GROUPS_GROUP_TYPE = [
  'academics',
  'sports',
  'house',
  'club',
  'admission',
  'transport',
  'alumni'
]

export const GROUP_TYPE_OBJ = {
  academics: 'Academics',
  alumni: 'Alumni',
  sport: 'Sports',
  house: 'House',
  admission: 'Admission',
  transport: 'Transport',
  club: 'Club'
}

export const ATTENDANCE_TYPE = [
  {
    id: 'daily',
    name: 'Daily'
  },
  {
    id: 'hourly',
    name: 'Hourly'
  },
  {
    id: 'both',
    name: 'Both'
  }
]

export const GROUPS_GROUP_TYPE_FOR_SMS = [
  {
    id: 'academics',
    name: 'Academics'
  },
  {
    id: 'alumni',
    name: 'Alumni'
  },
  {
    id: 'sports',
    name: 'Sports'
  },
  {
    id: 'club',
    name: 'Club'
  },
  {
    id: 'house',
    name: 'House'
  },
  {
    id: 'admission',
    name: 'Admission'
  },
  {
    id: 'transport',
    name: 'Transport'
  }
]
export const GROUPS_SMS_GROUP_ACTIONS = {
  academics: ['groups', 'departments'],
  sports: ['groups'],
  club: ['groups'],
  house: ['groups'],
  admission: ['groups'],
  transport: ['groups'],
  alumni: ['groups']
}

export const SUBJECT_TYPE = ['marks', 'grade']

export const ZERO_REASON = {
  ab: 'absent',
  ne: 'not eligible',
  ch: 'cheated',
  ex: 'exempted',
  ml: 'medical leave',
  AB: 'absent',
  NE: 'not eligible',
  CH: 'cheated',
  EX: 'exempted',
  NS: 'not submitted',
  ns: 'not submitted',
  ML: 'medical leave'
}

export const ZERO_REASON_OBJ = {
  absent: 'AB',
  'not eligible': 'NE',
  cheated: 'CH',
  exempted: 'EX',
  'not submitted': 'NS',
  'medical leave': 'ML'
}

export const ATTENDANCE_VALS = {
  STATE: {
    PRESENT: 'Present',
    ABSENT: 'Absent',
    DISABLED: 'Joined Late'
  },
  VALS: {
    ABSENT: 0,
    PRESENT: 1,
    DISABLED: 2
  }
}

export const HALF_DAY_ATTENDANCE_VALS = {
  STATE: {
    PRESENT: 'Present',
    ABSENT: 'Absent',
    DISABLED: 'Joined Late'
  },
  VALS: {
    ABSENT: 0,
    PRESENT: 0.5,
    DISABLED: 2
  }
}

export const EMPLOYEE_ATTENDANCE_VALS = {
  STATE: {
    PRESENT: 'Present',
    ABSENT: 'Absent',
    LATE: 'Late Login'
  },
  VALS: {
    ABSENT: 0,
    PRESENT: 1,
    LATE: 2
  }
}

export const HALF_DAY_ATTENDANCE_SESSION_VALS = {
  fullDay: 'Full Day',
  morning: 'Morning',
  afternoon: 'Afternoon',
  none: 'None'
}

export const HALF_DAY_ATTENDANCE_SESSION_INVERSE_VALS = {
  morning: 'afternoon',
  afternoon: 'morning'
}

export const MONTHS_TYPES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const MONTHS_TYPES_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
export const ACADEMIC_YEARS_ARR = ['2019', '2020']
export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const ACADEMIC_YEAR_START_MONTH = 5

export const TIME_PERIODS = ['am', 'pm']

export const ORIENTATION = [
  {
    id: 'p',
    name: 'Portrait'
  },
  {
    id: 'l',
    name: 'Landscape'
  }
]

export const BLOOD_GROUP = [
  'O negative',
  'O positive',
  'A negative',
  'A1 positive',
  'A positive',
  'B negative',
  'B positive',
  'AB negative',
  'AB positive'
]

export const COURSE_CATEGORY = ['Aided', 'Unaided']

export const BSGC_REPORT_TYPE = [
  {
    id: 'test',
    name: 'Test'
  },
  {
    id: 'normal',
    name: 'Normal'
  },
  {
    id: 'finalClassVX',
    name: 'Final - Class V-X'
  },
  {
    id: 'finalClassXI',
    name: 'Final - Class XI'
  }
]
/* 
{
  'id':'test',
  'name':'Test'
}, 
{
  'id':'normal',
  'name':'Normal'
}, 
{
  'id':'finalClassVX',
  'name':'Final - Class V-X'
},
{
  'id':'finalClassXI',
  'name':'Final - Class XI'
}
*/

export const NEWGROUP_FORM_NAME = 'NEWGROUP_FORM'
export const NEWLIBRARY_CATALOG_FORM_NAME = 'NEWLIBRARY_CATALOG_FORM'
export const NEWLIBRARY_LEND_FORM = 'NEWLIBRARY_LEND_FORM'
export const NEWTEST_FORM_NAME = 'NEWTEST_FORM'
export const EDIT_TEST_FORM_NAME = 'EDIT_TEST_FORM'
export const NEWSUBJECT_FORM_NAME = 'NEWSUB_FORM'
export const ADDSTUDENT_FORM_NAME = 'ADDSTUDENT_FORM'
export const EDITSTUDENT_FORM_NAME = 'EDITSTUDENT_FORM'
export const NEWHOUR_FORM_NAME = 'NEWHOUR_FORM'
export const EDITSUBJECT_FORM_NAME = 'EDITSUBJECT_FORM'
export const ADDELECTIVES_FORM_NAME = 'ADDELECTIVES_FORM'
export const NEWSUBTEST_FORM_NAME = 'NEWSUBTEST_FORM'
export const ADDTEACHER_FORM_NAME = 'ADDTEACHER_FORM_NAME'
export const CHANGE_PASSWORD_FORM_NAME = 'CHANGE_PASSWORD_FORM'
export const BULK_ATTENDANCE_FORM_NAME = 'BULK_ATTENDANCE_FORM'
export const CORRECTION_ATTENDANCE_FORM_NAME = 'CORRECTION_ATTENDANCE_FORM'
export const REPORT_CARD_FORM_NAME = 'REPORT_CARD_FORM'
export const TC_FORM_NAME = 'TC_FORM_NAME'
export const ID_CARD_NAME = 'ID_CARD_NAME'
export const CONDUCT_CERTIFICATE_NAME = 'CONDUCT_CERTIFICATE_NAME'
export const TIMETABLE_FORM = 'TIMETABLE_FORM'
export const ADD_STUDENT_PROMOTIONAL_YEAR = 'ADD_STUDENT_PROMOTIONAL_YEAR'
export const HOMEWORK_FORM = 'HOMEWORK_FORM'

// export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const WORKING_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

/*TODO:
- split these into three separate enums,
- remove the placeholder option from the list,
- refactor the forms that are using these enums
*/
export const STUDENT_INFO = {
  GENDER: ['male', 'female'],
  PARENT_TYPE: [
    {
      value: 'Select relation',
      disable: true
    },
    {
      value: 'father',
      disable: false
    },
    {
      value: 'mother',
      disable: false
    },
    {
      value: 'guardian',
      disable: false
    }
  ],
  ADDRESS_TYPE: [
    {
      value: 'Select address type',
      disable: true
    },
    {
      value: 'office',
      disable: false
    },
    {
      value: 'permanent',
      disable: false
    },
    {
      value: 'temporary',
      disable: false
    }
  ]
}

// use these props to give the normal Styling and override a property to change Styling
export const STYLE_PROPS_FOR_GROUP_SELECTOR = {
  showLeftIcon: true,
  showRightIcon: true,
  labelClass: 'selector__field',
  fieldClass: 'selector__field__input',
  leftIconClass: 'selector__field__icon--gray-bg selector__field__icon--small',
  rightIconClass:
    'selector__field__icon--white-bg selector__field__icon--small ',
  iconSize: 'icon--small',
  leftIconName: 'list-ol',
  rightIconName: 'caret-down'
}

export const STYLE_PROPS_FOR_GROUP_SELECTOR_NO_LEFT_ICON = {
  showLeftIcon: false,
  showRightIcon: true,
  labelClass: 'selector__field',
  fieldClass: 'selector__field__input',
  leftIconClass: 'selector__field__icon--gray-bg selector__field__icon--small',
  rightIconClass:
    'selector__field__icon--white-bg selector__field__icon--small ',
  iconSize: 'icon--small',
  leftIconName: 'list-ol',
  rightIconName: 'caret-down'
}

// map student detail headers to its values
export const STUDENT_DETAIL_HEADERS = {
  student_personal: 'STUDENT PERSONAL',
  mother_details: 'MOTHER DETAILS',
  father_details: 'FATHER DETAILS',
  guardian_details: 'GUARDIAN DETAILS',
  // current_class_details: 'CURRENT CLASS DETAILS',
  previous_school_details: 'PREVIOUS SCHOOL DETAILS',
  joining_details: 'JOINING DETAILS',
  subjects: 'SUBJECTS'
}

// structure needed to map keys from the backend to the front end
export const STUDENT_DETAILS = {
  student_personal: {
    serial_number: 'Sl. No.',
    name: 'Student Name',
    date_of_birth: 'DOB',
    gender: 'Gender',
    roll_number: 'Roll No',
    admission_number: 'Admission No.',
    registration_number: 'Registration No.',
    account_number: 'Account No./Fee ID',
    blood_group: 'Blood Group',
    addresses_permanent: 'Permanent Address',
    addresses_temporary: 'Temporary Address',
    addresses_office: 'Office Address',
    sts_number: 'STS Number',
    house: 'House',
    mother_tongue: 'Mother Tongue',
    caste: 'Caste',
    sub_caste: 'Sub Caste',
    reservation: 'Reservation',
    religion: 'Religion',
    nationality: 'Nationality',
    place_of_birth: 'Birth Place',
    scripture_class: 'Scripture Class',
    hostel: 'Hostel',
    physically_handicapped: 'Physically Handicapped',
    nri: 'NRI',
    height: 'Height',
    heightEnd: 'Height End',
    weight: 'Weight',
    weightEnd: 'Weight End',
    remarks: 'Remarks',
    aadhar: 'Aadhar',
    passport_number: 'Passport Number',
    reasonOfArchive: 'Archived Reason',
    admission_year: 'Admission Year',
    csiRefNumber: 'CSI Ref No.',
    studentEmail: `Student's Email`
  },
  mother_details: {
    mother_name: "Mother's Name",
    mother_occupation: "Mother's Occupation",
    mother_education_level: "Mother's Education",
    mother_income: "Mother's Income",
    mother_email: "Mother's Email",
    mother_living: 'Mother Living',
    mother_number: "Mother's Contact",
    mother_sms: 'SMS to Mother'
  },
  father_details: {
    father_name: "Father's Name",
    father_occupation: "Father's Occupation",
    father_education_level: "Father's Education",
    father_income: "Father's Income",
    father_email: "Father's Email",
    father_living: 'Father Living',
    father_number: "Father's Contact",
    father_sms: 'SMS to Father'
  },
  guardian_details: {
    guardian_name: "Guardian's Name",
    guardian_occupation: "Guardian's Occupation",
    guardian_education_level: "Guardian's Education",
    guardian_income: "Guardian's Income",
    guardian_email: "Guardian's Email",
    guardian_living: 'Guardian Living',
    guardian_number: "Guardian's Contact",
    guardian_sms: 'SMS to Guardian'
  },
  // current_class_details: {
  //   current_class_name: 'Class Name',
  //   current_class_section: 'Section Name'
  // },
  previous_school_details: {
    previous_school_name: 'Previous School name',
    previous_school_board_university: 'Pervious School Board',
    previous_school_tc: 'TC',
    previous_school_medium: 'Medium',
    previous_school_max_marks: 'Max Marks',
    previous_school_obtained_marks: 'Obtained Marks',
    previous_school_passing_year: 'Passing Year',
    previous_school_school_exam_passed: 'School Exam Passed'
  },
  joining_details: {
    joining_class: 'Class',
    joining_date: 'Joining Date'
  },
  subjects: {},
  paidFees: 'Total Paid Fees (Rs.)',
  dueFees: 'Total Due Fees (Rs.)'
}

export const STUDENT_DETAILS_DEFAULT_SELECTED = {
  serial_number: false,
  roll_number: true,
  name: true,
  admission_number: true,
  date_of_birth: true,
  account_number: true,
  father_name: true,
  father_number: true,
  mother_name: true,
  mother_number: true,
  guardian_name: false,
  gender: false,
  mother_occupation: false,
  father_occupation: false,
  guardian_occupation: false,
  addresses_permanent: false,
  blood_group: false,
  sts_number: false,
  house: false,
  mother_tongue: false,
  caste: false,
  sub_caste: false,
  reservation: true,
  religion: false,
  nationality: false,
  place_of_birth: false,
  scripture_class: false,
  hostel: false,
  physically_handicapped: false,
  nri: false,
  mother_education_level: false,
  mother_income: false,
  mother_email: false,
  mother_living: false,
  mother_sms: false,
  father_education_level: false,
  father_income: false,
  father_email: false,
  father_living: false,
  father_sms: false,
  guardian_education_level: false,
  guardian_income: false,
  guardian_email: false,
  guardian_living: false,
  guardian_number: false,
  guardian_sms: false,
  current_class_name: false,
  current_class_section: false,
  previous_school_tc: false,
  previous_school_medium: false,
  previous_school_max_marks: false,
  previous_school_obtained_marks: false,
  previous_school_passing_year: false,
  previous_school_school_exam_passed: false,
  joining_class: false,
  joining_date: false,
  admission_year: true,
  csiRefNumber: false,
  studentEmail: false
}

// teacher list
export const TEACHER_DETAILS = {
  name: 'Name',
  phone_primary: 'Primary Contact',
  phone_secondary: 'Secondary Contact',
  email: 'Email Id',
  // picture: 'Profile Picture',
  joining_designation: 'Designation',
  joining_date: 'Joining Date',
  department: 'Department',
  gender: 'Gender',
  date_of_birth: 'Date Of Birth',
  employee_code: 'Employee Code',
  previous_employment: 'Previous Employment',
  panNumber: 'Pan Number',
  aadharNumber: 'Aadhar Number',
  spouseName: 'Spouse Name',
  maritalStatus: 'Marital Status',
  bloodGroup: 'Blood Group',
  fatherName: 'Father Name',
  addresses_office: 'Office Address',
  addresses_permanent: 'Permanent Address',
  addresses_temporary: 'Temporary Address',
  teacher_user_id: 'User ID',
  employement_status: 'Employement Status',
  archive: 'Archive Reason',
  lic: 'LIC Details'
}

export const TEACHER_DETAILS_DEFAULT_SELECTED = {
  name: true,
  phone_primary: true,
  phone_secondary: false,
  email: true,
  // picture: false,
  joining_designation: true,
  department: true,
  gender: true,
  joining_date: false,
  date_of_birth: false,
  employee_code: false,
  previous_employment: false,
  panNumber: false,
  aadharNumber: false,
  spouseName: false,
  maritalStatus: false,
  bloodGroup: false,
  fatherName: false,
  addresses_office: false,
  addresses_permanent: false,
  addresses_temporary: false,
  employement_status: false,
  lic: false
}

export const APPLICATION_STATUS_OBJECT = {
  new: {
    buttonName: 'ADD NEW',
    currentName: 'NEW',
    completedName: 'NEW',
    userlevels: ['admissionOfficer'],
    nextStatus: ['admitted'],
    actions: [
      {
        name: 'fillapplication',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'applicationFormFee',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'fees',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'admitted',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'onhold',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'closed',
        userLevels: ['admissionOfficer']
      }
    ],
    pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  },
  applicationpurchased: {
    buttonName: 'ADD NEW',
    currentName: 'NEW - APPLICATION FORM FEE PAID',
    completedName: 'NEW - APPLICATION FORM FEE PAID',
    userlevels: ['admissionOfficer'],
    nextStatus: ['admitted'],
    actions: [
      {
        name: 'fillapplication',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'fees',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'admitted',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'onhold',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'closed',
        userLevels: ['admissionOfficer']
      }
    ],
    pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  },
  onhold: {
    buttonName: 'ON HOLD',
    currentName: 'ONHOLD',
    completedName: 'ONHOLD',
    userlevels: ['admissionOfficer'],
    nextStatus: ['admitted'],
    actions: [
      {
        name: 'fillapplication',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'admitted',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'closed',
        userLevels: ['admissionOfficer']
      }
    ],
    pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  },

  // chalangenerated: {
  //   buttonName: 'GENERATE APPLICATION FEE RECEIPT',
  //   currentName: 'GENERATE APPLICATION FEE RECEIPT',
  //   completedName: 'APPLICATION CHALAN GENERATED',
  //   userlevels: ['admissionOfficer'],
  //   nextStatus: ['applicationpurchased'],
  //   actions: [
  //     {
  //       name: 'printreceipt',
  //       userLevels: ['admissionOfficer']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  // },
  // applicationpurchased: {
  //   buttonName: 'ASSIGN APPLICATION NUMBER',
  //   currentName: 'PURCHASE APPLICATION',
  //   completedName: 'APPLICATION PURCHASED',
  //   userlevels: ['admissionOfficer'],
  //   nextStatus: ['inprocess'],
  //   actions: [
  //     {
  //       name: 'fillapplication',
  //       userLevels: ['admissionOfficer']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  // },
  // inprocess: {
  //   buttonName: 'MARK APPLICATION COMPLETE',
  //   currentName: 'APPLICATION INCOMPLETE',
  //   completedName: 'INFO UPDATED',
  //   userlevels: ['admissionOfficer'],
  //   nextStatus: ['approved'],
  //   actions: [
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  // },
  // approved: {
  //   buttonName: 'APPROVE APPLICATION',
  //   currentName: 'PENDING WITH ADMISSION OFFICER',
  //   completedName: 'APPROVED BY ADMISSION OFFICER',
  //   userlevels: ['admissionOfficer'],
  //   nextStatus: ['appointmentprocess', 'pendingPrincipalApproval'],
  //   actions: [
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  // },
  // appointmentprocess: {
  //   buttonName: 'SCHEDULE APPOINTMENT',
  //   currentName: '',
  //   completedName: 'APPOINTMENT SCHEDULED',
  //   userlevels: ['admissionOfficer'],
  //   nextStatus: ['principalApproved'],
  //   actions: [
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  // },
  // pendingPrincipalApproval: {
  //   buttonName: 'ASSIGN TO PRINCIPAL',
  //   currentName: '',
  //   completedName: 'PENDING WITH PRINCIPAL',
  //   userlevels: ['admissionOfficer'],
  //   nextStatus: ['principalApproved'],
  //   actions: [
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  // },
  // principalApproved: {
  //   buttonName: 'APPROVE',
  //   currentName: 'PENDING PRINCIPAL APPROVAL',
  //   completedName: 'PRINCIPAL APPROVED',
  //   userlevels: ['principal'],
  //   nextStatus: ['feereview'],
  //   actions: [
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH PRINCIPAL'
  // },
  // feereview: {
  //   buttonName: 'ASSIGN FEE',
  //   currentName: 'FEE REVIEW',
  //   completedName: 'FEE REVIEWED',
  //   userlevels: ['director'],
  //   nextStatus: ['feepending'],
  //   actions: [
  //     {
  //       name: 'editfee',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     },
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH DIRECTOR'
  // },
  // feepending: {
  //   buttonName: 'FEE REVIEWED',
  //   currentName: 'FEE PENDING',
  //   completedName: 'FEE PENDING',
  //   userlevels: ['director'],
  //   nextStatus: ['feepaid'],
  //   actions: [
  //     {
  //       name: 'feereceipt',
  //       userLevels: ['feeAdmin']
  //     },
  //     {
  //       name: 'editfee',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     },
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH DIRECTOR'
  // },
  // feepaid: {
  //   buttonName: 'MARK FEE PAID',
  //   currentName: 'FEE PENDING',
  //   completedName: 'FEE PAID',
  //   userlevels: ['feeAdmin'],
  //   nextStatus: ['admitted'],
  //   actions: [
  //     {
  //       name: 'editfee',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     },
  //     {
  //       name: 'uploaddocuments',
  //       userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
  //     }
  //   ],
  //   pendingStatement: 'PENDING WITH FEE ADMIN'
  // },
  admitted: {
    buttonName: 'APPROVE ADMISSION',
    currentName: 'FEE PAID',
    completedName: 'ADMISSION APPROVED',
    userlevels: ['admissionOfficer'],
    nextStatus: [],
    actions: [
      {
        name: 'assigntoclass',
        userLevels: ['admissionOfficer']
      },

      {
        name: 'fillapplication',
        userLevels: ['admissionOfficer']
      },
      {
        name: 'fees',
        userLevels: ['admissionOfficer']
      }

      // {
      //   name: 'editfee',
      //   userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
      // },
      // {
      //   name: 'uploaddocuments',
      //   userLevels: ['admissionOfficer', 'principal', 'director', 'feeAdmin']
      // }
    ],
    pendingStatement: 'PENDING WITH ADMISSION OFFICER'
  },
  closed: {
    buttonName: 'CLOSED',
    currentName: 'CLOSED',
    completedName: 'CLOSED',
    userlevels: [],
    nextStatus: [],
    actions: null,
    pendingStatement: ''
  }
}

export const APPLICATION_STATUS_ARRAY = [
  'closed',
  'new',
  'onhold',
  // 'chalangenerated',
  // 'applicationpurchased',
  // 'inprocess',
  // 'approved',
  // 'appointmentprocess',
  // 'pendingPrincipalApproval',
  // 'principalApproved',
  // 'feereview',
  // 'feepending',
  // 'feepaid',
  'admitted'
]

export const APPLICATION_STATUS = {
  new: 'NEW',
  closed: 'CLOSED',
  onhold: 'ONHOLD',
  // chalangenerated: 'GENERATE APPLICATION FEE RECEIPT',
  // applicationpurchased: 'PURCHASE APPLICATION',
  // inprocess: 'FILL APPLICATION',
  // approved: 'APPROVE APPLICATION',
  // appointmentprocess: 'SCHEDULE APPOINTMENT',
  // pendingPrincipalApproval: 'PENDING WITH PRINCIPAL',
  // principalApproved: 'Approve',
  // feereview: 'ASSIGN FEE',
  // feepending: 'FEE PENDING',
  // feepaid: 'APPROVE FEE PAID',
  admitted: 'APPROVE ADMISSION'
}

export const PRESENT_APPLICATION_STATUS = {
  new: 'NEW',
  closed: 'CLOSED',
  onhold: 'ONHOLD',
  // chalangenerated: 'CHALAN GENERATED',
  // applicationpurchased: 'APPLICATION PURCHASED',
  // inprocess: 'INFO UPDATED',
  // approved: 'APPLICATION APPROVED',
  // appointmentprocess: 'APPOINTMENT SCHEDULED',
  // pendingPrincipalApproval: 'PENDING WITH PRINCIPAL',
  // principalApproved: 'PRINCIPAL APPROVED',
  // feereview: 'FEE REVIEW',
  // feepending: 'FEE PENDING',
  // feepaid: 'FEE PAID',
  admitted: 'ADMISSION APPROVED',
  applicationpurchased: 'NEW - APPLICATION FORM FEE PAID'
}

export const APPLICATION_STATUS_CLASSNAMES = {
  applicationpurchased: 'status__applicationpurchased',
  applicationpurchased: 'status__new',
  closed: 'status__closed',
  onhold: 'status__closed',
  rejected: 'status__closed',
  new: 'status__new',
  pending: 'status__new',
  chalangenerated: 'status__chalangenerated',
  inprocess: 'status__inprocess',
  approved: 'status__approved',
  appointmentprocess: 'status__appointmentprocess',
  feereview: 'status__feereview',
  feepending: 'status__feepending',
  feepaid: 'status__feepaid',
  admitted: 'status__admitted',
  pendingPrincipalApproval: 'status__pendingprincipal',
  principalApproved: 'status__principalApproved',
  LOST: 'status__closed',
  RETURNED: 'status__admitted',
  LENT: 'status__new',
  PENDING: 'status__new',
  COLLECTED: 'status__admitted',
  WAVIED: 'status__closed'
}

export const EMAIL_DELIVERY = {
  Bounced: 'status__new',
  Complained: 'status__new',
  Delivered: 'status__approved',
  Invalid: 'status__closed',
  NA: 'status__new'
}

export const SURVEY_STATUS = {
  DRAFTED: 'status__new',
  LIVE: 'status__approved',
  CANCELED: 'status__closed',
  CLOSED: 'status__closed'
}

export const SURVEY_STATUS_OBJ = {
  DRAFTED: 'DRAFTED',
  LIVE: 'LIVE',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED'
}
export const CHANGE_SURVEY_STATUS_OBJ = [
  {
    id: 'DRAFTED',
    name: 'DRAFTED',
    disable: true
  },
  {
    id: 'LIVE',
    name: 'LIVE',
    disable: false
  },
  {
    id: 'CANCELED',
    name: 'CANCELED',
    disable: false
  },
  {
    id: 'CLOSED',
    name: 'CLOSED',
    disable: false
  }
]

export const STUDENT_DOCUMENT_STATUS = [
  {
    id: 'pending',
    name: 'Pending'
  },
  {
    id: 'approved',
    name: 'Approved'
  },
  {
    id: 'rejected',
    name: 'Rejected'
  }
]

export const PROPOSAL_STATUS_ARRAY = [
  'DRAFTED',
  'PROPOSED',
  // 'REJECTED',
  // 'HOLD',
  // 'DROPPED',
  'APPROVED'
]

export const PROPOSAL_STATUS = {
  DRAFTED: 'DRAFTED',
  PROPOSED: 'PROPOSED',
  REJECTED: 'REJECTED',
  HOLD: 'HOLD',
  DROPPED: 'DROPPED',
  APPROVED: 'APPROVED'
}

export const APPOINTMNT_SLOTS = {
  10: '10:00 am',
  11: '11:00 am',
  12: '12:00 pm',
  13: '01:00 pm',
  14: '02:00 pm'
}

export const FINETYPE = [
  {
    id: 'perDay',
    name: 'Per Day'
  },
  {
    id: 'fixed',
    name: 'Fixed Amount'
  }
]

export const FINE_TYPE_OBJ = {
  perDay: 'Per Day',
  fixed: 'FIxed Amount'
}

export const FEETYPES = {
  tuition: 'Tuition Fees',
  stationary: 'Stationary',
  uniform: 'Uniform',
  transport: 'Transportation fees',
  admission: 'Admission Fees',
  coCurricularActivities: 'Co-Curricular Activities',
  sports: 'Sports Fees',
  maintanence: 'Maintenance',
  hostel: 'Hostel',
  program: 'School Program',
  crafts: 'AV / Crafts',
  parking: 'Parking Fees',
  idCard: 'ID Card',
  yearBook: 'Year Book',
  shoe: 'Shoe Fees',
  lab: 'Lab Fees',
  infrastructure: 'Infrastructure Development',
  education: 'Education Development',
  outreach: 'OutReach Program',
  computerScience: 'Computer Science Fees',
  medical: 'Medical Fees',
  robotics: 'Robotics / IT Fees',
  software: 'Software Fees',
  deposit: 'Caution Deposit',
  library: 'Library Fees',
  food: 'Food Fees',
  mess: 'Mess Fees',
  building: 'Building Funds',
  miscellaneous: 'Miscellaneous',
  others: 'Others',
  annualDay: 'Annual Day',
  defamation: 'Defamation Fees',
  exam: 'Exam Fees',
  term: 'Term Fees',
  term1: 'Term I Fees',
  term2: 'Term II Fees',
  term3: 'Term III Fees',
  term4: 'Term IV Fees',
  gsFund: 'GS Fund',
  registration: 'Registration Fees',
  arrear: 'Arrear Fees',
  ApplicationFees: 'Application Fees',
  TransferCertificate: 'Transfer Certificate',
  TextBooks: 'Text Books',
  NoteBooks: 'Note Books',
  Tie: 'Tie',
  Belt: 'Belt',
  socks: 'Socks',
  EducationalTrip: 'Educational Trip',
  ChangeOverFees: 'Change Over Fees',
  PathfinderFees: 'Pathfinder Fees',
  ChoirFees: 'Choir Fees',
  OtherFees: 'Other Fees',
  graduation: 'Graduation Fees',
  lab1: 'Term I Lab Fees',
  lab2: 'Term II Lab Fees',
  lab3: 'Term III Lab Fees',
  tuitionOne: 'Tuition Fees I',
  tuitionTwo: 'Tuition Fees II',
  tuitionThree: 'Tuition Fees III',
  tuitionFour: 'Tuition Fees IV',
  annual: 'Annual Fees',
  changeOver: 'Change Over',
  SslcSupplementaryFees: 'SSLC Supplementary Fees',
  Migration: 'Migration',
  SslcExamFees: 'SSLC Exam Fees',
  IcseExamFees: 'ICSE Exam Fees',
  IcseRegistration: 'ICSE Registration',
  PathfinderCamp: 'Pathfinder Camp',
  StaffRetreat: 'Staff Retreat',
  ChoirUniform: 'Choir Uniform ',
  Diary: 'Diary',
  PucSupplementaryfees: 'PUC Supplementary Fees',
  Fine: 'Fine',
  ScienceClub: 'Science Club',
  IcseSupplementaryFees: 'ICSE Supplementary Fees',
  PucExamFees: 'Puc Exam Fees',
  SslcRegistration: 'SSLC Registration',
  other: 'Other Fees',
  government: 'Government Fees',
  coachingClass: 'Coaching Class',
  nbrb: 'NBRB',
  overallFees: 'Overall Fees',
  academicYearFees: 'Fees for the complete Academic Year',
  fees: 'Fees',
  MinorityScholarship: 'Minority Scholarship',
  PrivateStudentsExamFee: 'Private Students Exam Fee',
  PrivateStudentsFee: 'Private Students Fee',
  StudyCertificate: 'Study Certificate',
  DHSCX: 'DHSC – X',
  DCECIIPUC: 'DCEC – II PUC',
  AdvanceFee: 'Advance Fee(Next Academic year)',
  ArrearFee: 'Arrear Fee(Previous Academic year)',
  quarter1: 'Quarter 1 Term Fee',
  quarter2: 'Quarter 2 Term Fee',
  quarter3: 'Quarter 3 Term Fee',
  quarter4: 'Quarter 4 Term Fee',
  schoolfeeinst1: 'School Fee Installment 1',
  schoolfeeinst2: 'School Fee Installment 2',
  schoolfeeinst3: 'School Fee Installment 3',
  schoolfeeinst4: 'School Fee Installment 4',
  schoolfeeinst5: 'School Fee Installment 5',
  schoolfeeinst6: 'School Fee Installment 6',
  AdmissionFees: 'Admission Fees',
  book: 'Book',
  specialFees: 'Special Fees',
  lateAdmissionFees: 'Late Admission fees',
  deferredIncome: 'Deferred Income',
  culturalFee: 'Cultural Fee',
  studentWelfare: 'Student Welfare',
  teachersWelfare: 'Teachers Welfare',
  specialSportsFund: 'Special Sports Fund',
  toiletMaintainance: 'Toilet Maintainance Fee',
  electricalWater: 'Electrical & Water Fees',
  practicalExamFees: 'Practical Exam Fees  per subject',
  miscelleneousFee1: 'Miscelleneous Fee-1',
  miscelleneousFee2: 'Miscelleneous Fee-2',
  flagFees: 'Flag Fees',
  nssFee: 'NSS fee',
  magazine: 'Magazine',
  academicFeesWelfare: 'Academic fees: teachers welfare,  teachers day etc.',
  postage: 'Postage: SMS, Test M C, telephone Charges, attendance & etc.',
  marksCard: 'Marks Card',
  breakageFees: 'Breakage fees',
  studentGroupInsurance: 'Student Group Insurance',
  poorStudentWelfareFund: 'Poor student welfare fund',
  yogaFee: 'Yoga Fee',
  suvidhya: 'Suvidhya (PU Board)',
  pathfinderHikingActivity: 'Pathfinder Hiking Activity',
  pathfinderSwimmingActivity: 'Pathfinder Swimming Activity',
  collegefeeinst1: 'College Fee Installment 1',
  collegefeeinst2: 'College Fee Installment 2',
  collegefeeinst3: 'College Fee Installment 3',
  collegefeeinst4: 'College Fee Installment 4',
  collegefeeinst5: 'College Fee Installment 5',
  collegefeeinst6: 'College Fee Installment 6'
}

export const MODE_OF_PAYMENT = {
  cash: 'Cash',
  cheque: 'Cheque',
  dd: 'Demand Draft(DD)',
  card: 'Card(debit/credit)',
  neft: 'NEFT',
  imps: 'IMPS',
  upi: 'UPI'
}

export const PAYMENT_FILTER_TYPES = {
  cash: 'Cash',
  cheque: 'Cheque',
  dd: 'Demand Draft(DD)',
  online: 'Online',
  neft: 'NEFT/RTGS',
  imps: 'IMPS',
  card: 'Card(debit/credit)',
  upi: 'UPI'
}

export const STUDENT_SEARCH_FILTERS = {
  name: 'NAME',
  roll_number: 'ROLL NUMBER',
  admission_number: 'ADMISSION NUMBER',
  account_number: 'ACCOUNT NUMBER',
  caste: 'CASTE',
  reservation: 'RESERVATION',
  nationality: 'NATIONALITY',
  father: 'FATHER',
  mother: 'MOTHER',
  sub_caste: 'SUB CASTE',
  house: 'HOUSE',
  religion: 'RELIGION'
}

export const MAX_STUDENTS_PER_PAGE = 34

export const STUDENT_LIST_CAPITALIZED_COLUMNS = [
  'name',
  'gender',
  'father_name',
  'father_occupation',
  'mother_name',
  'mother_occupation'
]

export const PORTION_AND_TIMETABLE = [
  { id: 'portion', name: 'Portion' },
  { id: 'timetable', name: 'Timetable' }
]

export const STUDENT_CERTIFICATE_LISTS = [
  {
    type: 'bcbsGrantedVacationVisa',
    value: 'Granted Vacation Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'text',
        name: 'accompaniedBy',
        value: 'Accompanied By'
      },
      {
        type: 'date',
        name: 'startDate',
        value: 'Start Date'
      },
      {
        type: 'date',
        name: 'endDate',
        value: ' End Date'
      },
      {
        type: 'select',
        name: 'visaType',
        value: 'Visa Type',
        defaultPlaceHolder: 'Please select the visa type',
        options: [
          {
            name: 'Normal',
            id: ''
          },
          {
            name: 'Schengen',
            id: 'schengen'
          }
        ]
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedVacationVisa',
    value: 'Promoted Vacation Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'text',
        name: 'vacationType',
        value: 'Vacation Type'
      },
      {
        type: 'date',
        name: 'vacationStartDate',
        value: 'Vacation Start Date'
      },
      {
        type: 'date',
        name: 'vacationEndDate',
        value: 'Vacation End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsVacationVisa',
    value: 'Vacation Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'text',
        name: 'vacationType',
        value: 'Vacation Type'
      },
      {
        type: 'date',
        name: 'vacationStartDate',
        value: 'Vacation Start Date'
      },
      {
        type: 'date',
        name: 'vacationEndDate',
        value: 'Vacation End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCompetitionVisa',
    value: 'BCBS Competition  Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'text',
        name: 'eventName',
        value: 'Event Name'
      },
      {
        type: 'date',
        name: 'eventStartDate',
        value: 'Event Start Date'
      },
      {
        type: 'date',
        name: 'eventEndDate',
        value: 'Event End Date'
      },
      {
        type: 'text',
        name: 'accompaniedByTeacherName',
        value: 'Accompanied By Teacher Name'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-Charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsBonafideLetter',
    value: 'Bonafide Letter',
    extraFields: [
      {
        type: 'text',
        name: 'certificateIssuedFor',
        value: 'Certificate Issued For'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsBonafideLetterWithReligion',
    value: 'Bonafide Letter (with Religion)',
    extraFields: [
      // {
      //   type: 'text',
      //   name: 'certificateIssuedFor',
      //   value: 'Certificate Issued For'
      // },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsBonafidePlainLetter',
    value: 'Bonafide Plain Letter',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetBackwardClass',
    value: 'Cet Backward Class',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetBeforeExam',
    value: 'Cet Before Exam',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetLetter',
    value: 'Cet Letter',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsForeignStudentBonafide',
    value: 'Foreign Student Bonafide',
    extraFields: [
      {
        type: 'text',
        name: 'visaNo',
        value: 'Visa No'
      },

      {
        type: 'date',
        name: 'visaStartDate',
        value: 'Visa Start Date'
      },
      {
        type: 'date',
        name: 'visaEndDate',
        value: 'Visa End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  // {
  //   type: 'bcbsGrantedVacationVisa',
  //   value: 'Granted Vacation Visa',
  //   extraFields: [
  //     {
  //       type: 'text',
  //       name: 'country',
  //       value: 'COUNTRY'
  //     },
  //     {
  //       type: 'date',
  //       name: 'fromDate',
  //       value: 'from Date'
  //     }
  //   ]
  // },
  {
    type: 'bcbsLongAbsenteeLetterOffice',
    value: 'Long Absentee Letter Office',
    extraFields: [
      {
        type: 'date',
        name: 'absentFrom',
        value: 'Absent From'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsLongAbsenteeLetterParent',
    value: 'Long Absentee Letter Parent',
    extraFields: [
      {
        type: 'date',
        name: 'absentFrom',
        value: 'Absent From'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPassport',
    value: 'Passport',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedAadharLetter',
    value: 'Promoted Aadhar Letter',
    extraFields: [
      {
        type: 'select',
        name: 'nextClassName',
        value: 'Promo Class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedBonafideLetter',
    value: 'Promoted Bonafide Letter',
    extraFields: [
      {
        type: 'text',
        name: 'certificateIssuedFor',
        value: 'Certificate Issued For'
      },
      {
        type: 'select',
        name: 'nextClassName',
        value: 'NextClass',
        defaultPlaceHolder: 'Please select the class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedPassport',
    value: 'Promoted Passport',
    extraFields: [
      {
        type: 'select',
        name: 'nextClassName',
        value: 'NextClass',
        defaultPlaceHolder: 'Please select the Next Class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedPlainBonafide',
    value: 'Promoted Plain Bonafide',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedRenewalPassport',
    value: 'Promoted Renewal Passport',
    extraFields: [
      {
        type: 'select',
        name: 'nextClassName',
        value: 'Promoted Passport NextClass',
        defaultPlaceHolder: 'Please select the Class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPromotedVacationVisa',
    value: 'Promoted Vacation Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'text',
        name: 'vacationType',
        value: 'Vacation Type'
      },
      {
        type: 'date',
        name: 'vacationStartDate',
        value: 'Vacation Start Date'
      },
      {
        type: 'date',
        name: 'vacationEndDate',
        value: 'Vacation End Date'
      },
      {
        type: 'select',
        name: 'nextClassName',
        value: 'Promoted Passport NextClass',
        defaultPlaceHolder: 'Please select the class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsRenewalOfPassport',
    value: 'Renewal Of Passport',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsSpecialLeavePermissionForVisa',
    value: 'Special Leave Permission For Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'date',
        name: 'startDate',
        value: 'Start Date'
      },
      {
        type: 'date',
        name: 'endDate',
        value: 'End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsStdXIIBonafideLetterForCet',
    value: 'Std XII Bonafide Letter For Cet',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsStudentStudyCertificate',
    value: 'Student Study Certificate',
    extraFields: [
      {
        type: 'select',
        name: 'year',
        value: 'Select Academic Year',
        defaultPlaceHolder: 'Please select the Year',
        optionType: 'year'
      },
      {
        type: 'select',
        name: 'className',
        value: 'Select Class',
        defaultPlaceHolder: 'Please select the Class',
        options: []
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsUniqueIdentificationCard',
    value: 'Unique Identification Card',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsVacationVisa',
    value: 'Vacation Visa',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'text',
        name: 'vacationType',
        value: 'Vacation Type'
      },
      {
        type: 'date',
        name: 'vacationStartDate',
        value: 'Vacation Start Date'
      },
      {
        type: 'date',
        name: 'vacationEndDate',
        value: 'Vacation End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsAttendancePercentage',
    value: 'Attendance Percentage',
    extraFields: [
      {
        type: 'text',
        name: 'attendance',
        value: 'Attendance'
      },
      {
        type: 'text',
        name: 'attendanceTotal',
        value: 'Attendance Total'
      },
      {
        type: 'text',
        name: 'attendancePer',
        value: 'Percentage'
      },
      {
        type: 'text',
        name: 'absentDays',
        value: 'No. of days absent'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCasteCertifcate',
    value: 'Caste Certificate',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetSc',
    value: 'CET - Schedule Caste',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetSt',
    value: 'CET - Schedule Tribe',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetDiscontinuedandRejoinedletter',
    value: 'CET - Discontinued and Rejoined letter',
    extraFields: [
      {
        type: 'text',
        name: 'firstJoiningYear',
        value: 'First Joining Year'
      },
      {
        type: 'text',
        name: 'firstJoiningClass',
        value: 'First Joining Class'
      },
      {
        type: 'text',
        name: 'leftyear',
        value: 'Left Year'
      },
      {
        type: 'text',
        name: 'leftClass',
        value: 'Left Class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsCetLetterLeftWhileStudying',
    value: 'CET - Left While Studying Letter',
    extraFields: [
      {
        type: 'text',
        name: 'year',
        value: 'Current Year'
      },
      {
        type: 'text',
        name: 'month',
        value: 'Current Month'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbs3rdLanguageHindi',
    value: 'III Language - Hindi',
    extraFields: [
      {
        type: 'text',
        name: 'leftYear',
        value: 'Left Year'
      },
      {
        type: 'text',
        name: 'leftClass',
        value: 'Left Class'
      },
      {
        type: 'text',
        name: 'startClass',
        value: 'Start Class'
      },
      {
        type: 'text',
        name: 'endClass',
        value: 'End Class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsLongAbsenteeLetterafterLongLeave',
    value: 'Long Absentee Letter - Not Attending Class After Leave',
    extraFields: [
      {
        type: 'date',
        name: 'leaveApplyDate',
        value: 'Leave Application Date'
      },
      {
        type: 'text',
        name: 'leavePeriod',
        value: 'No. of months for leave'
      },
      {
        type: 'date',
        name: 'longLeaveStartDate',
        value: 'Long Leave Start Date'
      },
      {
        type: 'date',
        name: 'longLeaveEndDate',
        value: 'Long Leave End Date'
      },
      {
        type: 'date',
        name: 'absentFrom',
        value: 'Absent From'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsLongAbsenteeTc',
    value: 'Long Absentee - TC',
    extraFields: [
      {
        type: 'date',
        name: 'referenceletterDate',
        value: 'Reference Letter Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsNccStudentStudyCertificate',
    value: 'NCC STUDY CERTIFICATE',
    extraFields: [
      {
        type: 'text',
        name: 'nccParticipatedClasses',
        value: 'NCC Participated Class'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsOverseasCitizenofIndia',
    value: 'Overseas Citizen of Indian card',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPassportinTatkalScheme',
    value: 'BCBS Passport in Tatkal Scheme',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'date',
        name: 'tripStartDate',
        value: 'Trip Start Date'
      },
      {
        type: 'date',
        name: 'tripEndDate',
        value: 'Trip End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsPioCard',
    value: 'PIO Card',
    extraFields: [
      {
        type: 'text',
        name: 'pioCardNumber',
        value: 'PIO Number'
      },
      {
        type: 'date',
        name: 'pioValidity',
        value: 'PIO Validity'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsrenewalPassportTatkalScheme',
    value: 'BCBS Renewal of  Passport in Tatkal Scheme',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'date',
        name: 'tripStartDate',
        value: 'Trip Start Date'
      },
      {
        type: 'date',
        name: 'tripEndDate',
        value: 'Trip End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsResidentialPermiLetter',
    value: 'Residential Permit Letter',
    extraFields: [
      {
        type: 'text',
        name: 'visaNumber',
        value: 'Visa Number'
      },
      {
        type: 'date',
        name: 'visaEndDate',
        value: 'Visa End Date'
      },
      {
        type: 'text',
        name: 'FsisStudentId',
        value: 'FSIS Student Id'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsThaiVisaLetterForNextAcademicYear',
    value: 'Thailand Visa Letter For Next Academic Year School Reopen Date',
    extraFields: [
      {
        type: 'date',
        name: 'reopeningDate',
        value: 'Reopening Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsStudentStudySubjectsIcse',
    value: 'Study Certificate - Subjects Studied ICSE',
    extraFields: [
      {
        type: 'text',
        name: 'subjectsStudied',
        value: 'Subjects Studied'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsStudentStudySubjectsIsc',
    value: 'Study Certificate - Subjects Studied ISC',
    extraFields: [
      {
        type: 'text',
        name: 'subjectsStudied',
        value: 'Subjects Studied'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsAdmissionSlip',
    value: 'Admission Slip',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsForeignStudentVisaForAdmission',
    value: 'Foreign Student Visa for Admission',
    extraFields: [
      {
        type: 'text',
        name: 'lastClass',
        value: 'Class in which he attended Examination'
      },
      {
        type: 'text',
        name: 'month',
        value: 'Month'
      },
      {
        type: 'text',
        name: 'year',
        value: 'Year'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsVisaLetter',
    value: 'Visa Letter',
    extraFields: [
      {
        type: 'text',
        name: 'country',
        value: 'Country'
      },
      {
        type: 'date',
        name: 'startDate',
        value: 'Visa Start Date'
      },
      {
        type: 'date',
        name: 'endDate',
        value: 'Visa End Date'
      },
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsProformaMinorityCertificate',
    value: 'Proforma For Linguistic Minority Certificate',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'bcbsInstructionMediumEnglishCertificate',
    value: 'Instruction of medium is English',
    extraFields: [
      {
        type: 'select',
        name: 'signedBy',
        value: 'Signed By',
        defaultPlaceHolder: 'Please select the SignedBy',
        options: [
          {
            name: 'Principal',
            id: 'principal'
          },
          {
            name: 'Principal In-charge',
            id: 'principalInCharge'
          },
          {
            name: 'Vice Principal',
            id: 'vicePrincipal'
          }
        ]
      }
    ]
  },
  {
    type: 'consentForm',
    value: 'Consent Form',
    extraFields: [
      {
        type: 'date',
        name: 'requestLetterDate',
        value: 'TC Request Letter Date'
      },
      {
        type: 'date',
        name: 'tcIssueDate',
        value: 'TC Issue Date'
      },
      {
        type: 'date',
        name: 'submissionDate',
        value: 'Last Date of Submission'
      }
    ]
  },
  {
    type: 'visBonafideCertificate',
    value: 'Bonafide Certificate',
    extraFields: [
      {
        type: 'text',
        name: 'classCompleted',
        value: 'Class Completed'
      },
      {
        type: 'text',
        name: 'conduct',
        value: 'Conduct'
      }
    ]
  },
  {
    type: 'visStudyCertificate',
    value: 'Study Certificate',
    extraFields: [
      {
        type: 'text',
        name: 'refNumber',
        value: 'Ref Number'
      }
    ]
  }
]
export const MARKS_REPORT = [
  {
    name: 'MARKS REPORT',
    value: 'a4',
    func: 'downloadReport'
  },
  {
    name: 'CHECKLIST UNIT TEST',
    value: 'a3',
    func: 'downloadReport'
  },
  {
    name: 'CHECKLIST EXAM',
    value: 'a3',
    func: 'checkListExam',
    type: 'all'
  },
  // {
  //   name: 'CHECKLIST EXAM (Only Main Subjects)',
  //   value: 'a3',
  //   func: 'checkListExam',
  //   type: 'main'
  // },
  // {
  //   name: 'CHECKLIST EXAM (Other SUbjects)',
  //   value: 'a3',
  //   func: 'checkListExam',
  //   type: 'others'
  // },
  {
    name: 'CUMULATIVE SECTION REPORT UNIT TEST',
    value: 'a3',
    func: 'downloadReportcummulative'
  },
  {
    name: 'CUMULATIVE SECTION REPORT EXAM',
    value: 'a3',
    func: 'downloadReportPer'
  },
  {
    name: 'CLASS 12 CUMULATIVE SECTION REPORT',
    value: 'a3',
    func: 'downloadReportBestOfPer'
  },
  {
    name: 'Kindergarten Marks Report (W/ subtest)',
    value: 'a3',
    func: 'downloadKindergartenReport'
  },
  {
    name: 'Marks Report (W/ subtest)',
    value: 'a3',
    func: 'downloadMarksWithSubtest'
  }
]

export const cmsType = [
  { id: 'newsLetter', name: 'News Letter' },
  { id: 'teachersMessage', name: "Teacher's Message" },
  { id: 'fortnightlyPlan', name: 'Fortnightly Plan' },
  { id: 'magazine', name: 'Magazine' },
  { id: 'weeklyReport', name: 'Weekly Report' },
  { id: 'monthlyReport', name: 'Monthly Report' },
  { id: 'others', name: 'Others' }
]
export const cmsTypeObj = {
  newsLetter: 'News Letter',
  teachersMessage: "Teacher's Message",
  fortnightlyPlan: 'Fortnightly Plan',
  magazine: 'Magazine',
  weeklyReport: 'Weekly Report',
  monthlyReport: 'Monthly Report',
  others: 'Others',
  '': 'No Document Type'
}

export const TabularAnalysisMarksRanges = [
  { id: '0To100', name: 'All' },
  { id: '0To34.9', name: 'Failures' },
  { id: '35To49.9', name: 'Below Average' },
  { id: '50To74.9', name: 'Average' },
  { id: '75To100', name: 'Above Average' }
]
export const TabularAnalysisObj = {
  '0To100': 'All',
  '0To34.9': 'Failures',
  '35To49.9': 'Below Average',
  '50To74.9': 'Average',
  '75To100': 'Above Average'
}

export const TabularAnalysisMarksRangesObj = {
  '0To34.9': [],
  '35To49.9': [],
  '50To74.9': [],
  '75To100': []
}

export const TabularAnalysisTypes = [
  { id: 'studentList', name: 'StudentList' },
  { id: 'studentCountSubjectwise', name: 'Target Board' }
]

//FEE TYPE OPRIONS
export const FEE_TYPE_OPTIONS = {
  AdmissionFees: 'Admission Fees',
  ApplicationFees: 'Application Fees',
  arrear: 'Arrear Fees',
  TransferCertificate: 'Transfer Certificate',
  TextBooks: 'Text Books',
  NoteBooks: 'Note Books',
  Tie: 'Tie',
  Belt: 'Belt',
  socks: 'Socks',
  EducationalTrip: 'Educational Trip',
  ChangeOverFees: 'Change Over Fees',
  PathfinderFees: 'Pathfinder Fees',
  ChoirFees: 'Choir Fees',
  OtherFees: 'Other Fees',
  SslcSupplementaryFees: 'SSLC Supplementary Fees',
  Migration: 'Migration',
  SslcExamFees: 'SSLC Exam Fees',
  IcseExamFees: 'ICSE Exam Fees',
  IcseRegistration: 'ICSE Registration',
  PathfinderCamp: 'Pathfinder Camp',
  StaffRetreat: 'Staff Retreat',
  ChoirUniform: 'Choir Uniform ',
  Diary: 'Diary',
  PucSupplementaryfees: 'PUC Supplementary Fees',
  Fine: 'Fine',
  ScienceClub: 'Science Club',
  IcseSupplementaryFees: 'ICSE Supplementary Fees',
  PucExamFees: 'Puc Exam Fees',
  SslcRegistration: 'SSLC Registration',
  MinorityScholarship: 'Minority Scholarship',
  PrivateStudentsExamFee: 'Private Students Exam Fee',
  PrivateStudentsFee: 'Private Students Fee',
  StudyCertificate: 'Study Certificate',
  DHSCX: 'DHSC – X',
  DCECIIPUC: 'DCEC – II PUC',
  AdvanceFee: 'Advance Fee(Next Academic year)',
  ArrearFee: 'Arrear Fee(Previous Academic year)',
  lateAdmissionFees: 'Late Admission fees',
  transport: 'Transportation fees',
  pathfinderHikingActivity: 'Pathfinder Hiking Activity',
  pathfinderSwimmingActivity: 'Pathfinder Swimming Activity'
}

export const studentArr1 = [
  {
    name: 'STUDENT LIST',
    url: '/student/show'
  },
  {
    name: 'ADD STUDENT',
    url: '/student/add'
  }
]
export const attendanceArray = [
  {
    name: 'ATTENDANCE ENTRY',
    url: '/attendance/new'
  },
  {
    name: 'ATTENDANCE REPORT',
    url: '/attendance/attendance-report'
  }
]
export const marksArray = [
  {
    name: 'MARKS ENTRY',
    url: '/marks/entry'
  },
  {
    name: 'MARKS REPORT',
    url: '/marks/report/level-2'
  }
]
export const institutionArray = [
  {
    name: 'CLASS LIST',
    url: '/studentgroup/classes'
  },
  {
    name: 'EDIT CLASS',
    url: '/studentgroup/class/edit'
  }
]

export const teacherLeaveStatus = ['Pending', 'Approved', 'Rejected']

export const catalogTypes = [
  {
    id: 'PAPERBACK',
    type: 'Paperback'
  },
  {
    id: 'HARDBACK',
    type: 'Hardback'
  },
  {
    id: 'JOURNAL',
    type: 'Journal'
  },
  {
    id: 'THESIS',
    type: 'Thesis'
  }
]

export const studentAchievementCategoryType = [
  {
    id: 'academics',
    type: 'Academics'
  },
  {
    id: 'co-curricular',
    type: 'Co-Curricular '
  },
  {
    id: 'extra-Curricular',
    type: 'Extra-Curricular'
  },
  {
    id: 'attendance',
    type: 'Attendance'
  },
  {
    id: 'sports',
    type: 'Sports'
  },
  {
    id: 'health',
    type: 'Health'
  },
  {
    id: 'behaviour',
    type: 'Behaviour'
  }
]

export const studentAchievementType = [
  {
    id: 'achievement',
    type: 'Achievement'
  },
  {
    id: 'general',
    type: 'General'
  },
  {
    id: 'liability',
    type: 'Liability'
  },
  {
    id: 'confidential',
    type: 'Confidential'
  }
]

export const studentAchievementTypeForTeacher = [
  {
    id: 'achievement',
    type: 'Achievement'
  },
  {
    id: 'general',
    type: 'General'
  },
  {
    id: 'liability',
    type: 'Liability'
  }
]

export const FEE_CATEGORY_TYPE = [
  {
    id: 'general',
    type: 'General'
  },
  {
    id: 'transportation',
    type: 'Transportation'
  }
]

export const FEE_FREQUENCY = [
  {
    id: 'installment',
    type: 'Installment'
  },
  {
    id: 'monthly',
    type: 'Monthly'
  },
  {
    id: 'quarterly',
    type: 'Quarterly'
  },
  {
    id: 'half',
    type: 'Half'
  },
  {
    id: 'anually',
    type: 'Annually'
  }
]

export const FEE_FREQUENCY_OBJ = {
  installment: 'Installment',
  monthly: 'Monthly',
  half: 'Half',
  quarterly: 'Quarterly',
  anually: 'Annually'
}

export const LEVEL_TYPE_OBJ = {
  superAdmin: 'Superadmin',
  support: 'Support',
  admin: 'Admin',
  adminCoordinator: 'Admin Coordinator',
  principal: 'Principal',
  teacher: 'Teacher',
  student: 'Student',
  cashier: 'Cashier',
  admissionOfficer: 'Admission Officer',
  director: 'Director'
}

export const LEVEL_TYPE = [
  {
    id: 'superAdmin',
    name: 'Superadmin'
  },
  {
    id: 'support',
    name: 'Support'
  },
  {
    id: 'admin',
    name: 'Admin'
  },
  {
    id: 'adminCoordinator',
    name: 'Admin Coordinator'
  },
  {
    id: 'principal',
    name: 'Principal'
  },
  {
    id: 'teacher',
    name: 'Teacher'
  },
  {
    id: 'student',
    name: 'Student'
  },
  {
    id: 'cashier',
    name: 'Cashier'
  },
  {
    id: 'admissionOfficer',
    name: 'Admission Officer'
  },
  {
    id: 'library',
    name: 'Librarian'
  },
  {
    id: 'parent',
    name: 'Parent'
  },
  {
    id: 'director',
    name: 'Director'
  }
]

export const CREATE_USER = [
  // {
  //   id: 'superAdmin',
  //   name: 'Superadmin'
  // },
  {
    id: 'admin',
    name: 'Admin'
  },
  {
    id: 'adminCoordinator',
    name: 'Admin Coordinator'
  },
  {
    id: 'principal',
    name: 'Principal'
  },
  {
    id: 'cashier',
    name: 'Cashier'
  },
  {
    id: 'admissionOfficer',
    name: 'Admission Officer'
  },
  {
    id: 'library',
    name: 'Librarian'
  },
  { id: 'teacher', name: 'Teacher' },
  { id: 'director', name: 'Director' }
  // { id: 'parent', name: 'Parent' }
]

export const PAYMENT_PORTAL = [
  {
    id: 'razorpay',
    type: 'Razorpay'
  },
  {
    id: 'easebuzz',
    type: 'Easebuzz'
  }
]

export const teacherDocuments = [
  'Aadhar Card',
  'PAN Card',
  'Passport',
  'Offer Letter'
]

export const teacherDocumentsObj = {
  'Aadhar Card': 'Aadhar Card',
  'PAN Card': 'PAN Card',
  Passport: 'Passport',
  'Offer Letter': 'Offer Letter'
}

export const archiveReasons = [
  'Archived - T.C.',
  'Archived - Fee defaulter',
  'Archived - Pending',
  'Archived - Long Absent',
  'Archived - Withheld',
  'Archived - Completed Std.X (year)',
  'Archived - Completed Std.XII (year)',
  'Archived - Failed in Std.X (year)',
  'Archived - Failed in Std.XII (year)'
]

export const archiveReasonsObj = {
  'Archived - T.C.': 'Archived - T.C.',
  'Archived - Fee defaulter': 'Archived - Fee defaulter',
  'Archived - Pending': 'Archived - Pending',
  'Archived - Long Absent': 'Archived - Long Absent',
  'Archived - Withheld': 'Archived - Withheld',
  'Archived - Completed Std.X (year)': 'Archived - Completed Std.X (year)',
  'Archived - Completed Std.XII (year)': 'Archived - Completed Std.XII (year)',
  'Archived - Failed in Std.X (year)': 'Archived - Failed in Std.X (year)',
  'Archived - Failed in Std.XII (year)': 'Archived - Failed in Std.XII (year)'
}

export const listOfQueries = [
  'Login Issue',
  'Fee related',
  'Marks related',
  'Homework/Assignment related',
  'Question Banks',
  'Circular',
  'Worksheets',
  'Report card',
  'Incorrect ward detail',
  'Teacher / Staff',
  'Not Listed Above (Create new)'
]

export const TEACHER_INFO = {
  EMAIL_TYPE: [
    {
      value: 'Select Email type',
      disable: true
    },
    {
      value: 'Professional',
      disable: false
    },
    {
      value: 'Personal',
      disable: false
    }
  ],
  CONTACT_TYPE: [
    {
      value: 'Select Contact type',
      disable: true
    },
    {
      value: 'Primary',
      disable: false
    },
    {
      value: 'Secondary',
      disable: false
    },
    {
      value: 'Emergency',
      disable: false
    }
  ],
  EMPLOYMENT_STATUS: [
    {
      value: 'Select Employment status',
      disable: true
    },
    {
      value: 'Permanent',
      disable: false
    },
    {
      value: 'Temporary',
      disable: false
    },
    {
      value: 'Guest Lecturer',
      disable: false
    }
  ],
  SENIORITY: [
    {
      value: 'Select Seniority',
      disable: true
    },
    {
      value: 'Associate Professor',
      disable: false
    },
    {
      value: 'Assistant Professor',
      disable: false
    }
  ]
}
