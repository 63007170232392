// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Datasort from 'react-data-sort'
// import selectors
import { byId as subjectsById } from '../../reducers/subjects'
// import components
import TableHead from './tableHead'
import TableBody from './tableBody'

class StudentTableComponent extends Component {
  static propTypes = {
    indexStart: PropTypes.number
  }
  render() {
    const {
      tableHeaders,
      groupSubjectIds,
      subjectsById,
      indexStart = 'none',
      studentData
    } = this.props
    return (
      <Datasort
        data={studentData && studentData.length > 0 ? studentData : [{}]}
        defaultSortBy="roll_number"
        render={({ data, setSortBy, sortBy, direction, toggleDirection }) => {
          return (
            <div className="attd-report-table">
              <table className="table profile-table">
                <TableHead
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  direction={direction}
                  toggleDirection={toggleDirection}
                  tableHeaders={tableHeaders}
                  groupSubjectIds={groupSubjectIds}
                  subjectsById={subjectsById}
                />
                <TableBody
                  data={
                    data &&
                    data[0] &&
                    Object.keys(data[0]) &&
                    Object.keys(data[0]).length > 0
                      ? data
                      : []
                  }
                  tableHeaders={tableHeaders}
                  indexStart={indexStart}
                />
              </table>
            </div>
          )
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  subjectsById: subjectsById(state)
})

const StudentTable = connect(mapStateToProps, null)(StudentTableComponent)

export default StudentTable
