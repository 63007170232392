import {
  renderMaxMarks,
  getMark,
  checkForElective,
  getById,
  downloadReport
} from '../../../helpers/utils'
import { isUndefined } from 'util'

const downloadMarksReport = ({
  props,
  studentData,
  subjectData,
  templateType,
  state
}) => {
  const { groups, subjectsById, tests, institutionData, academicYear } = props
  const { parent_group, groupId, testId } = state
  const { name: institutionName, address: institutionAddress } = Object.assign(
    { name: '', address: '' },
    institutionData
  )
  const subjectOfTestById = getById(tests[testId].subjects, 'subject')
  const { academic_year, group_name, umbrella_group } = groups[groupId]
  const maximumMarks = subjectData.map(obj => {
    let returnObj = {}
    returnObj['maxMarks'] = renderMaxMarks(obj, studentData)
    if (obj.electives.length > 0) returnObj['span'] = true
    return returnObj
  })
  const subjectNames = subjectData.map(sub => {
    let obj = {}
    obj['name'] = sub.umbrellaName
      ? `${sub.umbrellaName} ${sub.name}`
      : sub.name
    if (sub.electives.length > 0) obj['span'] = true
    return obj
  })
  const studentdetail = studentData.map(student => {
    let stdobj = {}
    let arr = []
    const subjectIds = subjectData.map(sub => sub._id)
    subjectIds.forEach((sub, i) => {
      let obj = {}
      if (!isUndefined(subjectsById[sub])) {
        if (subjectsById[sub].electives.length > 0) {
          obj['span'] = true
        }
      }
      if (isUndefined(student.marks[sub]) || isUndefined(subjectsById[sub])) {
        obj['marks'] = null
        obj['noMarks'] = true
      } else {
        obj['marks'] = `${getMark(student.marks[sub], subjectsById[sub])} ${
          checkForElective(student.marks[sub])
            ? checkForElective(student.marks[sub])
            : ''
        }`
        obj['marksPrint'] = getMark(student.marks[sub], subjectsById[sub])
        obj['elective'] = `${
          checkForElective(student.marks[sub])
            ? checkForElective(student.marks[sub])
            : ''
        }`

        if (subjectsById[sub].marksGrade === 'marks') {
          const marksOnly = student.marks[sub]['mark']
          // const maxMarks = student.marks[sub]['maxMarks']
          const minMarks = subjectOfTestById[sub]['minMarks']
          // const per = (marksOnly / maxMarks) * 100
          if (student.marks[sub].zeroReason === 'notZero') {
            if (subjectsById[sub].totalCalculation) {
              if (marksOnly < minMarks) obj['fail'] = true
            } else {
              if (marksOnly < minMarks) obj['fail'] = true
            }
          } else {
            obj['zeroReason'] = true
          }
        }
      }

      arr.push(obj)
    })
    stdobj['rollno'] = student.roll_number
    stdobj['name'] = student.name
    stdobj['marks'] = arr
    stdobj['total'] = student.total
    stdobj['percentage'] = student.percentage
    stdobj['sectionPosition'] = student.sectionPosition
    stdobj['classPosition'] = student.classPosition
    return stdobj
  })
  const data = {
    title: `${parent_group}-${groups[groupId].group_name}-${tests[testId].name}`,
    maxMarks: maximumMarks,
    span: 6 + subjectNames.length,
    students: studentdetail.map(data => {
      let stddata = {}
      stddata['rollno'] = data.rollno
      stddata['name'] = data.name
      stddata['marks'] = data.marks
      stddata['total'] = data.total
      stddata['percentage'] = data.percentage
      stddata['sectionPosition'] = data.sectionPosition
      stddata['classPosition'] = data.classPosition
      return stddata
    }),
    subjects: subjectNames,
    classList: `CLASS LIST ${umbrella_group} - ${group_name} ${academic_year}-2019`,
    year: `${academic_year}-2019`,
    class: umbrella_group,
    section: group_name
  }
  data['institutionName'] = institutionName
  data['institutionAddress'] = institutionAddress
  data['class'] = umbrella_group
  data['section'] = group_name
  data['testName'] = tests[testId].name
  const academicYearPlushOne = Number(academicYear) + 1
  data['academicYear'] = `${academicYear} - ${academicYearPlushOne}`
  let template = 'performanceReport'
  if (templateType === 'a3') template = 'performanceReportA3'
  downloadReport({
    nameOfTemplate: template,
    dataForTemplate: data,
    downloadedFileName: 'performance-report'
  })
}
export default downloadMarksReport
