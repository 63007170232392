// import packages
import React, { Component } from 'react'

// import components
import TableHead from './tableHead'
import TableBody from './tableBody'

class StudentTable extends Component {
  render() {
    const { tableHeaders, studentData } = this.props
    return (
      <div className="attd-report-table">
        <table className="table profile-table">
          <TableHead tableHeaders={tableHeaders} />
          <TableBody data={studentData} tableHeaders={tableHeaders} />
        </table>
      </div>
    )
  }
}

export default StudentTable
