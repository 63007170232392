import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
// import { PrivateLayout } from '../../../common/layout/layoutComponent'
import { BlankLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

// const ParentLogin = lazy(() => import('../../../../components/dashboard/parent'))
const ParentDashboard = lazy(() => import('../../../dashboard/parent'))
const ForgotPasswordComponent = lazy(() =>
  import('../../../account/forgotPassword')
)

const ParentRoute = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* Frontend v3 routes */}

            {/* Frontend v4 routes */}
            <BlankLayout
              exact
              path="/dashboard"
              name="children list"
              component={ParentDashboard}
            />
            <BlankLayout
              exact
              path="/reset-password"
              name="reset password"
              component={ForgotPasswordComponent}
            />
            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default ParentRoute
