import { createSelector } from 'reselect'

import {
  APP_LOGOUT,
  COMMENTS_DATA,
  ADD_COMMENT,
  START_COMMENTS_DATA
} from '../helpers/actions'

import { getActiveApplication } from './ui/application'

const initialState = {
  byId: {},
  allIds: [],
  isloading: false
}

export const comments = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_DATA:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isloading: false
      })
    case START_COMMENTS_DATA:
      return Object.assign({}, state, {
        isloading: true
      })
    case ADD_COMMENT: {
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.comment['_id']]: action.comment
        }),
        allIds: state.allIds.concat(action.comment['_id'])
      })
    }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getById = state => state.comments.byId
export const getAllIds = state => state.comments.allIds
export const isloading = state => state.comments.isloading

export const getApplicationComments = createSelector(
  getById,
  getAllIds,
  getActiveApplication,
  (byId, comments, activeApplication) => {
    return comments
      .filter(id => byId[id]['commentTo'] === activeApplication)
      .map(id => byId[id])
  }
)
