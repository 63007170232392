import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

/*reducers*/
import {
  isLoggedIn,
  getLoggingIn,
  getError,
  getMsg
} from '../../../reducers/accounts'

/*actions*/
import { emitLogin } from '../../../actions/login'

/*Components*/
import LoginForm from './loginForm'
import PageHelmet from '../../common/atom/pageHelmet'

const LoginComponent = () => {
  const dispatch = useDispatch()
  const loggedIn = useSelector(isLoggedIn)

  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')

  const isLoggingIn = useSelector(getLoggingIn)
  const error = useSelector(getError)
  const errorMsg = useSelector(getMsg)

  const handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    switch (name) {
      case 'name':
        setUserName(value)
        break
      case 'password':
        setPassword(value)
        break
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      // device_id: '',
      device_type: 'Desktop',
      browser: navigator?.userAgentData?.brands?.[0]?.brand,
      app_version: navigator?.userAgent,
      os: navigator?.userAgentData?.platform,
      user: userName,
      password: password
    }
    dispatch(emitLogin(data))
  }
  if (!loggedIn) {
    return (
      <div className="container login-page zIndex--2">
        <PageHelmet>Login</PageHelmet>
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1">
            <div className="col-xs-11 col-xs-offset-1">
              <span>
                <img
                  src="sealtabs-logo.png"
                  alt="sealtabs-logo"
                  className="sealtabs-logo"
                />
              </span>
            </div>
            <div className="col-lg-12 quote-and-form-container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="st-home-quote">
                    <h2 className="st-home-quote st-home-quote__text">
                      "Education is the most powerful tool which you can use to
                      change the world."
                    </h2>
                    <p className="st-home-quote st-home-quote__author">
                      - Nelson Mandela
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="st-form">
                    <LoginForm
                      loggedIn={loggedIn}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      error={error}
                      errorMsg={errorMsg}
                      isLoggingIn={isLoggingIn}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <Redirect to="/dashboard" />
  }
}

export default LoginComponent
