import React from 'react'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'

import { uniqueParentGroups } from '../../../helpers/utils'
import { SelectInput, SelectInputWithID } from '../../inputs.jsx'
import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../../helpers/enums'

export const GroupSelector = ({
  groups,
  handleParentChange,
  umbrellaGroup,
  sections,
  handleSectionChange,
  groupId,
  handleSubmit,
  isDirty
}) => {
  return (
    <form className="group-selector" onSubmit={handleSubmit}>
      <SelectInput
        name="parent_group"
        options={uniqueParentGroups(groups)}
        handleChange={handleParentChange}
        umbrellaGroup={umbrellaGroup}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <SelectInputWithID
        name="group_name"
        options={sections}
        handleChange={handleSectionChange}
        id={groupId}
        {...STYLE_PROPS_FOR_GROUP_SELECTOR}
      />
      <button
        type="submit"
        className={classNames({
          'button button--blue': true,
          disabled: !isDirty
        })}
        disabled={!isDirty}
      >
        Go
      </button>
    </form>
  )
}
