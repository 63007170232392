import {
  SET_ACTIVE_STUDENTFEE,
  APP_LOGOUT,
  SET_SELECTED_REFUND_RECIEPTS
} from '../../helpers/actions'

const initialState = {
  activeStudentFee: '',
  selectedRefundFeeReciepts: []
}

export const studentFees = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SET_ACTIVE_STUDENTFEE:
      return Object.assign({}, state, {
        activeStudentFee: action.id
      })
    case SET_SELECTED_REFUND_RECIEPTS:
      return Object.assign({}, state, {
        selectedRefundFeeReciepts: action.data
      })
    default:
      return state
  }
}

export const getActiveStudentFee = state =>
  state.ui.studentFees.activeStudentFee
export const getSelectedRefundFeeReciepts = state =>
  state.ui.studentFees.selectedRefundFeeReciepts
