// import packages
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
// import selectors
import { getAcademicGroups, groupsById } from '../../../reducers/groups'
// import helpers
import {
  uniqueParentGroups,
  filterSectionsByParent
} from '../../../helpers/utils'
import { STYLE_PROPS_FOR_GROUP_SELECTOR } from '../../../helpers/enums'
// import components
import { SelectInput, SelectInputWithID } from '../../inputs'

class GroupSelectorContainer extends Component {
  static propTypes = {
    umbrellaGroup: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    isDirty: PropTypes.bool.isRequired,
    academicGroups: PropTypes.array.isRequired,
    groupsById: PropTypes.object.isRequired,
    handleUmbrellaChange: PropTypes.func.isRequired,
    handleSectionChange: PropTypes.func.isRequired,
    handleGo: PropTypes.func.isRequired
  }

  render() {
    const {
      umbrellaGroup,
      groupId,
      isDirty,
      academicGroups,
      handleUmbrellaChange,
      handleSectionChange,
      handleGo
    } = this.props
    return (
      <form className="group-selector" onSubmit={handleGo}>
        <SelectInput
          name="umbrellaGroup"
          options={uniqueParentGroups(academicGroups)}
          umbrellaGroup={umbrellaGroup}
          handleChange={handleUmbrellaChange}
          {...STYLE_PROPS_FOR_GROUP_SELECTOR}
        />
        <FontAwesome size="2x" name="caret-right" />
        <SelectInputWithID
          name="groupId"
          options={filterSectionsByParent(umbrellaGroup, academicGroups)}
          id={groupId}
          handleChange={handleSectionChange}
          {...STYLE_PROPS_FOR_GROUP_SELECTOR}
        />
        <FontAwesome size="2x" name="caret-right" />

        <button
          type="submit"
          className={classNames({
            'button button--small button--blue': true,
            disabled: !isDirty
          })}
          disabled={!isDirty}
        >
          Go
        </button>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  academicGroups: getAcademicGroups(state),
  groupsById: groupsById(state)
})

const GroupSelector = connect(mapStateToProps)(GroupSelectorContainer)

export default GroupSelector
