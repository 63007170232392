import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

class ReduxSelectWithId extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string
  }

  render() {
    const {
      options,
      name,
      placeholder = 'Select Option',
      onChange = () => {}
    } = this.props
    return (
      <Field name={name} component="select" required onChange={onChange}>
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Field>
    )
  }
}

export default ReduxSelectWithId
