import {
  checkStatus,
  parseJSON,
  composeAuth,
  AuthError,
  getById,
  getAllIds
} from '../helpers/utils.js'
import { emitAuthFailed } from './login.js'
import {
  START_POST_POLICY,
  POST_POLICY_SUCCESS,
  POST_POLICY_FAILURE,
  START_FETCH_POLICY,
  START_FETCH_POLICY_SUCCESS,
  START_FETCH_POLICY_FAILURE,
  START_UPLOAD_POLICY_DOCUMENT,
  UPLOAD_POLICY_DOCUMENT_SUCCESS,
  UPLOAD_POLICY_DOCUMENT_FAILURE,
  REMOVE_POLICY_DOCUMENT_LINK
} from '../helpers/actions'

export const emitPostPolicyStart = () => ({
  type: START_POST_POLICY
})
export const setPolicyData = data => ({
  type: POST_POLICY_SUCCESS,
  data
})
export const emitPostPOlicyFail = () => ({
  type: POST_POLICY_FAILURE
})
export const emitFetchPolicyStart = () => ({
  type: START_FETCH_POLICY
})

export const setFetchPolicyData = data => {
  const byId = getById(data)
  const allIds = getAllIds(data)
  return {
    type: START_FETCH_POLICY_SUCCESS,
    byId,
    allIds
  }
}
export const emitFetchPolicyFail = () => ({
  type: START_FETCH_POLICY_FAILURE
})
export const emitPolicyDocumentUploadStart = () => ({
  type: START_UPLOAD_POLICY_DOCUMENT
})
export const emitPolicyDocumentLInk = data => ({
  type: UPLOAD_POLICY_DOCUMENT_SUCCESS,
  data: [{ url: data.publicUrl, name: data.filename }]
})
export const removePolicyDocumentLink = ({ data }) => ({
  type: REMOVE_POLICY_DOCUMENT_LINK,
  data: data
})
export const emitPolicyDocumentLInkFailure = () => ({
  type: UPLOAD_POLICY_DOCUMENT_FAILURE
})
export const singlefileUpload = ({ jwt, body }) => {
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/s3/v2'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/*',
      Authorization
    },
    body
  })
}

export const startSingleFileUpload = data => dispatch => {
  dispatch(emitPolicyDocumentUploadStart())
  return singlefileUpload(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startFileUpload', json.data, typeof json.data)
      dispatch(emitPolicyDocumentLInk(json.data))
    })
    .catch(err => {
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      // dispatch(emitHomeworkDocumentLInkFailure())
    })
}
const postPolicy = ({ jwt, ...payload }) => {
  const Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/policy'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify(payload)
  })
}
export const startPostPolicy = data => dispatch => {
  dispatch(emitPostPolicyStart())
  return postPolicy(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startPostPolicy', json.data)
      dispatch(setPolicyData())
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      else dispatch(emitPostPOlicyFail())
    })
}

const getPolicy = ({ jwt }) => {
  const Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER_NEW + '/api/policy'
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
  })
}
export const startFetchPolicy = data => dispatch => {
  dispatch(emitFetchPolicyStart())
  return getPolicy(data)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      console.log('data inside startPostPolicy', json.data)
      dispatch(setFetchPolicyData(json.data))
    })
    .catch(err => {
      console.error(err)
      if (err instanceof AuthError) dispatch(emitAuthFailed())
      else dispatch(emitFetchPolicyFail())
    })
}
