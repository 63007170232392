import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
//import selectors
import { getInstitutionData } from '../../../reducers/institution'

const PageHelmet = ({ children }) => {
  const institution = useSelector(getInstitutionData)

  return <Helmet title={`${children} | ${institution?.name} | Sealtabs`} />
}

export default PageHelmet
