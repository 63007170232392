import {
  APP_LOGOUT,
  POST_PORTION_FILE_FAILED,
  START_PORTION_FILE_UPLOAD,
  POST_PORTION_FILE_SUCCESS,
  SET_PORTION_FILE_DETAILS
} from '../helpers/actions'

const initialState = {
  portion: [],
  isLoading: false,
  messagge: '',
  byId: {},
  allIds: []
}

export const portionFileUpload = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case START_PORTION_FILE_UPLOAD:
      return Object.assign({}, state, {
        isLoading: true,
        message: ''
      })
    case POST_PORTION_FILE_SUCCESS:
      return Object.assign({}, state, {
        portion: action.original
          ? [...action.data]
          : [...state.file, ...action.data],
        isLoading: false
      })
    case SET_PORTION_FILE_DETAILS:
      return Object.assign({}, state, {
        byId: {
          [action.id]: action.data
        },
        allIds: [action.id],
        isLoading: false,
        portion: [],
        message: 'success'
      })
    case POST_PORTION_FILE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: 'fail'
      })
    default:
      return state
  }
}

//selectors
export const getById = state => state.portionFileUpload.byId
export const getAllIds = state => state.portionFileUpload.allIds
export const isFileLoading = state => state.portionFileUpload.isLoading
export const getportionFileMsg = state => state.portionFileUpload.message
export const getPortionFileLink = state => state.portionFileUpload.portion
