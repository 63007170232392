// import packages
import React, { Component } from 'react'
import { Field } from 'redux-form'

export default class SubjectDetails extends Component {
  render() {
    return (
      <div>
        <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
          <div className="field">
            <label className="field__label" htmlFor="name">
              Subject Name
            </label>
            <Field
              name="name"
              placeholder="Subject Name"
              type="text"
              component="input"
              required
            />
          </div>
          <div className="field">
            <label className="field__label" htmlFor="code">
              Subject Code
            </label>
            <Field
              name="code"
              placeholder="Subject Code"
              type="text"
              component="input"
              required
            />
          </div>
        </div>
        <div className="fields-ctr fields-ctr--has-2-fields fields-ctr--no-border">
          <div className="field">
            <label className="field__label" htmlFor="order">
              Subject Order
            </label>
            <Field
              name="order"
              placeholder="Subject Order"
              type="number"
              component="input"
              required
            />
          </div>
        </div>
      </div>
    )
  }
}
