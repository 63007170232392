import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

import SpinnerNew from '../atom/spinnerNew'

class ModalComponent extends Component {
  render() {
    const {
      showModal,
      closeModal,
      modalHeaderText,
      modalBodyText,
      handleSubmit,
      buttonText,
      isReady,
      children,
      isLoading,
      modalFooterText
    } = this.props
    return (
      <Modal
        size="lg"
        // centered={true}
        show={showModal}
      >
        <ModalHeader>
          <p className="modal-title">{modalHeaderText}</p>
        </ModalHeader>
        <ModalBody className="modal-body modal-body__editfee">
          {children || !modalBodyText ? (
            children
          ) : (
            <div className="info-cell">
              <h4>{modalBodyText}</h4>
            </div>
          )}
        </ModalBody>
        {modalFooterText ? (
          <>
            <div className="space-creator"></div>
            <div className="date-and-class" style={{ marginLeft: '18px' }}>
              {modalFooterText}
            </div>
          </>
        ) : null}
        <ModalFooter>
          <div className="comment-button-container">
            <button className="button button--white" onClick={closeModal}>
              CLOSE
            </button>
            {isLoading ? (
              <SpinnerNew />
            ) : (
              buttonText && (
                <button
                  className="button button--blue"
                  onClick={() => handleSubmit()}
                  disabled={!isReady}
                >
                  {buttonText}
                </button>
              )
            )}
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalComponent
